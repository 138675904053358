import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Loader } from 'components/ui/molecules/Loader'
import { PageTitle } from 'components/ui/atoms/PageTitle'
import { Button } from 'components/ui/atoms/Button'
import { FaFilter, FaPlus, FaPlusCircle } from 'react-icons/fa'
import { RoutesLinks } from 'components/routes-links'
import IRMInvestorsFilters from './IRMInvestorsFilters'
import InvestorsList from './InvestorsList'
import { SectionHeader } from '../ui/molecules/SectionHeader'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import RegisterIRMInvestorSection from './RegisterIRMInvestorSection'
import IrmService from 'services/irm-service'
import { useToasts } from 'react-toast-notifications'
import { useSwal } from 'hooks/useSwal'

const IRM = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { confirm } = useSwal()

  const handleRedirect = (url) => history.push(url)
  const { addToast } = useToasts()

  const [loading, setLoading] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [filters, setFilters] = useState({})
  const [currentPage, setCurrentPage] = useState(0)
  const [showRegisterIRMInvestor, setShowRegisterIRMInvestor] = useState(false)

  const filterInvestors = (filters) => {
    setCurrentPage(0)
    setFilters(filters)
  }

  const SECTIONS = ['my_contacts']// 'kiota_database']
  const [section, setSection] = useState('my_contacts')

  const createInvestor = async (values) => {
    confirm().then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true)
          const result = await IrmService.registerInvestor(values)
          addToast(t('investor_registered_successfully'), {
            appearance: 'success',
            autoDismiss: true
          })
          handleRedirect(`${RoutesLinks.INVESTORS_RELATIONS_MANAGER_LINK}${result.data.data._id}`)
        } catch (error) {
          console.error(error)
          addToast(t('error_occurred_registering_investor'))
        } finally {
          setLoading(false)
        }
      }
    })
  }

  return (
    <>
      {showRegisterIRMInvestor && (
        <RegisterIRMInvestorSection onClose={() => setShowRegisterIRMInvestor(false)} onSubmit={createInvestor}/>
      )}
      <div className='flex items-start'>
        <div className='flex-1'>
          <PageTitle
            title={t('investors_relations_manager')}
            removeMargin={true}
          />
        </div>

        {section === 'my_contacts' && <ButtonMain
          marginRight={2}
          iconComponent={<FaPlus className='inline-block mr-2' />}
          text={t('register_investor')}
          onClick={() => setShowRegisterIRMInvestor(true)}
        />}
        <div data-tip={t('filter')}>
          <ButtonSecondary
            iconComponent={<FaFilter className='h-4'/>}
            onClick={() => setShowFilters(true)}
          />
        </div>
      </div>
      <div className='my-6'>
        <SectionHeader
          sectionTitles={SECTIONS.map((sec) => t(sec))}
          sectionKeys={SECTIONS}
          setSection={setSection}
          section={section}
        />
      </div>
      {loading && <Loader />}

      <InvestorsList filters={filters} filterInvestors={filterInvestors} loading={loading} setLoading={setLoading} currentPage={currentPage} setCurrentPage={setCurrentPage} kiota={section !== 'my_contacts'} />

      {showFilters && (
        <IRMInvestorsFilters
          onClose={() => setShowFilters(false)}
          onFilter={filterInvestors}
          filters={filters}
        />
      )}
    </>
  )
}

export default IRM
