import React, { useEffect } from 'react'
import { FaGlobe, FaLinkedin, SiCrunchbase } from 'react-icons/all'
import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'
import Config from 'config';

export const StartupSocialNetworks = ({ startup, className = '' }) => {
  const { t } = useTranslation()
  const socialNetworksItems = [
    {
      name: 'linkedin',
      title: 'Linkedin',
      icon: <FaLinkedin/>
    },
    {
      name: 'crunchbase_url',
      title: 'Crunchbase',
      icon: <SiCrunchbase />
    },
    {
      name: 'web',
      title: t('project_web'),
      icon: <FaGlobe />
    }
  ]

  const getStartupSocialNetworks = () => {
    if (!startup) {
      return []
    }

    const networks = {
      linkedin: startup.linkedin,
      crunchbase_url: startup.crunchbase_url,
      web: startup.web
    }
    return Object.keys(networks)
      .filter(n => networks[n])
      .map(n => {
        const item = socialNetworksItems.find(i => i.name === n)
        return item || null
      })
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [startup])

  return (
    <div className={`flex justify-center items-center h-[48px] ${className}`}>
      {getStartupSocialNetworks().map(item => (
        <a
          key={item.name}
          href={startup[item.name]}
          target="_blank"
          rel="noreferrer"
          data-tip={item.title}
          className={`w-8 h-8 flex justify-center items-center rounded-full bg-main-${Config.PLATFORM} text-white mx-2`}
        >
          {item.icon}
        </a>
      ))}
    </div>
  )
}
