import moment from 'moment'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/ui/atoms/Button'
import { Card } from 'components/ui/atoms/Card'
import Logo from 'styles/images/logoKiota.png'
import {
  FaExclamationCircle,
  FaRocket
} from 'react-icons/all'
import { useToasts } from 'react-toast-notifications'
import { Loader } from 'components/ui/molecules/Loader'
import { useSwal } from 'hooks/useSwal'
import Utils from 'utils/utils'
import QuestionnairesService from 'services/questionnaires-service'
import EventUtils from 'utils/event-utils'
import { useAsyncState } from '../../hooks/useAsyncState'
import { PublicQuestionnaire } from '../public-questionnaire/PublicQuestionnaire'
import Config from 'config'
import TagManager from 'react-gtm-module'

export const PublicForm = () => {
  const { t } = useTranslation()
  const { formId, templateId } = useParams()
  const { addToast } = useToasts()
  const { alert, confirm } = useSwal()
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const [loading, setLoading] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [openDate, setOpenDate] = useState(null)
  const [errorRetrievingForm, setErrorRetrievingForm] = useState(false)
  const [formNotFound, setFormNotFound] = useState(false)
  const [form, setForm] = useState(null)
  const [questions, setQuestions] = useState([])
  const [answers, setAnswers] = useState({})
  const [bgColor, setBgColor] = useState('#fff')
  const [fontColor, setFontColor] = useState(null)
  const [buttonColor, setButtonColor] = useState(null)
  const [buttonFontColor, setButtonFontColor] = useState(null)
  const [questionErrors, setQuestionErrors] = useAsyncState({})
  const [descriptionFormEnded, setDescriptionFormEnded] = useState('')
  const [isTemplate, setIsTemplate] = useState(false)
  const [showInvestorsPercentage, setShowInvestorsPercentage] = useState(false)
  const [visibilityMode, setVisibilityMode] = useState(false)
  const [confirmRetries, setConfirmRetries] = useState(0)
  const [languages, setLanguages] = useState([])

  const isDraft = () => {
    return queryParams.get('draft') === 'true'
  }

  const isPreview = () => {
    return queryParams.get('preview') === 'true' || isTemplate
  }


  const getActivateUrl = () => {
    return queryParams.get('activateUrl')
  }

  const getForm = () => {
    setLoading(true)

    if (isDraft()) {
      QuestionnairesService.getDraftForm(formId, true).then(result => {
        if (result.data.data) {
          setForm(result.data.data.draft)
          setQuestions(result.data.data.draft.questions)
        } else {
          setFormNotFound(true)
        }
      }).catch(() => {
        setErrorRetrievingForm(true)
      }).finally(() => {
        setLoading(false)
      })
    } else {
      QuestionnairesService.getPublishedForm(formId, true).then(result => {
        if (result.data.data) {
          setForm(result.data.data)
          setQuestions(result.data.data.questions)
        } else {
          setFormNotFound(true)
        }
      }).catch(() => {
        setErrorRetrievingForm(true)
      }).finally(() => {
        setLoading(false)
      })
    }
  }

  const getTemplate = () => {
    setLoading(true)
    QuestionnairesService.getQuestionnairesTemplate(templateId, true)
      .then((response) => {
        if (response.data.data) {
          setForm(response.data.data)
          setQuestions(response.data.data.questions)
        } else {
          setFormNotFound(true)
        }
      })
      .catch(() => {
        addToast(t('error_retrieving_template'), {
          appearance: 'error',
          autoDismiss: true
        })
        setErrorRetrievingForm(true)
      })
      .finally(() => {
        setLoading(false)
        setIsTemplate(true)
      })
  }

  const confirmFinishForm = () => {
    const haveAnswers = Object.keys(answers).filter(key => answers[key] !== null).length > 0

    if (!haveAnswers) {
      alert({ text: t('form_no_answers'), icon: 'error' })
      return
    }

    confirm({ text: t('confirm_submit_form') }).then(isConfirmed => {
      if (isConfirmed) {
        setLoading(true)

        const parsedQuestions = []

        questions.forEach(question => {
          let answer = answers[question.question_id._id]

          if (question.question_id.type === 'questions_group') {
            answer = answers[question.question_id._id] ? answers[question.question_id._id].join('|__|') : [].join('|__|')

            if (answers[question.question_id._id]) {
              answers[question.question_id._id].forEach((q, index) => {
                if (q && (q).constructor.name === 'Object') {
                  answers[question.question_id._id][index] = `${q._id}-|-${q.name}`
                } else {
                  answers[question.question_id._id][index] = q
                }
              })
              answer = answers[question.question_id._id].join('|__|')
            } else {
              answer = [].join('|__|')
            }
          }
          if (question.question_id.type.includes('file')) {
            answer = answers[question.question_id._id] ? answers[question.question_id._id]._id : null
          }

          parsedQuestions.push({
            question: { ...question.question_id },
            answer: `${answer || ''}`.trim()
          })
        })

        QuestionnairesService.saveFormApplication(formId, {
          seconds_lapsed: openDate ? moment().diff(openDate, 'seconds') : null,
          questions: parsedQuestions
        }, true).then(() => {
          setIsSubmitted(true)
          localStorage.removeItem('kiota-questionnaire')
          setLoading(false)
        }).catch(() => {
          addToast(t('error_occurred'), { appearance: 'error', autoDismiss: true })
        }).finally(() => {
          setLoading(false)
          setConfirmRetries(confirmRetries + 1)
        })
      }
    })
  }


  useEffect(() => {
    if (form) {

      const draft = isDraft()
      if (!draft && !isPreview()) {
        EventUtils.sendAnalyticEvent({
          event: 'formVisualized',
          user: null,
          payload: {
            questionnaire: form._id
          }
        }).catch(console.log)
      }

      // save date when form is opened
      setOpenDate(moment())
      // change page title
      document.title = `${form.title || t('form')} | Powered by Kiota`
      // change page favicon
      if (form.favicon) {
        const favicon = document.querySelector('head link[rel="icon"]')
        favicon.setAttribute('href', form.favicon)
      }

      setLanguages(draft ? form?.draft.languages && form.draft.languages.length ? form.draft.languages : form?.languages && form.languages.length ? form.languages : ['en', 'es'] : ['en', 'es'])
      setVisibilityMode(draft ? form?.draft.visibility_mode : form?.visibility_mode || 'multiple_page')
      setDescriptionFormEnded(draft ? form?.draft.custom_ended_message ? form.form_ended_description : null : form?.custom_ended_message ? form.form_ended_description : null)
      setBgColor(draft ? form?.draft.background_color ? form.background_color : '#FFFFFF' : form?.background_color ? form.background_color : '#FFFFFF')
      setFontColor(draft ? form?.draft.font_color ? form.font_color : '#000000' : form?.font_color ? form.font_color : '#000000')
      setButtonColor(draft ? form?.draft.button_color ? form.button_color : '#4D70B3' : form?.button_color ? form.button_color : '#4D70B3')
      setButtonFontColor(draft ? form?.draft.button_font_color ? form.button_font_color : '#FFFFFF' : form?.button_font_color ? form.button_font_color : '#FFFFFF')
    }
  }, [form])


  useEffect(() => {
    if (formId === '63ff516559b78229fe30d534') {
      const tagManagerArgs = {
        gtmId: 'GTM-PMQ2P55'
      }
      TagManager.initialize(tagManagerArgs)
    }
    formId && getForm()
  }, [formId])

  useEffect(() => {
    templateId && getTemplate()
  }, [templateId])

  useEffect(() => {
    if (!isDraft() && !isPreview() && form) {
      EventUtils.sendAnalyticEvent({
        event: 'formStarted',
        user: null,
        payload: {
          questionnaire: form._id
        }
      }).catch(console.log)
    }
  }, [form])

  return (
    <>
      {fontColor && <style>{`.form-container, .form-container h1 { color: ${fontColor} !important; } .form-container .question-container input, .form-container .question-container textarea, .form-container .question-container .questionnaire-select, .form-container .question-container .questionnaire-select button { color: #000 !important }`}</style>}
      {buttonColor && <style>{`.form-container button { background-color: ${buttonColor} !important; }  .form-container ul li button { background-color: #fff !important; } .form-container svg { color: ${buttonColor} !important; }`}</style>}
      {buttonFontColor && <style>{`.form-container button svg { color: ${buttonFontColor} !important; } .form-container button { color: ${buttonFontColor} !important; } `}</style>}

      {((formNotFound || errorRetrievingForm) && !loading) && (
        <Card
          wrapperClassName={`bg-main-${Config.PLATFORM} mt-24 max-w-screen-sm mx-auto`}
          containerClassName={`text-center text-lg my-6 text-main-${Config.PLATFORM}`}
        >
          <div>
            <FaExclamationCircle
              className="inline-block mb-6"
              size={70}
            />
          </div>
          <p>
            {formNotFound && t('public_form_not_found')}
            {errorRetrievingForm && t('error_occurred_retrieving_form')}
          </p>
        </Card>
      )}

      {form && ((!errorRetrievingForm && !formNotFound && form) || (isPreview() || isDraft())) && (
        <div
          style={{
            backgroundColor: bgColor,
            backgroundImage: `radial-gradient(${Utils.saturateColor(bgColor, 0.7)} 0.5px, transparent 0.5px)`,
            backgroundSize: 'calc(10 * 1px) calc(10 * 1px)'
          }}
          className="h-screen w-full flex flex-col justify-center overflow-hidden form-container"
        >
          {getActivateUrl() && (
            <div className='max-w-screen-sm my-0 mx-auto bg-gray-300 p-8 rounded-2xl w-full flex items-center justify-center'>
              <Button
                className='m-0 w-auto mr-3 bg-gray-500 hover:bg-gray-600 text-white'
                horizontal='6'
                verticalMargin='0'
                vertical='3'
                marginRight='2'
                text={t('activate_account')}
                onClick={() => history.push(getActivateUrl())}
              />
            </div>
          )}

          {/*<div className="w-full">
            <div className='max-w-screen-sm mx-auto'>
              <div className='my-6'>
                <h1 className={`text-main-${Config.PLATFORM} text-center text-3xl`}>
                  {form.title || t('application')}
                </h1>
              </div>
            </div>
          </div>*/}

          {questions.length > 0 && (
            <PublicQuestionnaire
              isSubmitted={isSubmitted}
              questions={questions}
              setLoading={setLoading}
              answers={answers}
              setAnswers={setAnswers}
              questionErrors={questionErrors}
              setQuestionErrors={setQuestionErrors}
              confirmFinishForm={confirmFinishForm}
              fontColor={fontColor}
              buttonColor={buttonColor}
              isDraft={isDraft()}
              isPreview={isPreview()}
              questionnaireId={formId}
              descriptionFormEnded={descriptionFormEnded}
              loading={loading}
              setIsSubmitted={setIsSubmitted}
              visibilityMode={visibilityMode}
              showInvestorsPercentage={form.show_investors_percentage}
              confirmRetries={confirmRetries}
              languages={languages}
              investorId={form.investor._id}
              formId={form._id}
            />
          )}

          <div
            style={{
              color: Utils.saturateColor(bgColor, 0.5)
            }}
            className={`absolute inset-x-0 bottom-2 text-center text-xs border-t border-main-${Config.PLATFORM} max-w-screen-sm mx-auto justify-center flex flex-col`}>
            <span className={`mt-3 block text-main-${Config.PLATFORM}`}>
              Powered by&nbsp;
              <a
                className={`text-main-${Config.PLATFORM}`}
                href="https://kiota.com"
                target="_blank" rel="noreferrer">
                Kiota
              </a>
            </span>
            <a
              href="https://kiota.com"
              target="_blank"
              className="inline-block mt-1" rel="noreferrer"
            >
              <img className="m-auto" src={Logo} alt="Logo Kiota" width={40} />
            </a>
          </div>
        </div>
      )}

      {loading && <Loader />}
    </>
  )
}
