/* eslint-disable default-case */
import produce from 'immer'

import { ProjectActionsTypes } from './project-actions-types'
import { UserActionsTypes } from 'redux/user'

const { SET_FILTERS, SET_PAGINATION, SET_SORT, RESET } = ProjectActionsTypes
const { RESET: LOGOUT } = UserActionsTypes

const defaultState = {
  filters: {
    name: '',
    sector: null,
    country: null,
    city: null,
    status: null,
    selectedDateType: 'custom',
    selectedDate: [null, null],
    score: [0, 0],
    sortBy: '-createdAt',
    onlyFavourite: false,
    onlyArchived: false,
    onlyInvested: false
  },
  pagination: {
    page: 0,
    rowsPerPage: 12
  }
}

const ProjectReducer = (state = defaultState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_FILTERS:
        draft.filters = {
          ...action.payload.data.filters
        }
        break
      case SET_PAGINATION:
        draft.pagination = action.payload.data
        break
      case SET_SORT:
        draft.filters = {
          ...action.payload.data
        }
        break
      case RESET:
      case LOGOUT:
        return defaultState
    }
  })

export default ProjectReducer
