import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import useUser from 'hooks/useUser'
import { Loader } from 'components/ui/molecules/Loader'
import CompanyService from 'services/company-service'
import Select from '../ui/atoms/Select'
import Config from 'config'
import { Card } from 'components/ui/atoms/Card'

export function CompanySettings () {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { user, reloadUserInfo } = useUser()
  const [loading, setLoading] = useState(false)
  const [listForStartups, setListForStartups] = useState(!!user.investor.list_for_startups)

  useEffect(() =>{
    setListForStartups(!!user.investor.list_for_startups)
  }, [user?.investor])

  const updateCompanyInfo = (value) => {
    setLoading(true)
    CompanyService.updateCompanyInfo({ list_for_startups: !!value })
      .then(() => reloadUserInfo())
      .then(() => {
        addToast(t('company_info_updated_successfully'), {
          appearance: 'success',
          autoDismiss: true
        })
      })
      .catch(error => {
        addToast(
          error.response && error.response.data.msg_key
            ? t(error.response.data.msg_key)
            : t('error_occurred_updating_company_info'),
          {
            appearance: 'error',
            autoDismiss: true
          }
        )
      }).finally(() => {
        setLoading(false)
      })
  }

  return (
    <Card>
    <div>
      <h3 className={`border-b font-medium text-main-${Config.PLATFORM} font-bold mb-4 border-main-${Config.PLATFORM}`}>
        {t('visibility')}
      </h3>
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-start sm:justify-between">
        <div>
          <small className="text-gray-dark font-light">
            {t('list_for_startups')}
          </small>
        </div>
        <div className="flex flex-row">
          <Select
            isClearable={false}
            name='list_for_startups'
            onSelect={selection => updateCompanyInfo(selection[0].id)}
            initialValues={listForStartups ? [{ id: 1, value: t('yes') }] : [{ id: 0, value: t('no') }]}
            items={[
              { id: 1, value: t('yes') },
              { id: 0, value: t('no') }
            ]}
          />
        </div>
      </div>
      {loading && <Loader />}
    </div>
    </Card>
  )
}
