import React, { useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import { Loader } from 'components/ui/molecules/Loader'
import { PageTitle } from '../ui/atoms/PageTitle'
import FormUtils from '../../utils/form-utils'
import { useHistory, useParams } from 'react-router-dom'
import { DealflowService } from '../../services/dealflow-service'
import ProjectCreateOrUpdate from '../edit-startup/components/ProjectCreateOrUpdate'

const DeaflowForm = () => {
  const { addToast } = useToasts()
  const { t } = useTranslation()
  const { dealId } = useParams()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [deal, setDeal] = useState(null)
  const [investments, setInvestments] = useState([])

  const onSubmit = (values) => {
    setLoading(true)

    const showError = error => {
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t('error_occurred_updating_startup'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    }

    const startupData = {
      ...values,
      creation_date: FormUtils.parseDateFromString(values.creation_date)
    }

    DealflowService.updateDeal(dealId, startupData)
      .then(() => getDeal())
      .then(() => addToast(t('project_updated'), { appearance: 'success', autoDismiss: true }))
      .catch(error => showError(error))
      .finally(() => setLoading(false))
  }

  const getDeal = () => {
    return DealflowService.getDeal(dealId).then((result) => {
      setDeal(result.data || {})
    }).catch(() => {
      addToast(t('error_occurred_retrieving_dealflow'), { appearance: 'error', autoDismiss: true })
      history.goBack()
    })
  }

  const getInvestments = async () => {
    try {
      setLoading(true)
      const result = await DealflowService.getInvestments(dealId)
      setInvestments(result.data)
    } catch (error) {
      addToast(t('error_occurred_retrieving_dealflow'), { appearance: 'error', autoDismiss: true })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (dealId) {
      setLoading(true)
      getDeal().finally(() => setLoading(false))
      getInvestments()
    }
  }, [dealId])

  return (
    <>
      <PageTitle
        showBack={true}
        title={t(!deal ? 'loading' : 'update_startup')}
      />

      {deal && (
        <ProjectCreateOrUpdate
          info={deal}
          onSubmit={onSubmit}
          onChangeStartupMembers={getDeal}
          onChangeCaptableShareholders={getDeal}
          onChangeCompetitors={getDeal}
          onChangeInvestments={getInvestments}
          investments={investments}
          isDealflow={true}
        />
      )}

      {loading && <Loader />}
    </>
  )
}

export default DeaflowForm
