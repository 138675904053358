import { useHistory, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { RoutesLinks } from 'components/routes-links'

import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import { FaEye } from 'react-icons/fa'
import { ButtonCardMain } from 'components/ui/atoms/ButtonCardMain'
import Config from 'config'
import moment from 'moment'
import { useEffect, useState } from 'react'
import FormAnswersSection from 'components/qe-forms/components/forms-form/FormAnswersSection'
import SupportService from 'services/support-service'
import { useToasts } from 'react-toast-notifications'
import { Loader } from 'components/ui/molecules/Loader'
import FormUtils from 'utils/form-utils'
import useUser from 'hooks/useUser'
import { Card } from 'components/ui/atoms/Card'

const DealflowForms = ({ deal }) => {
  const { t } = useTranslation()
  const [selectedForm, setSelectedForm] = useState(null)
  const [answerToExpand, setAnswerToExpand] = useState(null)
  const [loading, setLoading] = useState(false)
  const { addToast } = useToasts()
  const [info, setInfo] = useState(null)
  const { userLanguage } = useUser()

  const downloadDocument = async (documentId) => {
    try {
      setLoading(true)
      let result = null
      result = await SupportService.getDocumentForForm(documentId, false)
      if (result?.data?.url) {
        window.open(result.data.url, '_blank')
      }
    } catch (error) {
      addToast(t(error?.response?.data?.msg_key || 'error_occurred_downloading_document'), {
        appearance: 'error',
        autoDismiss: true
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (info?.application) {
      const parsedQuestions = FormUtils.getParsedQuestions(info.application.questions.map((q) => { return { question_id: q.question }}))
      const answers = FormUtils.getParsedAnswers(parsedQuestions, [info], t, userLanguage, downloadDocument, false)

      setAnswerToExpand({ answers: answers[0], questions: parsedQuestions })
    }
  }, [info])

  const getForm = async () => {
    try {
      setLoading(true)
      const result = await SupportService.getEventStartup(selectedForm, deal._id)
      setInfo(result.data.data)
    } catch (error) {
      addToast(t('error_occurred_retrieving_information'), {
        appearance: 'error',
        autoDismiss: true
      })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (selectedForm) {
      getForm()
    }
  }, [selectedForm])

  return (
    <>
      {loading && <Loader />}
      {answerToExpand && (
        <FormAnswersSection
          answer={answerToExpand}
          onClose={() => {
            setInfo(null)
            setAnswerToExpand(null)
            setSelectedForm(null)
          }}
        />
      )}
      {(!deal?.forms || deal?.forms?.length === 0) && <div className='my-6'><NoDataInfo title={t('dealflow_without_forms')} /></div>}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 my-10">
        {deal?.forms?.length > 0 && (
          deal.forms.map((form, index) => {
            return (
              <Card key={form._id}>
                  <h5 className={`uppercase font-bold text-sm leading-none text-main-${Config.PLATFORM} truncate mb-1`}>
                    {form.form.name}
                  </h5>
                  <div className='text-xs'>{moment(form.date).format('YYYY-MM-DD')}</div>

                  <div className={`flex justify-end mt-4 pt-2 border-t border-separator-${Config.PLATFORM}`}>
                    <ButtonCardMain
                      onClick={() => setSelectedForm(form.form._id)}
                      text={t('see_answers')}
                      iconComponent={<FaEye className="inline-block w-6 h-3" />}
                    />
                  </div>
              </Card>
            )
          })
        )}
      </div>
    </>
  )
}

export default DealflowForms
