import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import Settings from '../settings'
import Support from '../support'
import Emails from '../emails';

import { SectionHeader } from 'components/ui/molecules/SectionHeader'
import AccountPersonalInfo from '../personal-info/AccountPersonalInfo'
import { PageTitle } from '../../../ui/atoms/PageTitle'
import AccountSecurity from '../security/AccountSecurity'
import Config from 'config'

const InvestorScope = () => {
  const { t } = useTranslation()

  const SECTIONS = Config.PLATFORM === '000' ? [
    'personal_information',
    'settings',
    'security',
    'support',
    'email'
  ] : [
    'personal_information',
    'settings',
    'security'
  ]

  const components = {
    personal_information: <AccountPersonalInfo />,
    security: <AccountSecurity />,
    settings: <Settings />,
    support: <Support />,
    email: <Emails />
  }

  const [section, setSection] = useState('personal_information')

  return (
    <>
      <PageTitle title={t('my_account')} />

      <SectionHeader
        sectionTitles={SECTIONS.map((sec) => t(sec))}
        sectionKeys={SECTIONS}
        section={section}
        setSection={(sec) => {
          setSection(sec)
        }}
      />
      <div className="flex flex-col w-full my-6">
          {components[section]}
      </div>
    </>
  )
}

export default InvestorScope
