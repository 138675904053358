import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Loader } from '../ui/molecules/Loader'
import { StartupGeneralInformation } from '../startup-profile/StartupGeneralInformation'
import { Card } from 'components/ui/atoms/Card'
import { FaExclamationCircle, FaEye } from 'react-icons/fa'
import { DealflowService } from '../../services/dealflow-service'
import Select from '../ui/atoms/Select'
import ReactTooltip from 'react-tooltip'
import { SectionHeader } from '../ui/molecules/SectionHeader'
import { RequestProfileVisibility } from '../startup-profile/components/RequestProfileVisibility'
import { StartupKPIs } from '../startup-profile/components/StartupKPIs'
import { AugmentedInformation } from '../startup-profile/components/AugmentedInformation'
import { ApplicationQuestionnaireView } from '../ui/objects/ApplicationQuestionnaireView'
import { SharedEventHeader } from '../events/components/event/components/SharedEventHeader'
import ProjectsService from '../../services/projects-service'
import useUser from '../../hooks/useUser'
import { useToasts } from 'react-toast-notifications'
import { SharedEventFooter } from 'components/events/components/event/components/SharedEventFooter'
import SupportService from 'services/support-service'
import { Alert } from 'components/ui/molecules/Alert'
import { Pagination } from 'components/ui/atoms/Pagination'
import Avatar from 'react-avatar'
import { Button } from 'components/ui/atoms/Button'
import moment from 'moment'
import { PageTitle } from 'components/ui/atoms/PageTitle'
import Config from 'config'
import { ButtonCardMain } from 'components/ui/atoms/ButtonCardMain'
import UnauthorizedKmZeroReport from 'components/ui/objects/UnauthorizedKmZeroReport'
import QuestionnairesService from 'services/questionnaires-service'
import { usePagination } from 'hooks/usePagination'
import { FormAnswers } from 'components/qe-forms/components/forms-form/FormAnswers'

const EMAILS = {
  'cestrada@kiota.com': 'Cristian Estrada',
  'nromero@kmzerohub.com': 'Nora Romero',
  'jrequena@kmzerohub.com': 'Juan Requena',
  'szhu@kmzerohub.com': 'Sophia Zhu',
  'pablo.lamsfus@korosti.es': 'Pablo Lamsfus',
  'marta.laorden@mcwin.fund': 'MARA LAORDEN',
  'jdeantonio@ship2bventures.com': 'J DE ANTONIO',
  'alava@clave.capital': 'ALAVA',
  'akulisic@thefoodtechlab.com': 'Akulisic',
  'da@unovis.vc': 'DA UNOVIS',
  'juancividanesroger@thefoodtechlab.com': 'Juan Cividanes',
  'giancarlo@fiveseasons.vc': 'Giancarlo',
  'olaf@zintinus.com': 'Olaf',
  'brian@collidecap.com': 'Brian',
  'julia.brinkmann@collateralgoodventures.com': 'Julia Brinkmann',
  'aaraneda@fenventures.com': 'aaraneda',
  'yossi.halevy@gmail.com': 'yossi',
  'noramay@supplychange.fund': 'normay',
  'neha@goodstartup.com': 'nega',
  'edgarp@iadb.org': 'edgarp',
  'andrew@agfunder.com': 'ANDREW',
  'sam@acre.vc': 'SAM',
  'micol@planet.fund': 'MICOL',
  'jovana.ruzic@collidecap.com': 'JOVANA RUZIC',
  'shayna@supplychange.fund': 'SHAYNA SUPPLYCHANGE',
  'ystrachan@iadb.org': 'YSTRACHAN IADB',
  'anamen@iadb.org': 'Anamen IADB'
}

export const KmZeroReportVC = () => {
  const wrapperElement = useRef(null)
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)
  const [deal, setDeal] = useState(null)
  const [section, setSection] = useState('startups')
  const [sections, setSections] = useState(['startups'])
  const [authorized, setAuthorized] = useState(false)
  const [email, setEmail] = useState(localStorage.getItem('X-KMZ-VC-REP-EMAIL'))
  const [sharedDealflow, setSharedDealflow] = useState([]);
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [totalStartups, setTotalStartups] = useState(0)
  const [dealInfo, setDealInfo] = useState(null)
  const [formAnswers, setFormAnswers] = useState([])
  const [questionnaire, setQuestionnaire] = useState(null)
  const [board, setBoard] = useState(null)

  const {
    setTotalPages,
    totalPages,
    setPage,
    page,
    rowsPerPage,
    setRowsPerPage
  } = usePagination(12)

  useEffect(() => {
    const checkAccess = async () => {
      if (EMAILS[email]) {
        setAuthorized(true)
      } else {
        addToast(t('forbiden_access_to_report'), {
          appearance: 'error',
          autoDismiss: true
        })
      }
    }

    if (email) {
      localStorage.setItem('X-KMZ-VC-REP-EMAIL', email)
      checkAccess()
    }
  }, [email])



  useEffect(() => {
    if (wrapperElement.current) {
      wrapperElement.current.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }, [])

  const getForm = async () => {
    setLoading(true)
    return QuestionnairesService.getQuestionnairesForm('6423021a10f9e261a95ec61f', true)
      .then((response) => {
        setQuestionnaire(response.data.data)
      })
      .catch(() => {
        addToast(t('error_retrieving_form'), {
          appearance: 'error',
          autoDismiss: true
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getQuestionnaireAnswers = () => {
    setLoading(true)
    QuestionnairesService.getQuestionnaireAnswers('6423021a10f9e261a95ec61f', {
      rowsPerPage,
      page,
      sortBy: '-createdAt'
    }, true)
      .then((result) => {
        const { applications, pagination } = result.data
        setTotalStartups(pagination.totalDocs)
        setFormAnswers(applications)
        setTotalPages(pagination.pages)
      })
      .catch(() => {
        addToast(t('error_retrieving_startups'), {
          appearance: 'error',
          autoDismiss: true
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (authorized) {
      getForm()
      getQuestionnaireAnswers()
    }
  }, [authorized])

  useEffect(() => {
    if (questionnaire) {
      getQuestionnaireAnswers()
    }
  }, [questionnaire, page, rowsPerPage])

  if (!authorized) {
    return <>
      {loading && <Loader />}
      <UnauthorizedKmZeroReport loading={loading} onRequestAccess={setEmail} description={t('access_control_description_report')} buttonText={t('access_report')} />
    </>
  }

  return (
    <div className="flex flex-col min-h-screen" ref={wrapperElement}>
      <section className="px-8 my-8 flex-1">
        <PageTitle title={'Reporte Segunda Convocatoria KM ZERO'} />
        <div className="flex items-start">
          <div className="flex-1 mb-6">
            <SectionHeader
              sectionTitles={sections.map(section => t(section))}
              sectionKeys={sections}
              section={section}
              setSection={setSection}
            />
          </div>
        </div>

        <div className={`text-2xl font-semibold text-secondary-${Config.PLATFORM}`}>{t('total_startups_applied_currently', { startups: totalStartups })}</div>
        {section === 'startups' && (
          <FormAnswers
            questions={questionnaire ? questionnaire.questions : []}
            answers={formAnswers}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            totalPages={totalPages}
            setRowsPerPage={setRowsPerPage}
            shared={true}
          />
        )}
      </section>
      <SharedEventFooter />
      {loading && <Loader />}
    </div>
  )
}
