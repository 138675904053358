import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Loader } from 'components/ui/molecules/Loader'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import { Pagination } from 'components/ui/atoms/Pagination'
import { Button } from 'components/ui/atoms/Button'
import { isEqual } from 'lodash'
import { RoutesLinks } from 'components/routes-links'
import SupportService from 'services/support-service'
import { ScoreCardsOperations } from 'redux/score-cards'
import { ScoreCardBox } from 'components/ui/molecules/ScoreCardBox'
import { Card } from 'components/ui/atoms/Card'
import { PageTitle } from '../ui/atoms/PageTitle'
import { SectionHeader } from 'components/ui/molecules/SectionHeader'
import Config from 'config';
import { FaPlus, FaPlusCircle } from 'react-icons/fa'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

const ScoreCards = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const myRef = useRef(null)
  const { addToast } = useToasts()
  const [scoreCards, setScoreCards] = useState([])
  const [loading, setLoading] = useState(false)
  const [sort] = useState('-createdAt')
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 10,
    totalPages: 0,
    totalDocs: 0
  })

  const SECTIONS = ['evaluation_processes', 'score_cards']

  const ROUTES = {
    score_cards: RoutesLinks.SCORE_CARDS_LINK,
    evaluation_processes: RoutesLinks.EVALUATION_PROCESSES_LINK
  }

  const getScoreCards = async () => {
    try {
      setLoading(true)

      const queryParams = {
        page: pagination.page,
        rowsPerPage: pagination.rowsPerPage,
        sortBy: sort
      }

      const result = await SupportService.getScoreCards(queryParams)

      setScoreCards(result.data.data.score_cards)
      if (result.data.data.pagination) {
        const newPagination = {
          ...pagination,
          totalPages: result.data.data.pagination.pages,
          totalDocs: result.data.data.pagination.totalDocs
        }

        if (!isEqual(newPagination, pagination)) {
          setPagination(newPagination)
        }
      }
    } catch (error) {
      addToast(t('error_occurred_retrieving_score_cards'), {
        appearance: 'error',
        autoDismiss: true
      })
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getScoreCards()
    myRef.current.scrollIntoView()
  }, [pagination.page, pagination.rowsPerPage, sort])

  return (
    <div ref={myRef}> 
      <div className='flex items-start'>
        <div className='flex-1'>
          <PageTitle
            title={t('score_cards')}
          />
        </div>

        <ButtonMain
          iconComponent={<FaPlus className='inline-block mr-2' />}
          text={t('create_score_card')}
          onClick={() => history.push(`${RoutesLinks.SCORE_CARDS_LINK}wizard`)}
          />
      </div>

      <SectionHeader
        section={'score_cards'}
        setSection={(sec) => {
          history.push(ROUTES[sec])
        }}
        sectionKeys={SECTIONS}
        sectionTitles={SECTIONS.map((s) => t(s))}
      />

      {scoreCards.length > 0 && (
        <>
          <div className='mt-6 grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4'>
            {scoreCards.map((scoreCard, index) => (
              <ScoreCardBox
                key={index}
                onClick={() => history.push(`${RoutesLinks.SCORE_CARDS_LINK}${scoreCard._id}`)}
                criteria={scoreCard.criteria}
                name={scoreCard.name}
                description={scoreCard.description}
                scale={scoreCard.scale}
                generalScore={scoreCard.general_score}
                generalScoreScale={scoreCard.general_score_scale}
              />
            ))}
          </div>

          <Pagination
            currentPage={pagination.page}
            setCurrentPage={(page) => {
              setPagination({
                ...pagination,
                page
              })
            }}
            perPage={pagination.rowsPerPage}
            pages={pagination.totalPages}
            setPerPage={(value) => {
              setPagination({
                ...pagination,
                page: 0,
                rowsPerPage: value[0].id
              })
            }}
          />
        </>
      )}

      {!loading && !pagination.totalDocs && (
        <div className="mt-6">
          <NoDataInfo
            title={t('score_cards_not_found')}
            textOne={t('score_cards_not_found_description')}
          />
        </div>
      )}

      {loading && <Loader />}
    </div>
  )
}

export default ScoreCards
