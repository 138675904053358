import { useCallback, useState, useEffect } from 'react'
import { SectionHeader } from 'components/ui/molecules/SectionHeader'
import { useTranslation } from 'react-i18next'
import { Loader } from 'components/ui/molecules/Loader'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import { useToasts } from 'react-toast-notifications'
import SupportService from 'services/support-service'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Details from 'styles/images/details_blue.svg'
import { useHistory } from 'react-router-dom'
import { RoutesLinks } from 'components/routes-links'

const SupportTickets = () => {
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const [tickets, setTickets] = useState([])
  const history = useHistory()

  const handleRedirect = (url) => history.push(url)

  const getSupportTickets = useCallback(async () => {
    try {
      setLoading(true)
      const result = await SupportService.getSupportTickets()
      setTickets(result.data.data)
    } catch (error) {
      console.log(error)
      addToast(
        error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t('error_occurred'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    } finally {
      setLoading(false)
    }
  })

  useEffect(() => {
    getSupportTickets()
  }, [])

  const ROUTES = {
    tickets: RoutesLinks.SUPPORT_TICKETS_LINK,
    nps_section: RoutesLinks.SUPPORT_NPS_LINK,
    requested_features: RoutesLinks.SUPPORT_REQUESTED_FEATURES_LINK
  }

  return (
    <>
      <SectionHeader
        setSection={(sec) => {
          handleRedirect(ROUTES[sec])
        }}
        sectionTitles={[
          t('tickets'),
          t('nps_section'),
          t('requested_features')
        ]}
        sectionKeys={['tickets', 'nps_section', 'requested_features']}
        section={'tickets'}
        showAdd={false}
        showFilters={false}
      />

      <div className={'mt-10'}>
        {!loading &&
          tickets.map((ticket, index) => {
            return (
              <div
                className={'flex w-full flex-col rounded-2xl my-4'}
                key={index}
              >
                <div
                  className={'w-full  | flex flex-row flex-grow rounded-2xl justify-start items-center transition-all duration-400 ease-in-out bg-white shadow-soft-white p-4'}
                >
                  <div className={'flex flex-col w-full pr-4'}>
                    <div className={'text-xs'}>
                      <FontAwesomeIcon
                        className=" text-sm mr-1 cursor-pointer"
                        icon="user"
                        onClick={() => {
                          navigator.clipboard.writeText(ticket.user.name)
                          addToast(t('name_copied_to_clipboard'), {
                            appearance: 'success',
                            autoDismiss: true
                          })
                        }}
                      />
                      {`${ticket.user.name} ${ticket.user.last_name}`}
                      <FontAwesomeIcon
                        className=" text-sm ml-2 mr-1 cursor-pointer"
                        icon="envelope"
                        onClick={() => {
                          navigator.clipboard.writeText(ticket.user.email)
                          addToast(t('email_copied_to_clipboard'), {
                            appearance: 'success',
                            autoDismiss: true
                          })
                        }}
                      />
                      {ticket.user.email}
                    </div>
                    <div className={'font-semibold text-xs mt-2 mb-1'}>
                      {`${t('message')}:`}
                    </div>
                    <div className={'text-xs'}>{ticket.message}</div>
                    <div className={'font-semibold text-xs mt-2 mb-1'}>
                      {`${t('chronology')}:`}
                    </div>
                    <div className={'text-xs'}>
                      <FontAwesomeIcon
                        className=" text-sm"
                        icon="inbox"
                      />
                      <span className={'ml-1 text-xs'}>
                            {moment(ticket.createdAt).format(
                              'YYYY-MM-DD HH:MM'
                            )}
                          </span>
                      <FontAwesomeIcon
                        className=" text-sm ml-2"
                        icon="eye"
                      />
                      <span className={'ml-1 text-xs'}>
                            {ticket.review_date
                              ? moment(ticket.review_date).format(
                                'YYYY-MM-DD HH:MM'
                              )
                              : '-'}
                          </span>
                      <FontAwesomeIcon
                        className=" text-sm ml-2"
                        icon="reply"
                      />
                      <span className={'ml-1 text-xs'}>
                            {ticket.reply_date
                              ? moment(ticket.reply_date).format(
                                'YYYY-MM-DD HH:MM'
                              )
                              : '-'}
                          </span>
                      <FontAwesomeIcon
                        className=" text-sm ml-2"
                        icon="check"
                      />
                      <span className={'ml-1 text-xs'}>
                            {ticket.close_date
                              ? moment(ticket.close_date).format(
                                'YYYY-MM-DD HH:MM'
                              )
                              : '-'}
                          </span>
                      <FontAwesomeIcon
                        className=" text-sm ml-2"
                        icon="check-double"
                      />
                      <span className={'ml-1 text-xs'}>
                            {ticket.confirmation_date
                              ? moment(ticket.confirmation_date).format(
                                'YYYY-MM-DD HH:MM'
                              )
                              : ' -'}
                          </span>
                    </div>
                    <div className={'font-semibold text-xs mt-2 mb-1'}>
                      {`${t('others')}:`}
                    </div>
                    <div className={'text-xs'}>
                      <FontAwesomeIcon
                        className=" text-sm"
                        icon="bullseye"
                      />
                      <span className={'ml-1 text-xs'}>
                            {moment(ticket.createdAt).format(
                              'YYYY-MM-DD HH:MM'
                            )}
                          </span>
                      <FontAwesomeIcon
                        className=" text-sm ml-2"
                        icon="sync"
                      />
                      <span className={'ml-1 text-xs'}>
                            {ticket.confirmation_date
                              ? moment(ticket.confirmation_date).format(
                                'YYYY-MM-DD HH:MM'
                              )
                              : ' -'}
                          </span>
                    </div>
                  </div>
                  <div className={'flex flex-col justify-start'}>
                    <img
                      src={Details}
                      alt="Open Support Ticket"
                      className="inline-block cursor-pointer w-10 mr-2"
                      onClick={() => console.log('===')}
                    />
                  </div>
                </div>
              </div>
            )
          })}
        {!loading && !tickets.length && (
          <NoDataInfo title={t('no_support_tickets_found')} />
        )}
      </div>
      {loading && <Loader />}
    </>
  )
}

export default SupportTickets
