import { useTranslation } from 'react-i18next'
import { Input } from '../../../ui/atoms/Input'
import { Button } from '../../../ui/atoms/Button'
import AccountService from '../../../../services/account-service'
import { useToasts } from 'react-toast-notifications'
import useUser from '../../../../hooks/useUser'
import { Card } from '../../../ui/atoms/Card'
import * as Yup from 'yup'
import FormUtils from '../../../../utils/form-utils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { FaGithub, FaGitlab, FaLinkedin, FaMedium, FaTwitter, FaYoutube } from 'react-icons/all'
import { Loader } from '../../../ui/molecules/Loader'
import Config from 'config';
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

const AccountSocialNetworks = () => {
  const { t } = useTranslation()
  const { user, reloadUserInfo } = useUser()
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)
  const socialNetworksItems = [
    {
      name: 'linkedin',
      title: 'Linkedin',
      icon: <FaLinkedin className={`text-main-${Config.PLATFORM} mr-1 relative -bottom-[2px]`} />,
      placeholder: 'https://www.linkedin.com/in/johndoe/'
    },
    {
      name: 'twitter',
      title: 'Twitter',
      icon: <FaTwitter className={`text-main-${Config.PLATFORM} mr-1 relative -bottom-[2px]`} />,
      placeholder: 'https://twitter.com/johndoe'
    },
    {
      name: 'youtube',
      title: 'YouTube',
      icon: <FaYoutube className={`text-main-${Config.PLATFORM} mr-1 relative -bottom-[2px]`} />,
      placeholder: 'https://www.youtube.com/user/johndoe'
    },
    {
      name: 'medium',
      title: 'Medium',
      icon: <FaMedium className={`text-main-${Config.PLATFORM} mr-1 relative -bottom-[2px]`} />,
      placeholder: 'https://medium.com/@johndoe'
    },
    {
      name: 'github',
      title: 'GitHub',
      icon: <FaGithub className={`text-main-${Config.PLATFORM} mr-1 relative -bottom-[2px]`} />,
      placeholder: 'https://github.com/johndoe'
    },
    {
      name: 'gitlab',
      title: 'GitLab',
      icon: <FaGitlab className={`text-main-${Config.PLATFORM} mr-1 relative -bottom-[2px]`} />,
      placeholder: 'https://gitlab.com/johndoe'
    }
  ]
  const validationShape = socialNetworksItems.reduce((acc, item) => {
    acc[item.name] = Yup.string()
      .transform(value => !value ? null : value)
      .matches(FormUtils.URL_REGEX, t('incorrect_format'))
      .nullable()
    return acc
  }, {})

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    trigger
  } = useForm({
    resolver: yupResolver(Yup.object().shape(validationShape), { abortEarly: false }),
    criteriaMode: 'all',
    reValidateMode: 'all',
    mode: 'all'
  })

  const updateSocialNetworks = (values) => {
    setLoading(true)
    AccountService.updateSocialNetworks(values)
      .then(() => reloadUserInfo())
      .then(() => {
        addToast(t('social_networks_updated_successfully'), {
          appearance: 'success',
          autoDismiss: true
        })
      })
      .catch((error) => {
        addToast(
          error.response && error.response.data.msg
            ? error.response.data.msg
            : t('error_occurred_updating_social_networks'),
          {
            appearance: 'error',
            autoDismiss: true
          }
        )
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (user && user.social_networks) {
      socialNetworksItems.forEach(item => setValue(item.name, user.social_networks[item.name]))
      trigger()
    }
  }, [user])

  return (
    <>
      <Card containerClassName="flex flex-col lg:flex-row">
        <div className="lg:w-1/3 w-full lg:pr-8 flex">
          <div>
            <h3 className={`font-medium text-base text-main-${Config.PLATFORM} font-bold`}>
              {t('social_networks')}
            </h3>
            <p className={`font-medium lg:mr-2 text-xs text-main-${Config.PLATFORM}`}>
              {t('social_networks_subtitle')}
            </p>
            {/*<p className="text-gray text-xs mt-3 leading-4 font-regular">
              {t('social_networks_text')}
            </p>
            <p className="text-gray text-xs mt-3 leading-4 font-regular">
              {t('social_networks_text_two')}
            </p>*/}
          </div>
        </div>
        <div className="lg:w-2/3 w-full flex items-center lg:pr-8">
          <form onSubmit={handleSubmit(updateSocialNetworks)} className="w-full mt-4">
            {socialNetworksItems.map((item) => (
              <div className="w-full lg:px-2" key={item.name}>
                <Input
                  type="text"
                  label={<>{item.icon} {item.title}</>}
                  reference={register}
                  name={item.name}
                  error={errors[item.name]}
                  placeholder={item.placeholder}
                />
              </div>
            ))}

            <div className='mt-4 flex justify-end'>
              <ButtonMain
                type="submit"
                text={t('save')}
                width={'full lg:w-1/2'}
              />
            </div>
          </form>
        </div>
      </Card>

      {loading && <Loader />}
    </>
  )
}

export default AccountSocialNetworks
