import Language from './language'
import DealflowViewMode from './DealflowViewMode'
import RemindersNotifications from './RemindersNotifications'
import useUser from 'hooks/useUser'

const Settings = () => {
  const { isInvestor } = useUser()
  return (
    <>
      <Language />
      {/*isInvestor && <RemindersNotifications />*/}
      {isInvestor && <DealflowViewMode />}
    </>
  )
}

export default Settings
