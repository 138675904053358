import { useTranslation } from 'react-i18next'
import { Button } from 'components/ui/atoms/Button'
import { SidePanel } from 'components/ui/objects/SidePanel'
import { useEffect } from 'react'
import { Input } from 'components/ui/atoms/Input'
import Config from 'config';

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import useUser from 'hooks/useUser'
import { ButtonDanger } from 'components/ui/atoms/ButtonDanger'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

const TeammateSection = ({ onClose, teammate, onSubmit, removeTeammate }) => {
  const { t } = useTranslation()

  const { user } = useUser();

  const isMainUser = () => {
    const mainUser = user.investor.users.find((teammate) => teammate._id === user.investor.main_contact_id)
    return mainUser.user === user._id
  }

  const TeammateSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('incorrect_format'))
      .required(t('required_field')),
    name: Yup.string().required(t('required_field')),
    last_name: Yup.string().required(t('required_field'))
  })

  const { register, errors, setValue, trigger, getValues } = useForm({
    resolver: yupResolver(TeammateSchema),
    criteriaMode: 'all',
    reValidateMode: 'all',
    mode: 'onChange'
  })

  useEffect(() => {
    if (teammate && Object.keys(teammate).length) {
      setValue('email', teammate?.user?.email || teammate.email, { shouldDirty: true })
      setValue('name', teammate?.user?.name || teammate.name)
      if (teammate.last_name || teammate?.user?.last_name) {
        setValue('last_name', teammate?.user?.last_name || teammate.last_name)
      }
    }
  }, [teammate, setValue])

  return (
    <>
      <SidePanel
        onClose={onClose}
        title={t(teammate ? 'teammate_details' : 'add_teammate')}
        width='1/4'
      >

      <form className="w-full">
          <div>
            <Input
              reference={register}
              id="name"
              type="text"
              name="name"
              label={t('name') + '*:'}
              placeholder={t('introduce_name_they')}
              error={errors.name}
              disabled={teammate && teammate.user}
            />
          </div>
          <div>
            <Input
              reference={register}
              id="last_name"
              type="text"
              name="last_name"
              label={t('last_name') + '*:'}
              placeholder={t('introduce_last_name_they')}
              error={errors.last_name}
              disabled={teammate && teammate.user}
            />
          </div>
          <div>
            <Input
              reference={register}
              id="email"
              type="email"
              name="email"
              label={t('email') + '*:'}
              placeholder={t('write_email_they')}
              error={errors.email}
              disabled={teammate && teammate.user}
            />
          </div>
          {teammate && teammate.user && teammate.user._id !== user._id && isMainUser() && (
            <div className='text-xs w-full p-4 border border-gray-lines rounded-2xl bg-gray-lines font-semibold'>
              {t('can_not_edit_registered_teammate')}
            </div>
          )}
          {teammate && teammate.user && teammate.user._id !== user._id && !isMainUser() && (
            <div className='text-xs w-full p-4 border border-gray-lines rounded-2xl bg-gray-lines font-semibold'>
              {t('can_not_edit_registered_teammate_insufficient_permissions')}
            </div>
          )}
          {teammate && teammate.user && teammate.user._id === user._id && (
            <div className='text-xs w-full p-4 border border-gray-lines rounded-2xl bg-gray-lines font-semibold'>
              {t('edit_information_from_your_account')}
            </div>
          )}
          <div className={`flex justify-end mt-2 pt-2 border-t border-separator-${Config.PLATFORM}`}>
            {teammate && isMainUser() && <ButtonDanger
              type="button"
              text={t('delete')}
              onClick={() => removeTeammate(teammate)}
              marginRight={2}
            />}
            {((teammate && !teammate.user && isMainUser()) || !teammate) && <ButtonMain
              type="button"
              onClick={async () => {
                const validation = await trigger()
                if (validation) onSubmit({ ...getValues() })
              }}
              text={t(!teammate ? 'add_teammate' : 'save')}
            />}
          </div>
        </form>
      </SidePanel>
    </>
  )
}

export default TeammateSection
