import { useDispatch, useSelector } from 'react-redux'
import UserService from '../services/user-service'
import UserOperations from '../redux/user/user-operations'
import ProjectsService from '../services/projects-service'
import { useEffect, useState } from 'react'
import { get } from 'lodash'

const useUser = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const [startupId, setStartupId] = useState(user?.projects?.length ? user.projects[0]._id : null)
  const [userLanguage, setUserLanguage] = useState('en')
  const [isAdmin, setIsAdmin] = useState(false)
  const [isInvestor, setIsInvestor] = useState(false)
  const [isFounder, setIsFounder] = useState(false)
  const [isProfessional, setIsProfessional] = useState(false)
  const [isDemoAccount, setIsDemoAccount] = useState(false)
  const [isVentureCapitals, setIsVentureCapitals] = useState(false)
  const [isProspectiveInvestor, setIsProspectiveInvestor] = useState(false)
  const [plan, setPlan] = useState(null)
  const setUserInfo = (data) => dispatch(UserOperations.setUserInfo({ data }))
  const haveFunctionality = (functionality) => user.investor?.permissions?.functionalities?.includes(functionality)
  const [investorId, setInvestorId] = useState(null)

  useEffect(() => {
    setStartupId(user?.projects?.length ? user.projects[0]._id : null)
    setUserLanguage(get(user, 'preferences.language.code') || 'en')
    setIsAdmin(user?.role === 'admin')
    setIsInvestor(user?.role === 'investor')
    setIsFounder(user?.role === 'founder')
    setIsProfessional(user?.role === 'professional')
    setIsDemoAccount(user && user.investor && !!user.investor.demo)
    setInvestorId(user && user.investor && user.investor._id)
    setPlan(user && user.investor ? user.investor.plan_id : null)
    setIsVentureCapitals(user && user.investor && user.investor.plan_id === 'venture_capitals')
    setIsProspectiveInvestor(user && user.investor && user.investor.plan_id === 'prospective_investor')
  }, [user])

  return {
    user,
    startupId,
    userLanguage,
    isAdmin,
    isInvestor,
    isFounder,
    isDemoAccount,
    isVentureCapitals,
    isProspectiveInvestor,
    plan,
    setUserInfo,
    isProfessional,
    haveFunctionality,
    investorId,

    reloadUserInfo: () => {
      return UserService.getUser(user._id).then(({ data }) => setUserInfo(data))
    },

    getUserStartup: () => {
      return ProjectsService.getProject(startupId)
        .then(({ data }) => data)
    },

    getUserFullName: () => (user?.last_name ? `${user?.name} ${user?.last_name}` : `${user?.name}`) || ''
  }
}

export default useUser
