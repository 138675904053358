import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'components/ui/molecules/Modal'

import { RichEditor } from 'components/ui/objects/RichEditor'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { isEmpty } from 'lodash'

import Config from 'config'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

const CriteriaComment = ({
  showModal,
  handleClose,
  onSubmit,
  initialValues,
  editable
}) => {
  const { t } = useTranslation()

  const TextNoteSchema = Yup.object().shape({
    content: Yup.string().required(t('required_field'))
  })

  const { register, handleSubmit, errors, setValue, getValues, trigger, watch } = useForm({
    mode: 'all',
    criteriaMode: 'all',
    reValidateMode: 'all',
    resolver: yupResolver(TextNoteSchema)
  })

  const watchContent = watch('editorEnabled', false);

  const _setValue = (name, value) => {
    setValue(name, value, { shouldValidate: true })
    trigger(name)
  }

  useEffect(() => {
    register('content')
    register('editorEnabled')
  }, [])

  useEffect(() => {
    if (!isEmpty(initialValues)) {
      setValue('content', initialValues.content || '')
    }
    setValue('editorEnabled', true)
  }, [initialValues])

  return (
    <>
      <Modal
        showModal={showModal}
        showCloseModal={true}
        onClose={handleClose}
        paddingTop="6"
        paddingBottom="4"
      >
        <h3 className={`font-medium mr-4 text-main-${Config.PLATFORM}`}>{t(editable ? 'add_coment' : 'see_comment')}</h3>
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-6">
            {watchContent && <RichEditor
              id={'content'}
              disabled={!editable}
              reference={register}
              name='content'
              placeholder={`${t('write_comment')}`}
              onChange={value => _setValue('content', value === '<p><br></p>' ? '' : value)}
              toolbarClassName="content"
              value={getValues().content || ''}
              error={errors.content}
            />}
          </div>
          {editable && (
            <div className={`flex justify-end mt-4 border-t pt-2 border-separator-${Config.PLATFORM}`}>
              <ButtonMain
                type="submit"
                text={t('save')}
              />
            </div>
          )}
        </form>
      </Modal>
    </>
  )
}

export default CriteriaComment
