import { FaFile, FaLink, FaRocket } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { TbCircleOff } from 'react-icons/tb'
import { useEffect, useState } from 'react'

import { Autocomplete } from 'components/ui/atoms/Autocomplete'
import { TagsSelector } from 'components/ui/atoms/TagsSelector'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import InputFile from 'components/ui/atoms/input-files'
import { Modal } from 'components/ui/molecules/Modal'
import { Input } from 'components/ui/atoms/Input'
import Config from 'config'
import { SidePanel } from 'components/ui/objects/SidePanel'

export const UploadFileSection = ({
  onClose,
  onSubmit,
  tags,
  startup,
  dealflow = [],
  dealId,
  accept = '.doc, .docx, .json, .ppt, .pptx, .xls, .xlsx, video/mp4, video/avi, video/mov, .pdf, .jpg, .png'
}) => {
  const [selectedTags, setSelectedTags] = useState([])
  const { t } = useTranslation()
  const [selectedFile, setSelectedFile] = useState(null)
  const [fileError, setFileError] = useState(false)
  const [linkError, setLinkError] = useState(false)
  const [documentNameError, setDocumentNameError] = useState(false)
  const [selectedStartup, setSelectedStartup] = useState(dealId || startup || null)
  const [documentTypeError, setDocumentTypeError] = useState(false)

  const parsedDealflow = (dealflow || []).sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1
    }
    return 0
  }).map(n => ({ id: n._id, name: n.name }))

  const [entityToAssignDocument, setEntityToAssignDocument] = useState(dealId ? 'startup' : null)

  const [documentType, setDocumentType] = useState(null)
  const [link, setLink] = useState(null)
  const [documentName, setDocumentName] = useState(null)

  const uploadDocument = () => {
    if (documentType) {
      if (documentType === 'file' && !selectedFile) {
        setFileError(true)
      } else if (documentType === 'link' && (!link || !documentName)) {
        if (!link) {
          setLinkError(true)
        }
        if (!documentName) {
          setDocumentNameError(true)
        }
      } else {
        onClose(false)
        onSubmit(documentType, selectedFile, selectedTags.length ? selectedTags : null, selectedStartup, link, documentName, entityToAssignDocument)
      }
    } else {
      setDocumentTypeError(true)
    }
  }

  useEffect(() => {
    if (link && linkError) {
      setLinkError(false)
    }
  }, [link])

  useEffect(() => {
    if (documentName && documentNameError) {
      setDocumentNameError(false)
    }
  }, [documentName])

  return (
    <SidePanel
      width={'1/4'}
      onClose={onClose}
      title={t('upload_document')}
    >
      <div className={`w-full px-1 text-sm font-medium my-2 flex text-main-${Config.PLATFORM}`}>{t('what_do_you_want_to_upload')}</div>
      <div className="flex flex-row my-3">
          <div
            onClick={documentType !== 'file' ? () => {
              setDocumentTypeError(false)
              setDocumentType('file')
            } : null}
            className={`w-8 flex justify-center  p-1 hover:shadow-inner rounded-md cursor-pointer border-2 bg-white ${documentType === 'file' ? `shadow-inner border-main-${Config.PLATFORM}` : 'shadow-soft-white'}`}
          >
            <FaFile />
          </div>

          <div
            onClick={documentType !== 'link' ? () => {
              setDocumentTypeError(false)
              setDocumentType('link')
            } : null}
            className={`bg-white w-8 flex justify-center p-1 hover:shadow-inner ml-2 rounded-md cursor-pointer border-2 ${documentType === 'link' ? `shadow-inner border-main-${Config.PLATFORM}` : 'shadow-soft-white'}`}
          >
            <FaLink/>
          </div>
      </div>
      {documentTypeError && <div className="text-red relative mt-2 text-xxs text-left">
          {t('you_have_to_select_a_document_type')}
        </div>
      }
      {documentType !== null && (
        <>
          {documentType === 'file' && (
            <div className='my-4'>
              <p className={`font-medium text-sm text-main-${Config.PLATFORM} border-t mb-1 pt-2`}>
                {t('select_file')}
              </p>
              <InputFile
                multiple={true}
                selectedFile={selectedFile}
                setSelectedFile={(e) => {
                  setFileError(false)
                  setSelectedFile(e)
                }}
                accept={accept}
                error={fileError ? { message: t('required_field') } : {}}
              />
            </div>
          )}
          {
            documentType === 'link' && (
              <div className='mt-4'>
                <p className={`font-medium text-sm text-main-${Config.PLATFORM} mb-1 border-t pt-2`}>
                  {t('document_name_label')}
                </p>
                <Input
                  onChange={(e) => setDocumentName(e.target.value)}
                  placeholder={t('document_name_placeholder')}
                  error={documentNameError ? { message: t('required_field') } : {}}
                />
                <p className={`font-medium text-sm text-main-${Config.PLATFORM} mb-1`}>
                  {t('insert_link')}
                </p>
                <Input
                  onChange={(e) => setLink(e.target.value)}
                  placeholder={t('link_placeholder')}
                  error={linkError ? { message: t('required_field') } : {}}
                />
              </div>
          )}
          <p className={`font-medium text-sm text-main-${Config.PLATFORM} mb-1`}>
            {t('would_you_like_to_add_a_tag_to_document')}
          </p>

          {tags.length !== 0 && <TagsSelector
            tags={tags}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
          />}
          {tags.length === 0 && <div className='text-xs text-center font-bold my-2'>{t('no_tags_found')}</div>}

          {!dealId && (
            <>
              <div className={`w-full px-1 text-sm font-medium mt-2 flex text-main-${Config.PLATFORM} border-t py-4`}>{t('would_you_like_to_assign_document')}</div>
                <div className="flex flex-row">
                  <div
                    data-tip={t('startup')}
                    onClick={entityToAssignDocument !== 'startup' ? () => setEntityToAssignDocument('startup') : null}
                    className={`w-8 flex justify-center  p-1 hover:shadow-inner rounded-md cursor-pointer border-2 bg-white ${entityToAssignDocument === 'startup' ? `shadow-inner border-main-${Config.PLATFORM}` : 'shadow-soft-white'}`}
                  >
                    <FaRocket />
                  </div>
                  <div
                    data-tip={t('no_assign')}
                    onClick={() => setEntityToAssignDocument(null)}
                    className={`bg-white w-8 flex justify-center p-1 hover:shadow-inner ml-2 rounded-md cursor-pointer border-2 ${entityToAssignDocument === null ? `shadow-inner border-main-${Config.PLATFORM}` : 'shadow-soft-white'}`}
                  >
                    <TbCircleOff/>
                  </div>
              </div>
              {entityToAssignDocument && (
                <>
                  {entityToAssignDocument === 'startup' && (
                    <>
                      {dealflow.length !== 0 && (
                        <div className="mt-4 w-full">
                          <Autocomplete
                            name="startup"
                            label={t('startup')}
                            placeholder={t('startup')}
                            options={parsedDealflow}
                            onSelect={selected => {
                              setSelectedStartup(selected?.id)
                            }}
                            initialValues={parsedDealflow.length !== 0 ? parsedDealflow.find((dealflow) => dealflow.id === selectedStartup) : null}
                          />
                        </div>
                      )}

                      {dealflow.length === 0 && <div className='text-xs text-center font-bold my-4'>{t('no_startups_found')}</div>}
                    </>)
                  }
                </>
              )}
            </>
          )}
        </>
      )}

        <div className={`flex justify-end mt-4 border-t pt-2 border-separator-${Config.PLATFORM}`}>
        <ButtonMain
          text={t('upload_document')}
          onClick={() => uploadDocument()}
        />
      </div>
    </SidePanel>
  )
}
