import axios from 'axios/index'
import Config from '../config'

const UsersService = {
  getInvestorsInvitations: async (investorId, params) => {
    let query = ''

    if (params.page !== null) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}page=${params.page}`
    }

    if (params.rowsPerPage) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}rowsPerPage=${params.rowsPerPage}`
    }

    return axios.get(
      `${Config.USERS_API_URL}investors/${investorId}/invitations${query}`
    )
  },

  createRole: async (name, permissions) => {
    return axios.post(`${Config.USERS_API_URL}roles`, {
      name,
      permissions
    })
  },

  updateRole: async (roleId, name, permissions) => {
    return axios.patch(`${Config.USERS_API_URL}roles/${roleId}`, {
      name,
      permissions
    })
  },

  getEmployees: async () => {
    return axios.get(`${Config.USERS_API_URL}employees`)
  },

  getRoles: async () => {
    return axios.get(`${Config.USERS_API_URL}roles`)
  },

  getRole: async (roleId) => {
    return axios.get(`${Config.USERS_API_URL}roles/${roleId}`)
  },

  getInvestors: async () => {
    return axios.get(`${Config.USERS_API_URL}investors`)
  },

  getUsers: async () => {
    return axios.get(`${Config.USERS_API_URL}`)
  },

  createInvestor: async (data) => {
    return axios.post(`${Config.USERS_API_URL}investors`, data)
  },

  checkInvestorInvitationCode: async (invitationCode) => {
    return axios.get(
      `${Config.USERS_API_URL}investors/check-invitation-code?invitation_code=${invitationCode}`
    )
  },

  updateInvestor: async (investorId, data) => {
    return axios.patch(`${Config.USERS_API_URL}investors/${investorId}`, data)
  },

  activateInvestor: async (investorId) => {
    return axios.patch(
      `${Config.USERS_API_URL}investors/${investorId}/activate`
    )
  },

  createEmployee: async (employee) => {
    return axios.post(`${Config.USERS_API_URL}employee`, employee)
  },

  removeEmployee: async (employeeId) => {
    return axios.delete(`${Config.USERS_API_URL}employee/${employeeId}`)
  },

  updateEmployee: async (employeeId, name, last_name, email) => {
    return axios.patch(`${Config.USERS_API_URL}employee/${employeeId}`, {
      name,
      last_name,
      email
    })
  },

  removeRole: async (roleId) => {
    return axios.delete(`${Config.USERS_API_URL}role/${roleId}`)
  },

  checkUser: async (email) => {
    return axios.get(`${Config.USERS_API_URL}check?email=${email}`)
  },

  getPortfolio: async (investorId, params) => {
    let query = ''

    if (params.sector) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}sector=${params.sector}`
    }

    if (params.sortBy) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}sortBy=${params.sortBy}`
    }

    if (params.page !== null) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}page=${params.page}`
    }

    if (params.rowsPerPage) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}rowsPerPage=${params.rowsPerPage}`
    }

    return axios.get(
      `${Config.USERS_API_URL}${investorId}/portfolio${query}`,
      {}
    )
  },

  sharePortfolio: async (investorId, name, lastName, email) => {
    return await axios.post(
      `${Config.USERS_API_URL}${investorId}/portfolio/share`,
      { name, last_name: lastName, email }
    )
  },

  shareInvitationCode: async (investorId, name, lastName, email) => {
    return await axios.post(
      `${Config.USERS_API_URL}${investorId}/invitation-code/share`,
      { name, last_name: lastName, email }
    )
  },

  updateTours: async (data) => {
    return axios.post(`${Config.USERS_API_URL}tours`, data)
  },

  getInvitations: async (params) => {
    let query = ''

    if (params.name) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}name=${params.name}`
    }

    if (params.status) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}status=${params.status}`
    }

    if (params.email) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}email=${params.email}`
    }

    if (params.sortBy) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}sortBy=${params.sortBy}`
    }

    if (params.hasOwnProperty('page')) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}page=${params.page}`
    }

    if (params.rowsPerPage) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}rowsPerPage=${params.rowsPerPage}`
    }

    return axios.get(`${Config.USERS_API_URL}invitations${query}`)
  },

  searchInvestors: async (query) => {
    return axios.get(`${Config.USERS_API_URL}investors/search?q=${query}`)
  }
}
export default UsersService
