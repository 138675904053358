import { Input } from 'components/ui/atoms/Input'
import { Modal } from 'components/ui/molecules/Modal'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useEffect } from 'react'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import Config from 'config'

const SectionModal = ({ showModal, onClose, onSubmit, name }) => {
    const { t } = useTranslation()

    const { register, handleSubmit, errors, setValue } = useForm({
        mode: 'onChange',
        criteriaMode: 'all',
        reValidateMode: 'all',
        resolver: yupResolver(Yup.object().shape({
          name: Yup.string().required(t('required_field'))
        }))
      })

      useEffect(() => {
        if (name) {
            setValue('name', name)
        }
      }, [name])

    return (
        <Modal
            showModal={showModal}
            onClose={onClose}
            showCloseModal={true}
            fixedWidth={'w-1/4'}
        >
            <div className={'flex flex-col max-h-full'}>
            <h2 className={`font-semibold text-xl mb-2 text-main-${Config.PLATFORM}`}>
                    {t(name ? 'update_section' : 'create_section')}
                </h2>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className='flex flex-col'
                >
                    <Input
                        reference={register}
                        name='name'
                        label={t('name')}
                        error={errors.name}
                    />
                    <div className={`flex justify-end mt-4 border-t pt-2 border-separator-${Config.PLATFORM}`}>
                        <ButtonMain text={t(name ? 'update_section' : 'create_section')}/>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default SectionModal
