import React from 'react'
import Config from 'config'

export function RadioButton ({
  name,
  id,
  reference,
  checked,
  label,
  onChange,
  value,
  likert = true,
  disabled
}) {
  return (
    <div className="block">
      <div className="mt-2">
        <div>
          {likert
            ? (
            <label className="inline-flex items-center">
              <input
                type="radio"
                disabled={disabled}
                checked={checked}
                onChange={onChange}
                className={`form-radio bg-transparence-blue cursor-pointer checked:bg-main-${Config.PLATFORM} shadow-hover rounded-full`}
                name={name}
                value={value}
              />
              <span
                className={`flex justify-center -ml-5 sm:-ml-7 z-10 mt-16 text-center text-xxxs sm:text-xs xl:text-sm absolute leading-4 ${
                  likert ? 'w-16 sm:w-20' : 'w-auto'
                }`}
              >
                {label}
              </span>
            </label>
              )
            : (
            <>
              <input
                type="radio"
                onChange={onChange}
                checked={checked}
                className={`form-radio bg-transparence-blue cursor-pointer checked:bg-main-${Config.PLATFORM} shadow-hover rounded-full`}
                name={name}
                value={value}
              />
              <label className="text-sm ml-2">{label}</label>
            </>
              )}
        </div>
      </div>
    </div>
  )
}
