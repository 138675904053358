import React from 'react'
import ReactDOM from 'react-dom'
import Config from 'config'

export function Loader () {
  const LOADERS = {
    '000': <svg
    id="loading-spinner"
    width="192"
    height="193"
    viewBox="-5 0 278 270"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="path-one"
      d="M15.3283 0H0V272.708H15.3283V0Z"
      stroke="white"
      strokeWidth="3"
    />
    <path
      id="path-two"
      d="M150.271 150.57L85.0244 215.817L141.875 272.653H158.709L215.206 216.156L215.572 215.789L150.271 150.57ZM150.705 264.053L102.482 215.844L150.271 168.055L198.426 216.224L150.705 264.053Z"
      stroke="#3C5DA3"
      strokeWidth="3"
    />
    <path
      id="path-three"
      d="M150.271 37.6017L85.0244 102.849L150.271 168.095L215.152 103.215L215.518 102.849L150.271 37.6017ZM150.271 150.638L102.482 102.849L150.271 55.0597L198.047 102.849L150.271 150.638Z"
      stroke="#3C5DA3"
      strokeWidth="3"
    />
    <path
      id="path-four"
      d="M207.094 207.447L141.983 272.708L271.717 272.273L207.094 207.447ZM171.731 260.35L207.094 224.905L242.214 260.174L171.731 260.35Z"
      stroke="#67A7DE"
      strokeWidth="3"
    />
    <path
      id="path-five"
      d="M93.5975 94.0586L28.3506 159.305L93.5975 224.539L158.478 159.658L158.844 159.305L93.5975 94.0586ZM141.386 159.305L93.5975 207.094L45.8492 159.305L93.5975 111.503L141.386 159.305Z"
      stroke="white"
      strokeWidth="3"
    />
    <path
      id="path-six"
      d="M182.895 13.5648C178.611 9.28067 173.524 5.88228 167.927 3.5637C162.329 1.24512 156.33 0.0517655 150.271 0.0517654C144.213 0.0517654 138.213 1.24512 132.616 3.5637C127.018 5.88228 121.932 9.28067 117.648 13.5648C113.364 17.849 109.965 22.9351 107.647 28.5326C105.328 34.1301 104.135 40.1295 104.135 46.1883C104.135 52.247 105.328 58.2464 107.647 63.844C109.965 69.4415 113.364 74.5275 117.648 78.8117C126.3 87.464 138.035 92.3248 150.271 92.3248C162.507 92.3248 174.242 87.464 182.895 78.8117C191.547 70.1594 196.408 58.4244 196.408 46.1883C196.408 33.9521 191.547 22.2171 182.895 13.5648V13.5648ZM174.159 70.076C169.432 74.8058 163.409 78.0276 156.851 79.3338C150.293 80.6401 143.495 79.972 137.317 77.4142C131.139 74.8564 125.858 70.5237 122.143 64.9642C118.428 59.4047 116.444 52.8682 116.444 46.1815C116.444 39.4948 118.428 32.9583 122.143 27.3988C125.858 21.8393 131.139 17.5066 137.317 14.9488C143.495 12.391 150.293 11.7229 156.851 13.0292C163.409 14.3354 169.432 17.5572 174.159 22.287C180.495 28.6304 184.054 37.2294 184.054 46.1951C184.054 55.1607 180.495 63.7597 174.159 70.1031V70.076Z"
      stroke="#67A7DE"
      strokeWidth="3"
    />
  </svg>,
    '001': <svg
    width="200" height="200" viewBox="0 0 45 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M39.4968 0.446775C38.3314 -0.398097 35.8062 0.0568345 33.3458 0.966697C30.8853 1.87656 28.4896 3.24135 27.7774 4.21621C26.1587 6.42587 24.54 9.02548 22.986 11.5601L18.9069 12.145L10.6191 23.5833L15.3458 22.8684C15.3458 22.8034 15.3458 22.7384 15.3458 22.6734C15.4105 22.4135 15.54 22.1535 15.6695 21.9585L21.8206 13.5098C20.2019 16.3694 18.7127 19.099 17.5472 21.3086C17.2882 21.6336 17.0292 22.1535 16.7055 22.6734C16.6407 22.7384 16.576 22.8684 16.576 22.9984C16.576 23.1283 16.576 23.1933 16.576 23.3233C16.576 23.4533 16.6407 23.5183 16.7055 23.6483C16.7702 23.7133 16.835 23.8432 16.8997 23.9082L23.9573 29.1074C24.022 29.1724 24.1515 29.2374 24.2163 29.2374C24.4105 29.3024 24.6695 29.2374 24.799 29.1724C24.8637 29.1074 24.9932 29.0424 25.058 28.9775L25.1227 28.9125L26.2882 27.6777C27.7127 26.2479 29.3314 24.4931 31.0796 22.6734L26.0292 29.6274C25.8997 29.8223 25.6407 30.0173 25.4465 30.1473L25.317 30.2123L26.0292 34.9566L34.317 23.5183L33.7343 19.6839C35.7414 17.4092 37.7486 15.0046 39.4321 12.7949C40.1443 11.8201 40.7271 9.22045 40.8566 6.49086C40.986 3.76127 40.6623 1.29165 39.4968 0.446775ZM33.0868 13.3798C29.5904 13.4448 28.2307 10.0653 29.8494 7.92064C30.9501 6.29589 33.022 6.03593 34.576 7.14076C36.7127 8.70053 36.4537 12.34 33.0868 13.3798Z" fill="#0E5394"/>
    <path d="M34.9641 9.48009C34.8346 8.83019 34.5108 8.31027 33.9928 7.92033C33.4749 7.53038 32.8274 7.33541 32.1799 7.46539C30.8849 7.66037 29.9785 8.89518 30.1727 10.26C30.3669 11.5598 31.5972 12.4696 32.9569 12.2747C34.1871 12.0797 35.1583 10.8449 34.9641 9.48009Z" fill="#0E5394"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M41.5036 10.7804C41.1799 11.8852 40.8561 12.7951 40.4676 13.315C38.9137 15.5246 36.9712 17.7993 35.0935 19.944L35.6115 23.5834C35.6115 23.7784 35.6115 23.9084 35.482 24.0384L26.1583 36.9714C25.964 37.2314 25.5755 37.2964 25.2518 37.1014C25.1223 36.9714 24.9928 36.8414 24.9928 36.6465L24.0216 30.2774H23.9568C23.6978 30.2124 23.4388 30.0824 23.2446 29.9525L16.187 24.7532C15.9928 24.6233 15.7986 24.3633 15.6691 24.1683V24.1033L9.25899 25.0782C9.06475 25.0782 8.93525 25.0782 8.80576 24.9482C8.54676 24.7532 8.48201 24.3633 8.67626 24.0384L18.0647 11.1703C18.1942 11.1053 18.3237 10.9753 18.518 10.9753L22.1439 10.3904C23.6331 7.92079 25.187 5.45117 26.8058 3.30649C27.259 2.72158 28.1655 2.00669 29.3309 1.35679C27.1942 0.641894 24.8633 0.251953 22.4676 0.251953C10.036 0.251953 0 10.3904 0 22.8685C0 28.1977 1.81295 33.072 4.85612 36.9064L12.0432 27.8728C12.3022 27.4828 12.8849 27.4178 13.2734 27.7428C13.6619 28.0677 13.7266 28.5877 13.4029 28.9776L6.02158 38.2712C6.66906 38.9861 7.44604 39.701 8.15828 40.2859L13.2086 33.8519C13.4676 33.4619 14.0504 33.3969 14.4388 33.7219C14.8273 34.0468 14.8921 34.5668 14.5683 34.9567L9.58273 41.3257C10.3597 41.8457 11.2014 42.3656 12.0432 42.8205L19.295 33.6569C19.6187 33.267 20.1367 33.202 20.5252 33.5269C20.9137 33.8519 20.9784 34.3718 20.6547 34.7617L13.6619 43.6004C16.3813 44.7702 19.3597 45.4201 22.4676 45.4201C34.8993 45.4201 45 35.3466 45 22.8685C45 18.4492 43.705 14.2898 41.5036 10.7804Z" fill="#0E5394"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M2.91334 39.3754C2.5896 39.7653 2.65435 40.2853 3.04284 40.6102C3.43132 40.9352 3.94931 40.8702 4.27305 40.4802L6.02125 38.2706C5.63276 37.8156 5.24427 37.3607 4.85578 36.9058L2.91334 39.3754Z" fill="#0E5394"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.1661 45.2249C9.90715 45.6148 9.97189 46.1348 10.3604 46.4597C10.7489 46.7197 11.2021 46.6547 11.5259 46.3297L13.6625 43.6651C13.1446 43.4052 12.5618 43.1452 12.0438 42.8853L10.1661 45.2249Z" fill="#0E5394"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.75514 45.8748C3.43139 46.2647 3.49614 46.7847 3.88463 47.1096C4.27312 47.4346 4.79111 47.3696 5.11485 46.9796L9.51773 41.3255C9.06449 41.0005 8.5465 40.6106 8.09326 40.2856L3.75514 45.8748Z" fill="#0E5394"/>
    </svg>
  }
  return ReactDOM.createPortal(
    <div
      className="bg-gray-opacity h-screen fixed top-0 right-0 z-50"
      id="spinner"
    >
      {LOADERS[Config.PLATFORM]}
    </div>
    , document.body)
}
