import React, { useEffect, useState } from 'react'
import { RoutesLinks } from 'components/routes-links'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import QuestionnairesService from '../../services/questionnaires-service'
import { Loader } from '../ui/molecules/Loader'
import { SectionHeader } from '../ui/molecules/SectionHeader'
import { Pagination } from 'components/ui/atoms/Pagination'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import { Button } from 'components/ui/atoms/Button'
import { Card } from 'components/ui/atoms/Card'
import { useSwal } from 'hooks/useSwal'
import { PageTitle } from 'components/ui/atoms/PageTitle'
import { Question } from 'components/qe-questions/components/Question'
import { ImportQuestionsModal } from 'components/ui/molecules/ImportQuestionsModal'
import useUser from '../../hooks/useUser'
import { FaFilter, FaPlus, FaPlusCircle } from 'react-icons/all'
import { FormQuestionsFilters } from './components/FormQuestionsFilters'
import { Modal } from 'components/ui/molecules/Modal'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import Config from 'config'

const QuestionnairesQuestions = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const history = useHistory()
  const { confirm } = useSwal()
  const [loading, setLoading] = useState(true)
  const { user, isAdmin } = useUser()
  const [questions, setQuestions] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalQuestions, setTotalQuestions] = useState(0)
  const [showImportQuestionsModal, setShowImportQuestionsModal] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [filters, setFilters] = useState({})


  const [showUsedQuestionError, setShowUsedQuestionError] = useState(false)
  const [questionUsedInForms, setQuestionUsedInForms] = useState([])

  const SECTIONS = ['forms', 'questions', 'templates']

  const SECTIONS_URLS = {
    forms: RoutesLinks.QE_FORMS_LINK,
    templates: RoutesLinks.QE_TEMPLATES_LINK,
    questions: RoutesLinks.QE_QUESTIONS_LINK,
  }

  const getQuestionnaireQuestions = () => {
    setLoading(true)

    const params = {
      ...filters,
      rowsPerPage: pageSize,
      page: currentPage
    }

    QuestionnairesService.getQuestionnairesQuestions(params)
      .then((response) => {
        const { questions, pagination } = response.data.data
        setQuestions(questions)

        if (pagination.current - 1 !== currentPage) {
          setCurrentPage(pagination.current)
        }

        if (pagination.total !== totalPages) {
          setTotalPages(pagination.pages)
        }

        if (pagination.totalDocs !== totalQuestions) {
          setTotalQuestions(pagination.totalDocs)
        }
      })
      .catch((error) => {
        console.log(error)
        addToast(t('error_getting_questions'), {
          appearance: 'error',
          autoDismiss: true
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const deleteQuestionnaireQuestion = (questionId) => {
    confirm({
      text: t('delete_question_warning'),
      icon: 'warning'
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          await QuestionnairesService.deleteQuestionnairesQuestion(questionId)
          addToast(t('question_deleted_successfully'), {
            appearance: 'success',
            autoDismiss: true
          })

          getQuestionnaireQuestions()
        } catch (error) {
          if (error.response.status === 403) {
            setShowUsedQuestionError(true)
            setQuestionUsedInForms(error.response.data.questionnaires)
          } else {
            addToast(
              error.response
                ? error.response.data.msg_key
                  ? t(error.response.data.msg_key)
                  : t('error_occurred')
                : t('error_occurred_deleting_question'),
              {
                appearance: 'error',
                autoDismiss: true
              }
            )
          }
        }
      }
    })
  }

  const updateQuestion = (questionId, data) => {
    confirm({
      text: t(data.public ? 'public_question_warning' : 'private_question_warning'),
      icon: 'warning'
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          await QuestionnairesService.updateQuestionnairesQuestion(questionId, data)
          addToast(t('question_updated_successfully'), {
            appearance: 'success',
            autoDismiss: true
          })

          getQuestionnaireQuestions()
        } catch (error) {
          addToast(
            error.response
              ? error.response.data.msg_key
                ? t(error.response.data.msg_key)
                : t('error_occurred')
              : t('error_occurred_updating_question'),
            {
              appearance: 'error',
              autoDismiss: true
            }
          )
        }
      }
    })
  }

  const showSectionHeader = () => {
    let sections = []
    if (!isAdmin) sections = user?.investor?.permissions?.sections || []

    return isAdmin || sections.includes('questionnaires_engine')
  }

  const importQuestionsFile = async (file) => {
    const formData = new FormData()
    formData.append('file', file)

    try {
      setLoading(true)
      await QuestionnairesService.importQuestions(formData)
      addToast(t('questions_imported_successfully'), {
        appearance: 'success',
        autoDismiss: true
      })
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t('error_occurred_importing_questions'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    } finally {
      setShowImportQuestionsModal(false)
      setLoading(false)
    }
  }

  const filterQuestions = (filters) => {
    setCurrentPage(0)
    setFilters(filters)
  }

  useEffect(() => {
    getQuestionnaireQuestions()
  }, [currentPage, pageSize, filters])

  return (
    <>
      {showUsedQuestionError && (
        <Modal
          showCloseModal={true}
          showModal={showUsedQuestionError}
          paddingBottom="2"
          paddingTop="6"
          onClose={() => {
            setShowUsedQuestionError(false)
            setQuestionUsedInForms([])
          }}
        >
          <h2 className="font-semibold text-xl mr-4">
            {t('error_occurred_deleting_question')}
          </h2>
          <p className="font-medium text-sm text-gray-dark">
            {t('question_is_being_used_in_some_questionnaires')}
          </p>
          <p className="font-medium text-sm text-gray-dark mt-6 mb-4">
            {t('delete_from_questionnaires_previously')}
          </p>
          <div className="text-sm  text-justify font-normal w-full px-10 mb-6">
            <ul className='list-disc'>
              {questionUsedInForms.map((q, index) => {
                return <li key={index} className="mb-1">{q}</li>
              })}
            </ul>
          </div>
        </Modal>
      )}

      <div className='flex'>
        <div className='flex-1'>
          <PageTitle
            title={t(showSectionHeader() ? 'questionnaires_engine' : 'questions')}
            removeMargin={true}
          />
        </div>

        <ButtonMain
          iconComponent={<FaPlus className='inline-block mr-2' />}
          marginRight='2'
          text={t('create_question')}
          onClick={() => history.push(`${RoutesLinks.QE_QUESTIONS_WIZARD_LINK}`)}
        />

        <div data-tip={t('filter')}>
          <ButtonSecondary
            iconComponent={<FaFilter className='h-4'/>}
            onClick={() => setShowFilters(true)}
          />
        </div>
      </div>

      {showSectionHeader() && (
        <SectionHeader
          sectionTitles={SECTIONS.map((sec) => t(sec))}
          sectionKeys={SECTIONS}
          setSection={(sec) => {
            history.push(SECTIONS_URLS[sec])
          }}
          section="questions"
        />
      )}

      {!loading && (
        <>
          {isAdmin && (
            <div className="w-full mt-6">
              <Card containerClassName="flex flex-col lg:flex-row lg:justify-between">
                <div className={'md:w-full lg:w-10/12'}>
                  <h3 className={`text-main-${Config.PLATFORM}`}>{t('would_you_like_to_import_questions')}</h3>
                  <p className={'text-xs pr-4'}>{t('import_questions_explanation')}</p>
                </div>
                <div className="md:w-full lg:w-2/12 justify-end flex text-center">
                  <Button
                    width="1/2 lg:w-full"
                    text={t('import_questions')}
                    onClick={() => {
                      setShowImportQuestionsModal(true)
                    }}
                  />
                </div>
              </Card>
            </div>
          )}

          {showImportQuestionsModal && (
            <ImportQuestionsModal
              onSubmit={(file) => importQuestionsFile(file)}
              showModal={showImportQuestionsModal}
              handleCloseModal={() => {
                setShowImportQuestionsModal(false)
              }}
            />
          )}

          {questions.length > 0 && (
            <>
              <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 mb-4 mt-4">
                {questions.map((question) => (
                  <Question
                    key={question._id}
                    question={question}
                    deleteQuestion={deleteQuestionnaireQuestion}
                    updateQuestion={updateQuestion}
                  />
                ))}
              </div>

              <Pagination
                currentPage={currentPage}
                setCurrentPage={(page) => setCurrentPage(page)}
                perPage={pageSize}
                pages={totalPages}
                setPerPage={(value) => setPageSize(value[0].id)}
              />
            </>
          )}

          {questions.length === 0 && (
            <div className="my-6">
              <Card>
                <NoDataInfo
                  title={t(filters && Object.keys(filters).length ? 'questions_not_found' : 'questions_not_found_empty')}
                  textOne={t(
                    filters && Object.keys(filters).length ? 'questions_not_found_text' : 'questions_not_found_empty_text'
                  )}
                />
              </Card>
            </div>
          )}
        </>
      )}

      {showFilters && (
        <FormQuestionsFilters
          onClose={() => setShowFilters(false)}
          onFilter={filterQuestions}
          filters={filters}
        />
      )}

      {loading && <Loader />}
    </>
  )
}

export default QuestionnairesQuestions
