import axios from 'axios/index'
import Config from '../config'

const SupportService = {
  saveNPS: async (nps) => {
    return axios.post(`${Config.SUPPORT_API_URL}nps`, nps)
  },

  getLastNPS: () => {
    return axios.get(`${Config.SUPPORT_API_URL}last-nps`)
  },

  contact: async (data) => {
    return axios.post(`${Config.SUPPORT_API_URL}tickets`, data)
  },

  requestDemo: async (name, email) => {
    return axios.post(`${Config.SUPPORT_API_URL}demo`, { name, email })
  },

  requestFeature: async (data) => {
    return axios.post(`${Config.SUPPORT_API_URL}request-feature`, data)
  },

  getSupportTickets: async () => {
    return axios.get(`${Config.SUPPORT_API_URL}tickets`)
  },

  getNPS: async () => {
    return axios.get(`${Config.SUPPORT_API_URL}nps`)
  },

  getRequestedFeatures: async () => {
    return axios.get(`${Config.SUPPORT_API_URL}requested-features`)
  },

  getStats: async () => {
    return axios.get(`${Config.SUPPORT_API_URL}stats`)
  },

  createScoreCard: async (data) => {
    return axios.post(`${Config.SUPPORT_API_URL}score-cards`, data)
  },

  getScoreCard: async (scoreCardId) => {
    return axios.get(`${Config.SUPPORT_API_URL}score-cards/${scoreCardId}`)
  },

  getScoreCardSelectedForPool: async () => {
    return axios.get(`${Config.SUPPORT_API_URL}score-cards/pool`)
  },

  archiveScoreCard: async (scoreCardId) => {
    return axios.delete(`${Config.SUPPORT_API_URL}score-cards/${scoreCardId}`)
  },

  updateScoreCard: async (scoreCardId, data) => {
    return axios.put(`${Config.SUPPORT_API_URL}score-cards/${scoreCardId}`, data)
  },

  getScoreCards: async (params) => {
    let query = ''

    if (params.name) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}name=${params.name}`
    }

    if (params.status) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}status=${params.status}`
    }

    if (params.sortBy) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}sortBy=${params.sortBy}`
    }

    if (params.hasOwnProperty('page')) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}page=${params.page}`
    }

    if (params.rowsPerPage) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}rowsPerPage=${params.rowsPerPage}`
    }

    return axios.get(`${Config.SUPPORT_API_URL}score-cards${query}`)
  },

  createApiKey: async () => {
    return axios.post(`${Config.SUPPORT_API_URL}integrations/api-key
    `)
  },

  getKiotaGammaEvaluation: async (dealId) => {
    return axios.get(`${Config.SUPPORT_API_URL}integrations/k_gamma/execution?dealflow=${dealId}`)
  },

  getApiKey: async () => {
    return axios.get(`${Config.SUPPORT_API_URL}integrations/api-key`)
  },

  getApiExecutions: async (integrationId, params) => {
    let query = ''

    if (params.page !== null) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}page=${params.page}`
    }

    if (params.rowsPerPage) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}rowsPerPage=${params.rowsPerPage}`
    }

    return axios.get(`${Config.SUPPORT_API_URL}integrations/${integrationId}/executions${query}`)
  },

  getApiIntegrationStats: async (integrationId) => {
    return axios.get(`${Config.SUPPORT_API_URL}integrations/${integrationId}/stats`)
  },

  evaluateStartupNew: async (data, isFromShared = false) => {
    return axios.post(`${Config.SUPPORT_API_URL}evaluations`, data, {
      ...(isFromShared ? { headers: { 'X-Shared': 'true' } } : {})
    })
  },

  getEvaluation: async (evaluationId) => {
    return axios.get(`${Config.SUPPORT_API_URL}evaluations/${evaluationId}`)
  },

  getJudgeEvaluation: async (judgeId, startupId, isFromShared = false) => {
    return axios.get(`${Config.SUPPORT_API_URL}evaluations/startup/${startupId}/judge/${judgeId}`, {
      ...(isFromShared ? { headers: { 'X-Shared': 'true' } } : {})
    })
  },

  getEvaluations: async (params, isFromShared = false) => {
    const searchParams = new URLSearchParams(params)
    return axios.get(`${Config.SUPPORT_API_URL}evaluations?${searchParams}`, {
      ...(isFromShared ? { headers: { 'X-Shared': 'true' } } : {})
    })
  },

  getEvaluationNotes: async (evaluationId) => {
    return axios.get(`${Config.SUPPORT_API_URL}evaluations/${evaluationId}/notes`)
  },

  createEvaluationNote: async (evaluationId, title, type, content) => {
    return axios.post(`${Config.SUPPORT_API_URL}evaluations/${evaluationId}/notes`, {
      title,
      type,
      content
    })
  },

  deleteEvaluationNote: async (evaluationId, noteId) => {
    return axios.delete(`${Config.SUPPORT_API_URL}evaluations/${evaluationId}/notes/${noteId}`)
  },

  getEvaluationNote: async (evaluationId, noteId) => {
    return axios.get(`${Config.SUPPORT_API_URL}evaluations/${evaluationId}/notes/${noteId}`)
  },

  updateEvaluationNote: async (evaluationId, noteId, title, content) => {
    return axios.patch(`${Config.SUPPORT_API_URL}evaluations/${evaluationId}/notes/${noteId}`, {
      title,
      content
    })
  },

  getEvents: async (params) => {
    const query = new URLSearchParams(params)
    return axios.get(`${Config.SUPPORT_API_URL}events?${query}`)
  },

  createEvent: async (data) => {
    return axios.post(`${Config.SUPPORT_API_URL}events`, data)
  },

  getEvent: async (eventId, isFromShared = false) => {
    return axios.get(`${Config.SUPPORT_API_URL}events/${eventId}`, {
      ...(isFromShared ? { headers: { 'X-Shared': 'true' } } : {})
    })
  },

  archiveEvent: async (eventId) => {
    return axios.post(`${Config.SUPPORT_API_URL}events/${eventId}/archive`)
  },

  deleteEvent: async (eventId) => {
    return axios.delete(`${Config.SUPPORT_API_URL}events/${eventId}`)
  },

  shareEvent: (eventId, shared) => {
    return axios.post(`${Config.SUPPORT_API_URL}events/${eventId}/share`, shared)
  },

  updateSharedReport: (eventId, shareId, data) => {
    return axios.post(`${Config.SUPPORT_API_URL}events/${eventId}/shared-report/${shareId}`, data)
  },

  getLatestNews: async (params) => {
    let query = ''

    if (params.country) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}country=${params.country}`
    }
    if (params.sector) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}sector=${params.sector}`
    }
    if (params.investment_stage) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}investment_stage=${params.investment_stage}`
    }
    if (params.sortBy) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}sortBy=${params.sortBy}`
    }

    if (params.hasOwnProperty('page')) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}page=${params.page}`
    }

    if (params.rowsPerPage) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}rowsPerPage=${params.rowsPerPage}`
    }

    return axios.get(`${Config.SUPPORT_API_URL}news/latest${query}`)
  },

  getFavouritesNews: async (params) => {
    let query = ''

    if (params.sortBy) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}sortBy=${params.sortBy}`
    }

    if (params.hasOwnProperty('page')) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}page=${params.page}`
    }

    if (params.rowsPerPage) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}rowsPerPage=${params.rowsPerPage}`
    }

    return axios.get(`${Config.SUPPORT_API_URL}news/favourites${query}`)
  },

  getAugmentedInformation: async (params) => {
    let query = ''

    if (params.country) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}country=${params.country}`
    }

    if (params.sector) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}sector=${params.sector}`
    }

    if (params.investment_stage) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}investment_stage=${params.investment_stage}`
    }

    return axios.get(`${Config.SUPPORT_API_URL}/augmented-information${query}`)
  },

  saveEventApplication: (eventId, data, isFromShared = false) => {
    return axios.post(`${Config.SUPPORT_API_URL}events/event-application/${eventId}`, data, {
      ...(isFromShared ? { headers: { 'X-Shared': 'true' } } : {})
    })
  },

  getEventStats: async (eventId) => {
    return axios.get(`${Config.SUPPORT_API_URL}events/${eventId}/stats`)
  },

  addStartupToEvent: async (eventId, data) => {
    return axios.post(`${Config.SUPPORT_API_URL}events/${eventId}/startups`, data)
  },

  inviteJudgeToEvent: async (eventId, judge, isFromShared = false) => {
    return axios.post(`${Config.SUPPORT_API_URL}events/${eventId}/judges`, judge, {
      ...(isFromShared ? { headers: { 'X-Shared': 'true' } } : {})
    })
  },

  inviteJudgeToPool: async (judge) => {
    return axios.post(`${Config.SUPPORT_API_URL}judges/pool`, judge)
  },

  getJudges: async (eventId, params) => {
    if (params) {
      let query = ''

      if (params.sortBy) {
        query = `${query}${query ? '&' : '?'}`
        query = `${query}sortBy=${params.sortBy}`
      }

      if (params.hasOwnProperty('page')) {
        query = `${query}${query ? '&' : '?'}`
        query = `${query}page=${params.page}`
      }

      if (params.rowsPerPage) {
        query = `${query}${query ? '&' : '?'}`
        query = `${query}rowsPerPage=${params.rowsPerPage}`
      }

      return axios.get(`${Config.SUPPORT_API_URL}events/${eventId}/judges${query}`)
    } else {
      return axios.get(`${Config.SUPPORT_API_URL}events/${eventId}/judges`)
    }
  },

  getJudge: async (eventId, judgeId, isFromShared = false) => {
    return axios.get(`${Config.SUPPORT_API_URL}events/${eventId}/judge/${judgeId}`, {
      ...(isFromShared ? { headers: { 'X-Shared': 'true' } } : {})
    })
  },

  getStartups: async (eventId, params, isFromShared = false) => {
    const query = new URLSearchParams(params)
    return axios.get(`${Config.SUPPORT_API_URL}events/${eventId}/startups?${query}`, {
      ...(isFromShared ? { headers: { 'X-Shared': 'true' } } : {})
    })
  },

  updateEvent: async (eventId, data) => {
    return axios.put(`${Config.SUPPORT_API_URL}events/${eventId}`, data)
  },

  exportResponses: async (eventId) => {
    return axios.get(`${Config.SUPPORT_API_URL}events/${eventId}/export/responses`)
  },

  exportEvaluationsPDF: async (eventId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Config.SUPPORT_API_URL}events/${eventId}/export/evaluations/pdf`, {
          responseType: 'blob'
        })
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'evaluation-report.pdf')
          document.body.appendChild(link)
          link.click()
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  exportEvaluationsCSV: async (eventId) => {
    return axios.get(`${Config.SUPPORT_API_URL}events/${eventId}/export/evaluations/csv`)
  },

  updateEventStartup: async (eventId, startupId, data) => {
    return axios.put(`${Config.SUPPORT_API_URL}events/${eventId}/startups/${startupId}`, data)
  },

  deleteEventStartup: async (eventId, startupId) => {
    return axios.delete(`${Config.SUPPORT_API_URL}events/${eventId}/startups/${startupId}`)
  },

  deleteJudge: async (eventId, judgeId) => {
    return axios.delete(`${Config.SUPPORT_API_URL}events/${eventId}/judges/${judgeId}`)
  },

  getEventStartup: async (eventId, startupId, isFromShared = false) => {
    return axios.get(`${Config.SUPPORT_API_URL}events/${eventId}/startups/${startupId}`, {
      ...(isFromShared ? { headers: { 'X-Shared': 'true' } } : {})
    })
  },

  getEventStartupEvaluation: async (eventId, startupId, judgeId) => {
    return axios.get(`${Config.SUPPORT_API_URL}events/${eventId}/startups/${startupId}/evaluation?judge=${judgeId}`)
  },

  updateJudge: async (eventId, judgeId, data) => {
    return axios.put(`${Config.SUPPORT_API_URL}events/${eventId}/judges/${judgeId}`, data)
  },

  getEventStartupNotes: (eventId, startupId) => {
    return axios.get(`${Config.SUPPORT_API_URL}notes?event=${eventId}&startup=${startupId}`)
  },

  createNote: (data) => {
    return axios.post(`${Config.SUPPORT_API_URL}notes`, data)
  },

  getNote: async (noteId) => {
    return axios.get(`${Config.SUPPORT_API_URL}notes/${noteId}`)
  },

  updateNote: async (noteId, data) => {
    return axios.patch(`${Config.SUPPORT_API_URL}notes/${noteId}`, data)
  },

  deleteNote: async (noteId) => {
    return axios.delete(`${Config.SUPPORT_API_URL}notes/${noteId}`)
  },

  investmentMemorandumPDF: async (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Config.SUPPORT_API_URL}reporting/investment-memorandum`, data, {
          responseType: 'blob'
        })
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'investment-memorandum.pdf')
          document.body.appendChild(link)
          link.click()
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  onePagerStartupPDF: async (startupId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Config.SUPPORT_API_URL}reporting/one-pager/${startupId}`, {
          responseType: 'blob'
        })
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'one-pager.pdf')
          document.body.appendChild(link)
          link.click()
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  kpisReportStartupPDF: async (startupId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Config.SUPPORT_API_URL}reporting/kpis-report/${startupId}`, {
          responseType: 'blob'
        })
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'kpis-report.pdf')
          document.body.appendChild(link)
          link.click()
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  downloadExternalJudgeEvaluation: async (judge, startup, event) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Config.SUPPORT_API_URL}reporting/evaluation-report/events/${event._id}/external-judges/${judge._id}/startups/${startup._id}`, {
          responseType: 'blob'
        })
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${event.name.replace(' ', '-')}-${judge.judge.replace(' ', '-')}-${startup.name}-evaluation-report.pdf`)
          document.body.appendChild(link)
          link.click()
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  downloadEvaluation: async (judge, startup, event) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Config.SUPPORT_API_URL}reporting/evaluation-report/events/${event._id}/judges/${judge.id}/startups/${startup._id}`, {
          responseType: 'blob'
        })
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${event.name.replace(' ', '-')}-${judge.value.replace(' ', '-')}-${startup.name}-evaluation-report.pdf`)
          document.body.appendChild(link)
          link.click()
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  evaluationReportPDF: async (startupId, eventId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Config.SUPPORT_API_URL}reporting/evaluation-report/${startupId}/${eventId}`, {
          responseType: 'blob'
        })
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'evaluation-report.pdf')
          document.body.appendChild(link)
          link.click()
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getRecentActivity: async (params) => {
    const query = new URLSearchParams(params)
    return axios.get(`${Config.SUPPORT_API_URL}recent-activity?${query}`)
  },

  uploadDocument: async (data) => {
    return await axios.post(
      `${Config.SUPPORT_API_URL}documents`,
      data
    )
  },

  uploadLink: async (data) => {
    return await axios.post(
      `${Config.SUPPORT_API_URL}documents/link`,
      data
    )
  },

  saveDocumentInForm: async (data) => {
    return await axios.post(
      `${Config.SUPPORT_API_URL}documents/forms`,
      data
    )
  },

  saveDocumentInScoreCard: async (data) => {
    return await axios.post(
      `${Config.SUPPORT_API_URL}documents/score-cards`,
      data
    )
  },

  saveImageSelectorOption: async (data) => {
    return await axios.post(
      `${Config.SUPPORT_API_URL}documents/image-selector-option`,
      data
    )
  },

  saveSeparatorImage: async (data) => {
    return await axios.post(
      `${Config.SUPPORT_API_URL}documents/separator-image`,
      data
    )
  },

  getDocuments: async (params, shared = false) => {
    if (params) {
      let query = ''

      if (params.sortBy) {
        query = `${query}${query ? '&' : '?'}`
        query = `${query}sortBy=${params.sortBy}`
      }

      if (params.hasOwnProperty('page')) {
        query = `${query}${query ? '&' : '?'}`
        query = `${query}page=${params.page}`
      }

      if (params.rowsPerPage) {
        query = `${query}${query ? '&' : '?'}`
        query = `${query}rowsPerPage=${params.rowsPerPage}`
      }

      if (params.startup) {
        query = `${query}${query ? '&' : '?'}`
        query = `${query}startup=${params.startup}`
      }

      if (params.entrepreneur) {
        query = `${query}${query ? '&' : '?'}`
        query = `${query}entrepreneur=${params.entrepreneur}`
      }

      if (params.event_startup) {
        query = `${query}${query ? '&' : '?'}`
        query = `${query}event_startup=${params.event_startup}`
      }

      if (params.name) {
        query = `${query}${query ? '&' : '?'}`
        query = `${query}name=${params.name}`
      }

      if (params.tags) {
        query = `${query}${query ? '&' : '?'}`
        query = `${query}tags=${params.tags}`
      }

      if (shared) {
        return axios.get(`${Config.SUPPORT_API_URL}documents/shared${query}`)
      } else {
        return axios.get(`${Config.SUPPORT_API_URL}documents${query}`)
      }
    } else {
      return axios.get(`${Config.SUPPORT_API_URL}documents`)
    }
  },

  getDocument: async (documentId, shared = false) => {
    if (shared) {
      return await axios.get(
        `${Config.SUPPORT_API_URL}documents/shared/${documentId}`
      )
    } else {
      return await axios.get(
        `${Config.SUPPORT_API_URL}documents/${documentId}`
      )
    }
  },

  getDocumentForForm: async (documentId, shared) => {
    if (shared) {
      return await axios.get(
        `${Config.SUPPORT_API_URL}documents/forms/shared/${documentId}`
      )
    } else {
      return await axios.get(
        `${Config.SUPPORT_API_URL}documents/forms/${documentId}`
      )
    }
  },

  getDocumentForScoreCard: async (documentId) => {
    return await axios.get(
      `${Config.SUPPORT_API_URL}documents/score-cards/${documentId}`
    )
  },

  deleteDocument: async (documentId) => {
    return await axios.delete(
      `${Config.SUPPORT_API_URL}documents/${documentId}`
    )
  },

  updateDocument: async (documentId, data) => {
    return await axios.patch(
      `${Config.SUPPORT_API_URL}documents/${documentId}`, data
    )
  },

  getDocumentsStats: async () => {
    return await axios.get(
      `${Config.SUPPORT_API_URL}documents/stats`
    )
  },

  getMilestoneSections: async (dealId) => {
    return await axios.get(
      `${Config.SUPPORT_API_URL}milestones/sections?startup=${dealId}`
    )
  },

  createMilestoneSection: async (data) => {
    return await axios.post(
      `${Config.SUPPORT_API_URL}milestones/sections`,
      data
    )
  },

  getMilestoneGoals: async (dealId) => {
    return await axios.get(
      `${Config.SUPPORT_API_URL}milestones/goals?startup=${dealId}`
    )
  },

  createMilestoneGoal: async (data) => {
    return await axios.post(
      `${Config.SUPPORT_API_URL}milestones/goals`,
      data
    )
  },

  deleteMilestoneGoal: async (goalId, dealId) => {
    return await axios.delete(
      `${Config.SUPPORT_API_URL}milestones/goals/${goalId}?startup=${dealId}`
    )
  },

  deleteMilestoneSection: async (sectionId, dealId, newSectionId, purge) => {
    return await axios.delete(
      `${Config.SUPPORT_API_URL}milestones/sections/${sectionId}?startup=${dealId}&destination=${newSectionId}&purge=${purge}`
    )
  },

  updateMilestoneSection: async (sectionId, dealId, data) => {
    return await axios.patch(
      `${Config.SUPPORT_API_URL}milestones/sections/${sectionId}?startup=${dealId}`,
      data
    )
  },

  updateMilestoneGoal: async (goalId, dealId, data) => {
    return await axios.patch(
      `${Config.SUPPORT_API_URL}milestones/goals/${goalId}?startup=${dealId}`,
      data
    )
  },

  sendConnectionRequest: async (investorId, connectionMessage) => {
    return await axios.post(
      `${Config.SUPPORT_API_URL}connections`,
      { investor: investorId, message: connectionMessage }
    )
  },

  getConnections: async (status) => {
    return await axios.get(
      `${Config.SUPPORT_API_URL}connections?status=${status}`
    )
  },

  removeConnection: async (connectionId) => {
    return await axios.delete(
      `${Config.SUPPORT_API_URL}connections/${connectionId}`
    )
  },

  declineConnection: async (connectionId) => {
    return await axios.post(
      `${Config.SUPPORT_API_URL}connections/${connectionId}/decline`, {}
    )
  },

  acceptConnection: async (connectionId) => {
    return await axios.post(
      `${Config.SUPPORT_API_URL}connections/${connectionId}/accept`, {}
    )
  },

  cancelConnectionRequest: async (connectionId) => {
    return await axios.post(
      `${Config.SUPPORT_API_URL}connections/${connectionId}/cancel`, {}
    )
  },

  declineConnectionRequest: async (connectionId) => {
    return await axios.post(
      `${Config.SUPPORT_API_URL}connections/${connectionId}/decline`, {}
    )
  },

  acceptConnectionRequest: async (connectionId) => {
    return await axios.post(
      `${Config.SUPPORT_API_URL}connections/${connectionId}/accept`, {}
    )
  },

  generateEDAReportFromEvent: async (eventId, questions) => {
    return await axios.post(
      `${Config.SUPPORT_API_URL}reporting/eda/event/${eventId}`, { questions }
    )
  },

  generateEDAReportFromBoard: async (boardId, questions) => {
    return await axios.post(`${Config.SUPPORT_API_URL}reporting/eda/board/${boardId}`, { questions })
  },

  getEDAHistoricalData: async (query) => {
    const params = new URLSearchParams(query)
    return await axios.get(`${Config.SUPPORT_API_URL}reporting/eda?${params}`)
  },

  getEDAReport: async (reportId) => {
    return await axios.get(`${Config.SUPPORT_API_URL}reporting/eda/${reportId}`)
  },

  getAllJudges: async () => {
    return axios.get(`${Config.SUPPORT_API_URL}judges`)
  },

  deleteJudgeNew: async (judgeId) => {
    return axios.delete(`${Config.SUPPORT_API_URL}judges/${judgeId}`)
  },

  updateJudgeNew: async (judgeId, data) => {
    return axios.put(`${Config.SUPPORT_API_URL}judges/${judgeId}`, data)
  },

  getJudgeEvaluationFromPool: async (judgeId, dealId) => {
    return axios.get(`${Config.SUPPORT_API_URL}evaluations/dealflow/${dealId}?judge=${judgeId}`)
  },

  getIrmInvestorAnnotations: (irmInvestorId) => {
    return axios.get(`${Config.SUPPORT_API_URL}notes?irm_investor=${irmInvestorId}`)
  },

  getNotes: (filters) => {
    let query = ''

    Object.keys(filters).forEach(key => {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}${key}=${filters[key]}`
    })
    return axios.get(`${Config.SUPPORT_API_URL}notes${query}`)
  },

  checkProcessEvaluationAccess: (eventId, accessToken) => {
    return axios.get(`${Config.SUPPORT_API_URL}events/${eventId}/check-access?access_token=${accessToken}`)
  },

  getSharedDeal: async (dealId) => {
    return axios.get(`${Config.SUPPORT_API_URL}shared-deal/${dealId}`)
  },

  contactApplicationKmZero: async (data) => {
    return axios.post(`${Config.SUPPORT_API_URL}contact-application-kmzero`, data)
  },

  commentApplicationKmZero: async (data) => {
    return axios.post(`${Config.SUPPORT_API_URL}comment-application-kmzero`, data)
  },

  getReminders: (filters) => {
    let query = ''

    Object.keys(filters).forEach(key => {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}${key}=${filters[key]}`
    })
    return axios.get(`${Config.SUPPORT_API_URL}reminders${query}`)
  },

  createReminder: (data) => {
    return axios.post(`${Config.SUPPORT_API_URL}reminders`, data)
  },

  getReminder: async (reminderId) => {
    return axios.get(`${Config.SUPPORT_API_URL}reminders/${reminderId}`)
  },

  updateReminder: async (reminderId, data) => {
    return axios.patch(`${Config.SUPPORT_API_URL}reminders/${reminderId}`, data)
  },

  deleteReminder: async (reminderId) => {
    return axios.delete(`${Config.SUPPORT_API_URL}reminders/${reminderId}`)
  },

  getRemindersResume: async (reminderId) => {
    return axios.get(`${Config.SUPPORT_API_URL}reminders/resume`)
  }

}
export default SupportService
