import moment from 'moment'
import { Card } from 'components/ui/atoms/Card'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SupportService from 'services/support-service'
import Config from 'config'

export const EventBasicInfo = ({ event }) => {
  const { t } = useTranslation()
  const [stats, setStats] = useState({})

  const getEndDate = () => {
    if (event.end_date) {
      return moment(event.end_date).format('DD/MM/YYYY')
    }

    return '-'
  }

  const getStats = () => {
    SupportService.getEventStats(event._id).then((result) => {
      setStats(result.data.data)
    })
  }

  useEffect(() => {
    if (event) {
      getStats()
    }
  }, [event])

  return (
    <div className="grid grid-cols-3 gap-4 mt-6 mb-6">
      <Card containerClassName="text-center">
        <div className="">{t('startups')}</div>
        <div className={`text-main-${Config.PLATFORM} text-2xl`}>
          {stats?.startupsCount || 0}
        </div>
      </Card>

      <Card containerClassName="text-center">
        <div className="">{t('average_score')}</div>
        <div className={`text-main-${Config.PLATFORM} text-2xl`}>
          {stats?.evaluationsAverage >= 0 ? stats.evaluationsAverage.toFixed(2) : '-'}
        </div>
      </Card>

      <Card containerClassName="text-center">
        <div className="">{t('end_date')}</div>
        <div className={`text-main-${Config.PLATFORM} text-2xl`}>
          {getEndDate()}
        </div>
      </Card>
    </div>
  )
}
