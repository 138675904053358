import { useState, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { RoutesLinks } from 'components/routes-links'
import { useToasts } from 'react-toast-notifications'

import ProjectsService from 'services/projects-service'

import { Loader } from 'components/ui/molecules/Loader'
import { Button } from 'components/ui/atoms/Button'

import ArrowGoBack from 'styles/images/arrow_left.svg'

import { isEmpty } from 'lodash'
import { useSwal } from 'hooks/useSwal'
import useUser from 'hooks/useUser'
import DataRoomAccess from './DataRoomAccess'
import DataRoomSections from './DataRoomSections'
import DataRoomStatistics from './DataRoomStatistics'
import { PageTitle } from 'components/ui/atoms/PageTitle'
import { ButtonDanger } from 'components/ui/atoms/ButtonDanger'
import { FaTrash } from 'react-icons/fa'

const DataRoom = () => {
  const { user } = useUser()
  const { dataRoomId } = useParams()
  const projectId = user.projects.length ? user.projects[0]._id : null
  const history = useHistory()
  const { t } = useTranslation()
  const handleRedirect = (url) => history.push(url)
  const { addToast } = useToasts()
  const { confirm } = useSwal()
  const [dataRoom, setDataRoom] = useState({})

  const [loading, setLoading] = useState(false)
  const [loadingDataRoom, setLoadingDataRoom] = useState(true)

  const getDataRoom = useCallback(async () => {
    try {
      setLoadingDataRoom(true)
      const result = await ProjectsService.getDataRoom(dataRoomId)
      setDataRoom(result.data)
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t('error_occurred_retrieving_data_room'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
      handleRedirect(`${RoutesLinks.DATA_ROOMS_LINK}`)
      console.error(error)
    } finally {
      setLoadingDataRoom(false)
    }
  }, [])

  useEffect(() => {
    getDataRoom()
  }, [projectId])

  const deleteDataRoom = () => {
    confirm({
      icon: 'warning'
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true)
          await ProjectsService.deleteDataRoom(projectId, dataRoomId)

          addToast(t('data_room_successfully_deleted'), {
            appearance: 'success',
            autoDismiss: true
          })
          handleRedirect(`${RoutesLinks.DATA_ROOMS_LINK}`)
          setLoading(false)
        } catch (error) {
          addToast(
            error.response && error.response.data.msg_key
              ? error.response.data.msg_key
              : t('error_occurred_deleting_guest'),
            {
              appearance: 'error',
              autoDismiss: true
            }
          )
        } finally {
          setLoading(false)
        }
      }
    })
  }

  return (
    <>
    <div className="w-full flex flex-row justify-between">
      <div className='flex'>
        <div className='flex-1'>
          <PageTitle
            title={isEmpty(dataRoom)
              ? t('data_room')
              : `${t('data_room')}: ${dataRoom.name}`}
            showBack={true}
            onBackClick={() => history.push(RoutesLinks.DATA_ROOMS_LINK)}
          />
        </div>
      </div>
      <div>
      <ButtonDanger
            onClick={deleteDataRoom}
            text={t('delete_data_room')}
            iconComponent={<FaTrash className="inline-block mr-3" />}
          />
      </div>
    </div>
      <>
        <DataRoomAccess dataRoom={dataRoom} getDataRoom={getDataRoom} loading={loading} setLoading={setLoading} />
        <DataRoomSections dataRoom={dataRoom} getDataRoom={getDataRoom} loading={loading} setLoading={setLoading} />
        <DataRoomStatistics dataRoom={dataRoom} />
      </>
      {(loading || loadingDataRoom) && <Loader />}
    </>
  )
}

export default DataRoom
