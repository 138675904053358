import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { DealflowService } from '../../services/dealflow-service'
import { Loader } from '../ui/molecules/Loader'
import { PageTitle } from '../ui/atoms/PageTitle'
import { Button } from '../ui/atoms/Button'
import { AiOutlineMore, FaPlus, FaPlusCircle } from 'react-icons/all'
import { ManageTagsModal } from '../tags/ManageTagsModal'
import { useSwal } from '../../hooks/useSwal'
import DealflowActions from './DealFlowActions'
import { StartupGeneralInformation } from '../startup-profile/StartupGeneralInformation'
import { SectionHeader } from '../ui/molecules/SectionHeader'
import { ApplicationQuestionnaireView } from '../ui/objects/ApplicationQuestionnaireView'
import { DocumentList } from '../documentation/documents/components/DocumentList'
import Milestones from '../milestones'
import Annotations from '../annotations'
import Tracking from '../tracking';
import { StartupKPIs } from '../startup-profile/components/StartupKPIs'
import { AugmentedInformation } from '../startup-profile/components/AugmentedInformation'
import { InvestorsMatching } from '../startup-profile/components/InvestorsMatching'
import { RequestProfileVisibility } from '../startup-profile/components/RequestProfileVisibility'
import SupportService from '../../services/support-service'
import { RoutesLinks } from '../routes-links'
import Select from '../ui/atoms/Select'
import ReactTooltip from 'react-tooltip'
import EvaluationDealflow from 'components/evaluations/EvaluationDealflow'
import Config from 'config'
import { CircularChard } from 'components/ui/molecules/charts/CircularChart'
import moment from 'moment'
import { ResponsiveSwarmPlot } from '@nivo/swarmplot'
import { KiotaGammaEvaluation } from 'components/startup-profile/components/KiotaGammaEvaluation'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import RegisterInvestmentSection from './RegisterInvestmentSection'
import RejectDealSection from './RejectDealSection'
import MarkStartupAsFailedSection from './MarkStartupAsFailedSection'
import RegisterExitSection from './RegisterExitSection'
import DeleteDealSection from './DeleteDealSection'
import DealflowForms from './DealflowForms'
import ReminderSection from 'components/reminders/ReminderSection'
import RemindersOperations from 'redux/reminders/reminders-operations'
import { useDispatch } from 'react-redux'

export const DealflowView = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { dealId } = useParams()
  const { confirm } = useSwal()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [loadingReminders, setLoadingReminders] = useState(false)
  const [deal, setDeal] = useState(null)
  const [showTagsModal, setShowTagsModal] = useState(false)
  const [showDealflowActions, setShowDealflowActions] = useState(false)
  const [showRegisterInvestmentSection, setShowRegisterInvestmentSection] = useState(false)
  const [showRejectDealSection, setShowRejectDealSection] = useState(false)
  const [showMarkAsFailedSection, setShowMarkAsFailedSection] = useState(false)
  const [showRegisterExitSection, setShowRegisterExitSection] = useState(false)
  const [showDeleteDealSection, setShowDeleteDealSection] = useState(false)
  const [investments, setInvestments] = useState([])
  const [investorView, setInvestorView] = useState(true)
  const [section, setSection] = useState('')
  const [sections, setSections] = useState([])
  const [projectModel, setProjectModel] = useState('managed_project')
  const [showReminderSection, setShowReminderSection] = useState(false)
  const [reminderToEdit, setReminderToEdit] = useState(null)
  const [reminders, setReminders] = useState([])

  const setRemindersOperations = (reminders) =>
  dispatch(RemindersOperations.setReminders(reminders))

const setTotalReminders = (total) =>
  dispatch(RemindersOperations.setTotalReminders(total))

  const [evaluation, setEvaluation] = useState(null)

  const documentListRef = useRef()
  const kiotaGammaEvaluationRef = useRef()

  const getDeal = async () => {
    try {
      setLoading(true)
      const result = await DealflowService.getDeal(dealId)
      if (result.data) {
        setDeal(result.data)
      } else {
        addToast(t('error_occurred_retrieving_dealflow'), { appearance: 'error', autoDismiss: true })
        history.push(RoutesLinks.DEALFLOW_LINK)
      }
    } catch (error) {
      addToast(t('error_occurred_retrieving_dealflow'), { appearance: 'error', autoDismiss: true })
      history.goBack()
    } finally {
      setLoading(false)
    }
  }

  const getInvestments = async () => {
    try {
      setLoading(true)
      const result = await DealflowService.getInvestments(dealId);
      setInvestments(result.data)
    } catch (error) {
      addToast(t('error_occurred_retrieving_dealflow'), { appearance: 'error', autoDismiss: true })
    } finally {
      setLoading(false)
    }
  }

  const getKiotaGammaEvaluation = async () => {
    try {
      setLoading(true)
      const result = await SupportService.getKiotaGammaEvaluation(dealId)
      if (result.data.data) {
        setEvaluation({
          general_error: result.data.data.general_error,
          growth_robustness_comparison_error: result.data.data.growth_robustness_comparison_error,
          survival_robustness_comparison_error: result.data.data.survival_robustness_comparison_error,
          pre_valuation_robustness_comparison_error: result.data.data.pre_valuation_robustness_comparison_error,
          general_result: result.data.data.general_result,
          growth_robustness_comparison: result.data.data.growth_robustness_comparison,
          survival_robustness_comparison: result.data.data.survival_robustness_comparison,
          pre_valuation_robustness_comparison: result.data.data.pre_valuation_robustness_comparison,
          createdAt: result.data.data.createdAt
        })
      }
    } catch (error) {
      addToast(t('error_occurred_retrieving_information'), { appearance: 'error', autoDismiss: true })
    } finally {
      setLoading(false)
    }
  }

  const updateDealTags = (tags) => {
    setLoading(true)
    return DealflowService.updateDealTags(dealId, tags)
      .then(() => getDeal())
      .catch(() => {
        addToast(t('error_occurred_updating_tags'), { appearance: 'error', autoDismiss: true })
      }).finally(() => {
        setLoading(false)
        setShowTagsModal(false)
      })
  }

  const deleteDeal = (data) => {
    confirm({text: t('delete_deal_description')}).then(isConfirmed => {
      if (isConfirmed) {
        setLoading(true)
        DealflowService.deleteDeal(dealId, data).then(() => {
          addToast(t('deal_deleted'), { appearance: 'success', autoDismiss: true })
          history.goBack()
        }).catch(() => {
          addToast(t('error_occurred'), { appearance: 'error', autoDismiss: true })
        }).finally(() => {
          setLoading(false)
        })
      }
    })
  }

  const deleteReminder = (reminderId) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true)
          await SupportService.deleteReminder(reminderId)
          getReminders()
          getRemindersResume()
          addToast(t('reminder_deleted_successfully'), { appearance: 'success', autoDismiss: true })
        } catch (error) {
          addToast(t('error_occurred_deleting_reminder'), { appearance: 'error', autoDismiss: true })
        } finally {
          setLoading(false)
        }
      }
    })
  }

  const markReminderAsCompleted = (reminder) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true)
          await SupportService.updateReminder(reminder._id, { completed: true })
          getReminders()
          getRemindersResume()
          addToast(t('reminder_marked_as_completed_successfully'), { appearance: 'success', autoDismiss: true })
        } catch (error) {
          addToast(t('error_occurred_marking_reminder_as_completed'), { appearance: 'error', autoDismiss: true })
        } finally {
          setLoading(false)
        }
      }
    })
  }

  const downloadOnePager = () => {
    if (!deal?.project?._id) {
      return
    }

    setLoading(true)
    SupportService.onePagerStartupPDF(deal?.project?._id).catch(() => {
      addToast(t('error_occurred'), { appearance: 'error', autoDismiss: true })
    }).finally(() => {
      setLoading(false)
      setShowDealflowActions(false)
    })
  }

  const downloadKpisReport = () => {
    if (!deal?.project?._id) {
      return
    }

    setLoading(true)
    SupportService.kpisReportStartupPDF(deal?.project?._id).catch(() => {
      addToast(t('error_occurred'), { appearance: 'error', autoDismiss: true })
    }).finally(() => {
      setLoading(false)
      setShowDealflowActions(false)
    })
  }

  const requestAccess = (section) => {
    setLoading(true)
    DealflowService.requestProfileVisibility(deal._id, section).then(() => {
      addToast(t('dealflow_requested_visibility_success'), {
        appearance: 'success',
        autoDismiss: true
      })
    }).finally(() => setLoading(false))
  }

  const haveManagedProject = () => {
    return deal?.managed_project && Object.keys(deal?.managed_project || {}).length > 0
  }

  useEffect(() => {
    if (deal) {
      getKiotaGammaEvaluation()

      const _sections = []

      _sections.push('general_information')
      _sections.push('kpis')

      if (deal.managed_startup) {
        _sections.push('entry_form')
      }

      _sections.push('documents')
      _sections.push('annotations')
      _sections.push('evaluations')
      _sections.push('milestones')
      _sections.push('tracking')

      if (Config.PLATFORM === '000') {
        _sections.push('questionnaires')
        _sections.push('investors_matching')
        _sections.push('our_analysis')
      }

      setSections(_sections)
      setSection(_sections[0])

      setProjectModel(deal.project ? 'project' : haveManagedProject() ? 'managed_project' : 'project')
    }
  }, [deal])

  const getReminders = async () => {
    try {
      setLoadingReminders(true)
      const result = await SupportService.getReminders({ deal: dealId, page: 0, rowsPerPage: 9999 })
      setReminders(result.data.reminders)
    } catch (error) {
      console.error(error)
      addToast(t('error_occurred_retrieving_reminders'), {
        appearance: 'error',
        autoDismiss: true
      })
    } finally {
      setLoadingReminders(false)
    }
  }

  const getRemindersResume = async () => {
    try {
      const result = await SupportService.getRemindersResume()
      if (result.data) {
        setRemindersOperations(result.data.reminders)
        setTotalReminders(result.data.total)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getDeal()
    getInvestments()
    getReminders()
  }, [])

  if (!deal) {
    return null
  };

  const registerInvestment = async (values) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true)
          if (values.is_own_investment) {
            delete values.shareholder
          }
          await DealflowService.registerInvestment(dealId, { ...values, sector: deal?.sector?._id })
          addToast(t('investment_registered_successfully'), { appearance: 'success', autoDismiss: true })
          setShowRegisterInvestmentSection(false)
          getDeal()
          getInvestments()
        } catch (error) {
          console.log(error)
          addToast(t('error_occurred_registering_investment'), { appearance: 'error', autoDismiss: true })
        } finally {
          setLoading(false)
        }
      }
    })
  };

  const rejectDeal = async (values) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true)
          await DealflowService.rejectDeal(dealId, values)
          addToast(t('startup_rejected_successfully'), { appearance: 'success', autoDismiss: true })
          setShowRejectDealSection(false)
          getDeal()
        } catch (error) {
          addToast(t('error_occurred_rejecting_startup'), { appearance: 'error', autoDismiss: true })
        } finally {
          setLoading(false)
        }
      }
    })
  }

  const markDealAsFailed = async (values) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true)
          await DealflowService.markAsFailed(dealId, values)
          addToast(t('startup_marked_as_failed_successfully'), { appearance: 'success', autoDismiss: true })
          setShowMarkAsFailedSection(false)
          getDeal()
        } catch (error) {
          addToast(t('error_occurred_marking_startup_as_failed'), { appearance: 'error', autoDismiss: true })
        } finally {
          setLoading(false)
        }
      }
    })
  }

  const registerExit = async (values) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true)
          await DealflowService.registerExit(dealId, values)
          addToast(t('exit_registered_successfully'), { appearance: 'success', autoDismiss: true })
          setShowRegisterExitSection(false)
          getDeal()
        } catch (error) {
          addToast(t('error_occurred_registering_exit'), { appearance: 'error', autoDismiss: true })
        } finally {
          setLoading(false)
        }
      }
    })
  }

  const markDealAsInvested = async (values) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true)
          await DealflowService.markAsInvested(dealId)
          addToast(t('startup_marked_as_invested_successfully'), { appearance: 'success', autoDismiss: true })
          setShowDealflowActions(false)
          getDeal()
        } catch (error) {
          addToast(t('error_occurred_marking_startup_as_invested'), { appearance: 'error', autoDismiss: true })
        } finally {
          setLoading(false)
        }
      }
    })
  }

  const markDealAsInteresting = async (values) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true)
          await DealflowService.markAsInteresting(dealId)
          addToast(t('startup_marked_as_interesting_successfully'), { appearance: 'success', autoDismiss: true })
          setShowDealflowActions(false)
          getDeal()
        } catch (error) {
          addToast(t('error_occurred_marking_startup_as_interesting'), { appearance: 'error', autoDismiss: true })
        } finally {
          setLoading(false)
        }
      }
    })
  }

  return (
    <>
      <div className="flex items-start">
        <div className="flex-1">
          <PageTitle title={deal?.name} showBack={true} />
        </div>

        {/*.Config.PLATFORM === '000' && ['kpis', 'general_information'].includes(section) && (
          <div className='scale-75 transform -mt-1.5 z-50 relative' data-tip={t('change_project_model')}>
            <Select
              isClearable={false}
              onSelect={selection => {
                setProjectModel(selection[0].id)
                ReactTooltip.hide()
              }}
              initialValues={[
                { id: t(projectModel), value: t(`${projectModel === 'managed_project' ? 'managed_' : ''}project_model`) }
              ]}
              items={[
                haveManagedProject() && { id: 'managed_project', value: t('managed_project_model') },
                deal?.project && { id: 'project', value: t('project_model') }
              ].filter(Boolean)}
            />
          </div>
        )*/}

        {section === 'our_analysis' && (!evaluation || (evaluation && evaluation.general_result && evaluation.growth_robustness_comparison && evaluation.survival_robustness_comparison && evaluation.pre_valuation_robustness_comparison)) && (
          <ButtonSecondary
          iconComponent={<FaPlus className='inline-block mr-2' />}
          marginRight='2'
          text={t('request_evaluation')}
          onClick={() => kiotaGammaEvaluationRef?.current?.showRequestEvaluationSection()}
        />
        )}
        {section === 'documents' && (
          <ButtonSecondary
          iconComponent={<FaPlus className='inline-block mr-2' />}
          marginRight='2'
          text={t('upload_document')}
          onClick={() => documentListRef?.current?.uploadDocument()}
        />
        )}

        <ButtonMain
          onClick={() => setShowDealflowActions(true)}
          text={t('actions')}
          iconComponent={<AiOutlineMore className="inline-block w-4 h-4" />}
        />
      </div>

      <SectionHeader
        sectionTitles={sections.map(section => t(section))}
        sectionKeys={sections}
        section={section}
        setSection={setSection}
        setShowFilters={() => documentListRef?.current?.showFilters()}
      />

      {section === 'general_information' && (
        <StartupGeneralInformation
          info={deal}
          investments={investments}
          requestAccess={requestAccess}
          investorView={investorView}
          setInvestorView={setInvestorView}
          reminders={reminders}
          deleteReminder={deleteReminder}
          setReminderToEdit={setReminderToEdit}
          markReminderAsCompleted={markReminderAsCompleted}
        />
      )}

      {section === 'kpis' && (
        <>
          <StartupKPIs startup={deal} />
        </>
      )}

      {section === 'augmented_information' && (
        <AugmentedInformation startup={deal} />
      )}

      {/*{section === 'entry_form' && (
        <ApplicationQuestionnaireView applicationQuestions={deal?.managed_startup?.application?.questions} />
      )}*/}

      {section === 'documents' && (
        <DocumentList dealId={deal?._id} ref={documentListRef} />
      )}

      {section === 'milestones' && (
        <Milestones dealId={deal?._id} />
      )}

      {section === 'evaluations' && (
        <EvaluationDealflow deal={deal} />
      )}

      {section === 'annotations' && (
        <Annotations dealId={deal?._id} />
      )}

      {section === 'investors_matching' && (
        <>
          <InvestorsMatching startup={deal} />
        </>
      )}

      {section === 'questionnaires' && (
        <DealflowForms
          deal={deal}
      />)}

      {section === 'our_analysis' && (
        <KiotaGammaEvaluation
          ref={kiotaGammaEvaluationRef}
          deal={deal}
          getKiotaGammaEvaluation={getKiotaGammaEvaluation}
          evaluation={evaluation}
        />)}

      {section === 'tracking' && (
        <Tracking deal={deal} />
      )}

      <ManageTagsModal
        category='dealflow'
        currentTags={deal.tags || []}
        handleCloseModal={() => setShowTagsModal(false)}
        onSubmit={tags => updateDealTags(tags)}
        showModal={showTagsModal}
      />

      {showDealflowActions && (
        <DealflowActions
          interesting={deal.interesting}
          invested={deal.invested}
          rejected={deal.rejected}
          exited={deal.exited}
          failed={deal.failed}
          committed={deal.committed}
          onClose={() => {
            setShowDealflowActions(false)
          }}
          showTags={() => setShowTagsModal(true)}
          deleteDeal={() => {
            setShowDealflowActions(false)
            setShowDeleteDealSection(true)
          }}
          registerInvestment={() => {
            setShowDealflowActions(false)
            setShowRegisterInvestmentSection(true)
          }}
          markAsFailed={() => {
            setShowDealflowActions(false)
            setShowMarkAsFailedSection(true)
          }}
          rejectDeal={() => {
            setShowDealflowActions(false)
            setShowRejectDealSection(true)
          }}
          markAsInvested={markDealAsInvested}
          markAsInteresting={markDealAsInteresting}
          registerExit={() => {
            setShowDealflowActions(false)
            setShowRegisterExitSection(true)
          }}
          contact={deal.main_contact_email ? () => window.location = `mailto:${deal.main_contact_email}` : deal?.project?.main_contact_email ? () => window.location = `mailto:${deal.project.main_contact_email}` : null }
          downloadOnePager={deal.type === 'projects' ? downloadOnePager : null}
          downloadKpisReport={deal.type === 'projects' ? downloadKpisReport : null}
          editInfo={() => history.push(`${RoutesLinks.DEALFLOW_LINK}${dealId}/edit`)}
          addReminder={() => setShowReminderSection(true)}

        />
      )}

      {showDeleteDealSection && (
        <DeleteDealSection
          onClose={() => setShowDeleteDealSection(false)}
          deleteDeal={deleteDeal}
        />
      )}

      {(showReminderSection || reminderToEdit) && (
        <ReminderSection
          onClose={() => {
            setReminderToEdit(null)
            setShowReminderSection(false)
          }}
          afterSubmit={() => {
            setReminderToEdit(null)
            setShowReminderSection(false)
            getReminders()
            getRemindersResume()
          }}
          dealId={dealId}
          initialValues={reminderToEdit}
        />
      )}
      {showRegisterInvestmentSection && (
        <RegisterInvestmentSection
          onClose={() => setShowRegisterInvestmentSection(false)}
          registerInvestment={registerInvestment}
          investments={investments}
        />
      )}

      {showRejectDealSection && (
        <RejectDealSection
          onClose={() => setShowRejectDealSection(false)}
          rejectDeal={rejectDeal}
        />
      )}

      {showMarkAsFailedSection && (
        <MarkStartupAsFailedSection
          onClose={() => setShowMarkAsFailedSection(false)}
          markStartupAsFailed={markDealAsFailed}
        />
      )}

      {showRegisterExitSection && (
        <RegisterExitSection
          onClose={() => setShowRegisterExitSection(false)}
          registerInvestment={registerExit}
        />
      )}

      {(loading || loadingReminders) && <Loader />}
    </>
  )
}
