import { useTranslation } from 'react-i18next'
import { SidePanel } from 'components/ui/objects/SidePanel'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import Config from 'config'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import Select from 'components/ui/atoms/Select'
import RejectionReasons from 'assets/json/rejection-reasons.json'
import { TextArea } from 'components/ui/atoms/TextArea'
import { CurrencyInput } from 'components/ui/atoms/CurrencyInput'
import { Input } from 'components/ui/atoms/Input'
import FormUtils from 'utils/form-utils'
import moment from 'moment'
import { Switch } from 'components/ui/atoms/Switch'
import { useToasts } from 'react-toast-notifications'
import { initial } from 'lodash'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { FaCalendarAlt, FaCheckCircle, FaClock, FaEnvelope, FaPhoneAlt } from 'react-icons/fa'
import { AiFillFlag } from 'react-icons/ai'
import { HiOutlineDotsCircleHorizontal } from 'react-icons/hi'
import ReactTooltip from 'react-tooltip'
import SupportService from 'services/support-service'
import { Loader } from 'components/ui/molecules/Loader'
import { useSwal } from 'hooks/useSwal'
import { Autocomplete } from 'components/ui/atoms/Autocomplete'
import { DealflowService } from 'services/dealflow-service'


/**
 * @param onClose
 * @returns {JSX.Element}
 * @constructor
 */
const ReminderSection = ({ onClose, initialValues, dealId, afterSubmit }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { confirm } = useSwal()

  const [loading, setLoading] = useState(false)
    const [deals, setDeals] = useState([])
    const [isPublic, setIsPublic] = useState(true);

  const { register, handleSubmit, errors, setValue, trigger, watch, getValues } = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    reValidateMode: 'all',
    resolver: yupResolver(Yup.object().shape({
        deal: Yup.string().required(t('required_field')),
        reminder: Yup.string().required(t('required_field')),
        reminder_type: Yup.string().required(t('required_field')),
        date: Yup.string()
        .required(t('required_field'))
        .transform((value) => FormUtils.parseDateFromString(value, 'YYYY-MM-DD HH:mm'))
      }), { abortEarly: false }),
  })


  const getDealflow = () => {
    return DealflowService.getDealflow({
      rowsPerPage: 999999,
      pool: 'true',
      populate: 'false',
      boards: 'false',
      milestones: 'false',
      files: 'false'
    }).then((response) => {
      setDeals(response.data.dealflow.map((d) => { return { name: d.name, id: d._id }}))
    })
  }

  useEffect(() => {
    register('reminder_type')
    register('deal')
  }, [register])

  useEffect(() => {
    if (dealId) {
        setValue('deal', dealId)
        trigger()
    }
  }, [dealId])

  useEffect(() => {
    getDealflow()
  }, [])

  useEffect(() => {
    if (!initialValues) {
        setValue('date', moment().format('YYYY-MM-DD'))
        setValue('reminder_type', 'other')
    } else {
        setValue('reminder', initialValues.reminder)
        setValue('date', initialValues.date ? moment(initialValues.date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'))
        setValue('reminder_type', initialValues.reminder_type)
        setIsPublic(!initialValues.is_private)
    }
    trigger()
  }, [initialValues])

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const createReminder = async (data) => {
    confirm().then(async (isConfirmed) => {
        if (isConfirmed) {
            try {
              setLoading(true)
              await SupportService.createReminder({
                deal: dealId,
                ...data
              })
              addToast(t('reminder_saved_successfully'), {
                appearance: 'success',
                autoDismiss: true
              })
              afterSubmit()
            } catch (error) {
                console.error(error)
              addToast(t('error_occurred_saving_reminder'), {
                appearance: 'error',
                autoDismiss: true
              })
            } finally {
              setLoading(false)
            }
        }
    })
  }

  const updateReminder = async (data) => {
    confirm().then(async (isConfirmed) => {
        if (isConfirmed) {
            try {
              setLoading(true)
              await SupportService.updateReminder(initialValues._id, data)
              addToast(t('reminder_updated_successfully'), {
                appearance: 'success',
                autoDismiss: true
              })
              afterSubmit()
            } catch (error) {
                console.error(error)
              addToast(t('error_occurred_updating_reminder'), {
                appearance: 'error',
                autoDismiss: true
              })
            } finally {
              setLoading(false)
            }
        }
    })
  }

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config)
    trigger(name)
  }

  return (
    <>
        {loading && <Loader />}
      <SidePanel
        width={'1/3'}
        onClose={onClose}
        title={t(initialValues ? 'update_reminder' : 'add_reminder')}
      >
        <div className="mt-2 flex flex-col w-full">
            <div className="w-full">
                <label className="block mb-1 text-left text-xs font-medium text-black">
                    {`${t('reminder_type')}:`} <span className={'ml-2 font-semibold text-gray'}>{t(getValues().reminder_type)}</span>
                </label>
                <div className="flex flex-row justify-start items-start mt-2 mb-4">
                    <div data-tip={t('call')}>
                    <ButtonSecondary
                    type={'button'}
                        onClick={() => {
                        setValue('reminder_type', 'call')
                        trigger()
                        }}
                        iconComponent={<FaPhoneAlt className="inline w-4 h-4" />}
                        marginRight={2}
                    />
                    </div>
                    <div data-tip={t('meeting')}>
                    <ButtonSecondary
                        type={'button'}
                        onClick={() => {
                        setValue('reminder_type', 'meeting')
                        trigger()
                        }}
                        iconComponent={ <FaCalendarAlt className="inline w-4 h-4" /> }
                        marginRight={2}
                    />
                    </div>
                    <div data-tip={t('task')}>
                    <ButtonSecondary
                        type={'button'}
                        onClick={() => {
                        setValue('reminder_type', 'task')
                        trigger()
                        }}
                        iconComponent={ <FaCheckCircle className="inline w-4 h-4" /> }
                        marginRight={2}
                    />
                    </div>
                    <div data-tip={t('deadline')}>
                    <ButtonSecondary
                        type={'button'}
                        onClick={() => {
                        setValue('reminder_type', 'deadline')
                        trigger()
                        }}
                        iconComponent={ <AiFillFlag className="inline w-4 h-4" /> }
                        marginRight={2}
                    />
                    </div>
                    <div data-tip={t('email')}>
                    <ButtonSecondary
                        type={'button'}
                        onClick={() => {
                        setValue('reminder_type', 'email')
                        trigger()
                        }}
                        iconComponent={ <FaEnvelope className="inline w-4 h-4" /> }
                        marginRight={2}
                    />
                    </div>
                    <div data-tip={t('other')}>
                    <ButtonSecondary
                        type={'button'}
                        onClick={() => {
                        setValue('reminder_type', 'other')
                        trigger()
                        }}
                        iconComponent={ <HiOutlineDotsCircleHorizontal className="inline w-4 h-4" /> }
                    />
                    </div>
                </div>
            </div>
            {!dealId && (
                <Autocomplete
                    name="deal"
                    placeholder={t('choose_or_type_startup')}
                    options={deals}
                    label={t('select_startup')}
                    error={errors.deal}
                    onSelect={deal => _setValue('deal', deal ? deal.id : null)}
                    initialValues={
                    watch('deal')
                        ? deals.find(item => item.id === watch('deal'))
                        : null
                    }
                />
            )}
            <Input
              reference={register}
              name="reminder"
              placeholder={t('reminder_placeholder')}
              label={t('reminder')}
              required={true}
              error={errors.reminder}
            />
            <Input
              reference={register}
              name="date"
              placeholder={t('date')}
              label={t('date')}
              type="date"
              required={true}
              error={errors.date}
            />
            <div className="w-full px-1 text-xs mt-2 mb-2 font-semibold">{t('would_you_like_to_share_remidner_with_your_team')}</div>
            <div className='mb-2'>
                <Switch
                checked={isPublic}
                onChange={(checked) => setIsPublic(checked)}
                text={isPublic ? t('yes') : t('no')}
                />
            </div>
            <div className={`flex justify-end mt-2 pt-2 border-t border-separator-${Config.PLATFORM}`}>
              <ButtonMain
                onClick={async () => {
                    const validation = await trigger()
                    if (validation) {
                        if (initialValues) {
                            updateReminder({ ...getValues(), is_private: !isPublic })
                        } else {
                            createReminder({ ...getValues(), is_private: !isPublic })
                        }
                    }
                    else {
                        addToast(t('form_field_error'), { appearance: 'error', autoDismiss: true })
                    }
                }}
                text={t(initialValues ? 'update_reminder' : 'add_reminder')}
              />
            </div>
        </div>
      </SidePanel>
    </>
  )
}

export default ReminderSection
