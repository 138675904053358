import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import { CoverBackground } from 'components/ui/atoms/CoverBackground'
import { Container } from 'components/ui/objects/Container'
import NotificationsOperations from 'redux/notifications/notifications-operations'
import RemindersOperations from 'redux/reminders/reminders-operations'
import { RoutesLinks } from 'components/routes-links'
import LoginForm from './components/login-form'
import AuthService from 'services/auth-service'
import Lang from 'lang'
import { useAuth } from '../../hooks/useAuth'
import useUser from '../../hooks/useUser'
import { useLoginEntries } from '../../hooks/useLoginEntries'

const Login = () => {
  const dispatch = useDispatch()
  const { token, setToken, setRefreshToken, setApiKey } = useAuth()
  const { setUserInfo } = useUser()
  const { setLoginEntries } = useLoginEntries()
  const { addToast } = useToasts()
  const { i18n, t } = useTranslation()
  const [loadingGoogleLogin, setLoadingGoogleLogin] = useState(false)
  const [loadingLinkedinLogin, setLoadingLinkedinLogin] = useState(false)
  const [loading, setLoading] = useState(false)

  const setInvitations = (invitations) =>
    dispatch(NotificationsOperations.setInvitations(invitations))

  const setReminders = (reminders) =>
    dispatch(RemindersOperations.setReminders(reminders))

  const setTotalReminders = (total) =>
    dispatch(RemindersOperations.setTotalReminders(total))

  const onSubmit = async ({ email, password, keep_logged }) => {
    try {
      setLoading(true)
      // const result = await AuthService.login(email, password, keep_logged);
      const result = await AuthService.login(email, password, true)
      Lang.setLang(result.data.user.preferences.language.code)
      await i18n.changeLanguage(result.data.user.preferences.language.code)

      setUserInfo(result.data.user)
      setToken(result.data.token)
      setApiKey(result.data.api_key)
      setLoginEntries(result.data.login_entries)
      setRefreshToken(result.data.refreshToken)
      setInvitations(result.data.invitations)
      if (result.data.reminders) {
        setReminders(result.data.reminders.reminders)
        setTotalReminders(result.data.reminders.total)
      }
    } catch (error) {
      console.log(error)
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t('error_occurred_login_in'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    } finally {
      setLoading(false)
    }
  }

  const responseSuccessGoogle = async (response) => {
    try {
      setLoadingGoogleLogin(true)
      const result = await AuthService.loginWithGoogle(response.tokenId)
      setLoadingGoogleLogin(false)
      await i18n.changeLanguage(result.data.user.language)
      setUserInfo(result.data.user)
      setToken(result.data.token)
      setApiKey(result.data.api_key)
      setLoginEntries(result.data.login_entries)
      setRefreshToken(result.data.refreshToken)
    } catch (error) {
      if (error) {
        console.log(error)

        setLoadingGoogleLogin(false)
        addToast(
          error.response.data.msg_key
            ? t(error.response.data.msg_key)
            : t('error_occurred'),
          {
            appearance: 'error',
            autoDismiss: true
          }
        )
      }
    }
  }

  const responseFailureGoogle = (response) => {
    setLoadingGoogleLogin(false)
    if (response.error !== 'popup_closed_by_user') {
      addToast(t('error_occurred'), {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  const responseSuccessLinkedin = async (response) => {
    try {
      setLoadingLinkedinLogin(true)
      const result = await AuthService.loginWithLinkedin(response.code)
      setLoadingLinkedinLogin(false)
      await i18n.changeLanguage(result.data.user.language)
      setUserInfo(result.data.user)
      setToken(result.data.token)
      setApiKey(result.data.api_key)
      setLoginEntries(result.data.login_entries)
      setRefreshToken(result.data.refreshToken)
    } catch (error) {
      if (error) {
        setLoadingLinkedinLogin(false)
        addToast(
          error.response.data.msg_key
            ? t(error.response.data.msg_key)
            : t('error_occurred'),
          {
            appearance: 'error',
            autoDismiss: true
          }
        )
      }
    }
  }

  const responseFailureLinkedin = (response) => {
    setLoadingLinkedinLogin(false)
    if (response.error !== 'popup_closed_by_user') {
      addToast(t('error_occurred'), {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  if (token) {
    return (
      <Redirect
        to={{
          pathname: RoutesLinks.CHECKS_LINK
        }}
      />
    )
  }

  return (
    <Container variant="session">
      <Container>
        <LoginForm
          activeTheme={'light'}
          onSubmit={onSubmit}
          responseSuccessGoogle={responseSuccessGoogle}
          responseFailureGoogle={responseFailureGoogle}
          responseSuccessLinkedin={responseSuccessLinkedin}
          responseFailureLinkedin={responseFailureLinkedin}
          loadingGoogleLogin={loadingGoogleLogin}
          loadingLinkedinLogin={loadingLinkedinLogin}
          loading={loading}
        />
      </Container>

      {/* <CoverBackground bg="logo"> */}
      <CoverBackground link={true}>
        <Container/>
      </CoverBackground>
    </Container>
  )
}

export default Login
