import { Card } from 'components/ui/atoms/Card'
import { Loader } from 'components/ui/molecules/Loader'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Sectors from 'assets/json/sectors.json'
import InvestmentStages from 'assets/json/investment-stages.json'
import Countries from 'assets/json/countries.json'
import ProjectStages from 'assets/json/project-stages.json'
import BusinessModels from 'assets/json/business-models.json'

import Config from 'config';
import Select from '../ui/atoms/Select'
import { FaTimes } from 'react-icons/all'
import { Input } from '../ui/atoms/Input'
import { Button } from '../ui/atoms/Button'
import { TextArea } from 'components/ui/atoms/TextArea'
import { CurrencyInput } from 'components/ui/atoms/CurrencyInput'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

const sortByKey = (obj, key) => {
  const resp = obj.sort((a, b) => {
    const nameA = a[key].toUpperCase();
    const nameB = b[key].toUpperCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    // names must be equal
    return 0;
  })
  return resp;
}

export const InvestorInvestmentThesis = ({ onSubmit, thesis, irm = false }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)

  const leadInvestorsOptions = [{ id: 'yes', value: t('yes') }, { id: 'no', value: t('no') }, { id: 'depends', value: t('depends') }]
  const followOnOptions = [{ id: 'yes', value: t('yes') }, { id: 'no', value: t('no') }, { id: 'depends', value: t('depends') }]

  const sectors = Sectors.map(item => ({ value: t(item.key), id: item._id })).sort((a, b) => a.value > b.value ? 1 : -1).reduce((prev, curr) => {
    const alreadyPushedIndex = prev.findIndex(i => i.id === curr.id)
    if (alreadyPushedIndex === -1) {
      prev.push(curr)
    }
    return prev
  }, [])

  const countries = Countries.map(item => ({
    ...item,
    id: item._id,
    value: `${t(item.key)}`
  }));
  const businessModels = BusinessModels.map(item => ({
    ...item,
    id: item._id,
    value: t(item.key)
  }));
  const projectStages = ProjectStages.map(item => ({
    ...item,
    id: item._id,
    value: t(item.key)
  }));
  const investmentStages = InvestmentStages.map(item => ({
    ...item,
    id: item._id,
    value: t(item.key)
  })).sort((a, b) => a.value - b.value);

  const countriesSorted = sortByKey(countries, 'value');
  const sectorsSorted = sortByKey(sectors, 'value');

  const { register, errors, trigger, setValue, handleSubmit, watch } = useForm({
    mode: 'all',
    criteriaMode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      Yup.object().shape({
        sectors: Yup.array().nullable(),
        business_models: Yup.array().nullable(),
        investment_stages: Yup.array().nullable(),
        project_stages: Yup.array().nullable(),
        countries: Yup.array().nullable(),
        lead_investor: Yup.array().nullable(),
        lead_investor_comments: Yup.string().nullable(),
        follow_on: Yup.array().nullable(),
        follow_on_comments: Yup.string().nullable(),
        minimum_ticket_unit: Yup.string(),
        maximum_ticket_unit: Yup.string(),
        maximum_valuation_unit: Yup.string(),
        minimum_valuation_unit: Yup.string(),
        minimum_ticket: Yup.number()
          .typeError(t('incorrect_format'))
          .transform((_, val) => val === `${Number(val)}` ? Number(val) : null)
          .nullable(),
        maximum_ticket: Yup.number()
          .typeError(t('incorrect_format'))
          .transform((_, val) => val === `${Number(val)}` ? Number(val) : null)
          .nullable(),
        minimum_valuation: Yup.number()
          .typeError(t('incorrect_format'))
          .transform((_, val) => val === `${Number(val)}` ? Number(val) : null)
          .nullable(),
        maximum_valuation: Yup.number()
          .typeError(t('incorrect_format'))
          .transform((_, val) => val === `${Number(val)}` ? Number(val) : null)
          .nullable()
      })
    )
  })


  const onSubmitForm = async (values) => {
    setLoading(true)
    console.log(values)
    await onSubmit(values)
    setLoading(false)
  }

  const onError = () => {
    addToast(t('form_field_error'), { appearance: 'error', autoDismiss: true })
  }

  const hydrateForm = () => {
    const selectables = [
      'sectors',
      'business_models',
      'investment_stages',
      'project_stages',
      'countries'
    ]

    if (!thesis.minimum_ticket_unit) {
      setValue('minimum_ticket_unit', 'k')
    }
    if (!thesis.maximum_ticket_unit) {
      setValue('maximum_ticket_unit', 'k')
    }
    if (!thesis.minimum_valuation_unit) {
      setValue('minimum_valuation_unit', 'k')
    }
    if (!thesis.maximum_valuation_unit) {
      setValue('maximum_valuation_unit', 'k')
    }

    Object.keys(thesis).forEach(key => {
      setValue(key, selectables.includes(key)
        ? (thesis[key] || []).map(item => item._id)
        : key === 'lead_investor' ? [thesis[key]] || [] : key === 'follow_on' ? [thesis[key]] || [] : ['minimum_ticket_unit', 'maximum_ticket_unit', 'minimum_valuation_unit', 'maximum_valuation_unit'].includes(key) ? thesis[key] || 'k' : thesis[key] || ''
      )
    })

    trigger()
  }

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, { shouldValidate: true, shouldTouch: true, ...config })
    trigger(name)
  }

  const renderLeadInvestorCard = () => {
    return (
      <div>

        <h5 className={`text-main-${Config.PLATFORM} text-lg font-bold border-b border-main-${Config.PLATFORM} mb-4`}>
          {t('lead_investor')}
        </h5>

        <Select
          name={'lead_investor'}
          placeholder={t(irm ? 'is_this_investor_lead' : 'do_you_lead_rounds')}
          items={leadInvestorsOptions}
          error={errors.lead_investor}
          multiSelect={false}
          isClearable={false}
          showQuantity={false}
          initialValues={leadInvestorsOptions.filter(i => watch('lead_investor')?.includes(i.id)) || []}
          onSelect={selection => _setValue('lead_investor', selection.map(item => item.id))}
        />
        <div className='my-2'>
          <TextArea
            reference={register}
            name={'lead_investor_comments'}
            error={errors.lead_investor_comments}
            label={t(irm ? 'lead_investor_comments' : 'do_you_have_comments_lead_investor')}
            maxLength={200}
            placeholder={t(irm ? 'lead_investor_comments' : 'do_you_have_comments_lead_investor')}
          />
        </div>
      </div>
    )
  }

  const renderFollowOnCard = () => {
    return (
      <div>
        <h5 className={`text-main-${Config.PLATFORM} text-lg font-bold border-b border-main-${Config.PLATFORM} mb-4`}>
          {t('follow_on')}
        </h5>

        <Select
          name={'follow_on'}
          placeholder={t(irm ? 'does_this_investor_follow_on' : 'do_you_follow_on')}
          items={followOnOptions}
          error={errors.follow_on}
          multiSelect={false}
          isClearable={false}
          showQuantity={false}
          initialValues={followOnOptions.filter(i => watch('follow_on')?.includes(i.id)) || []}
          onSelect={selection => _setValue('follow_on', selection.map(item => item.id))}
        />
        <div className='my-2'>
          <TextArea
            reference={register}
            name={'follow_on_comments'}
            error={errors.follow_on_comments}
            label={t(irm ? 'follow_on_comments' : 'do_you_have_comments_follow_on')}
            maxLength={200}
            placeholder={t(irm ? 'follow_on_comments' : 'do_you_have_comments_follow_on')}
          />
        </div>

      </div>
    )
  }

  const renderSelectorCard = (title, name, collection) => {
    return (
      <div>
        <h5 className={`text-main-${Config.PLATFORM} text-lg font-bold border-b border-main-${Config.PLATFORM} mb-4`}>
          {t(title)}
        </h5>

        <Select
          name={name}
          placeholder={t('choose_multiple_options')}
          items={collection}
          error={errors[name]}
          multiSelect={true}
          isClearable={false}
          showQuantity={false}
          initialValues={sectorsSorted.filter(i => watch(name)?.includes(i.id)) || []}
          onSelect={selection => _setValue(name, selection.map(item => item.id))}
        />

        <div>
          {watch(name)?.map(modelId => (
            <span
              key={modelId}
              className={`inline-block px-2 py-1 mr-2 my-1 text-xs font-medium text-buttons-primary-${Config.PLATFORM} bg-white border-gray-lines border rounded-full`}>

              {collection.find(s => s.id === modelId)?.value}

              <FaTimes
                onClick={() => _setValue(name, (watch(name) || []).filter(id => id !== modelId))}
                className='inline-block ml-2 cursor-pointer'
              />
            </span>
          ))}
        </div>
      </div>
    )
  }

  useEffect(() => {
    register('minimum_ticket_unit')
    register('maximum_ticket_unit')
    register('minimum_valuation_unit')
    register('maximum_valuation_unit')

    register('follow_on')
    register('lead_investor')
    register('sectors')
    register('business_models')
    register('investment_stages')
    register('project_stages')
    register('countries')
  }, [register])

  useEffect(() => {
    if (thesis) {
      hydrateForm()
    }
  }, [thesis])

  return (
    <>
      <Card>
        <form onSubmit={handleSubmit(onSubmitForm, onError)}>
          <div className='mb-4'>
            <h5 className={`text-main-${Config.PLATFORM} text-lg font-bold border-b border-main-${Config.PLATFORM} mb-4`}>
              {t('investment')}
            </h5>

            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4'>
              <CurrencyInput
                nameInput={'minimum_ticket'}
                nameSelect={'minimum_ticket_unit'}
                reference={register}
                placeholder={t('minimum_ticket') + ' (€)'}
                label={t('minimum_ticket') + ' (€)'}
                error={errors.minimum_ticket}
                watch={watch}
                setValue={_setValue}
              />

              <CurrencyInput
                nameInput={'maximum_ticket'}
                nameSelect={'maximum_ticket_unit'}
                reference={register}
                placeholder={t('maximum_ticket') + ' (€)'}
                label={t('maximum_ticket') + ' (€)'}
                error={errors.maximum_ticket}
                watch={watch}
                setValue={_setValue}
              />

              <CurrencyInput
                nameInput={'minimum_valuation'}
                nameSelect={'minimum_valuation_unit'}
                reference={register}
                placeholder={t('minimum_valuation') + ' (€)'}
                label={t('minimum_valuation') + ' (€)'}
                error={errors.minimum_valuation}
                watch={watch}
                setValue={_setValue}
              />
              <CurrencyInput
                nameInput={'maximum_valuation'}
                nameSelect={'maximum_valuation_unit'}
                reference={register}
                placeholder={t('maximum_valuation') + ' (€)'}
                label={t('maximum_valuation') + ' (€)'}
                error={errors.maximum_valuation}
                watch={watch}
                setValue={_setValue}
              />
            </div>
          </div>

          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            {renderLeadInvestorCard()}
            {renderFollowOnCard()}
            {renderSelectorCard('sectors', 'sectors', sectorsSorted)}

            {renderSelectorCard('business_models', 'business_models', businessModels)}

            {renderSelectorCard('investment_stages', 'investment_stages', investmentStages)}

            {renderSelectorCard('project_stages', 'project_stages', projectStages)}

            {renderSelectorCard('countries', 'countries', countriesSorted)}
          </div>

          <div className="flex justify-end my-4">
            <ButtonMain
              text={t('save')}
              type='submit'
              width='full md:w-1/2'
            />
          </div>
        </form>
      </Card>
      {loading && <Loader />}
    </>
  )
}
