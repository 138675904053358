import TranslateUtils from 'utils/translate-utils'
import { Button } from 'components/ui/atoms/Button'
import { RoutesLinks } from 'components/routes-links'
import {
  FaEdit,
  FaLock,
  FaMinus,
  FaMinusCircle,
  FaPlus,
  FaPlusCircle,
  FaTimesCircle,
  FaTrash,
  FaUserCheck,
  FaUserFriends,
  FaUserLock
} from 'react-icons/all'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { QuestionsUtils } from 'utils/questions-utils'
import ReactTooltip from 'react-tooltip'
import useUser from '../../../hooks/useUser'
import { ButtonDanger } from 'components/ui/atoms/ButtonDanger'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import Config from 'config'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { Card } from 'components/ui/atoms/Card'

export const Question = ({
  question,
  deleteQuestion,
  updateQuestion,
  addOrRemoveQuestion,
  iconSmall = false,
  isSelected = false,
  showTag = false,
  showEdit = true,
  openEditInTab = false,
  showRequiredBadge = false
}) => {
  const { user, userLanguage, isAdmin } = useUser()
  const { t } = useTranslation()
  const history = useHistory()

  const openEdit = () => {
    const url = `${RoutesLinks.QE_QUESTIONS_WIZARD_LINK}/${question._id}`

    if (openEditInTab) {
      window.open(url)
    } else {
      history.push(url)
    }
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [user, question])

  return (
    <Card>
      <div className="flex flex-col mb-2">
        <div className="flex flex-row">
          {QuestionsUtils.getIcon(question.type, iconSmall)}

          <div className="flex-1 flex flex-row ml-2">
            <div className={`w-full flex-1 mr-2 font-semibold ${iconSmall ? 'text-xs' : 'text-sm'}`}>
              {TranslateUtils.getTextByLang(userLanguage, question.title)}
              <div className="text-xxs text-gray">{t(question.type)}</div>
              {question.percentage && <div className='text-xxs text-gray'>{t('percentage_investors', { percentage: question.percentage })}</div>}
              <div className="text-xxs text-gray font-normal text-ellipsis w-full">
                {['select', 'multiselect', 'likert_scale'].includes(question.type) && question.options?.length > 0 &&
                  question.options.map((option, index) => (
                    <span key={index}>
                      {index > 0 && ', '}
                      {TranslateUtils.getTextByLang(userLanguage, option)}
                    </span>
                  ))}
              </div>
            </div>

            <div className="flex flex-row justify-center items-start">
              {showEdit && (question.investor || (!question.investor && isAdmin)) && (
                <div data-tip={t('edit')}>
                  <ButtonSecondary
                    onClick={openEdit}
                    iconComponent={<FaEdit className="inline" />}
                  />
                </div>
              )}

              {isAdmin && !question.investor && updateQuestion && (
                <div className="ml-1">
                  <ButtonSecondary
                    onClick={() => {
                      updateQuestion(question._id, { public: !question.public })
                    }}
                    iconComponent={
                      question.public ? <FaUserLock className="inline" /> : <FaUserCheck className="inline" />
                    }
                  />
                </div>
              )}

              {((isAdmin && !question.investor) || question.investor) && deleteQuestion && (
                <div className="ml-1" data-tip={t('delete')}>
                  <ButtonDanger
                    onClick={() => deleteQuestion(question._id)}
                    iconComponent={<FaTrash className="inline" />}
                  />
                </div>
              )}

              {addOrRemoveQuestion && (
                <div className="ml-1" data-tip={t(isSelected ? 'delete_question' : 'add_question')}>
                  {!isSelected && <ButtonMain
                    onClick={() => addOrRemoveQuestion(question)}
                    iconComponent={<FaPlus className="inline" />}
                  />}
                  {isSelected && <ButtonDanger
                    onClick={() => addOrRemoveQuestion(question)}
                    iconComponent={<FaMinusCircle className="inline" />}
                  />}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="font-semibold text-xs flex flex-row justify-start my-2">
          <span className="border-gray-lines border rounded-lg py-0.5 px-1">{`ID: ${question._id.slice(-4)}`}</span>

          {showTag && question.tag && (
            <span className="border-green border rounded-lg py-0.5 px-1 ml-1">
              {question.investor ? question.tag : t(question.tag)}
            </span>
          )}

          {!isAdmin && !question.investor && (
            <span
              data-tip={!isAdmin ? t('question_owned_by_kiota') : null}
              className="border-gray-lines border rounded-lg py-0.5 px-1 ml-1"
            >
              <FaLock className="inline-block mr-1" />
              Kiota
            </span>
          )}

          {showRequiredBadge && <span className="border-red border rounded-lg py-0.5 px-1 ml-1">{t('question_required')}</span>}

          {question.deletedAt && (
            <span data-tip={t('question_deleted')} className="border-red border rounded-lg py-0.5 px-1 ml-1">
              <FaTimesCircle className="text-red inline-block mr-1" />
              {t('deleted')}
            </span>
          )}

          {!question.investor && question.public && isAdmin && (
            <span className="border-gray-lines border rounded-lg py-0.5 px-1 ml-1">
              <FaUserFriends className="'text-red inline-block mr-1" />
              {t('public')}
            </span>
          )}

          {question.title?.es && (
            <span data-tip={t('es')} className='border-gray-lines border rounded-lg py-0.5 px-1 ml-1'>
              ES
            </span>
          )}

          {question.title?.en && (
            <span data-tip={t('es')} className='border-gray-lines border rounded-lg py-0.5 px-1 ml-1'>
            EN
            </span>
          )}
        </div>
      </div>
    </Card>
  )
}
