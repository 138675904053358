import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PageTitle } from '../../../ui/atoms/PageTitle'
import { SectionHeader } from '../../../ui/molecules/SectionHeader'
import AccountPersonalInfo from '../personal-info/AccountPersonalInfo'
import AccountSocialNetworks from '../social-networks/AccountSocialNetworks'
import AccountSecurity from '../security/AccountSecurity'

const FounderScope = () => {
  const { t } = useTranslation()
  const [section, setSection] = useState('general_info')

  return (
    <>
      <PageTitle title={t('my_account')} />

      <SectionHeader
        section={section}
        setSection={setSection}
        sectionKeys={['general_info', 'social_networks', 'security']}
        sectionTitles={[t('general_info'), t('social_networks'), t('security')]}
      />

      <div className="mt-4">
        {section === 'general_info' && <AccountPersonalInfo />}
        {section === 'social_networks' && <AccountSocialNetworks />}
        {section === 'security' && <AccountSecurity />}
      </div>
    </>
  )
}

export default FounderScope
