import { RoutesLinks } from 'components/routes-links'
import { Loader } from 'components/ui/molecules/Loader'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { ChecksOperations } from 'redux/checks'
import AuthService from 'services/auth-service'
import NotificationsOperations from 'redux/notifications/notifications-operations'
import { ChatService } from '../../services/chat-service'
import { useAuth } from '../../hooks/useAuth'
import useUser from '../../hooks/useUser'
import { useLoginEntries } from '../../hooks/useLoginEntries'

const RegisterConfirm = () => {
  const dispatch = useDispatch()
  const { token, setToken, setRefreshToken, setApiKey } = useAuth()
  const { setUserInfo } = useUser()
  const { setLoginEntries } = useLoginEntries()
  const location = useLocation()
  const { t } = useTranslation()
  const [error, setError] = useState(false)
  const queryParams = new URLSearchParams(location.search)
  const code = queryParams.get('code')
  const { addToast } = useToasts()
  const setInvitations = (invitations) =>
    dispatch(NotificationsOperations.setInvitations(invitations))

    useEffect(() => {
    const confirmRegister = async () => {
      try {
        const { data } = await AuthService.confirmRegister(code)
        dispatch(ChecksOperations.setStage('ACCOUNT_ACTIVATED'))

        setUserInfo(data.user)
        setToken(data.token)
        setApiKey(data.api_key)
        setLoginEntries(data.login_entries)
        setRefreshToken(data.refreshToken)
        setInvitations(data.invitations)
        ChatService.loginUser(data.user)
      } catch (error) {
        console.log(error)
        setError(true)
        addToast(
          error.response && error.response.data.msg_key
            ? t(error.response.data.msg_key)
            : t('error_occurred_confirming_register'),
          {
            appearance: 'error',
            autoDismiss: true
          }
        )
      }
    }

    if (code) {
      confirmRegister()
    }
  }, [code])

  if (!code || error) {
    return (
      <Redirect
        to={{
          pathname: RoutesLinks.LOGIN_LINK
        }}
      />
    )
  } else if (token) {
    const { from } = location.state || {
      from: { pathname: RoutesLinks.REGISTER_CONFIRM }
    }

    return (
      <Redirect
        to={{
          pathname: RoutesLinks.CHECKS_LINK,
          state: { from }
        }}
      />
    )
  } else {
    return <Loader />
  }
}

export default RegisterConfirm
