/**
 *
 * @param onClick
 * @param type
 * @param width
 * @param verticalMargin
 * @param vertical
 * @param horizontal
 * @param marginRight
 * @param marginLeft
 * @param bgColor
 * @param textColor
 * @param bgHoverColor
 * @param borderColor
 * @param textColorHover
 * @param icon
 * @param iconComponent
 * @param text
 * @param disabled
 * @param textSize
 * @param weight
 * @param shadow
 * @param iconWidth
 * @param textAlign {'left' | 'center' | 'right'}
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
export function Button ({
  onClick,
  type,
  width = 'full',
  verticalMargin = '5',
  vertical = '2.5',
  horizontal = '7',
  marginRight = '0',
  marginLeft = '0',
  bgColor = 'transparence-blue',
  textColor = 'blue-dark',
  bgHoverColor,
  borderColor,
  textColorHover,
  icon,
  iconComponent,
  text,
  disabled,
  textSize = 'sm',
  weight = 'semibold',
  shadow = 'soft-white',
  iconWidth = 'auto',
  textAlign = 'center',
  className = ''
}) {
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={
        `${textAlign === 'center' ? 'text-center' : textAlign === 'right' ? 'text-right' : 'text-left'} 
        block w-${width} my-${verticalMargin} mr-${marginRight} ml-${marginLeft} py-${vertical} px-${horizontal} 
        text-${textSize} font-${weight} text-${textColor} placeholder-gray bg-${bgColor} rounded-2xl 
        shadow-${shadow} cursor-pointer transition-all duration-500 ease-in-out 
        hover:bg-${bgHoverColor} hover:border-${borderColor} hover:text-${textColorHover} hover:shadow-hover
        ${borderColor && `border border-${borderColor}`}
        focus:outline-none hover:shadow-inner ${className}`
      }
    >
      {icon && (
        <img src={icon} alt="Icon" className={`inline | mr-2 | w-${iconWidth} `} />
      )}

      {iconComponent && iconComponent}

      {text}
    </button>
  )
}
