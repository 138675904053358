import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { RoutesLinks } from 'components/routes-links'
import { Card } from 'components/ui/atoms/Card'
import Avatar from 'react-avatar'
import Utils from 'utils/utils'
import { FaEye, FaPlus, FaRocket, FaUser, FaUsers } from 'react-icons/fa'
import { ButtonCardMain } from 'components/ui/atoms/ButtonCardMain'
import Config from 'config'
import ReactTooltip from 'react-tooltip'
import { BsCash } from 'react-icons/bs'

const IRMInvestorCard = ({ irm = true, kiota = false, addToMyContacts, investor }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const handleRedirect = (url) => history.push(url)

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  return (
        <Card>
            <div className="flex pb-2 mb-2 ">
          <div className='flex flex-row w-full justify-around'>
 
            <div className="flex flex-col h-full text-sm min-w-0 w-3/5">
              <h5 className={`uppercase font-bold text-sm leading-none text-main-${Config.PLATFORM} truncate mb-1`}>
                {investor.company || investor.ba}
              </h5>
              <div className='flex flex-row'>
                <div className='w-auto'>
                <span
                    className={`inline-block mr-2 mt-1 font-semibold text-xxs px-2 py-0.5 rounded-md border border-gray-lines text-text-buttons-secondary-${Config.PLATFORM}`}
                    >
                        {t(investor.investor_type)}
                        {investor.investor_type === 'ba' && <FaUser className='inline ml-2'/>}
                        {investor.investor_type === 'accelerator' && <FaRocket className='inline ml-2'/>}
                        {investor.investor_type === 'vc' && <BsCash className='inline ml-2'/>}
                        {investor.investor_type === 'ba_network' && <FaUsers className='inline ml-2'/>}
                    </span>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-end mr-5 w-2/5 ">
            <div className="info-card-container-img">
              <Avatar
                src={investor.company_logo || investor.ba_image}
                name={investor.company || investor.ba}
                alt={investor.company || investor.ba}
                maxInitials={3}
                className={`h-full object-contain ${investor.company_logo || investor.ba_image ? 'w-full' : ''}`}
                color="#e0e6f2"
                fgColor="#4d70b3"
                round="5"
              />
              </div>
            </div>

          </div>
        </div>
            <div className='flex w-full'>

            <div className='flex flex-col w-full'>
                <div className='text-xs mt-2 flex flex-row'>
                <div>
                    <span className='font-semibold'>
                    {`${t('minimum_ticket')}: `}
                    </span>
                    <span>
                    {investor.investment_thesis.hasOwnProperty('minimum_ticket') && ![null, undefined].includes(investor.investment_thesis.minimum_ticket) ? `${investor.investment_thesis.minimum_ticket} ${t(investor.investment_thesis.minimum_ticket_unit)} €` : '-'}
                    </span>
                </div>
                <div className='ml-2'>
                    <span className='font-semibold'>
                    {`${t('maximum_ticket')}: `}
                    </span>
                    <span>
                    {investor.investment_thesis.hasOwnProperty('maximum_ticket') && ![null, undefined].includes(investor.investment_thesis.maximum_ticket) ? `${investor.investment_thesis.maximum_ticket} ${t(investor.investment_thesis.maximum_ticket_unit)} €` : '-'}
                    </span>
                </div>

                </div>
                <div className='text-xs mt-1 flex flex-row'>
                <div>
                    <span className='font-semibold'>
                    {`${t('minimum_valuation')}: `}
                    </span>
                    <span>
                    {investor.investment_thesis.hasOwnProperty('minimum_valuation') && ![null, undefined].includes(investor.investment_thesis.minimum_valuation) ? `${investor.investment_thesis.minimum_valuation} ${t(investor.investment_thesis.minimum_valuation_unit)} €` : '-'}
                    </span>
                </div>
                <div className='ml-2'>
                    <span className='font-semibold'>
                    {`${t('maximum_valuation')}: `}
                    </span>
                    <span>
                    {investor.investment_thesis.hasOwnProperty('maximum_valuation') && ![null, undefined].includes(investor.investment_thesis.maximum_valuation) ? `${investor.investment_thesis.maximum_valuation} ${t(investor.investment_thesis.maximum_valuation_unit)} €` : '-'}
                    </span>
                </div>
                </div>
                <div className='text-xs mt-2 pt-2 flex flex-col border-t border-gray-lines w-full'>
                <div>
                    <span className='font-semibold'>
                    {`${t('sectors')}: `}
                    </span>
                    <span>
                    {investor.investment_thesis.sectors?.length ? investor.investment_thesis.sectors.length > 1 ? `${t(investor.investment_thesis.sectors[0].key)} (+${investor.investment_thesis.sectors.length} ${t('more')})` : t(investor.investment_thesis.sectors[0].key) : '-'}
                    </span>
                </div>
                <div>
                    <span className='font-semibold'>
                    {`${t('countries')}: `}
                    </span>
                    <span>
                    {investor.investment_thesis.countries?.length ? investor.investment_thesis.countries.length > 1 ? `${t(investor.investment_thesis.countries[0].key)} (+${investor.investment_thesis.countries.length} ${t('more')})` : t(investor.investment_thesis.countries[0].key) : '-'}
                    </span>
                </div>
                <div>
                    <span className='font-semibold'>
                    {`${t('investment_stages')}: `}
                    </span>
                    <span>
                    {investor.investment_thesis.investment_stages?.length ? investor.investment_thesis.investment_stages.length > 1 ? `${t(investor.investment_thesis.investment_stages[0].key)} (+${investor.investment_thesis.investment_stages.length} ${t('more')})` : t(investor.investment_thesis.investment_stages[0].key) : '-'}
                    </span>
                </div>
                <div>
                    <span className='font-semibold'>
                    {`${t('lead_investor')}: `}
                    </span>
                    <span>
                    {investor.investment_thesis.lead_investor ? t(investor.investment_thesis.lead_investor) : '-'}
                    </span>
                </div>
                </div>
            </div>
            </div>
            {(irm || kiota) && (
                <div className={`flex justify-end mt-4 border-t border-separator-${Config.PLATFORM} pt-2`}>
                {kiota && <ButtonCardMain
                    onClick={() => addToMyContacts(investor._id)}
                    text={t('add_to_my_contacts')}
                    iconComponent={<FaPlus className="inline-block w-6 h-4" />}
                    />}
                {irm && !kiota && <ButtonCardMain
                    onClick={() => handleRedirect(`${RoutesLinks.INVESTORS_RELATIONS_MANAGER_LINK}${investor._id}`)}
                    text={t('see_details')}
                    iconComponent={<FaEye className="inline-block w-6 h-4" />}
                    />}
            </div>)
            }
        </Card>
    )
}

export default IRMInvestorCard
