import axios from 'axios/index'
import Config from '../config'

const IntegrationsService = {
  executeKiotaExpress: async (data, apiKey, datasource, endpoint) => {
    const URLS = {
        general: `${Config.INTEGRATIONS_URL}master`,
        growth_robustness_comparison: `${Config.INTEGRATIONS_URL}growth-robustness-comparison`,
        survival_robustness_comparison: `${Config.INTEGRATIONS_URL}survival-robustness-comparison`,
        pre_valuation_robustness_comparison: `${Config.INTEGRATIONS_URL}pre-valuation-robustness-comparison`
    }
    return axios.post(URLS[endpoint], data, { headers: { 'X-Auth': apiKey, 'X-Origin': 'platform', 'X-Source': datasource } })
  },

  executeKiotaGammaEvaluation: async (data, apiKey) => {
    return axios.post(`${Config.INTEGRATIONS_URL}k-gamma/`, data, { headers: { 'X-Auth': apiKey } })
  },

  executeODS: async (data, apiKey, datasource) => {
    return axios.post(`${Config.INTEGRATIONS_URL}k-gamma`, data, { headers: { 'X-Auth': apiKey, 'X-Origin': 'platform', 'X-Source': datasource } })
  }
}
export default IntegrationsService
