import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import React, { useEffect, useState } from 'react'
import { usePagination } from '../../hooks/usePagination'
import { DealflowService } from '../../services/dealflow-service'
import { Card } from '../ui/atoms/Card'
import { NoDataInfo } from '../ui/atoms/NoDataInfo'
import { Pagination } from '../ui/atoms/Pagination'
import { Loader } from '../ui/molecules/Loader'
import { DealflowProjectConnection } from './DealflowProjectConnection'

export const InvestorProjectConnections = ({setDealToResume}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [connections, setConnections] = useState([])
  const [loading, setLoading] = useState(false)

  const {
    setTotalPages,
    totalPages,
    setPage,
    page,
    rowsPerPage,
    setRowsPerPage
  } = usePagination(12)

  const getConnections = () => {
    setLoading(true)
    return DealflowService.getProjectConnections({
      rowsPerPage,
      page,
      sortBy: '-createdAt'
    }).then(result => {
      const { connections, pagination } = result.data
      setConnections(connections)
      setTotalPages(pagination.pages)
    }).catch(() => {
      addToast(t('error_occurred'), { appearance: 'error', autoDismiss: true })
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    getConnections()
  }, [page, rowsPerPage])

  return (
    <>
      {(!loading && connections.length === 0) && (
        <div className={'my-10'}>
          <NoDataInfo
            title={t('connections_not_found')}
            textOne={t('connections_not_found_description')}
          />
        </div>
      )}

      {(!loading && connections.length > 0) && (
        <div className="mt-6">
          <div className="grid grid-cols-1 lg:grid-cols-2  2xl:grid-cols-3 gap-4">
            {connections.map(connection => (
              <DealflowProjectConnection
                key={connection._id}
                connection={connection}
                onManage={() => getConnections()}
                setDealToResume={setDealToResume}
              />
            ))}
          </div>

          <Pagination
            paginateOptions={[12, 24, 36, 48, 60]}
            currentPage={page}
            setCurrentPage={(page) => setPage(page)}
            perPage={rowsPerPage}
            pages={totalPages}
            setPerPage={(value) => setRowsPerPage(value[0].id)}
          />
        </div>
      )}

      {loading && <Loader />}
    </>
  )
}
