import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, Redirect } from 'react-router-dom'

import { CoverBackground } from 'components/ui/atoms/CoverBackground'
import { Container } from 'components/ui/objects/Container'
import { Loader } from 'components/ui/molecules/Loader'
import ResendLinkForm from './components/forgot-form'
import { RoutesLinks } from 'components/routes-links'
import AuthService from 'services/auth-service'

const ResendLink = () => {
  const { t } = useTranslation()
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const history = useHistory()

  const email = history.location.state ? history.location.state.email : null

  const isEmailsent = () => {
    setEmailSent(true)
  }

  const resendCode = async () => {
    try {
      setError(null)
      setLoading(true)
      setEmailSent(true)
      await AuthService.forgotPassword(email)
    } catch (error) {
      setError(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t('error_occurred')
      )
    } finally {
      setLoading(false)
    }
  }

  if (email) {
    return (
      <Container variant="session">
        <Container>
          <ResendLinkForm
            onSubmit={resendCode}
            error={error}
            loading={loading}
            email={email}
            emailSent={emailSent}
          />
        </Container>
        {loading && <Loader />}
        <CoverBackground link={true}/>
      </Container>
    )
  } else {
    return (
      <Redirect
        to={{
          pathname: RoutesLinks.LOGIN_LINK
        }}
      />
    )
  }
}

export default ResendLink
