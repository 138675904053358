import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Loader } from '../ui/molecules/Loader'
import { StartupGeneralInformation } from '../startup-profile/StartupGeneralInformation'
import { Card } from 'components/ui/atoms/Card'
import { FaExclamationCircle, FaEye } from 'react-icons/fa'
import { DealflowService } from '../../services/dealflow-service'
import Select from '../ui/atoms/Select'
import ReactTooltip from 'react-tooltip'
import { SectionHeader } from '../ui/molecules/SectionHeader'
import { RequestProfileVisibility } from '../startup-profile/components/RequestProfileVisibility'
import { StartupKPIs } from '../startup-profile/components/StartupKPIs'
import { AugmentedInformation } from '../startup-profile/components/AugmentedInformation'
import { ApplicationQuestionnaireView } from '../ui/objects/ApplicationQuestionnaireView'
import { SharedEventHeader } from '../events/components/event/components/SharedEventHeader'
import ProjectsService from '../../services/projects-service'
import useUser from '../../hooks/useUser'
import { useToasts } from 'react-toast-notifications'
import { SharedEventFooter } from 'components/events/components/event/components/SharedEventFooter'
import Unauthorized from 'components/ui/objects/Unauthorized'
import SupportService from 'services/support-service'
import { Alert } from 'components/ui/molecules/Alert'
import { Pagination } from 'components/ui/atoms/Pagination'
import Avatar from 'react-avatar'
import { Button } from 'components/ui/atoms/Button'
import moment from 'moment'
import { PageTitle } from 'components/ui/atoms/PageTitle'
import Config from 'config'
import { ButtonCardMain } from 'components/ui/atoms/ButtonCardMain'

export const SharedDealflow = () => {
  const wrapperElement = useRef(null)
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)
  const [deal, setDeal] = useState(null)
  const [section, setSection] = useState('')
  const [sections, setSections] = useState([])
  const [authorized, setAuthorized] = useState(false)
  const [sharedDealflowAccessToken, setSharedDealflowAccessToken] = useState(localStorage.getItem('X-SD-Token'))
  const [sharedDealflow, setSharedDealflow] = useState([]);
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalStartups, setTotalStartups] = useState(0)
  const [dealInfo, setDealInfo] = useState(null)

  const getSharedDeal = async (sharedDealId) => {
    setLoading(true)
    DealflowService.getSharedDealExternal(sharedDealId, sharedDealflowAccessToken).then((result) => {
      setDealInfo(result.data.data);
    }).catch(() => {
      addToast(t('error_occurred_retrieving_information'), { appearance: 'error', autoDismiss: true })
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    if (dealInfo) {
      if (dealInfo.application) {
        setSections(['application'])
        setSection('application')
      } else {
        setSections(['general_information'])
        setSection('general_information')
      }
    }
  }, [dealInfo])

  useEffect(() => {
    if (deal) {
      getSharedDeal(deal._id);
    }
  }, [deal])

  const getSharedDealflow = async () => {
    DealflowService.getSharedDealflowExternal(sharedDealflowAccessToken).then((result) => {
      const { startups, pagination } = result.data

      setSharedDealflow(startups)

      if (pagination.current - 1 !== currentPage) {
        setCurrentPage(pagination.current)
      }

      if (pagination.total !== totalPages) {
        setTotalPages(pagination.pages)
      }

      if (pagination.totalDocs !== totalStartups) {
        setTotalStartups(pagination.totalDocs)
      }

    }).catch(() => {
      addToast(t('error_occurred_retrieving_information'), { appearance: 'error', autoDismiss: true })
    })
  }

  useEffect(() => {
    const checkAccess = async () => {
      try {
        setLoading(true)
        await DealflowService.checkSharedDealflowAccess(
          sharedDealflowAccessToken
        )
        setAuthorized(true)
      } catch (error) {
        console.log({error})
        addToast(t(error?.response?.status === 403 ? 'forbiden_access_to_shared_dealflow' : 'error_occurred_checking_access'), {
          appearance: 'error',
          autoDismiss: true
        })
      } finally {
        setLoading(false)
      }
    }

    if (sharedDealflowAccessToken) {
      localStorage.setItem('X-SD-Token', sharedDealflowAccessToken)
      checkAccess()
    }
  }, [sharedDealflowAccessToken])

  useEffect(() => {
    if (authorized) {
      getSharedDealflow()
    }
  }, [currentPage, pageSize, authorized])

  useEffect(() => {
    if (wrapperElement.current) {
      wrapperElement.current.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }, [])

  if (!authorized) {
    return <>
      {loading && <Loader />}
      <Unauthorized loading={loading} onRequestAccess={setSharedDealflowAccessToken} description={t('access_control_description_shared_dealflow')} buttonText={t('access_shared_dealflow')} />
    </>
  }

  return (
    <div className="flex flex-col min-h-screen" ref={wrapperElement}>
      <section className="px-8 my-8 flex-1">

      {!deal && (
        <>
          <PageTitle title={t('shared_dealflow')} />
          <div className='my-6'>
            <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4'>
              {sharedDealflow.map((sharedDeal) => {
                return <div key={sharedDeal._id}>
                <Card>
                  <div className='flex'>
                  <Avatar
                    src={sharedDeal.logo}
                    size="50"
                    round={true}
                    color="#e0e6f2"
                    fgColor="#4d70b3"
                    alt={sharedDeal.name}
                  />
                  <div className='flex flex-col px-2 w-full'>
                    <div className='flex flex-row justify-between w-full'>
                      <div>
                        <span className={`text-sm text-main-${Config.PLATFORM} font-bold line-clamp-1`}>
                          {sharedDeal.name}
                        </span>
                      </div>
                    </div>
                    <div className='mt-2 mb-1 flex flex-row'>
                      <span className="text-xs font-semibold mr-2">
                        {`${t('investor')}: `}
                      </span>
                      <span className='font-semibold text-xs'>
                        {sharedDeal.shared_by_investor.company_name}
                      </span>
                    </div>
                    <div className='mb-1 flex flex-row'>
                      <span className="text-xs font-semibold mr-2">
                        {`${t('date')}: `}
                      </span>
                      <span className='font-semibold text-xs'>
                        {moment(sharedDeal.date).format('YYYY-MM-DD')}
                      </span>
                    </div>
                  </div>
                  </div>
                  <div className={`flex justify-end border-t border-separator-${Config.PLATFORM} pt-2 mt-4`}>
                    <ButtonCardMain
                      onClick={() => setDeal(sharedDeal)}
                      text={t('see_details')}
                      iconComponent={<FaEye className="inline-block w-6 h-3" />}
                      />
                  </div>
                </Card>
              </div>
              })}
            </div>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={(page) => setCurrentPage(page)}
              perPage={pageSize}
              pages={totalPages}
              setPerPage={(value) => setPageSize(value[0].id)}
            />
          </div>
        </>
      )}

      {deal && dealInfo && (
        <>
          {/*<SharedEventHeader showLoginButtons={true} />*/}

          <div className='px-20 py-4'>
            <PageTitle title={dealInfo?.name} showBack={true} onBackClick={() => {
              setDeal(null)
              setDealInfo(null)
            }} />
            <div className="flex items-start">
              <div className="flex-1 mb-6">
                <SectionHeader
                  sectionTitles={sections.map(section => t(section))}
                  sectionKeys={sections}
                  section={section}
                  setSection={setSection}
                />
              </div>
            </div>

            {section === 'general_information' && (
              <StartupGeneralInformation
                publicProfile={true}
                requestAccess={null}
                deal={dealInfo}
                isTeamMember={false}
              />
            )}

            {section === 'application' && dealInfo.application && (
              <ApplicationQuestionnaireView applicationQuestions={dealInfo.application.questions}/>
            )}
          </div>
        </>
      )}

              </section>
      <SharedEventFooter />
      {loading && <Loader />}
    </div>
  )
}
