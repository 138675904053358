import { useTranslation } from 'react-i18next'
import { RoutesLinks } from 'components/routes-links'
import { useHistory } from 'react-router-dom'
import { useTour } from 'hooks/useTour'
import useUser from './useUser'

export function useOnboardingSteps () {
  const { user, plan } = useUser()
  const history = useHistory()
  const { t } = useTranslation()
  const tour = useTour('quick_tour')

  const getTasks = () => {
    if (!user) {
      return []
    }


    const ONBOARDING_TASKS = {
      kiota_integrations: [
        {
          title: t('activate_account'),
          completed: true
        },
        {
          title: t('onboarding_task_take_tour'),
          description: t('onboarding_task_take_tour_description'),
          completed: !!user?.onboarding_tasks.includes('take_quick_tour'),
          onClick: () => tour.start()
        },
        {
          title: t('make_your_first_api_call'),
          description: t('make_your_first_api_call_tour_description'),
          completed: !!user?.onboarding_tasks.includes('make_your_first_api_call')
        }
      ],
      default: [
        {
          title: t('activate_account'),
          completed: true
        },
        {
          title: t('onboarding_task_take_tour'),
          description: t('onboarding_task_take_tour_description'),
          completed: !!user?.onboarding_tasks.includes('take_quick_tour'),
          onClick: () => tour.start()
        },
        {
          title: t('onboarding_task_invite_startup'),
          description: t('onboarding_task_invite_startup_description'),
          completed: !!user?.onboarding_tasks.includes('invite_startup'),
          onClick: () => history.push(RoutesLinks.DEALFLOW_LINK)
        },
        {
          title: t('onboarding_task_create_first_board_pipeline_manager'),
          description: t(
            'onboarding_task_create_first_board_pipeline_manager_description'
          ),
          completed: !!user?.onboarding_tasks.includes('create_board'),
          onClick: () =>
            history.push(`${RoutesLinks.PIPELINE_MANAGER_LINK}boards`)
        },
        {
          title: t('onboarding_task_create_your_first_score_card'),
          description: t(
            'onboarding_task_create_your_first_score_card_description'
          ),
          completed: !!user?.onboarding_tasks.includes('create_score_card'),
          onClick: () => history.push(RoutesLinks.SCORE_CARDS_LINK)
        },
        {
          title: t('onboarding_task_evaluate_your_first_startup'),
          description: t(
            'onboarding_task_evaluate_your_first_startup_description'
          ),
          completed: !!user?.onboarding_tasks.includes('evaluate_startup'),
          onClick: () => history.push(RoutesLinks.EVALUATION_LINK)
        },
        {
          title: t('onboarding_task_add_your_first_question'),
          description: t('onboarding_task_add_your_first_question_description'),
          completed: !!user?.onboarding_tasks.includes('add_question'),
          onClick: () => history.push(RoutesLinks.QE_QUESTIONS_LINK)
        },
        {
          title: t('onboarding_task_create_your_first_questionnaire'),
          description: t(
            'onboarding_task_create_your_first_questionnaire_description'
          ),
          completed: !!user?.onboarding_tasks.includes('create_questionnaire'),
          onClick: () => history.push(RoutesLinks.QE_FORMS_LINK)
        }
      ]
    }

    return plan ? ONBOARDING_TASKS[plan] ? ONBOARDING_TASKS[plan] : ONBOARDING_TASKS.default : []
  }

  return {
    getTasks: () => {
      return getTasks()
    },
    getTaskProgress: () => {
      const tasks = getTasks()
      return Math.round((tasks.filter((t) => t.completed).length / tasks.length) * 100)
    }
  }
}
