import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import Config from '../../../../config'
import { RoutesLinks } from '../../../routes-links'
import { Link, useLocation } from 'react-router-dom'

import { FormTitle } from '../../../ui/atoms/FormTitle'
import { Button } from '../../../ui/atoms/Button'
import { BannerResponsive } from '../../../ui/atoms/BannerResponsive'

import { GoogleButton } from '../../../ui/atoms/GoogleButton'
import { LinkedinButton } from '../../../ui/atoms/LinkedinButton'
import { Input } from '../../../ui/atoms/Input'
import { BottomText } from '../../../ui/atoms/BottomText'
import { HrText } from '../../../ui/molecules/HrText'
import { useSelector } from 'react-redux'
import moment from "moment";
import LinkedinIcon from '../../../../assets/images/LinkedinIcon.png'

import 'styles/main.css'
import EventUtils from 'utils/event-utils'
import useUser from '../../../../hooks/useUser'
import { PoweredText } from 'components/ui/atoms/PoweredText'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

const LoginForm = ({
  onSubmit,
  responseSuccessGoogle,
  responseFailureGoogle,
  responseSuccessLinkedin,
  responseFailureLinkedin,
  loading,
  loadingGoogleLogin,
  loadingLinkedinLogin,
  activeTheme
}) => {
  const { t } = useTranslation()
  const location = useLocation()
  const { user } = useUser()

  const [showPassword, setShowPassword] = useState(false)

  const handleShowhide = () => {
    try {
      EventUtils.sendAnalyticEvent({
        event: 'showPasswordInput',
        user: user ? user._id : null,
        payload: {
          url: location.pathname,
          value: !showPassword
        }
      })
    } catch (error) { }
    setShowPassword(!showPassword)
  }

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('incorrect_format'))
      .required(t('required_field')),
    password: Yup.string()
      .required(t('required_field'))
      .min(8, t('password_length'))
  })

  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(LoginSchema)
  })

  return (
    <section className="flex flex-col justify-center items-center | h-full w-full  | lg:p-16">
      <div className="max-w-xs sm:max-w-md md:max-w-lg mx-2 mt-16 sm:mt-32 md:mt-0 lg:py-4 ">
        <BannerResponsive />
        <FormTitle
          formTitle={t('welcome')}
        />
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <Input
            reference={register}
            id="email"
            type="email"
            name="email"
            label={t('email') + ': *'}
            placeholder={t('introduce_email')}
            error={errors.email}
            disabled={!!loading}
          />

          <Input
            reference={register}
            id="password"
            type='password'
            name="password"
            label={t('password')}
            required={true}
            placeholder={t('password_req_min_char')}
            error={errors.password}
            disabled={!!loading}
          />

          {loading
            ? (
              <span className={`text-xs text-main-${Config.PLATFORM} hover:text-link-hover-${Config.PLATFORM} font-semibold cursor-default`}>
                {t('forgot_password')}
              </span>
            )
            : (
              <Link to={`${RoutesLinks.FORGOT_LINK}`} className={`text-xs text-main-${Config.PLATFORM} hover:text-link-hover-${Config.PLATFORM}`}>
                {t('forgot_password')}
              </Link>
            )}

          {/* <Checkbox
            reference={register}
            id="keep_logged"
            name="keep_logged"
            label="Keep me logged in"
          /> */}

          <div className='my-4'>
            <ButtonMain
              type="submit"
              text={t('sign_in')}
              disabled={!!loading}
              width={'full'}
              paddingVertical={3}
            />
          </div>
          <span className="text-gray font-medium text-xs">
            {t('not_registered_yet')}{' '}
          </span>
          {loading
            ? (
              <span className={`underline text-xs text-main-${Config.PLATFORM} hover:text-link-hover-${Config.PLATFORM} font-semibold cursor-default`}>
                {t('create_account')}
              </span>
            )
            : <Link
                to={`${RoutesLinks.REGISTER_LINK}`}
                className={`underline text-xs text-main-${Config.PLATFORM} hover:text-link-hover-${Config.PLATFORM}`}
                disabled={!!loading}
              >
                {t('create_account')}
              </Link>
            }
        </form>
      </div>
      <BottomText text={`${t('copyright')} @${moment().format('YYYY')} ${t('copyright_rights')}`} />
      {Config.PLATFORM !== '000' && <PoweredText />}
    </section>
  )
}

export default LoginForm
