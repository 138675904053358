import FounderScope from 'components/dashboard/components/founder-scope'
import InvestorScope from 'components/dashboard/components/investor-scope'
import AdminScope from 'components/dashboard/components/admin-scope'
import useUser from '../../hooks/useUser'

const Dashboard = () => {
  const { user, isInvestor, isFounder, isAdmin, isProspectiveInvestor } = useUser()

  if (isAdmin) {
    return <AdminScope />
  }

  if (isFounder) {
    return <FounderScope />
  }

  if (isInvestor) {
    return <InvestorScope />
  }

  return null
}

export default Dashboard
