import axios from 'axios'
import Config from '../config'

const API_ENDPOINT = `${Config.USERS_API_URL}message-templates`

export const MessageTemplatesService = {

  getTemplates: async () => {
    return axios.get(`${API_ENDPOINT}?rowsPerPage=999999`).then(response => response.data.data.messageTemplates)
  },

  createTemplate: async template => {
    return axios.post(API_ENDPOINT, template)
  },

  updateTemplate: async (templateId, values) => {
    return axios.patch(`${API_ENDPOINT}/${templateId}`, values)
  },

  deleteTemplate: async templateId => {
    return axios.delete(`${API_ENDPOINT}/${templateId}`)
  }

}
