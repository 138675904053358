import { useTranslation } from 'react-i18next'
import { SidePanel } from 'components/ui/objects/SidePanel'
import { useState } from 'react'
import Select from 'components/ui/atoms/Select'
import BAForm from './BAForm'
import GeneralInvestorForm from './GeneralInvestorForm'

const RegisterIRMInvestorSection = ({ onClose, onSubmit }) => {
  const { t } = useTranslation()

  const INVESTOR_TYPES = [
    { id: 'accelerator', value: t('accelerator') },
    { id: 'ba', value: t('ba') },
    { id: 'ba_network', value: t('ba_network') },
    { id: 'vc', value: t('vc') }
  ]

  const [investorType, setInvestorType] = useState('')

  return (
    <>
      <SidePanel
        onClose={onClose}
        title={t('create_account')}
        width={'1/3'}
      >

      <label
          htmlFor="select-investor-type"
          className="block mb-1 | text-left text-xs font-medium text-black"
        >
          {`${t('investor_type_label')}*:`}
        </label>
        <Select
          id="investorType"
          items={INVESTOR_TYPES}
          initialValues={[]}
          onSelect={(selections) => {
            setInvestorType(selections.length ? selections[0].id : null)
          }}
          isClearable={false}
          placeholder={t('select_investor_type')}
          name="investorType"
          error={investorType === null ? { message: t('required_field') } : null}
        />
      <div>
        {investorType === 'ba' && <BAForm onSubmit={onSubmit} investorType={investorType}/>}
        {investorType && investorType !== 'ba' && <GeneralInvestorForm investorType={investorType} onSubmit={onSubmit} />}
      </div>
      </SidePanel>
    </>
  )
}

export default RegisterIRMInvestorSection
