import { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { RoutesLinks } from 'components/routes-links'
import { useToasts } from 'react-toast-notifications'
import ProjectsService from 'services/projects-service'
import { Loader } from 'components/ui/molecules/Loader'
import { Button } from 'components/ui/atoms/Button'
import { useSelector } from 'react-redux'
import PaymentsService from 'services/payments-service'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import { TableDefault } from 'components/ui/objects/TableDefault'
import Tick from 'styles/images/tick_blue.svg'
import { ModalSwal } from 'components/ui/molecules/Modal'
import Warning from 'styles/images/warning_blue.svg'
import { Card } from 'components/ui/atoms/Card'
import { useSwal } from 'hooks/useSwal'
import useUser from '../../hooks/useUser'
import { PageTitle } from 'components/ui/atoms/PageTitle'
import { FaPlus, FaPlusCircle } from 'react-icons/fa'
import DataroomsList from './DataroomsList'
import NewDataRoomModal from './NewDataRoomModal'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

const DataRooms = () => {
  const { user } = useUser()
  const [firstRender, setFirstRender] = useState(true)
  const projectId = user.projects.length ? user.projects[0]._id : null
  const history = useHistory()
  const { t } = useTranslation()
  const handleRedirect = (url) => history.push(url)
  const { addToast } = useToasts()
  const [dataRooms, setDataRooms] = useState([])

  const [loading, setLoading] = useState(true)
  const [showNewDataroomModal, setShowNewDataroomModal] = useState(false)
  // const [paymentStatus, setPaymentStatus] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState('succeeded')
  const [showLimitReachedModal, setShowLimitReachedModal] = useState(false)

  const [section, setSection] = useState('data_rooms')

  const getDataRooms = useCallback(async () => {
    try {
      setLoading(true)
      const result = await ProjectsService.getDataRooms(projectId)
      setDataRooms(result.data)
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t('error_occurred_retrieving_data_room'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
      handleRedirect(`${RoutesLinks.DASHBOARD_LINK}`)
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [])

  /* useEffect(() => {
    if (paymentStatus === "error" && !firstRender) {
      addToast(t("error_occurred_processing_payment"), {
        appearance: "error",
        autoDismiss: true,
      });
    } else if (paymentStatus === "succeeded") {
      getDataRooms();
    } else if (paymentStatus === "canceled" && firstRender) {
      setPaymentStatus(null);
    }
  }, [paymentStatus]); */

  const checkPayment = async () => {
    try {
      setLoading(true)
      const result = await PaymentsService.checkDataRoomPayment(projectId)
      setPaymentStatus(result.data ? result.data.status : result.data)
    } catch (error) {
      addToast(t('error_occurred_checking_payment'), {
        appearance: 'error',
        autoDismiss: true
      })
      handleRedirect(`${RoutesLinks.DASHBOARD_LINK}`)
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (projectId) {
      getDataRooms()
    } else {
      setLoading(false)
    }
  }, [projectId])
  /* useEffect(() => {
    checkPayment();
  }, [projectId]); */

  const createDataRoom = async ({ name }) => {
    try {
      setLoading(true)
      const result = await ProjectsService.createDataRoom(projectId, {
        name
      })
      setShowNewDataroomModal(false)
      handleRedirect(`${RoutesLinks.DATA_ROOMS_LINK}/${result.data._id}`)
      addToast(t('data_room_created_successfully'), {
        appearance: 'success',
        autoDismiss: true
      })
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t('error_occurred_creating_data_room'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
      console.log(error)
    } finally {
      setLoading(false)
    }
  }


  return (

    <>
      <div className='flex items-start'>
        <div className='flex-1'>
          <PageTitle
            removeMargin={true}
            showBack={false}
            title={t(section)}
          />
        </div>

        {projectId && <ButtonMain
          iconComponent={<FaPlus className='inline-block mr-2' />}
          text={t('create_data_room')}
          onClick={() => {
            dataRooms.length < 10
              ? setShowNewDataroomModal(true)
              : setShowLimitReachedModal(true)
          }}
        />}
      </div>

      {showNewDataroomModal && <NewDataRoomModal showModal={showNewDataroomModal} onClose={() => setShowNewDataroomModal(false)} onSubmit={createDataRoom} /> }
      <DataroomsList dataRooms={dataRooms} />
      {showLimitReachedModal && (
        <ModalSwal
          img={Warning}
          text={t('data_room_limit_reached')}
          onClick={() => setShowLimitReachedModal(false)}
        />
      )}
    </>
  )
}

export default DataRooms
