import React from 'react'
import { useTranslation } from 'react-i18next'

import { Container } from 'components/ui/objects/Container'
import { Button } from 'components/ui/atoms/Button'

import TestVideo from 'styles/images/test.mp4'
import Logo from 'styles/images/logo_light.svg'

import Gray from 'styles/images/circle_gray.png'
import Blue from 'styles/images/circle_blue.png'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

const ThirdStep = ({ nextStep, previousStep }) => {
  const { t } = useTranslation()

  return (
    <div className="bg-bgOnboardingTwo bg-cover bg-no-repeat bg-center min-w-screen min-h-screen relative flex justify-center align-center h-full">
      <div className="w-full flex items-center justify-between pr-10 fixed top-0">
        <img src={Logo} alt="Logo" className="mx-6 my-4" />
        <div>
          <button
            className="text-coolGray-500 font-medium outline-none focus:outline-none shadow-soft-white hover:shadow-inner rounded-2xl p-2 text-xs bg-transparence-blue"
            onClick={previousStep}
            type="button"
          >
            {t('go_back')}
          </button>
        </div>
      </div>

      <div className="pt-8 px-4 | text-center | w-11/12 lg:w-9/12 max-w-screen-lg min-h-screen">
        <div className="flex  justify-center items-center h-full">
          <div className="flex flex-col lg:flex-row justify-around w-full">
            <div className="lg:text-left mt-4 lg:-ml-14 lg:mr-10">
              <h1 className="text-xl text-black mb-4">{t('what_are_we')}</h1>
              <p className="lg:max-w-xs mb-4 text-sm">{t('resources')}</p>
              <p className="lg:max-w-xs mb-4 text-sm">{t('skeep_resources')}</p>
              <div className="hidden lg:block">
                <ButtonMain
                  onClick={nextStep}
                  type="button"
                  text={t('next')}
                />
              </div>
            </div>
            <div className="lg:max-w-7xl lg:-mr-32 flex flex-col items-center mt-10 lg:mt-0">
              <video width="900px" height="auto" controls autoPlay>
                <source src={TestVideo} type="video/mp4" />
                <source src={TestVideo} type="video/ogg" />
                {t('support_video')}
              </video>
              <div className="lg:hidden mt-10">
                <ButtonMain
                  onClick={nextStep}
                  type="submit"
                  text={t('next')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ThirdStep
