import EventService from 'services/events-service'

const EventUtils = {
  async sendAnalyticEvent (data) {
    try {
      await EventService.saveAnalyticEvent({
        ...data,
        platform_id: sessionStorage.getItem('platform_id')
      })
    } catch (e) {
      console.error(e)
    }
  }
}

export default EventUtils
