import React from 'react'
import Avatar from 'react-avatar'
import moment from 'moment'
import { FaArrowRight, FaUser } from 'react-icons/all'
import { useTranslation } from 'react-i18next'

export function BasicCardMessageTemplate ({ template, onSend }) {
  const { t } = useTranslation()
  const trimMessage = (message, length) => message.length > length - 3 ? `${message.substring(0, length)}...` : message

  return (
    <div className="py-2 px-1">
      <div className="w-28 h-32 flex flex-col justify-between items-between | shadow-soft-white rounded-2xl | p-1">
        <div className="text-left leading-none flex flex-col justify-start items-start h-full px-1">
          <p className="text-xxs h-auto break-all font-semibold mb-2">
            {trimMessage(template.title, 14)}
          </p>
          <p className="text-xxxs">
            {trimMessage(template.message, 132)}
          </p>
        </div>
        <div className="flex w-full justify-between items-center border-t border-gray-lines px-1 pt-1">
          <span className="text-xxxs text-gray">
            {moment(template.createdAt).format('YYYY-MM-DD')}
          </span>
          <span title={template.created_by_user?.name}>
            {template.created_by_user.image
              ? (
              <Avatar
                src={template.created_by_user.image}
                color="#e0e6f2"
                fgColor="#4d70b3"
                size="20px"
                round="10px"
              />
                )
              : (
              <FaUser className="text-xxxs text-gray" />
                )}
          </span>
        </div>
        <div className="flex w-full justify-around border-t border-gray-lines px-1 pt-1">
          <button
            className="flex items-center justify-center p-1 cursor-pointer text-xxs"
            onClick={() => onSend()}
          >
            {t('send')} <FaArrowRight className="ml-1" />
          </button>
        </div>
      </div>
    </div>
  )
}
