import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useToasts } from 'react-toast-notifications'
import { SidePanel } from '../../ui/objects/SidePanel'
import { Input } from '../../ui/atoms/Input'
import Select from '../../ui/atoms/Select'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import Config from 'config'

export const FormQuestionsFilters = ({
  onFilter,
  onClose,
  filters = {}
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const originTypes = [
    { id: 'mine', value: t('questions_mine') },
    { id: 'kiota', value: t('questions_kiota') }
    // { id: 'other_investors', value: t('questions_other_investors') }
  ]
  const questionTypes = [
    { id: 'short_answer', value: t('short_answer') },
    { id: 'long_answer', value: t('long_answer') },
    { id: 'boolean', value: t('boolean') },
    { id: 'select', value: t('select') },
    { id: 'number', value: t('number') },
    { id: 'multiselect', value: t('multiselect') },
    { id: 'date', value: t('date') },
    { id: 'url', value: t('url') },
    { id: 'phone', value: t('phone') },
    { id: 'likert_scale', value: t('likert_scale') },
    { id: 'email', value: t('email') },
    { id: 'file', value: t('file') }
  ]

  const {
    register,
    handleSubmit,
    trigger,
    errors,
    setValue,
    reset,
    watch
  } = useForm({
    resolver: yupResolver(Yup.object().shape({
      title: Yup.string(),
      origin: Yup.array().nullable(),
      type: Yup.array().nullable()
    }), { abortEarly: false }),
    criteriaMode: 'all',
    reValidateMode: 'all',
    mode: 'onChange'
  })

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config)
    trigger(name)
  }

  const filter = (filters) => {
    if (filters.origin && filters.origin.length) {
      filters.origin = filters.origin.map((item) => item.id)
    }

    if (filters.type && filters.type.length) {
      filters.type = filters.type.map((item) => item.id)
    }

    onFilter && onFilter(filters)
    onClose && onClose()
  }

  const resetFilters = () => {
    reset()
    filter({})
  }

  const onInvalid = () => {
    addToast(t('form_field_error'), { appearance: 'error', autoDismiss: true })
  }

  useEffect(() => {
    register('origin')
    register('type')
  }, [register])

  useEffect(() => {
    if (filters) {
      Object.entries(filters).forEach(([key, value]) => {
        if (key === 'origin') {
          const _value = (value || []).map((item) => originTypes.find((origin) => origin.id === item))
          _setValue(key, _value, { shouldValidate: true })
        } else if (key === 'type') {
          const _value = (value || []).map((item) => questionTypes.find((type) => type.id === item))
          _setValue(key, _value, { shouldValidate: true })
        } else {
          _setValue(key, value, { shouldValidate: true })
        }
      })
    }
  }, [filters, register])

  return (
    <SidePanel title={t('filters')} onClose={() => onClose && onClose()}>
      <form
        className="flex flex-col w-72"
        onSubmit={handleSubmit(filter, onInvalid)}
      >
        <div>
          <Input
            reference={register}
            name="title"
            label={t('title')}
            placeholder={t('title')}
            error={errors.title}
          />

          <Select
            name='origin'
            label={t('origin')}
            isClearable={true}
            onSelect={selection => _setValue('origin', selection || null)}
            initialValues={watch('origin') ? watch('origin') : []}
            multiSelect={true}
            items={originTypes}
          />

          <Select
            name='type'
            label={t('type')}
            isClearable={true}
            onSelect={selection => _setValue('type', selection || null)}
            initialValues={watch('type') ? watch('type') : []}
            multiSelect={true}
            items={questionTypes}
          />
        </div>

        <div className={`flex justify-end mt-2 pt-2 border-t border-separator-${Config.PLATFORM}`}>
          <ButtonSecondary
            text={t('clear_filters')}
            onClick={() => resetFilters()}
            type="button"
            marginRight={2}
          />
          <ButtonMain
            text={t('filter')}
            type="submit"
          />
        </div>
      </form>
    </SidePanel>
  )
}
