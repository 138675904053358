import axios from 'axios/index'
import Config from '../config'

const BoardsService = {
  createBoard: async (name, columns = [], entityToManage) => {
    return axios.post(`${Config.USERS_API_URL}boards`, { name, columns, entity_to_manage: entityToManage})
  },

  getBoards: async (projectId = null, populated = false, isFromShared = false, investorId) => {
    const params = new URLSearchParams()

    if (populated) {
      params.append('populated', 'true')
    }

    if (projectId) {
      params.append('projectId', projectId)
    }

    if (isFromShared && investorId) {
      params.append('investorId', investorId)
    }

    return axios.get(`${Config.USERS_API_URL}boards?${params.toString()}`, {
      ...(isFromShared ? { headers: { 'X-Shared': 'true' } } : {})
    })
  },

  getBoard: async (boardId, isPublic = false) => {
    return axios.get(`${Config.USERS_API_URL}boards/${boardId}`, isPublic ? { headers: { 'X-Shared': 'true' } } : {})
  },

  deleteBoard: async (boardId) => {
    return axios.delete(`${Config.USERS_API_URL}boards/${boardId}`)
  },

  updateBoard: async (boardId, data) => {
    return axios.patch(`${Config.USERS_API_URL}boards/${boardId}`, data)
  },

  deleteColumn: async (boardId, columnId) => {
    return axios.delete(
      `${Config.USERS_API_URL}boards/${boardId}/columns/${columnId}`
    )
  },

  updateColumn: async (boardId, columnId, data) => {
    return axios.patch(
      `${Config.USERS_API_URL}boards/${boardId}/columns/${columnId}`,
      data
    )
  },

  deleteCard: async (boardId, columnId, cardId) => {
    return axios.delete(
      `${Config.USERS_API_URL}boards/${boardId}/columns/${columnId}/cards/${cardId}`
    )
  },

  createColumn: async (boardId, data) => {
    return axios.post(`${Config.USERS_API_URL}boards/${boardId}/columns/`, data)
  },

  createCard: async (boardId, columnId, card) => {
    return axios.post(
      `${Config.USERS_API_URL}boards/${boardId}/columns/${columnId}/cards`,
      card
    )
  },

  moveCard: async (boardId, cardId, data) => {
    return axios.patch(
      `${Config.USERS_API_URL}boards/${boardId}/cards/${cardId}`,
      data
    )
  },

  sortCard: async (boardId, columnId, cardId, data) => {
    return axios.patch(
      `${Config.USERS_API_URL}boards/${boardId}/columns/${columnId}/cards/${cardId}/sort`,
      data
    )
  },

  updateCard: (boardId, columnId, cardId, data) => {
    return axios.patch(
      `${Config.USERS_API_URL}boards/${boardId}/columns/${columnId}/cards/${cardId}`,
      data
    )
  }
}

export default BoardsService
