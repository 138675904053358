import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useToasts } from 'react-toast-notifications'
import { SidePanel } from 'components/ui/objects/SidePanel'
import { Input } from 'components/ui/atoms/Input'
import Select from 'components/ui/atoms/Select'
import Sectors from 'assets/json/sectors.json'
import InvestmentStages from 'assets/json/investment-stages.json'
import Countries from 'assets/json/countries.json'
import ProjectStages from 'assets/json/project-stages.json'
import BusinessModels from 'assets/json/business-models.json'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import Config from 'config'
import { CurrencyInput } from 'components/ui/atoms/CurrencyInput'

const IRMInvestorsFilters = ({
  onFilter,
  onClose,
  filters = {},
}) => {

  const { t } = useTranslation()
  const { addToast } = useToasts()

  const sectors = Sectors.map(item => ({ value: t(item.key), id: item._id })).sort((a, b) => a.value > b.value ? 1 : -1)
  const countries = Countries.map(item => ({ value: t(item.key), id: item._id })).sort((a, b) => a.value > b.value ? 1 : -1)
  const investmentStages = InvestmentStages.map(item => ({ value: t(item.key), id: item._id })).sort((a, b) => a.value > b.value ? 1 : -1)
  const projectStages = ProjectStages.map(item => ({ value: t(item.key), id: item._id })).sort((a, b) => a.value > b.value ? 1 : -1)
  const businessModels = BusinessModels.map(item => ({ value: t(item.key), id: item._id })).sort((a, b) => a.value > b.value ? 1 : -1)

  const leadInvestorsOptions = [{ id: 'yes', value: t('yes') }, { id: 'no', value: t('no') }, { id: 'depends', value: t('depends') }]
  const investorTypesOptions = [{ id: 'accelerator', value: t('accelerator') }, { id: 'ba', value: t('ba') }, { id: 'vc', value: t('vc') }, { id: 'ba_network', value: t('ba_network') }]

  const {
    register,
    handleSubmit,
    trigger,
    errors,
    setValue,
    reset,
    watch
  } = useForm({
    resolver: yupResolver(Yup.object().shape({
      name: Yup.string(),
      minimum_ticket: Yup.number()
          .typeError(t('incorrect_format'))
          .transform((_, val) => val === `${Number(val)}` ? Number(val) : null)
          .nullable(),
      minimum_ticket_unit: Yup.string(),
      maximum_ticket: Yup.number()
        .typeError(t('incorrect_format'))
        .transform((_, val) => val === `${Number(val)}` ? Number(val) : null)
        .nullable(),
      maximum_ticket_unit: Yup.string(),
      minimum_valuation: Yup.number()
          .typeError(t('incorrect_format'))
          .transform((_, val) => val === `${Number(val)}` ? Number(val) : null)
          .nullable(),
      minimum_valuation_unit: Yup.string(),
      maximum_valuation: Yup.number()
          .typeError(t('incorrect_format'))
          .transform((_, val) => val === `${Number(val)}` ? Number(val) : null)
          .nullable(),
      maximum_valuation_unit: Yup.string(),
      sectors: Yup.array().nullable(),
      countries: Yup.array().nullable(),
      investment_stages: Yup.array().nullable(),
      project_stages: Yup.array().nullable(),
      business_models: Yup.array().nullable(),
      lead_investor: Yup.string().nullable(),
      investor_type: Yup.string().nullable()
    }), { abortEarly: false }),
    criteriaMode: 'all',
    reValidateMode: 'all',
    mode: 'onChange'
  })

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config)
    trigger(name)
  }

  const filter = (filters) => {
    if (filters.sectors && filters.sectors.length) {
      filters.sectors = filters.sectors.map((item) => item.id)
    }

    if (filters.countries && filters.countries.length) {
        filters.countries = filters.countries.map((item) => item.id)
      }
    
    if (filters.investment_stages && filters.investment_stages.length) {
    filters.investment_stages = filters.investment_stages.map((item) => item.id)
    }

    if (filters.project_stages && filters.project_stages.length) {
        filters.project_stages = filters.project_stages.map((item) => item.id)
      }

      if (filters.business_models && filters.business_models.length) {
        filters.business_models = filters.business_models.map((item) => item.id)
      }

    onFilter && onFilter({...filters, exclusive: true})
    onClose && onClose()
  }

  const resetFilters = () => {
    reset()
    filter({})
  }

  const onInvalid = (error) => {
    console.error(error)
    addToast(t('form_field_error'), { appearance: 'error', autoDismiss: true })
  }

  useEffect(() => {
    register('lead_investor')
    register('sectors')
    register('countries')
    register('investment_stages')
    register('project_stages')
    register('business_models')
    register('investor_type')
    register('minimum_ticket_unit')
    register('maximum_ticket_unit')
    register('minimum_valuation_unit')
    register('maximum_valuation_unit')
  }, [register])

  useEffect(() => {
    if (filters) {
      if (Object.keys(filters).length) {
        Object.entries(filters).forEach(([key, value]) => {
          if (key === 'sectors') {
            const _value = (value || []).map((item) => sectors.find((sector) => sector.id === item))
            _setValue(key, _value, { shouldValidate: true })
          } else if (key === 'countries') {
              const _value = (value || []).map((item) => countries.find((country) => country.id === item))
              _setValue(key, _value, { shouldValidate: true })
            } else if (key === 'investment_stages') {
              const _value = (value || []).map((item) => investmentStages.find((investmentStage) => investmentStage.id === item))
              _setValue(key, _value, { shouldValidate: true })
            } else if (key === 'project_stages') {
              const _value = (value || []).map((item) => projectStages.find((projectStage) => projectStage.id === item))
              _setValue(key, _value, { shouldValidate: true })
            } else if (key === 'business_models') {
              const _value = (value || []).map((item) => businessModels.find((businessModel) => businessModel.id === item))
              _setValue(key, _value, { shouldValidate: true })
            } else if (key === 'lead_investor') {
              _setValue(key, value, { shouldValidate: true })
            } else if (key === 'investor_type') {
              _setValue(key, value, { shouldValidate: true })
            } else if (key === 'minimum_ticket_unit') {
              _setValue(key, value || 'k', { shouldValidate: true })
            } else if (key === 'maximum_ticket_unit') {
              _setValue(key, value || 'k', { shouldValidate: true })
            } else if (key === 'minimum_valuation_unit') {
              _setValue(key, value || 'k', { shouldValidate: true })
            } else if (key === 'maximum_valuation_unit') {
              _setValue(key, value || 'k', { shouldValidate: true })
            } else {
            _setValue(key, value, { shouldValidate: true })
          }
        })
      } else {
        _setValue('minimum_ticket_unit', 'k')
        _setValue('maximum_ticket_unit', 'k')
        _setValue('minimum_valuation_unit', 'k')
        _setValue('maximum_valuation_unit', 'k')
      }
    } else {
      _setValue('minimum_ticket_unit', 'k')
      _setValue('maximum_ticket_unit', 'k')
      _setValue('minimum_valuation_unit', 'k')
      _setValue('maximum_valuation_unit', 'k')
    }

    console.log({filters})
  }, [filters, register])

  return (
    <SidePanel title={t('filter_investors')} onClose={() => onClose && onClose()} width={'1/4'}>
      <form
        className="flex flex-col w-full"
        onSubmit={handleSubmit(filter, onInvalid)}
      >
        <div>
         <Select
            label={t('investor_type')}
            name={'investor_type'}
            items={investorTypesOptions}
            multiSelect={false}
            isClearable={true}
            initialValues={watch('investor_type') ? [investorTypesOptions.find((investorTypeOption) => investorTypeOption.id === watch('investor_type'))] : []}
            onSelect={selection => _setValue('investor_type', selection.length ? selection[0].id : null)}
          />
          <Input
            reference={register}
            name="name"
            label={t('name')}
            placeholder={t('name')}
            error={errors.name}
          />
           <CurrencyInput
              nameInput={'minimum_ticket'}
              nameSelect={'minimum_ticket_unit'}
              reference={register}
              placeholder={t('minimum_ticket') + ' (€)'}
              label={t('minimum_ticket') + ' (€)'}
              error={errors.minimum_ticket}
              watch={watch}
              setValue={_setValue}
            />

            <CurrencyInput
              nameInput={'maximum_ticket'}
              nameSelect={'maximum_ticket_unit'}
              reference={register}
              placeholder={t('maximum_ticket') + ' (€)'}
              label={t('maximum_ticket') + ' (€)'}
              error={errors.maximum_ticket}
              watch={watch}
              setValue={_setValue}
            />
            <CurrencyInput
              nameInput={'minimum_valuation'}
              nameSelect={'minimum_valuation_unit'}
              reference={register}
              placeholder={t('minimum_valuation') + ' (€)'}
              label={t('minimum_valuation') + ' (€)'}
              error={errors.minimum_valuation}
              watch={watch}
              setValue={_setValue}
            />
            <CurrencyInput
              nameInput={'maximum_valuation'}
              nameSelect={'maximum_valuation_unit'}
              reference={register}
              placeholder={t('maximum_valuation') + ' (€)'}
              label={t('maximum_valuation') + ' (€)'}
              error={errors.maximum_valuation}
              watch={watch}
              setValue={_setValue}
            />
          <Select
            name='sectors'
            label={t('sectors')}
            isClearable={true}
            onSelect={selection => _setValue('sectors', selection || null)}
            initialValues={watch('sectors') ? watch('sectors') : []}
            multiSelect={true}
            items={sectors}
          />
          <Select
            name='countries'
            label={t('countries')}
            isClearable={true}
            onSelect={selection => _setValue('countries', selection || null)}
            initialValues={watch('countries') ? watch('countries') : []}
            multiSelect={true}
            items={countries}
          />
          <Select
            name='investment_stages'
            label={t('investment_stages')}
            isClearable={true}
            onSelect={selection => _setValue('investment_stages', selection || null)}
            initialValues={watch('investment_stages') ? watch('investment_stages') : []}
            multiSelect={true}
            items={investmentStages}
          />
          <Select
            name='project_stages'
            label={t('project_stages')}
            isClearable={true}
            onSelect={selection => _setValue('project_stages', selection || null)}
            initialValues={watch('project_stages') ? watch('project_stages') : []}
            multiSelect={true}
            items={projectStages}
          />
          <Select
            name='business_models'
            label={t('business_models')}
            isClearable={true}
            onSelect={selection => _setValue('business_models', selection || null)}
            initialValues={watch('business_models') ? watch('business_models') : []}
            multiSelect={true}
            items={businessModels}
          />
          <Select
            label={t('is_this_investor_lead')}
            name={'lead_investor'}
            items={leadInvestorsOptions}
            multiSelect={false}
            isClearable={true}
            initialValues={watch('lead_investor') ? [leadInvestorsOptions.find((leadInvestorOption) => leadInvestorOption.id === watch('lead_investor'))] : []}
            onSelect={selection => _setValue('lead_investor', selection.length ? selection[0].id : null)}
          />
        </div>

        <div className={`flex justify-end mt-2 pt-2 border-t border-separator-${Config.PLATFORM}`}>
          <ButtonSecondary
            text={t('clear_filters')}
            onClick={() => resetFilters()}
            type="button"
            marginRight={2}
          />
          <ButtonMain
            text={t('filter')}
            type="submit"
          />
        </div>
      </form>
    </SidePanel>
  )
}

export default IRMInvestorsFilters
