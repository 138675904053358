import React, { useState, useEffect } from 'react';
import { GoogleButton } from 'components/ui/atoms/GoogleButton';
import { Button } from 'components/ui/atoms/Button';
import Config from 'config';
import { useTranslation } from 'react-i18next'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary';
import { useSwal } from 'hooks/useSwal';

const API_KEY = 'AIzaSyDDXutZvDTGFliGz9_-PG1IwnXERSVu5jU'
const CLIENT_ID = '690938764456-ac8b32rplj2tfk4f43grc7iuh36udi8i.apps.googleusercontent.com'

const EmailSyncView = () => {
  const { t } = useTranslation()
  const { confirm } = useSwal()

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    const handleLoadGoogleAuth = async () => {
      if (!gapi.auth2.getAuthInstance()) {
        await gapi.client.init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest'],
          scope: 'https://www.googleapis.com/auth/gmail.readonly profile email'
        });
      }

      const authInstance = gapi.auth2.getAuthInstance();

      if (authInstance.isSignedIn.get()) {
        console.log('is authenticated')

        const basicProfile = authInstance.currentUser.get().getBasicProfile();
        const _email = basicProfile.getEmail();
        setIsAuthenticated(true);
        setUserEmail(_email);
      } else {
        console.log('user not authenticated');
        handleLoginFailure();
        setIsAuthenticated(false);
      }
    };

    gapi.load('client:auth2', handleLoadGoogleAuth);
  }, []);


  const handleLoginSuccess = (response) => {
    // console.log()
    setIsAuthenticated(true);

    // Recupera el email del usuario del objeto de respuesta
    const email = response?.profileObj?.email;
    setUserEmail(email);
  };

  const handleLoginFailure = (response) => {
    console.log('Error al iniciar sesión', response);
  };

  const handleLogout = () => {
    confirm({
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        const authInstance = window.gapi.auth2.getAuthInstance();
        authInstance.signOut().then(() => {
          setIsAuthenticated(false);
          setUserEmail('');
        });
      }
    })
  }

  return (
    <div>
      <h3 className={`border-b font-medium text-main-${Config.PLATFORM} font-bold mb-4 border-main-${Config.PLATFORM}`}>
        {t('synchronization')}
      </h3>
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-start sm:justify-between">
        <div>
          {!isAuthenticated && <small className="text-gray-dark font-light">
            {t('enable_email_synchronization')}
          </small>}
          {isAuthenticated && <>
            <small className='text-gray-dark font-light'>{t('connected_with_email')}</small>
            <small className={`text-main-${Config.PLATFORM} font-semibold ml-1`}>
              {userEmail}
            </small>
            </>
          }
        </div>
        <div className="flex flex-row">
          {isAuthenticated ? (
              <ButtonSecondary
                onClick={() => handleLogout()}
                text={t('disable_email_synchronization')}
                />
            ) : (<GoogleButton
              text={t('enable_email_synchronization')}
              clientId={CLIENT_ID}
              onSuccess={handleLoginSuccess}
              onFailure={handleLoginFailure}
              cookiePolicy={'single_host_origin'}
            />)}
        </div>
      </div>
    </div>
  )
}

export default EmailSyncView;