import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom'
import { Loader } from 'components/ui/molecules/Loader'
import { Pagination } from 'components/ui/atoms/Pagination'
import { RoutesLinks } from 'components/routes-links'
import { DealflowService } from 'services/dealflow-service'
import { usePagination } from 'hooks/usePagination'
import { Input } from 'components/ui/atoms/Input'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import { Button } from 'components/ui/atoms/Button'
import {
  FaWpforms,
  FaCalendarWeek,
  FaArrowRight,
  FaRocket,
  FaUsers,
  FaRegEdit,
  FaClipboardList, FaCheckCircle, FaTimesCircle, FaPlusCircle, FaUser, GiPodium, FaGavel, FaPlus, FaEye, FaInfoCircle
} from 'react-icons/all'
import { PageTitle } from 'components/ui/atoms/PageTitle'
import { SectionHeader } from 'components/ui/molecules/SectionHeader'
import UsersService from 'services/users-service'
import { Card } from 'components/ui/atoms/Card'
import SupportService from 'services/support-service'
import { isEqual } from 'lodash'
import { DataGrid } from 'components/ui/objects/DataGrid'
import { useSwal } from 'hooks/useSwal'
import Config from 'config';
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import ReactTooltip from 'react-tooltip'
import { ButtonCardMain } from 'components/ui/atoms/ButtonCardMain'
import moment from 'moment'
import EvaluationProcessWizard from './EvaluationProcessWizard'

const EvaluationProcesses = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const myRef = useRef(null)
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)
  const ROUTES = {
    score_cards: RoutesLinks.SCORE_CARDS_LINK,
  }
  const { confirm } = useSwal()
  const [section, setSection] = useState('active')

  const SECTIONS = ['evaluation_processes', 'score_cards']
  const [employees, setEmployees] = useState([])
  const [events, setEvents] = useState([])
  const [sort] = useState('-createdAt')
  const [showEvaluationProcessWizard, setShowEvaluationProcessWizard] = useState(false)
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 10,
    totalPages: 0,
    totalDocs: 0
  })
  const getEvents = (archived = false) => {
    setLoading(true)
    SupportService.getEvents({
      page: pagination.page,
      rowsPerPage: pagination.rowsPerPage,
      sortBy: sort,
      archived
    }).then(result => {
      setEvents(result.data.data.events)
      if (result.data.data.pagination) {
        const newPagination = {
          ...pagination,
          totalPages: result.data.data.pagination.pages,
          totalDocs: result.data.data.pagination.totalDocs
        }

        if (!isEqual(newPagination, pagination)) {
          setPagination(newPagination)
        }
      }
    }).catch(error => {
      addToast(t('error_occurred_retrieving_events'), {
        appearance: 'error',
        autoDismiss: true
      })
      console.log(error)
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    getEvents()
    myRef.current.scrollIntoView()
  }, [pagination.page, pagination.rowsPerPage])

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [events])

  useEffect(() => {
    if (section === 'active') {
      getEvents()
    } else if (section === 'archived') {
      getEvents(true)
    }
  }, [section])

  return (
    <div ref={myRef}>
      {showEvaluationProcessWizard && <EvaluationProcessWizard getEvents={getEvents} onClose={() => setShowEvaluationProcessWizard(false)} />}
      <div className='flex items-start'>
        <div className='flex-1'>
          <PageTitle
            title={t('evaluation_processes')}
          />
        </div>
        <ButtonMain
          iconComponent={<FaPlus className='inline-block mr-2' />}
          text={t('create_evaluation_process')}
          onClick={() => setShowEvaluationProcessWizard(true)}
          />
      </div>
      <SectionHeader
        section={'evaluation_processes'}
        setSection={(sec) => {
          history.push(ROUTES[sec])
        }}
        sectionKeys={SECTIONS}
        sectionTitles={SECTIONS.map((s) => t(s))}
      />
      <SectionHeader
        section={section}
        setSection={setSection}
        sectionKeys={['active', 'archived']}
        sectionTitles={[t('active'), t('archived')]}
      />
      {loading && <Loader />}
      {events.length === 0 && !loading && (
        <div className="my-6">
          <NoDataInfo
            title={t('evaluation_processes_not_found')}
            textOne={t('no_evaluation_processes_description')}
          />
        </div> 
      )}
      {events.length > 0 && (
        <>
          <div className="mt-6 grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-8">
            {events.map((event, index) => (
              <Card
                key={index}
                containerClassName="relative overflow-hidden"
              >

                {event.archived && (
                  <div
                    style={{ top: 10, right: -55 }}
                    className={`absolute m-4 bg-white border border-border-buttons-secondary-${Config.PLATFORM} text-main-${Config.PLATFORM} text-sm rotate-45 px-12 z-10 font-semibold`}
                  >
                    {t('archived')}
                  </div>
                )}

                <div className="flex justify-between">
                  <div className="flex-1 relative">
                    <div className={`w-full px-1 font-bold text-main-${Config.PLATFORM}flex flex-col`}>
                      <div>
                        {event.name}
                      </div>
                      <div className={'text-xxs mr-1 text-black my-2 border-t border-gray-lines pt-2'}>
                        <span data-tip={t(event.managed_entity || 'startups')}>
                          <FaRocket className="inline-block w-5 h-5 mr-1" />
                        </span>
                        {event.stats.startupsCount}
                        <span data-tip={t('judges')}>
                          <FaGavel className="inline-block w-4 h-4 mr-1 ml-4" />
                        </span>
                          {event.stats.judgesCount}

                        <span data-tip={t('evaluations')}>
                          <GiPodium className="inline-block w-5 h-5 mr-1 ml-4" />
                        </span>
                          {event.stats.evaluations}
                          <span
                          data-tip={t(event.score_card ? 'scorecard_assigned' : 'scorecard_not_assigned')}
                          className="font-semibold"
                        >
                          <FaClipboardList className={`inline-block w-5 h-5 ml-4 ${event.score_card ? 'text-black' : 'text-gray-lines'} `} />
                        </span>
                        <span
                          data-tip={t((event.show_general_information || event?.source_information?.length) ? 'source_information_assigned' : 'source_information_not_assigned')}
                          className="font-semibold"
                        >
                          <FaInfoCircle className={`inline-block w-5 h-5 ml-2 ${(event.show_general_information || event?.source_information?.length) ? 'text-black' : 'text-gray-lines'} `} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`flex justify-end pt-2 border-t border-separator-${Config.PLATFORM}`}>
                  <ButtonCardMain
                    onClick={() => history.push(`${RoutesLinks.EVALUATION_PROCESSES_LINK}${event._id}`)}
                    text={t('see_details')}
                    iconComponent={<FaEye className="inline-block mr-2 w-5 h-4" />}
                  />
                </div>
              </Card>
            ))}
          </div>

          <Pagination
            currentPage={pagination.page}
            setCurrentPage={(page) => {
              setPagination({
                ...pagination,
                page
              })
            }}
            perPage={pagination.rowsPerPage}
            pages={pagination.totalPages}
            setPerPage={(value) => {
              setPagination({
                ...pagination,
                page: 0,
                rowsPerPage: value[0].id
              })
            }}
          />
        </>
      )}
    </div>
  )
}

export default EvaluationProcesses
