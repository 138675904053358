import {
  FaBell,
  FaColumns,
  FaExpandAlt,
  FaEye,
  FaFile,
  FaPaperPlane,
  FaShareAlt,
  FaTags,
  GiProgression
} from 'react-icons/all'
import { useTranslation } from 'react-i18next'
import { Card } from 'components/ui/atoms/Card'
import Avatar from 'react-avatar'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { RoutesLinks } from '../routes-links'
import { TagList } from '../tags/TagList'
import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { ProgressBar } from 'components/ui/atoms/ProgressBar'
import { Button } from 'components/ui/atoms/Button'
import SupportService from '../../services/support-service'
import { useToasts } from 'react-toast-notifications'
import { Loader } from '../ui/molecules/Loader'
import ShareStartupSection from '../ui/atoms/ShareStartupSection'
import Config from 'config'
import { ButtonCardMain } from 'components/ui/atoms/ButtonCardMain'
import { ButtonCardSecondary } from 'components/ui/atoms/ButtonCardSecondary'
import Utils from 'utils/utils'
import { DealflowBoardsBar } from './DealflowBoardsBar'
import { RemindersBar } from './RemindersBar'

export const DealflowPoolListItem = ({ dealflow, setDealToResume }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [showShareStartupSection, setShowShareStartupSection] = useState(false)

  const getMilestonePercent = () => {
    if (!dealflow?.milestones_resume) {
      return 0
    }

    const { objetives, completed } = dealflow?.milestones_resume
    return completed ? Math.round((completed / objetives) * 100) : 0
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  return (
    <>
      <Card
        wrapperClassName="transition-transform"
        containerClassName="w-full flex flex-col h-full"
      >
        <div className="flex pb-2 mb-2 ">
          <div className='flex flex-row w-full justify-around'>

            <div className="flex flex-col h-full text-sm min-w-0 w-3/5">
              <h5 className={`uppercase font-bold text-sm leading-none text-main-${Config.PLATFORM} truncate mb-1`}>
                {dealflow.name}
              </h5>
              <div className='flex flex-row'>
                <div className='w-auto'>
                  {Utils.getDealflowStatus(dealflow, t)}
                </div>
                {dealflow.priority && (
                  <div className='w-auto'>
                    {Utils.getDealflowPriority(dealflow, t)}
                  </div>
                )}
                {dealflow.next_step && (
                  <div className='w-auto'>
                    {Utils.getDealflowNextStep(dealflow, t)}
                  </div>
                )}
              </div>
            </div>

            <div className="flex items-center justify-end mr-5 w-2/5 ">
            <div className="info-card-container-img">
              <Avatar
                src={dealflow.logo}
                name={dealflow.name}
                alt={dealflow.name}
                maxInitials={3}
                className={`h-full object-contain ${dealflow.logo ? 'w-full' : ''}`}
                color="#e0e6f2"
                fgColor="#4d70b3"
                round="5"
              />
              </div>
            </div>

          </div>
        </div>

        <div className='flex items-center'>
          <div className='mr-2' data-tip={t('boards')}>
            <FaColumns className={`text-main-${Config.PLATFORM}`} />
          </div>
          <div className='flex-1 overflow-hidden'>
            <DealflowBoardsBar dealflow={dealflow} />
          </div>
        </div>

        <div className='flex items-center border-t border-gray-lines py-2'>
          <div className='mr-2' data-tip={t('tags')}>
            <FaTags className={`text-main-${Config.PLATFORM}`} />
          </div>
          <div className='flex-1'>
            <TagList tags={dealflow.tags || []} showEmptyState={true} />
          </div>
        </div>

        <div className='flex items-center border-t border-gray-lines py-2'>
          <div className='mr-2' data-tip={t('milestones')}>
            <GiProgression className={`text-main-${Config.PLATFORM}`} />
          </div>
          <div className='flex-1'>
            {getMilestonePercent() > 0 ? (
              <div className='mt-1'>
                <ProgressBar value={getMilestonePercent()} textSize={'text-xs'}/>
              </div>
            ) : <span className="inline-block mr-3 mt-1 font-semibold text-xxs px-2 py-0.5 text-gray-300">
            {t('no_milestones')}
          </span>}
          </div>
        </div>
        <div className='flex items-center border-t border-gray-lines py-2'>
          <div className='mr-2' data-tip={t('reminders')}>
            <FaBell className={`text-main-${Config.PLATFORM}`} />
          </div>
          <div className='flex-1 overflow-hidden'>
            <RemindersBar dealflow={dealflow}/>
          </div>
        </div>

        <div className={`flex justify-end mt-2 pt-2 border-t border-separator-${Config.PLATFORM}`}>
          <ButtonCardSecondary
            onClick={() => setDealToResume(dealflow)}
            text={t('see_resume')}
            iconComponent={<FaExpandAlt className="inline-block mr-2 w-5 h-4" />}
            marginRight="4"
            />
          {Config.PLATFORM !== '000' && dealflow.contact_email && <Button
            onClick={() => window.location = `mailto:${dealflow.contact_email}`}
            text={t('share')}
            verticalMargin="1"
            iconComponent={<FaPaperPlane className="inline-block mr-2 w-5 h-4" />}
            vertical="2"
            horizontal="4"
            marginRight="4"
            width={'auto'}
            className="text-xs bg-white"
          />}
          <ButtonCardMain
            onClick={() => history.push(`${RoutesLinks.DEALFLOW_LINK}${dealflow._id}`)}
            text={t('see_profile')}
            iconComponent={<FaEye className="inline-block mr-2 w-5 h-4" />}
          />
        </div>
      </Card>

      {showShareStartupSection && (
        <ShareStartupSection
          elementToShare={dealflow}
          source='pool'
          onClose={() => setShowShareStartupSection(false)}
        />
      )}

      {loading && <Loader />}
    </>
  )
}
