import { useTranslation } from 'react-i18next'

export function PoweredText () {
    const { t } = useTranslation();

  return (
    <div className="sm:w-3/4 bottom-0 | sm:pb-2 | text-center border-t border-gray-lines">
      <span className="block left-0 | text-gray font-normal text-xxs">{t('powered_by_kiota')}</span>
    </div>
  )
}
