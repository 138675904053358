import axios from 'axios/index'
import Config from '../config'

const DataRoomService = {
  updateVisibility: async (projectId, dataRoomId, data) => {
    return axios.patch(
      `${Config.PROJECTS_API_URL}${projectId}/data-rooms/${dataRoomId}/visibility`,
      data
    )
  },

  inviteGuest: async (projectId, dataRoomId, data) => {
    return axios.post(
      `${Config.PROJECTS_API_URL}${projectId}/data-rooms/${dataRoomId}/invite`,
      data
    )
  },

  deleteGuest: async (projectId, dataRoomId, data) => {
    return axios.post(
      `${Config.PROJECTS_API_URL}${projectId}/data-rooms/${dataRoomId}/revoke-access`,
      data
    )
  },

  deleteDocument: async (projectId, dataRoomId, documentId, section) => {
    return axios.delete(
      `${Config.PROJECTS_API_URL}${projectId}/data-rooms/${dataRoomId}/documents/${documentId}?section=${section}`
    )
  },

  updateDataRoomTeam: async (projectId, dataRoomId, data) => {
    return axios.patch(
      `${Config.PROJECTS_API_URL}${projectId}/data-rooms/${dataRoomId}/team`,
      data
    )
  },

  resendCode: async (projectId, dataRoomId, data) => {
    return axios.post(
      `${Config.PROJECTS_API_URL}${projectId}/data-rooms/${dataRoomId}/resend`,
      data
    )
  },

  updateDownloadable: async (
    projectId,
    dataRoomId,
    documentId,
    category,
    downloadable
  ) => {
    return axios.patch(
      `${Config.PROJECTS_API_URL}${projectId}/data-rooms/${dataRoomId}/documents/${documentId}?category=${category}`,
      { downloadable }
    )
  },

  createSection: async (projectId, dataRoomId, data) => {
    return axios.post(
      `${Config.PROJECTS_API_URL}${projectId}/data-rooms/${dataRoomId}/sections`,
      data
    )
  },

  deleteSection: async (projectId, dataRoomId, sectionId) => {
    return axios.delete(
      `${Config.PROJECTS_API_URL}${projectId}/data-rooms/${dataRoomId}/sections/${sectionId}`
    )
  },

  editSection: async (projectId, dataRoomId, sectionId, data) => {
    return axios.patch(
      `${Config.PROJECTS_API_URL}${projectId}/data-rooms/${dataRoomId}/sections/${sectionId}`,
      data
    )
  }
}
export default DataRoomService
