import moment from 'moment'
import Lang from 'lang'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import QuestionnairesService from '../../../../services/questionnaires-service'
import { Loader } from 'components/ui/molecules/Loader'
import { useHistory, useParams } from 'react-router-dom'
import useUser from '../../../../hooks/useUser'
import { SectionHeader } from 'components/ui/molecules/SectionHeader'
import { FormStatistics } from './FormStatistics'
import { ShareAndEmbedForm } from '../ShareAndEmbedForm'
import { FormContentSection } from '../FormContentSection'
import { FormResultsSection } from '../FormResultsSection'
import { Alert } from '../../../ui/molecules/Alert'
import { FormPreview } from '../FormPreview'
import { FormSettingsSection } from '../FormSettingsSection'
import { RoutesLinks } from '../../../routes-links'
import { PageTitle } from '../../../ui/atoms/PageTitle'
import { Button } from '../../../ui/atoms/Button'
import { FaEye, FaFileExport, FaPaperPlane } from 'react-icons/fa'
import { useSwal } from '../../../../hooks/useSwal'
import { Modal } from '../../../ui/molecules/Modal'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { FormSaveConfirmationModal } from '../FormSaveConfirmationModal'
import BoardsService from 'services/boards-service'
import SupportService from 'services/support-service'

const QuestionnairesForm = () => {
  const dateFormat = 'MMM, D'
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { formId, templateId } = useParams()
  const { user, isAdmin, haveFunctionality } = useUser()
  const { confirm, alert } = useSwal()
  const history = useHistory()
  const [isTemplate, setIsTemplate] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingProcessQuestionnaire, setLoadingProcessQuestionnaire] = useState(false)
  const [questionnaire, setQuestionnaire] = useState(null)
  const [answersEvolutions, setAnswersEvolutions] = useState(null)
  const [section, setSection] = useState('overview')
  const [sections, setSections] = useState([])
  const [formStatistics, setFormStatistics] = useState({})
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [showSaveConfirmationModal, setShowSaveConfirmationModal] = useState(false)
  const [selectedQuestions, setSelectedQuestions] = useState([])
  const [keyQuestions, setKeyQuestions] = useState({})
  const [requiredQuestions, setRequiredQuestions] = useState({})
  const [boards, setBoards] = useState([])
  const [assignStartupsToPipelineManager, setAssignStartupsToPipelineManager] = useState(false)
  const [pipelineManagerBoard, setPipelineManagerBoard] = useState(null)
  const [pipelineManagerStage, setPipelineManagerStage] = useState(null)
  const [name, setName] = useState('')
  const [formBanner, setFormBanner] = useState(null)
  const [formDescription, setFormDescription] = useState('')
  const [formFavicon, setFormFavicon] = useState(null)
  const [formTitle, setFormTitle] = useState('')
  const [formNotificationEmail, setFormNotificationEmail] = useState('')
  const [formEndedDescription, setFormEndedDescription] = useState('')
  const [languages, setLanguages] = useState([])
  const [sendNotificationEmail, setSendNotificationEmail] = useState(false)
  const [customEndedMessage, setCustomEndedMessage] = useState(false)
  const [formBackgroundColor, setFormBackgroundColor] = useState('#fff')
  const [formFontColor, setFormFontColor] = useState('')
  const [formButtonFontColor, setFormButtonFontColor] = useState('')
  const [formButtonColor, setFormButtonColor] = useState('')
  const [visibilityMode, setVisibilityMode] = useState('multiple_page')
  const [showInvestorsPercentage, setShowInvestorsPercentage] = useState(false)
  const [activeTemplate, setActiveTemplate] = useState(false)
  const [category, setCategory] = useState('')
  const [estimatedDuration, setEstimatedDuration] = useState(0)
  const [templateLogo, setTemplateLogo] = useState(null)
  const [investorName, setInvestorName] = useState('')
  const [questions, setQuestions] = useState([])
  const [filteredQuestions, setFilteredQuestions] = useState({})

  const getQuestionnairesAnswerEvolution = () => {
    return QuestionnairesService.getQuestionnaireAnswers(questionnaire._id, {
      page: 0,
      rowsPerPage: 999999,
      dateFrom: moment().subtract(14, 'days').toDate().toISOString()
    }).then((result) => {
      const { applications } = result.data
      if (applications.length) {
        console.log(applications)
        const grouped = applications.reduce((r, a) => {
          const date = moment(a.createdAt).format(dateFormat, Lang.getCurrentLang().code)
          r[date] = r[date] || []
          r[date].push(a)
          return r
        }, Object.create(null))

        const answers = Array(15).fill(null).map((v, i) => {
          const date = moment()
            .subtract(14 - i, 'days')
            .format(dateFormat, Lang.getCurrentLang().code)

          return {
            x: date,
            y: (grouped[date] || []).length
          }
        })

        setAnswersEvolutions([{
          id: 'answers',
          data: answers
        }])
      }
    }).catch(() => {
      addToast(t('error_occurred_retrieving_latest_startups'), {
        appearance: 'error',
        autoDismiss: true
      })
    })
  }

  const getQuestionnaireStats = () => {
    setLoading(true)
    QuestionnairesService.getQuestionnaireStats(questionnaire._id)
      .then((result) => {
        setFormStatistics(result.data)
      })
      .catch(() => {
        addToast(t('error_getting_form_stats'), {
          appearance: 'error',
          autoDismiss: true
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getForm = async () => {
    setLoading(true)
    return QuestionnairesService.getQuestionnairesForm(formId)
      .then((response) => {
        setQuestionnaire(response.data.data)
      })
      .catch(() => {
        addToast(t('error_retrieving_form'), {
          appearance: 'error',
          autoDismiss: true
        })
        history.push(RoutesLinks.QE_FORMS_LINK)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getTemplate = () => {
    setLoading(true)
    QuestionnairesService.getQuestionnairesTemplate(templateId)
      .then((response) => {
        setQuestionnaire(response.data.data)
      })
      .catch(() => {
        addToast(t('error_retrieving_template'), {
          appearance: 'error',
          autoDismiss: true
        })
        history.push(RoutesLinks.QE_TEMPLATES_LINK)
      })
      .finally(() => {
        setLoading(false)
        setIsTemplate(true)
      })
  }

  const parseQuestions = (selectedQuestions) => {
    const parsedQuestions = []
    selectedQuestions.forEach((q, index) => {
      const parsedQuestion = {}
      parsedQuestion.question_id = q._id
      parsedQuestion.required = !!requiredQuestions[q._id]
      if (!index) {
        parsedQuestion.first_question = true
      }
      if (selectedQuestions.length > index + 1) {
        parsedQuestion.next_question = { question_id: selectedQuestions[index + 1]._id }
      }
      parsedQuestions.push(parsedQuestion)
    })

    return parsedQuestions
  }

  const saveForm = async (publish) => {
    if (!selectedQuestions.length) {
      alert({ text: t('questions_required') })
      return
    } else if (assignStartupsToPipelineManager && (!pipelineManagerBoard || !pipelineManagerStage)) {
      alert({ text: t('assign_startups_automation_incomplete') })
      return
    } else if (sendNotificationEmail && !formNotificationEmail) {
      alert({ text: t('application_notification_email_required') })
      return
    } else if (customEndedMessage && !formEndedDescription) {
      alert({ text: t('custom_application_ended_message_required') })
      return
    } else if (!languages.length) {
      alert({ text: t('form_should_contains_at_least_one_language') })
      return
    }

    let data = {
      key_questions: { ...keyQuestions },
      questions: parseQuestions(selectedQuestions),
      assign_startups_to_pipeline_manager: assignStartupsToPipelineManager,
      pipeline_manager_board: pipelineManagerBoard,
      pipeline_manager_stage: pipelineManagerStage,
      name,
      title: formTitle,
      description: formDescription,
      banner: formBanner,
      favicon: formFavicon,
      notification_email: formNotificationEmail,
      send_notification_email: !!sendNotificationEmail,
      custom_ended_message: !!customEndedMessage,
      form_ended_description: formEndedDescription,
      background_color: formBackgroundColor,
      font_color: formFontColor,
      button_font_color: formButtonFontColor,
      button_color: formButtonColor,
      visibility_mode: visibilityMode,
      show_investors_percentage: showInvestorsPercentage,
      languages
    }

    if (!publish) {
      data = {
        draft: {
          ...questionnaire.draft,
          ...data
        }
      }
    } else {
      data = {
        published: true,
        publish_date: new Date(),
        draft: null,
        ...data
      }
    }

    if (isTemplate) {
      data.category = category
      data.active = activeTemplate
      data.estimated_duration = estimatedDuration
      data.logo = templateLogo
      data.investor_name = investorName
    }

    try {
      setLoading(true)

      if (isTemplate) {
        await QuestionnairesService.updateQuestionnairesTemplate(questionnaire._id, data).then(() => {
          addToast(t('template_updated_successfully'), {
            appearance: 'success',
            autoDismiss: true
          })
        })
      } else {
        await QuestionnairesService.updateQuestionnairesForm(questionnaire._id, data).then(() => {
          addToast(t('form_updated_successfully'), {
            appearance: 'success',
            autoDismiss: true
          })
        })
      }
    } catch (error) {
      addToast(
        t(
          isTemplate
            ? 'error_occurred_updating_template'
            : 'error_occurred_updating_form'
        ),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    } finally {
      setLoading(false)
      setShowSaveConfirmationModal(false)
      publish && !isTemplate && !questionnaire?.publish_date && setSection('overview')
      getForm()
    }
  }

  useEffect(() => {
    if (templateId) {
      getTemplate()
    }
  }, [templateId])

  useEffect(() => {
    if (formId) {
      getForm()
    }
  }, [formId])

  useEffect(() => {
    setSections(['overview', 'content', 'results', 'settings'])
    setSection(history.location.state?.section ? history.location.state?.section : 'overview')

    if (isAdmin) {
      setIsTemplate(true)
    }
  }, [user])

  const getBoards = async () => {
    try {
      setLoading(true)
      const result = await BoardsService.getBoards()
      setBoards(result.data)
    } catch (error) {
      console.log(error)
      addToast(
        error.response && error.response.data.msg_key
          ? error.response.data.msg_key
          : t('error_occurred_retrieving_boards'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    } finally {
      setLoading(false)
    }
  }

  const processQuestions = (questions, searchTerm) => {
    const groupedQuestions = {}
    const addToTag = (question, tag) => {
      if (!groupedQuestions[tag]) {
        groupedQuestions[tag] = [question]
      } else {
        groupedQuestions[tag].push(question)
      }
    }
    const matchSearchTerm = (question) => {
      return (
        `${question.title?.es}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
        `${question.title?.en}`.toLowerCase().includes(searchTerm.toLowerCase())
      )
    }
    const _questions = searchTerm ? questions.filter((q) => matchSearchTerm(q)) : questions

    _questions.forEach((question) => {
      if (question.tag) {
        addToTag(question, question.tag)
      } else {
        addToTag(question, 'other_questions')
      }
    })

    return groupedQuestions
  }

  const getQuestionnaireQuestions = () => {
    setLoading(true)

    QuestionnairesService.getQuestionnairesQuestions()
      .then((response) => {
        setQuestions(response.data.data)
        setFilteredQuestions(processQuestions(response.data.data))
      })
      .catch(() => {
        history.push(RoutesLinks.QE_FORMS_LINK)
        addToast(t('error_getting_questions'), {
          appearance: 'error',
          autoDismiss: true
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (questionnaire) {
      getQuestionnaireQuestions()
      getQuestionnaireStats()
      getQuestionnairesAnswerEvolution()
      getBoards()
    }
  }, [user, questionnaire])

  useEffect(() => {
    if (questionnaire) {
      const _model = questionnaire.draft ? { ...questionnaire, ...questionnaire.draft } : questionnaire

      setName(_model.name)
      setFormBanner(_model.banner)
      setFormDescription(_model.description)
      setAssignStartupsToPipelineManager(_model.assign_startups_to_pipeline_manager)
      setPipelineManagerBoard(_model.pipeline_manager_board)
      setPipelineManagerStage(_model.pipeline_manager_stage)
      setFormFavicon(_model.favicon)
      setFormTitle(_model.title)
      setFormNotificationEmail(_model.notification_email ? questionnaire.notification_email : '')
      setFormEndedDescription(_model.form_ended_description || '')
      setLanguages(_model.languages && _model.languages.length ? _model.languages : ['en', 'es'])
      setSendNotificationEmail(_model.send_notification_email)
      setCustomEndedMessage(_model.custom_ended_message)
      setFormBackgroundColor(_model.background_color || '#FFFFFF')
      setFormFontColor(_model.font_color || '#000000')
      setFormButtonFontColor(_model.button_font_color || '#FFFFFF')
      setFormButtonColor(_model.button_color || '#4D70B3')
      setVisibilityMode(_model.visibility_mode || 'multiple_page')
      setShowInvestorsPercentage(_model.show_investors_percentage)

      setKeyQuestions((questionnaire.draft ? questionnaire.draft.key_questions : questionnaire.key_questions) || {})

      if (isTemplate) {
        setCategory(_model.category)
        setActiveTemplate(_model.active)
        setEstimatedDuration(_model.estimated_duration)
        setTemplateLogo(_model.logo)
      }
    }
  }, [questionnaire])


  useEffect(() => {
    setLoadingProcessQuestionnaire(true)
    if (questionnaire) {
      const tmpSelectedQuestions = []
      const tmpRequiredQuestions = {}
      const _questions = questionnaire.draft ? questionnaire.draft.questions : questionnaire.questions

      _questions?.forEach((q) => {
        tmpSelectedQuestions.push({
          _id: q.question_id._id,
          title: q.question_id.title,
          type: q.question_id.type,
          file_accepted_extensions: q.question_id.file_accepted_extensions
        })
        tmpRequiredQuestions[q.question_id._id] = q.required
      })

      setSelectedQuestions(tmpSelectedQuestions)
      setRequiredQuestions(tmpRequiredQuestions)
    }
    setLoadingProcessQuestionnaire(false)

  }, [questionnaire])

  const exportResponses = () => {
    return confirm({
      title: t('export_responses'),
      text: t('export_responses_explanation')
    }).then((isConfirmed) => {
      if (isConfirmed) {
        setLoading(true)
        return SupportService.exportResponses(formId)
          .then((result) => {
            if (result.data.data && result.data.data !== '') {
              window.open(result.data.data, '_blank')
            }
          })
          .catch((error) => {
            if (error.response) {
              addToast(t('error_occurred_exporting_responses'), {
                appearance: 'error',
                autoDismiss: true
              })
            }
          })
          .finally(() => {
            setLoading(false)
          })
      }
    })
  }

  return (
    <>
      <div className='flex items-start'>
        <div className='flex-1'>
          <PageTitle
            showBack={true}
            title={t(
              isTemplate
                ? templateId ? 'create_template' : 'new_template'
                : formId ? 'form_details' : 'new_form'
            )}
          />
        </div>

        {section === 'results' && formStatistics && formStatistics.responses !== 0 && <ButtonSecondary
          marginRight={2}
          iconComponent={<FaFileExport className='inline-block mr-2' />}
          text={t('export_responses')}
          onClick={exportResponses}
          />}
        {questionnaire && (questionnaire.published || questionnaire.draft) && (
          <ButtonSecondary
            iconComponent={<FaEye className='inline-block mr-2' />}
            marginRight='2'
            text={t('preview')}
            onClick={() => setShowPreviewModal(true)}
          />
        )}

        <ButtonMain
          iconComponent={<FaPaperPlane className='inline-block mr-2' />}
          text={t('save')}
          onClick={() => setShowSaveConfirmationModal(true)}
        />
      </div>

      <FormSaveConfirmationModal
        show={showSaveConfirmationModal}
        onClose={() => setShowSaveConfirmationModal(false)}
        onSave={saveForm}
      />

      <SectionHeader
        section={section}
        setSection={setSection}
        sectionKeys={sections}
        sectionTitles={sections.map((s) => t(s))}
      />

      {questionnaire && !!questionnaire.draft && !['content', 'results', 'settings'].includes(section) && (
        <Alert style='warning' text={t('form_draft_warning_2')} />
      )}

      {section === 'overview' && (
        <>
          <FormStatistics
            statistics={formStatistics}
            answersEvolutions={answersEvolutions}
            showAnswerEvolution={true}
          />

          {questionnaire?.published && (
            <ShareAndEmbedForm questionnaire={questionnaire} />
          )}
        {/*<FormPreview
          questionnaire={questionnaire}
          draft={!!questionnaire?.draft}
        />
          </>*/}
        </>
      )}

      {section === 'content' && (
        <FormContentSection
          processQuestions={processQuestions}
          filteredQuestions={filteredQuestions}
          setFilteredQuestions={setFilteredQuestions}
          questions={questions}
          questionnaire={questionnaire}
          isTemplate={isTemplate}
          selectedQuestions={selectedQuestions}
          setSelectedQuestions={setSelectedQuestions}
          keyQuestions={keyQuestions}
          setKeyQuestions={setKeyQuestions}
          requiredQuestions={requiredQuestions}
          setRequiredQuestions={setRequiredQuestions}
        />
      )}

      {section === 'results' && (
        <FormResultsSection
          questionnaire={questionnaire}
          answersEvolutions={answersEvolutions}
          formStatistics={formStatistics}
        />
      )}

      {section === 'share_and_integrate' && (
        <ShareAndEmbedForm questionnaire={questionnaire} showTitle={false} />
      )}

      {section === 'settings' && (
        <FormSettingsSection
          getForm={getForm}
          questionnaire={questionnaire}
          isTemplate={isTemplate}
          boards={boards}
          assignStartupsToPipelineManager={assignStartupsToPipelineManager}
          setAssignStartupsToPipelineManager={setAssignStartupsToPipelineManager}
          pipelineManagerBoard={pipelineManagerBoard}
          setPipelineManagerBoard={setPipelineManagerBoard}
          pipelineManagerStage={pipelineManagerStage}
          setPipelineManagerStage={setPipelineManagerStage}
          name={name}
          setName={setName}
          formBanner={formBanner}
          setFormBanner={setFormBanner}
          formDescription={formDescription}
          setFormDescription={setFormDescription}
          formFavicon={formFavicon}
          setFormFavicon={setFormFavicon}
          formTitle={formTitle}
          setFormTitle={setFormTitle}
          formNotificationEmail={formNotificationEmail}
          setFormNotificationEmail={setFormNotificationEmail}
          formEndedDescription={formEndedDescription}
          setFormEndedDescription={setFormEndedDescription}
          languages={languages}
          setLanguages={setLanguages}
          sendNotificationEmail={sendNotificationEmail}
          setSendNotificationEmail={setSendNotificationEmail}
          customEndedMessage={customEndedMessage}
          setCustomEndedMessage={setCustomEndedMessage}
          formBackgroundColor={formBackgroundColor}
          setFormBackgroundColor={setFormBackgroundColor}
          formFontColor={formFontColor}
          setFormFontColor={setFormFontColor}
          formButtonFontColor={formButtonFontColor}
          setFormButtonFontColor={setFormButtonFontColor}
          formButtonColor={formButtonColor}
          setFormButtonColor={setFormButtonColor}
          visibilityMode={visibilityMode}
          setVisibilityMode={setVisibilityMode}
          showInvestorsPercentage={showInvestorsPercentage}
          setShowInvestorsPercentage={setShowInvestorsPercentage}
          activeTemplate={activeTemplate}
          setActiveTemplate={setActiveTemplate}
          category={category}
          setCategory={setCategory}
          estimatedDuration={estimatedDuration}
          setEstimatedDuration={setEstimatedDuration}
          templateLogo={templateLogo}
          setTemplateLogo={setTemplateLogo}
          investorName={investorName}
          setInvestorName={setInvestorName}
        />
      )}

      {showPreviewModal && (
        <Modal
          showModal={showPreviewModal}
          onClose={() => setShowPreviewModal(false)}
          showCloseModal={true}
          closeOnClickOutside={true}
        >
          <div className='w-70width'>
            <FormPreview draft={!!questionnaire?.draft} questionnaire={questionnaire} />
          </div>
        </Modal>
      )}

      {(loading || loadingProcessQuestionnaire)&& <Loader />}
    </>
  )
}

export default QuestionnairesForm
