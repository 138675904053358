import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PageTitle } from 'components/ui/atoms/PageTitle'
import { SectionHeader } from 'components/ui/molecules/SectionHeader'
import { Team } from './Team'
import { GeneralInformation } from './GeneralInformation'
import { CompanySettings } from './CompanySettings'
import useUser from 'hooks/useUser'
import { InvestorInvestmentThesis } from '../investment-thesis/InvestorInvestmentThesis'
import PreferencesService from 'services/preferences-service'
import { useToasts } from 'react-toast-notifications'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { FaPlus } from 'react-icons/fa'
import { Card } from 'components/ui/atoms/Card'

const Company = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const { user, haveFunctionality, reloadUserInfo, plan, isProfessional } = useUser()
  const thesis = user?.investor?.investment_thesis
  const teamRef = useRef()

  const [section, setSection] = useState('general_information')
  const SECTIONS = haveFunctionality('company_settings')
    ? ['general_information', 'team', 'investment_thesis', 'settings']
    : isProfessional ? ['general_information'] : plan !== 'kiota_bots' ? ['general_information', 'team', 'investment_thesis'] : ['general_information', 'team']

  const onSubmitInvestmentThesis = (values) => {
    return PreferencesService.updateInvestorInvestmentThesis({...values, lead_investor: values.lead_investor ? values.lead_investor[0] : null, follow_on: values.follow_on ? values.follow_on[0] : null })
      .then(() => reloadUserInfo())
      .then(() => {
        addToast(t('investment_thesis_updated_successfully'), {
          appearance: 'success',
          autoDismiss: true
        })
      }).catch(error => {
        addToast(
          error.response && error.response.data.msg_key
            ? t(error.response.data.msg_key)
            : t('error_occurred_updating_investment_thesis'),
          {
            appearance: 'error',
            autoDismiss: true
          }
        )
      })
  }

  const COMPONENTS = {
    general_information: <GeneralInformation />,
    team: <Team ref={teamRef}/>,
    settings: <CompanySettings />,
    investment_thesis: <InvestorInvestmentThesis onSubmit={onSubmitInvestmentThesis} thesis={thesis}/>
  }

  return (
    <>
      <div className="w-full flex flex-row justify-between">
        <div className='flex'>
          <div className='flex-1'>
            <PageTitle title={t('company')} />
          </div>
        </div>
        {section === 'team' && <div>
          <ButtonMain
              onClick={() => teamRef?.current?.showTeammateSection()}
              text={t('add_teammate')}
            iconComponent={<FaPlus className="inline-block mr-3" />}
          />
        </div>}
      </div>
      <SectionHeader
        section={section}
        setSection={setSection}
        sectionKeys={SECTIONS}
        sectionTitles={SECTIONS.map((s) => t(s))}
      />

      <div className='mt-6'>
        {COMPONENTS[section]}
      </div>
    </>
  )
}

export default Company
