import { useTranslation } from 'react-i18next'
import { SidePanel } from 'components/ui/objects/SidePanel'
import { useEffect } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import Config from 'config'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import Select from 'components/ui/atoms/Select'
import DeletionReasons from 'assets/json/deletion-reasons.json'
import { TextArea } from 'components/ui/atoms/TextArea'
import { ButtonDanger } from 'components/ui/atoms/ButtonDanger'

/**
 * @param onClose
 * @returns {JSX.Element}
 * @constructor
 */
const DeleteDealSection = ({ onClose, deleteDeal }) => {
  const { t } = useTranslation()

  const deletionReasons = DeletionReasons.map((deletionReason) => {
    return { id: deletionReason.id, value: t(deletionReason.key) }
  })

  const { register, handleSubmit, errors, setValue, trigger, watch } = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    reValidateMode: 'all',
    resolver: yupResolver(Yup.object().shape({
        deletion_reason: Yup.string().required(t('required_field')),
        deletion_comments: Yup.string().nullable()
      }), { abortEarly: false }),
  })

  useEffect(() => {
    register('deletion_reason')
  }, [register])

  useEffect(() => {
    setValue('deletion_reason', deletionReasons[0].id)
    trigger()
  }, [])

  return (
    <>
      <SidePanel
        width={'1/4'}
        onClose={onClose}
        title={t('delete_startup')}
      >
        <p className={`font-medium mb-6 text-sm text-main-${Config.PLATFORM} border-b border-main-${Config.PLATFORM}`}>
          {t('delete_dealflow_reason_title')}
        </p>
        <div className="mt-4 flex flex-col w-full">
          <form className="w-full flex flex-col" onSubmit={handleSubmit(deleteDeal)}>
            <div className="w-full mr-4">
            <Select
              isClearable={false}
              items={deletionReasons}
              initialValues={
                watch('deletion_reason')
                  ? deletionReasons
                    .filter(item => item.id === watch('deletion_reason'))
                  : []
              }
              onSelect={selected => {
                setValue('deletion_reason', selected.length ? selected[0].id : null)
                trigger()
              }}
              id="deletion_reason"
              error={errors.deletion_reason}
              name="deletion_reason"
              reference={register}
            />
            </div>
            <div className="mt-2 w-full">
                <TextArea
                reference={register}
                name="deletion_comments"
                error={errors.deletion_comments}
                label={t('comments')}
                maxLength={200}
                placeholder={t('comments')}
                />
            </div>
            <div className={`flex justify-end mt-2 pt-2 border-t border-separator-${Config.PLATFORM}`}>
              <ButtonDanger
                type={'submit'}
                text={t('delete_startup')}
              />
            </div>
          </form>
        </div>
      </SidePanel>
    </>
  )
}

export default DeleteDealSection
