import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { Loader } from 'components/ui/molecules/Loader'
import { SectionHeader } from 'components/ui/molecules/SectionHeader'
import { useHistory } from 'react-router-dom'
import { RoutesLinks } from 'components/routes-links'
import SupportService from 'services/support-service'
import { DocumentationStatistics } from 'components/ui/atoms/DocumentationStatistics'
import { DocumentList } from './components/DocumentList'
import Config from 'config';
import { PageTitle } from 'components/ui/atoms/PageTitle'
import { Button } from 'components/ui/atoms/Button'
import { FaFilter, FaPlus, FaPlusCircle } from 'react-icons/fa'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'

const Documents = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const documentListRef = useRef()
  const SECTIONS = ['documents', 'tags']
  const [statistics, setStatistics] = useState({
    total: 0,
    documents: [
      { extension: 'total', documents: 0, size: 0 },
      { extension: 'pdf', documents: 0, size: 0 },
      { extension: 'excel', documents: 0, size: 0 },
      { extension: 'multimedia', documents: 0, size: 0 },
      { extension: 'img', documents: 0, size: 0 },
      { extension: 'others', documents: 0, size: 0 }
    ]
  })

  const getStats = () => {
    return SupportService.getDocumentsStats().then((response) => {
      setStatistics(response.data)
    }).catch((error) => {
      addToast(error?.response?.data?.msg_key || t('error_occurred_retrieving_document_stats'), {
        appearance: 'error',
        autoDismiss: true
      })
    })
  }

  useEffect(() => {
    setLoading(true)
    getStats().finally(() => {
      setLoading(false)
    })
  }, [])

  return (
    <>

      <div className='flex items-start'>
        <div className='flex-1'>
          <PageTitle
            title={t('documentation')}
          />
        </div>

        <ButtonMain
          iconComponent={<FaPlus className='inline-block mr-2' />}
          marginRight={'2'}
          text={t('upload_document')}
          onClick={() => documentListRef?.current?.uploadDocument()}
        />
        <div data-tip={t('filter')}>
          <ButtonSecondary
            iconComponent={<FaFilter className='h-4'/>}
            onClick={() => documentListRef?.current?.showFilters()}
            />
        </div>
      </div>


      <SectionHeader
        sectionTitles={SECTIONS.map((sec) => t(sec))}
        sectionKeys={SECTIONS}
        section={'documents'}
        setSection={(sec) => history.push(`${RoutesLinks.DOCUMENTATION_LINK}${sec.replace('_', '-')}`)}
        showAdd={false}
        showFilters={false}
        setShowFilters={null}
      />

      <DocumentationStatistics
        used={statistics?.used || 0}
        available={statistics?.available || 0}
        documents={statistics?.documents || []}
      />

      <DocumentList ref={documentListRef} />

      {loading && <Loader />}
    </>
  )
}

export default Documents
