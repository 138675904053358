import 'shepherd.js/dist/css/shepherd.css'
import 'tailwindcss/tailwind.css'
import 'index.css'
import 'App.css'

import { useRef, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Routes from './components/routes'
import { useSelector } from 'react-redux'
import Scrollbar from 'react-scrollbars-custom'
import { ToastProvider } from 'react-toast-notifications'
import { ToastContainer } from 'components/ui/objects/ToastContainer'
import Loader from './components/loader'
import LoadingOverlay from 'react-loading-overlay'
import Menu from './components/layout/menu'
import Header from './components/layout/header'
import PropTypes from 'prop-types'
import { BasicToast } from 'components/ui/atoms/BasicToast'
import { RoutesLinks } from 'components/routes-links'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { pdfjs } from 'react-pdf'
import EventUtils from 'utils/event-utils'
import { ChatService } from 'services/chat-service'
import { Chat } from 'components/chat/Chat'
import { ChatMessageTemplatesContainer } from 'components/ui/objects/ChatMessageTemplatesContainer'
import { DemoAccountBanner } from 'components/ui/atoms/DemoAccountBanner'
import { useTour } from 'hooks/useTour'
import ReactTooltip from 'react-tooltip'
import useUser from './hooks/useUser'
import { OnboardingTasksPannel } from 'components/ui/molecules/OnboardingTasksPannel'
import Config from 'config'
import Parameters from 'config/parameters.json';

import { mountScript } from 'components/account/components/emails/scripts/mountGoogleScript';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

function App () {
  const scrollBar = useRef(null)
  const location = useLocation()
  const { user, userLanguage, isFounder, isVentureCapitals, isDemoAccount } = useUser()
  const appLoading = useSelector((state) => state.app.loading)
  const appLoadingOverlay = useSelector((state) => state.app.loadingOverlay)
  const messageTemplatesContainer = useSelector((state) => state.app.messageTemplatesContainer)
  const checked = useSelector((state) => state.checks && state.checks.checked)
  const tour = useTour('quick_tour')
  const [showSetup, setShowSetup] = useState(false)

  const isFullPage = () => {
    const fullPages = [RoutesLinks.SHARED_LINK, RoutesLinks.PUBLIC_EVENTS_LINK, RoutesLinks.PUBLIC_FORMS_LINK, RoutesLinks.PUBLIC_PROFILE_LINK, RoutesLinks.KUFF_LINK]

    for (const page of fullPages) {
      if (location.pathname.includes(page)) {
        return true
      }
    }

    return false
  }

  useEffect(() => {
    const favicon = document.querySelector('head link[rel="icon"]')
    favicon.setAttribute('href', Parameters.favicon[Config.PLATFORM])
    if (Config.PLATFORM === '001') {
      document.title = 'TheStartupCommunity | Powered by Kiota'
    }

    sessionStorage.setItem('platform_id', Math.random().toString(36).substring(2, 9))
  }, [])

  useEffect(() => {
    try {
      EventUtils.sendAnalyticEvent({
        event: 'changeRoute',
        user: user ? user._id : null,
        payload: {
          url: location.pathname
        }
      })
    } catch (error) {
      console.log(error)
    }

    if (scrollBar && scrollBar.current) {
      scrollBar.current.scrollToTop()
    }

    ReactTooltip.hide()
  }, [location.pathname])

  useEffect(() => {
    // chat initialization
    if (isFounder || isVentureCapitals) {
      ChatService.initialize()
        .then(() => ChatService.loginUser(user))
        .then(() => ChatService.initializeWidget())
        .then(() => setTimeout(() => {
          ChatService.setChatLocalization(userLanguage)
        }, 5000))
    }

    // tour launch
    if (user && user.tours && user._id && !user.tours.quick_tour) {
      tour.start()
    }
  }, [user?._id])
  

   useEffect(() => {
     mountScript();
   }, [])
  
  if (appLoading) {
    return <Loader />
  }

  const stripePromise = loadStripe(
    'pk_test_51J2FcAAHOHnJYdUdtK6uITsnK9CqZziVUwiFzlthnS3oJiaYfLZjb2hhjClDP9NhfcNn8bkdTxmSS2L6veGCVUsQ00lbkauM2a'
  )

  return (
    <ToastProvider components={{ Toast: BasicToast, ToastContainer }}>
      <Elements stripe={stripePromise}>
        <Scrollbar
          ref={scrollBar}
          trackYProps={{ className: 'scrollbar_trackY' }}
          thumbYProps={{ className: 'scrollbar_thumbY' }}
          disableTracksWidthCompensation={true}
        >
          {checked && (
            <div>
              {!isFullPage() && <Header />}

              {!isFullPage() && <Menu openSetup={() => setShowSetup(true)} />}

              {showSetup && <OnboardingTasksPannel onClose={() => setShowSetup(false)}/>}

              <main id="app-content-wrapper" className={`${isFullPage() ? 'pl-0' : 'pl-16'}  lg:pl-0`}>
                {!isFullPage() && isDemoAccount && <DemoAccountBanner />}

                <section className={`w-full h-full ${!isFullPage() ? 'pt-4 sm:pl-8 lg:pl-60 pr-0 sm:pr-10 pb-8' : ''}`}>
                  <Routes />
                </section>

                <Chat />

                {messageTemplatesContainer.show ? <ChatMessageTemplatesContainer /> : null}
              </main>

              {appLoadingOverlay && appLoadingOverlay.state && (
                <div
                  style={{
                    position: 'fixed',
                    zIndex: 1500,
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%',
                    overflow: 'auto',
                    backgroundColor: 'rgba(0, 0, 0, 0.4)'
                  }}
                >
                  <LoadingOverlay active={true} spinner={true} text={appLoadingOverlay.message} />
                </div>
              )}
            </div>
          )}

          {!checked && <Routes />}

          <ReactTooltip
            effect="solid"
            backgroundColor="white"
            className={`shadow-soft-white text-main-${Config.PLATFORM} opacity-100 font-bold`}
          />
        </Scrollbar>
      </Elements>
    </ToastProvider>
  )
}

App.propTypes = {
  window: PropTypes.func
}

export default App
