import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Input } from '../atoms/Input'
import { TextArea } from '../atoms/TextArea'
import { Checkbox } from './Checkbox'
import { Button } from '../atoms/Button'
import { ButtonSecondary } from '../atoms/ButtonSecondary'
import { ButtonDanger } from '../atoms/ButtonDanger'

export function EditCardMessageTemplate ({
  template = {},
  onSave = () => null,
  onDelete = () => null,
  onCancel = () => null,
  mode = 'edit'
}) {
  const { t } = useTranslation()
  const [editing, setEditing] = useState(mode !== 'edit')

  const messageTemplateSchema = Yup.object().shape({
    title: Yup.string().required(t('required_field')),
    message: Yup.string().required(t('required_field')),
    is_startup: Yup.bool(),
    is_entrepreneur: Yup.bool()
  })

  const {
    register,
    errors,
    setValue,
    getValues,
    setError,
    clearErrors,
    handleSubmit
  } = useForm({
    resolver: yupResolver(messageTemplateSchema, { abortEarly: false }),
    criteriaMode: 'all',
    reValidateMode: 'all',
    mode: 'all'
  })

  const save = () => {
    handleSubmit(() => {
      onSave(getValues())
      setEditing(false)
    })()
  }

  const cancel = () => {
    clearErrors()
    onCancel()
    setEditing(false)
  }

  const checkCategory = () => {
    const values = getValues()

    if (!values.is_startup && !values.is_entrepreneur) {
      setError('category', { type: 'required', message: t('required_field') })
    } else {
      clearErrors('category')
    }
  }

  useEffect(() => {
    setValue('title', template?.title)
    setValue('message', template?.message)
    setValue('is_startup', template?.is_startup)
    setValue('is_entrepreneur', template?.is_entrepreneur)
  }, [template, editing])

  return (
    <div className="flex flex-col justify-between items-between | shadow-soft-white rounded-2xl | p-4 w-full mx-4 mb-4">
      {!editing && (
        <div className="text-left leading-none flex flex-col justify-start items-start h-full px-1">
          <p className="text-xxs h-auto break-all font-semibold mb-2">
            {template.title}
          </p>
          <p className="text-xxxs">{template.message}</p>
        </div>
      )}

      {editing && (
        <form onSubmit={(e) => e.preventDefault()}>
          <Input
            reference={register}
            id="title"
            type="text"
            name="title"
            label={t('title') + ': *'}
            error={errors.title}
          />

          <TextArea
            reference={register}
            id="message"
            name="message"
            label={t('message') + ': *'}
            error={errors.message}
            row={8}
            cols={8}
          />
          <label
            className="text-left text-xxs italic text-gray block"
            style={{ marginTop: errors.message ? 0 : -15 }}
          >
            {t('message_template_variables_tip', { name: '{{name}}' })}
          </label>

          <label className={'block mb-1 mt-3 | text-left text-xs font-medium'}>
            {`${t('message_template_category_label')}*`}
          </label>
          <label className="text-left text-xxs italic text-gray block">
            {t('message_template_category_help')}
          </label>
          <Checkbox
            reference={register}
            id="is_startup"
            name="is_startup"
            label={t('startup')}
            onChange={checkCategory}
          />
          <Checkbox
            reference={register}
            id="is_entrepreneur"
            name="is_entrepreneur"
            label={t('entrepreneur')}
            onChange={checkCategory}
          />
          {errors?.category && (
            <div className="text-red relative text-xs">
              {errors.category.message}
            </div>
          )}
        </form>
      )}

      {!editing && (
        <div className="grid grid-cols-2 gap-2">
          <div>
            <ButtonSecondary
              onClick={() => setEditing(true)}
              text={t('edit')}
            />
          </div>
          <div>
            <ButtonDanger
              onClick={() => onDelete(template._id)}
              text={t('delete')}
            />
          </div>
        </div>
      )}

      {editing && (
        <div className="grid grid-cols-2 gap-2">
          <div>
            <ButtonSecondary
              onClick={() => save()}
              text={t('save')}
            />
          </div>
          <div>
            <ButtonSecondary
              onClick={() => cancel()}
              text={t('cancel')}
            />
          </div>
        </div>
      )}
    </div>
  )
}
