import React, { useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import ProjectsService from 'services/projects-service'
import { Loader } from 'components/ui/molecules/Loader'
import useUser from '../../hooks/useUser'
import { PageTitle } from '../ui/atoms/PageTitle'
import FormUtils from '../../utils/form-utils'
import ProjectCreateOrUpdate from './components/ProjectCreateOrUpdate'
import CreateStartupForm from './components/CreateStartupForm'

const StartupCreateOrUpdate = () => {
  const { addToast } = useToasts()
  const { t } = useTranslation()
  const { user, reloadUserInfo, getUserStartup, startupId } = useUser()
  const [startup, setStartup] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const startupLoaded = startup !== undefined

  const createOrUpdate = async (values) => {
    const startupData = {
      ...values,
      creation_date: FormUtils.parseDateFromString(values.creation_date)
    }

    const showError = error => {
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t('error_occurred_creating_startup'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    }

    setLoading(true)
    if (!startup) {
      startupData.founders = [{
        user: user._id,
        email: user.email,
        name: user.name,
        last_name: user.last_name,
        score: null,
        role: null
      }]

      try {
        await ProjectsService.createProject(startupData)
        reloadUserInfo()
      } catch (error) {
        showError(error)
        addToast(t('project_created'), { appearance: 'success', autoDismiss: true })
      } finally {
        setLoading(false)
      }
    } else {
      try {
        await ProjectsService.updateProject(startupId, startupData)
        reloadUserInfo()
        addToast(t('project_updated'), { appearance: 'success', autoDismiss: true })
      } catch (error) {
        showError(error)
      } finally {
        setLoading(false)
      }
    }
  }

  const getStartup = () => {
    return getUserStartup()
      .then(startup => setStartup(startup))
  }

  useEffect(() => {
    if (startupId) {
      setLoading(true)
      getStartup().finally(() => setLoading(false))
    }
  }, [startupId])

  return (
    <>
      <PageTitle
        title={t(loading ? 'loading' : startupLoaded ? 'update_startup' : 'create_startup')}
        showBack={true}
      />

      {!startup && !loading && <CreateStartupForm onSubmit={createOrUpdate} />}
      {startup && <ProjectCreateOrUpdate
        info={startup}
        onSubmit={createOrUpdate}
        onChangeStartupMembers={() => getStartup()}
      />}

      {loading && <Loader />}
    </>
  )
}

export default StartupCreateOrUpdate
