import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { Loader } from 'components/ui/molecules/Loader'
import Unauthorized from 'components/ui/objects/Unauthorized'
import CryptoJS from 'crypto-js'
import Config from 'config'
import KuffService from 'services/kuff-service'
import { Button } from 'components/ui/atoms/Button'
import { Card } from 'components/ui/atoms/Card'
import Avatar from 'react-avatar'
import { FaCheckCircle, FaPlusCircle } from 'react-icons/fa'
import { Pagination } from 'components/ui/atoms/Pagination'

const Kuff = () => {
  const { addToast } = useToasts()
  const [kuffAccessCode, setKuffAccessCode] = useState(localStorage.getItem('X-KUFF-Token') ? CryptoJS.AES.decrypt(localStorage.getItem('X-KUFF-Token'), Config.KUFF_SECRET).toString(CryptoJS.enc.Utf8) : localStorage.getItem('X-KUFF-Token'))

  const [authorized, setAuthorized] = useState(false)

  const { t } = useTranslation()

  const [kuff, setKuff] = useState(null)

  const [loading, setLoading] = useState(true)
  const [sent, setSent] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [investors, setInvestors] = useState([{_id: 1, company_name: 'Investor 1'}])
  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalInvestors, setTotalInvestors] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)

  const getLatestKuff = async() => {
    KuffService.getLatest(kuffAccessCode)
    .then((result) => {
      setKuff(result.data.data)
    })
    .catch(error => {
      addToast(
        error?.response?.data?.msg_key ? t(error?.response?.data?.msg_key) : t('error_occurred_retrieving_information'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    const getKuff = async () => {
      try {
        setLoading(true)
        await KuffService.access(
          kuffAccessCode
        )
        setAuthorized(true)
        getLatestKuff()
      } catch (error) {
        addToast(t(error?.response?.status === 403 ? 'forbiden_access_to_kuff' : 'error_occurred_retrieving_information'), {
          appearance: 'error',
          autoDismiss: true
        })
      } finally {
        setLoading(false)
      }
    }

    if (kuffAccessCode) {
      localStorage.setItem('X-KUFF-Token', CryptoJS.AES.encrypt(kuffAccessCode, Config.KUFF_SECRET).toString())
      getKuff()
    } else {
      setLoading(false)
    }
  }, [kuffAccessCode])

  const startKuff = async () => {
    KuffService.start(kuffAccessCode)
      .then((result) => {
        setKuff(result.data.data)
      })
      .catch(error => {
        addToast(
          error?.response?.data?.msg_key ? t(error?.response?.data?.msg_key) : t('error_occurred'),
          {
            appearance: 'error',
            autoDismiss: true
          }
        )
      }).finally(() => {
        setLoading(false)
      })
  }

  const sendKuff = async () => {
    /*  KuffService.start(kuffAccessCode)
      .then((result) => {
        setKuff(result.data.data)
      })
      .catch(error => {
        addToast(
          error?.response?.data?.msg_key ? t(error?.response?.data?.msg_key) : t('error_occurred'),
          {
            appearance: 'error',
            autoDismiss: true
          }
        )
      }).finally(() => {
        setLoading(false)
      })*/
      setSent(true)
  }

  if (!authorized && !loading) return <Unauthorized loading={loading} onRequestAccess={(accessCode) => setKuffAccessCode(CryptoJS.AES.encrypt(accessCode, Config.KUFF_SECRET).toString())} description={t('access_control_description_kuff')} buttonText={t('access_kuff')} />

  if (loading) return <Loader />

  return (
    <>
      {!kuff && (
        <div className="w-full flex flex-col justify-center items-center h-full min-h-screen">
          <div className="xs:w-full md:w-2/3 pt-8 px-4 | text-center | min-h-screen justify-center mt-20">
          <div>
            <h3>
                {t('kuff_not_found_text_one')}
            </h3>
            <h4 className='my-2'>
                {t('kuff_not_found_text_two')}
            </h4>
          </div>
            <Button
              type="button"
              textColor="blue-dark"
              text={t('start')}
              loading={loading}
              spinner="true"
              disabled={!!loading}
              onClick={startKuff}
          />
          </div>
        </div>
      )}
      {kuff && !sent && !confirmed && (
        <div className="w-full flex flex-col justify-center items-center h-full min-h-screen">
          <div className="xs:w-full md:w-2/3 pt-8 px-4 | text-center | min-h-screen justify-center mt-20">
          <div>
            <h3>
                {t('kuff_completed')}
            </h3>
            <h4 className='my-2'>
                {t('kuff_completed_description')}
            </h4>
          </div>
            <Button
              type="button"
              textColor="blue-dark"
              text={t('send')}
              loading={loading}
              spinner="true"
              disabled={!!loading}
              onClick={sendKuff}
          />
          </div>
        </div>
      )}
      {sent && !confirmed && (
        <div className="w-full flex flex-col justify-center items-center h-full min-h-screen">
          <div className="xs:w-full pt-8 px-4 | text-center | min-h-screen justify-center mt-20">
          <div>
            <h3>
                {t('kuff_investors_list')}
            </h3>
            <h4 className='my-2'>
                {t('kuff_investors_list_description')}
            </h4>
          </div>
          <Button
                        onClick={() => setConfirmed(true)}
                        text={t('confirm')}
                        verticalMargin="1"
                        iconComponent={<FaCheckCircle className="inline-block w-6 h-4" />}
                        vertical="2"
                        horizontal="4"
                        marginRight="4"
                        className="text-xs w-auto"
                        />
          {investors.length !== 0 && (
            <div className='my-6'>
              <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4'>
                {investors.map((investor) => {
                  return <div key={investor._id}>
                  <Card>
                    <div className='flex'>
                    <Avatar
                      src={investor.logo}
                      size="50"
                      round={true}
                      color="#e0e6f2"
                      fgColor="#4d70b3"
                      alt={investor.company_name}
                    />
                    <div className='flex flex-col px-2'>
                      <div>
                        <span className="text-sm font-bold line-clamp-1">
                          {investor.company_name}
                        </span>
                      </div>
                      </div>
                    </div>
                    <div className='flex justify-end'>
                    <Button
                        onClick={() => setConfirmed(true)}
                        text={t('select_this')}
                        verticalMargin="1"
                        iconComponent={<FaCheckCircle className="inline-block w-6 h-4" />}
                        vertical="2"
                        horizontal="4"
                        marginRight="4"
                        className="text-xs w-auto"
                        />
                    </div>
                  </Card>
                </div>
                })}
              </div>
              <Pagination
                currentPage={currentPage}
                setCurrentPage={(page) => setCurrentPage(page)}
                perPage={pageSize}
                pages={totalPages}
                setPerPage={(value) => setPageSize(value[0].id)}
              />
            </div>
          )}
        </div>
        </div>
      )}
      {confirmed && <div className="w-full flex flex-col justify-center items-center h-full min-h-screen">
          <div className="xs:w-full pt-8 px-4 | text-center | min-h-screen justify-center mt-20">
      <div className="w-full">
      <div className="max-w-screen-sm mx-auto">
        <Card
          wrapperClassName="bg-white mb-6"
          containerClassName="text-center text-lg my-6"
        >
          <div>
            <FaCheckCircle
              className="text-green inline-block mb-6"
              size={70}
            />
          </div>
          <div>{t('application_submitted')}</div>
        </Card>
      </div>
      </div>
      </div></div>
      }
    </>
  )
}

export default Kuff
