import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { RoutesLinks } from '../routes-links'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { ChecksOperations } from '../../redux/checks'
import AuthUtils from '../../utils/auth-utils'
import OnBoarding from '../on-boarding'
import { ModalSwal } from '../ui/molecules/Modal'
import { Loader } from 'components/ui/molecules/Loader'
import Configuration from 'styles/images/configuration_account_white.svg'
import Tick from 'styles/images/tick_white.svg'
import Config from 'config'
import useUser from '../../hooks/useUser'

const CHECKS = {
  LOADING: 'LOADING',
  ACCOUNT_ACTIVATED: 'ACCOUNT_ACTIVATED',
  ON_BOARDING_FIRST_STEP: 'ON_BOARDING_FIRST_STEP',
  ON_BOARDING_SECOND_STEP: 'ON_BOARDING_SECOND_STEP',
  ON_BOARDING_THIRD_STEP: 'ON_BOARDING_THIRD_STEP',
  ON_BOARDING_FOURTH_STEP: 'ON_BOARDING_FOURTH_STEP',
  ON_BOARDING_FINISHED: 'ON_BOARDING_FINISHED',
  CHECK_ACCOUNT: 'CHECK_ACCOUNT',
  CONFIGURING_ACCOUNT: 'CONFIGURING_ACCOUNT',
  PAYMENT_REQUIRED: 'PAYMENT_REQUIRED',
  SUBSCRIPTION_EXPIRED: 'SUBSCRIPTION_EXPIRED'
}

const Checks = () => {
  const { t } = useTranslation()
  const authInfo = useSelector((state) => state.auth)
  const { user } = useUser()
  const checked = useSelector((state) => state.checks.checked)
  const [check, setCheck] = useState(CHECKS.CHECK_ACCOUNT)

  const dispatch = useDispatch()
  const stage = useSelector((state) => state.checks.stage)
  // const stage = CHECKS.ON_BOARDING_FINISHED;

  const { token } = authInfo

  /* useEffect(() => {
    const getChecks = async () => {
      if (stage === CHECKS.ON_BOARDING_FINISHED) {
        dispatch(ChecksOperations.setChecked(true));
        dispatch(ChecksOperations.setStage(null));
        setCheck(null);
      } else if ([CHECKS.ON_BOARDING_FIRST_STEP,CHECKS.ON_BOARDING_SECOND_STEP, CHECKS.ON_BOARDING_THIRD_STEP, CHECKS.ON_BOARDING_FOURTH_STEP, CHECKS.PAYMENT_REQUIRED, CHECKS.SUBSCRIPTION_EXPIRED].includes(stage) ) {
        setCheck(stage);
      } else if (stage === CHECKS.CHECK_ACCOUNT) {
        setCheck(CHECKS.LOADING);

        if (AuthUtils.isAdmin(user.role)) {
          dispatch(ChecksOperations.setChecked(true));
          dispatch(ChecksOperations.setStage(null));
          setCheck(null);
        } else if (AuthUtils.isFounder(user.role)) {
          dispatch(ChecksOperations.setStage(CHECKS.ON_BOARDING_FIRST_STEP));
          setCheck(CHECKS.ON_BOARDING_FIRST_STEP);
        } else {
          if (
            (user.investor.active_subscription &&
              (user.activated ||
                user.activated === null ||
                user.activated === undefined)) ||
            user.investor.demo
          ) {
            dispatch(ChecksOperations.setChecked(true));
            dispatch(ChecksOperations.setStage(null));
            setCheck(null);
          } else {
            setCheck(CHECKS.PAYMENT_REQUIRED);
            dispatch(ChecksOperations.setStage(CHECKS.PAYMENT_REQUIRED));
          }
        }
      } else if (stage === CHECKS.ACCOUNT_ACTIVATED) {
        setCheck(stage);
      } else if (!stage) {
        if (AuthUtils.isAdmin(user.role) || AuthUtils.isFounder(user.role)) {
          dispatch(ChecksOperations.setChecked(true));
          dispatch(ChecksOperations.setStage(null));
          setCheck(null);
        } else if (
          (user &&
            user.investor &&
            user.investor.active_subscription &&
            (user.activated ||
              user.activated === null ||
              user.activated === undefined)) ||
          (user && user.investor && user.investor.demo)
        ) {
          dispatch(ChecksOperations.setChecked(true));
          dispatch(ChecksOperations.setStage(null));
          setCheck(null);
        } else if (
          user &&
          user.investor &&
          !user.investor.active_subscription
        ) {
          if (user.investor.active_subscription !== null) {
            setCheck(CHECKS.SUBSCRIPTION_EXPIRED);
            dispatch(ChecksOperations.setStage(CHECKS.SUBSCRIPTION_EXPIRED));
          } else {
            setCheck(CHECKS.PAYMENT_REQUIRED);
            dispatch(ChecksOperations.setStage(CHECKS.PAYMENT_REQUIRED));
          }
        }
      }
    };

    getChecks();
  }, [dispatch, user, stage]); */

  useEffect(() => {
    const getChecks = async () => {
      if (stage === CHECKS.ON_BOARDING_FINISHED) {
        dispatch(ChecksOperations.setChecked(true))
        dispatch(ChecksOperations.setStage(null))
        setCheck(null)
      } else if (stage === CHECKS.ON_BOARDING_FIRST_STEP) {
        setCheck(stage)
      } else if (stage === CHECKS.ON_BOARDING_SECOND_STEP) {
        setCheck(stage)
      } else if (stage === CHECKS.ON_BOARDING_THIRD_STEP) {
        setCheck(stage)
      } else if (stage === CHECKS.ON_BOARDING_FOURTH_STEP) {
        setCheck(stage)
      } else if (stage === CHECKS.CHECK_ACCOUNT) {
        setCheck(CHECKS.LOADING)
        if (AuthUtils.isAdmin(user.role)) {
          dispatch(ChecksOperations.setChecked(true))
          dispatch(ChecksOperations.setStage(null))
          setCheck(null)
        } else if (AuthUtils.isFounder(user.role) || AuthUtils.isProfessional(user.role)) {
          dispatch(ChecksOperations.setStage(CHECKS.ON_BOARDING_FIRST_STEP))
          setCheck(CHECKS.ON_BOARDING_FIRST_STEP)
        } else {
          if (user.activated || user.activated === null || user.activated === undefined || user.investor.demo) {
            dispatch(ChecksOperations.setChecked(true))
            dispatch(ChecksOperations.setStage(null))
            setCheck(null)
          } else {
            dispatch(ChecksOperations.setStage(CHECKS.CONFIGURING_ACCOUNT))
            setCheck(CHECKS.CONFIGURING_ACCOUNT)
          }
        }
      } else if (stage === CHECKS.ACCOUNT_ACTIVATED) {
        if (user && (user.investor && (user.investor.demo || user.activated) || AuthUtils.isFounder(user.role) || AuthUtils.isProfessional(user.role))) {
          dispatch(ChecksOperations.setChecked(true))
          dispatch(ChecksOperations.setStage(null))
        } else {
          setCheck(stage)
        }
      } else if (!stage) {
        if (AuthUtils.isAdmin(user.role) || AuthUtils.isFounder(user.role) || AuthUtils.isProfessional(user.role)) {
          dispatch(ChecksOperations.setChecked(true))
          dispatch(ChecksOperations.setStage(null))
          setCheck(null)
        } else if (
          (user && user.investor && (user.activated || user.activated === null || user.activated === undefined)) ||
          (user && user.investor && user.investor.demo)
        ) {
          dispatch(ChecksOperations.setChecked(true))
          dispatch(ChecksOperations.setStage(null))
          setCheck(null)
        } else if (user && user.investor) {
          dispatch(ChecksOperations.setStage(CHECKS.CONFIGURING_ACCOUNT))
          setCheck(CHECKS.CONFIGURING_ACCOUNT)
        }
      }
    }

    getChecks()
  }, [dispatch, user, stage])

  const hideAccountActivatedModal = () => {
    dispatch(ChecksOperations.setStage(CHECKS.CHECK_ACCOUNT))
    setCheck(CHECKS.CHECK_ACCOUNT)
  }

  if (token) {
    if (check === CHECKS.LOADING) {
      return <Loader />
    } else if (
      check === CHECKS.ON_BOARDING_FIRST_STEP ||
      check === CHECKS.ON_BOARDING_SECOND_STEP ||
      check === CHECKS.ON_BOARDING_THIRD_STEP ||
      check === CHECKS.ON_BOARDING_FOURTH_STEP
    ) {
      return <OnBoarding />
    } else if (checked) {
      return <Redirect to={RoutesLinks.DASHBOARD_LINK} />
    } else if (check === CHECKS.ACCOUNT_ACTIVATED) {
      return (
        <ModalSwal
          title={t('account_setup')}
          text={t('configuring_account')}
          img={Configuration}
          padding="0"
          bgColor="blue-dark"
          textColor="gray-lines"
          textWidth="regular"
          width="96"
          height="auto"
          textMargin="0"
          exit="true"
        />
      )
    } else if (check === CHECKS.CONFIGURING_ACCOUNT) {
      return (
        <ModalSwal
          title={t('account_setup')}
          text={t('configuring_account')}
          img={Configuration}
          padding="0"
          bgColor="blue-dark"
          textColor="gray-lines"
          textWidth="regular"
          width="96"
          height="auto"
          textMargin="0"
          exit="true"
        />
      )
    } else if (check === CHECKS.SUBSCRIPTION_EXPIRED) {
      return (
        <ModalSwal
          titleColor={'gray-lines'}
          title={t('')}
          text={t('subscription_expired_help')}
          padding="0"
          bgColor="blue-dark"
          textColor="gray-lines"
          textWidth="regular"
          width="96"
          height="auto"
          textMargin="0"
          img={Configuration}
          actionText={t('continue')}
          action={() => {
            dispatch(ChecksOperations.setStage(CHECKS.PAYMENT_REQUIRED))
            setCheck(CHECKS.PAYMENT_REQUIRED)
          }}
        />
      )
    } else if (check === CHECKS.PAYMENT_REQUIRED) {
      // TODO: REVIEW PAYMEN
      return <Loader />
    } else {
      return <Loader />
    }
  } else {
    return <Redirect to={RoutesLinks.LOGIN_LINK} />
  }
}

export default Checks
