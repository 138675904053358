import { useState, useEffect, useCallback } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { RoutesLinks } from 'components/routes-links'
import UsersService from 'services/users-service'
import { Loader } from 'components/ui/molecules/Loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Details from 'styles/images/details_blue.svg'
import Avatar from 'react-avatar'
import { useSwal } from 'hooks/useSwal'
import InvestorSection from './InvestorSection'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

const Investors = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const history = useHistory()
  const { confirm } = useSwal()
  const handleRedirect = (url) => history.push(url)

  const [investors, setInvestors] = useState([])
  const [loading, setLoading] = useState(true)

  const getInvestors = useCallback(async () => {
    try {
      setLoading(true)
      const result = await UsersService.getInvestors()
      setInvestors(result.data.data)
    } catch (error) {
      addToast(
        error?.response?.data?.msg_key
          ? t(error?.response?.data?.msg_key)
          : t('error_occurred'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    } finally {
      setLoading(false)
    }
  }, [addToast, t])

  const saveInvestor = async (formData) => {
    try {
      setLoading(true)

      await UsersService.createInvestor(formData)

      addToast(t('investor_created'), {
        appearance: 'success',
        autoDismiss: true
      })

      await getInvestors()
      setShowInvestorSection(false)
    } catch (error) {
      addToast(
        error?.response?.data?.msg_key
          ? t(error?.response?.data?.msg_key)
          : t('error_occurred_creating_investor'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getInvestors().catch((error) => {
      addToast(
        error?.response?.data?.msg_key
          ? t(error?.response?.data?.msg_key)
          : t('error_occurred'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    })
  }, [getInvestors])


  const handleActivateInvestor = (investor) => {
    confirm({
      title: t('activate_investor')
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true)
          await UsersService.activateInvestor(investor._id)
          addToast(t('investor_activated_successfully'), {
            appearance: 'success',
            autoDismiss: true
          })
          getInvestors()
        } catch (error) {
          addToast(
            error.response && error.response.data.msg_key
              ? error.response.data.msg_key
              : t('error_occurred_activating_investor'),
            {
              appearance: 'error',
              autoDismiss: true
            }
          )
        } finally {
          setLoading(false)
        }
      }
    })
  }

  const [showInvestorSection, setShowInvestorSection] = useState(false)
  const [investor, setInvestor] = useState(null)

  const closeInvestorSection = () => {
    setShowInvestorSection(false)
    setInvestor(null)
  }

  return (
    <>
      <div className={'text-2xl  font-bold mb-10'}>
        {t('investors')}
      </div>

      <ButtonMain text={t('create_investor')} onClick={() => setShowInvestorSection(true)} />
      {showInvestorSection && <InvestorSection onClose={closeInvestorSection} onSubmit={saveInvestor}/> }
      <div className="mt-5 mb-10 grid grid-cols-1  xl:grid-cols-2 2xl:grid-cols-3 gap-4">
        {!loading &&
          investors.map((investor, index) => {
            return (
              <div className="flex flex-col rounded-2xl my-4" key={index}>
                <div className="w-full | flex flex-row flex-grow rounded-2xl justify-start items-center transition-all duration-400 ease-in-out bg-white shadow-soft-white p-4">
                  <div className="flex flex-col w-full pr-4">
                    <div className="text-xs flex">
                      <div>
                        {investor.image
                          ? (
                          <img
                            src={investor.image}
                            alt={'Investor Logo'}
                            className="w-16 rounded-xl"
                          />
                            )
                          : (
                          <Avatar
                            name={investor.company_name}
                            color="#e0e6f2"
                            fgColor="#4d70b3"
                            round="8px"
                            size={'4rem'}
                          />
                            )}
                      </div>
                      <div className="flex flex-col ml-4">
                        <div
                          className={`text-sm font-semibold ${
                            investor.activated
                              ? ''
                              : 'text-red-opacity cursor-pointer'
                          } mb-1`}
                          onClick={
                            !investor.activated
                              ? () => {
                                  handleActivateInvestor(investor)
                                }
                              : () => null
                          }
                        >
                          {investor.company_name}
                        </div>
                        <div>
                          <FontAwesomeIcon
                            className=" text-sm mr-1 cursor-pointer"
                            icon="user"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                investor.main_contact.user
                                  ? `${investor.main_contact.user.name} ${investor.main_contact.user.last_name}`
                                  : investor.main_contact.name
                                    ? `${investor.main_contact.name} ${investor.main_contact.last_name}`
                                    : '-'
                              )
                              addToast(t('name_copied_to_clipboard'), {
                                appearance: 'success',
                                autoDismiss: true
                              })
                            }}
                          />
                          {investor.main_contact.user
                            ? `${investor.main_contact.user.name} ${investor.main_contact.user.last_name || ''}`
                            : investor.main_contact.name
                              ? `${investor.main_contact.name} ${investor.main_contact.last_name || ''}`
                              : '-'}
                        </div>
                        <div className={'mt-2'}>
                          <FontAwesomeIcon
                            className=" text-sm mr-1 cursor-pointer"
                            icon="envelope"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                investor.main_contact.user
                                  ? investor.main_contact.user.email
                                  : investor.main_contact.email
                                    ? investor.main_contact.email
                                    : '-'
                              )
                              addToast(t('email_copied_to_clipboard'), {
                                appearance: 'success',
                                autoDismiss: true
                              })
                            }}
                          />
                          {investor.main_contact.user
                            ? investor.main_contact.user.email
                            : investor.main_contact.email
                              ? investor.main_contact.email
                              : '-'}
                        </div>
                      </div>
                    </div>
                    <div className="text-xs mt-4 mb-1">
                      <FontAwesomeIcon
                        className=" text-sm mr-1"
                        icon="calendar-plus"
                      />

                      {moment(investor.createdAt).format('YYYY-MM-DD HH:MM')}

                      <FontAwesomeIcon
                        className=" text-sm mr-1  ml-2"
                        icon="check-double"
                      />
                      {investor.activation_date
                        ? moment(investor.activation_date).format('YYYY-MM-DD HH:MM')
                        : '-'}
                      <FontAwesomeIcon
                        className=" text-sm mr-1  ml-2"
                        icon="sign-in-alt"
                      />
                      {investor.last_login
                        ? moment(investor.last_login).format(
                          'YYYY-MM-DD HH:MM'
                        )
                        : '-'}
                    </div>
                    <div className="mt-2 text-xs">
                      <FontAwesomeIcon
                        className=" text-sm mr-1"
                        icon="user-tie"
                      />
                      {investor.users.length}
                      <FontAwesomeIcon
                        className=" text-sm mr-1  ml-2"
                        icon="users"
                      />
                      {investor.entrepreneurs
                        ? investor.entrepreneurs.length
                        : 0}
                      <FontAwesomeIcon
                        className=" text-sm mr-1 ml-2"
                        icon="rocket"
                      />
                      {investor.projects.length}
                      <FontAwesomeIcon
                        className=" text-sm mr-1 ml-2 cursor-pointer"
                        icon="signature"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            investor.invitation_code
                          )
                          addToast(
                            t('invitation_code_copied_to_clipboard'),
                            {
                              appearance: 'success',
                              autoDismiss: true
                            }
                          )
                        }}
                      />
                      {investor.invitation_code}
                    </div>
                  </div>
                  {investor.activated && (
                    <div className="flex flex-col justify-start">
                      <img
                        src={Details}
                        alt="Open Support Ticket"
                        className="inline-block cursor-pointer w-10 mr-2"
                        onClick={() =>
                          handleRedirect(
                            `${RoutesLinks.USERS_LINK}investors/${investor._id}/startups`
                          )
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            )
          })}
      </div>

      {loading && <Loader />}
    </>
  )
}

export default Investors
