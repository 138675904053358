import { PopoverMenu } from 'components/ui/atoms/PopoverMenu'
import { NotificationsMenu } from 'components/ui/atoms/NotificationsMenu'
import AuthUtils from 'utils/auth-utils'
import useUser from '../../../hooks/useUser'
import { RemindersMenu } from 'components/ui/atoms/RemindersMenu'

const Header = () => {
  const { user } = useUser()

  return (
    <header className="p-3 pr-10 w-full flex justify-end items-center">
      <div className="flex items-center" id="header-options">
        {AuthUtils.isFounder(user.role) && <NotificationsMenu />}
        {AuthUtils.isInvestor(user.role) && <RemindersMenu />}
        <PopoverMenu />
      </div>
    </header>
  )
}

export default Header
