import { FaChevronDown } from 'react-icons/all'
import { TagListDots } from 'components/tags/TagListDots'
import Config from 'config';

export const BoardCardCollapsedHeader = ({ tags, title, imageComponent, onClick, startupToSearch = null }) => {
  return (
    <div
      className="flex relative w-full"
      onClick={() => onClick && onClick()}
    >
      <div>
        <div className="flex items-center">
          {imageComponent}
          <span className={`text-xs font-medium ${startupToSearch && title.toLowerCase().includes(startupToSearch.toLowerCase()) && 'bg-yellow-400'}`}>{title}</span>
        </div>
      </div>

      <div className="absolute right-2 top-1">
        <span className={`text-sm text-center text-main-${Config.PLATFORM} w-5 h-5`}>
          <FaChevronDown />
        </span>
      </div>

      <TagListDots tags={tags} className="absolute -bottom-1 left-[30px]" />
    </div>
  )
}
