import axios from 'axios/index'
import Config from '../config'

const IrmService = {
  getInvestors: async (params) => {
    return axios.post(`${Config.SUPPORT_API_URL}irm/search`, params)
  },

  registerInvestor: async (data) => {
    return axios.post(`${Config.SUPPORT_API_URL}irm`, data)
  },

  addInvestorToContacts: async (investorId) => {
    return axios.post(`${Config.SUPPORT_API_URL}irm/${investorId}/join`, {})
  },

  getInvestor: async (investorId) => {
    return axios.get(`${Config.SUPPORT_API_URL}irm/${investorId}`)
  },

  saveInvestor: async (investorId, data) => {
    return axios.patch(`${Config.SUPPORT_API_URL}irm/${investorId}`, data)
  },

  deleteInvestor: async (investorId) => {
    return axios.delete(`${Config.SUPPORT_API_URL}irm/${investorId}`)
  },

  getNotes: async (investorId) => {
    return axios.get(`${Config.SUPPORT_API_URL}irm/${investorId}/annotations`)
  },

  importData: async (data) => {
    return axios.post(`${Config.SUPPORT_API_URL}irm/import`, data)
  }
}
export default IrmService
