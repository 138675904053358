import { useCallback, useState, useEffect } from 'react'
import { SectionHeader } from 'components/ui/molecules/SectionHeader'
import { useTranslation } from 'react-i18next'
import { Loader } from 'components/ui/molecules/Loader'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import { useToasts } from 'react-toast-notifications'
import SupportService from 'services/support-service'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Details from 'styles/images/details_blue.svg'
import { RoutesLinks } from 'components/routes-links'
import { useHistory } from 'react-router-dom'

const SupportRequestedFeatures = () => {
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const history = useHistory()

  const handleRedirect = (url) => history.push(url)

  const [requestedFeatures, setRequestedFeatures] = useState([])

  const getRequestedFeatures = useCallback(async () => {
    try {
      setLoading(true)
      const result = await SupportService.getRequestedFeatures()
      setRequestedFeatures(result.data.data)
    } catch (error) {
      console.log(error)
      addToast(
        error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t('error_occurred'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    } finally {
      setLoading(false)
    }
  })

  useEffect(() => {
    getRequestedFeatures()
  }, [])

  const ROUTES = {
    tickets: RoutesLinks.SUPPORT_TICKETS_LINK,
    nps_section: RoutesLinks.SUPPORT_NPS_LINK,
    requested_features: RoutesLinks.SUPPORT_REQUESTED_FEATURES_LINK
  }

  return (
    <>
      <SectionHeader
        setSection={(sec) => {
          handleRedirect(ROUTES[sec])
        }}
        sectionTitles={[
          t('tickets'),
          t('nps_section'),
          t('requested_features')
        ]}
        sectionKeys={['tickets', 'nps_section', 'requested_features']}
        section={'requested_features'}
        showAdd={false}
        showFilters={false}
      />

      <div className={'mt-10'}>
        {!loading && (
          <>
            {requestedFeatures.map((requestedFeature, index) => {
              return (
                <div
                  className={'flex w-full flex-col rounded-2xl my-4'}
                  key={index}
                >
                  <div
                    className={'w-full  | flex flex-row flex-grow rounded-2xl justify-start items-center transition-all duration-400 ease-in-out bg-white shadow-soft-white p-4'}
                  >
                    <div className={'flex flex-col w-full pr-4'}>
                      <div className={'text-xs'}>
                        <FontAwesomeIcon
                          className=" text-sm mr-1 cursor-pointer"
                          icon="user"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              requestedFeature.user.name
                            )
                            addToast(t('name_copied_to_clipboard'), {
                              appearance: 'success',
                              autoDismiss: true
                            })
                          }}
                        />
                        {`${requestedFeature.user.name} ${requestedFeature.user.last_name}`}
                        <FontAwesomeIcon
                          className=" text-sm ml-2 mr-1 cursor-pointer"
                          icon="envelope"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              requestedFeature.user.email
                            )
                            addToast(t('email_copied_to_clipboard'), {
                              appearance: 'success',
                              autoDismiss: true
                            })
                          }}
                        />
                        {requestedFeature.user.email}

                        <FontAwesomeIcon
                          className=" text-sm ml-2 mr-1"
                          icon="calendar"
                        />
                        {moment(requestedFeature.createdAt).format(
                          'YYYY-MM-DD'
                        )}
                      </div>
                      <div className={'font-semibold text-xs mt-2 mb-1'}>
                        {`${t('requested_feature')}:`}
                      </div>
                      <div className={'text-xs'}>
                        {requestedFeature.message
                          ? requestedFeature.message
                          : '-'}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </>
        )}
        {!loading && !requestedFeatures.length && (
          <NoDataInfo title={t('no_requested_features_found')} />
        )}
      </div>
      {loading && <Loader />}
    </>
  )
}

export default SupportRequestedFeatures
