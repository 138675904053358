import { useTranslation } from 'react-i18next'
import {
  FaFileExcel, FaFileImage, FaFilePdf, FaTimes, FaFile, FaDownload
} from 'react-icons/all'
import InputFile from '../../ui/atoms/input-files'
import { useToasts } from 'react-toast-notifications'
import { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import SupportService from 'services/support-service'
import Config from 'config'

export const QuestionTypeFile = ({
  formId,
  investorId,
  currentQuestion,
  onChange,
  setLoading,
  value,
  scoreCardId,
  shared
}) => {
  const { t } = useTranslation()

  const { addToast } = useToasts()
  const [fileNames, setFileNames] = useState({})

  const downloadDocument = async () => {
    try {
      setLoading(true)
      let result = null
      if (!scoreCardId) {
        result = await SupportService.getDocumentForForm(typeof value === 'string' ? value.split('-|-')[0] : value._id, shared)
      } else {
        result = await SupportService.getDocumentForScoreCard(typeof value === 'string' ? value.split('-|-')[0] : value._id)
      }
      if (result?.data?.url) {
        window.open(result.data.url, '_blank')
      }
    } catch (error) {
      addToast(t(error?.response?.data?.msg_key || 'error_occurred_downloading_document'), {
        appearance: 'error',
        autoDismiss: true
      })
    } finally {
      setLoading(false)
    }
  }

  const onSelectFile = async(file) => {
    if (!file) {
      onChange(null)
      return
    }

    if (((file?.size || 0) / 1024 / 1024) > 200) {
      addToast(t('error_file_size', { max_size: 200}), {
        appearance: 'error',
        autoDismiss: true
      })
      return
    }

    setLoading(true)

    setFileNames({
      ...fileNames,
      [currentQuestion.question_id._id]: file.name
    })

    const formData = new FormData()
    formData.append('document', file)
    if (formId) {
      formData.append('form', formId)
    }
    if (investorId) {
      formData.append('investor', investorId)
    }

    if (scoreCardId) {
      formData.append('scorecard', scoreCardId)
    }

    formData.append('question', currentQuestion.question_id._id)

    try {
      let result = null
      if (scoreCardId) {
        result = await SupportService.saveDocumentInScoreCard(formData);
      } else {
        result = await SupportService.saveDocumentInForm(formData);
      }
      onChange(result.data);
    } catch (error) {
      onChange(null)
      addToast(t('error_occurred'), { appearance: 'error', autoDismiss: true })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {!!value && (
        <>
          <div className="mt-2">
            <p>
              {currentQuestion.question_id.type === 'file_image' && (
                <FaFileImage className={`text-main-${Config.PLATFORM} inline-block mr-2`} />
              )}
              {currentQuestion.question_id.type === 'file_pdf' && (
                <FaFilePdf className={`text-main-${Config.PLATFORM} inline-block mr-2`}/>
              )}
              {currentQuestion.question_id.type === 'file_excel' && (
                <FaFileExcel className={`text-main-${Config.PLATFORM} inline-block mr-2`} />
              )}
              {currentQuestion.question_id.type === 'file' && (
                <FaFile className={`text-main-${Config.PLATFORM} inline-block mr-2`} />
              )}
              {typeof value === 'string' ? value.split('-|-')[1] : value.name}&nbsp;
              <span
                onClick={() => {
                  downloadDocument()
                  ReactTooltip.hide()
                }}
                className="cursor-pointer"
                data-tip={t('download')}
              >
                <FaDownload className={`text-main-${Config.PLATFORM} inline-block mr-2`} />
              </span>
              <span
                onClick={() => {
                  onChange(null)
                  ReactTooltip.hide()
                }}
                className="cursor-pointer"
                data-tip={t('reset')}
              >
                <FaTimes className={`text-main-${Config.PLATFORM} inline-block mr-2`} />
              </span>
            </p>
          </div>
        </>
      )}

      {!value && (
        <InputFile
          setSelectedFile={file => onSelectFile(file)}
          className='bg-white'
          accept={
            currentQuestion.question_id.type === 'file_pdf'
              ? '.pdf'
              : currentQuestion.question_id.type === 'file_image'
                ? '.jpg, .jpeg, .png' : currentQuestion.question_id.type === 'file_excel'
                  ? '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                  : currentQuestion.question_id.file_accepted_extensions || '.mp4, .avi, .pdf, .jpg, .jpeg, .png, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
          }
        />
      )}
    </>
  )
}
