import { useState } from 'react'
import UserOperations from 'redux/user/user-operations'
import PreferencesService from 'services/preferences-service'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Loader } from 'components/ui/molecules/Loader'
import { Card } from 'components/ui/atoms/Card'
import useUser from 'hooks/useUser'
import Config from 'config'
import { FaGripHorizontal, FaListAlt } from 'react-icons/fa'

const DealflowViewMode = () => {
  const { t } = useTranslation()

  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const { user } = useUser()

  const setUserInfo = (data) => dispatch(UserOperations.setUserInfo({ data }))
  const [loading, setLoading] = useState(false)

  const [selectedViewMode, setSelectedViewMode] = useState(user.preferences.dealflow_visibility_mode || 'grid')

  const onSubmit = async (viewMode) => {
    try {
      setLoading(true)
      const result = await PreferencesService.updatePreferences({
        ...user.preferences,
        language: user.preferences.language.code,
        dealflow_visibility_mode: viewMode
      })
      setUserInfo(result.data)
      setSelectedViewMode(viewMode)
      addToast(t('settings_updated_successfully'), {
        appearance: 'success',
        autoDismiss: true
      })
    } catch (error) {
      if (error.response) {
        addToast(t('error_occurred_updating_settings'), {
          appearance: 'error',
          autoDismiss: true
        })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Card wrapperClassName='my-4'>
      <h3 className={`border-b font-medium text-main-${Config.PLATFORM} font-bold mb-4 border-main-${Config.PLATFORM}`}>
        {t('dealflow_visibility_mode')}
      </h3>
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-start sm:justify-between">
        <div>
          <small className="text-gray-dark font-light">
            {t('change_dealflow_visibility_mode')}
          </small>
        </div>
        <div className="flex flex-row">
            <div
              className={`mr-1 w-10 flex items-center justify-center p-1 ${selectedViewMode === 'list' ? `shadow-inner border-2 border-main-${Config.PLATFORM}` : `border border-border-buttons-secondary-${Config.PLATFORM}`} bg-white hover:shadow-inner mr-2 rounded-lg cursor-pointer`}
              onClick={() => onSubmit('list')}
            >
              <FaListAlt className='inline' />
            </div>
            <div
              className={`mr-1 w-10 flex items-center justify-center p-1 ${selectedViewMode === 'grid' ? `shadow-inner border-2 border-main-${Config.PLATFORM}` : `border border-border-buttons-secondary-${Config.PLATFORM}`} bg-white hover:shadow-inner mr-2 rounded-lg cursor-pointer`}
              onClick={() => onSubmit('grid')}
            >
              <FaGripHorizontal className='inline' />
            </div>
        </div>
      </div>
      {loading && <Loader />}
    </Card>
  )
}

export default DealflowViewMode
