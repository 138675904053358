import { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ProjectsService from 'services/projects-service'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import { ModalSwal } from 'components/ui/molecules/Modal'
import { Loader } from 'components/ui/molecules/Loader'
import { RoutesLinks } from 'components/routes-links'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import { PageTitle } from 'components/ui/atoms/PageTitle';
import AuthUtils from 'utils/auth-utils'
import UserService from 'services/user-service'
import UserOperations from 'redux/user/user-operations'

import { isEqual } from 'lodash'
import Invitation from 'components/invitations/components/invitation'
import NotificationsOperations from 'redux/notifications/notifications-operations'
import Info from 'styles/images/vidio-info.svg'
import { useSwal } from 'hooks/useSwal'
import useUser from '../../hooks/useUser'

const Notifications = () => {
  const { user } = useUser()

  const history = useHistory()
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { confirm } = useSwal()
  const handleRedirect = (url) => history.push(url)
  const dispatch = useDispatch()
  const [showModalInfo, setShowModalInfo] = useState(false)

  const setUserInfo = (data) => dispatch(UserOperations.setUserInfo({ data }))
  const setInvitationsOperations = (invitations) =>
    dispatch(NotificationsOperations.setInvitations(invitations))

  const [loading, setLoading] = useState(false)


  const [invitations, setInvitations] = useState([])
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 10,
    totalPages: 0,
    totalDocs: 0
  })

  const getInvitations = useCallback(async () => {
    try {
      setLoading(true)

      const queryParams = {
        page: pagination.page,
        rowsPerPage: pagination.rowsPerPage,
        sortBy: '-createdAt'
      }

      const result = await ProjectsService.getInvitations(queryParams)

      setInvitations(result.data.invitations)
      setInvitationsOperations(!!result.data.invitations.length)

      if (result.data.pagination) {
        const newPagination = {
          ...pagination,
          totalPages: result.data.pagination.pages,
          totalDocs: result.data.pagination.totalDocs
        }

        if (!isEqual(newPagination, pagination)) {
          setPagination(newPagination)
        }
      }
    } catch (error) {
      addToast(t('error_occurred'), {
        appearance: 'error',
        autoDismiss: true
      })
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [addToast, t, pagination])

  const declineInvitation = (invitation) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true)
          await ProjectsService.declineInvitation(invitation._id)
          reloadUserInfo()
          getInvitations()
          addToast(t('successfully_declined'), {
            appearance: 'success',
            autoDismiss: true
          })
        } catch (error) {
          addToast(t('error_occurred'), {
            appearance: 'error',
            autoDismiss: true
          })
        } finally {
          setLoading(false)
        }
      }
    })
  }

  const reloadUserInfo = async () => {
    try {
      const response = await UserService.getUser(user._id)
      setUserInfo(response.data)
      if (response.data.projects.length && !(invitations.length - 1)) {
        setInvitationsOperations(false)
        handleRedirect(RoutesLinks.MY_STARTUP_LINK)
      } else if (!(invitations.length - 1)) {
        setInvitationsOperations(false)
      }
    } catch (error) {}
  }

  const acceptInvitation = (invitation) => {
    if (user.projects.length) {
      setShowModalInfo(true)
    } else {
      confirm().then(async (isConfirmed) => {
        if (isConfirmed) {
          try {
            setLoading(true)
            await ProjectsService.joinProject(invitation._id)
            reloadUserInfo()
            getInvitations()
          } catch (error) {
            console.log(error)
            addToast(t('error_occurred'), {
              appearance: 'error',
              autoDismiss: true
            })
          } finally {
            setLoading(false)
          }
        }
      })
    }
  }

  useEffect(() => {
    if (AuthUtils.isFounder(user.role)) {
      getInvitations()
    }
  }, [pagination.page, pagination.rowsPerPage])

  return (
    <>
      <PageTitle title={t('notifications')} />
      <div className='my-6'>
        {invitations &&
          invitations.length > 0 &&
          invitations.map((invitation, index) => {
            return (
              <Invitation
                key={index}
                invitation={invitation}
                decline={declineInvitation}
                accept={acceptInvitation}
                dashboard={false}
              />
            )
          })}
        {showModalInfo && (
          <ModalSwal
            title={t('maximum_projects_reached')}
            text={t('maximum_projects_reached_description')}
            onClick={() => setShowModalInfo(false)}
            img={Info}
          />
        )}
        {!loading && invitations.length === 0 && (
          <NoDataInfo
            title={t('invitations_not_found')}
            textOne={t('no_invitations_data_one')}
            textTwo={t('no_invitations_data_two')}
          />
        )}
        {loading && <Loader />}
      </div>
    </>
  )
}

export default Notifications
