import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import DataRoomsService from 'services/data-room-service'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Input } from 'components/ui/atoms/Input'
import { IconContainer } from 'components/ui/atoms/IconContainer'
import Plus from 'styles/images/plus.svg'
import Trash from 'styles/images/trash.svg'
import Plane from 'styles/images/plane.svg'
import { useSwal } from 'hooks/useSwal'
import useUser from 'hooks/useUser'
import ReactTooltip from 'react-tooltip'
import { useEffect } from 'react'
import Config from 'config'

const DataRoomAccess = ({ dataRoom, getDataRoom, loading, setLoading }) => {
    useEffect(() => {
        ReactTooltip.rebuild()
    }, [dataRoom])

  const { user } = useUser()
  const projectId = user.projects.length ? user.projects[0]._id : null
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { confirm } = useSwal()

  const inputPrev = { name: '', last_name: '', email: '' }

  const GuestSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('incorrect_format'))
      .required(t('required_field')),
    name: Yup.string().required(t('required_field')),
    last_name: Yup.string().required(t('required_field'))
  })

  const { register, handleSubmit, errors, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(GuestSchema)
  })

  const submitGuest = async (inputs) => {
    if (
      !dataRoom.guests.find(
        (guest) => guest.email === inputs.email && guest.active === true
      )
    ) {
      try {
        setLoading(true)
        await DataRoomsService.inviteGuest(projectId, dataRoom._id, {
          ...inputs
        })
        setLoading(false)
        getDataRoom()
      } catch (error) {
        addToast(
          error.response && error.response.data.msg_key
            ? error.response.data.msg_key
            : t('error_occurred_inviting_guest'),
          {
            appearance: 'error',
            autoDismiss: true
          }
        )
      } finally {
        setLoading(false)
      }
    }
    reset(inputPrev)
  }

  const deleteGuest = (guestId) => {
    confirm({
      icon: 'warning'
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true)
          await DataRoomsService.deleteGuest(projectId, dataRoom._id, {
            guest: guestId
          })
          addToast(t('guest_deleted_successfully'), {
            appearance: 'success',
            autoDismiss: true
          })
          setLoading(false)
          getDataRoom()
        } catch (error) {
          addToast(
            error.response && error.response.data.msg_key
              ? t(error.response.data.msg_key)
              : t('error_occurred_deleting_guest'),
            {
              appearance: 'error',
              autoDismiss: true
            }
          )
        }
      }
    })
  }

  const resendCode = (guestId) => {
    confirm({
      icon: 'warning'
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true)
          await DataRoomsService.resendCode(projectId, dataRoom._id, {
            guest: guestId
          })
          addToast(t('code_resent_successfully'), {
            appearance: 'success',
            autoDismiss: true
          })
        } catch (error) {
          addToast(
            error.response && error.response.data.msg_key
              ? t(error.response.data.msg_key)
              : t('error_occurred_resending_code'),
            {
              appearance: 'error',
              autoDismiss: true
            }
          )
        } finally {
          setLoading(false)
        }
      }
    })
  }

  return (
    <>
        <h3 className={`text-2xl text-main-${Config.PLATFORM} mt-4 mb-4 border-b border-main-${Config.PLATFORM}`}>{t('access')}</h3>
        <div>
            <div className={'my-4'}>
            <div className="text-left">
                <p className={`text-main-${Config.PLATFORM}`}>
                {t('share_data_room_guest')}
                </p>
                <small>{t('share_data_room_guest_explanation')}</small>

                <form
                className="flex items-center mt-6 mb-3"
                onSubmit={handleSubmit(submitGuest)}
                >
                <div className="flex w-full flex-wrap flex-col sm:flex-row">
                    <div className="w-full sm:w-1/2 md:w-5/12 px-1">
                    <Input
                        reference={register}
                        id="email_guest"
                        type="email"
                        name="email"
                        placeholder={t('write_guest_email')}
                        label={`${t('email')}:`}
                        error={errors.email}
                    />
                    </div>
                    <div className="w-full sm:w-1/2 md:w-3/12 px-1">
                    <Input
                        reference={register}
                        id="name_guest"
                        type="text"
                        name="name"
                        placeholder={t('write_guest_name')}
                        label={`${t('name')}:`}
                        error={errors.name}
                    />
                    </div>
                    <div className="w-full sm:w-1/2 md:w-3/12 px-1">
                    <Input
                        reference={register}
                        id="last_name_guest"
                        type="text"
                        name="last_name"
                        placeholder={t('write_guest_last_name')}
                        label={`${t('last_name')}:`}
                        error={errors.last_name}
                    />
                    </div>
                    <div className="w-1/2 md:w-1/12 flex mt-3 md:mt-5 sm:justify-center" data-tip={t('add_guest')}>
                    <IconContainer
                        width="8"
                        height="8"
                        shadow="hover"
                        shadowHover="inner"
                        iconWidth="4"
                        icon={Plus}
                        onClick={() => console.log('===')}
                    />
                    </div>
                </div>
                </form>
            </div>
            <div>
                {dataRoom.guests?.filter((guest) => guest.active === true)
                .map((elm, idx) => (
                    <div className="w-full flex items-center" key={idx}>
                    <div className="w-full flex items-center flex-wrap">
                        <div className="w-1/2 md:w-5/12 px-1">
                        <Input
                            disabled
                            id={'email' + idx}
                            type="email"
                            name={elm.email || ' '}
                            value={elm.email || ' '}
                        />
                        </div>
                        <div className="w-1/2 md:w-3/12 px-1">
                        <Input
                            disabled
                            id={'name' + idx}
                            type="text"
                            name={elm.name || ' '}
                            value={elm.name || ' '}
                        />
                        </div>
                        <div className="w-1/2 md:w-3/12 px-1 flex items-center">
                        <Input
                            disabled
                            id={'last_name' + idx}
                            type="text"
                            name={elm.last_name || ' '}
                            value={elm.last_name || ' '}
                        />
                        </div>
                        <div className="w-1/2 md:w-1/12 flex justify-center mb-1 md:mb-4">
                            <div data-tip={t('resend_password')}>
                                <IconContainer
                                    width="8"
                                    height="8"
                                    shadow="hover"
                                    shadowHover="inner"
                                    iconWidth="4"
                                    icon={Plane}
                                    onClick={() => resendCode(elm._id)}
                                />
                            </div>
                            <div data-tip={t('remove_guest')}>
                                <IconContainer
                                    width="8"
                                    height="8"
                                    shadow="hover"
                                    shadowHover="inner"
                                    iconWidth="4"
                                    icon={Trash}
                                    onClick={() => deleteGuest(elm._id)}
                                />
                            </div>
                        </div>
                    </div>
                    </div>
                ))}
            </div>
            </div>
        </div>
    </>
  )
}

export default DataRoomAccess
