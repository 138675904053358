import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import QuestionnairesService from '../../services/questionnaires-service'
import { RoutesLinks } from '../routes-links'
import { Loader } from '../ui/molecules/Loader'
import { SectionHeader } from '../ui/molecules/SectionHeader'
import { Button } from 'components/ui/atoms/Button'
import { Card } from 'components/ui/atoms/Card'
import { PageTitle } from 'components/ui/atoms/PageTitle'
import { FaPlus, FaPlusCircle } from 'react-icons/all'
import ReactTooltip from 'react-tooltip'
import { Pagination } from 'components/ui/atoms/Pagination'
import useUser from '../../hooks/useUser'
import { CreateFormSection } from './components/CreateFormSection'
import { FormListItem } from './components/FormListItem'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

const Forms = () => {
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { user, isAdmin } = useUser()
  const history = useHistory()
  const [forms, setForms] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(12)
  const [showCreateFormSection, setShowCreateFormSection] = useState(false)

  const SECTIONS_URLS = {
    forms: RoutesLinks.QE_FORMS_LINK,
    questions: RoutesLinks.QE_QUESTIONS_LINK,
    templates: RoutesLinks.QE_TEMPLATES_LINK
  }

  const getQuestionnaireForms = () => {
    setLoading(true)

    return QuestionnairesService.getQuestionnairesForms({
      rowsPerPage: pageSize,
      page: currentPage
    })
      .then((response) => {
        const { forms, pagination } = response.data.data
        setForms(forms)

        if (pagination.current - 1 !== currentPage) {
          setCurrentPage(pagination.current)
        }

        if (pagination.total !== totalPages) {
          setTotalPages(pagination.pages)
        }
      })
      .catch(() => {
        addToast(t('error_retrieving_forms'), {
          appearance: 'error',
          autoDismiss: true
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const showSectionHeader = () => {
    let sections = []
    if (!isAdmin) sections = user?.investor?.permissions?.sections || []

    return isAdmin || sections.includes('questionnaires_engine')
  }

  useEffect(() => {
    getQuestionnaireForms()
  }, [currentPage, pageSize])

  useEffect(() => {
    setTimeout(() => {
      ReactTooltip.rebuild()
    }, 500)
  }, [forms])

  return (
    <>
      <div className='flex items-start'>
        <div className='flex-1'>
          <PageTitle
            title={t(showSectionHeader() ? 'my_forms' : 'questionnaires_engine')}
          />
        </div>

        <ButtonMain
          iconComponent={<FaPlus className='inline-block mr-2' />}
          text={t('create_form')}
          onClick={() => setShowCreateFormSection(true)}
        />
      </div>

      {showSectionHeader() && (
        <SectionHeader
          sectionTitles={[t('forms'), t('questions'), t('templates')]}
          sectionKeys={['forms', 'questions', 'templates']}
          setSection={(sec) => history.push(SECTIONS_URLS[sec])}
          section="forms"
        />
      )}

      <div className="py-4">
      {forms.length === 0 && (
        <div className='my-6'>
          <NoDataInfo title={t('forms_not_found')} textOne={t('forms_not_found_help')}/>
        </div>
      )}
        {forms.length > 0 && (
          <>
            <div className='grid gap-8 grid-cols-2 2xl:grid-cols-3'>
              {forms.map(form => (
                <FormListItem
                  key={form._id}
                  form={form}
                />
              ))}
            </div>

            <div className="mt-4">
              <Pagination
                paginateOptions={[12, 24, 50]}
                currentPage={currentPage}
                setCurrentPage={(page) => setCurrentPage(page)}
                perPage={pageSize}
                pages={totalPages}
                setPerPage={(value) => setPageSize(value[0].id)}
              />
            </div>
          </>
        )}
      </div>

      {showCreateFormSection && <CreateFormSection
        onClose={() => setShowCreateFormSection(false)}
        forms={forms.map((form) => {
          return { id: form._id, value: form.name }
        })}
      />}

      {loading && <Loader />}
    </>
  )
}

export default Forms
