import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { useHistory, useLocation } from 'react-router-dom'
import { RoutesLinks } from 'components/routes-links'
import SupportService from 'services/support-service'
import { Loader } from 'components/ui/molecules/Loader'
import { EventCreateOrUpdateForm } from 'components/events/components/event/components/EventCreateOrUpdateForm'
import { SidePanel } from 'components/ui/objects/SidePanel'

const EvaluationProcessWizard = ({ onClose, getEvents }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)


  const onSubmit = (values) => {
    setLoading(true)

    SupportService.createEvent(values)
      .then(() => {
        addToast(t('evaluation_proccess_created_successfully'), {
          appearance: 'success',
          autoDismiss: true
        })
        getEvents()
        onClose()
      })
      .catch(() => {
        addToast(t('error_creating_evaluation_process'), {
          appearance: 'error',
          autoDismiss: true
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <SidePanel
        width={'1/2'}
        onClose={onClose}
        title={t('create_evaluation_process')}
      >
          <EventCreateOrUpdateForm onSubmit={onSubmit} />
        </SidePanel>

      {loading && <Loader />}
    </>
  )
}

export default EvaluationProcessWizard
