import ScrollContainer from 'react-indiana-drag-scroll'
import { RoutesLinks } from '../routes-links'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import Config from 'config';

export const DealflowBoardsBar = ({ dealflow }) => {
  const { t } = useTranslation()
  const boardsPanel = useRef(null)
  const history = useHistory()
  const [boardPanelWidth, setBoardPanelWidth] = useState('100%')

  const calculateBoardsWidth = () => {
    if (!boardsPanel || !boardsPanel.current) {
      return 0
    }

    if (!dealflow.boards || !dealflow.boards.length) {
      return '100%'
    }

    let totalWidth = 0
    const tags = boardsPanel.current.querySelectorAll('span')

    for (let i = 0; i < tags.length; i++) {
      totalWidth += tags[i].offsetWidth + 10
    }

    return totalWidth
  }

  useEffect(() => {
    setBoardPanelWidth(calculateBoardsWidth())
  }, [boardsPanel])

  return (
    <div className="relative">
      {(dealflow.boards && dealflow.boards.length > 0) && (
        <div className="h-full top-0 right-0 w-5 absolute bg-gradient-to-l from-white to-transparent" />
      )}

      <ScrollContainer
        className="h-8 max-h-8 mt-0.5 cursor-grab active:cursor-grabbing"
        horizontal={true}
        hideScrollbars={true}
      >
        <div
          ref={boardsPanel}
          style={{ width: boardPanelWidth }}
        >
          {(dealflow.boards && dealflow.boards.length > 0) && dealflow.boards.map(({ _id, name }, i) => {
            return (
              <span
                key={`${_id}-${i}`}
                className={`inline-block mr-2 mt-1 font-semibold text-xxs bg-bg-buttons-secondary-${Config.PLATFORM} px-2 py-0.5 rounded-md border border-border-buttons-secondary-${Config.PLATFORM} text-text-buttons-secondary-${Config.PLATFORM} cursor-pointer hover:shadow-inner`}
                onClick={() => history.push(`${RoutesLinks.PIPELINE_MANAGER_LINK}boards/${_id}`)}
              >
                {name}
              </span>
            )
          })}

          {(!dealflow.boards || dealflow.boards.length === 0) && (
            <span className="inline-block mr-3 mt-1 font-semibold text-xxs px-2 py-0.5 text-gray-300">
              {t('no_boards')}
            </span>
          )}
        </div>
      </ScrollContainer>
    </div>
  )
}
