import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { SectionHeader } from 'components/ui/molecules/SectionHeader'
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom'
import { Button } from 'components/ui/atoms/Button'
import { RoutesLinks } from 'components/routes-links'
import SupportService from 'services/support-service'
import ArrowGoBack from 'styles/images/arrow_left.svg'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import { Loader } from 'components/ui/molecules/Loader'
import Annotations from 'styles/images/notes.svg'
import NotesContainer from 'components/ui/objects/NotesContainer'
import Select from 'components/ui/atoms/Select'
import { isEmpty } from 'lodash'
import { JudgeStartupEvaluationForm } from 'components/events/components/evaluation/JudgeStartupEvaluationForm'
import { JudgeStartupEvaluationView } from 'components/events/components/evaluation/JudgeStartupEvaluationView'
import useUser from 'hooks/useUser'
import { useSwal } from 'hooks/useSwal'
import { DocumentList } from 'components/documentation/documents/components/DocumentList'
import { Alert } from 'components/ui/molecules/Alert'
import Config from 'config';
import { FaEye, FaFileDownload, FaPlus, FaPlusCircle } from 'react-icons/fa'
import { PageTitle } from 'components/ui/atoms/PageTitle'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { TbNotes } from 'react-icons/tb'
import { ButtonCardSecondary } from 'components/ui/atoms/ButtonCardSecondary'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { StartupGeneralInformation } from 'components/startup-profile/StartupGeneralInformation'
import { DealflowService } from 'services/dealflow-service'
import DealflowForms from 'components/dealflow/DealflowForms'
import { ButtonCardMain } from 'components/ui/atoms/ButtonCardMain'
import FormAnswersSection from 'components/qe-forms/components/forms-form/FormAnswersSection'
import FormUtils from 'utils/form-utils'
import { DealResumeSection } from 'components/dealflow/DealResumeSection'
import { Card } from 'components/ui/atoms/Card'

const Application = () => {
  const { t } = useTranslation()
  const { eventId, startupId } = useParams()
  const { addToast } = useToasts()
  const { user, userLanguage } = useUser()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [section, setSection] = useState('information')
  const [event, setEvent] = useState(null)
  const [showNotes, setShowNotes] = useState(false)
  const [notes, setNotes] = useState([])
  const [selectedNote, setSelectedNote] = useState(null)
  const [loadingGetNotes, setLoadingGetNotes] = useState(true)
  const [loadingCreateNote, setLoadingCreateNote] = useState(false)
  const [loadingUpdateNote, setLoadingUpdateNote] = useState(false)
  const [loadingDeleteNote, setLoadingDeleteNote] = useState(false)
  const [judges, setJudges] = useState([])
  const [selectedJudge, setSelectedJudge] = useState(null)
  const [loadingGetNote, setLoadingGetNote] = useState(false)
  const [showEvaluateForm, setShowEvaluateForm] = useState(true)
  const [evaluation, setEvaluation] = useState(null)
  const { confirm } = useSwal()
  const [selectedForm, setSelectedForm] = useState(null)
  const [info, setInfo] = useState(null)
  const [answerToExpand, setAnswerToExpand] = useState(null)
  const [deal, setDeal] = useState(null)
  const [showDealInfo, setShowDealInfo] = useState(false)
  const [investorView, setInvestorView] = useState(true)

  const documentListRef = useRef()


  const getEvent = async () => {
    try {
      setLoading(true)
      const result = await SupportService.getEvent(eventId)
      if (!isEmpty(result.data.data)) {
        getSharedDeal()
        setEvent(result.data.data)
      } else {
        history.push(RoutesLinks.EVALUATION_PROCESSES_LINK)
      }
    } catch (error) {
      addToast(t('error_occurred_retrieving_event'), {
        appearance: 'error',
        autoDismiss: true
      })
      history.push(RoutesLinks.EVALUATION_PROCESSES_LINK)
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const getSharedDeal = async () => {
    try {
      setLoading(true)
      const result = await SupportService.getSharedDeal(startupId)
      if (result.data) {
        setDeal(result.data)
      } else {
        addToast(t('error_occurred_retrieving_information'), { appearance: 'error', autoDismiss: true })
      }
    } catch (error) {
      console.log(error)
      addToast(t('error_occurred_retrieving_information'), { appearance: 'error', autoDismiss: true })
    } finally {
      setLoading(false)
    }
  }

  const getNotes = async () => {
    try {
      setLoadingGetNotes(true)
      const result = await SupportService.getNotes(
        {startup: startupId}
      )
      setNotes(result.data)
    } catch (error) {
      addToast(t('error_occurred_retrieving_notes'), {
        appearance: 'error',
        autoDismiss: true
      })
    } finally {
      setLoadingGetNotes(false)
    }
  }

  const createNote = async (title, type, content) => {
    try {
      setLoadingCreateNote(true)
      await SupportService.createNote({
        event: eventId,
        startup: startupId,
        user: user._id,
        title,
        content,
        type,
        judge: selectedJudge ? selectedJudge.id : null
      })
      getNotes()
    } catch (error) {
      addToast(t('error_occurred_creating_note'), {
        appearance: 'error',
        autoDismiss: true
      })
    } finally {
      setLoadingCreateNote(false)
    }
  }

  const updateNote = async (title, content) => {
    try {
      setLoadingUpdateNote(true)
      await SupportService.updateNote(selectedNote._id, { title, content })
      getNotes()
    } catch (error) {
      addToast(t('error_occurred_updating_note'), {
        appearance: 'error',
        autoDismiss: true
      })
    } finally {
      setLoadingUpdateNote(false)
    }
  }

  const deleteNote = async (noteId) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoadingDeleteNote(true)
          await SupportService.deleteNote(noteId)
          getNotes()
        } catch (error) {
          addToast(t('error_occurred_deleting_note'), {
            appearance: 'error',
            autoDismiss: true
          })
        } finally {
          setLoadingDeleteNote(false)
        }
      }
    })
  }

  const getNote = async (noteId) => {
    try {
      setLoadingGetNote(true)
      const result = await SupportService.getNote(noteId)
      setSelectedNote(result.data)
    } catch (error) {
      addToast(t('error_occurred_retrieving_note'), {
        appearance: 'error',
        autoDismiss: true
      })
    } finally {
      setLoadingGetNote(false)
    }
  }

  const getJudges = () => {
    setLoading(true)
    SupportService.getJudges(eventId)
      .then((response) => {
        setJudges(response.data.data)
      })
      .catch((error) => {
        console.log(error)
        addToast(t('error_occurred'), {
          appearance: 'error',
          autoDismiss: true
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const isEvaluationOwner = () => {
    const judge = judges.find((j) => j._id === selectedJudge.id)
    return judge.user && judge.user === user._id
  }

  const getEventStartupEvaluation = () => {
    setLoading(true)
    SupportService.getEventStartupEvaluation(
      eventId,
      startupId,
      selectedJudge.id
    )
      .then((result) => {
        if (result.data.data) {
          setShowEvaluateForm(false)
        }
        setEvaluation(result.data.data)
      })
      .catch((error) => {
        console.log(error)
        addToast(t('error_occurred_retrieving_evaluation'), {
          appearance: 'error',
          autoDismiss: true
        })
      }).finally(() => {
        setLoading(false)
      })
  }


  useEffect(() => {
    getEvent()
    getNotes()
  }, [])

  useEffect(() => {
    if (selectedJudge) {
      getEventStartupEvaluation()
    }
  }, [selectedJudge])

  useEffect(() => {
    getJudges()
  }, [])

  const downloadDocument = async (documentId) => {
    try {
      setLoading(true)
      let result = null
      result = await SupportService.getDocumentForForm(documentId, false)
      if (result?.data?.url) {
        window.open(result.data.url, '_blank')
      }
    } catch (error) {
      addToast(t(error?.response?.data?.msg_key || 'error_occurred_downloading_document'), {
        appearance: 'error',
        autoDismiss: true
      })
    } finally {
      setLoading(false)
    }
  }

  const getForm = async () => {
    try {
      setLoading(true)
      const result = await SupportService.getEventStartup(selectedForm, startupId)
      setInfo(result.data.data)
    } catch (error) {
      addToast(t('error_occurred_retrieving_information'), {
        appearance: 'error',
        autoDismiss: true
      })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (selectedForm) {
      getForm()
    }
  }, [selectedForm])

  useEffect(() => {
    if (info?.application) {
      const parsedQuestions = FormUtils.getParsedQuestions(info.application.questions.map((q) => { return { question_id: q.question }}))
      const answers = FormUtils.getParsedAnswers(parsedQuestions, [info], t, userLanguage, downloadDocument)

      setAnswerToExpand({ answers: answers[0], questions: parsedQuestions })
    }
  }, [info])


  const hasInformationToShow = () => {
    const formsInDeal = deal.forms ? deal.forms.map((form) => form.form) : []
    const events = event.source_information.map((source) => source)
    const forms = events.filter((event) => formsInDeal.includes(event._id));
    return !!forms.length || event.show_general_information
  }

  const getSourceInformation = () => {
    const formsInDeal = deal?.forms?.map((form) => form.form) || []
    const events = event.source_information.map((source) => source)
    return (
      events.filter((event) => formsInDeal.includes(event._id)).map((source, index) => {
        return (
          <Card>
              <h5 className={`uppercase font-bold text-sm leading-none text-main-${Config.PLATFORM} truncate mb-1`}>
                {source.name}
              </h5>

              <div className={`flex justify-end mt-4 pt-2 border-t border-separator-${Config.PLATFORM}`}>
                <ButtonCardMain
                  onClick={() => setSelectedForm(source._id)}
                  text={t('see_answers')}
                  iconComponent={<FaEye className="inline-block w-6 h-3" />}
                />
              </div>
          </Card>
        )
      })
    )
  }

  const downloadEvaluation = async () => {
    return confirm({
      title: t('download_evaluation')
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true)
          await SupportService.downloadEvaluation(selectedJudge, deal, event)
        } catch (error) {
          console.log(error)
          addToast(t('error_occurred_downloading_evaluation'), {
            appearance: 'error',
            autoDismiss: true
          })
        } finally {
          setLoading(false)
        }
      }
    })
  }

  return (
    <>

      {showDealInfo && (
        <DealResumeSection
          deal={deal || {}}
          onClose={() => {
            setShowDealInfo(null)
          }}
          investorView={investorView}
          setInvestorView={setInvestorView}
        />
      )}
      {showNotes && (
        <NotesContainer
          zIndex="10"
          listTitle={t('annotations')}
          setFiltersButtons={false}
          setShowModal={setShowNotes}
          notes={notes}
          createNote={createNote}
          updateNote={updateNote}
          deleteNote={deleteNote}
          getNote={getNote}
          selectedNote={selectedNote}
          setSelectedNote={setSelectedNote}
          onClose={() => setShowNotes(false)}

        />
      )}
      {answerToExpand && (
        <FormAnswersSection
          answer={answerToExpand}
          onClose={() => {
            setInfo(null)
            setAnswerToExpand(null)
            setSelectedForm(null)
          }}
        />
      )}

      <div className='flex items-start'>
        <div className='flex-1'>
          <PageTitle
            title={`${t('startup')}: ${deal && deal.name
              ? deal.name
              : t('startup_name_not_provided')
            }`}
            showBack={true}
            onBackClick={() => {
              if (history.location.state && history.location.state.from) {
                history.push(
                  history.location.state.from,
                  history.location.state.pagination
                )
              } else {
                history.goBack()
              }
            }}
          />
        </div>
        {evaluation &&
          <ButtonSecondary
          iconComponent={<FaFileDownload className='inline-block mr-2' />}
          marginRight='2'
          text={t('download_evaluation')}
          onClick={downloadEvaluation}
        />
        }
        {section === 'documents' && !deal?.discarded && 
          <ButtonSecondary
          iconComponent={<FaPlus className='inline-block mr-2' />}
          marginRight='2'
          text={t('upload_document')}
          onClick={() => documentListRef?.current?.uploadDocument()}
        />
        }
          {!deal?.discarded && <ButtonSecondary
            onClick={() => setShowNotes(true)}
            text={t('annotations')}
            iconComponent={<TbNotes className="inline-block mr-3 w-4 h-4" />}
            />}
      </div>

      {(loading ||
        loadingGetNotes ||
        loadingCreateNote ||
        loadingGetNote ||
        loadingDeleteNote ||
        loadingUpdateNote) && <Loader />}
      <SectionHeader
        section={section}
        setSection={(section) => {
          setSection(section)
          setSelectedJudge(null)
        }}
        sectionKeys={['information', 'documents', 'evaluations']}
        sectionTitles={[t('information'), t('documents'), t('evaluations')]}
      />
      {section === 'information' && deal && hasInformationToShow() && event && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 my-10">

          {event.show_general_information && (
              <Card key={'general_information'}>
                  <h5 className={`uppercase font-bold text-sm leading-none text-main-${Config.PLATFORM} truncate mb-1`}>
                    {t('general_information')}
                  </h5>

                  <div className={`flex justify-end mt-4 pt-2 border-t border-separator-${Config.PLATFORM}`}>
                    <ButtonCardMain
                      onClick={() => setShowDealInfo(true)}
                      text={t('see_details')}
                      iconComponent={<FaEye className="inline-block w-6 h-3" />}
                    />
                  </div>
              </Card>
          )}
          {deal && getSourceInformation()}
        </div>
      )}

      {section === 'information' && deal && !hasInformationToShow() && (
          <div className='my-10'>
            <NoDataInfo title={t('shared_judge_no_information_to_show')} textOne={t('shared_judge_no_information_to_show_text_one')} />
        </div>
        )}
      {section === 'evaluations' &&
        (deal && deal.discarded ? (
          <div className='mt-10'>
            <NoDataInfo
              title={t('startup_discarded')}
              textOne={t('startup_discarded_description')}
            />
          </div>
        ) : !event.score_card ? (
          <div className='mt-10'>
            <NoDataInfo
              title={t('scorecard_not_found')}
              textOne={t('scorecard_not_found_description')}
            />
          </div>
        ) : (
          <>
            {judges.length !== 0 && (
              <div className="mt-6">
                <Select
                  label={<span className="inline-block ml-3">{t('select_judge')}</span>}
                  placeholder={selectedJudge ? selectedJudge.value : t('select_judge')}
                  onSelect={(value) => setSelectedJudge(value[0])}
                  items={judges.filter((judge) => judge.startups.includes(deal._id)).map((j) => ({
                    id: j._id,
                    value: `${j.judge} ${!j.user ? `(${t('external')})` : ''}`.trim(),
                    enabled: j.enabled,
                    user: j.user,
                    startups: j.startups
                  }))}
                />

              </div>
            )}
            {selectedJudge && !selectedJudge.startups.includes(deal._id) && (
              <div className='mt-10'>
              <NoDataInfo
                title={t('startup_not_assigned_to_this_judge')}
              />
            </div>
            )}

            {(!selectedJudge ||
              (selectedJudge && !isEvaluationOwner() && !evaluation && selectedJudge.startups.includes(deal._id))) && (
                <div className='mt-10'>
                  <NoDataInfo
                    title={t(
                      judges.length === 0
                        ? 'no_judges_assigned_to_event'
                        : !selectedJudge
                          ? 'select_judge_to_see_evaluation'
                          : 'evaluation_not_submitted'
                    )}
                    textOne={t(
                      judges.length === 0
                        ? 'no_judges_assigned_to_event_description'
                        : !selectedJudge
                          ? 'select_judge_to_see_evaluation_description'
                          : 'evaluation_not_submitted_description'
                    )}
                  />
                </div>
              )}

            {selectedJudge && evaluation && !showEvaluateForm && selectedJudge.startups.includes(deal._id) && (
              <>
                {!selectedJudge.enabled && <Alert style={'info'} text={t('judge_currently_disabled')} />}
                <JudgeStartupEvaluationView evaluation={evaluation} />
                {selectedJudge.enabled && selectedJudge.user && selectedJudge.user === user._id && (
                  <div className="flex justify-end mt-4 mb-6">
                    <ButtonMain
                      onClick={() => setShowEvaluateForm(true)}
                      text={t('update_evaluation')}
                      width={'full md:w-1/2'}
                    />
                  </div>
                )}
              </>
            )}

            {selectedJudge &&
              selectedJudge.startups.includes(deal._id) &&
              isEvaluationOwner() &&
              (!evaluation || showEvaluateForm) && (
                <>
                  <JudgeStartupEvaluationForm
                    scoreCard={event.score_card}
                    eventId={event._id}
                    judgeId={selectedJudge.id}
                    dealId={startupId}
                    onCancel={() => setShowEvaluateForm(false)}
                    showCancelButton={!!evaluation}
                    isUpdating={evaluation && showEvaluateForm}
                    afterSave={() => getEventStartupEvaluation()}
                    evaluationToUpdate={evaluation}
                    judgeEnabled={selectedJudge.enabled}
                  />
                </>
              )}
          </>
        ))}

      {section === 'documents' && (
        <DocumentList dealId={startupId} ref={documentListRef} />
      )}
    </>
  )
}

export default Application
