import { RoutesLinks } from 'components/routes-links'
import { CoverBackground } from 'components/ui/atoms/CoverBackground'
import { Loader } from 'components/ui/molecules/Loader'
import { Container } from 'components/ui/objects/Container'
import Lang from 'lang'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { ChecksOperations } from 'redux/checks'
import UserOperations from 'redux/user/user-operations'
import AuthService from 'services/auth-service'
import Form from './components/form'
import NotificationsOperations from 'redux/notifications/notifications-operations'
import RemindersOperations from 'redux/reminders/reminders-operations'

const Register = () => {
  const { t } = useTranslation()
  const [email, setEmail] = useState(null)
  const location = useLocation()

  const registerMode = location.pathname.split('/')[2]
  const history = useHistory()
  const handleRedirect = (url) => history.push(url)
  const queryParams = new URLSearchParams(location.search)
  const { addToast } = useToasts()
  const invitationCode = queryParams.get('code')
  const [invitationData, setInvitationData] = useState({})
  const [registered, setRegistered] = useState(false)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const checkInvitationCode = async () => {
      try {
        const result = await AuthService.checkInvitationCode(invitationCode)
        setLoading(true)
        setInvitationData({
          email: result.data.email,
          name: result.data.name,
          last_name: result.data.last_name,
          invited_by: result.data.invited_by.name,
          company_name: result.data.company_name
            ? result.data.company_name
            : null,
          role: result.data.role
        })
      } catch (error) {
        addToast(t('invalid_invitation_code'), {
          appearance: 'error',
          autoDismiss: true
        })
        handleRedirect(RoutesLinks.LOGIN_LINK)
      } finally {
        setLoading(false)
      }
    }

    if (registerMode === 'invitation' && !invitationCode) {
      handleRedirect(RoutesLinks.LOGIN_LINK)
    } else if (registerMode === 'invitation') {
      checkInvitationCode()
    }
  }, [])

  const setToken = (token) => dispatch(UserOperations.setToken({ token }))
  const setApiKey = (apiKey) => dispatch(UserOperations.setApiKey({ apiKey}))

  const setUserInfo = (data) => dispatch(UserOperations.setUserInfo({ data }))
  const setLoginEntries = (loginEntries) =>
    dispatch(UserOperations.setLoginEntries(loginEntries))
  const setRefreshToken = (refreshToken) =>
    dispatch(UserOperations.setRefreshToken({ refreshToken }))
  const setInvitations = (invitations) =>
    dispatch(NotificationsOperations.setInvitations(invitations))

    const setReminders = (reminders) =>
    dispatch(RemindersOperations.setReminders(reminders))

  const setTotalReminders = (total) =>
    dispatch(RemindersOperations.setTotalReminders(total))

  const handleSubmit = async ({
    email,
    name,
    last_name,
    password_register,
    password_confirmation,
    accept_terms,
    company_name
  }) => {
    try {
      setLoading(true)
      setEmail(email)
      let result = {}
      let response = null
      if (invitationCode && invitationData.role === 'founder') {
        response = await AuthService.registerInvitedFounder({
          password: password_register,
          password_confirmation,
          accept_terms,
          invitation_code: invitationCode,
          language: Lang.getCurrentLang().code
        })
        result = response.data
      } else if (invitationCode && invitationData.role === 'investor') {
        response = await AuthService.registerEmployee({
          password: password_register,
          password_confirmation,
          accept_terms,
          invitation_code: invitationCode,
          language: Lang.getCurrentLang().code
        })
        result = response.data
      } else if (registerMode === 'founder') {
        await AuthService.registerFounder({
          email,
          name,
          last_name,
          password: password_register,
          password_confirmation,
          accept_terms,
          language: Lang.getCurrentLang().code
        })
      } else if (registerMode === 'investor') {
        await AuthService.registerInvestor({
          email,
          name,
          last_name,
          password: password_register,
          password_confirmation,
          company_name,
          accept_terms,
          language: Lang.getCurrentLang().code
        })
      } else if (registerMode === 'professional') {
        await AuthService.registerProfessional({
          email,
          name,
          last_name,
          password: password_register,
          password_confirmation,
          accept_terms,
          language: Lang.getCurrentLang().code
        })
      }

      if (Object.keys(result).length) {
        dispatch(ChecksOperations.setStage('ACCOUNT_ACTIVATED'))

        setUserInfo(result.user)
        setToken(result.token)
        setApiKey(result.api_key)
        setLoginEntries(result.login_entries)
        setRefreshToken(result.refreshToken)
        setInvitations(result.invitations)
        if (result.reminders) {
          setReminders(result.reminders.reminders)
          setTotalReminders(result.reminders.total)
        }

      }
      setRegistered(true)
    } catch (error) {
      console.log(error)
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t('error_occurred'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    } finally {
      setLoading(false)
    }
  }

  if (registered && !invitationData.email) {
    return (
      <Redirect
        to={{
          pathname: RoutesLinks.REGISTER_RESEND_LINK,
          state: { email }
        }}
      />
    )
  } else if (registered && invitationData.email) {
    return (
      <Redirect
        to={{
          pathname: RoutesLinks.CHECKS_LINK
        }}
      />
    )
  } else {
    return (
      <Container variant="session">
        <Container>
          {(!invitationCode ||
            (invitationCode && Object.keys(invitationData).length !== 0)) && (
            <Form
              onSubmit={handleSubmit}
              mode={registerMode}
              invitationData={invitationData}
              loading={loading}
            />
          )}
          {invitationCode && !invitationData && <Loader />}
        </Container>

        <CoverBackground link={true}/>
      </Container>
    )
  }
}

export default Register
