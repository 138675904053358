import { useDispatch } from 'react-redux'
import AppOperations from '../redux/app/app-operations'

/**
 * Hook for getting message templates container
 * @returns {{openSendMode: function, openEditMode: function, close: function}}
 */
export function useMessageTemplatesContainer () {
  const dispatch = useDispatch()

  return {
    /**
     * @param sendTo {string} CometChat UID or GUID
     * @param category {string} - 'entrepreneur' or 'startup'
     * @param target {object} - target object
     */
    openSendMode: (sendTo, category, target) => {
      dispatch(AppOperations.setMessageTemplatesContainer({ show: true, meta: { sendTo, category, target }, editMode: false }))
    },
    openEditMode: () => {
      dispatch(AppOperations.setMessageTemplatesContainer({ show: true, meta: {}, editMode: true }))
    },
    close: () => {
      dispatch(AppOperations.setMessageTemplatesContainer({ show: false, meta: {}, editMode: false }))
    }
  }
}
