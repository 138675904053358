import { useEffect, useState } from 'react'
import { Button } from 'components/ui/atoms/Button'
import { useTranslation } from 'react-i18next'
import { SliderScore } from 'components/events/components/evaluation/socore-inputs/SliderScore'
import { LikertScore } from 'components/events/components/evaluation/socore-inputs/LikertScore'
import SupportService from 'services/support-service'
import { useToasts } from 'react-toast-notifications'
import { useSwal } from 'hooks/useSwal'
import { Loader } from 'components/ui/molecules/Loader'
import ScoreCardUtils from 'utils/score-card-utils'
import { Alert } from 'components/ui/molecules/Alert'
import Config from 'config'
import { PublicQuestionnaire } from 'components/public-questionnaire/PublicQuestionnaire'
import { useAsyncState } from 'hooks/useAsyncState'
import ReactTooltip from 'react-tooltip'
import FormUtils from 'utils/form-utils'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { FaComment } from 'react-icons/fa'
import CriteriaComment from './CriteriaComment'

export const JudgeStartupEvaluationForm = ({
  dealId,
  eventId,
  eventStartupId,
  judgeId,
  scoreCard,
  showCancelButton = false,
  onCancel,
  afterSave,
  isUpdating = false,
  evaluationToUpdate,
  judgeEnabled,
  shared
}) => {

  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { confirm } = useSwal()
  const [parents, setParents] = useState([])
  const [criteriaScores, setCriteriaScores] = useState({})
  const [loading, setLoading] = useState(false)
  const [answers, setAnswers] = useState(evaluationToUpdate && scoreCard?.questionnaire?.questions ? FormUtils.getInitialAnswers(evaluationToUpdate.answers, scoreCard.questionnaire.questions) : {})
  const [questionErrors, setQuestionErrors] = useAsyncState({})
  const [cbFinish, setCbFinish] = useState(false)
  const [resultCbFinish, setResultCbFinish] = useState(false)
  const [comments, setComments] = useState(evaluationToUpdate?.comments || {})
  const [showCommentModal, setShowCommentModal] = useState(false)
  const [criteriaToComment, setCriteriaToComment] = useState(null)

  useEffect(() => {
    setShowCommentModal(!!criteriaToComment)
  }, [criteriaToComment])

  const getCriteriaToEvaluate = (criteria) => {
    const childrenIds = criteria
      .filter((c) => c.parent)
      .map((children) => children._id)

    const parentIds = criteria
      .filter((c) => c.parent)
      .map((children) => children.parent)

    const mainCriteriaIds = criteria
      .filter((c) => !c.parent && !parentIds.includes(c._id))
      .map((c) => c._id)

    return [...childrenIds, ...mainCriteriaIds]
  }

  const getChildren = (parent) => {
    return ScoreCardUtils.getChildren(parent, scoreCard.criteria)
  }

  const hasChildren = (currentCriteria, isId = false) => {
    return ScoreCardUtils.hasChildren(currentCriteria, scoreCard.criteria, isId)
  }

  useEffect(() => {
    setTimeout(() => ReactTooltip.rebuild(), 500)
  }, [scoreCard?.questionnaire?.questions, questionErrors])

  const handleCriteria = (criteria) => {
    return (
      <div className="mt-4">
        {['scale_to_ten', 'scale_to_one_hundred'].includes(scoreCard.scale) && (
          <SliderScore
            key={criteria._id}
            max={scoreCard.scale === 'scale_to_ten' ? 10 : 100}
            value={criteriaScores[criteria._id] ? criteriaScores[criteria._id] : 0}
            showInput={true}
            onChange={(e) => {
              setCriteriaScores({
                ...criteriaScores,
                [criteria._id]: e
              })
            }}
          />
        )}

        {scoreCard.scale === 'likert_scale' && (
          <LikertScore
            identifier={criteria._id}
            value={criteriaScores[criteria._id]}
            onChange={(e) => {
              setCriteriaScores({
                ...criteriaScores,
                [criteria._id]: e
              })
            }}
          />
        )}
      </div>
    )
  }

  const submitEvaluation = () => {
    setLoading(true)
    let automaticGeneralScore = 0

    Object.keys(criteriaScores).forEach((key) => {
      const criteria = scoreCard.criteria.find((c) => c._id === key)
      const parent = criteria.parent
        ? scoreCard.criteria.find((c) => c._id === criteria.parent)
        : null

      automaticGeneralScore += parent
        ? criteriaScores[key] * (criteria.weigth / 100) * (parent.weigth / 100)
        : criteriaScores[key] * (criteria.weigth / 100)
    })


    const parsedAnswers = {}
    scoreCard?.questionnaire?.questions.forEach(question => {
      
      let answer = answers[question.question_id._id]
      if (question.question_id.type === 'questions_group') {
        if (answers[question.question_id._id]) {
          answers[question.question_id._id].forEach((q, index) => {
            if (q && (q).constructor.name === 'Object') {
              answers[question.question_id._id][index] = `${q._id}-|-${q.name}`
            } else {
              answers[question.question_id._id][index] = q
            }
          })
          answer = answers[question.question_id._id].join('|__|')
        } else {
          answer = [].join('|__|')
        }
      }
      if (question.question_id.type.includes('file')) {
        answer = answers[question.question_id._id] ? typeof answers[question.question_id._id] === 'string' ? answers[question.question_id._id] : `${answers[question.question_id._id]._id}-|-${answers[question.question_id._id].name}` : null
      }

      parsedAnswers[question.question_id._id] = `${answer || ''}`.trim()
    })

    return SupportService.evaluateStartupNew({
      deal: dealId,
      criterias_score: criteriaScores,
      general_score: Math.round(automaticGeneralScore * 100) / 100,
      score_card: scoreCard._id,
      judge: judgeId,
      answers: parsedAnswers,
      comments,
      event: eventId
    }, true)
      .then(() => {
        addToast(t('evaluation_submitted_successfully'), {
          appearance: 'success',
          autoDismiss: true
        })
        localStorage.removeItem('kiota-questionnaire')
        afterSave && afterSave()
      })
      .catch(() => {
        addToast(t('error_occurred_evaluating_startup'), {
          appearance: 'error',
          autoDismiss: true
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const confirmEvaluation = () => {
    confirm({
      text: t('confirm_evaluation_description')
    }).then((confirmed) => {
      if (confirmed) {
        submitEvaluation()
      } else {
        setCbFinish(false)
      }
    })
  }


  useEffect(() => {
    setResultCbFinish(false)
  }, [judgeId])

  useEffect(() => {
    if (resultCbFinish !== null) {
      setCbFinish(false)
    }
  }, [resultCbFinish])

  useEffect(() => {
    if ((!dealId && !eventId) || !scoreCard || !scoreCard.criteria) {
      return
    }

    setCriteriaScores(getCriteriaToEvaluate(
      scoreCard.criteria
    ).reduce((obj, id) => {
      if (isUpdating && evaluationToUpdate) {
        return { ...obj, [id]: evaluationToUpdate.criterias_score[id] }
      }

      return { ...obj, [id]: 0 }
    }, {}))

    setParents(ScoreCardUtils.getCriteriaParents(
      scoreCard.criteria
    ))
  }, [scoreCard, isUpdating, evaluationToUpdate])

  if ((!dealId && !eventId) || (!dealId && !eventStartupId) || !judgeId) {
    return null
  }

  if (!scoreCard || !scoreCard.criteria) {
    return (
      <Alert style={'warning'} text={t('scorecard_not_found_description')} />
    )
  }

  if (!judgeEnabled) {
    return (
      <Alert style={'warning'} text={t('judge_disabled')} />
    )
  }

  if (judgeEnabled) {
    return (
      <>
        {showCommentModal && (
          <CriteriaComment
            editable={true}
            initialValues={{ content: comments[criteriaToComment] }}
            showModal={showCommentModal}
            handleClose={() => setCriteriaToComment(null)}
            onSubmit={(comment) => {
              setComments({ ...comments, [criteriaToComment]: comment.content })
              setCriteriaToComment(null)
            }}
          />
        )}
        <div className="flex justify-center">
          <div className="flex flex-col w-full">
            <div className={`w-full flex justify-between border-b border-main-${Config.PLATFORM} my-4`}>
              <div>
                <h5 className={`text-main-${Config.PLATFORM} font-bold`}>{t('criteria')}</h5>
              </div>
            </div>
            <div className="mb-10 p-6">
              {scoreCard.criteria && parents.map((criteria) => (
                <div key={criteria._id}>
                  <div className="my-6">
                    <h3 className={`text-main-${Config.PLATFORM}`}>
                      {`${criteria.name} (${criteria.weigth}%)`}
                      <FaComment className={`inline ml-2 ${comments[criteria._id] ? 'text-main-000' : 'text-gray'} cursor-pointer`} onClick={() => setCriteriaToComment(criteria._id)}/>
                    </h3>
  
                    {(criteria.description && criteria.description !== '') && (
                      <small>{criteria.description}</small>
                    )}
                  </div>
  
                  {hasChildren(criteria) && (
                    getChildren(criteria).map((children) => (
                      <div key={children._id} className="mb-8 px-4">
                        <div className="text-sm font-semibold">
                          {`${children.name} (${children.weigth}%)`}
                          <FaComment className={`inline ml-2 ${comments[children._id] ? 'text-main-000' : 'text-gray'} cursor-pointer`} onClick={() => setCriteriaToComment(children._id)}/>
                        </div>
  
                        {(children.description && children.description !== '') && (
                          <small>{children.description}</small>
                        )}
  
                        {handleCriteria(children)}
                      </div>
                    ))
                  )}
  
                  {!hasChildren(criteria) && (
                    <div className="px-8">
                      {handleCriteria(criteria)}
                    </div>
                  )}
                </div>
              ))}
            </div>

            {scoreCard?.questionnaire?.questions.length && <div className="w-full my-4">
              <div className={`border-b border-main-${Config.PLATFORM}`}>
                <h5 className={`text-main-${Config.PLATFORM} font-bold`}>{t('questions')}</h5>
              </div>
              <div className="w-full">
                <PublicQuestionnaire
                  scoreCardId={scoreCard._id}
                  answers={answers}
                  setAnswers={setAnswers}
                  isDraft={false}
                  isPreview={false}
                  questionnaireId={scoreCard.questionnaire._id}
                  questions={scoreCard.questionnaire.questions}
                  visibilityMode={'single_page'}
                  languages={scoreCard.questionnaire.languages || ['en', 'es']}
                  questionErrors={questionErrors}
                  setQuestionErrors={setQuestionErrors}
                  cbFinish={cbFinish}
                  setResultCbFinish={setResultCbFinish}
                  confirmFinishForm={confirmEvaluation}
                  setLoading={setLoading}
                  shared={shared}
                />
              </div>
            </div>}

            <div className="flex flex-row mt-4 mb-6 justify-end">
              <div>
                {showCancelButton && (
                  <ButtonSecondary
                    onClick={() => onCancel && onCancel()}
                    text={t('cancel')}
                    marginRight={2}
                  />
                )}
              </div>
    
              {judgeEnabled && <div>
                <ButtonMain
                  onClick={scoreCard.questionnaire?.questions ? () => setCbFinish(true) : () => confirmEvaluation()}
                  text={isUpdating ? t('update') : t('confirm')}
                />
              </div>}
          </div>
        </div>
                </div>
        {loading && <Loader />}
      </>
    )
  }

  return null
}
