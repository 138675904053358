import { Card } from '../../ui/atoms/Card'
import { useTranslation } from 'react-i18next'
import { Alert } from '../../ui/molecules/Alert'
import { NoDataInfo } from '../../ui/atoms/NoDataInfo'

export const FormPreview = ({
  questionnaire,
  height = 500,
  showTitle = true,
  draft = false,
  forceShow = false
}) => {
  const { t } = useTranslation()
  

  const haveAnyQuestion = () => {
    if (!questionnaire) {
      return false
    }

    return (!!questionnaire.draft && questionnaire.draft?.questions?.length > 0) ||
      (questionnaire.questions && questionnaire.questions?.length > 0)
  }

  const canShowPreview = () => {
    if (!questionnaire) {
      return false
    }

    if (forceShow) {
      return true
    }

    return (questionnaire.published || questionnaire.draft) && haveAnyQuestion()
  }

  if (!questionnaire) {
    return null
  }

  return (
    <div className='my-3'>
      {showTitle && (
        <h3>{t('form_preview')}</h3>
      )}

      {questionnaire?.published && !questionnaire?.draft && (
        <div className='my-3'>
          <Alert style='info' text={t('form_published')} />
        </div>
      )}

      {!!questionnaire?.draft && (
        <div className='my-3'>
          <Alert style='info' text={t('form_draft')} />
        </div>
      )}

      {!haveAnyQuestion() && (
        <div className='my-3'>
          <Alert style='warning' text={t('form_have_no_questions')} />
        </div>
      )}

      {!canShowPreview() && (
        <Card wrapperClassName='mt-4'>
          <NoDataInfo title={t('form_preview_no_data')} textOne={t('form_preview_no_data_explanation')} />
        </Card>
      )}

      {canShowPreview() && (
        <div style={{ height }} className='w-full my-3'>
          <Card containerClassName='overflow-hidden p-0 h-full relative' wrapperClassName='h-full'>
            {draft && (
              <div className='capitalize absolute top-[45px] -rotate-45 py-2 w-[400px] left-[-150px] text-center font-semibold text-white bg-blueGray-800'>
                {t('draft')}
              </div>
            )}

            <iframe
              className='w-full h-full'
              src={`${location.origin}/public/forms/${questionnaire?._id}?${draft ? 'draft' : 'preview'}=true`}
            />
          </Card>
        </div>
      )}
    </div>
  )
}
