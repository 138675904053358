import AuthUtils from '../utils/auth-utils'
import Config from '../config'

const appID = Config.COMETCHAT_APP_ID
const appRegion = Config.COMETCHAT_APP_REGION
const authKey = Config.COMETCHAT_AUTH_KEY
const widgetId = Config.COMETCHAT_WIDGET_ID
const wrapperId = 'chat-wrapper'

export const ChatService = {

  isInitialized: () => {
    return window.CometChatWidget.CometChat.isInitialized()
  },

  initialize: async () => {
    return window.CometChatWidget.init({ appID, appRegion, authKey })
  },

  initializeWidget: () => {
    // close chat on click outside
    document.getElementById('root').addEventListener('click', ChatService.closeChat)

    return window.CometChatWidget.launch({
      widgetID: widgetId,
      target: `#${wrapperId}`,
      roundedCorners: false,
      defaultType: 'user'
    })
  },

  setChatLocalization: lang => {
    return window.CometChatWidget.localize(lang)
  },

  loginUser: async kiotaUser => {
    const chatUser = new window.CometChatWidget.CometChat.User(kiotaUser._id)
    chatUser.setName(`${kiotaUser.name || ''} ${kiotaUser.last_name || ''}`)

    const cometChatUser = await window.CometChatWidget.createOrUpdateUser(chatUser)
    return window.CometChatWidget.login(cometChatUser)
  },

  openChat: () => {
    document.dispatchEvent(new Event('chat-open'))
    document.getElementById(wrapperId)?.classList.add('opened')
  },

  closeChat: () => {
    document.dispatchEvent(new Event('chat-close'))
    document.getElementById(wrapperId)?.classList.remove('opened')
  },

  toggleChat: () => {
    document.dispatchEvent(new Event(`chat-${ChatService.isOpened() ? 'close' : 'open'}`))
    document.getElementById(wrapperId)?.classList.toggle('opened')
  },

  isOpened: () => {
    return document.getElementById(wrapperId)?.classList.contains('opened')
  },

  onMessageReceived: callback => {
    window.CometChatWidget.on('onMessageReceived', message => callback(message))
  },

  onChatOpened: callback => {
    document.addEventListener('chat-open', () => callback())
  },

  onChatClosed: callback => {
    document.addEventListener('chat-close', () => callback())
  },

  chatWithUser: userId => {
    window.CometChatWidget.chatWithUser(userId)
    ChatService.openChat()
  },

  chatWithGroup: groupId => {
    window.CometChatWidget.chatWithGroup(groupId)
    ChatService.openChat()
  },

  getProjectGroupId: (project, currentUser) => {
    return currentUser && AuthUtils.isInvestor(currentUser.role) ? `${project._id}_${currentUser.investor._id}` : project._id
  },

  sendMessage: (receiverUid, messageText, isGroup) => {
    const receiverType = isGroup
      ? window.CometChatWidget.CometChat.RECEIVER_TYPE.GROUP
      : window.CometChatWidget.CometChat.RECEIVER_TYPE.USER

    return window.CometChatWidget.CometChat.sendMessage(new window.CometChatWidget.CometChat.TextMessage(
      receiverUid,
      messageText,
      receiverType
    ))
  }
}
