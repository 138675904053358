import { useState, useEffect } from 'react'
import ProfessionalServices from 'services/professional-service'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import { Loader } from 'components/ui/molecules/Loader'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import { PageTitle } from 'components/ui/atoms/PageTitle'
import { ConnectedStartup } from './ConnectedStartup'
import { SectionHeader } from 'components/ui/molecules/SectionHeader'
import { RegisteredStartup } from './RegisteredStartup'
import { usePagination } from 'hooks/usePagination'
import { Pagination } from 'components/ui/atoms/Pagination'
import { StartupConnectionRequest } from './StartupConnectionRequest'
import useUser from 'hooks/useUser'
import { DealResumeSection } from 'components/dealflow/DealResumeSection'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { FaFilter } from 'react-icons/fa'
import { DealflowFilters } from 'components/dealflow/DealflowFilters'

export const Startups = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [connections, setConnections] = useState([])
  const [registeredStartups, setRegisteredStartups] = useState([])
  const [connectionRequests, setConnectionRequests] = useState([])
  const [loading, setLoading] = useState(false)
  const [section, setSection] = useState('connections')
  const SECTIONS = ['connections', 'connection_requests', 'registered_startups']
  const [dealToResume, setDealToResume] = useState(null)
  const [showFilters, setShowFilters] = useState(null)
  const [filters, setFilters] = useState({})

  const {
    setTotalPages,
    totalPages,
    setPage,
    page,
    rowsPerPage,
    setRowsPerPage
  } = usePagination(12)

  const getConnections = () => {
    setLoading(true)
    return ProfessionalServices.getConnectedStartups({ rowsPerPage, page }).then(result => {
        const { connections, pagination } = result.data
        setConnections(connections)
        setTotalPages(pagination.pages)
    }).catch(() => {
      addToast(t('error_occurred_retrieving_connections'), { appearance: 'error', autoDismiss: true })
    }).finally(() => {
      setLoading(false)
    }) 
  }

  const getRegisteredStartups = () => {
    setLoading(true)
    return ProfessionalServices.getRegisteredStartups({
      rowsPerPage,
      page,
      sortBy: '-createdAt',
      ...filters
    }).then(result => {
      const { startups, pagination } = result.data
      setRegisteredStartups(startups)
      setTotalPages(pagination.pages)
    }).catch(() => {
      addToast(t('error_occurred_retrieving_startups'), { appearance: 'error', autoDismiss: true })
    }).finally(() => {
      setLoading(false)
    })
  }

  const getStartupsConnectionRequests = () => {
    setLoading(true)
    return ProfessionalServices.getStartupsConnectionRequests({
      rowsPerPage,
      page
    }).then(result => {
      const { connection_requests, pagination } = result.data
      setConnectionRequests(connection_requests)
      setTotalPages(pagination.pages)
    }).catch(() => {
      addToast(t('error_occurred_retrieving_connection_requests'), { appearance: 'error', autoDismiss: true })
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    setPage(1)
    setRowsPerPage(12)

    if (section === 'connections') {
        getConnections()
    }

    if (section === 'registered_startups') {
      getRegisteredStartups()
    }

    if (section === 'connection_requests') {
        getStartupsConnectionRequests()
    }
  }, [section])

  useEffect(() => {
    if (section === 'registered_startups') {
        getRegisteredStartups()
    } else if (section === 'connections') {
        getConnections()
    } else if (section === 'connection_requests') {
        getStartupsConnectionRequests()
    }
  }, [page, rowsPerPage, filters])

  return (
    <>
    <div className="flex items-start">
        <div className="flex-1">
          <PageTitle title={t('startups')} showBack={false} />
        </div>
        {section === 'registered_startups' && <div data-tip={t('filter')}>
          <ButtonSecondary
            iconComponent={<FaFilter className='h-4'/>}
            onClick={() => {
              setShowFilters(true)
            }}
            />
        </div>}
    </div>

      {showFilters && (
        <DealflowFilters
          onClose={() => setShowFilters(false)}
          onFilter={setFilters}
          filters={filters}
          showTags={false}
          section={'professional_registered_startups'}
        />
      )}
      <SectionHeader
        sectionTitles={SECTIONS.map(section => t(section))}
        sectionKeys={SECTIONS}
        section={section}
        setSection={setSection}
      />

      {dealToResume && (
        <DealResumeSection
          investorView={false}
          deal={dealToResume || {}}
          onClose={() => {
            setDealToResume(null)
          }}
        />
      )}
      {section === 'connections' && (
          <>
          {connections.length !== 0 && (
            <>
              <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 my-6'>
                    {connections.map(connection => (
                    <ConnectedStartup
                        key={connection._id}
                        onManage={getConnections}
                        startup={connection.startup}
                        connection={connection}
                        setDealToResume={setDealToResume}
                    />
                    ))}
                </div>
                <Pagination
                    paginateOptions={[12, 24, 36, 48, 60]}
                    currentPage={page}
                    setCurrentPage={(page) => setPage(page)}
                    perPage={rowsPerPage}
                    pages={totalPages}
                    setPerPage={(value) => setRowsPerPage(value[0].id)}
                />
            </>)}

          {connections.length === 0 && (
                        <div className='my-10'>
            <NoDataInfo
              title={t('startups_connected_not_found')}
              textOne={t('connected_startups_professionals_not_found')}
            />
            </div>
          )}
        </>
      )}

      {section === 'registered_startups' && (
        <>
          {registeredStartups.length === 0 && (
            <div className='my-10'>
              <NoDataInfo
                title={t('registered_startups_not_found')}
                textOne={t('registered_startups_professionals_not_found')}
              />
            </div>
          )}
          {registeredStartups.length !== 0 && (
            <>
              <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 my-6'>
                {registeredStartups.map(startup => (
                  <RegisteredStartup
                    key={startup._id}
                    startup={startup}
                    onManage={getRegisteredStartups}
                    setDealToResume={setDealToResume}
                  />
                ))}
              </div>
                <Pagination
                    paginateOptions={[12, 24, 36, 48, 60]}
                    currentPage={page}
                    setCurrentPage={(page) => setPage(page)}
                    perPage={rowsPerPage}
                    pages={totalPages}
                    setPerPage={(value) => setRowsPerPage(value[0].id)}
                />
            </>
          )}
        </>
      )}

    {section === 'connection_requests' && (
        <>
          {connectionRequests.length === 0 && (
            <div className='my-10'>
              <NoDataInfo
                title={t('connection_requests_not_found')}
                textOne={t('connection_requests_professionals_not_found')}
              />
            </div>
          )}
          {connectionRequests.length !== 0 && (
            <>
              <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 my-6'>
                {connectionRequests.map(request => (
                  <StartupConnectionRequest
                    request={request}
                    key={request._id}
                    startup={request.startup}
                    onManage={getStartupsConnectionRequests}
                    setDealToResume={setDealToResume}
                  />
                ))}
              </div>
                <Pagination
                    paginateOptions={[12, 24, 36, 48, 60]}
                    currentPage={page}
                    setCurrentPage={(page) => setPage(page)}
                    perPage={rowsPerPage}
                    pages={totalPages}
                    setPerPage={(value) => setRowsPerPage(value[0].id)}
                />
            </>
          )}
        </>
      )}
      {loading && <Loader />}
    </>
  )
}
