import { useTranslation } from 'react-i18next'
import { SidePanel } from 'components/ui/objects/SidePanel'
import { useEffect } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import Config from 'config'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import Select from 'components/ui/atoms/Select'
import FailureReasons from 'assets/json/failure-reasons.json'
import { TextArea } from 'components/ui/atoms/TextArea'
import FormUtils from 'utils/form-utils'
import moment from 'moment'
import { Input } from 'components/ui/atoms/Input'

/**
 * @param onClose
 * @returns {JSX.Element}
 * @constructor
 */
const MarStartupAsFailedSection = ({ onClose, markStartupAsFailed }) => {
  const { t } = useTranslation()

  const failureReasons = FailureReasons.map((reason) => {
    return { id: reason.id, value: t(reason.key) }
  })

  const { register, handleSubmit, errors, setValue, trigger, watch } = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    reValidateMode: 'all',
    resolver: yupResolver(Yup.object().shape({
        failure_reason: Yup.string().required(t('required_field')),
        official_failure_date: Yup.string()
        .required(t('required_field'))
        .transform((value) => FormUtils.parseDateFromString(value, 'YYYY-MM-DD HH:mm')),
        comments: Yup.string().nullable()
      }), { abortEarly: false }),
  })

  useEffect(() => {
    register('failure_reason')
  }, [register])

  useEffect(() => {
    setValue('failure_reason', FailureReasons[0].id)
    setValue('official_failure_date', moment().format('YYYY-MM-DD'))
    trigger()
  }, [])

  return (
    <>
      <SidePanel
        width={'1/4'}
        onClose={onClose}
        title={t('mark_as_failed')}
      >
        <p className={`font-medium mb-6 text-sm text-main-${Config.PLATFORM} border-b border-main-${Config.PLATFORM}`}>
          {t('failed_reason_title')}
        </p>

        <div className="mt-4 flex flex-col w-full">
          <form className="w-full flex flex-col" onSubmit={handleSubmit(markStartupAsFailed)}>
            <div className="w-full mr-4">
            <Select
              isClearable={false}
              items={failureReasons}
              initialValues={
                watch('failure_reason')
                  ? failureReasons
                    .filter(item => item.id === watch('failure_reason'))
                  : []
              }
              onSelect={selected => {
                setValue('failure_reason', selected.length ? selected[0].id : null)
                trigger()
              }}
              id="failure_reason"
              error={errors.failure_reason}
              name="failure_reason"
              reference={register}
            />
            </div>
            <div className='mt-2 w-full'>
                <Input
                reference={register}
                name="official_failure_date"
                placeholder={t('official_failure_date')}
                label={t('official_failure_date')}
                type="date"
                error={errors.official_failure_date}
                />
            </div>
            <div className="mt-2 w-full">
                <TextArea
                reference={register}
                name="comments"
                error={errors.comments}
                label={t('rejected_reasons')}
                maxLength={200}
                placeholder={t('comments')}
                />
            </div>
            <div className={`flex justify-end mt-2 pt-2 border-t border-separator-${Config.PLATFORM}`}>
              <ButtonMain
                type={'submit'}
                text={t('mark_as_failed')}
              />
            </div>
          </form>
        </div>
      </SidePanel>
    </>
  )
}

export default MarStartupAsFailedSection
