import { TeammateCard } from 'components/ui/molecules/TeammateCard'
import { forwardRef, useEffect, useState, useImperativeHandle } from 'react'
import { useToasts } from 'react-toast-notifications'
import UsersService from 'services/users-service'
import { useSwal } from 'hooks/useSwal'
import { useTranslation } from 'react-i18next'
import TeammateSection from './TeammateSection'

export const Team = forwardRef(({}, ref) => {
  const [teammates, setTeammates] = useState([])
  const [teammate, setTeammate] = useState(null)
  const [loading, setLoading] = useState(false)
  const { addToast } = useToasts()
  const { t } = useTranslation()
  const { confirm } = useSwal()
  const [showTeammateSection, setShowTeammateSection] = useState(false)

  useImperativeHandle(ref, () => ({
    showTeammateSection: () => setShowTeammateSection(true)
  }))

  const getTeammates = async () => {
    try {
      setLoading(true)
      const result = await UsersService.getEmployees()
      setTeammates(result.data)
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t('error_occurred'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const saveTeammate = async (values) => {
    try {
      setLoading(true)
      if (!teammate) {
        await UsersService.createEmployee(values)
        addToast(t('teammate_created'), {
          appearance: 'success',
          autoDismiss: true
        })
      } else {
        await UsersService.updateEmployee(
          teammate._id,
          values.name,
          values.last_name,
          values.email,
        )
        addToast(t('teammate_updated'), {
          appearance: 'success',
          autoDismiss: true
        })
      }
      setShowTeammateSection(false)
      setTeammate(null)
      getTeammates()
    } catch (error) {
      console.log(error)
      addToast(
        error.response
          ? error.response.data.msg_key
            ? t(error.response.data.msg_key)
            : t(teammate ? 'error_occurred_updating_teammate' : 'error_occurred_creating_teammate')
          : t(teammate ? 'error_occurred_updating_teammate' : 'error_occurred_creating_teammate'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    } finally {
      setLoading(false)
    }
  }

  const removeTeammate = (teammate) => {
    confirm({
      text: t('revert_notification'),
      icon: 'warning'
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true)
          await UsersService.removeEmployee(teammate._id)
          addToast(t('teammate_deleted_successfully'), {
            appearance: 'success',
            autoDismiss: true
          })
          setTeammate(null)
          setShowTeammateSection(false)
          getTeammates()
        } catch (error) {
          addToast(
            error.response && error.response.data.msg_key
              ? t(error.response.data.msg_key)
              : t('error_occurred_deleting_teammate'),
            {
              appearance: 'error',
              autoDismiss: true
            }
          )
        } finally {
          setLoading(false)
        }
      }
    })
  }

  useEffect(() => {
    getTeammates()
  }, [])

  const editTeammate = (teammate) => {
    setTeammate(teammate)
    setShowTeammateSection(true)
  }

  return (
    <>
      {showTeammateSection && (
        <TeammateSection
          onClose={() => {
            setShowTeammateSection(false)
            setTeammate(null)
          }}
          teammate={teammate}
          onSubmit={saveTeammate}
          removeTeammate={removeTeammate}
        />
      )}
      <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-4 my-10">
        {teammates.map((teammate, index) => {
          return (
            <TeammateCard
              key={index}
              teammate={teammate}
              editTeammate={editTeammate}
            />
          )
        })}
      </div>
    </>
  )
})
