import { useEffect, useState } from 'react'
import { Modal } from 'components/ui/molecules/Modal'
import { Autocomplete } from 'components/ui/atoms/Autocomplete'
import { useTranslation } from 'react-i18next'
import { DealflowService } from 'services/dealflow-service'
import { Spinner } from 'components/ui/atoms/Spinner'
import Config from 'config';
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

const NewCardModal = ({ showModal, handleCloseModal, onSubmit }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [dealflow, setDealflow] = useState([])
  const [selectedDealflow, setSelectedDealflow] = useState(null)
  const [errors, setErrors] = useState({
    dealflow: null,
    event: null,
    event_startup: null
  })

  const getDealflow = () => {
    return DealflowService.getDealflow({
      rowsPerPage: 999999,
      pool: 'true',
      populate: 'false',
      boards: 'false',
      milestones: 'false',
      files: 'false',
      reminders: 'false'
    }).then((response) => {
      setDealflow(response.data.dealflow)
    })
  }

  const onSelect = (value, setter, errorKey) => {
    setter(value)
    updateErrors(errorKey, value)
  }

  const updateErrors = (errorKey, value) => {
    const error = { message: t('required_field') }
    const errors = {
      dealflow: !selectedDealflow ? error : null,
      [errorKey]: errorKey === 'dealflow'
          ? !value
              ? error
              : null
          : null
    }
    setErrors(errors)
    return !errors.dealflow && !errors.event && !errors.event_startup
  }

  const submit = () => {
    const isValid = updateErrors()

    if (isValid) {
      handleCloseModal()
      onSubmit({ dealflow: selectedDealflow })
    }
  }

  useEffect(() => {
    Promise.all([getDealflow()]).finally(() => setLoading(false))
  }, [])

  return (
    <Modal showModal={showModal} paddingBottom="3" fixedWidth="w-6/12" showCloseModal={true} onClose={handleCloseModal}>
        <h2 className={`font-semibold text-xl mr-4  text-main-${Config.PLATFORM}`}>
          {t('add_startup')}
        </h2>

      {loading && (
        <div className="text-center">
          <Spinner color="rgb(77 112 179)" />
        </div>
      )}

      {!loading && (
        <>
            <div className="my-3">
              <Autocomplete
                labelColor={selectedDealflow ? 'text-black' : 'text-red'}
                name="startup"
                placeholder={t('search_startup')}
                isClearable={true}
                required={true}
                label={t('startup')}
                options={dealflow}
                onSelect={(value) => onSelect(value?._id || null, setSelectedDealflow, 'dealflow')}
                error={errors.dealflow}
              />
            </div>
        </>
      )}

<div className={`flex justify-end mt-4 border-t pt-2 border-separator-${Config.PLATFORM}`}>
        <ButtonMain
          type="submit"
          onClick={() => submit()}
          text={t('add_startup')}
          disabled={loading}
        />
      </div>
    </Modal>
  )
}

export default NewCardModal
