import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Like from 'styles/images/like.svg'
import ContentLoader from 'react-content-loader'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import { useToasts } from 'react-toast-notifications'
import BarChart from 'components/ui/molecules/charts/BarChart'
import StarIcon from 'styles/images/star.svg'
import SupportService from 'services/support-service'
import InvestmentsEvolutionUtil from 'utils/investments-evolution-utils'
import { Card } from 'components/ui/atoms/Card'
import Config from 'config';
import Utils from 'utils/utils'

import { AiOutlineReload, BiLike, FaExclamationTriangle } from 'react-icons/all'
import { CircularChard } from 'components/ui/molecules/charts/CircularChart'
import { ResponsiveSwarmPlot } from '@nivo/swarmplot'
import moment from 'moment'
import { Button } from 'components/ui/atoms/Button'
import KiotaGammaEvaluationPannel from 'components/kiota-gamma/KiotaGammaEvaluationPannel'
import ReactTooltip from 'react-tooltip'
import { RobustnessComparisonItemGraph } from './RobustnessComparisonItemGraph'
import { Alert } from 'components/ui/molecules/Alert'
import { Loader } from 'components/ui/molecules/Loader'
import IntegrationsService from 'services/integrations-service'
import InvestmentStages from 'assets/json/investment-stages.json'
import Sectors from 'assets/json/sectors.json'
import Countries from 'assets/json/countries.json'
import { useAuth } from 'hooks/useAuth'

export const KiotaGammaEvaluation = forwardRef(({ evaluation, getKiotaGammaEvaluation, deal }, ref) => {
    const { t } = useTranslation()
    const [showExecutionPannel, setShowExecutionPannel] = useState(false)
    const [loading, setLoading] = useState(false)
    const { addToast } = useToasts()

    const {apiKey} = useAuth()

    useEffect(() => {
        ReactTooltip.rebuild()
      }, [])


      const evaluateStartup = async (values) => {
        try {
            console.log(values)
            setLoading(true)
            const parsedValues = {
                ...values,
                dealflow: deal._id
            }
            parsedValues.funding_type = InvestmentStages.find((is) => is._id === parsedValues.funding_type).translation_en;
            parsedValues.industries = Sectors.find((sector) => sector._id === parsedValues.industries).translation_en;

            if (!parsedValues.last_investment_round_announced_date) {
                parsedValues.last_investment_round_announced_date = moment().format('YYYY-MM-DD')
            }
            if (parsedValues.incorporation_country) {
                parsedValues.incorporation_country = Countries.find((country) => country._id === parsedValues.incorporation_country).translation_en;
            } else {
                parsedValues.incorporation_country = ""
            }

            parsedValues.funding_rounds = parsedValues.funding_rounds !== "" ? parsedValues.funding_rounds : 0;
            parsedValues.last_investment_round_equity_only_funding = parsedValues.last_investment_round_equity_only_funding === 'yes';
            parsedValues.last_investment_round_investors = parsedValues.last_investment_round_investors !== "" ? parsedValues.last_investment_round_investors : 0;
            parsedValues.last_investment_round_lead_investors = parsedValues.last_investment_round_lead_investors !== "" ? parsedValues.last_investment_round_lead_investors : 0;
            parsedValues.last_investment_round_money_raised= parsedValues.last_investment_round_money_raised !== "" ? parsedValues.last_investment_round_money_raised : 0;
            parsedValues.last_investment_round_partner_investors = parsedValues.last_investment_round_partner_investors !== "" ? parsedValues.last_investment_round_partner_investors: 0;
            parsedValues.last_investment_round_pre_money_valuation = parsedValues.last_investment_round_pre_money_valuation !== "" ? parsedValues.last_investment_round_pre_money_valuation: 0;
            parsedValues.total_funding_amount = parsedValues.total_funding_amount !== "" ? parsedValues.total_funding_amount: 0;

            await IntegrationsService.executeKiotaGammaEvaluation(parsedValues, apiKey)
            getKiotaGammaEvaluation()
        } catch (error) {
            console.log(error)
            addToast(t('error_occurred_evaluating_startup'), { appearance: 'error', autoDismiss: true })
        } finally {
            setLoading(false)
        }
      }

      useImperativeHandle(ref, () => ({
        showRequestEvaluationSection: () => setShowExecutionPannel(true)
      }))

    return (
        <>
            {loading && <Loader />}
            {showExecutionPannel && (
                <KiotaGammaEvaluationPannel onClose={() => {
                        setShowExecutionPannel(false)
                    }}
                    expandedInformation={{
                        organization_name: deal?.name || evaluation?.startup || '',
                        industries: deal?.sector?._id,
                        incorporation_country: deal?.incorporation_country?._id,
                        funding_type: deal?.investment_stage?._id
                    }}
                    dealflow={true}
                    onSubmit={evaluateStartup}
                />
            )}
            <div className={'w-full flex flex-col my-2'}>
                {evaluation && (!evaluation.general_result || !evaluation.growth_robustness_comparison || !evaluation.survival_robustness_comparison || !evaluation.pre_valuation_robustness_comparison) && (
                    <Alert style={'warning'} text={t('startup_under_analysis')} />
                )}
                <div className={'flex w-full px-4 mb-8'}>
                </div>
                <div className='flex'>
                <div className='w-full lg:w-1/2 border-r border-gray-lines flex items-center flex-col px-2'>
                <div className="font-semibold text-lg mb-2 w-full border-b border-gray-lines flex justify-between">
                    <div className='flex items-center'>
                        <span className={`text-main-${Config.PLATFORM}`}>{t('overview')}</span>
                        {(!evaluation || !evaluation?.general_result || evaluation.general_error) && <FaExclamationTriangle className={`ml-2 ${!evaluation ? `text-main-${Config.PLATFORM}` : evaluation?.general_error ? 'text-red' : 'text-orange-400'}`} data-tip={t(!evaluation ? 'pending_evaluation' : evaluation.general_error ? 'evaluation_could_not_be_completed' : 'pending_evaluation_result')} />}
                    </div>
                    {evaluation && !evaluation?.general_result && <div data-tip={t('reload')}>
                        <Button
                            iconComponent={<AiOutlineReload />}
                            className='m-0 text-xs'
                            horizontal='1'
                            width='auto'
                            verticalMargin='1'
                            vertical="1"
                            marginRight='2'
                            onClick={getKiotaGammaEvaluation}
                            />
                        </div>}
                </div>
                <div className='w-full text-center'>
                    <Card>
                    <div className='flex justify-center flex-col justify-center items-center'>
                        <div
                        className="w-28 shadow-hover rounded-full"
                        id={'global-score'}
                        >
                        <CircularChard
                            width="15"
                            fontSize="2xl"
                            value={evaluation?.general_result ? Math.round(evaluation.general_result.general_score * 100) : 0}
                            valueText={evaluation?.general_result ? `${Math.round(evaluation.general_result.general_score * 100)}%` : '-'}
                        />
                        </div>
                    </div>
                        <div className={'mt-2'}>{t('general_score')}</div>
                    <div className='flex justify-center flex-col justify-center items-center'>

                    <div
                    className="w-28 shadow-hover rounded-full mt-4"
                    id={'global-score'}
                    >
                    <CircularChard
                        width="15"
                        fontSize="2xl"
                        value={evaluation?.general_result ? Math.round(evaluation.general_result.survival_score * 100) : 0}
                        valueText={evaluation?.general_result ? `${Math.round(evaluation.general_result.survival_score * 100)}%` : '-'}
                    />
                    </div>
                    </div>
                    <div className={'mt-2'}>{t('survival_score')}</div>
                    <div className='flex justify-center flex-col justify-center items-center'>
                    
                    <div
                    className="w-28 shadow-hover rounded-full mt-4"
                    id={'growth-score'}
                    >
                    <CircularChard
                        width="15"
                        fontSize="2xl"
                        value={evaluation?.general_result ? Math.round(evaluation.general_result.growth_score * 100) : 0}
                        valueText={evaluation?.general_result ? `${Math.round(evaluation.general_result.growth_score * 100)}%` : '-'}
                    />
                    </div>
                    </div>
                    <div className={'mt-2'}>{t('growth_score')}</div>
                    <div className={'mt-5 text-3xl'}>{evaluation?.general_result ? `${Utils.moneyFormatter(evaluation.general_result.pre_money_valuation)} $` : '-'}</div>
                    <div className={'mt-2'}>{t('pre_valuation')}</div>
                    </Card>
                </div>
                </div>

                <div className='w-full lg:w-1/2 flex flex-col px-2'>
                <div className={`font-semibold text-lg mb-2 w-full text-main-${Config.PLATFORM} border-b border-gray-lines flex justify-between`}>
                    <div className='flex items-center'>
                        {t('robustness_comparison')}
                        {(!evaluation || !evaluation?.growth_robustness_comparison || !evaluation.survival_robustness_comparison || !evaluation.pre_valuation_robustness_comparison || evaluation.growth_robustness_comparison_error || evaluation.survival_robustness_comparison_error || evaluation.pre_valuatio_robustness_comparison_error) && <FaExclamationTriangle className={`ml-2 ${!evaluation ? `text-main-${Config.PLATFORM}` : (evaluation?.growth_robustness_comparison_error || evaluation?.survival_robustness_comparison_error || evaluation?.pre_valuation_robustness_comparison_error) ? 'text-red' : 'text-orange-400'}`} data-tip={t(!evaluation ? 'pending_evaluation' : evaluation.general_error ? 'evaluation_could_not_be_completed' : 'pending_evaluation_result')} />}
                    </div>
                    <div>
                    {evaluation && (!evaluation?.growth_robustness_comparison || !evaluation.survival_robustness_comparison || !evaluation.pre_valuation_robustness_comparison) && (<div data-tip={t('reload')}>
                        <Button
                            iconComponent={<AiOutlineReload />}
                            className='m-0 text-xs'
                            horizontal='1'
                            width='auto'
                            verticalMargin='1'
                            vertical="1"
                            marginRight='2'
                            onClick={getKiotaGammaEvaluation}
                            />
                        </div>)}
                    </div>
                </div>
                    <div className="mx-4">
                        <div className='my-4'>
                            <Card>
                                <RobustnessComparisonItemGraph
                                    title={t('survival_score')}
                                    description={t('survival_prediction_description')}
                                    group={'survival'}
                                    dataToPlot={evaluation?.survival_robustness_comparison}
                                    hasEvaluation={!!evaluation}
                                    error={evaluation?.survival_robustness_comparison_error}
                                />
                            </Card>
                        </div>
                        <div className='my-4'>
                            <Card>
                                <RobustnessComparisonItemGraph
                                    title={t('growth_score')}
                                    description={t('growth_prediction_description')}
                                    group={'growth'}
                                    dataToPlot={evaluation?.growth_robustness_comparison}
                                    hasEvaluation={!!evaluation}
                                    error={evaluation?.growth_robustness_comparison_error}
                                />
                            </Card>
                        </div>
                        <div className='my-4'>
                            <Card>
                                <RobustnessComparisonItemGraph
                                    title={t('pre_money_valuation')}
                                    description={t('pre_valuation_prediction_description')}
                                    group={'pre_valuation'}
                                    dataToPlot={evaluation?.pre_valuation_robustness_comparison}
                                    hasEvaluation={!!evaluation}
                                    error={evaluation?.pre_valuation_robustness_comparison_error}
                                />
                            </Card>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
})
