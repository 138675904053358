import axios from 'axios/index'
import Config from '../config'

const PreferencesService = {
  updatePreferences: async (data) => {
    return axios.post(`${Config.USERS_API_URL}preferences`, data)
  },

  updateInvestorPreferences: async (data) => {
    return axios.post(`${Config.USERS_API_URL}investor/preferences`, data)
  },

  updateInvestorInvestmentThesis: async (data) => {
    return axios.post(
      `${Config.USERS_API_URL}investor/investment-thesis`,
      data
    )
  }
}
export default PreferencesService
