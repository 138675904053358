import axios from 'axios/index'
import Config from '../config'

const ProfessionalService = {
  getRegisteredStartups: async (params) => {
    return axios.get(`${Config.SUPPORT_API_URL}professionals/startups/explore`, { params })
  },

  getRegisteredProfessionals: async (params) => {
    return axios.get(`${Config.SUPPORT_API_URL}professionals/professionals/explore`, { params })
  },

  getConnectedStartups: async (params) => {
    return axios.get(`${Config.SUPPORT_API_URL}professionals/startups/connections`, { params })
  },

  getStartupsConnectionRequests: async (params) => {
    return axios.get(`${Config.SUPPORT_API_URL}professionals/startups/connection-requests`, { params })
  },

  getProfessionalsConnectionRequests: async (params) => {
    return axios.get(`${Config.SUPPORT_API_URL}professionals/professionals/connection-requests`, { params })
  },

  requestConnectionToStartup: async (startupId) => {
    return axios.post(`${Config.SUPPORT_API_URL}professionals/startups/${startupId}/request-connection`)
  },

  requestConnectionToProfessional: async (professionalId, startupId) => {
    return axios.post(`${Config.SUPPORT_API_URL}professionals/professionals/${professionalId}/request-connection?startup=${startupId}`, {})
  },

  acceptConnectionRequest: async (connectionId) => {
    return axios.post(`${Config.SUPPORT_API_URL}professionals/connection-requests/${connectionId}/accept`)
  },

  rejectConnectionRequest: async (connectionId) => {
    return axios.post(`${Config.SUPPORT_API_URL}professionals/connection-requests/${connectionId}/reject`)
  },

  deleteConnection: async (connectionId) => {
    return axios.delete(`${Config.SUPPORT_API_URL}professionals/connections/${connectionId}`)
  },

  getConnectedProfessionals: async (params) => {
    return axios.get(`${Config.SUPPORT_API_URL}professionals/professionals/connections`, { params })
  }
}
export default ProfessionalService;
