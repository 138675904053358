import axios from 'axios/index'
import Config from '../config'

const TagsService = {
  createTag: async (category, name, color) => {
    return axios.post(`${Config.SUPPORT_API_URL}tags`, { category, name, color })
  },

  getTags: async (category) => {
    if (category) {
      return axios.get(`${Config.SUPPORT_API_URL}tags?category=${category}`)
    } else {
      return axios.get(`${Config.SUPPORT_API_URL}tags`)
    }
  },

  deleteTag: async (tagId) => {
    return axios.delete(`${Config.SUPPORT_API_URL}tags/${tagId}`)
  },

  updateTag: async (tagId, data) => {
    return axios.put(`${Config.SUPPORT_API_URL}tags/${tagId}`, data)
  }
}
export default TagsService
