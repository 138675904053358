import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../ui/atoms/Button'
import Logo from '../../../../styles/images/logo_light.svg'

import DataRoomVidio from 'styles/images/vidio-dataroom.svg'
import Evalutaion from 'styles/images/onboarding-evaluate.svg'
import Manage from 'styles/images/onboarding-manage.svg'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

const SecondStep = ({ nextStep, previousStep }) => {
  const { t } = useTranslation()

  return (
    <div className="bg-bgOnboardingOne bg-cover bg-no-repeat bg-center min-w-screen min-h-screen relative flex justify-center align-center h-full">
      <div className="w-full flex items-center justify-between pr-10 fixed top-0">
        <img src={Logo} alt="Logo" className="mx-6 my-4" />
        <div>
          <button
            className="text-coolGray-500 font-medium outline-none focus:outline-none shadow-soft-white hover:shadow-inner rounded-2xl p-2 text-xs bg-transparence-blue"
            onClick={previousStep}
            type="submit"
          >
            {t('go_back')}
          </button>
        </div>
      </div>

      <div className="pt-8 px-4 | text-center | min-h-screen">
        <div className="flex flex-col justify-center items-center h-full">
          <h1 className="w-full text-xl sm:text-3xl text-black mb-10 font-medium max-w-lg">
            {t('onboarding_secondstep_title')}
          </h1>
          <div className="px-10">
            <div className="flex flex-col sm:flex-row mb-6 sm:mb-10">
              <div className="flex items-start sm:items-center relative w-auto mr-6">
                <img
                  src={Manage}
                  alt="Test"
                  className="w-12 sm:w-16 rounded-full shadow-hover"
                />
              </div>
              <div className="text-left  max-w-sm">
                <p className="text-xs sm:text-sm font-medium">
                  {t('manage_project')}
                </p>
                <p className="text-xxs sm:text-xs text-gray-dark">
                  {t('manage_description')}
                </p>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row mb-6 sm:mb-10">
              <div className="flex items-start sm:items-center relative w-auto mr-6">
                <img
                  src={DataRoomVidio}
                  alt="Test"
                  className="w-12 sm:w-16 rounded-full shadow-hover"
                />
              </div>
              <div className="text-left max-w-sm">
                <p className="text-xs sm:text-sm font-medium">
                  {t('data_rooms')}
                </p>
                <p className="text-xxs sm:text-xs text-gray-dark">
                  {t('data_description')}
                </p>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row mb-6 sm:mb-14">
              <div className="flex items-start sm:items-center relative w-auto mr-6">
                <img
                  src={Evalutaion}
                  alt="Test"
                  className="w-12 sm:w-16 rounded-full shadow-hover"
                />
              </div>
              <div className="text-left max-w-sm">
                <p className="text-xs sm:text-sm font-medium">
                  {t('evaluate_project')}
                </p>
                <p className="text-xxs sm:text-xs text-gray-dark">
                  {t('evaluation_description')}
                </p>
              </div>
            </div>
          </div>
          <ButtonMain
            onClick={nextStep}
            type="submit"
            text={t('continue')}
          />
        </div>
      </div>
    </div>
  )
}

export default SecondStep
