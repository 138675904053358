import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Like from 'styles/images/like.svg'
import ContentLoader from 'react-content-loader'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import { useToasts } from 'react-toast-notifications'
import BarChart from 'components/ui/molecules/charts/BarChart'
import StarIcon from 'styles/images/star.svg'
import SupportService from 'services/support-service'
import InvestmentsEvolutionUtil from 'utils/investments-evolution-utils'
import { Card } from 'components/ui/atoms/Card'
import Config from 'config';

import { BiLike } from 'react-icons/all'

export const AugmentedInformation = ({ startup }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const [
    investmentsEvolutionAnnually,
    setInvestmentsEvolutionAnnually
  ] = useState(true)

  const [investmentsEvolution, setInvestmentsEvolution] = useState({
    annually: [],
    monthly: []
  })

  const [loading, setLoading] = useState(true)

  const [fundingRounds, setFundingRounds] = useState([])
  const [news, setNews] = useState([])

  if (!startup) {
    return null
  }

  useEffect(() => {
    const getAugmentedInformation = async () => {
      try {
        setLoading(true)

        const queryParams = {}
        if (startup.sector) {
          queryParams.sector = startup.sector._id
        }
        if (startup.investment_stage) {
          queryParams.investment_stage = startup.investment_stage._id
        }
        if (startup.country_incorporation) {
          queryParams.country = startup.country_incorporation._id
        }
        const result = await SupportService.getAugmentedInformation(
          queryParams
        )

        setFundingRounds(result.data.data.funding_rounds)
        setNews(result.data.data.news)
        setLoading(false)
      } catch (error) {
        addToast(t('error_occurred_retrieving_information'), {
          appearance: 'error',
          autoDismiss: true
        })
      }
    }
    getAugmentedInformation()
    const investments = InvestmentsEvolutionUtil.getEvolution(
      startup?.country_incorporation?._id,
      startup?.sector?._id,
      startup?.investment_stage?._id
    )

    if (
      investmentsEvolutionAnnually &&
      !investments.annually.length &&
      investments.monthly.length
    ) {
      setInvestmentsEvolutionAnnually(false)
    } else if (
      !investmentsEvolutionAnnually &&
      !investments.monthly.length &&
      investments.annually.length
    ) {
      setInvestmentsEvolutionAnnually(true)
    }

    setInvestmentsEvolution(investments)
  }, [])

  return (
    <div className="mx-4 my-6">
      <div className='flex'>
        <div className="w-3/4 pr-4">
          <Card>
            <p className='text-xl font-semibold my-2'>{t('investments_evolution')}</p>
              <div className="p-2 flex justify-end text-xxs">
                <div
                  onClick={() => setInvestmentsEvolutionAnnually(true)}
                  className={`shadow-soft-white rounded-l-2xl p-1 hover:shadow-inner hover:bg-transparence-blue cursor-pointer ${
                    investmentsEvolutionAnnually
                      ? 'bg-transparence-blue'
                      : 'bg-white'
                  }`}
                >
                  {t('investments_evolution_annually')}
                </div>
                <div
                  onClick={() => setInvestmentsEvolutionAnnually(false)}
                  className={`shadow-soft-white rounded-r-2xl p-1 hover:shadow-inner hover:bg-transparence-blue cursor-pointer ${
                    investmentsEvolutionAnnually
                      ? 'bg-white'
                      : 'bg-transparence-blue'
                  }`}
                >
                  {t('investments_evolution_monthly')}
                </div>
              </div>
              {investmentsEvolution[
                investmentsEvolutionAnnually ? 'annually' : 'monthly'
              ].length !== 0 && (
                <div className="w-full h-80">
                  <BarChart
                    keys={[t('investments')]}
                    data={investmentsEvolution[
                      investmentsEvolutionAnnually ? 'annually' : 'monthly'
                    ].map((item) => {
                      return {
                        [t('investments')]: item.money_raised,
                        indexed: item.date
                      }
                    })}
                    axisRotation={investmentsEvolutionAnnually ? 0 : 45}
                  />
                </div>
              )}
              {investmentsEvolution[
                investmentsEvolutionAnnually ? 'annually' : 'monthly'
              ].length === 0 && (
                <div className="my-12">
                  <NoDataInfo
                    title={t('investments_evolution_not_found')}
                    textOne={t('investments_evolution_not_found_description')}
                  />
                </div>
              )}
          </Card>
        </div>
        <div className='w-1/4'>
          <Card>
            <p className='text-xl font-semibold my-2'>{t('last_investments')}</p>
            {loading &&
              ['', '', '', '', ''].map((item, index) => {
                return (
                  <ContentLoader key={index} viewBox="0 0 380 30">
                    <circle cx="15" cy="15" r="10" />
                    <rect x="40" y="10" rx="4" ry="4" width="240" height="10" />
                  </ContentLoader>
                )
              })}
            {!loading && fundingRounds.length === 0 && (
              <div className={'font-medium text-xxs text-gray'}>
                {t('information_not_available')}
              </div>
            )}
            {!loading &&
              fundingRounds.map((fundingRound, index) => {
                return (
                  <div
                    key={index}
                    className="flex py-1 w-full justify-between text-xs"
                  >
                    <div className={'flex'}>
                      <div className="mr-2">
                        <img
                          src={fundingRound.image}
                          alt="Logo"
                          className={'w-6 rounded-full'}
                        />
                      </div>

                      <div>
                        <span className={'font-bold mr-1'}>
                          {fundingRound.name}
                        </span>
                        <span
                          className={'mr-1'}
                        >{`${fundingRound.date} | `}</span>
                        <span>{`| ${fundingRound.funding_type} | ${fundingRound.amount}`}</span>
                      </div>
                    </div>
                  </div>
                )
              })}
          </Card>
        </div>
      </div>
      <Card wrapperClassName='my-8'>
        <p className='text-xl font-semibold my-2'>{t('latest_news')}</p>
        {loading &&
          ['', '', '', '', ''].map((item, index) => {
            return (
              <ContentLoader key={index} viewBox="0 0 380 30">
                <rect x="0" y="10" rx="4" ry="4" width="270" height="10" />
                <rect x="290" y="5" rx="4" ry="4" width="20" height="20" />
                <rect x="320" y="5" rx="4" ry="4" width="20" height="20" />
                <rect x="350" y="5" rx="4" ry="4" width="20" height="20" />
              </ContentLoader>
            )
          })}
        {!loading && news.length === 0 && (
          <div className={'font-medium text-xxs text-gray'}>
            {t('information_not_available')}
          </div>
        )}
        {!loading &&
          news.map((item, index) => {
            return (
              <div
                key={index}
                className="flex py-1 w-full justify-between mt-2"
              >
                <div className="text-xs flex items-center w-full">
                  <div className={'mr-1 flex justify-between w-full'}>
                    <div className="flex flex-col w-full">
                      <div className={' line-clamp-1 font-bold'}>
                        {item.title}
                      </div>
                      <div className={' line-clamp-1 pr-4'}>
                        {item.abstract}
                      </div>
                    </div>
                  </div>
                  <div className={'text-xxs cursor-pointer'}>
                    <a href={item.link} target="_blank" rel="noreferrer" className={`hover:text-link-hover-${Config.PLATFORM}`}>
                      {t('read_more')}
                    </a>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div>
                    <button
                      onClick={() => console.log('===')}
                      className={`ml-2 bg-transparence-blue font-medium text-main-${Config.PLATFORM} px-2 py-2 rounded-lg w-7 flex items-center justify-center`}
                    >
                      <BiLike className="inline-block" />
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() => console.log('===')}
                      className={`ml-2 bg-yellow-50 font-medium text-main-${Config.PLATFORM} px-2 py-1 rounded-lg w-7 hover:bg-yellow-100`}
                    >
                      <img
                        src={StarIcon}
                        alt="Favourite icon"
                        className="w-5 mr-1"
                      />{' '}
                    </button>
                  </div>
                </div>
              </div>
            )
          })}
      </Card>
    </div>
  )
}
