import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'

import { RoutesLinks } from 'components/routes-links'
import { useSelector } from 'react-redux'
import Config from 'config'
import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'
import { useEffect, useState } from 'react'
import { Arrow, useLayer } from 'react-laag'
import { FaBell } from 'react-icons/fa'
import Utils from 'utils/utils'

export function RemindersMenu () {
  const history = useHistory()
  const { t } = useTranslation()
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const handleRedirect = (url) => {
    history.push(url)
  }

    const reminders = useSelector((state) => state.reminders.reminders)
    const totalReminders = useSelector((state) => state.reminders.total)

  const getReminders = () => {
    
    if (totalReminders) {
        return [
                ...reminders,
            {
                title: totalReminders - reminders.length ? `${t('go_to_reminders')} (+${totalReminders - reminders.length})` : `${t('go_to_reminders')}`,
                icon: <FaBell />,
                url: RoutesLinks.REMINDERS_LINK
            }
        ]
    } else {
        return [
            { reminder: t('reminders_not_found_today')},
            {
                title: t('go_to_reminders'),
                icon: <FaBell />,
                url: RoutesLinks.REMINDERS_LINK
            }
        ]
    }
  }

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    onOutsideClick: () => setOpen(false),
    onDisappear: () => setOpen(false),
    overflowContainer: true,
    auto: true,
    placement: 'bottom-center',
    triggerOffset: 12,
    containerOffset: 16,
    arrowOffset: 16
  })

  return (
    <div>
        <button
        {...triggerProps}
        onClick={() => setOpen(!isOpen)}
        className={`bg-bg-buttons-secondary-${Config.PLATFORM} border border-border-buttons-secondary-${Config.PLATFORM} focus:outline-none custom-circle hover:shadow-inner shadow-soft-white w-8 h-8 rounded-full ml-2`}
      >
        <FontAwesomeIcon className={`text-text-buttons-secondary-${Config.PLATFORM} w-3`} icon="bell" />
        {totalReminders > 0 && (
          <span className="inline-block w-1.5 h-1.5 bg-red -mr-1.5 -mb-0.5 rounded-full"/>
        )}
      </button>

      {isOpen &&
        renderLayer(
          <ul
            {...layerProps}
            className={`mt-2 px-2 py-2 z-30 | shadow-hover border bg-white rounded-2xl border-border-buttons-secondary-${Config.PLATFORM}`}
          >
            {getReminders().map((i, index) => (
                <li
                    key={index}
                    onClick={!i.reminder ? () => handleRedirect(i.url) : null}
                    className={`px-2 py-2 flex text-main-${Config.PLATFORM} items-center ${!i.reminder && 'cursor-pointer'} ${i.reminder && 'border-b border-gray-lines'} text-sm text-gray`}
                >
                    {!i.reminder && <>
                        <span className={`mr-2 text-main-${Config.PLATFORM}`}>{i.icon}</span>
                        <span className={'hover:font-bold'}>{t(i.title)}</span>
                    </>}
                    {i.reminder && <>
                        {i.reminder_type && <span className={`mr-2 text-main-${Config.PLATFORM}`}>{Utils.getReminderIcon(i, t)}</span>}
                        <span>{t(i.reminder)}</span>
                    </>}
                </li>
            ))}
            <Arrow
              { ...arrowProps}
              borderColor={Config.PLATFORM === '000' ? '#61D8BD' : '#BD13B8'}
              borderWidth={1}
              className={'w-5'}
            />
          </ul>
        )}
    </div>
  )
}
