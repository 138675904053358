import React, { useState, useEffect } from 'react';
import { GoogleButton } from 'components/ui/atoms/GoogleButton';
import { Button } from 'components/ui/atoms/Button';
import { Loader } from 'components/ui/molecules/Loader'
import Config from 'config';
import EmailsList from './EmailsList';
import EmailDetail from './EmailDetail';
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary';
import { TfiReload } from 'react-icons/tfi';
import { useTranslation } from 'react-i18next'
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo';

const API_KEY = 'AIzaSyDDXutZvDTGFliGz9_-PG1IwnXERSVu5jU';
const CLIENT_ID = '690938764456-ac8b32rplj2tfk4f43grc7iuh36udi8i.apps.googleusercontent.com';
const PAGE_SIZE = 10;

const EmailsView = ({deal}) => {
  const [emails, setEmails] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [prevPageToken, setPrevPageToken] = useState('');
  const [nextPageToken, setNextPageToken] = useState('');
  const [showEmailDetail, setShowEmailDetail] = useState(false);
  const [emailData, setEmailData] = useState({});
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation()
  const [paginationTokens, setPaginationTokens] = useState([])
  const [hasNextPage, setHasNextPage] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)


  useEffect(() => {
    const initClient = async () => {

      if (!gapi.auth2.getAuthInstance()) {
        await gapi.client.init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest'],
          scope: 'https://www.googleapis.com/auth/gmail.readonly'
        });
      }
      const authInstance = gapi.auth2.getAuthInstance();

      if (authInstance.isSignedIn.get()) {
        setIsAuthenticated(true);

        const basicProfile = authInstance.currentUser.get().getBasicProfile();
        const _email = basicProfile.getEmail();

        if (_email) {
          getEmails()
        } else {
          setEmails([])
        }

      } else {
        handleLoginFailure();
        setIsAuthenticated(false);
      }
    };

    gapi.load('client:auth2', initClient);
  }, []);

  const getEmails = async (pageToken = '') => {

    // console.log(`to:${emailStartup}||from:${emailStartup}||subject:"${startupName}"`)
    try {
      setLoading(true);
      let query = null;
      if (deal.main_contact_email) {
        query = `to:${deal.main_contact_email}||from:${deal.main_contact_email}||subject:"${deal.name}"`
      } else {
        query = `subject:"${deal.name}"`
      }

      const response = await gapi.client.gmail.users.messages.list({
        userId: 'me',
        maxResults: PAGE_SIZE,
        pageToken,
        q: query
      });

      const messages = response?.result?.messages;

      let emails = [];
      if (messages) {
        emails = await Promise.all(messages.map(async (message) => {
          const email = await gapi.client.gmail.users.messages.get({
            userId: 'me',
            id: message.id,
            format: 'full'
          });
          return email.result;
        }));
      }

      console.log(response.result)
      const { nextPageToken } = response.result;
      if (nextPageToken) {
        setHasNextPage(true)
        if (!paginationTokens.includes(nextPageToken)) {
          setPaginationTokens([...paginationTokens, nextPageToken])
        }
      } else {
        setHasNextPage(false)
      }

      setEmails(emails);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  const handleLoginSuccess = async () => {
    setIsAuthenticated(true);
    getEmails();
  };

  const handleLoginFailure = (response) => {
    console.log('Error al iniciar sesión', response);
    setLoading(false)
  };

  const getEmailContent = async (emailId) => {
    try {
      const response = await gapi.client.gmail.users.messages.get({
        userId: 'me',
        id: emailId,
        format: 'full'
      });

      const email = response.result;
      setEmailData(email)
    } catch (e) {
      console.error(e)
    }

  };

  return (
      <div className='my-6'>
        <div className={`flex justify-between items-center mb-6 border-b border-main-${Config.PLATFORM}`}>
        <h3 className={`font-medium text-main-${Config.PLATFORM} font-bold mb-4`}>
{t('email')}</h3>
          <div>
            {isAuthenticated ? (
            <ButtonSecondary
              onClick={() => {
                setCurrentPage(0)
                setPaginationTokens([])
                getEmails()
              }}
              text={t('synchronize_emails')}
              iconComponent={<TfiReload className='inline mr-1'/> }
            />) : (<GoogleButton
              text={t('enable_email_synchronization')}
              clientId={CLIENT_ID}
              onSuccess={handleLoginSuccess}
              onFailure={handleLoginFailure}
              cookiePolicy={'single_host_origin'}
            />)}
          </div>
        </div>

        {emails.length ? (
          <div className='w-full'>
            <EmailsList emails={emails || []} getEmailContent={getEmailContent} showEmailDetail={setShowEmailDetail} />
            {(currentPage !== 0 || paginationTokens?.length > 0) && (
              <div className='flex justify-end my-4'>
                {currentPage !== 0 && <ButtonSecondary onClick={() => {
                  getEmails(currentPage - 1 ? paginationTokens[currentPage - 1] : '');
                  setCurrentPage(currentPage - 1)
                }} iconComponent={<GrFormPrevious />} className='w-4 h-4' marginRight={2} />}
                {paginationTokens.length && paginationTokens.length > currentPage && <ButtonSecondary
                  onClick={() => {
                    getEmails(paginationTokens[currentPage])
                    setCurrentPage(currentPage + 1)
                  }}
                  iconComponent={<GrFormNext className='w-4 h-4'/>}
                />}
              </div>
            )}
          </div>
        ) : (<div className='my-10'><NoDataInfo title={t(isAuthenticated ? 'no_emails_found' : 'email_synchronization_not_enabled')} /></div>)}

        {showEmailDetail && (
          <EmailDetail
            onClose={() => setShowEmailDetail(false)}
            email={emailData} />
        )}

        {loading && (
          <Loader />
        )}
      </div>
  )
}

export default EmailsView
