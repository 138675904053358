import { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom'
import { Loader } from 'components/ui/molecules/Loader'
import { Button } from 'components/ui/atoms//Button'
import moment from 'moment'
import { RoutesLinks } from 'components/routes-links'
import SupportService from 'services/support-service'
import ScaleOverTen from 'styles/images/scale_over_ten.svg'
import ScaleOverOneHundred from 'styles/images/scale_over_one_hundred.svg'
import TextareaIcon from 'styles/images/textarea.svg'
import { isEmpty } from 'lodash'
import { ScoreCardInfo } from 'components/events/components/event/components/score-card-info'
import ScoreCardUtils from 'utils/score-card-utils'
import { FaChevronCircleDown, FaExpand, FaExpandAlt, FaExternalLinkAlt } from 'react-icons/all'
import { useSwal } from 'hooks/useSwal'
import Config from 'config';
import { PageTitle } from 'components/ui/atoms/PageTitle';
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { ButtonDanger } from 'components/ui/atoms/ButtonDanger'
import { Modal } from 'components/ui/molecules/Modal'

const ScoreCard = () => {
  const SCALES = {
    scale_to_ten: ScaleOverTen,
    scale_to_one_hundred: ScaleOverOneHundred,
    open_value: TextareaIcon
  }

  const { t } = useTranslation()
  const history = useHistory()
  const { scoreCardId } = useParams()
  const { confirm } = useSwal()
  const handleRedirect = (url) => history.push(url)

  const { addToast } = useToasts()
  const [showUSedScoreCardError, setShowUSedScoreCardError] = useState(false)
  const [scoreCardInEvents, setScoreCardInEvents] = useState([])
  const [scoreCard, setScoreCard] = useState(null)
  const [loading, setLoading] = useState(true)
  const [loadingArchiveScoreCard, setLoadingArchiveScoreCard] = useState(false)

  const getScoreCard = useCallback(async () => {
    try {
      setLoading(true)

      const result = await SupportService.getScoreCard(scoreCardId)
      if (!isEmpty(result.data.data)) {
        setScoreCard(result.data.data)
      } else {
        handleRedirect(RoutesLinks.SCORE_CARDS_LINK)
      }
    } catch (error) {
      addToast(t('error_occurred_retrieving_score_card'), {
        appearance: 'error',
        autoDismiss: true
      })
      handleRedirect(RoutesLinks.SCORE_CARDS_LINK)
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [setLoading])

  useEffect(() => {
    getScoreCard()
  }, [])

  const archiveScoreCard = () => {
    confirm({
      icon: 'warning'
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoadingArchiveScoreCard(true)
          await SupportService.archiveScoreCard(scoreCardId)
          addToast(t('score_card_archived_successfully'), {
            appearance: 'success',
            autoDismiss: true
          })
          handleRedirect(`${RoutesLinks.SCORE_CARDS_LINK}`)
        } catch (error) {
          if (error.response.status === 403) {
            setShowUSedScoreCardError(true)
            setScoreCardInEvents(error.response.data.events)
          } else {
            addToast(
              error.response
                ? error.response.data.msg_key
                  ? t(error.response.data.msg_key)
                  : t('error_occurred')
                : t('error_archiving_score_card'),
              {
                appearance: 'error',
                autoDismiss: true
              }
            )
          }
        } finally {
          setLoadingArchiveScoreCard(false)
        }
      }
    })
  }

  const updateScoreCard = async (data) => {
    try {
      setLoading(true)
      await SupportService.updateScoreCard(scoreCardId, data)
      addToast(t('score_card_updated_successfully'), {
        appearance: 'success',
        autoDismiss: true
      })
      getScoreCard()
    } catch (error) {
      addToast(t('error_occurred_updating_scorecard'), {
        appearance: 'error',
        autoDismiss: true
      })
    } finally {
      setLoading(false)
    }
  }

  const expandOrCollapse = (elementId) => {
    const element = document.getElementById(elementId)
    element.classList.toggle('hidden')
  }

  return (
    <>
    {showUSedScoreCardError && (
        <Modal
          showCloseModal={true}
          showModal={showUSedScoreCardError}
          paddingBottom="2"
          paddingTop="6"
          onClose={() => {
            setShowUSedScoreCardError(false)
            setScoreCardInEvents([])
          }}
        >
          <h2 className="font-semibold text-xl mt-4">
            {t('error_occurred_deleting_score_card')}
          </h2>
          <p className="font-medium text-sm text-gray-dark">
            {t('score_card_is_being_used_in_some_evaluation_process')}
          </p>
          <p className="font-medium text-sm text-gray-dark mt-6 mb-4">
            {t('delete_score_card_from_evaluation_processes_previously')}
          </p>
          <div className="text-sm  text-justify font-normal w-full px-10 mb-6">
            <ul className='list-disc'>
              {scoreCardInEvents.map((q, index) => {
                return <li key={index} className="mb-1">{q}</li>
              })}
            </ul>
          </div>
        </Modal>
      )}

    <div className='flex items-start'>
        <div className='flex-1'>
          <PageTitle
            title={`${t('score_card')} : ${!scoreCard?.name ? '' : `${scoreCard.name}`}`}
            showBack={true}
            onBackClick={() => {
              handleRedirect(RoutesLinks.SCORE_CARDS_LINK)
            }} />
        </div>
        <div>
          {!loading && scoreCard && !scoreCard.deletedAt && (
            <div className='flex'>
              <ButtonDanger
                type="button"
                onClick={archiveScoreCard}
                text={t('archive_score_card')}
                marginRight={2}
              />
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-center w-full mb-6">
        <div className={'flex flex-col w-full'}>
          {scoreCard && (
            <>
              <h3 className={`text-2xl text-main-${Config.PLATFORM} my-6 border-b border-main-${Config.PLATFORM}`}>{t('criteria')}</h3>
              {ScoreCardUtils.getCriteriaParents(scoreCard.criteria).map(
                (parent) => (
                  <div key={parent._id}>
                    <div
                      className="border-b border-gray-lines font-bold flex flex-row justify-between hover:bg-gray-100 px-2 hover:rounded-md transition-colors cursor-pointer mt-6"
                      onClick={() => expandOrCollapse(parent._id)}
                    >
                      <span>{`${parent.name}`}</span>
                      <FaChevronCircleDown
                        data-panel={parent._id}
                        className={`text-main-${Config.PLATFORM} inline-block mt-1`}
                      />
                    </div>

                    <div id={parent._id} className={'mt-2'}>
                      {parent.description && (
                        <small className="px-6">{parent.description}</small>
                      )}
                      {ScoreCardUtils.getChildren(
                        parent,
                        scoreCard.criteria
                      ).map((child) => (
                        <div key={child._id}>
                          <div className="ml-4 font-bold mt-1 text-sm flex flex-row justify-between hover:bg-gray-100 px-2 rounded-md transition-colors">
                            <span>{child.name}</span>
                            <span className={`text-main-${Config.PLATFORM} inline-block mt-1 mr-[1px]`}>
                              {`${child.weigth}%`}
                            </span>
                          </div>
                          {child.description && (
                            <small className="px-10">{child.description}</small>
                          )}
                        </div>
                      ))}
                      {!ScoreCardUtils.hasChildren(
                        parent,
                        scoreCard.criteria
                      ) && (
                          <div key={`${parent._id}_no_subcriteria`}>
                            <div className="ml-4 font-bold mt-1 text-sm flex flex-row justify-between hover:bg-gray-100 px-2 rounded-md transition-colors ">
                              <span>{t('no_subcriteria')}</span>
                            </div>
                          </div>
                        )}
                      <div
                        key={`${parent._id}_total`}
                        className={'border-t border-gray-lines'}
                      >
                        <div className="ml-4 font-bold mt-1 text-sm flex flex-row justify-between hover:bg-gray-100 px-2 rounded-md transition-colors cursor-pointer">
                          <span>{t('total')}</span>
                          <span className={`text-main-${Config.PLATFORM} inline-block mt-1 mr-[1px]`}>
                            {`${parent.weigth}%`}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </>
          )}
          {scoreCard && (
            <div>
              <>
                <div className="shadow-soft-white rounded-2xl p-4 my-8">
                  <div className="text-base font-semibold">{t('weigths')}</div>
                  <ScoreCardInfo legend={false} criteria={scoreCard.criteria} />
                </div>
              </>

              {Config.PLATFORM === '000' && (
                <>
            <h3 className={`text-2xl text-main-${Config.PLATFORM} my-8 border-b border-main-${Config.PLATFORM}`}>{t('questionnaire')}</h3>
                    
                  {scoreCard?.questionnaire ? <div className='flex'><span className='text-base font-semibold'>{scoreCard.questionnaire.name}</span>{/*<FaExternalLinkAlt className={'ml-2'} onClick={() => window.open(`${RoutesLinks.QE_FORMS_LINK}/${scoreCard.questionnaire._id}`, '_blank')} />*/}</div> : <div className='my-4'><NoDataInfo title={t('no_questionnaire_assigned_to_scorecard')} /></div>}
                </>
              )}
                          <h3 className={`text-2xl text-main-${Config.PLATFORM} my-8 border-b border-main-${Config.PLATFORM}`}>{t('scale')}</h3>

        
              <div className="w-full shadow-soft-white rounded-2xl p-4 mt-6">
                <div
                  className={'font-semibold w-full justify-center items-center  rounded-2xl | transition-all duration-400 ease-in-out | mr-2 text-xs '}
                >
                  <img
                    src={SCALES[scoreCard.scale]}
                    alt="Arrow icon"
                    className={`inline ${scoreCard.scale === 'likert_scale'
                        ? 'w-6 ml-2 mr-4'
                        : 'w-10 mr-2'
                      }`}
                  />
                  {t(scoreCard.scale)}
                </div>
              </div>
            </div>
          )}
          {scoreCard && (
            <div className="w-full shadow-soft-white rounded-2xl p-4 my-4">
              <div>
                <div className="w-full px-1 text-sm font-semibold">
                  {t('creation_date')}
                </div>
              </div>
              <div className="w-full px-1 text-xs">
                {moment(scoreCard.createdAt).format('YYYY-MM-DD HH:MM')}
              </div>
              <div>
                <div className="w-full px-1 text-sm font-semibold mt-2">
                  {t('created_by')}
                </div>
              </div>
              <div className="w-full px-1 text-xs">
                {`${scoreCard.created_by.name} ${scoreCard.created_by.last_name}`}
              </div>
              {scoreCard.deletedAt && (
                <>
                  <div>
                    <div className="w-full px-1 text-sm font-semibold mt-2">
                      {t('archived_date')}
                    </div>
                  </div>
                  <div className="w-full px-1 text-xs">
                    {moment(scoreCard.deletedAt).format('YYYY-MM-DD HH:MM')}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {(loading || loadingArchiveScoreCard) && <Loader />}
    </>
  )
}

export default ScoreCard
