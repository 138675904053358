import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import BoardsService from 'services/boards-service'
import { Loader } from 'components/ui/molecules/Loader'
import { SectionHeader } from 'components/ui/molecules/SectionHeader'
import { useHistory } from 'react-router-dom'
import { RoutesLinks } from 'components/routes-links'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import { IconContainer } from 'components/ui/atoms/IconContainer'
import Plus from 'styles/images/plus.svg'
import Avatar from 'react-avatar'
import Scrollbar from 'react-scrollbars-custom'
import { useSwal } from 'hooks/useSwal'

const MessagingSsytem = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(true)
  const [creating, setCreating] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [updating, setUpdating] = useState(false)
  const { confirm } = useSwal()
  const [boards, setBoards] = useState([])

  const history = useHistory()

  const handleRedirect = (url) => history.push(url)

  const getBoards = useCallback(async () => {
    try {
      setLoading(true)

      const result = await BoardsService.getBoards()
      setBoards(result.data)
    } catch (error) {
      console.log(error)
      addToast(
        error.response && error.response.data.msg_key
          ? error.response.data.msg_key
          : t('error_occurred_retrieving_boards'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    } finally {
      setLoading(false)
    }
  }, [setBoards])

  useEffect(() => {
    getBoards()
  }, [])

  const TEMPLATES = [
    {
      name: 'Wells (1974)',
      columns: [
        { name: 'Search' },
        { name: 'Screen' },
        { name: 'Evaluation' },
        { name: 'Operations' },
        { name: 'Cashing out' }
      ]
    },
    {
      name: 'Tyebjee/Bruno (1984)',
      columns: [
        { name: 'Deal originitation' },
        { name: 'Screening' },
        { name: 'Evaluation' },
        { name: 'Deal structuring' },
        { name: 'Post-investment activities' }
      ]
    },
    {
      name: 'Hall (1989)',
      columns: [
        { name: 'Generating deal-flow' },
        { name: 'Proposal screening' },
        { name: 'Proposal assessment' },
        { name: 'Project evaluation' },
        { name: 'Due diligence' },
        { name: 'Deal structuring' },
        { name: 'Venture operations' },
        { name: 'Cashing out' }
      ]
    },
    {
      name: 'Fried/Hisrich (1994)',
      columns: [
        { name: 'Deal origination' },
        { name: 'Firm-specific scree' },
        { name: 'Generic screen' },
        { name: 'First phase evaluation' },
        { name: 'Second phase evaluation' },
        { name: 'Closing' }
      ]
    },
    {
      name: 'Boocock/Woods (1997)',
      columns: [
        { name: 'Generating deal-flow' },
        { name: 'Initial screening' },
        { name: 'First meeting' },
        { name: 'Second meeting' },
        { name: 'Board presentation' },
        { name: 'Due diligence' },
        { name: 'Deal structuring' },
        { name: 'Monitoring of investments' },
        { name: 'Cashing out' }
      ]
    }
  ]

  const confirmCreate = ({ name, template }) => {
    confirm({
      title: t('are_you_sure'),
      text: t('create_board_text')
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setCreating(true)
          setShowBoardModal(false)
          await BoardsService.createBoard(
            name,
            template ? TEMPLATES[template].columns : []
          )
          getBoards()
          addToast(t('board_created_successfully'), {
            appearance: 'success',
            autoDismiss: true
          })
        } catch (error) {
          console.log(error)
          addToast(
            error.response && error.response.data.msg_key
              ? error.response.data.msg_key
              : t('error_occurred_creating_board'),
            {
              appearance: 'error',
              autoDismiss: true
            }
          )
        } finally {
          setCreating(false)
        }
      }
    })
  }

  const confirmUpdate = ({ name }) => {
    confirm({
      text: t('update_board_text')
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setUpdating(true)
          setShowBoardModal(false)
          await BoardsService.updateBoard(boardToEdit._id, { name })
          getBoards()
          addToast(t('board_updated_successfully'), {
            appearance: 'success',
            autoDismiss: true
          })
        } catch (error) {
          console.log(error)
          addToast(
            error.response && error.response.data.msg_key
              ? error.response.data.msg_key
              : t('error_occurred_creating_board'),
            {
              appearance: 'error',
              autoDismiss: true
            }
          )
        } finally {
          setBoardToEdit({})
          setUpdating(false)
        }
      }
    })
  }

  const deleteBoard = (board) => {
    confirm({
      text: t('delete_board_text'),
      icon: 'warning'
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setDeleting(true)
          await BoardsService.deleteBoard(board._id)
          getBoards()
          addToast(t('board_deleted_successfully'), {
            appearance: 'success',
            autoDismiss: true
          })
        } catch (error) {
          addToast(
            error.response && error.response.data.msg_key
              ? error.response.data.msg_key
              : t('error_occurred_deleting_board'),
            {
              appearance: 'error',
              autoDismiss: true
            }
          )
        } finally {
          setDeleting(false)
        }
      }
    })
  }

  const [showBoardModal, setShowBoardModal] = useState(false)

  const SECTIONS = ['entrepreneurs', 'startups']

  const [boardToEdit, setBoardToEdit] = useState({})

  const handleEditBoard = (board) => {
    setShowBoardModal(true)
    setBoardToEdit({ name: board.name, _id: board._id })
  }

  return (
    <>
      <div className={'text-2xl mb-4 font-bold'}>
        {t('messaging_system')}
      </div>
      <SectionHeader
        sectionTitles={SECTIONS.map((sec) => t(sec))}
        sectionKeys={SECTIONS}
        section={'entrepreneurs'}
        setSection={(sec) => {
          handleRedirect(
            `${RoutesLinks.PIPELINE_MANAGER_LINK}${sec.replace('_', '-')}`
          )
        }}
        showAdd={true}
        showFilters={false}
        setShowAdd={() => setShowBoardModal(true)}
      />
      <div>
        {(loading || creating || deleting || updating) && <Loader />}

        <div
          className={
            'w-full mb-10 flex border-2 border-gray-lines rounded-2xl mt-5'
          }
          style={{ height: '30rem' }}
        >
          <div
            className={'w-1/4 border-r-2 border-gray-lines overflow-hidden'}
          >
            <div className={'flex border-b border-gray-lines p-2 w-full'}>
              <div className={' px-2 py-1 truncate'}>
                <div
                  className={
                    'flex text-xs font-semibold truncate items-center'
                  }
                >
                  Start a new conversation
                  <IconContainer
                    width="6"
                    height="6"
                    shadow="hover"
                    shadowHover="inner"
                    iconWidth="4"
                    icon={Plus}
                  />
                </div>
              </div>
            </div>
            <Scrollbar
              trackYProps={{
                className: 'scrollbar_trackY'
              }}
              thumbYProps={{
                className: 'scrollbar_thumbY'
              }}
            >
              <div className={'overflow-y-auto'}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((startup, index) => {
                  return (
                    <div
                      className={'flex border-b border-gray-lines p-2 w-full hover:bg-transparence-blue cursor-pointer'}
                      key={index}
                    >
                      <div className={'flex items-center'}>
                        <Avatar
                          name={'kjl .jlk'}
                          color="#e0e6f2"
                          fgColor="#4d70b3"
                          size="2rem"
                          round="100px"
                          textSizeRatio={3}
                        />
                      </div>
                      <div className={' px-2 py-1 truncate'}>
                        <div
                          className={
                            'text-xs font-semibold truncate'
                          }
                        >
                          Cristian Estrada
                        </div>
                        <div
                          className={
                            'text-xxs text-secondary mt-1 truncate'
                          }
                        >
                          Este mensaje es demasiado largo
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </Scrollbar>
          </div>
          <div className={'w-3/4 flex flex-col justify-between'}>
            <Scrollbar
              trackYProps={{
                className: 'scrollbar_trackY'
              }}
              thumbYProps={{
                className: 'scrollbar_thumbY'
              }}
            >
              <div className={'overflow-y-auto'}>
                <div>
                  <p>bb</p>
                </div>
                <div>
                  <p>cc</p>
                </div>
                <div>
                  <p>knjhkjkkkk</p>
                </div>
                <div>
                  <p>knjhkjkkkk</p>
                </div>
                <div>
                  <p>knjhkjkkkk</p>
                </div>
                <div>
                  <p>knjhkjkkkk</p>
                </div>
                <div>
                  <p>knjhkjkkkk</p>
                </div>
                <div>
                  <p>knjhkjkkkk</p>
                </div>
                <div>
                  <p>knjhkjkkkk</p>
                </div>
                <div>
                  <p>knjhkjkkkk</p>
                </div>
                <div>
                  <p>knjhkjkkkk</p>
                </div>
                <div>
                  <p>knjhkjkkkk</p>
                </div>
                <div>
                  <p>knjhkjkkkk</p>
                </div>
                <div>
                  <p>knjhkjkkkk</p>
                </div>
                <div>
                  <p>knjhkjkkkk</p>
                </div>
                <div>
                  <p>knjhkjkkkk</p>
                </div>
                <div>
                  <p>knjhkjkkkk</p>
                </div>
                <div>
                  <p>knjhkjkkkk</p>
                </div>
                <div>
                  <p>knjhkjkkkk</p>
                </div>
                <div>
                  <p>knjhkjkkkk</p>
                </div>
                <div>
                  <p>knjhkjkkkk</p>
                </div>
                <div>
                  <p>knjhkjkkkk</p>
                </div>
                <div>
                  <p>knjhkjkkkk</p>
                </div>
                <div>
                  <p>aa</p>
                </div>
              </div>
            </Scrollbar>
            <div className={'border-t border-gray-lines p-2'}>
              <p>knjhkjkkkk</p>
            </div>
          </div>
        </div>
        {boards.length === 0 && !loading && (
          <NoDataInfo
            title={t('boards_not_found')}
            textOne={t('no_boards_text_one')}
            textTwo={t('no_boards_text_two')}
          />
        )}
      </div>
    </>
  )
}

export default MessagingSsytem
