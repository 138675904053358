import InvestmentEvolutions from 'assets/json/investments-evolution.json'

const InvestmentsEvolutionUtil = {
  getEvolution: (country, sector, investmentStage) => {
    const annuallyEvolution = InvestmentEvolutions.annually.find(
      (item) =>
        item.country === country &&
        item.sector === sector &&
        item.investment_stage === investmentStage
    )
    const monthlyEvolution = InvestmentEvolutions.monthly.find(
      (item) =>
        item.country === country &&
        item.sector === sector &&
        item.investment_stage === investmentStage
    )

    return {
      annually: annuallyEvolution ? annuallyEvolution.evolution : [],
      monthly: monthlyEvolution ? monthlyEvolution.evolution : []
    }
  }
}

export default InvestmentsEvolutionUtil
