import { useTranslation } from 'react-i18next'
import { Likert } from 'components/ui/molecules/Likert'

export const LikertScore = ({ identifier, value, onChange }) => {
  const { t } = useTranslation()

  const options = [
    { id: '1', value: t('very_low') },
    { id: '2', value: t('low') },
    { id: '3', value: t('medium') },
    { id: '4', value: t('high') },
    { id: '5', value: t('very_high') }
  ]

  return (
    <div className="mb-2">
      <Likert
        id={identifier}
        value={value}
        options={options}
        onChange={onChange}
      />
    </div>
  )
}
