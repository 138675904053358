import { useCallback, useState, useEffect } from 'react'
import { SectionHeader } from 'components/ui/molecules/SectionHeader'
import { useTranslation } from 'react-i18next'
import { Loader } from 'components/ui/molecules/Loader'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import { useToasts } from 'react-toast-notifications'
import SupportService from 'services/support-service'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Details from 'styles/images/details_blue.svg'
import { RoutesLinks } from 'components/routes-links'
import { useHistory } from 'react-router-dom'

const SupportNPS = () => {
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const history = useHistory()

  const handleRedirect = (url) => history.push(url)

  const [npsList, setNPSList] = useState([])
  const [stats, setStats] = useState(null)

  const getNPS = useCallback(async () => {
    try {
      setLoading(true)
      const result = await SupportService.getNPS()
      setStats(result.data.data.stats)
      setNPSList(result.data.data.nps)
    } catch (error) {
      console.log(error)
      addToast(
        error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t('error_occurred'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    } finally {
      setLoading(false)
    }
  })

  useEffect(() => {
    getNPS()
  }, [])

  const ROUTES = {
    tickets: RoutesLinks.SUPPORT_TICKETS_LINK,
    nps_section: RoutesLinks.SUPPORT_NPS_LINK,
    requested_features: RoutesLinks.SUPPORT_REQUESTED_FEATURES_LINK
  }

  return (
    <>
      <SectionHeader
        setSection={(sec) => {
          handleRedirect(ROUTES[sec])
        }}
        sectionTitles={[
          t('tickets'),
          t('nps_section'),
          t('requested_features')
        ]}
        sectionKeys={['tickets', 'nps_section', 'requested_features']}
        section={'nps_section'}
        showAdd={false}
        showFilters={false}
      />

      <div className={'mt-10'}>
        {!loading && stats && (
          <>
            <div className="flex justify-around text-xs">
              <div className="w-1/4 border-r py-2">
                <div className={' font-bold mb-2'}>
                  {t('scores')}
                </div>
                <div className={'text-xl  font-bold'}>
                  {stats.count}
                </div>
              </div>
              <div className="w-1/4 border-r pl-4 py-2">
                <div className={' font-bold mb-2'}>
                  {t('maximum_score')}
                </div>
                <div className={'text-xl  font-bold'}>
                  {`${stats.max}/10`}
                </div>
              </div>
              <div className="w-1/4 pl-4 py-2">
                <div className={' font-bold mb-2'}>
                  {t('minimum_score')}
                </div>
                <div className={'text-xl  font-bold'}>
                  {`${stats.min}/10`}
                </div>{' '}
              </div>
              <div className="w-1/4 pl-4 py-2">
                <div className={' font-bold mb-2'}>
                  {t('avg_score')}
                </div>
                <div className={'text-xl  font-bold'}>
                  {`${stats.avg}/10`}
                </div>{' '}
              </div>
            </div>
            <div className={'my-10'}>
              {npsList.map((nps, index) => {
                return (
                  <div
                    className={'flex w-full flex-col rounded-2xl my-4'}
                    key={index}
                  >
                    <div
                      className={'w-full  | flex flex-row flex-grow rounded-2xl justify-start items-center transition-all duration-400 ease-in-out bg-white shadow-soft-white p-4'}
                    >
                      <div className={'flex flex-col w-full pr-4'}>
                        <div className={'text-xs'}>
                          <FontAwesomeIcon
                            className=" text-sm mr-1 cursor-pointer"
                            icon="user"
                            onClick={() => {
                              navigator.clipboard.writeText(nps.user.name)
                              addToast(t('name_copied_to_clipboard'), {
                                appearance: 'success',
                                autoDismiss: true
                              })
                            }}
                          />
                          {`${nps.user.name} ${nps.user.last_name}`}
                          <FontAwesomeIcon
                            className=" text-sm ml-2 mr-1 cursor-pointer"
                            icon="envelope"
                            onClick={() => {
                              navigator.clipboard.writeText(nps.user.email)
                              addToast(t('email_copied_to_clipboard'), {
                                appearance: 'success',
                                autoDismiss: true
                              })
                            }}
                          />
                          {nps.user.email}

                          <FontAwesomeIcon
                            className=" text-sm ml-2 mr-1"
                            icon="calendar"
                          />
                          {moment(nps.createdAt).format('YYYY-MM-DD')}
                          <FontAwesomeIcon
                            className=" text-sm ml-2 mr-1"
                            icon="star"
                          />
                          {nps.score}
                        </div>
                        <div className={'font-semibold text-xs mt-2 mb-1'}>
                          {`${t('comments')}:`}
                        </div>
                        <div className={'text-xs'}>
                          {nps.comment ? nps.comment : '-'}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </>
        )}
        {!loading && !npsList.length && (
          <NoDataInfo title={t('no_nps_found')} />
        )}
      </div>
      {loading && <Loader />}
    </>
  )
}

export default SupportNPS
