import { useTranslation } from 'react-i18next'
import { Card } from 'components/ui/atoms/Card'
import Avatar from 'react-avatar'
import { FaExpandAlt, FaEye, FaHandshake, FaPaperPlane, FaTimes } from 'react-icons/all'
import { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { Button } from 'components/ui/atoms/Button'
import Utils from 'utils/utils'
import { useSwal } from 'hooks/useSwal'
import { useToasts } from 'react-toast-notifications'
import { Loader } from 'components/ui/molecules/Loader'
import useUser from 'hooks/useUser'
import ProfessionalService from 'services/professional-service'
import Config from 'config';
import { ButtonCardMain } from 'components/ui/atoms/ButtonCardMain'
import { ButtonCardSecondary } from 'components/ui/atoms/ButtonCardSecondary'

export function ConnectedStartup({
  startup,
  onManage,
  connection,
  setDealToResume
}) {
  const { t } = useTranslation()
  const { confirm } = useSwal()
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)

  const deleteConnection = () => {
    confirm({
      title: t('delete_connection')
    }).then(async (isConfirmed) => {
      if (isConfirmed) { 
        setLoading(true)
        ProfessionalService.deleteConnection(connection._id).then(() => {
          addToast(t('connection_deleted_successfully'), { appearance: 'success', autoDismiss: true })
          onManage && onManage()
        }).catch(() => {
          addToast(t('error_occurred_deleting_connection'), { appearance: 'error', autoDismiss: true })
        }).finally(() => {
          setLoading(false)
        })
      }
    })
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  return (
    <>
      <Card
        wrapperClassName="transition-transform"
        containerClassName="w-full flex flex-col h-full"
      >
        <div className='flex flex-col'>
          <div className="flex pb-2 mb-2 justify-between">
            <div className='flex flex-row w-full'>

              <div className="flex flex-col h-full text-xs min-w-0 w-1/2">
                <h5 className={`uppercase font-bold text-sm leading-none text-main-${Config.PLATFORM} truncate mb-1`}>
                  {startup.name}
                </h5>
                <div className='text-xxs'>
                  <div>
                    <b>{t('country')}:</b>
                    <span className='capitalize ml-1'>{startup.country_incorporation ? t(startup.country_incorporation.key) : '-'}</span>
                  </div>
                  <div>
                    <b>{t('sector')}:</b>
                    <span className='capitalize ml-1'>{startup.sector ? t(startup.sector.key) : '-'}</span>
                  </div>
                  <div>
                    <b>{t('investment_stage')}:</b>
                    <span className='capitalize ml-1'>{startup.investment_stage ? t(startup.investment_stage.key) : '-'}</span>
                  </div>
                  <div>
                    <b>{t('project_stage')}:</b>
                    <span className='capitalize ml-1'>{startup.project_stage ? t(startup.project_stage.key) : '-'}</span>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-center mr-2 w-1/2 info-card-container-img">
                <Avatar
                  src={startup.logo}
                  name={startup.name}
                  alt={startup.name}
                  maxInitials={3}
                  className={`h-full object-contain ${startup.logo ? 'w-full' : ''}`}
                  color="#e0e6f2"
                  fgColor="#4d70b3"
                  round="5"
                />
              </div>

            </div>
          </div>
        </div>
        <div className={`flex justify-end border-t border-separator-${Config.PLATFORM} pt-2 mt-4`}>
          <ButtonCardSecondary
            onClick={() => setDealToResume(startup)}
            text={t('see_profile')}
            iconComponent={<FaEye className="inline-block mr-2 w-5 h-4" />}
            marginRight={2}
          />
          <ButtonCardMain
            onClick={() => deleteConnection(connection._id)}
            text={t('delete_connection')}
            iconComponent={<FaTimes className="inline-block w-5 h-4" />}
          />
        </div>
      </Card>

      {loading && <Loader />}
    </>
  )
}
