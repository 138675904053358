import React, { useState } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { RoutesLinks } from '../../../routes-links'
import { BannerResponsive } from '../../../ui/atoms/BannerResponsive'
import { FormTitle } from '../../../ui/atoms/FormTitle'
import { Button } from '../../../ui/atoms/Button'
import { Input } from '../../../ui/atoms/Input'
import { BottomText } from '../../../ui/atoms/BottomText'
import ShowPassword from '../../../../assets/images/ShowPassword.png'
import moment from "moment";
import Config from "config";
import { PoweredText } from 'components/ui/atoms/PoweredText'

const ConfirmRecoverPasswordForm = ({ onSubmit, passwordRecovered }) => {
  const { t } = useTranslation()

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)

  const handleShowhide = () => {
    setShowPassword(!showPassword)
  }

  const handleShowhideConfirm = () => {
    setShowConfirm(!showConfirm)
  }

  const ConfirmRecoverPasswordSchema = Yup.object().shape({
    new_password: Yup.string()
      .required(t('required_field'))
      .min(8, t('password_length'))
      .matches(/^.*(?=.*?[A-Z]).*$/, t('password_req_uppercase'))
      .matches(/^.*(?=.*?[a-z]).*$/, t('password_req_lowercase'))
      .matches(/^.*(?=.*?[0-9]).*$/, t('password_req_number'))
      .matches(
        /^.*(?=.*?[\^ $ * . [ \] { } ( ) ? " ! @ # % & , > < ' : ; _ ~ ` \\ / |]).*$/,
        t('password_req_special_char')
      ),
    password_confirmation: Yup.string()
      .min(8, t('password_length'))
      .oneOf([Yup.ref('new_password'), null], t('passwords_do_not_match'))
      .required(t('required_field'))
  })

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting }
  } = useForm({
    resolver: yupResolver(ConfirmRecoverPasswordSchema, { abortEarly: false }),
    criteriaMode: 'all',
    reValidateMode: 'all',
    mode: 'all'
  })

  return (
    <section className="flex flex-col justify-center items-center | min-h-screen w-full | pt-20 sm:pt-0 lg:p-16">
      <div className="max-w-xs mx-4 sm:max-w-md 2xl:max-w-lg">
        <BannerResponsive />
        <div className="flex flex-col">
          <>
            <FormTitle formTitle={t('recover_password')} />
            <div className="text-black text-base">
              <p className="pb-2">{t('confirm_forgot_instructions')}</p>
            </div>
            <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-4">
                <Input
                  disabled={isSubmitting}
                  reference={register}
                  id="new_password"
                  type={showPassword ? 'text' : 'password'}
                  name="new_password"
                  label={t('new_password') + ': *'}
                  placeholder={t('password_req_min_char')}
                  error={errors}
                  icon={ShowPassword}
                  show={handleShowhide}
                />
                <Input
                  disabled={isSubmitting}
                  reference={register}
                  icon={ShowPassword}
                  id="password_confirmation"
                  type={showConfirm ? 'text' : 'password'}
                  name="password_confirmation"
                  label={t('confirm_password') + ': *'}
                  placeholder={t('password_req_min_char')}
                  error={errors.password_confirmation}
                  show={handleShowhideConfirm}
                />
              </div>
              <div className="w-full flex justify-center">
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  horizontal="16"
                  text={t('restore_password')}
                  textColor={`buttons-card-text-${Config.PLATFORM}`}
                  bgColor={`buttons-secondary-${Config.PLATFORM}`}
                  bgHoverColor={`buttons-hover-${Config.PLATFORM}`}
                />
              </div>
            </form>
            <div className="w-full text-center mt-4 text-xs">
              <Link to={`${RoutesLinks.LOGIN_LINK}`} className="underline">
                {t('go_to_login')}
              </Link>
            </div>
          </>
        </div>
      </div>
      <BottomText text={`${t('copyright')} @${moment().format('YYYY')} ${t('copyright_rights')}`} />
      {Config.PLATFORM !== '000' && <PoweredText />}
    </section>
  )
}

export default ConfirmRecoverPasswordForm
