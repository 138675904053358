import { useTranslation } from 'react-i18next'
import { SidePanel } from 'components/ui/objects/SidePanel'
import { useEffect } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import Config from 'config'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { TextArea } from 'components/ui/atoms/TextArea'
import { Input } from 'components/ui/atoms/Input'
import FormUtils from 'utils/form-utils'
import moment from 'moment'
import { useToasts } from 'react-toast-notifications'
import Select from 'components/ui/atoms/Select'
import JobTypes from 'assets/json/job-types.json'
import LocationTypes from 'assets/json/location-types.json'

/**
 * @param onClose
 * @returns {JSX.Element}
 * @constructor
 */
const ExperienceSection = ({ onClose, registerExperience, initialValues, updateProfessionalExperience }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const jobTypes = JobTypes.map((jobType) => {
    return { id: jobType._id, value: t(jobType.key) }
  })

  const locationTypes = LocationTypes.map((locationType) => {
    return { id: locationType._id, value: t(locationType.key) }
  })

  const { register, handleSubmit, errors, setValue, trigger, watch, getValues } = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    reValidateMode: 'all',
    resolver: yupResolver(Yup.object().shape({
        role: Yup.string().required(t('required_field')),
        job_type: Yup.string().required(t('required_field')),
        company: Yup.string().required(t('required_field')),
        company_logo: Yup.string()
        .transform(value => !value ? null : value)
        .matches(FormUtils.URL_REGEX, t('incorrect_format'))
        .nullable(),
        company_url: Yup.string()
        .transform(value => !value ? null : value)
        .matches(FormUtils.URL_REGEX, t('incorrect_format'))
        .nullable(),
        location: Yup.string(),
        location_type: Yup.string(),
        start_date: Yup.string()
            .required(t('required_field'))
            .transform((value) => FormUtils.parseDateFromString(value, 'YYYY-MM-DD HH:mm')),
        end_date: Yup.string()
            .nullable()
            .transform((value) => FormUtils.parseDateFromString(value, 'YYYY-MM-DD HH:mm')),
        description: Yup.string().nullable()
      }), { abortEarly: false })
  })

  useEffect(() => {
    register('job_type')
    register('location_type')
    }, [register])

  useEffect(() => {
    if (!initialValues) {
        setValue('start_date', moment().format('YYYY-MM-DD'))
    } else {
        setValue('role', initialValues.role)
        setValue('description', initialValues.description || '')
        setValue('location', initialValues.location || '')
        setValue('company', initialValues.company)
        setValue('company_logo', initialValues.company_logo || '')
        setValue('company_url', initialValues.company_url || '')

        if (initialValues.job_type) {
            setValue('job_type', initialValues.job_type._id)
        }
        if (initialValues.location_type) {
            setValue('location_type', initialValues.location_type._id)
        }
        if (initialValues.end_date) {
            setValue('end_date', moment(initialValues.end_date).format('YYYY-MM-DD'))
        }
        setValue('start_date', moment(initialValues.start_date).format('YYYY-MM-DD'))
    }
    trigger()
  }, [initialValues])

  return (
    <>
      <SidePanel
        width={'1/4'}
        onClose={onClose}
        title={t(initialValues ? 'update_experience' : 'add_experience')}
      >
        <div className="mt-4 flex flex-col w-full">
            <div className="w-full">
            <Input
                required={true}
              reference={register}
              name="role"
              placeholder={t('role')}
              label={t('role')}
              type="string"
              error={errors.role}
            />
            <Select
            required={true}
                label={t('job_type')}
              isClearable={false}
              items={jobTypes}
              initialValues={
                watch('job_type')
                  ? jobTypes
                    .filter(item => item.id === watch('job_type'))
                  : []
              }
              onSelect={selected => {
                setValue('job_type', selected.length ? selected[0].id : null)
                trigger()
              }}
              id="job_type"
              error={errors.job_type}
              name="job_type"
              reference={register}
            />
            <Input
            required={true}
              reference={register}
              name="start_date"
              placeholder={t('start_date')}
              label={t('start_date')}
              type="date"
              error={errors.start_date}
            />
            <Input
              reference={register}
              name="end_date"
              placeholder={t('end_date')}
              label={t('end_date')}
              type="date"
              error={errors.end_date}
            />
            <Input
              reference={register}
              name="company"
              placeholder={t('company')}
              label={t('company')}
              type="string"
              error={errors.company}
              required={true}
            />
            <Input
              reference={register}
              name="company_logo"
              placeholder={t('company_logo_url_placeholder')}
              label={t('company_logo')}
              type="string"
              error={errors.company_logo}
            />
            <Input
              reference={register}
              name="company_url"
              placeholder={t('company_url_placeholder')}
              label={t('company_url')}
              type="string"
              error={errors.company_url}
            />
            <Input
              reference={register}
              name="location"
              placeholder={t('location')}
              label={t('location')}
              type="string"
              error={errors.location}
            />
            <Select
                            label={t('location_type')}

              isClearable={false}
              items={locationTypes}
              initialValues={
                watch('location_type')
                  ? locationTypes
                    .filter(item => item.id === watch('location_type'))
                  : []
              }
              onSelect={selected => {
                setValue('location_type', selected.length ? selected[0].id : null)
                trigger()
              }}
              id="location_type"
              error={errors.location_type}
              name="location_type"
              reference={register}
            />
            <TextArea
                reference={register}
                name="description"
                error={errors.description}
                label={t('description')}
                maxLength={200}
                placeholder={t('description')}
                />
            </div>
            <div className={`flex justify-end mt-2 pt-2 border-t border-separator-${Config.PLATFORM}`}>
              <ButtonMain
                onClick={async () => {
                    const validation = await trigger()
                    if (validation) {
                        if (initialValues) {
                            updateProfessionalExperience(getValues())
                        } else {
                            registerExperience(getValues())
                        }
                    }
                    else {
                        addToast(t('form_field_error'), { appearance: 'error', autoDismiss: true })
                    }
                }}
                text={t(initialValues ? 'update_experience' : 'add_experience')}
              />
            </div>
        </div>
      </SidePanel>
    </>
  )
}

export default ExperienceSection
