import React from 'react'
import FounderScope from './components/founder-scope'
import InvestorScope from './components/investor-scope'
import useUser from '../../hooks/useUser'
import ProfessionalScope from './components/professional-scope'

const Account = () => {
  const { isFounder, isInvestor, isProfessional } = useUser()

  const components = {
    founder: <FounderScope />,
    investor: <InvestorScope />,
    professional: <ProfessionalScope/>
  }

  return isFounder ? components.founder : isInvestor ? components.investor : isProfessional ? components.professional : null
}

export default Account
