import { yupResolver } from '@hookform/resolvers/yup'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { TextArea } from 'components/ui/atoms/TextArea'
import SupportService from 'services/support-service'
import { Button } from 'components/ui/atoms/Button'
import { AppOperations } from 'redux/app'
import { useDispatch } from 'react-redux'
import Config from 'config';
import { ButtonMain } from './ButtonMain'

export function Contact () {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const dispatch = useDispatch()

  const ContactSchema = Yup.object().shape({
    message: Yup.string().required(t('required_field'))
  })

  const { register, handleSubmit, errors, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ContactSchema)
  })

  const onSubmit = async (values) => {
    try {
      dispatch(AppOperations.setShowContactModal(false))

      await SupportService.contact(values)
      addToast(t('support_ticket_received'), {
        appearance: 'success',
        autoDismiss: true
      })
      reset({ message: '' })
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t('error_occurred_contacting'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    }
  }

  return (
    <>
      <div className="flex flex-col">
        <h3 className={`font-medium mb-4 text-main-${Config.PLATFORM}`}>{t('help_title')}</h3>
      </div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <TextArea
            reference={register}
            id="message"
            name="message"
            rows="10"
            placeholder={t('help_message')}
            error={errors.message}
          />
          <div className="flex justify-end my-4">
            <ButtonMain
              width={'full md:w-1/2'}
              type="submit"
              text={t('send')}
            />
          </div>
        </div>
      </form>
    </>
  )
}
