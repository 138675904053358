import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Card } from '../ui/atoms/Card'
import { NoDataInfo } from '../ui/atoms/NoDataInfo'
import { Button } from '../ui/atoms/Button'
import { FaExpandAlt, FaEye, FaGripHorizontal, FaListAlt, FaPlus } from 'react-icons/all'
import { Pagination } from '../ui/atoms/Pagination'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { DealflowService } from '../../services/dealflow-service'
import { Loader } from '../ui/molecules/Loader'
import { DealflowPoolListItem } from './DealflowPoolListItem'
import { usePagination } from '../../hooks/usePagination'
import { DealflowFilters } from './DealflowFilters'
import Config from 'config';
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import CreateStartupForm from 'components/edit-startup/components/CreateStartupForm'
import { SidePanel } from 'components/ui/objects/SidePanel'
import { DealResumeSection } from './DealResumeSection'
import BoardsService from 'services/boards-service'
import { useSwal } from 'hooks/useSwal'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import useUser from 'hooks/useUser'
import { DataGrid } from 'components/ui/objects/DataGrid'
import { RoutesLinks } from 'components/routes-links'
import { useHistory } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import Utils from 'utils/utils'

export const PoolStartups = forwardRef(({ setDealToResume }, ref) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [dealflow, setDealflow] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingBoards, setLoadingBoards] = useState(false)
  const { user } = useUser()

  const [showDealSection, setShowDealSection] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [filters, setFilters] = useState({})
  const [boards, setBoards] = useState([])
  const { confirm, alert } = useSwal()
  const [viewMode, setViewMode] = useState(user.preferences.dealflow_visibility_mode || 'grid')
  const headerClass = `text-main-${Config.PLATFORM} font-bold text-sm border-b border-main-${Config.PLATFORM} text-left bg-white`;
  const history = useHistory()

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const {
    setTotalPages,
    totalPages,
    setPage,
    page,
    rowsPerPage,
    setRowsPerPage
  } = usePagination(12)

  const getDealflow = () => {
    setLoading(true)
    return DealflowService.getDealflow({
      ...filters,
      rowsPerPage,
      page,
      sortBy: '-createdAt',
      pool: 'true',
      reminders: 'true'
    }).then(result => {
      const { dealflow, pagination } = result.data
      setDealflow(dealflow)
      setTotalPages(pagination.pages)
    }).catch(() => {
      addToast(t('error_occurred_retrieving_dealflow'), { appearance: 'error', autoDismiss: true })
    }).finally(() => {
      setLoading(false)
    })
  }

  const getBoards = async () => {
    try {
      setLoadingBoards(true)
      const result = await BoardsService.getBoards()
      setBoards(result.data)
    } catch (error) {
      console.log(error)
      addToast(
        error.response && error.response.data.msg_key
          ? error.response.data.msg_key
          : t('error_occurred_retrieving_boards'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    } finally {
      setLoadingBoards(false)
    }
  }

  const showAddStartupModal = () => {
    setShowDealSection(true)
  }

  const addDeal = (data) => {
    if (data.assign_startup_to_pipeline_manager && (!data.pipeline_manager_board || !data.pipeline_manager_stage)) {
      alert({ text: t('assign_startup_to_pipeline_manager_incomplete') })
      return
    }
    confirm({
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        setLoading(true)
        try {
          await DealflowService.addDeal(data)
          getDealflow()
          addToast(t('startup_added_successfully'), {
            appearance: 'success',
            autoDismiss: true
          })
          setShowDealSection(false)
        } catch (error) {
          console.log(error)
          addToast(t(error?.response?.data?.msg_key || 'error_adding_startup'), {
            appearance: 'error',
            autoDismiss: true
          })
        } finally {
          setLoading(false)
        }
      }
    })
  }

  useImperativeHandle(ref, () => ({
    addStartup () {
      showAddStartupModal()
    },
    showFilters () {
      setShowFilters(true)
    }
  }), [])

  useEffect(() => {
    getBoards()
  }, [])

  useEffect(() => {
    getDealflow()
  }, [page, rowsPerPage, filters])

  const getLocation = (deal) => {
    if (deal.city_incorporation && deal.country_incorporation) {
      return `${deal.city_incorporation} (${t(deal.country_incorporation.key)})`
    } else if (deal.city_incorporation) {
      return deal.city_incorporation
    } else if (deal.country_incorporation) {
      return t(deal.country_incorporation.key)
    } else {
      return '-'
    }
  }

  return (
    <>
      {(!loading && dealflow.length === 0) && (
        <div className='my-6'>
          <NoDataInfo
              title={t(Object.keys(filters).length ? 'no_filtered_results' : 'pool_startups_not_found')}
              textOne={t(Object.keys(filters).length ? 'no_filtered_results_text' : 'pool_startups_not_found_description')}
            />
        </div>
      )}

      {(!loading && dealflow.length > 0) && (
        <div className="mt-6">
          <div className='flex justify-end mb-4'>
            <div
              className={`mr-1 w-10 flex items-center justify-center p-1 ${viewMode === 'list' ? `shadow-inner border-2 border-main-${Config.PLATFORM}` : `border border-border-buttons-secondary-${Config.PLATFORM}`} bg-white hover:shadow-inner mr-2 rounded-lg cursor-pointer`}
              onClick={() => setViewMode('list')}
            >
              <FaListAlt className='inline' />
            </div>
            <div
              className={`mr-1 w-10 flex items-center justify-center p-1 ${viewMode === 'grid' ? `shadow-inner border-2 border-main-${Config.PLATFORM}` : `border border-border-buttons-secondary-${Config.PLATFORM}`} bg-white hover:shadow-inner mr-2 rounded-lg cursor-pointer`}
              onClick={() => setViewMode('grid')}
            >
              <FaGripHorizontal className='inline' />
            </div>
          </div>
          {viewMode === 'grid' && (
            <div className="grid grid-cols-1 lg:grid-cols-2  2xl:grid-cols-3 gap-4">
              {dealflow.map(dealflow => (
                <DealflowPoolListItem
                  key={dealflow._id}
                  dealflow={dealflow}
                  setDealToResume={setDealToResume}
                />
              ))}
            </div>
          )}

          {viewMode === 'list' && (
            <Card>
              <DataGrid
                actionsHeaderClassName={headerClass}
                data={dealflow.map(d => ({ ...d, id: d._id }))}
                headers={[
                  {
                    key: 'name',
                    title: t('name'),
                    headerClassName: headerClass,
                    render: (key, deal) => (
                      <div className="flex">
                        <div className="ml-2">{deal.name}</div>
                      </div>
                    )
                  },
                  {
                    key: 'priority',
                    title: t('priority'),
                    headerClassName: headerClass,
                    render: (key, deal) => (
                      <div className="flex">
                        <div className="ml-2">{deal.priority ? Utils.getDealflowPriority(deal, t) : '-'}</div>
                      </div>
                    )
                  },
                  {
                    key: 'next_step',
                    title: t('next_step'),
                    headerClassName: headerClass,
                    render: (key, deal) => (
                      <div className="flex">
                        <div className="ml-2">{deal.next_step ? Utils.getDealflowNextStep(deal, t) : '-'}</div>
                      </div>
                    )
                  },
                  {
                    key: 'location',
                    title: t('location'),
                    headerClassName: headerClass,
                    render: (key, deal) => (
                      <div className="flex">
                        <div className="ml-2">{getLocation(deal)}</div>
                      </div>
                    )
                  },
                  {
                    key: 'brand_statement',
                    title: t('brand_statement'),
                    headerClassName: headerClass,
                    render: (key, deal) => (
                      deal?.brand_statement ? <div className="flex">
                        <div className="ml-2 cursor-help" data-tip={`${deal.brand_statement.substring(0, 150)}...`}>{`${deal.brand_statement.substring(0, 50)}...` || '-'}</div>
                      </div> : <div className="flex">
                        <div className="ml-2">-</div>
                      </div>
                    )
                  },
                  {
                    key: 'main_contact_person',
                    title: t('main_contact_person'),
                    headerClassName: headerClass,
                    render: (key, deal) => (
                      <div className="flex">
                        <div className="ml-2">{deal.main_contact_person || '-'}</div>
                      </div>
                    )
                  },
                  {
                    key: 'main_contact_email',
                    title: t('main_contact_email'),
                    headerClassName: headerClass,
                    render: (key, deal) => (
                      <div className="flex">
                        <div className="ml-2">{deal.main_contact_email || '-'}</div>
                      </div>
                    )
                  },
                  /*},
                  {
                    key: 'startup',
                    title: t('startup'),
                    headerClassName: headerClass,
                    show: () => !dealId,
                    render: (key, doc) => (
                      <>
                        {doc.startup && (
                          <div>
                            <Avatar
                              src={doc.startup.logo}
                              size="30"
                              round={true}
                              color="#e0e6f2"
                              fgColor="#4d70b3"
                              alt={doc.startup.name}
                            />
                            <span className="ml-2">{doc.startup.name}</span>
                          </div>
                        )}
                        {!doc.startup && '-'}
                      </>
                    )
                  },
                  {
                    key: 'tags',
                    title: t('tags'),
                    headerClassName: headerClass,
                    render: (key, document) => (
                      <>
                      {(document?.tags || []).length !== 0 ?
                        <TagListCircles tags={document?.tags || []} /> : '-'}
                      </>
                    )
                  },
                  {
                    key: 'createdAt',
                    title: t('date'),
                    headerClassName: headerClass,
                    render: (key, createdAt) => moment(createdAt).format('DD/MM/YYYY')
                  }*/
                ]}
                actions={[
                  {
                    id: 'expand',
                    tip: t('see_resume'),
                    onClick: (deal) => {
                      setDealToResume(deal)
                    },
                    buttonProps: {
                      iconComponent: <FaExpandAlt />
                    }
                  },
                  {
                    id: 'see',
                    tip: t('see_profile'),
                    onClick: (deal) => history.push(`${RoutesLinks.DEALFLOW_LINK}${deal._id}`),
                    buttonProps: {
                      iconComponent: <FaEye />
                    }
                  }
                ]}
              />
            </Card>
          )}

          <Pagination
            paginateOptions={[12, 24, 36, 48, 60]}
            currentPage={page}
            setCurrentPage={(page) => setPage(page)}
            perPage={rowsPerPage}
            pages={totalPages}
            setPerPage={(value) => setRowsPerPage(value[0].id)}
          />
        </div>
      )}

      {showDealSection && (
        <SidePanel
          onClose={() => setShowDealSection(false)}
          title={t('add_startup')}
          width='1/4'
        >
          <CreateStartupForm onSubmit={addDeal} boards={boards}/>
        </SidePanel>
      )}

      {showFilters && (
        <DealflowFilters
          onClose={() => setShowFilters(false)}
          onFilter={setFilters}
          filters={filters}
        />
      )}

      {(loading || loadingBoards) && <Loader />}
    </>
  )
})
