import React, { useEffect, useState } from 'react'
import { DataGrid } from '../../ui/objects/DataGrid'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import FormUtils from '../../../utils/form-utils'
import { Input } from '../../ui/atoms/Input'
import { Button } from '../../ui/atoms/Button'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useToasts } from 'react-toast-notifications'
import { FaTrash } from 'react-icons/all'
import { FaEdit, FaPlus } from 'react-icons/fa'
import ReactTooltip from 'react-tooltip'
import { Card } from '../../ui/atoms/Card'
import { NoDataInfo } from '../../ui/atoms/NoDataInfo'
import Config from 'config';
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { Loader } from 'components/ui/molecules/Loader'
import { DealflowService } from 'services/dealflow-service'
import { useSwal } from 'hooks/useSwal'
import { SidePanel } from 'components/ui/objects/SidePanel'


export const StartupEditCompetitors = ({ competitors, onChange, isDealflow, startup, loading, setLoading }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [showCompetitorSection, setShowCompetitorSection] = useState(false)
  const [competitorToEdit, setCompetitorToEdit] = useState(null)
  const { confirm } = useSwal()

  const {
    register,
    handleSubmit,
    trigger,
    errors,
    reset
  } = useForm({
    resolver: yupResolver(Yup.object().shape({
      name: Yup.string().required(t('required_field')),
      description: Yup.string(),
      web: Yup.string()
        .transform(value => !value ? null : value)
        .matches(FormUtils.URL_REGEX, t('incorrect_format'))
        .nullable()
    }), { abortEarly: false }),
    criteriaMode: 'all',
    reValidateMode: 'all',
    mode: 'all'
  })

  const closeForm = () => {
    setShowCompetitorSection(false)
    setCompetitorToEdit(null)
    reset()
  }

  const onInvalid = () => {
    addToast(t('form_field_error'), { appearance: 'error', autoDismiss: true })
  }

  const onSuccess = messageKey => {
    closeForm()
    onChange && onChange()
    addToast(t(messageKey), {
      appearance: 'success',
      autoDismiss: true
    })
  }

  const onError = messageKey => {
    addToast(t(messageKey),
      {
        appearance: 'error',
        autoDismiss: true
      }
    )
  }

  const openForm = (competitor) => {
    reset({
      name: competitor?.name,
      web: competitor?.web,
      description: competitor?.description
    })

    trigger()
    setCompetitorToEdit(competitor)
    setShowCompetitorSection(true)
  }

  const onSubmit = (values) => {
    if (isDealflow) {
      if (!competitorToEdit) {
        confirm().then(isConfirmed => {
          if (isConfirmed) {
            setLoading(true)
            DealflowService.addCompetitor(startup._id, values)
              .then(() => onSuccess('competitor_added_successfully'))
              .catch(error => onError(error.response && error.response.data.msg_key
                ? t(error.response.data.msg_key)
                : t('error_occurred_adding_competitor')))
              .finally(() => setLoading(false))
          }
        })
      } else {
        setLoading(true)
        DealflowService.updateCompetitor(startup._id, values, competitorToEdit._id)
          .then(() => onSuccess('competitor_updated_successfully'))
          .catch(error => onError('error_occurred_updating_competitor'))
          .finally(() => setLoading(false))
      }
    }
  }

  const deleteCompetitor = (competitor) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        setLoading(true)
        DealflowService.deleteCompetitor(startup._id, competitor._id)
          .then(() => onSuccess('competitor_deleted_successfully'))
          .catch(error => onError('error_occurred_deleting_competitor'))
          .finally(() => setLoading(false))
      }
    })
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [competitors])

  return (
    <>
    <div className="w-full mt-6 mb-8">
        <Card containerClassName="flex flex-col lg:flex-row lg:justify-between p-6 text-2">
          <div className="md:w-full lg:w-10/12">
            <h3 className={`text-main-${Config.PLATFORM}`}>
              {t('would_you_like_to_add_a_competitor')}
            </h3>
          </div>
          <div className="md:w-full lg:w-2/12 justify-end flex text-center">
            <ButtonMain
              width="1/2 lg:w-full"
              text={t('add_competitor')}
              onClick={() => openForm(null)}
              />
          </div>
        </Card>
      </div>

      {(!competitors || competitors.length === 0) && (
        <div className='my-10'>
          <NoDataInfo
            title={t('no_competitors_title')}
          />
        </div>
      )}

      {competitors?.length > 0 && (
        <DataGrid
          wrapperClassName='mt-3'
          data={competitors}
          bordered={true}
          compact={true}
          headers={[
            {
              key: 'name',
              title: t('name')
            },
            {
              key: 'description',
              title: t('description')
            },
            {
              key: 'web',
              title: t('project_web')
            }
          ]}
          actions={[
            {
              id: 'edit',
              tip: t('edit'),
              onClick: (competitor) => openForm(competitor),
              buttonProps: {
                iconComponent: <FaEdit className="inline" />,
                className: 'w-12',
                horizontal: '1',
                vertical: '1',
                verticalMargin: '0',
                horizontalMargin: '0',
                textColor: `buttons-card-text-${Config.PLATFORM}`,
                bgColor: `buttons-card-${Config.PLATFORM}`,
                bgHoverColor: `buttons-hover-${Config.PLATFORM}`
              }
            },
            {
              id: 'delete',
              tip: t('delete'),
              onClick: (competitor) => deleteCompetitor(competitor),
              buttonProps: {
                iconComponent: <FaTrash className="inline" />,
                className: 'w-12 bg-red text-white',
                horizontal: '1',
                vertical: '1',
                verticalMargin: '0',
                horizontalMargin: '0'
              }
            }
          ]}
        />
      )}

      {showCompetitorSection && (
        <SidePanel
            width={'1/4'}
            onClose={closeForm}
            title={t(!competitorToEdit ? 'add_competitor' : 'update_competitor')}
        >
        <form onSubmit={handleSubmit(onSubmit, onInvalid)} noValidate>
          <Input
            name='name'
            label={t('name')}
            error={errors.name}
            required={true}
            reference={register}
          />

          <Input
            name='description'
            label={t('description')}
            error={errors.description}
            reference={register}
          />

          <Input
            name='web'
            label={t('project_web')}
            error={errors.web}
            reference={register}
          />

          <div className={`flex justify-end mt-4 border-t pt-2 border-separator-${Config.PLATFORM}`}>
            <ButtonMain
              text={t(competitorToEdit !== null ? 'update_competitor' : 'add_competitor')}
              type='submit'
            />
          </div>
        </form>
        </SidePanel>
      )}
      {loading && <Loader /> }
    </>
  )
}
