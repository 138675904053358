import { useEffect, useState } from 'react'
import Avatar from 'react-avatar'
import { Card } from 'components/ui/atoms/Card'
import { useTranslation } from 'react-i18next'
import Subsectors from 'assets/json/subsectors.json'
import Person from 'assets/images/person.png'

import ReactTooltip from 'react-tooltip'
import Utils from '../../utils/utils'
import moment from 'moment'
import { Loader } from 'components/ui/molecules/Loader'
import { FaGithub, FaGitlab, FaLinkedin, FaMedium, FaTwitter, FaYoutube, SiCrunchbase, FaGlobe } from 'react-icons/all'
import {
  FaCalendarAlt,
  FaEnvelope,
  FaHandHoldingUsd, FaHandshake,
  FaLightbulb,
  FaLink,
  FaMapMarkerAlt,
  FaPaperPlane,
  FaQuestion,
  FaUser
} from 'react-icons/fa'
import { BsBarChartSteps } from 'react-icons/bs'
import { BiTargetLock } from 'react-icons/bi'
import { MdPriceChange } from 'react-icons/md'
import Linkedin from '../../assets/images/linkedin.png'
import Twitter from '../../assets/images/twitter.png'
import Instagram from '../../assets/images/instagram.png'
import Youtube from '../../assets/images/youtube.png'
import Tiktok from '../../assets/images/tiktok.png'
import Facebook from '../../assets/images/facebook.png'
import { FiTarget } from 'react-icons/fi'
import { NoDataInfo } from '../ui/atoms/NoDataInfo'

import Config from 'config';
import { SidePanel } from 'components/ui/objects/SidePanel'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import useUser from 'hooks/useUser'

export const DealResumeSection = ({
  deal,
  onClose,
  investorView = true,
  setInvestorView,
  section = 'pool'
}) => {
  const { isProfessional } = useUser()
  const [infoToShow, setInfoToShow] = useState(investorView || isProfessional || !deal.project ? deal : deal.project)

  useEffect(() => {
    if (investorView || isProfessional) {
      setInfoToShow(deal)
    } else if (deal.project) {
      setInfoToShow(deal.project)
    }
  }, [isProfessional])

    useEffect(() => {
        ReactTooltip.rebuild()
    }, [deal])

  const { t } = useTranslation()

  const socialNetworks = [
    {
      name: 'linkedin',
      icon: Linkedin,
      url: infoToShow.linkedin
    },
    {
      name: 'twitter',
      icon: Twitter,
      url: infoToShow.twitter
    },
    {
      name: 'instagram',
      icon: Instagram,
      url: infoToShow.instagram
    },
    {
      name: 'youtube',
      icon: Youtube,
      url: infoToShow.youtube
    },
    {
      name: 'tiktok',
      icon: Tiktok,
      url: infoToShow.tiktok
    },
    {
      name: 'facebook',
      icon: Facebook,
      url: infoToShow.facebook
    }
  ].sort((a, b) => {
    if (a.url && b.url) {
      return a.url.length - b.url.length
    } else if (a.url) {
      return -1
    } else if (b.url) {
      return 1
    }
    return 0
  })

  const getSectors = () => {
    return [t(infoToShow.sector?.key), t(infoToShow.subsector?.key)]
      .filter(Boolean)
      .join(', ')
  }

  const getOtherInterestingAreas = () => {
    return Subsectors
      .filter(subsector => (infoToShow.other_interesting_areas || []).includes(subsector._id))
      .map(subsector => t(subsector.key)).join(', ')
  }

  const renderBadge = (title, icon, value) => {
    return (
      <span
        data-tip={title}
        className={`rounded bg-gray-100 flex justify-between items-center gap-2 px-2 py-1 hover:bg-main-${Config.PLATFORM} hover:text-white parent-hover:text-white`}
      >
        <span className={`text-main-${Config.PLATFORM}`}>
          {icon}
        </span>
        <span className='font-semibold'>
          {value}
        </span>
      </span>
    )
  }

  return (
    <SidePanel
        width={'2/5'}
        onClose={onClose}
        title={t('general_information')}
      >
        <div className='mt-4'>
            <div className='flex flex-col'>
            <div className="w-full">
                <Card>
                <div className="flex">
                    <div className="mr-4">
                    <Avatar
                        src={infoToShow.logo}
                        alt={infoToShow.name}
                        color="#e0e6f2"
                        fgColor="#4d70b3"
                        size="100px"
                        round={false}
                        className={'avatar-startup'}
                    />
                    </div>
                    <div>
                    <h1 className={`text-4xl text-main-${Config.PLATFORM} mb-2 font-bold`}>
                        {infoToShow.name}
                        {infoToShow.web && <FaLink className='inline ml-2 h-4 cursor-pointer' onClick={() => window.open(infoToShow.web, '_blank')}/>}
                    </h1>
                    <div>
                        <p className="font-bold">
                        {infoToShow.sector ? getOtherInterestingAreas() : getSectors()}
                        </p>
                        <p className="text-xs">
                            {getOtherInterestingAreas()}
                        </p>
                    </div>
                    {investorView && !['explore', 'connection_requests'].includes(section) && <div className='flex flex-row'>
                        <div className='w-auto'>{Utils.getDealflowStatus(infoToShow, t)}</div>
                        {infoToShow.priority && (
                        <div className='w-auto'>{Utils.getDealflowPriority(infoToShow, t)}</div>
                        )}
                        {infoToShow.next_step && (
                        <div className='w-auto'>{Utils.getDealflowNextStep(infoToShow, t)}</div>
                        )}
                    </div>}

                    </div>
                </div>

                <div className='flex-1 mt-2 pt-2 border-t border-gray-lines'>
                    <pre className='font-sans whitespace-pre-wrap'>{infoToShow.brand_statement}</pre>
                </div>

                <div className='flex flex-row flex-wrap mt-4 gap-2'>
                    {(infoToShow.country_incorporation || infoToShow.city_incorporation) && (
                    renderBadge(
                        t('location'),
                        <FaMapMarkerAlt />,
                        [t(infoToShow.country_incorporation?.key), infoToShow.city_incorporation]
                        .filter(Boolean)
                        .join(', ')
                    )
                    )}

                    {infoToShow.creation_date && (
                    renderBadge(
                        t('creation_date'),
                        <FaCalendarAlt />,
                        moment(infoToShow.creation_date).format('DD/MM/YYYY')
                    )
                    )}

                    {infoToShow.project_stage && (
                    renderBadge(
                        t('project_stage'),
                        <BsBarChartSteps />,
                        t(infoToShow.project_stage.key)
                    )
                    )}
                    {infoToShow.investment_stage && (
                    renderBadge(
                        t('investment_stage'),
                        <BsBarChartSteps />,
                        t(infoToShow.investment_stage.key)
                    )
                    )}

                    {infoToShow.business_model && (
                    renderBadge(
                        t('business_model'),
                        <BiTargetLock />,
                        t(infoToShow.business_model.key)
                    )
                    )}

                    {infoToShow.minimum_ticket && (
                    renderBadge(
                        t('minimum_ticket'),
                        <MdPriceChange />,
                        `${infoToShow.minimum_ticket} ${t(infoToShow.minimum_ticket_unit)}`
                        )
                    )}

                    {infoToShow.investment_desired && (
                    renderBadge(
                        t('investment_desired'),
                        <FaHandHoldingUsd />,
                        `${infoToShow.investment_desired} ${t(infoToShow.investment_desired_unit)}`
                    )
                    )}

                    {infoToShow.valuation && (
                    renderBadge(
                        t('valuation'),
                        <FaHandHoldingUsd />,
                        `${infoToShow.valuation} ${t(infoToShow.valuation_unit)}`
                    )
                    )}
                </div>
                    <div className='flex-1 border-t border-gray-lines pt-2 flex text-xs mt-2'>
                    <FaUser className={`text-main-${Config.PLATFORM} mr-1`} data-tip={t('main_contact_person')} />
                    {infoToShow.main_contact_person || '-'}
                    <FaEnvelope className={`text-main-${Config.PLATFORM} ml-4 mr-1`} data-tip={t('main_contact_email')} />
                    {infoToShow.main_contact_email || '-'}
                    </div>
                    {Config.PLATFORM !== '000' && infoToShow.main_contact_email && (
                      <div className={`flex justify-end mt-4 py-4 border-t border-separator-${Config.PLATFORM}`}>
                      <ButtonSecondary
                            text={t('contact')}
                            iconComponent={<FaPaperPlane className='inline mr-1' />}
                            onClick={() => window.location = `mailto:${infoToShow.main_contact_email}`}
                        />
                      </div>
                    )}
                </Card>
            </div>

            <div className='w-full my-8'>
                <Card>
                <h3 className={`text-main-${Config.PLATFORM}`}>{t('social_networks')}</h3>
                <div className='grid grid-cols-3 gap-4 py-4'>
                    {socialNetworks.map(({ name, icon, url }) => (
                    <div key={name} className='flex justify-center flex-row'>
                        <a
                        target='_blank'
                        href={url}
                        rel="noreferrer"
                        data-tip={!url || url === '' ? null : t(name)}
                        className={!url || url === '' ? 'opacity-25 filter grayscale' : ''}
                        >
                        <img src={icon} alt={name} className="w-8" />
                        </a>
                    </div>
                    ))}
                </div>
                </Card>
            </div>
            </div>

            <div className='grid grid-cols-1 gap-4 my-8'>
            {['problem', 'solution', 'business_description', 'market'].map(key => (
                <div key={key} className='relative bg-white py-6 px-6 rounded-2xl my-4'>
                <div className='text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl left-4 -top-6 bg-lightBlue-600'>
                    {key === 'problem' && <FaQuestion className="inline-block w-6 h-6" />}
                    {key === 'solution' && <FaLightbulb className="inline-block w-6 h-6" />}
                    {key === 'business_description' && <FaHandshake className="inline-block w-6 h-6" />}
                    {key === 'market' && <FiTarget className="inline-block w-6 h-6" />}
                </div>

                <div className="pt-6 flex flex-col justify-between h-full">
                    <p className="text-lg font-semibold mt-2">{t(key)}</p>

                    <div className="mt-2 flex-1 flex text-base">
                        {(infoToShow[key] && infoToShow[key] !== '') && infoToShow[key]}

                        {(!infoToShow[key] || infoToShow[key] === '') && (
                        <NoDataInfo title={t('no_market_section_data')} />
                        )}
                    </div>
                </div>
                </div>
            ))}
            </div>
        </div>
    </SidePanel>
  )
}
