import { useEffect } from 'react'
import Avatar from 'react-avatar'
import { Card } from 'components/ui/atoms/Card'
import { useTranslation } from 'react-i18next'
import Subsectors from 'assets/json/subsectors.json'
import Person from 'assets/images/person.png'

import ReactTooltip from 'react-tooltip'
import Utils from '../../utils/utils'
import moment from 'moment'
import { Loader } from 'components/ui/molecules/Loader'
import { FaGithub, FaGitlab, FaLinkedin, FaMedium, FaTwitter, FaYoutube, SiCrunchbase, FaGlobe } from 'react-icons/all'
import {
  FaCalendarAlt,
  FaEnvelope,
  FaExternalLinkAlt,
  FaHandHoldingUsd, FaHandshake,
  FaLightbulb,
  FaLink,
  FaMapMarkerAlt,
  FaPaperPlane,
  FaQuestion,
  FaUser
} from 'react-icons/fa'
import { BsBarChartSteps } from 'react-icons/bs'
import { BiTargetLock } from 'react-icons/bi'
import { MdPriceChange } from 'react-icons/md'
import Linkedin from '../../assets/images/linkedin.png'
import Twitter from '../../assets/images/twitter.png'
import Instagram from '../../assets/images/instagram.png'
import Youtube from '../../assets/images/youtube.png'
import Tiktok from '../../assets/images/tiktok.png'
import Facebook from '../../assets/images/facebook.png'
import { FiTarget } from 'react-icons/fi'
import { NoDataInfo } from '../ui/atoms/NoDataInfo'
import SkillsKnowledges from 'assets/json/skills-knowledges.json'

import Config from 'config';
import { SidePanel } from 'components/ui/objects/SidePanel'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'

export const ProfessionalResumeSection = ({
  professional,
  onClose
}) => {
    useEffect(() => {
        ReactTooltip.rebuild()
    }, [professional])

  const { t } = useTranslation()

  const socialNetworks = [
    {
      name: 'linkedin',
      icon: Linkedin,
      url: professional.social_networks?.linkedin
    },
    {
      name: 'twitter',
      icon: Twitter,
      url: professional.social_networks?.twitter
    },
    {
      name: 'instagram',
      icon: Instagram,
      url: professional.social_networks?.instagram
    },
    {
      name: 'youtube',
      icon: Youtube,
      url: professional.social_networks?.youtube
    },
    {
      name: 'tiktok',
      icon: Tiktok,
      url: professional.social_networks?.tiktok
    },
    {
      name: 'facebook',
      icon: Facebook,
      url: professional.social_networks?.facebook
    }
  ].sort((a, b) => {
    if (a.url && b.url) {
      return a.url.length - b.url.length
    } else if (a.url) {
      return -1
    } else if (b.url) {
      return 1
    }
    return 0
  })
  const socialNetworksItemsFounder = [
    {
      name: 'linkedin',
      title: 'Linkedin',
      icon: <FaLinkedin />
    },
    {
      name: 'twitter',
      title: 'Twitter',
      icon: <FaTwitter />
    },
    {
      name: 'youtube',
      title: 'YouTube',
      icon: <FaYoutube />
    },
    {
      name: 'medium',
      title: 'Medium',
      icon: <FaMedium />
    },
    {
      name: 'github',
      title: 'GitHub',
      icon: <FaGithub />
    },
    {
      name: 'gitlab',
      title: 'GitLab',
      icon: <FaGitlab />
    },
    {
      name: 'crunchbase_url',
      title: 'Crunchbase',
      icon: <SiCrunchbase />
    }
  ]
  return (
    <SidePanel
        width={'2/5'}
        onClose={onClose}
        title={t('general_information')}
      >
        <div className='my-4'>
            <div className='flex flex-col'>
            <div className="w-full">
                <Card>
                <div className="flex">
                    <div className="mr-4">
                    <Avatar
                        src={professional.image}
                        alt={professional.name}
                        color="#e0e6f2"
                        fgColor="#4d70b3"
                        size="100px"
                        round={false}
                        className={'avatar-startup'}
                    />
                    </div>
                    <div>
                    <h1 className={`text-4xl text-main-${Config.PLATFORM} mb-2 font-bold`}>
                        {professional.name}{' '}
                    </h1>
                    </div>
                </div>

                {professional.self_description && <div className='flex-1 mt-2 pt-2 border-t border-gray-lines'>
                    <pre className='font-sans whitespace-pre-wrap'>{professional.self_description}</pre>
                </div>}

                <div className='flex-1 border-t border-gray-lines pt-2 flex text-xs mt-2'>
                <FaEnvelope className={`text-main-${Config.PLATFORM} mr-1`} data-tip={t('email')} />
                {professional.email || '-'}
                </div>
                <div className={`flex justify-end mt-4 py-4 border-t border-separator-${Config.PLATFORM}`}>
                <ButtonSecondary
                            text={t('contact')}
                            iconComponent={<FaPaperPlane className='inline mr-1' />}
                            onClick={() => window.location = `mailto:${professional.email}`}
                        />
                </div>
                </Card>
            </div>

            <div className='w-full mt-8'>
                <Card>
                <h3 className={`text-main-${Config.PLATFORM}`}>{t('skills_and_knowledge')}</h3>
                {(!professional.skills_and_knowledge || professional.skills_and_knowledge.length === 0) && (
                    <div className='my-4'>
                        <NoDataInfo title={t('no_skills_or_knowledge_registered')} />
                    </div>
                )}
                {professional.skills_and_knowledge && professional.skills_and_knowledge.length !== 0 && (
                    <div className='my-4'>
                        {professional.skills_and_knowledge.map(skillId => (
                            <span
                            key={skillId}
                            className={`inline-block px-2 py-1 mr-2 my-1 text-xs font-medium text-buttons-primary-${Config.PLATFORM} bg-white border-gray-lines border rounded-full`}>
                            {t(SkillsKnowledges.find(s => s._id === skillId)?.key)}
                            </span>
                        ))}
                    </div>
                )}
                </Card>
            </div>
            <div className='w-full my-8'>
                <Card>
                <h3 className={`text-main-${Config.PLATFORM}`}>{t('social_networks')}</h3>
                <div className='grid grid-cols-3 gap-4 py-4'>
                    {socialNetworks.map(({ name, icon, url }) => (
                    <div key={name} className='flex justify-center flex-row'>
                        <a
                        target='_blank'
                        href={url}
                        rel="noreferrer"
                        data-tip={!url || url === '' ? null : t(name)}
                        className={!url || url === '' ? 'opacity-25 filter grayscale' : ''}
                        >
                        <img src={icon} alt={name} className="w-8" />
                        </a>
                    </div>
                    ))}
                </div>
                </Card>
            </div>
            </div>

            <h3 className={`text-2xl text-main-${Config.PLATFORM} mt-4 mb-4 border-b border-main-${Config.PLATFORM}`}>{t('professional_experience')}</h3>

            {professional?.professional_experience && professional.professional_experience.length !== 0 ? (
                <>
                    {professional.professional_experience.map((professionalExperience) => {
                        return (
                            <div className='my-4'>

                                <Card
                                    key={professionalExperience._id}
                                    wrapperClassName="transition-transform"
                                    containerClassName="w-full flex flex-col h-full"
                                >
                                    <div className='flex flex-col'>
                                <div className="flex pb-2 mb-2 ">
                                    <div className='flex flex-row w-full'>
                                        <div className="flex flex-col text-xs min-w-0 w-3/5">
                                            <h3 className={`uppercase font-bold leading-none text-main-${Config.PLATFORM} truncate mb-1`}>
                                            {professionalExperience.role}
                                            </h3>
                                            <div>
                                                <div className='text-sm'>
                                                <b>{professionalExperience.company}</b>
                                                {professionalExperience.company_url && <FaLink className='inline ml-2 h-4 cursor-pointer' onClick={() => window.open(professionalExperience.company_url, '_blank')}/>}
                                                </div>
                                                <div className='my-2'>
                                                    {`${moment(professionalExperience.start_date).format('YYYY-MM-DD')} - ${professionalExperience.end_date ? moment(professionalExperience.end_date).format('YYYY-MM-DD') : t('currently')}`}
                                                </div>
                                                <div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="flex items-center justify-center w-2/5">
                                        <div className="w-full h-[80px] ">
                                            <Avatar
                                            src={professionalExperience.company_logo}
                                            name={professionalExperience.company_logo}
                                            alt={professionalExperience.company_logo}
                                            maxInitials={3}
                                            className={`w-full h-[80px] object-contain`}
                                            color="#e0e6f2"
                                            fgColor="#4d70b3"
                                            round="5"
                                            />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {professionalExperience.description && <div className='border-t border-gray-lines py-2 text-sm'>
                                    {professionalExperience.description}
                                </div>}
                            </div>
                                </Card>
                            </div>
                        )
                    })}
                </>
            )
            : (
                <div className='my-6'>
                <NoDataInfo title={t('no_data_registered')} />
                </div>
            )}

            <h3 className={`text-2xl text-main-${Config.PLATFORM} my-8 border-b border-main-${Config.PLATFORM}`}>{t('education')}</h3>
            {professional.education && professional.education.map((education) => {
                    return (
                        <div className='my-4'>
                            <Card
                                key={education._id}
                                wrapperClassName="transition-transform"
                                containerClassName="divide-gray-lines w-full flex flex-col h-full"
                            >
                                <div className='flex flex-col'>
                                    <div className="flex pb-2 mb-2 ">
                                        <div className='flex flex-row w-full'>
                                            <div className="flex flex-col text-xs min-w-0 w-3/5">
                                                <h3 className={`uppercase font-bold leading-none text-main-${Config.PLATFORM} truncate mb-1`}>
                                                {education.title}
                                                </h3>
                                                <div>
                                                    <div className='text-sm'>
                                                    <b>{education.entity}</b>
                                                    {education.entity_url && <FaLink className='inline ml-2 h-4 cursor-pointer' onClick={() => window.open(education.entity_url, '_blank')}/>}
                                                    </div>
                                                    <div className='my-2'>
                                                        {`${moment(education.start_date).format('YYYY-MM-DD')} - ${education.end_date ? moment(education.end_date).format('YYYY-MM-DD') : t('currently')}`}
                                                    </div>
                                                    <div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="flex items-center justify-center w-2/5">
                                            <div className="w-full h-[80px] ">
                                                <Avatar
                                                src={education.entity_logo}
                                                name={education.entity_logo}
                                                alt={education.entity_logo}
                                                maxInitials={3}
                                                className={`w-full h-[80px] object-contain`}
                                                color="#e0e6f2"
                                                fgColor="#4d70b3"
                                                round="5"
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {education.description && <div className='border-t border-gray-lines py-2 text-xs'>
                                        {education.description}
                                    </div>}
                                </div>
                            </Card>
                        </div>
                    )
                })}
            {(!professional.education || professional.education.length === 0) && (
              <div className="mt-2 flex-1 flex text-base">
                <NoDataInfo title={t('no_data_registered')} />
              </div>
            )}

                <h3 className={`text-2xl text-main-${Config.PLATFORM} my-8 border-b border-main-${Config.PLATFORM}`}>{t('portfolio')}</h3>
                {professional.portfolio && professional.portfolio.map((portfolioJob) => {
                    return (
                        <div className='my-4'>
                            <Card
                                key={portfolioJob._id}
                                wrapperClassName="transition-transform"
                                containerClassName="divide-gray-lines w-full flex flex-col h-full"
                            >
                                <div className='flex flex-col'>
                                    <div className="flex pb-2 mb-2 ">
                                        <div className='flex flex-row w-full'>
                                            <div className="flex flex-col text-xs min-w-0 w-3/5">
                                                <h3 className={`uppercase font-bold leading-none text-main-${Config.PLATFORM} truncate mb-1`}>
                                                {portfolioJob.title}
                                                {portfolioJob.link && <FaExternalLinkAlt className='inline ml-2 h-4 cursor-pointer' onClick={() => window.open(portfolioJob.link, '_blank')}/>}

                                                </h3>
                                                <div>
                                                    <div className='text-sm'>
                                                    <b>{portfolioJob.company}</b>
                                                    {portfolioJob.company_url && <FaLink className='inline ml-2 h-4 cursor-pointer' onClick={() => window.open(portfolioJob.company_url, '_blank')}/>}
                                                    </div>
                                                    <div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="flex items-center justify-center w-2/5">
                                            <div className="w-full h-[80px] ">
                                                <Avatar
                                                src={portfolioJob.company_logo}
                                                name={portfolioJob.company_logo}
                                                alt={portfolioJob.company_logo}
                                                maxInitials={3}
                                                className={`w-full h-[80px] object-contain`}
                                                color="#e0e6f2"
                                                fgColor="#4d70b3"
                                                round="5"
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {portfolioJob.description && <div className='border-t border-gray-lines py-2 text-xs'>
                                        {portfolioJob.description}
                                    </div>}
                                </div>
                            </Card>
                        </div>
                    )
                })}
{(!professional.portfolio || professional.portfolio.length === 0) && (
  <div className="mt-2 flex-1 flex text-base">
    <NoDataInfo title={t('no_data_registered')} />
  </div>
)}

        </div>
    </SidePanel>
  )
}
