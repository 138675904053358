import { TextArea } from 'components/ui/atoms/TextArea'
import { useTranslation } from 'react-i18next'

export const QuestionTypeTextArea = ({
  currentQuestion,
  onChange,
  value
}) => {
  const { t } = useTranslation()

  return (
    <TextArea
      key={currentQuestion.question_id._id}
      value={value || ''}
      onChange={e => onChange(e.target.value)}
      type="text"
      placeholder={t('type_answer')}
      row={8}
      cols={8}
      style={{ resize: 'none' }}
    />
  )
}
