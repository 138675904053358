import React, { useEffect, useState } from 'react'
import { RoutesLinks } from 'components/routes-links'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import QuestionnairesService from '../../services/questionnaires-service'
import { Loader } from '../ui/molecules/Loader'
import { SectionHeader } from '../ui/molecules/SectionHeader'
import TemplatesModal from './components/templates-modal'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import { Card } from 'components/ui/atoms/Card'
import { Button } from 'components/ui/atoms/Button'
import useUser from '../../hooks/useUser'
import ReactTooltip from 'react-tooltip'
import { PageTitle } from '../ui/atoms/PageTitle'
import moment from 'moment'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'

const Templates = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const { user, isAdmin, isInvestor } = useUser()
  const [templates, setTemplates] = useState([])
  const [showModal, setShowModal] = useState(false)
  const SECTIONS = ['forms', 'questions', 'templates']

  const SECTIONS_URLS = {
    forms: RoutesLinks.QE_FORMS_LINK,
    templates: RoutesLinks.QE_TEMPLATES_LINK,
    questions: RoutesLinks.QE_QUESTIONS_LINK
  }

  const getQuestionnaireTemplates = () => {
    return QuestionnairesService.getQuestionnairesTemplates().then(response => {
      setTemplates(response.data.data)
    }).catch(() => {
      addToast(t('error_getting_templates'), {
        appearance: 'error',
        autoDismiss: true
      })
    })
  }

  const onSubmit = async (values) => {
    return QuestionnairesService.createQuestionnairesTemplate(values).then(() => {
      setShowModal(false)
      return getQuestionnaireTemplates()
    }).catch(() => {
      addToast(t('error_creating_template'), {
        appearance: 'error',
        autoDismiss: true
      })
    })
  }

  const showSectionHeader = () => {
    let sections = []
    if (!isAdmin) sections = user?.investor?.permissions?.sections || []

    return isAdmin || sections.includes('questionnaires_engine')
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [templates])

  useEffect(() => {
    setLoading(true)
    getQuestionnaireTemplates().finally(() => setLoading(false))
  }, [])

  return (
    <>
      <PageTitle title={t(showSectionHeader() ? 'questionnaires_engine' : 'templates')} />

      {!showSectionHeader() && (
        <>
          <h2 className='text-sm'>{t('explore_templates')}</h2>
          <p className='text-gray-500 text-xs'>{t('explore_templates_description')}</p>
        </>
      )}

      {showSectionHeader() && (
        <SectionHeader
          sectionTitles={SECTIONS.map((sec) => t(sec))}
          sectionKeys={SECTIONS}
          setSection={(sec) => history.push(SECTIONS_URLS[sec])}
          section="templates"
        />
      )}

      {!loading && (
        <>
          {isAdmin && (
            <div className="w-full mt-6 mb-8">
              <Card containerClassName="flex flex-col lg:flex-row lg:justify-between">
                <div className={'md:w-full lg:w-10/12'}>
                  <h3>{t('would_you_like_to_create_a_template')}</h3>
                  <p className={'text-xs pr-4'}>{t('admin_create_template_explanation')}</p>
                </div>
                <div className="md:w-full lg:w-2/12 justify-end flex text-center">
                  <Button
                    width="1/2 lg:w-full"
                    text={t('create_template')}
                    onClick={() => {
                      history.push(`${RoutesLinks.QE_TEMPLATES_LINK}/wizard`)
                    }}
                  />
                </div>
              </Card>
            </div>
          )}

          {showModal && (
            <TemplatesModal
              showModal={showModal}
              handleCloseModal={() => setShowModal(false)}
              setShowModal={setShowModal}
              onSubmit={onSubmit}
            />
          )}

          {templates.length !== 0 && (
            <div className="mt-6 grid grid-cols-4 gap-8">
              {templates.map(template => (
                <Card
                  key={template._id}
                  onClick={() => history.push(`${RoutesLinks.QE_TEMPLATES_LINK}/${template._id}`)}
                  wrapperClassName="transition-transform hover:scale-105 cursor-pointer overflow-hidden"
                  movilePadding={'0'}
                  containerClassName='p-0 relative group h-full'
                >
                  <div className='absolute bg-gray-400 flex justify-center items-center w-full h-full -top-full transition-opacity opacity-0 group-hover:top-0 group-hover:opacity-90'>
                    <ButtonMain
                      width={'1/2'}
                      text={t('preview')}
                    />
                  </div>
                  <div className="flex flex-col justify-between">
                    <div
                      className='h-32 bg-cover bg-center border-b border-gray-lines'
                      style={{
                        backgroundImage: `url(${template.logo})`,
                        height: '450px'
                      }}
                    />
                    <div className='p-4'>
                      <div className="w-full text-md font-bold">
                        {template.name}
                      </div>
                      <div className="text-xs font-semibold">
                        {template.investor_name || 'Kiota'}
                      </div>
                      <div className="text-xs flex justify-between">
                        <span>
                          {template.questions?.length ? t('n_questions', { count: template.questions.length }) : t('no_questions')}
                        </span>
                        <span>
                          {moment(template.updatedAt).format('DD/MM/YYYY')}
                        </span>
                      </div>
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          )}

          {templates.length === 0 && (
            <div className='my-6'>
              <NoDataInfo
                title={t('empty_questionnaires_templates')}
                textOne={t(
                  isInvestor
                    ? 'empty_questionnaires_templates_text_one'
                    : 'empty_questionnaires_templates_text_one_admin'
                )}
              />
            </div>
          )}
        </>
      )}

      {loading && <Loader />}
    </>
  )
}

export default Templates
