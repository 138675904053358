import React, { useState, useEffect } from 'react'
import { Base64 } from 'js-base64';
import moment from 'moment';
import { SidePanel } from 'components/ui/objects/SidePanel'

const EmailDetail = ({ email, onClose }) => {

  const [emailData, setEmailData] = useState({});

  const getContentFromEmail = () => {
    const { payload } = email;
    let body;

    if (payload) {
      if (payload.parts) {
        payload.parts.forEach(part => {
          console.log(part.mimeType)
          if (part.mimeType === 'text/plain' || part.mimeType === 'text/html') {
            console.log("===")
            body = part.body.data;
          } else if (part.mimeType === 'multipart/alternative') {
            part.parts.forEach(p => {
              if (p.mimeType === 'text/plain' || p.mimeType === 'text/html') {
                body = p.body.data;
              }
            });
          }
        });
      } else {
        body = payload.body.data;
      }

      if (!body && payload.mimeType === 'multipart/alternative') {
        payload.parts.forEach(part => {
          if (part.mimeType === 'text/plain' || part.mimeType === 'text/html') {
            body = part.body.data;
          }
        });
      }


    }
    const decodedBody = body ? Base64.decode(body) : '';
    return decodedBody;
  }

  useEffect(() => {
    const subject = email?.payload?.headers.find(header => header.name === 'Subject')?.value;
    const from = email?.payload?.headers.find(header => header.name === 'From')?.value;
    const to = email?.payload?.headers.find(header => header.name === 'To')?.value;
    const date = moment(parseInt(email?.internalDate)).format('DD/MM/YYYY');
    const content = getContentFromEmail();

    setEmailData({
      subject,
      date,
      from,
      to,
      content
    })
  }, [email]);

  return (
    <SidePanel
      width="1/2"
      onClose={() => {
        setEmailData({});
        onClose(); 
      }}
      title={emailData.subject}
    >
      <div className="mb-6">
        <p><strong>From:</strong> {emailData.from || ''}</p>
        <p><strong>Para:</strong> {emailData.to || ''}</p>
        <p><strong>Date:</strong> {emailData.date || ''}</p>
      </div>
      <div dangerouslySetInnerHTML={{ __html: emailData.content }} />
    </SidePanel>
  )
}

export default EmailDetail;