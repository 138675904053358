import { useTranslation } from 'react-i18next'
import { useRef, useState } from 'react'
import Settings from '../settings'
import Support from '../support'

import { SectionHeader } from 'components/ui/molecules/SectionHeader'
import AccountPersonalInfo from '../personal-info/AccountPersonalInfo'
import { PageTitle } from '../../../ui/atoms/PageTitle'
import AccountSecurity from '../security/AccountSecurity'
import Config from 'config'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { FaPlus } from 'react-icons/fa'
import { ProfessionalExperience } from 'components/account/ProfessionalExperience'
import { Portfolio } from 'components/account/Portfolio'
import { Education } from 'components/account/Education'
import AccountSocialNetworks from '../social-networks/AccountSocialNetworks'

const ProfessionalScope = () => {
  const { t } = useTranslation()

  const SECTIONS = Config.PLATFORM === '000' ? [
    'personal_information',
    'social_networks',
    'professional_experience',
    'education',
    'portfolio',
    'settings',
    'security',
    'support'
  ] : [
    'personal_information',
    'social_networks',
    'professional_experience',
    'education',
    'portfolio',
    'settings',
    'security'
  ]

  const professionalExperienceRef = useRef()
  const educationRef = useRef()
  const portfolioRef = useRef()

  const components = {
    personal_information: <AccountPersonalInfo />,
    security: <AccountSecurity />,
    settings: <Settings />,
    support: <Support />,
    professional_experience: <ProfessionalExperience ref={professionalExperienceRef}/>,
    education: <Education ref={educationRef}/>,
    portfolio: <Portfolio ref={portfolioRef}/>,
    social_networks: <AccountSocialNetworks />
  }

  const [section, setSection] = useState('personal_information')

  return (
    <>
    <div className="flex items-start">
        <div className="flex-1">
          <PageTitle title={t('my_account')} />
        </div>
        {section === 'professional_experience' && (
          <>
            <ButtonMain
              onClick={() => professionalExperienceRef.current?.showExperienceSection()}
              text={t('add_experience')}
              iconComponent={<FaPlus className="inline-block mr-2" />}
              marginRight="2"
            />
          </>
        )}
        {section === 'education' && (
          <>
            <ButtonMain
              onClick={() => educationRef.current?.showEducationSection()}
              text={t('add_education')}
              iconComponent={<FaPlus className="inline-block mr-2" />}
              marginRight="2"
            />
          </>
        )}
        {section === 'portfolio' && (
          <>
            <ButtonMain
              onClick={() => portfolioRef.current?.showPortfolioJobSection()}
              text={t('add_job_to_portfolio')}
              iconComponent={<FaPlus className="inline-block mr-2" />}
              marginRight="2"
            />
          </>
        )}
    </div>
      <SectionHeader
        sectionTitles={SECTIONS.map((sec) => t(sec))}
        sectionKeys={SECTIONS}
        section={section}
        setSection={(sec) => {
          setSection(sec)
        }}
      />
      <div className="flex  mb-10">
        <div className="w-full py-4 sm:pl-4">{components[section]}</div>
      </div>
    </>
  )
}

export default ProfessionalScope
