import { useTranslation } from 'react-i18next'
import { Modal } from 'components/ui/molecules/Modal'
import { Button } from 'components/ui/atoms/Button'
import { Input } from 'components/ui/atoms/Input'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

const RequestAccessModal = ({
  showModal,
  setShowModal,
  handleCloseModal,
  onSubmit
}) => {
  const { t } = useTranslation()

  const PersonSchema = Yup.object().shape({
    email: Yup.string().email(t('incorrect_format')).required(t('required_field')),
    name: Yup.string().required(t('required_field'))
  })

  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(PersonSchema),
    criteriaMode: 'all',
    reValidateMode: 'all',
    mode: 'onChange'
  })

  return (
    <>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        paddingBottom="2"
        paddingTop="6"
        px="6"
      >
        <h2 className="font-semibold">
          {t('request_access')}
        </h2>
        <p className="font-medium text-gray-dark">
          {t('request_access_external')}
        </p>
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-16">
            <Input
              reference={register}
              id="name"
              type="text"
              name="name"
              label={t('name') + '*:'}
              placeholder={t('name')}
              error={errors.name}
            />
            <Input
              reference={register}
              id="email"
              type="text"
              name="email"
              label={t('email') + '*:'}
              placeholder={t('email')}
              error={errors.email}
            />
          </div>

          <div className="flex justify-between">
            <Button
              type="button"
              bgColor="white"
              width="auto"
              textColor="coolGray-500"
              bgHoverColor="white"
              text={t('cancel')}
              onClick={() => {
                handleCloseModal()
              }}
            />
            <Button
              type="submit"
              bgColor="blue-dark"
              width="auto"
              textColor="white"
              bgHoverColor="blue-dark"
              text={t('request_access')}
            />
          </div>
        </form>
      </Modal>
    </>
  )
}

export default RequestAccessModal
