import axios from 'axios/index'
import Config from '../config'

const AccountService = {
  updatePersonalInfo: async (data) => {
    return axios.patch(`${Config.USERS_API_URL}personal-info`, data)
  },

  updateSocialNetworks: async (data) => {
    return axios.patch(`${Config.USERS_API_URL}social-networks`, data)
  },

  addProfessionalExperience: async (data) => {
    return axios.post(`${Config.USERS_API_URL}professional-experience`, data)
  },

  updateProfessionalExperience: async (professionalExperienceId, data) => {
    return axios.patch(`${Config.USERS_API_URL}professional-experience/${professionalExperienceId}`, data)
  },

  deleteProfessionalExperience: async (professionalExperienceId, data) => {
    return axios.delete(`${Config.USERS_API_URL}professional-experience/${professionalExperienceId}`)
  },

  addEducation: async (data) => {
    return axios.post(`${Config.USERS_API_URL}education`, data)
  },

  updateEducation: async (educationId, data) => {
    console.log(educationId)
    return axios.patch(`${Config.USERS_API_URL}education/${educationId}`, data)
  },

  deleteEducation: async (educationId, data) => {
    return axios.delete(`${Config.USERS_API_URL}education/${educationId}`)
  },

  addJobToPortfolio: async (data) => {
    return axios.post(`${Config.USERS_API_URL}portfolio`, data)
  },

  updateJobFromPortfolio: async (portfolioJobId, data) => {
    return axios.patch(`${Config.USERS_API_URL}portfolio/${portfolioJobId}`, data)
  },

  deleteJobFromPortfolio: async (portfolioJobId, data) => {
    return axios.delete(`${Config.USERS_API_URL}portfolio/${portfolioJobId}`)
  },

  updatePassword: async (values) => {
    return axios.patch(`${Config.USERS_API_URL}password`, values)
  }
}
export default AccountService
