import { Button } from 'components/ui/atoms/Button'
import Document from 'components/project/components/documentation/components/document'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import useUser from 'hooks/useUser'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaChevronCircleDown, FaChevronCircleUp, FaFileUpload, FaPen, FaPlusCircle, FaTrash } from 'react-icons/fa'
import { useToasts } from 'react-toast-notifications'
import DataRoomService from 'services/data-room-service'
import ProjectsService from 'services/projects-service'
import UploadFileModal from '../upload-file-modal'
import SectionModal from './SectionModal'
import { Document as ReactPdfDocument, Page } from 'react-pdf'
import { Loader } from 'components/ui/molecules/Loader'
import { Modal } from 'components/ui/molecules/Modal'
import Download from 'styles/images/download-filled.svg'
import { useSwal } from 'hooks/useSwal'
import StatsModal from '../stats-modal'
import Config from 'config'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'

const DataRoomSections = ({ dataRoom, getDataRoom, setLoading }) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { addToast } = useToasts()

  const [showSectionModal, setShowSectionModal] = useState(false)
  const projectId = user.projects.length ? user.projects[0]._id : null
  const [sectionToEdit, setSectionToEdit] = useState(null)
  const [sectionToUpload, setSectionToUpload] = useState(null)
  const [documentToPreview, setDocumentToPreview] = useState(null)
  const [showPDFModal, setShowPDFModal] = useState(false)

  const [showUploadFileModal, setShowUploadFileModal] = useState(false)
  const [collapsed, setCollapsed] = useState({})
  const { confirm } = useSwal()
  const [showStatsModal, setShowStatsModal] = useState(false)
  const [documentStats, setDocumentStats] = useState([])

  const createSection = async ({ name }) => {
        try {
            setLoading(true)
            await DataRoomService.createSection(projectId, dataRoom._id, {
                name
            })
            setLoading(false)
            getDataRoom()
        } catch (error) {
        addToast(
            error.response && error.response.data.msg_key
            ? error.response.data.msg_key
            : t('error_occurred_creating_section'),
            {
            appearance: 'error',
            autoDismiss: true
            }
        )
        } finally {
            setLoading(false)
        }
    }

    const editSection = async ({ name }) => {
        try {
            setLoading(true)
            await DataRoomService.editSection(projectId, dataRoom._id, sectionToEdit._id, {
                name
            })
            setLoading(false)
            setSectionToEdit(null)
            getDataRoom()
        } catch (error) {
        addToast(
            error.response && error.response.data.msg_key
            ? error.response.data.msg_key
            : t('error_occurred_updating_section'),
            {
            appearance: 'error',
            autoDismiss: true
            }
        )
        } finally {
            setLoading(false)
        }
    }

    const deleteSection = async (sectionId) => {
      confirm({
        text: t('delete_section_warning'),
        icon: 'warning'
      }).then(async (isConfirmed) => {
        if (isConfirmed) {
          try {

              setLoading(true)
              await DataRoomService.deleteSection(projectId, dataRoom._id, sectionId)
              setLoading(false)
              getDataRoom()
          } catch (error) {
          addToast(
              error.response && error.response.data.msg_key
              ? error.response.data.msg_key
              : t('error_occurred_deleting_section'),
              {
              appearance: 'error',
              autoDismiss: true
              }
          )
          } finally {
              setLoading(false)
          }
        }
      });
    }

    useEffect(() => {
        if (sectionToEdit) {
            setShowSectionModal(true)
        } else {
            setShowSectionModal(false)
        }
    }, [sectionToEdit])

    useEffect(() => {
        if (sectionToUpload) {
            setShowUploadFileModal(true)
        } else {
            setShowUploadFileModal(false)
        }
    }, [sectionToUpload])

    const uploadFile = async (file, section, downloadable) => {
        try {
          setLoading(true)
        const formData = new FormData()
        
        formData.append('file', file)
        formData.append('section', section)
        formData.append('downloadable', downloadable)

        await ProjectsService.uploadFileFromDeviceToDataRoom(
            projectId,
            dataRoom._id,
            formData
        )
          getDataRoom()
          addToast(t('file_uploaded_successfully'), {
            appearance: 'success',
            autoDismiss: true
          })
        } catch (error) {
          addToast(
            error.response && error.response.data.msg_key
              ? t(error.response.data.msg_key)
              : t('error_occurred_uploading_file'),
            {
              appearance: 'error',
              autoDismiss: true
            }
          )
          console.log(error)
        } finally {
          setLoading(false)
        }
      }

      const downloadFile = async (documentId, section) => {
        try {
          setLoading(true)
          const resp = await ProjectsService.downloadDocument(
            dataRoom._id,
            documentId,
            section
          )
          if (resp.data && resp.data.url !== '') {
            window.open(resp.data.url, '_blank')
          }
        } catch (error) {
          addToast(
            error.response && error.response.data.msg_key
              ? t(error.response.data.msg_key)
              : t('error_occurred_downloading_file'),
            {
              appearance: 'error',
              autoDismiss: true
            }
          )
        } finally {
          setLoading(false)
        }
      }
    
      const previewFile = async (fileId, section) => {
        try {
          setLoading(true)
          const resp = await ProjectsService.downloadDocument(
            dataRoom._id,
            fileId,
            section
          )
          setDocumentToPreview(resp.data.url)
          setShowPDFModal(true)
        } catch (error) {
          addToast(
            error.response && error.response.data.msg_key
              ? t(error.response.data.msg_key)
              : t('error_occurred_retrieving_file'),
            {
              appearance: 'error',
              autoDismiss: true
            }
          )
        } finally {
          setLoading(false)
        }
      }

      const [
        showDownloadableUnavailableModal,
        setShowDownloadableUnavailableModal
      ] = useState(false)

      const getDocuments = (sectionId) => {
        const documents = dataRoom.documents.filter((document) => document.section === sectionId)
    
        if (documents?.length) {
          return (
            <div className="grid gap-4 xs:grid sm:grid-cols-3 lg:grid-cols-5 my-10">
              {documents.map((document, index) => {
                return (
                  <Document
                    key={index}
                    section={sectionId}
                    popoverMenu={true}
                    popoverMenuOptions={
                      ['statistics', 'delete']
                    }
                    popoverMenuMethods={{
                        statistics: () => {
                          const guestsStats = []
                          dataRoom.guests.forEach((guest) => {
                              const guestDownloads = document.events.filter(
                              (event) => event.user === guest._id && event.type === 'download'
                              )
                              const guestPreviews = document.events.filter(
                                (event) => event.user === guest._id && event.type === 'preview'
                                )
                              guestsStats.push({
                              name: `${guest.name} ${guest.last_name}`,
                              downloads: guestDownloads
                                  ? guestDownloads.length
                                  : 0,
                              previews: guestPreviews
                                  ? guestPreviews.length
                                  : 0,
                              last_download: guestDownloads.length
                                  ? guestDownloads[
                                  guestDownloads.length - 1
                                  ].date
                                  : null,
                              last_preview: guestPreviews.length
                                  ? guestPreviews[
                                    guestPreviews.length - 1
                                  ].date
                                  : null
                              })
                          })
                          setDocumentStats({
                              downloads: document
                              ? document.events.filter(
                                  (e) =>
                                  e.type === undefined ||
                                  e.type === 'download'
                              ).length
                              : 0,
                              previews: document
                              ? document.events.filter(
                                  (e) => e.type === 'preview'
                              ).length
                              : 0,
                              guests: guestsStats,
                              section: document.document.name
                          })
                        setShowStatsModal(true)
                        },
                        delete: () => deleteDocument(document._id, sectionId)
                    }}
                    document={{
                      name: document.document.name,
                      _id: document._id,
                      section: sectionId,
                      downloadable: document.downloadable,
                      uploaded_at: document.uploaded_at
                    }}
                    handleUploadFile={null}
                    downloadFile={
                      document.downloadable
                        ? () =>
                            downloadFile(
                              document._id,
                              sectionId
                            )
                        : () =>
                            setShowDownloadableUnavailableModal(true)
                    }
                    viewFile={
                      ['pdf', 'PDF'].includes(document.document.name
                        .split('.')
                        .at(-1))
                        ? () =>
                            previewFile(document._id, sectionId)
                        : null
                    }
                    documentSpecialized={false}
                    dataRoomLanding={true}
                  />
                )
              })}
          </div>
          )
        } else {
          return (
            <div className='my-10'>
              <NoDataInfo title={t('documents_not_found')} />
            </div>
          )
        }
      }

      const [numPages, setNumPages] = useState(null)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  const expandOrCollapse = (elementId) => {
    setCollapsed({ ...collapsed, [elementId]: !collapsed[elementId] })
    const element = document.getElementById(elementId)
    element.classList.toggle('hidden')
  }

  const deleteDocument = async (documentId, section) => {
    confirm({
      icon: 'warning'
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true)
          await DataRoomService.deleteDocument(
            projectId,
            dataRoom._id,
            documentId,
            section
          )

          addToast(t('document_successfully_deleted'), {
            appearance: 'success',
            autoDismiss: true
          })
          setLoading(false)
          getDataRoom()
        } catch (error) {
          addToast(
            error.response && error.response.data.msg_key
              ? error.response.data.msg_key
              : t('error_occurred_deleting_document'),
            {
              appearance: 'error',
              autoDismiss: true
            }
          )
        } finally {
          setLoading(false)
        }
      }
    })
  }

    return (
        <>
            {showStatsModal && (
              <StatsModal
                showModal={showStatsModal}
                setShowModal={setShowStatsModal}
                documentStats={documentStats}
                setDocumentStats={setDocumentStats}
              />
            )}
            <Modal
                showModal={showDownloadableUnavailableModal}
                showCloseModal={true}
                onClose={() => {
                    setShowDownloadableUnavailableModal(false)
                }}
                fixedWidth={'w-10/12'}
            >
                <NoDataInfo
                image={
                    <div className={'flex items-center justify-center'}>
                    <img src={Download} className={'w-40'} alt={t('document_not_downloadable')} />
                    </div>
                }
                title={t('document_not_downloadable')}
                textOne={t('document_not_downloadable_subtitle')}
                />
            </Modal>
            {showPDFModal && documentToPreview && (<Modal
                showModal={showPDFModal && documentToPreview}
                showCloseModal={true}
                onClose={() => {
                  setShowPDFModal(false)
                  setDocumentToPreview(null)
                }}
                fixedWidth={'w-10/12'}
            >
                <div className={'h-96 overflow-y-scroll overflow-x-hidden pdf'}>
                    <ReactPdfDocument
                        file={{
                        url: documentToPreview
                        }}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={<Loader />}
                    >
                        {Array.from(new Array(numPages), (el, index) => (
                        <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            loading={<Loader />}
                        />
                        ))}{' '}
                    </ReactPdfDocument>
                </div>
            </Modal>)}
            {showUploadFileModal && (
              <UploadFileModal
                section={sectionToUpload}
                documents={[]}
                showModal={showUploadFileModal}
                onClose={() => {
                  setShowUploadFileModal(false)
                  setSectionToUpload(null)
                }}
                onSubmit={uploadFile}
                accept={'video/mp4, video/avi, video/mov, .doc, .docx, .pdf, .ppt, .pptx, .xls, .xlsx, .zip'}
              />
            )}
            {showSectionModal && (
                <SectionModal
                    showModal={showSectionModal}
                    onClose={() => {
                        setShowSectionModal(false)
                        setSectionToEdit(null)
                    }}
                    onSubmit={sectionToEdit ? editSection : createSection}
                    name={sectionToEdit?.name}
                />
            )}
            <div className='my-10'>
                <div className={`flex border-b border-main-${Config.PLATFORM} mb-4 items-start`}>
                    <div className='flex-1'>
                      <h3 className={`text-2xl text-main-${Config.PLATFORM} mt-4`}>{t('sections')}</h3>
                    </div>
                    <ButtonSecondary
                        iconComponent={<FaPlusCircle className='inline-block mr-2' />}
                        text={t('create_section')}
                        onClick={() => setShowSectionModal(true)}
                    />
                </div>
                <div>
                    <small>{t('data_room_sections_explanation')}</small>
                    <div className={'my-4'}>
                        {dataRoom.sections?.map((section) => {
                          return (
                            <div key={section._id}>
                              <div
                                className="border-b border-gray-lines font-bold flex flex-row justify-between hover:bg-gray-100 px-2 hover:rounded-md transition-colors mt-6 text-xl"
                              >
                                <span className='cursor-pointer' onClick={() => expandOrCollapse(section._id)}>{section.name}</span>
                                <div>
                                  <FaFileUpload
                                    onClick={() => {
                                      setSectionToUpload(section)
                                    }}
                                    data-panel={parent._id}
                                    className={`text-main-${Config.PLATFORM} inline-block mt-1 mr-2 cursor-pointer`}
                                  />
                                  {!section.required && (
                                    <>
                                      <FaPen
                                        onClick={() => {
                                          setSectionToEdit(section)
                                        }}
                                        data-panel={parent._id}
                                        className={`text-main-${Config.PLATFORM} inline-block mt-1 mr-2 cursor-pointer`}
                                      />
                                      <FaTrash
                                        data-panel={section._id}
                                        className={`text-main-${Config.PLATFORM} inline-block mt-1 mr-2 cursor-pointer`}
                                        onClick={() => deleteSection(section._id)}
                                      />
                                    </>
                                  )}
                                  {collapsed[section._id] ? <FaChevronCircleDown
                                    onClick={() => expandOrCollapse(section._id)}
                                    data-panel={section._id}
                                    className={`text-main-${Config.PLATFORM} inline-block mt-1 cursor-pointer`}
                                  /> : <FaChevronCircleUp
                                  data-panel={section._id}
                                  onClick={() => expandOrCollapse(section._id)}
                                  className={`text-main-${Config.PLATFORM} inline-block mt-1 cursor-pointer`}
                                />}
                              </div>
                            </div>
                            <div id={section._id} className={'mt-2'}>
                                {getDocuments(section._id)}
                            </div>
                            </div>
                          )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default DataRoomSections
