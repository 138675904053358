import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import { Card } from 'components/ui/atoms/Card'
import { ButtonCardMain } from 'components/ui/atoms/ButtonCardMain'
import { FaEye } from 'react-icons/fa'
import Config from 'config'
import moment from 'moment'
import { RoutesLinks } from 'components/routes-links'

const EvaluationDealflow = ({ deal }) => {
  const { t } = useTranslation()

  const history = useHistory()
  const myRef = useRef(null)

  return (
    <div ref={myRef}>
      {(!deal?.events || deal?.events?.length === 0) && <div className='my-6'><NoDataInfo title={t('dealflow_without_evaluation_processes')} /></div>}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 my-10">
        {deal?.events?.length > 0 && (
          deal.events.map((event, index) => {
            return (
              <Card key={event._id}>
                  <h5 className={`uppercase font-bold text-sm leading-none text-main-${Config.PLATFORM} truncate mb-1`}>
                    {event.event.name}
                    <span
                      className={`inline-block ml-2 mt-1 font-semibold text-xxs px-2 py-0.5 rounded-md border border-gray-lines text-text-buttons-secondary-${Config.PLATFORM}`}
                    >
                      {t(event.discarded ? 'discarded' : 'admitted')}
                    </span>
                  </h5>
                  <div className='text-xs'>{moment(event.date).format('YYYY-MM-DD')}</div>

                  <div className={`flex justify-end mt-4 pt-2 border-t border-separator-${Config.PLATFORM}`}>
                    <ButtonCardMain
                      onClick={() => history.push(`${RoutesLinks.EVALUATION_PROCESSES_LINK}${event.event._id}`)}
                      text={t('see_details')}
                      iconComponent={<FaEye className="inline-block w-6 h-3" />}
                    />
                  </div>
              </Card>
            )
          })
        )}
      </div>
    </div>
  )
}

export default EvaluationDealflow
