import moment from 'moment'
import { FaCalendar, FaEye, FaTrash, FaUser, TbWriting, MdKeyboardVoice, MdTextFields } from 'react-icons/all'
import { useTranslation } from 'react-i18next'
import { Button } from '../atoms/Button'

import Config from 'config';
import { ButtonDanger } from '../atoms/ButtonDanger';
import { ButtonCardMain } from '../atoms/ButtonCardMain';

export function BasicCardNotes ({ deleteNote, note, getNote }) {
  const { t } = useTranslation()
  return (
    <div>
        <div className="flex flex-col justify-between items-between | shadow-soft-white rounded-2xl | p-2 border border-gray-lines">
          <div className="text-left leading-none flex flex-col justify-center items-start h-full px-1">
            {note.type === 'audio' && (
              <MdKeyboardVoice className={`inline-block mb-1 text-lg text-main-${Config.PLATFORM}`} />
            )}
            {note.type === 'text' && (
              <MdTextFields className={`inline-block mb-1 text-lg text-main-${Config.PLATFORM}`} />
            )}
            {note.type === 'draw' && (
              <TbWriting className={`inline-block mb-1 text-lg text-main-${Config.PLATFORM}`} />
            )}
            <p className="text-xs h-auto break-all mb-1">{note.title}</p>
          </div>
          <div className="flex w-full justify-between items-center border-t border-gray-lines px-1 pt-1">
            <span className="text-xxxs text-gray">
              <FaCalendar className="inline-block mr-1" />
              {moment(note.createdAt).format('YYYY-MM-DD')}
            </span>
          </div>
          <div className="flex w-full border-t border-gray-lines px-1 pt-1">
            <span className="text-xxxs text-gray">
              <FaUser className="inline-block mr-1" />
              {note.user?.name || note?.shared_deal_person_name || t('no_data')}
            </span>
          </div>
          <div className={`flex justify-end border-t border-separator-${Config.PLATFORM} pt-2 mt-4`}>
            {deleteNote && <ButtonDanger
            type="button"
            onClick={() => deleteNote(note._id)}
            iconComponent={<FaTrash className="inline-block w-5 h-4" />}
            marginRight={2}
            text={t('delete')}
            />}
            <ButtonCardMain
              type="button"
              text={t('see')}
              onClick={() => getNote(note._id)}
              iconComponent={<FaEye className="inline-block w-5 h-4" />}
            />
          </div>
        </div>
    </div>
  )
}
