import moment from 'moment'
import { Button } from 'components/ui/atoms/Button'
import { FaDownload, FaTags, FaTrashAlt } from 'react-icons/all'
import { useState } from 'react'
import { ManageTagsModal } from '../../tags/ManageTagsModal'
import Avatar from 'react-avatar'
import { DocumentIcon } from '../../documentation/documents/components/DocumentIcon'
import Utils from 'utils/utils'
import { ButtonSecondary } from './ButtonSecondary'
import { ButtonCardSecondary } from './ButtonCardSecondary'
import { ButtonDanger } from './ButtonDanger'

export function Document ({
  document,
  downloadDocument,
  deleteDocument,
  manageTags,
  tags
}) {
  const [showManageTagsModal, setShowManageTagsModal] = useState(false)

  return <>
    {showManageTagsModal && (
      <ManageTagsModal
        currentTags={document.tags}
        tags={tags}
        showModal={showManageTagsModal}
        handleCloseModal={() => setShowManageTagsModal(false)}
        onSubmit={manageTags}
      />
    )}

    <div key={document._id} className='p-4 shadow-soft-white rounded-2xl border-gray-lines border'>
      <div >
        <div className='flex flex-row justify-end mb-4'>
          {document.tags && document.tags.map(tag => (
            <span
              key={tag._id}
              data-tip={tag.name}
              className="inline-block mr-2"
            >
              <Avatar
                size="30"
                name={tag.name}
                round={true}
                color={tag.color}
                fgColor={Utils.saturateColor(tag.color, 0.7)}
                alt={tag.name}
              />
            </span>
          ))}
        </div>

        <div className='flex w-full flex-row justify-center'>
          <DocumentIcon documentName={document.name} />
        </div>

        <div className='my-4'>
          <div className={'text-lg font-semibold truncate pr-2'}>
            {document.name.split('.')[0]}
          </div>
          <div className='text-grey-darker text-xs'>
            {moment(document.updatedAt).format('YYYY-MM-DD HH:MM')}
          </div>
        </div>

        <div className='flex items-center justify-between border-t border-gray-lines pt-4'>
          <div className='flex flex-row justify-between w-full'>
            <div className='flex justify-center'>
              {document.startup && (
                <div>
                  <Avatar
                    src={document.startup.logo}
                    size="30"
                    round={true}
                    color="#e0e6f2"
                    fgColor="#4d70b3"
                    alt={document.startup.name}
                  />
                  <span className="ml-2">{document.startup.name}</span>
                </div>
              )}
            </div>
            <div className='flex flex-row'>
              <ButtonSecondary
                marginRight="2"
                onClick={() => {
                  setShowManageTagsModal(true)
                }}
                iconComponent={ <FaTags className="inline" /> }
              />
              <ButtonCardSecondary
                marginRight="2"
                onClick={() => {
                  downloadDocument(document._id)
                }}
                iconComponent={ <FaDownload className="inline" /> }

              />
              <ButtonDanger
                onClick={() => {
                  deleteDocument(document._id)
                }}
                iconComponent={ <FaTrashAlt className="inline" /> }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
}
