import axios from 'axios/index'
import Config from '../config'
import moment from 'moment'

const ProjectsService = {
  getTotalStartups: async (params) => {
    const query = new URLSearchParams(params)
    return axios.get(`${Config.PROJECTS_API_URL}total?${query}`)
  },

  getLatestStartups: async (params) => {
    const query = new URLSearchParams(params)
    return axios.get(`${Config.PROJECTS_API_URL}latest?${query}`)
  },

  getProjects: async (params) => {
    const searchParams = new URLSearchParams(params)

    if (params.selectedDateType) {
      if (params.selectedDateType === 'custom') {
        if (params.selectedDate) {
          if (params.selectedDate[0]) {
            searchParams.append('date_from', params.selectedDate[0])
          }
          if (params.selectedDate[1]) {
            searchParams.append('date_to', params.selectedDate[1])
          }
        }
      } else {
        const to = moment()
        const from = moment().subtract(params.selectedDateType, 'd')
        searchParams.append('date_from', from.format('YYYY-MM-DD'))
        searchParams.append('date_to', to.format('YYYY-MM-DD'))
      }
    }

    if (params.score) {
      searchParams.append('score_from', params.score[0])
      searchParams.append('score_to', params.score[1])
    }

    // clean up
    const keysForDel = []
    searchParams.forEach((value, key) => {
      if (
        [undefined, null, '', 'undefined', 'null', false, 'false'].includes(
          value
        )
      ) {
        keysForDel.push(key)
      }
    })

    keysForDel.forEach((key) => {
      searchParams.delete(key)
    })

    return axios.get(`${Config.PROJECTS_API_URL}?${searchParams}`)
  },

  filterProjects: async (params) => {
    let query = ''

    if (params.name) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}name=${params.name}`
    }

    return axios.get(`${Config.PROJECTS_API_URL}/filter${query}`, {})
  },

  getAllProjects: async () => {
    return axios.get(`${Config.PROJECTS_API_URL}/getAll`, {})
  },

  getAugmentedInformation: async (filters) => {
    let query = ''

    if (filters.sector) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}sector=${filters.sector}`
    }

    if (filters.country) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}country=${filters.country}`
    }

    if (filters.investment_stage) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}investment_stage=${filters.investment_stage}`
    }

    return axios.get(
      `${Config.PROJECTS_API_URL}/augmented-information${query}`
    )
  },

  getProject: async (projectId) => {
    return axios.get(`${Config.PROJECTS_API_URL}${projectId}`)
  },

  getQuestionnaires: async (projectId) => {
    return axios.get(
      `${Config.QUESTIONNAIRES_API_URL}?project_id=${projectId}`
    )
  },

  getOutliers: async (projectId) => {
    return axios.get(`${Config.OUTLIERS_API_URL}?project_id=${projectId}`)
  },

  getSectors: async () => {
    return axios.get(`${Config.PROJECTS_API_URL}sectors`)
  },

  getCities: async () => {
    return axios.get(`${Config.PROJECTS_API_URL}cities`)
  },

  getRoles: async () => {
    return axios.get(`${Config.PROJECTS_API_URL}roles`)
  },

  getCountries: async () => {
    return axios.get(`${Config.PROJECTS_API_URL}countries`)
  },

  getBusinessModels: async () => {
    return axios.get(`${Config.PROJECTS_API_URL}business-models`)
  },

  getProjectStages: async () => {
    return axios.get(`${Config.PROJECTS_API_URL}project-stages`)
  },

  getInvestmentStages: async () => {
    return axios.get(`${Config.PROJECTS_API_URL}investment-stages`)
  },

  getJoinPortfolioRequests: async (projectId) => {
    return axios.get(`${Config.PROJECTS_API_URL}${projectId}/requests`)
  },

  checkJoinCode: async (code) => {
    return axios.get(`${Config.PROJECTS_API_URL}join-code`, {
      params: { code }
    })
  },

  joinProject: async (invitationId) => {
    return axios.post(`${Config.PROJECTS_API_URL}join`, {
      invitation_id: invitationId
    })
  },

  declineInvitation: async (invitationId) => {
    return axios.post(`${Config.PROJECTS_API_URL}decline-invitation`, {
      invitation_id: invitationId
    })
  },

  checkId: async (projectId) => {
    return axios.get(`${Config.PROJECTS_API_URL}${projectId}/check-id`)
  },

  createProject: async (data) => {
    return axios.post(`${Config.PROJECTS_API_URL}users`, data)
  },

  updateProject: async (projectId, data) => {
    return axios.patch(`${Config.PROJECTS_API_URL}${projectId}`, data)
  },

  getSector: async (sectorId) => {
    return axios.get(`${Config.PROJECTS_API_URL}sectors/${sectorId}`)
  },

  importReport: async (projectId, data) => {
    return axios.post(`${Config.PROJECTS_API_URL}${projectId}/report`, data)
  },

  getInvitations: async (params) => {
    let query = ''

    if (params.page !== null) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}page=${params.page}`
    }

    if (params.sortBy) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}sortBy=${params.sortBy}`
    }

    if (params.rowsPerPage) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}rowsPerPage=${params.rowsPerPage}`
    }

    return axios.get(`${Config.PROJECTS_API_URL}invitations${query}`)
  },

  getInvitationsToPortfolio: async (projectId) => {
    return axios.get(`${Config.PROJECTS_API_URL}${projectId}/invitations`)
  },

  addMember: async (projectId, data) => {
    return axios.post(
      `${Config.PROJECTS_API_URL}${projectId}/add-member`,
      data
    )
  },

  updateMember: async (projectId, data, memberId) => {
    return axios.patch(
      `${Config.PROJECTS_API_URL}${projectId}/update-member/${memberId}`,
      data
    )
  },

  deleteMember: async (projectId, memberId) => {
    return axios.delete(
      `${Config.PROJECTS_API_URL}${projectId}/member/${memberId}`
    )
  },

  checkStartupCoincidences: async (startupName, founderEmail) => {
    return axios.post(`${Config.PROJECTS_API_URL}check-coincidences`, {
      startup_name: startupName,
      founder_email: founderEmail
    })
  },

  createProjectByInvestor: async (data) => {
    return axios.post(`${Config.PROJECTS_API_URL}invite`, data)
  },

  addProjectToInvestor: async (projectId, data) => {
    return axios.patch(
      `${Config.PROJECTS_API_URL}${projectId}/add-investor`,
      data
    )
  },

  getAvailableInvestors: async (projectId) => {
    return await axios.get(
      `${Config.PROJECTS_API_URL}deals-rooms/available-investors?project_id=${projectId}`
    )
  },

  getDataRoom: async (dataroomId) => {
    return await axios.get(`${Config.PROJECTS_API_URL}data-room/${dataroomId}`)
  },

  getSharedDataRoom: async (dataroomId, accessCode) => {
    return await axios.post(
      `${Config.PROJECTS_API_BASE_URL}shared/data-room/${dataroomId}`, { code: accessCode }
    )
  },

  deleteDataRoomFile: async (dataRoomId, fileId) => {
    return await axios.delete(
      `${Config.PROJECTS_API_URL}data-room/${dataRoomId}/file/${fileId}`
    )
  },

  createDataRoomFile: async (dataRoomId, data) => {
    return await axios.post(
      `${Config.PROJECTS_API_URL}data-room/${dataRoomId}/file`,
      data
    )
  },

  downloadDocument: async (dataRoomId, documentId, section) => {
    return await axios.get(
      `${Config.PROJECTS_API_URL}data-room/${dataRoomId}/documents/${documentId}?section=${section}`
    )
  },

  getSharedDataRoomDocument: async (dataRoomId, documentId, section) => {
    return await axios.get(
      `${Config.PROJECTS_API_BASE_URL}shared/data-room/${dataRoomId}/documents/${documentId}?section=${section}`
    )
  },

  getDataRoomAllFiles: async (dataRoomId) => {
    return await axios.get(
      `${Config.PROJECTS_API_BASE_URL}shared/data-room/${dataRoomId}/all-files`
    )
  },

  uploadFile: async (projectId, data) => {
    return await axios.post(
      `${Config.PROJECTS_API_URL}${projectId}/documents`,
      data
    )
  },

  downloadResource: async (projectId, type, resourceId) => {
    return await axios.get(
      `${Config.PROJECTS_API_URL}${projectId}/${type}/${resourceId}`
    )
  },

  updateUserProperties: async (
    projectId,
    userId,
    userPropertyId,
    property,
    value
  ) => {
    return await axios.patch(
      `${Config.PROJECTS_API_URL}${projectId}/userProperties`,
      {
        userId,
        userPropertyId,
        property,
        value
      }
    )
  },

  checkInvitationsByEmail: async (data) => {
    return axios.post(`${Config.PROJECTS_API_URL}invitations/check`, data)
  },

  getInvestors: async (projectId) => {
    return axios.get(`${Config.PROJECTS_API_URL}${projectId}/investors`)
  },

  getProfessionals: async (projectId) => {
    return axios.get(`${Config.PROJECTS_API_URL}${projectId}/professionals`)
  },

  getNotes: async (projectId) => {
    return axios.get(`${Config.PROJECTS_API_URL}${projectId}/notes`)
  },

  createNote: async (projectId, title, type, content) => {
    return axios.post(`${Config.PROJECTS_API_URL}${projectId}/notes`, {
      title,
      type,
      content
    })
  },

  deleteNote: async (projectId, noteId) => {
    return axios.delete(
      `${Config.PROJECTS_API_URL}${projectId}/notes/${noteId}`
    )
  },

  getNote: async (projectId, noteId) => {
    return axios.get(`${Config.PROJECTS_API_URL}${projectId}/notes/${noteId}`)
  },

  shareDataRoom: async (projectId, dataRoomId, name, lastName, email) => {
    return await axios.post(
      `${Config.PROJECTS_API_URL}${projectId}/data-room/${dataRoomId}/share`,
      { name, last_name: lastName, email }
    )
  },

  updateNote: async (projectId, noteId, title, content) => {
    return axios.patch(
      `${Config.PROJECTS_API_URL}${projectId}/notes/${noteId}`,
      {
        title,
        content
      }
    )
  },

  acceptPortfolioInvitation: async (projectId, invitationId) => {
    return axios.patch(
      `${Config.PROJECTS_API_URL}${projectId}/invitations/${invitationId}/accept`
    )
  },

  rejectPortfolioInvitation: async (projectId, invitationId) => {
    return axios.patch(
      `${Config.PROJECTS_API_URL}${projectId}/invitations/${invitationId}/reject`
    )
  },

  getDocuments: async (projectId) => {
    return axios.get(`${Config.PROJECTS_API_URL}${projectId}/documents`, {})
  },

  getDocumentsPaginated: async (projectId, params) => {
    const searchParams = new URLSearchParams(params)
    return axios.get(
      `${Config.PROJECTS_API_URL}${projectId}/documents-paginated?${searchParams}`
    )
  },

  getDocument: async (projectId, fileId) => {
    return await axios.get(
      `${Config.PROJECTS_API_URL}${projectId}/documents/${fileId}`
    )
  },

  getDataRooms: async (projectId) => {
    return await axios.get(`${Config.PROJECTS_API_URL}${projectId}/data-rooms`)
  },

  createDataRoom: async (projectId, data) => {
    return await axios.post(
      `${Config.PROJECTS_API_URL}${projectId}/data-rooms`,
      data
    )
  },

  uploadFileFromDeviceToDataRoom: async (projectId, dataRoomId, data) => {
    return await axios.post(
      `${Config.PROJECTS_API_URL}${projectId}/data-rooms/${dataRoomId}/file-device`,
      data
    )
  },

  uploadFileFromDocumentsToDataRoom: async (projectId, dataRoomId, data) => {
    return await axios.post(
      `${Config.PROJECTS_API_URL}${projectId}/data-rooms/${dataRoomId}/file-documents`,
      data
    )
  },

  deleteDataRoom: async (projectId, dataRoomId) => {
    return axios.delete(
      `${Config.PROJECTS_API_URL}${projectId}/data-rooms/${dataRoomId}`
    )
  },

  deleteDocument: async (projectId, documentId) => {
    return await axios.delete(
      `${Config.PROJECTS_API_URL}${projectId}/documents/${documentId}`
    )
  },

  signDocument: async (projectId, documentId) => {
    return await axios.patch(
      `${Config.PROJECTS_API_URL}${projectId}/documents/${documentId}/sign`
    )
  },

  getDocumentationStats: async (projectId) => {
    return await axios.get(
      `${Config.PROJECTS_API_URL}${projectId}/documentation/stats`
    )
  },

  createFunnelBoard: async (projectId, name, columns = []) => {
    return axios.post(`${Config.PROJECTS_API_URL}${projectId}/funnel/boards`, {
      name,
      columns
    })
  },

  getFunnelBoards: async (projectId) => {
    return axios.get(`${Config.PROJECTS_API_URL}${projectId}/funnel/boards`)
  },

  getFunnelBoard: async (projectId, boardId) => {
    return axios.get(
      `${Config.PROJECTS_API_URL}${projectId}/funnel/boards/${boardId}`
    )
  },

  deleteFunnelBoard: async (projectId, boardId) => {
    return axios.delete(
      `${Config.PROJECTS_API_URL}${projectId}/funnel/boards/${boardId}`
    )
  },

  updateFunnelBoard: async (projectId, boardId, data) => {
    return axios.patch(
      `${Config.PROJECTS_API_URL}${projectId}/funnel/boards/${boardId}`,
      data
    )
  },

  deleteFunnelColumn: async (projectId, boardId, columnId) => {
    return axios.delete(
      `${Config.PROJECTS_API_URL}${projectId}/funnel/boards/${boardId}/columns/${columnId}`
    )
  },

  updateFunnelColumn: async (projectId, boardId, columnId, data) => {
    return axios.patch(
      `${Config.PROJECTS_API_URL}${projectId}/funnel/boards/${boardId}/columns/${columnId}`,
      data
    )
  },

  deleteFunnelCard: async (projectId, boardId, columnId, cardId) => {
    return axios.delete(
      `${Config.PROJECTS_API_URL}${projectId}/funnel/boards/${boardId}/columns/${columnId}/cards/${cardId}`
    )
  },

  createFunnelColumn: async (projectId, boardId, name) => {
    return axios.post(
      `${Config.PROJECTS_API_URL}${projectId}/funnel/boards/${boardId}/columns/`,
      {
        name
      }
    )
  },

  createFunnelCard: async (projectId, boardId, columnId, investor) => {
    return axios.post(
      `${Config.PROJECTS_API_URL}${projectId}/funnel/boards/${boardId}/columns/${columnId}/cards`,
      {
        investor
      }
    )
  },

  moveFunnelCard: async (projectId, boardId, cardId, data) => {
    return axios.patch(
      `${Config.PROJECTS_API_URL}${projectId}/funnel/boards/${boardId}/cards/${cardId}`,
      data
    )
  },

  sortFunnelCard: async (projectId, boardId, columnId, cardId, data) => {
    return axios.patch(
      `${Config.PROJECTS_API_URL}${projectId}/funnel/boards/${boardId}/columns/${columnId}/cards/${cardId}/sort`,
      data
    )
  },

  getStartupsByInvestor: async (investorId) => {
    return axios.get(
      `${Config.PROJECTS_API_URL}by-investor?investor=${investorId}`
    )
  },

  getKiotaExpressEvaluationPDF: async (startupId) => {
    return axios.get(
      `${Config.PROJECTS_API_URL}${startupId}/kiota-express-evaluation-pdf`
    )
  },

  shareKiotaExpressWithInvestors: async (option) => {
    return axios.post(
      `${Config.PROJECTS_API_URL}share-kiota-express-with-investors`,
      {
        public_kiota_express_report: option
      }
    )
  },

  shareKiotaExpressEvaluation: async (startupId, data) => {
    return axios.post(
      `${Config.PROJECTS_API_URL}${startupId}/share-kiota-express-evaluation`,
      data
    )
  },

  updateMatchingScore: async (startupId, data) => {
    return axios.patch(
      `${Config.PROJECTS_API_URL}${startupId}/matching-score`,
      data
    )
  },

  shareDealflow: async (source, startup, investors) => {
    return axios.post(`${Config.PROJECTS_API_URL}dealflow/share`, {
      source,
      startup,
      investors
    })
  },

  shareDealflowWithExternal: async (source, startup, email) => {
    return axios.post(`${Config.PROJECTS_API_URL}dealflow/share-external`, {
      source,
      startup,
      email
    })
  },

  manageAccess: async (startupId, investorId, projectAccessGranted) => {
    return axios.post(
      `${Config.PROJECTS_API_URL}${startupId}/access`, { investor: investorId, project_access_granted: projectAccessGranted }
    )
  },

  getExploreProjects: async (params) => {
    return axios.post(`${Config.PROJECTS_API_URL}explore`, params)
  },

  getExploreInvestors: async (params) => {
    return axios.get(`${Config.PROJECTS_API_URL}explore/investors`, { params })
  },

  getExploreProfessionals: async (params) => {
    return axios.get(`${Config.PROJECTS_API_URL}explore/professionals`, { params })
  },

  /**
   * @param data {{type: 'manual' | 'import', email?: string, founder?: string, startup?: string, invitations?: {email: string, founder: string, startup: string}[]}}
   * @returns {Promise<AxiosResponse<any>>}
   */
  inviteStartup: async (data) => {
    return axios.post(`${Config.PROJECTS_API_URL}invite-dealflow`, data)
  },

  connectInvestor: async (startupId, investorId) => {
    return axios.post(`${Config.PROJECTS_API_URL}dealflow/investor/${startupId}/${investorId}`, {})
  },

  requestAccess: async (startupId, data) => {
    return axios.post(`${Config.PROJECTS_API_URL}${startupId}/public-profile/request-access`, data)
  },

  getProjectPublicProfile: async (startupId) => {
    return axios.get(`${Config.PROJECTS_API_URL}${startupId}/public-profile`)
  }
}

export default ProjectsService
