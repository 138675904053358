import React from 'react'
import { Tooltip } from '../atoms/Tooltip'
import Config from 'config'

export function Checkbox ({
  name,
  id,
  reference,
  label,
  error,
  checked,
  onChange,
  onClick,
  value,
  disabled,
  help,
  dataFor,
  children,
  width = 'full'
}) {
  return (
    <div className={`flex flex-col | w-${width} | my-2 mx-0`}>
      <div>
        <input
          className={`form-checkbox rounded-sm cursor-pointer hover:shadow-inner bg-main-${Config.PLATFORM} border border-gray-lines text-main-${Config.PLATFORM} ${
            checked ? 'shadow-inner' : 'shadow-soft-white'
          }`}
          type="checkbox"
          name={name}
          id={id}
          ref={reference}
          checked={checked}
          onChange={onChange || null}
          onClick={onClick || null}
          value={value}
          disabled={disabled}
        />

        <label className="ml-2 mb-0 text-xs cursor-pointer" htmlFor={id}>
          {label} {help && <Tooltip dataFor={dataFor} children={children} />}
        </label>
      </div>
      {error && (
        <div className="text-red -top-3 left-2 text-xxs">{error.message}</div>
      )}
    </div>
  )
}
