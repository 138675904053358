import { useTranslation } from 'react-i18next'
import Select from 'components/ui/atoms/Select'
import TranslateUtils from 'utils/translate-utils'

export const QuestionTypeSelect = ({
  currentQuestion,
  onChange,
  value,
  language
}) => {
  const { t } = useTranslation()

  const getSelectInitialValues = () => {
    return value
      ? value.filter((answ) => answ !== null).map((answ) => ({
        id: answ,
        value: TranslateUtils.getTextByLang(language, currentQuestion.question_id.options[answ])
      }))
      : []
  }

  return (
    <Select
      className="questionnaire-select"
      id={currentQuestion.question_id._id}
      name={currentQuestion.question_id._id}
      key={currentQuestion.question_id._id}
      multiSelect={currentQuestion.question_id.type === 'multiselect'}
      label={getSelectInitialValues() ? getSelectInitialValues().map(v => v.value).join(', ') : t('select_answer')}
      isClearable={!currentQuestion.required}
      placeholder={t('select_answer')}
      onSelect={opts => {
        onChange(opts.length ? opts.map(o => o.id) : null)
      }}
      initialValues={getSelectInitialValues()}
      items={currentQuestion.question_id.options.map((option, index) => ({
        id: index,
        value: TranslateUtils.getTextByLang(language, option)
      }))}
    />
  )
}
