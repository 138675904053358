import axios from 'axios/index'
import Config from '../config'

const AuthService = {
  confirmRegister: async (code) => {
    return axios.post(`${Config.AUTH_SEC_API_URL}auth/confirm-register`, {
      code
    })
  },

  checkInvitationCode: async (invitationCode) => {
    return axios.get(`${Config.AUTH_SEC_API_URL}auth/check-invitation-code`, {
      params: { invitation_code: invitationCode }
    })
  },

  login: async (email, password, keepLogged) => {
    return axios.post(`${Config.AUTH_SEC_API_URL}auth/login`, {
      email,
      password,
      keep_logged: keepLogged
    })
  },

  loginWithGoogle: async (token) => {
    return axios.post(`${Config.AUTH_SEC_API_URL}auth/login-google`, {
      token
    })
  },
  loginWithLinkedin: async (code) => {
    return axios.post(`${Config.AUTH_SEC_API_URL}auth/login-linkedin`, {
      code
    })
  },

  checkInvestorCode: async (code) => {
    return axios.get(`${Config.AUTH_SEC_API_URL}auth/check-investor-code`, {
      params: { code }
    })
  },

  checkProjectCode: async (code) => {
    return axios.get(`${Config.AUTH_SEC_API_URL}auth/check-project-code`, {
      params: { code }
    })
  },

  registerFounder: async (body) => {
    return axios.post(`${Config.AUTH_SEC_API_URL}auth/register-founder`, body)
  },
  registerInvitedFounder: async (body) => {
    return axios.post(
      `${Config.AUTH_SEC_API_URL}auth/register-invited-founder`,
      body
    )
  },
  registerInvestor: async (body) => {
    return axios.post(`${Config.AUTH_SEC_API_URL}auth/register-investor`, body)
  },

  registerProfessional: async (body) => {
    return axios.post(`${Config.AUTH_SEC_API_URL}auth/register-professional`, body)
  },

  resendActivationCode: async (email) => {
    return axios.post(`${Config.AUTH_SEC_API_URL}auth/resend-activation-link`, {
      email
    })
  },

  registerEmployee: async (body) => {
    return axios.post(`${Config.AUTH_SEC_API_URL}auth/register-employee`, body)
  },

  forgotPassword: async (email) => {
    return axios.post(`${Config.AUTH_SEC_API_URL}auth/forgot-password`, {
      email
    })
  },

  checkRestorePasswordCode: async (code) => {
    return axios.get(
      `${Config.AUTH_SEC_API_URL}auth/check-restore-password-code`,
      {
        params: { code }
      }
    )
  },

  restorePassword: async (code, password, passwordConfirmation) => {
    return axios.post(`${Config.AUTH_SEC_API_URL}auth/restore-password`, {
      code,
      password,
      password_confirmation: passwordConfirmation
    })
  }
}
export default AuthService
