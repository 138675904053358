import { DataGrid } from 'components/ui/objects/DataGrid'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import Config from 'config'
import { Card } from 'components/ui/atoms/Card'

const DataRoomStatistics = ({ dataRoom }) => {
    const { t } = useTranslation()

    return (
        <>
        <h3 className={`text-2xl text-main-${Config.PLATFORM} mt-4 mb-4 border-b border-main-${Config.PLATFORM}`}>{t('statistics')}</h3>
            <div>
                <div className="flex  my-10">
                    <div className="w-1/4 pr-10">
                        <Card>
                        <h4 className={`text-main-${Config.PLATFORM} font-semibold mb-2`}>
                            {t('accesses')}
                        </h4>
                        <p className={`text-2xl text-main-${Config.PLATFORM} font-bold`}>
                            {dataRoom.views}
                            {dataRoom.views > 0 && (
                            <span
                                className={
                                'ml-4 text-sm text-gray text-right pr-4 font-normal'
                                }
                            >
                                    (
                                {`${t('last_access')}: ${moment(
                                dataRoom.last_access
                                ).format('YYYY-MM-DD')}`}
                                )
                                </span>
                            )}
                        </p>
                        </Card>
                        <div className="my-4">
                        <Card>
                        <h4 className={`text-main-${Config.PLATFORM} font-semibold mb-2`}>
                            {t('downloads')}
                        </h4>
                        <p className={`text-2xl text-main-${Config.PLATFORM} font-bold`}>
                            {dataRoom.downloads !== null ? dataRoom.downloads : 0}
                            {dataRoom.downloads > 0 && (
                            <span
                                className={
                                'ml-4 text-sm text-gray text-right pr-4 font-normal'
                                }
                            >
                                    (
                                {`${t('last_download')}: ${moment(
                                dataRoom.last_download
                                ).format('YYYY-MM-DD')}`}
                                )
                                </span>
                            )}
                        </p>
                        </Card>
                        </div>
                        <div className="my-4">
                        <Card>

                        <h4 className={`text-main-${Config.PLATFORM} font-semibold mb-2`}>
                            {t('previews')}
                        </h4>
                        <p className={`text-2xl text-main-${Config.PLATFORM} font-bold`}>
                            {dataRoom && dataRoom.hasOwnProperty('previews')
                            ? dataRoom.previews
                            : 0}
                            {dataRoom.previews > 0 && (
                            <span
                                className={
                                'ml-4 text-sm text-gray text-right pr-4 font-normal'
                                }
                            >
                                    (
                                {`${t('last_preview')}: ${moment(
                                dataRoom.last_preview
                                ).format('YYYY-MM-DD')}`}
                                )
                                </span>
                            )}
                        </p>
                        </Card>
                        </div>
                    </div>
                    <div
                        className={`${
                        dataRoom &&
                        dataRoom.logs &&
                        dataRoom.logs.length > 0 &&
                        'divide-y divide-gray-lines'
                        } w-3/4 px-10`}
                    >
                        <Card containerClassName={`${
                        dataRoom &&
                        dataRoom.logs &&
                        dataRoom.logs.length > 0 &&
                        'divide-y divide-gray-lines'
                        }`}
                    >
                        <div>
                        <h3 className={`font-semibold mb-6 text-main-${Config.PLATFORM}`}>
                            {t('recent_activities')}
                        </h3>
                        </div>
                        {dataRoom &&
                        dataRoom.logs &&
                        dataRoom.logs.map((log, index) => {
                            return (
                            <div key={index} className="py-2">
                                <p className={'text-sm'}>
                                {log.log_type === 'access' && !log.user
                                    ? t('anonymous_access')
                                    : log.log_type === 'access'
                                    ? `${log.user} ${t('accessed_to_dataroom')}`
                                    : !log.user
                                        ? log.category === 'cv'
                                            ? `${t(
                                            log.log_type === 'download'
                                            ? 'anonymous_download'
                                            : 'anonymous_preview'
                                        )} ${t(log.category)} ${t('of')} ${
                                            log.founder
                                        }`
                                            : `${t(
                                            log.log_type === 'download'
                                            ? 'anonymous_download'
                                            : 'anonymous_preview'
                                        )} ${t(log.category)}`
                                        : log.category === 'cv'
                                            ? `${log.user} ${t(
                                            log.log_type === 'download'
                                            ? 'downloaded'
                                            : 'previewed'
                                        )} ${t(log.category)} ${t('of')} ${t(
                                            log.founder
                                        )}`
                                            : `${log.user} ${t(
                                            log.log_type === 'download'
                                            ? 'downloaded'
                                            : 'previewed'
                                        )} ${t(log.category)}`}
                                <span className={'text-xxs ml-2'}>
                                        {`(${moment(log.date).format('YYYY-MM-DD')})`}
                                    </span>
                                </p>
                            </div>
                            )
                        })}
                        {(!dataRoom ||
                        !dataRoom.logs ||
                        dataRoom.logs.length === 0) && (
                        <p className={'text-gray'}>
                            {t('no_recent_activites')}
                        </p>
                        )}
                        </Card>
                    </div>
                </div>
                <div>
                    <Card>

                    {dataRoom.guests?.length > 0 && (
                        <div className="w-full">
                            <div>
                                <h3 className={`font-semibold mb-6 text-main-${Config.PLATFORM}`}>
                                {t('user_interactions')}
                                </h3>
                            </div>
                            <DataGrid
                                wrapperClassName='mt-3'
                                data={dataRoom.guests.map((guest, index) => {
                                    return {
                                        id: index,
                                        name: `${guest.name} ${guest.last_name}`,
                                        accesses: guest.views,
                                        last_access: guest.last_view
                                            ? moment(guest.last_view).format('YYYY-MM-DD')
                                            : '-',
                                        downloads: guest.downloads,
                                        last_download: guest.last_download
                                            ? moment(guest.last_download).format(
                                            'YYYY-MM-DD'
                                            )
                                            : '-',
                                        previews: guest.previews || 0,
                                        last_preview: guest.last_preview
                                            ? moment(guest.last_preview).format(
                                            'YYYY-MM-DD'
                                            )
                                            : '-'
                                    }
                                })}
                                bordered={true}
                                compact={true}
                                headers={[
                                    {
                                        key: 'name',
                                        title: t('name')
                                    },
                                    {
                                        key: 'accesses',
                                        title: t('accesses')
                                    },
                                    {
                                        key: 'last_access',
                                        title: t('last_access')
                                    },
                                    {
                                        key: 'downloads',
                                        title: t('downloads')
                                    },
                                    {
                                        key: 'last_download',
                                        title: t('last_download')
                                    },
                                    {
                                        key: 'previews',
                                        title: t('previews')
                                    },
                                    {
                                        key: 'last_preview',
                                        title: t('last_preview')
                                    }
                                ]}
                            />
                        </div>
                    )}
                    </Card>
                </div>
            </div>
        </>
    )
}

export default DataRoomStatistics
