import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '../../../ui/objects/Container'
import { BasicCard } from '../../../ui/molecules/BasicCard'

import { Button } from '../../../ui/atoms/Button'

import Gear from '../../../../styles/images/gear.png'

import Logo from '../../../../styles/images/logo_light.svg'

import Gray from '../../../../styles/images/circle_gray.png'
import Blue from '../../../../styles/images/circle_blue.png'
import ReportVidio from 'styles/images/vidio-report.svg'
import QuestionnairesVidio from 'styles/images/vidio-questionnaires.svg'
import NewVidio from 'styles/images/vidio-new.svg'
import InfoVidio from 'styles/images/vidio-info.svg'
import DataRoomVidio from 'styles/images/vidio-dataroom.svg'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

const FourthStep = ({ previousStep, nextStep }) => {
  const { t } = useTranslation()

  return (
    <div className="bg-bgOnboardingTwo bg-cover bg-no-repeat bg-center min-w-screen min-h-screen relative flex justify-center align-center h-full">
      <div className="w-full flex items-center justify-between pr-10 fixed top-0">
        <img src={Logo} alt="Logo" className="mx-6 my-4" />
        <div>
          <button
            className="text-coolGray-500 font-medium outline-none focus:outline-none shadow-soft-white hover:shadow-inner rounded-2xl p-2 text-xs bg-transparence-blue"
            onClick={previousStep}
            type="button"
          >
            {t('go_back')}
          </button>
        </div>
      </div>

      <div className="pt-20 sm:pt-8 px-4 | text-center | max-w-screen-xl min-h-screen">
        <div className="flex  justify-center items-center h-full">
          <div className="flex flex-col lg:flex-row justify-center w-full">
            <div className="text-center sm:text-left mt-4 max-w-sm sm:ml-28 lg:ml-36">
              <h1 className="max-w-lg text-xl font-medium text-black mb-4">
                {t('utilities')}
              </h1>
              <p className="max-w-lg mb-4 text-sm">{t('resources')}</p>
              <p className="max-w-lg mb-4 text-sm">{t('skeep_resources')}</p>
              <div className="hidden lg:block">
                <ButtonMain
                  onClick={nextStep}
                  type="submit"
                  text={t('start')}
                />
              </div>
            </div>

            <div className="flex flex-wrap justify-center sm:justify-start sm:pl-24 w-full max-w-3xl mt-4 lg:mt-0">
              <BasicCard
                text={t('create_project_vidio_q')}
                image={NewVidio}
                alt="icon"
                onBoardingPage={true}
              />
              <BasicCard
                text={t('project_info_vidio')}
                image={InfoVidio}
                alt="icon"
                onBoardingPage={true}
              />
              <BasicCard
                text={t('data_rooms')}
                image={DataRoomVidio}
                alt="icon"
                onBoardingPage={true}
              />
              {/* <BasicCard
                text={t("questionnaires_vidio_q")}
                image={QuestionnairesVidio}
                alt="icon"
                video={testVideo}
                onBoardingPage={true}
              />
              <BasicCard
                text={t("report_vidio")}
                image={ReportVidio}
                alt="icon"
                video={testVideo}
                onBoardingPage={true}
              /> */}
            </div>
            <div className="lg:hidden mt-10 sm:ml-28 w-full sm:w-auto flex justify-center sm:justify-start mb-12">
              <ButtonMain
                onClick={nextStep}
                type="submit"
                text={t('start')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FourthStep
