import { Button } from '../../ui/atoms/Button'
import { useTranslation } from 'react-i18next'
import { FaLock } from 'react-icons/fa'
import { GiWorld } from 'react-icons/all'
import Config from 'config';
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary';
import { ButtonMain } from 'components/ui/atoms/ButtonMain';

export const StartupEditSettings = ({ visibility, onSelect }) => {
  const { t } = useTranslation()
  return (
    <>
      <p className={`font-medium text-base text-main-${Config.PLATFORM}`}>
        {t('select_profile_visibility')}
      </p>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4 my-8'>
        <div className="relative bg-white py-6 px-6 rounded-2xl border border-gray-lines my-4 shadow-soft-white">
          <div className="text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl bg-lightBlue-600 left-4 -top-6">
            <GiWorld className="inline-block w-6 h-6" />
          </div>
          <div className="pt-6 flex flex-col justify-between h-full">
            <div>
              <p className="text-lg font-semibold my-2">{t('startup_public_profile')}</p>
            </div>
            <div>
              <p className="text-sm text-gray my-2">{t('startup_public_profile_description')}</p>
            </div>
            <div className="flex-1 flex text-base my-2">
              <div className="text-sm  text-justify font-normal w-full px-10">
                <ul className='list-disc'>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('logo')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('name')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('sector')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('brand_statement')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('team')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('problem')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('solution')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('market')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('business_description')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('presentation_video')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('social_networks')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('incorporation_date')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('incorporation_country')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('business_model')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('project_stage')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('web')}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <p className="text-sm text-gray my-2">{t('access_request_required_description')}</p>
            </div>
            <div className="flex-1 flex text-base my-2">
              <div className="text-sm  text-justify font-normal w-full px-10">
                <ul className='list-disc'>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('documents')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('kpis')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('captable')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('competitors')}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            {visibility === 'private' && <ButtonMain
              onClick={() => onSelect('public')}
              text={t('select_option')}
            />
            }
          </div>
        </div>
        <div className="relative bg-white py-6 px-6 rounded-2xl border border-gray-lines my-4 shadow-soft-white">
          <div className="text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl bg-lightBlue-600 left-4 -top-6">
            <FaLock className="inline-block w-6 h-6" />
          </div>
          <div className="pt-6 flex flex-col justify-between h-full">
            <div>
              <p className="text-lg font-semibold my-2">{t('startup_private_profile')}</p>
            </div>
            <div>
              <p className="text-sm text-gray my-2">{t('startup_private_profile_description')}</p>
            </div>
            <div className="flex text-base">
              <div className="text-sm  text-justify font-normal w-full px-10">
                <ul className='list-disc'>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('logo')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('name')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('sector')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('brand_statement')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('problem')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('solution')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('market')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('business_description')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('presentation_video')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('social_networks')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('incorporation_date')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('incorporation_country')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('business_model')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('project_stage')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('web')}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <p className="text-sm text-gray my-2">{t('access_request_required_description')}</p>
            </div>
            <div className="flex-1 flex text-base my-2">
              <div className="text-sm  text-justify font-normal w-full px-10">
                <ul className='list-disc'>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('documents')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('kpis')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('captable')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('competitors')}
                    </span>
                  </li>
                  <li className="mb-1">
                    <span className="font-bold">
                      {t('team')}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            {visibility === 'public' && <ButtonMain
              text={t('select_option')}
              onClick={() => onSelect('private')}
            />
            }
          </div>
        </div>
        {
          /*
            {['startup_public_profile', 'startup_private_profile'].map(key => (
              <div key={key} className="relative bg-white py-6 px-6 rounded-2xl border border-gray-lines my-4 shadow-soft-white">
                <div className="text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl bg-lightBlue-600 left-4 -top-6">
                  {key === 'startup_public_profile' && <GiWorld className="inline-block w-6 h-6" />}
                  {key === 'startup_private_profile' && <FaLock className="inline-block w-6 h-6" />}
                </div>
                <div className="pt-6 flex flex-col justify-between h-full">
                  <div>
                    <p className="text-lg font-semibold my-2">{t(key)}</p>
                  </div>
                  <div className="flex-1 flex text-base">
                    {t(`${key}_description`)}
                  </div>
                {
                  (
                    (visibility === 'public' && key !== 'startup_public_profile') ||
                    (visibility === 'private' && key !== 'startup_private_profile')
                  ) &&
                  <Button
                      textSize="lg"
                      textColor="blue-dark"
                      text={t('select_option')}
                      horizontal="6"
                      verticalMargin="1"
                      className="mt-4"
                      onClick={() => onSelect(key === 'startup_public_profile' ? 'public' : 'private')}
                    />
                }
                </div>
              </div>
            ))}
          */
        }
      </div>

    </>
  )
}
