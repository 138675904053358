import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FaCheckCircle, FaChevronLeft, FaChevronRight
} from 'react-icons/all'
import { Button } from 'components/ui/atoms/Button'
import { QuestionType } from './components/QuestionType'
import { isEmpty } from 'lodash'
import Config from 'config'

export const PublicQuestionnaireQuestion = ({
  currentQuestion,
  indexElement,
  currentIndex,
  goNext,
  goBack,
  answers,
  setAnswers,
  questionErrors,
  checkCurrentQuestionErrors,
  setLoading,
  setContainerHeight,
  questionsLength,
  visibilityMode,
  showInvestorsPercentage = false,
  language,
  someErrorId,
  expandedQuestionsErrors,
  sortedQuestions,
  formId,
  investorId,
  scoreCardId = null,
  shared
}) => {

  const { t } = useTranslation()
  
  const [questionValidated, setQuestionValidated] = useState(false)
  const ref = useRef(null)

  const validateQuestion = async () => {
    setQuestionValidated(true)
    const value = answers[currentQuestion.question_id._id] || null
    if (currentQuestion.question_id.type === 'separator') {
      goNext()
    } else {
      const currentQuestionHasErrors = await checkCurrentQuestionErrors(currentQuestion, value)
      if (!currentQuestionHasErrors) {
        goNext()
      }
    }
  }

  const nextQuestion = () => {
    setQuestionValidated(false)

    setTimeout(() => {
      goNext()
    }, 100)
  }

  const previousQuestion = () => {
    setQuestionValidated(false)

    setTimeout(() => {
      goBack()
    }, 100)
  }

  const getClassNameByIndex = (indexElement) => {
    if (visibilityMode === 'single_page') {
      return ''
    } else if (indexElement === currentIndex) {
      return 'transition duration-1000 ease-in-out w-full absolute'
    } else if (indexElement < currentIndex) {
      return 'transition duration-1000 ease-in-out w-full absolute -translate-x-full'
    } else {
      return 'transition duration-1000 ease-in-out w-full absolute translate-x-full'
    }
  }

  useEffect(() => {
    setQuestionValidated(false)

    if (currentIndex === indexElement) {
      if (!answers[currentQuestion.question_id._id]) {
        setAnswers({ ...answers, [currentQuestion.question_id._id]: null })
      }

      setContainerHeight(ref.current.offsetHeight + 200)
      setTimeout(() => {
        document.querySelector('input[id="' + currentQuestion.question_id._id + '"], textarea[id="' + currentQuestion.question_id._id + '"]')?.focus()
      }, 1000)
    }
  }, [currentIndex])

  return (
    <div ref={ref} className={`question-container ${getClassNameByIndex(indexElement)} ${questionErrors[currentQuestion?.question_id?._id] && questionValidated ? 'animate-shake' : ''}`}>
      <div className='max-w-screen-sm mx-auto'>
        <div className="flex flex-row justify-center align-center">
          <div className="flex justify-center w-full">
            <div className="w-11/12">
              <QuestionType
                currentQuestion={currentQuestion}
                indexElement={indexElement}
                answers={answers}
                setAnswers={setAnswers}
                checkCurrentQuestionErrors={checkCurrentQuestionErrors}
                setLoading={setLoading}
                goNext={goNext}
                setQuestionValidated={setQuestionValidated}
                language={language}
                showInvestorsPercentage={showInvestorsPercentage}
                someErrorId={someErrorId}
                expandedQuestionsErrors={expandedQuestionsErrors}
                sortedQuestions={sortedQuestions}
                formId={formId}
                investorId={investorId}
                scoreCardId={scoreCardId}
                shared={shared}
              />

              {questionErrors[currentQuestion.question_id._id] && (
                <p className="text-red text-xs">
                  {questionErrors[currentQuestion.question_id._id]}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      {visibilityMode === 'multiple_page' && (
        <div className="flex max-w-screen-sm mx-auto justify-between w-full my-6">
          <div className="flex">
            {indexElement >= 1 && (
              <FaChevronLeft
                className={`w-8 h-8 hover:shadow-inner text-main-${Config.PLATFORM} rounded-lg p-1 cursor-pointer`}
                onClick={() => previousQuestion()}
              />
            )}
          </div>
          {currentQuestion && (
            <Button
              iconComponent={<FaCheckCircle className="inline-block mr-2" />}
              onClick={() => validateQuestion()}
              text={t('continue')}
              bgColor={`blue-dark-${Config.PLATFORM}`}
              textColor="white"
              width="auto"
              verticalMargin="0"
            />
          )}
        </div>
      )}
    </div>
  )
}
