import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'components/ui/molecules/Modal'
import { Button } from 'components/ui/atoms/Button'
import { Input } from 'components/ui/atoms/Input'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { SectionHeader } from 'components/ui/molecules/SectionHeader'
import InputFile from 'components/ui/atoms/input-files'
import { useToasts } from 'react-toast-notifications'
import { DataGrid } from 'components/ui/objects/DataGrid'
import { FaTrash } from 'react-icons/all'
import FormUtils from '../../../utils/form-utils'
import Utils from '../../../utils/utils'

import Config from 'config';

export function InvitationModal ({ onSubmit, showModal, handleCloseModal }) {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [section, setSection] = useState('manual')
  const [file, setFile] = useState(null)
  const tableHeaders = [
    { key: 'founder', title: t('founder') },
    { key: 'startup', title: t('startup') },
    { key: 'email', title: t('email') }
  ]
  const exampleData = [
    {
      founder: 'John Doe',
      startup: 'Startup Inc',
      email: 'john.doe@startup.com'
    },
    {
      founder: 'Jane Doe',
      startup: 'Startup Inc',
      email: 'Jane.doe@startup.com'
    }
  ]

  const shape = Yup.object().shape({
    founder: Yup.string().required(t('required_field')),
    startup: Yup.string().required(t('required_field')),
    email: Yup.string()
      .email(t('incorrect_format'))
      .required(t('required_field'))
  })

  const {
    register,
    handleSubmit,
    errors
  } = useForm({
    mode: 'all',
    criteriaMode: 'all',
    reValidateMode: 'all',
    resolver: yupResolver(shape)
  })

  const getExampleCsvUri = () => {
    const csv = 'founder;startup;email\n' +
      exampleData.map(item => `${item.founder};${item.startup};${item.email}`).join('\n')
    return 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
  }

  const selectFile = async (file) => {
    if (!file) {
      return
    }

    const csv = await FormUtils.readFile(file)
    const json = Utils.csvJSON(csv)
    const headers = Object.keys(json[0])

    if (headers.length !== 3 || headers[0] !== 'founder' || headers[1] !== 'startup' || headers[2] !== 'email') {
      addToast(t('incorrect_format'), { appearance: 'error', autoDismiss: true })
      return []
    }

    if (!json || !json.length) {
      addToast(t('invalid_csv_format'), {
        appearance: 'error',
        autoDismiss: true
      })

      return
    }

    setFile(json)
  }

  const submitImport = () => {
    onSubmit && onSubmit({
      type: section,
      invitations: file
    })
  }

  useEffect(() => {
    setFile(null)
    setSection('manual')
  }, [showModal])

  useEffect(() => {
    if (section === 'manual') {
      setFile(null)
    }
  }, [section])

  return (
    <Modal showModal={showModal} paddingBottom="2" paddingTop="6">
      <h2 className="font-semibold text-xl mb-2">
        {section === 'manual' ? t('invite_startup') : t('invite_startups')}
      </h2>
      <SectionHeader
        title={t('invite_startup')}
        showFilters={false}
        showAdd={false}
        showSort={false}
        showUpload={false}
        sectionTitles={[t('manual'), t('import_from_csv')]}
        sectionKeys={['manual', 'import']}
        section={section}
        setSection={setSection}
      />

      {section === 'manual' && (
        <form
          className="w-full"
          onSubmit={handleSubmit(values => onSubmit && onSubmit({ ...values, type: section }))}
        >
          <p className="font-medium text-sm text-gray-dark">
            {t('invite_startup_explanation')}
          </p>
          <div className="mt-8 w-full overflow-y-auto overscroll-y-auto h-96 sm:h-full">
            <Input
              reference={register}
              id="founder"
              type="text"
              name="founder"
              label={`${t('founder')}*`}
              placeholder={t('who_is_the_founder')}
              error={errors.founder}
            />
            <Input
              reference={register}
              id="startup"
              type="text"
              name="startup"
              label={`${t('startup')}*`}
              placeholder={t('which_is_the_startup')}
              error={errors.startup}
            />
            <Input
              reference={register}
              id="email"
              type="email"
              name="email"
              label={`${t('email')}*`}
              placeholder={t('what_is_the_founder_email')}
              error={errors.email}
            />
          </div>
          <div className="flex justify-between">
            <Button
              width="auto"
              textColor="coolGray-500"
              text={t('cancel')}
              onClick={handleCloseModal}
              marginRight="10"
            />
            <Button
              type="submit"
              width="auto"
              text={t('invite_startup')}
              textColor={`buttons-card-text-${Config.PLATFORM}`}
              bgColor={`buttons-secondary-${Config.PLATFORM}`}
              bgHoverColor={`buttons-hover-${Config.PLATFORM}`}
            />
          </div>
        </form>
      )}

      {section === 'import' && (
        <>
          <p className="font-medium text-sm text-gray-dark">
            {t('invite_startups_explanation')}
          </p>
          <p className="font-medium text-sm text-gray-dark">
            {t('invite_startups_explanation_2')}
          </p>
          <p className="font-medium text-sm text-gray-dark mb-3">
            <a download="example.csv" href={getExampleCsvUri()} className={`text-main-${Config.PLATFORM} hover:text-link-hover-${Config.PLATFORM}`}>{t('download_csv_example')}.</a>
          </p>

          {!file && (
            <InputFile
              accept={'.csv, .txt'}
              setSelectedFile={(file) => selectFile(file)}
            />
          )}

          {file && (
            <DataGrid
              compact={true}
              data={file.map((item, index) => ({ ...item, id: index }))}
              headers={tableHeaders}
              wrapperClassName="h-48"
              actions={[{
                id: 'delete',
                tip: t('delete'),
                buttonProps: {
                  iconComponent: <FaTrash className="inline" />,
                  textColor: 'red',
                  width: '12',
                  horizontal: '1',
                  vertical: '1',
                  verticalMargin: '0',
                  horizontalMargin: '0'
                },
                onClick: (rowData) => {
                  setFile(file.filter((item, index) => index !== rowData.id))
                }
              }]}
            />
          )}

          <div className="flex justify-between">
            <Button
              width="auto"
              textColor="coolGray-500"
              text={t('cancel')}
              onClick={handleCloseModal}
              marginRight="10"
            />
            <div className="flex flex-row">
              {file && (
                <Button
                  textColor="coolGray-500"
                  width="auto"
                  text={t('restart')}
                  marginRight="3"
                  onClick={() => setFile(null)}
                />
              )}
              <Button
                onClick={() => submitImport()}
                disabled={!file}
                width="auto"
                text={t('invite_startups')}
                textColor={`buttons-card-text-${Config.PLATFORM}`}
                bgColor={`buttons-secondary-${Config.PLATFORM}`}
                bgHoverColor={`buttons-hover-${Config.PLATFORM}`}
              />
            </div>
          </div>
        </>
      )}

    </Modal>
  )
}
