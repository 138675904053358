import { AppActionsTypes } from './app-actions-types'

const AppActions = {
  setLoading: (status) => ({
    type: AppActionsTypes.SET_LOADING,
    payload: { status }
  }),
  setLoadingOverlay: (status) => ({
    type: AppActionsTypes.SET_LOADING_OVERLAY,
    payload: { status }
  }),
  setSection: (section) => ({
    type: AppActionsTypes.SET_SECTION,
    payload: { section }
  }),
  setShowRateUsModal: (showRateUsModal) => ({
    type: AppActionsTypes.SET_SHOW_RATE_US_MODAL,
    payload: { showRateUsModal }
  }),
  setShowRequestFeatureModal: (showRequestFeatureModal) => ({
    type: AppActionsTypes.SET_SHOW_REQUEST_FEATURE_MODAL,
    payload: { showRequestFeatureModal }
  }),

  setShowContactModal: (showContactModal) => ({
    type: AppActionsTypes.SET_SHOW_CONTACT_MODAL,
    payload: { showContactModal }
  }),

  setShowInviteInvestorModal: (showInviteInvestorModal) => ({
    type: AppActionsTypes.SET_SHOW_INVITE_INVESTOR_MODAL,
    payload: { showInviteInvestorModal }
  }),

  setMessageTemplatesContainer: (messageTemplatesContainer) => ({
    type: AppActionsTypes.SET_MESSAGE_TEMPLATES_CONTAINER,
    payload: { messageTemplatesContainer }
  })
}

export default AppActions
