const AuthUtils = {
  isAdmin: (role) => {
    return role === 'admin'
  },
  isInvestor: (role) => {
    return role === 'investor'
  },
  isFounder: (role) => {
    return role === 'founder'
  },

  isProfessional: (role) => {
    return role === 'professional'
  },

  isDemoAccount: (user) => {
    return user && user.investor && !!user.investor.demo
  },
  isVentureCapitals: (user) => {
    return user && user.investor && user.investor.plan_id === 'venture_capitals'
  },
  isProspectiveInvestor: (user) => {
    return user && user.investor && user.investor.plan_id === 'prospective_investor'
  }
}

export default AuthUtils
