import axios from 'axios/index'
import Config from '../config'

const PaymentsService = {
  checkDiscountCoupon: async (coupon) => {
    return axios.get(`${Config.USERS_API_URL}payments/coupons/${coupon}`)
  },

  pay: async (data) => {
    return axios.post(`${Config.USERS_API_URL}payments`, data)
  },

  checkDataRoomPayment: async (projectId) => {
    return axios.get(
      `${Config.USERS_API_URL}payments/check?project_id=${projectId}`
    )
  },

  getPayments: async () => {
    return axios.get(`${Config.USERS_API_URL}payments`)
  },

  checkInvestorPayment: async () => {
    return axios.get(`${Config.USERS_API_URL}payments/check`)
  }
}
export default PaymentsService
