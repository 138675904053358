import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { SectionHeader } from 'components/ui/molecules/SectionHeader'
import { useHistory } from 'react-router-dom'
import { RoutesLinks } from 'components/routes-links'
import { TagListView } from '../../tags/TagListView'
import Config from 'config';
import { PageTitle } from 'components/ui/atoms/PageTitle'
import { Button } from 'components/ui/atoms/Button'
import { FaPlus, FaPlusCircle } from 'react-icons/fa'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

const DocumentationTags = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const SECTIONS = ['documents', 'tags']
  const tagsListRef = useRef()

  return (
    <>
        <div className='flex items-start'>
        <div className='flex-1'>
          <PageTitle
            title={t('documentation')}
          />
        </div>

        <ButtonMain
          iconComponent={<FaPlus className='inline-block mr-2' />}
          text={t('create_tag')}
          onClick={() => tagsListRef?.current?.handleCreateTag()}
        />
      </div>
      <SectionHeader
        sectionTitles={SECTIONS.map((sec) => t(sec))}
        sectionKeys={SECTIONS}
        section={'tags'}
        setSection={(sec) => {
          history.push(
            `${RoutesLinks.DOCUMENTATION_LINK}${sec.replace('_', '-')}`
          )
        }}
        showAdd={false}
        showFilters={false}
      />

      <TagListView category='documentation' ref={tagsListRef}/>
    </>
  )
}

export default DocumentationTags
