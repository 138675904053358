import { useTranslation } from 'react-i18next'
import { Button } from 'components/ui/atoms/Button'
import { useEffect, useState } from 'react'
import { Input } from 'components/ui/atoms/Input'
import { useToasts } from 'react-toast-notifications'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Loader } from 'components/ui/molecules/Loader'
import { FaTrash } from 'react-icons/fa'
import Avatar from 'react-avatar'
import InputFile from 'components/ui/atoms/input-files'
import FormUtils from 'utils/form-utils'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { ButtonDanger } from 'components/ui/atoms/ButtonDanger'
import Config from 'config'

const BAForm = ({ onSubmit, investorType, isWizard = true, onDelete, initialValues = {} }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)

  const [image, setImage] = useState(null)

  const BASchema = Yup.object().shape({
    ba: Yup.string().required(t('required_field')),
    ba_email: Yup.string().email(t('incorrect_format')),
    ba_phone_number: Yup.string(),
    ba_web: Yup.string()
        .transform(value => !value ? null : value)
        .matches(FormUtils.URL_REGEX, t('incorrect_format'))
        .nullable(),
    ba_linkedin: Yup.string()
        .transform(value => !value ? null : value)
        .matches(FormUtils.URL_REGEX, t('incorrect_format'))
        .nullable()
    })

  const { register, errors, trigger, getValues, setValue } = useForm({
    resolver: yupResolver(BASchema),
    criteriaMode: 'all',
    reValidateMode: 'all',
    mode: 'onChange'
  })

  const onSelectLogo = async file => {
    const fileSizeMb = (file?.size || 0) / 1024 / 1024

    if (fileSizeMb > 5) {
      addToast(t('error_file_size', { max_size: 5 }), {
        appearance: 'error',
        autoDismiss: true
      })
      return
    }

    setLoading(true)
    setImage(file ? await FormUtils.fileToBase64(file) : null)

    setTimeout(() => setLoading(false), 500)
  }

  useEffect(() => {
    if (initialValues && initialValues.ba_image) {
        setImage(initialValues.ba_image)
    }
  }, [initialValues])

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config)
    trigger(name)
  }

  useEffect(() => {
    if (initialValues) {
        Object.keys(initialValues).forEach((key) => {
            _setValue(key, initialValues[key])
        })
    }
}, [initialValues])

  return (
    <div>
        {image ? (
            <>
            <div className="text-xs font-medium text-black">
                {t('ba_profile_image_label')}
            </div>
            <div className="mt-2 flex flex-col justify-center w-32 mb-6">
                <Avatar
                src={image}
                size="80"
                round={true}
                color="#e0e6f2"
                fgColor="#4d70b3"
                className="mx-auto my-0"
                />
                <ButtonDanger
                type="button"
                iconComponent={<FaTrash className="inline-block mr-2" />}
                text={t('remove')}
                onClick={() => onSelectLogo(null)}
                marginTop={2}
                />
            </div>
            </>
        ) : (
            <div className="w-full mb-4">
            <InputFile
                label={t('ba_image_label')}
                placeholder={t('ba_image_placeholder')}
                error={errors.logo}
                setSelectedFile={(file) => onSelectLogo(file)}
                accept="image/*"
                height="120px"
                padding="18px"
            />
            </div>
        )}
        <form className="w-full">
            <div>
                <Input
                    reference={register}
                    id="ba"
                    type="text"
                    name="ba"
                    label={t('ba_name_label')}
                    placeholder={t('ba_name_placeholder')}
                    error={errors.ba}
                    required={true}
                />
            </div>
            <div>
                <Input
                    reference={register}
                    id="ba_email"
                    type="email"
                    name="ba_email"
                    label={t('ba_email_label')}
                    placeholder={t('ba_email_placeholder')}
                    error={errors.ba_email}
                />
            </div>
            <Input
                name="ba_web"
                reference={register}
                placeholder={t('ba_web_placeholder')}
                label={t('ba_web_label')}
                error={errors.ba_web}
            />
            <Input
                name="ba_linkedin"
                reference={register}
                placeholder={t('ba_linkedin_placeholder')}
                label={t('ba_linkedin_label')}
                error={errors.ba_linkedin}
            />
            <div>
                <Input
                    reference={register}
                    id="ba_phone_number"
                    type="text"
                    name="ba_phone_number"
                    label={`${t('ba_phone_number_label')}:`}
                    placeholder={t('ba_phone_number_placeholder')}
                    error={errors.ba_phone_number}
                />
            </div>
            <div className={`flex justify-end mt-2 pt-2 border-t border-separator-${Config.PLATFORM}`}>
            {!isWizard && <ButtonDanger
            onClick={onDelete}
            text={t('delete_investor')}
            marginRight={2}
            />}
            <ButtonMain
            type="button"
            onClick={async () => {
                const validation = await trigger()
                if (validation) onSubmit({ ...getValues(), ba_image: image, investor_type: investorType })
                else {
                    addToast(t('form_field_error'), { appearance: 'error', autoDismiss: true })
                }
            }}
            text={t('save')}
            />
        </div>
        </form>
        {loading && <Loader />}
    </div>
  )
}

export default BAForm
