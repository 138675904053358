import { useTranslation } from 'react-i18next'
import { Modal } from 'components/ui/molecules/Modal'

import Config from 'config'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { Autocomplete } from 'components/ui/atoms/Autocomplete'
import { useState } from 'react'
import { Switch } from 'components/ui/atoms/Switch'

const DeleteSectionModal = ({ onSubmit, showModal, handleCloseModal, sections, sectionToDelete }) => {
  const { t } = useTranslation()

  const parsedSections = sections.filter((s) => s._id !== sectionToDelete).map(section => ({ id: section._id, name: section._id ? section.section : t(section.section) }))

  const [selectedSection, setSelectedSection] = useState(null)
  const [moveGoals, setMoveGoals] = useState(false)

  return (
    <Modal showModal={showModal} showCloseModal={true} onClose={handleCloseModal} paddingBottom="4"
    paddingTop="6" fixedWidth={'w-full md:w-1/3'}>
      <h2 className={`font-semibold text-main-${Config.PLATFORM} mt-4`}>
            {t('delete_section')}
        </h2>

        <div className='my-6'>
        <div className="w-full px-1 text-xs mb-3 font-semibold">{t('would_you_like_to_move_goals_to_other_section')}</div>
            <Switch
                checked={moveGoals}
                onChange={(checked) => setMoveGoals(checked)}
                text={moveGoals ? t('move_goals') : t('purge_section')}
            />
            {moveGoals && (
                <div className='my-4'>
                    <Autocomplete
                        name="section"
                        label={t('section')}
                        placeholder={t('section')}
                        noOptionsText={t('sections_not_found')}
                        options={parsedSections}
                        onSelect={selected => {
                            setSelectedSection(selected)
                        }}
                        initialValues={null}
                        isClearable={true}
                    />
                </div>
            )}
        </div>
        <div className={`flex justify-end mt-4 border-t pt-2 border-separator-${Config.PLATFORM}`}>
            <ButtonMain
            disabled={moveGoals && !selectedSection}
                text={t('delete_section')}
            onClick={() => onSubmit(selectedSection)}
            />
      </div>
    </Modal>
  )
}

export default DeleteSectionModal
