import { RoutesLinks } from 'components/routes-links'
import { FaShareAlt, FaUsersCog } from 'react-icons/all'

const HeaderMenuItems = [
  {
    section: 'company',
    title: 'company',
    url: RoutesLinks.COMPANY_LINK,
    icon: <FaUsersCog />,
    investor: true,
    founder: false,
    professional: true,
    admin: false
  }
]

export default HeaderMenuItems
