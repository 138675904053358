import { useState, useEffect } from 'react'
import ProfessionalServices from 'services/professional-service'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import { Loader } from 'components/ui/molecules/Loader'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import { PageTitle } from 'components/ui/atoms/PageTitle'
import { ConnectedProfessional } from './ConnectedProfessional'
import { SectionHeader } from 'components/ui/molecules/SectionHeader'
import { RegisteredProfessional } from './RegisteredProfessional'
import { usePagination } from 'hooks/usePagination'
import { Pagination } from 'components/ui/atoms/Pagination'
import { ProfessionalConnectionRequest } from './ProfessionalConnectionRequest'
import useUser from 'hooks/useUser'
import { ProfessionalResumeSection } from './ProfessionalResumeSection'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { FaFilter } from 'react-icons/fa'
import { ProfessionalsFilters } from './ProfessionalsFilters'

export const Professionals = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [connections, setConnections] = useState([])
  const [registeredProfessionals, setRegisteredProfessionals] = useState([])
  const [connectionRequests, setConnectionRequests] = useState([])
  const [loading, setLoading] = useState(false)
  const [section, setSection] = useState('connections')
  const SECTIONS = ['connections', 'connection_requests', 'registered_professionals']
  const { startupId } = useUser()
  const [professionalToResume, setProfessionalToResume] = useState(null)
  const [showFilters, setShowFilters] = useState(null)
  const [filters, setFilters] = useState({})

  const {
    setTotalPages,
    totalPages,
    setPage,
    page,
    rowsPerPage,
    setRowsPerPage
  } = usePagination(12)

  const getConnections = () => {
    setLoading(true)
    return ProfessionalServices.getConnectedProfessionals({ rowsPerPage, page, startup: startupId }).then(result => {
        const { connections, pagination } = result.data
        setConnections(connections)
        setTotalPages(pagination.pages)
    }).catch(() => {
      addToast(t('error_occurred_retrieving_connections'), { appearance: 'error', autoDismiss: true })
    }).finally(() => {
      setLoading(false)
    }) 
  }

  const getRegisteredProfessionals = () => {
    setLoading(true)
    return ProfessionalServices.getRegisteredProfessionals({
      rowsPerPage,
      page,
      startup: startupId,
      ...filters
    }).then(result => {
      const { professionals, pagination } = result.data
      setRegisteredProfessionals(professionals)
      setTotalPages(pagination.pages)
    }).catch(() => {
      addToast(t('error_occurred_retrieving_professionals'), { appearance: 'error', autoDismiss: true })
    }).finally(() => {
      setLoading(false)
    })
  }

  const getProfessionalsConnectionRequests = () => {
    setLoading(true)
    return ProfessionalServices.getProfessionalsConnectionRequests({
      rowsPerPage,
      page,
      startup: startupId
    }).then(result => {
      const { connection_requests, pagination } = result.data
      setConnectionRequests(connection_requests)
      setTotalPages(pagination.pages)
    }).catch(() => {
      addToast(t('error_occurred_retrieving_connection_requests'), { appearance: 'error', autoDismiss: true })
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    setPage(1)
    setRowsPerPage(12)

    if (section === 'connections') {
        getConnections()
    }

    if (section === 'registered_professionals') {
        getRegisteredProfessionals()
    }

    if (section === 'connection_requests') {
        getProfessionalsConnectionRequests()
    }
  }, [section])

  useEffect(() => {
    if (section === 'registered_professionals') {
        getRegisteredProfessionals()
    } else if (section === 'connections') {
        getConnections()
    } else if (section === 'connections') {
        getProfessionalsConnectionRequests()
    }
  }, [page, rowsPerPage, filters])

  return (
    <>
    <div className="flex items-start">
        <div className="flex-1">
        <PageTitle title={t('professionals')} showBack={false} />
        </div>
        {section === 'registered_professionals' && <div data-tip={t('filter')}>
          <ButtonSecondary
            iconComponent={<FaFilter className='h-4'/>}
            onClick={() => {
              setShowFilters(true)
            }}
            />
        </div>}
    </div>

    {showFilters && (
        <ProfessionalsFilters
          onClose={() => setShowFilters(false)}
          onFilter={setFilters}
          filters={filters}
          showTags={false}
          section={'professional_registered_startups'}
        />
      )}
      <SectionHeader
        sectionTitles={SECTIONS.map(section => t(section))}
        sectionKeys={SECTIONS}
        section={section}
        setSection={setSection}
      /> 

      {professionalToResume && <ProfessionalResumeSection deal={{}} onClose={() => setProfessionalToResume(false)} professional={professionalToResume} />}

      {section === 'connections' && (
          <>
          {connections.length !== 0 && (
            <>
                <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 my-6'>
                    {connections.map(connection => (
                      <ConnectedProfessional
                          key={connection._id}
                          onManage={getConnections}
                          professional={connection.professional}
                          connection={connection}
                          resumeProfessional={() => setProfessionalToResume(connection.professional)}
                      />
                    )
                    )}
                </div>
                <Pagination
                    paginateOptions={[12, 24, 36, 48, 60]}
                    currentPage={page}
                    setCurrentPage={(page) => setPage(page)}
                    perPage={rowsPerPage}
                    pages={totalPages}
                    setPerPage={(value) => setRowsPerPage(value[0].id)}
                />
            </>)}

          {connections.length === 0 && (
                        <div className='my-10'>
            <NoDataInfo
              title={t('startups_connected_not_found')}
              textOne={t('connected_startups_professionals_not_found')}
            />
            </div>
          )}
        </>
      )}

      {section === 'registered_professionals' && (
        <>
          {registeredProfessionals.length === 0 && (
            <div className='my-10'>
              <NoDataInfo
                title={t('registered_professionals_not_found')}
                textOne={t('registered_professionals_startups_not_found')}
              />
            </div>
          )}
          {registeredProfessionals.length !== 0 && (
            <>
              <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 my-6'>
                {registeredProfessionals.map(professional => (
                  <RegisteredProfessional
                    key={professional._id}
                    professional={professional}
                    onManage={getRegisteredProfessionals}
                    resumeProfessional={() => setProfessionalToResume(professional)}
                  />
                ))}
              </div>
                <Pagination
                    paginateOptions={[12, 24, 36, 48, 60]}
                    currentPage={page}
                    setCurrentPage={(page) => setPage(page)}
                    perPage={rowsPerPage}
                    pages={totalPages}
                    setPerPage={(value) => setRowsPerPage(value[0].id)}
                />
            </>
          )}
        </>
      )}

    {section === 'connection_requests' && (
        <>
          {connectionRequests.length === 0 && (
            <div className='my-10'>
              <NoDataInfo
                title={t('connection_requests_not_found')}
                textOne={t('connection_requests_professionals_not_found')}
              />
            </div>
          )}
          {connectionRequests.length !== 0 && (
            <>
              <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 my-6'>
                {connectionRequests.map(request => (
                  <ProfessionalConnectionRequest
                    request={request}
                    key={request._id}
                    professional={request.professional}
                    onManage={getProfessionalsConnectionRequests}
                    resumeProfessional={() => setProfessionalToResume(request.professional)}
                  />
                ))}
              </div>
                <Pagination
                    paginateOptions={[12, 24, 36, 48, 60]}
                    currentPage={page}
                    setCurrentPage={(page) => setPage(page)}
                    perPage={rowsPerPage}
                    pages={totalPages}
                    setPerPage={(value) => setRowsPerPage(value[0].id)}
                />
            </>
          )}
        </>
      )}
      {loading && <Loader />}
    </>
  )
}
