import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Card } from '../../../ui/atoms/Card'

import useWindowDimensions from 'hooks/useWindowsDimensions'

import LogoDefault from 'styles/images/logo_default.png'
import Tick from 'styles/images/tick_black.svg'

import Cross from 'styles/images/cross_gray.svg'

import { IconContainer } from 'components/ui/atoms/IconContainer'
import moment from 'moment'
import { CircularChard } from 'components/ui/molecules/charts/CircularChart'

import LinkedinIcon from 'styles/images/LinkedinLogo.svg'
import WebIcon from 'styles/images/web_icon.svg'
import { Button } from 'components/ui/atoms/Button'
import Config from 'config';

const Invitation = ({ invitation, decline, accept, dashboard = false }) => {
  const { t } = useTranslation()
  const { width } = useWindowDimensions()

  const activeTheme = useSelector((state) => state.user.preferences.theme)

  return (
    <div className="my-4">
      <Card
        shadow={dashboard ? 'none' : 'soft-white'}
        padding="2"
        children={
          <div className="flex flex-col sm:flex-row justify-between divide-y sm:divide-y-0 sm:divide-x divide-gray-lines w-full">
            <div className="flex flex-col xl:flex-row items-start justify-start w-full sm:w-full md:w-full xl:w-1/5">
              {/* <div className="flex flex-col sm:flex-row xl:flex-col xl:w-full xl:items-start mb-2"> */}
              <div className="flex xl:flex-col mb-2">
                <div className="w-16 h-16 flex items-start justify-center mb-1">
                  <img
                    src={
                      invitation.project.logo
                        ? invitation.project.logo
                        : LogoDefault
                    }
                    alt="Startup logo"
                    className="inline-block w-16 h-16 object-contain cursor-pointer"
                  />
                </div>

                <div className="flex flex-col sm:h-full justify-end ml-2 pr-2 ">
                  <h5 className="uppercase font-semibold text-xxs mb-1 leading-none break-all">
                    {invitation.project.name}
                  </h5>
                  {invitation.project.web && (
                    <div className="flex w-44">
                      <img src={WebIcon} alt="Web icon" className="w-4 mr-1" />

                      <a
                        className="text-xxxs font-medium truncate"
                        href={invitation.project.web}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {invitation.project.web}
                      </a>
                    </div>
                  )}
                  {!invitation.project.web && (
                    <div className="flex">
                      <img src={WebIcon} alt="Web icon" className="w-4 mr-1" />
                      <span className="font-medium text-xxs">{'-'}</span>
                    </div>
                  )}
                  {invitation.project.linkedin && (
                    <div className="flex w-44">
                      <img
                        src={LinkedinIcon}
                        alt="Web icon"
                        className="w-4 mr-1"
                      />
                      <a
                        className="text-xxxs font-medium truncate"
                        href={invitation.project.linkedin}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {invitation.project.linkedin}
                      </a>
                    </div>
                  )}
                  {!invitation.project.linkedin && (
                    <div className="flex w-44">
                      <img
                        src={LinkedinIcon}
                        alt="Web icon"
                        className="w-4 mr-1"
                      />
                      <span className="font-medium text-xxs">{'-'}</span>
                    </div>
                  )}
                </div>
              </div>

              {width < 1280 && width > 640 && (
                <div className="py-4 flex justify-between pr-6 pl-2 w-full border-t border-gray-lines">
                  <div className="flex flex-col justify-start h-full">
                    <div className="flex flex-col pb-2 lg:pb-0">
                      <span className="font-semibold text-xxs">
                        {t('started_in')}:{' '}
                      </span>
                      <span className="font-medium text-xxxs">
                        {invitation.project.incorporation_date
                          ? moment(
                            invitation.project.incorporation_date
                          ).format('YYYY-MM-DD')
                          : '-'}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className="font-semibold text-xxs">
                        {t('business_model')}:{' '}
                      </span>
                      <span className="font-medium text-xxxs">
                        {invitation.project.business_model
                          ? t(invitation.project.business_model.key)
                          : '-'}
                      </span>
                    </div>
                  </div>

                  <div className="flex flex-col justify-around h-full">
                    <div className="flex flex-col pb-2 lg:pb-0">
                      <span className="font-semibold text-xxs">
                        {t('project_stage')}:{' '}
                      </span>
                      <span className="font-medium text-xxxs">
                        {' '}
                        {invitation.project.project_stage
                          ? t(invitation.project.project_stage.key)
                          : '-'}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <div className="flex flex-col">
                        <span className="font-semibold text-xxs">
                          {t('country')}:{' '}
                        </span>
                        <span className="font-medium text-xxxs">
                          {invitation.project.country_incorporation
                            ? t(invitation.project.country_incorporation.key)
                            : '-'}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col justify-around h-full">
                    <div className="flex flex-col pb-2 lg:pb-0">
                      <span className="font-semibold text-xxs">
                        {t('investment_stage')}:{' '}
                      </span>
                      <span className="font-medium text-xxxs">
                        {invitation.project.investment_stage
                          ? t(invitation.project.investment_stage.key)
                          : '-'}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className="font-semibold text-xxs">
                        {t('sector')}:{' '}
                      </span>
                      <span className="font-medium text-xxxs">
                        {invitation.project.sector
                          ? t(invitation.project.sector.key)
                          : '-'}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="flex hidden xl:flex px-2 py-4 lg:py-0 justify-around py-1 lg:w-2/4">
              <div className="flex flex-col justify-around h-full">
                <div className="flex flex-col pb-2 lg:pb-0">
                  <span className="font-semibold text-xxs">
                    {t('started_in')}:{' '}
                  </span>
                  <span className="font-medium text-xxxs">
                    {invitation.project.incorporation_date
                      ? moment(invitation.project.incorporation_date).format(
                        'YYYY-MM-DD'
                      )
                      : '-'}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="font-semibold text-xxs">
                    {t('business_model')}:{' '}
                  </span>
                  <span className="font-medium text-xxxs">
                    {invitation.project.business_model
                      ? t(invitation.project.business_model.key)
                      : '-'}
                  </span>
                </div>
              </div>

              <div className="flex flex-col justify-around h-full">
                <div className="flex flex-col pb-2 lg:pb-0">
                  <span className="font-semibold text-xxs">
                    {t('project_stage')}:{' '}
                  </span>
                  <span className="font-medium text-xxxs">
                    {' '}
                    {invitation.project.project_stage
                      ? t(invitation.project.project_stage.key)
                      : '-'}
                  </span>
                </div>
                <div className="flex flex-col">
                  <div className="flex flex-col">
                    <span className="font-semibold text-xxs">
                      {t('country')}:{' '}
                    </span>
                    <span className="font-medium text-xxxs">
                      {invitation.project.country_
                        ? t(invitation.project.investment_stage.key)
                        : '-'}
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex flex-col justify-around h-full">
                <div className="flex flex-col pb-2 lg:pb-0">
                  <span className="font-semibold text-xxs">
                    {t('investment_stage')}:{' '}
                  </span>
                  <span className="font-medium text-xxxs">
                    {invitation.project.investment_stage
                      ? t(invitation.project.investment_stage.key)
                      : '-'}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="font-semibold text-xxs">
                    {t('sector')}:{' '}
                  </span>
                  <span className="font-medium text-xxxs">
                    {invitation.project.sector
                      ? t(invitation.project.sector.key)
                      : '-'}
                  </span>
                </div>
              </div>
            </div>

            {width < 640 && (
              <div className="px-3 py-4 flex items-around items-center w-full lg:w-2/6">
                <div className="flex flex-col justify-between h-full w-full mr-4 xl:mr-4">
                  <div className="flex flex-col">
                    <span className="font-semibold text-xxs">
                      {t('started_in')}:{' '}
                    </span>
                    <span className="font-regular text-xxxs">
                      {invitation.project.incorporation_date
                        ? moment(invitation.project.incorporation_date).format(
                          'YYYY-MM-DD'
                        )
                        : '-'}
                    </span>
                  </div>
                  <div className="flex flex-col my-2">
                    <span className="font-semibold text-xxs">
                      {t('sector')}:{' '}
                    </span>
                    <span className="font-regular text-xxxs">
                      {invitation.project.sector
                        ? t(invitation.project.sector.key)
                        : '-'}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="font-semibold text-xxs">
                      {t('business_model')}:{' '}
                    </span>
                    <span className="font-regular text-xxxs">
                      {invitation.project.business_model
                        ? t(invitation.project.business_model.key)
                        : '-'}
                    </span>
                  </div>
                </div>

                <div className="flex flex-col justify-between h-full w-full">
                  <div className="flex flex-col">
                    <span className="font-semibold text-xxs">
                      {t('project_stage')}:{' '}
                    </span>
                    <span className="font-regular text-xxxs">
                      {invitation.project.project_stage
                        ? t(invitation.project.project_stage.key)
                        : '-'}
                    </span>
                  </div>
                  <div className="flex flex-col my-2">
                    <span className="font-semibold text-xxs">
                      {t('investment_stage')}:{' '}
                    </span>
                    <span className="font-regular text-xxxs">
                      {invitation.project.investment_stage
                        ? t(invitation.project.investment_stage.key)
                        : '-'}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex flex-col">
                      <span className="font-semibold text-xxs">
                        {t('country')}:{' '}
                      </span>
                      <span className="font-regular text-xxxs">
                        {' '}
                        {invitation.project.country_incorporation
                          ? t(invitation.project.country_incorporation.key)
                          : '-'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="flex flex-col items-center justify-center md:hidden lg:hidden px-1">
              {width > 640 && width < 768 && (
                <div className="flex flex-row items-center justify-around mb-2 ">
                  <IconContainer
                    bgColor="white"
                    width="8"
                    height="8"
                    shadow="soft-white"
                    shadowHover="hover"
                    icon={Tick}
                    iconWidth="3"
                    onClick={() => accept(invitation)}
                  />
                  <IconContainer
                    bgColor="white"
                    width="8"
                    height="8"
                    shadow="soft-white"
                    shadowHover="hover"
                    icon={Cross}
                    iconWidth="3"
                    onClick={() => decline(invitation)}
                  />
                </div>
              )}
            </div>

            <div className="w-full sm:w-2/12 xl:w-2/12 pl-2 py-4 lg:py-0 flex sm:hidden md:flex sm:flex-col items-center justify-around sm:justify-center">
              <Button
                text={t('accept')}
                textSize="xxs"
                width="28"
                icon={Tick}
                verticalMargin="1"
                horizontal="4"
                shadow="soft-white"
                onClick={() => accept(invitation)}
                textColor={`buttons-card-text-${Config.PLATFORM}`}
                bgColor={`buttons-card-${Config.PLATFORM}`}
                bgHoverColor={`buttons-hover-${Config.PLATFORM}`}
              />
              <Button
                bgColor="white"
                textColor="gray"
                text={t('decline')}
                textSize="xxs"
                width="28"
                icon={Cross}
                verticalMargin="1"
                horizontal="4"
                shadow="soft-white"
                onClick={() => decline(invitation)}
              />
            </div>
          </div>
        }
      ></Card>
    </div>
  )
}

export default Invitation
