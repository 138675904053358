import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Loader } from 'components/ui/molecules/Loader'
import { Button } from 'components/ui/atoms/Button'
import SupportService from 'services/support-service'
import { Card } from 'components/ui/atoms/Card'
import { PageTitle } from '../ui/atoms/PageTitle'
import { FaChevronCircleDown, FaChevronCircleUp, FaEdit, FaPlus, FaTrash } from 'react-icons/fa'
import { PieChart } from 'components/ui/molecules/charts/PieChart'
import MilestoneGoalWizard from './components/MilestoneGoalWizard'
import moment from 'moment'
import Utils from 'utils/utils'
import { ProgressBar } from 'components/ui/atoms/ProgressBar'
import { ReactSortable } from 'react-sortablejs'
import MilestoneSectionWizard from './components/MilestoneSectionWizard'
import { useSwal } from 'hooks/useSwal'
import ReactTooltip from 'react-tooltip'
import Config from 'config';
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { ButtonDanger } from 'components/ui/atoms/ButtonDanger'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import DeleteSectionModal from './components/DeleteSectionModal'

const Milestones = ({dealId}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const myRef = useRef(null)
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)
  const [sort] = useState('-createdAt')
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 10,
    totalPages: 0,
    totalDocs: 0
  })
  const [sections, setSections] = useState([])
  const [selectedSection, setSelectedSection] = useState(null)
  const [collapsed, setCollapsed] = useState({})

  const [goals, setGoals] = useState([])
  const [showMilestoneGoalWizard, setShowMilestoneGoalWizard] = useState(false)
  const [showMilestoneSectionWizard, setShowMilestoneSectionWizard] = useState(false)
  const [goalToEdit, setGoalToEdit] = useState(null)
  const [sectionToEdit, setSectionToEdit] = useState(null)
  const [milestones, setMilestones] = useState([])
  const [showDeleteSectionModal, setShowDeleteSectionModal] = useState(false)
  const [sectionToDelete, setSectionToDelete] = useState(null)

  useEffect(() => {
    setShowDeleteSectionModal(!!sectionToDelete)
  }, [sectionToDelete])
  const { confirm } = useSwal()


  const getSections = async () => {
    return SupportService.getMilestoneSections(dealId)
      .then((response) => {
        setSections([{section: "unclassified_goals", _id: null}, ...response.data.data])
      })
      .catch((error) => {
        addToast(
          error?.response?.data?.msg_key ? t(error.response.data.msg_key) : t('error_occurred_retrieving_milestone_sections'),
          {
            appearance: 'error',
            autoDismiss: true
          }
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getGoals = async () => {
    return SupportService.getMilestoneGoals(dealId)
      .then((response) => {
        setGoals(response.data.data)
      })
      .catch((error) => {
        addToast(
          error?.response?.data?.msg_key ? t(error.response.data.msg_key) : t('error_occurred_retrieving_milestone_goals'),
          {
            appearance: 'error',
            autoDismiss: true
          }
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const deleteGoal = async (goalId) => {
    confirm({
      icon: 'error'
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        setLoading(true)
        SupportService.deleteMilestoneGoal(goalId, dealId)
          .then(() => getGoals())
          .catch((error) => {
            addToast(
              error?.response?.data?.msg_key ? t(error.response.data.msg_key) : t('error_occurred_deleting_goal'),
              {
                appearance: 'error',
                autoDismiss: true
              }
            )
          }).finally(() => {
            setLoading(false)
          })
      }
    })
  }

  const deleteSection = async (section) => {
    confirm({
      icon: 'error'
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        setLoading(true)
        SupportService.deleteMilestoneSection(sectionToDelete, dealId, section ? section.id : null, !section)
          .then(() => getGoals())
          .then(() => getSections())
          .catch((error) => {
            addToast(
              error?.response?.data?.msg_key ? t(error.response.data.msg_key) : t('error_occurred_deleting_section'),
              {
                appearance: 'error',
                autoDismiss: true
              }
            )
          }).finally(() => {
            setLoading(false)
            setSectionToDelete(null)
          })
      }
    })
  }

  const confirmSectionDeletion = async (sectionId) => {
    confirm({
      icon: 'error'
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        setLoading(true)
        SupportService.deleteMilestoneSection(sectionId, dealId, null, true)
          .then(() => getGoals())
          .then(() => getSections())
          .catch((error) => {
            addToast(
              error?.response?.data?.msg_key ? t(error.response.data.msg_key) : t('error_occurred_deleting_section'),
              {
                appearance: 'error',
                autoDismiss: true
              }
            )
          }).finally(() => {
            setLoading(false)
            setSectionToDelete(null)
          })
      }
    })
  }

  const handleSection = async (data) => {
    if (data.id) {
      SupportService.updateMilestoneSection(data.id, dealId, { section: data.section })
        .then((response) => {
          getSections()
        })
        .catch((error) => {
          addToast(
            error?.response?.data?.msg_key ? t(error.response.data.msg_key) : t('error_occurred_updating_section'),
            {
              appearance: 'error',
              autoDismiss: true
            }
          )
        })
        .finally(() => {
          setShowMilestoneSectionWizard(false)
          setSectionToEdit(null)
          setLoading(false)
        })
    } else {
      SupportService.createMilestoneSection({ section: data.section, startup: dealId })
        .then((response) => {
          getSections()
        })
        .catch((error) => {
          addToast(
            error?.response?.data?.msg_key ? t(error.response.data.msg_key) : t('error_occurred_creating_section'),
            {
              appearance: 'error',
              autoDismiss: true
            }
          )
        })
        .finally(() => {
          setShowMilestoneSectionWizard(false)
          setSectionToEdit(null)
          setLoading(false)
        })
    }
  }

  const handleGoal = async (data) => {
    if (data.id) {
      const goalId = data.id
      delete data.id
      SupportService.updateMilestoneGoal(goalId, dealId, data)
        .then((response) => {
          getGoals()
        })
        .catch((error) => {
          addToast(
            error?.response?.data?.msg_key ? t(error.response.data.msg_key) : t('error_occurred_updating_goal'),
            {
              appearance: 'error',
              autoDismiss: true
            }
          )
        })
        .finally(() => {
          setShowMilestoneGoalWizard(false)
          setGoalToEdit(null)
          setSelectedSection(null)
          setLoading(false)
        })
    } else {
      delete data.id
      SupportService.createMilestoneGoal({...data, startup: dealId})
        .then((response) => {
          getGoals()
        })
        .catch((error) => {
          addToast(
            error?.response?.data?.msg_key ? t(error.response.data.msg_key) : t('error_occurred_creating_goal'),
            {
              appearance: 'error',
              autoDismiss: true
            }
          )
        })
        .finally(() => {
          setShowMilestoneGoalWizard(false)
          setGoalToEdit(null)
          setSelectedSection(null)
          setLoading(false)
        })
    }
  }

  const getGoalsStatsByStatus = () => {
    const unassigned = goals.filter((milestone) => milestone.status === 'unassigned').length
    const completed = goals.filter((milestone) => milestone.status === 'completed').length
    const notCompleted = goals.filter((milestone) => milestone.status === 'not_completed').length
    const overdue = goals.filter((milestone) => milestone.status === 'overdue').length

    return [{
      id: t('unassigned'),
      label: t('unassigned'),
      value: unassigned
    }, {
      id: t('completed'),
      label: t('completed'),
      value: completed
    }, {
      id: t('not_completed'),
      label: t('not_completed'),
      value: notCompleted
    }, {
      id: t('overdue'),
      label: t('overdue'),
      value: overdue
    }]
  }

  const getGoalsBySections = () => {

    const result = goals.reduce(function (r, a) {
      r[a.section] = r[a.section] || [];
      r[a.section].push(a);
      return r;
  }, Object.create(null));

  const parsedResult = [];

  Object.keys(result).forEach((sectionId) => {
    const section = sections.find((s) => ((sectionId === 'null' && !s._id) || s._id === sectionId))
    parsedResult.push({
      id: section._id ? section.section : t(section.section),
      label: section.section,
      value: result[sectionId].length
    })
  })
 
  return parsedResult;
}


  const [isSortingColumns, setIsSortingColumns] = useState(null)
  const [updatedSort, setUpdatedSort] = useState([])
  const [movedElement, setMovedElement] = useState({})
  const [adding, setAdding] = useState(false)
  const [removing, setRemoving] = useState(false)
  const [cardToAdd, setCardToAdd] = useState({})
  const [cardToRemove, setCardToRemove] = useState({})



  const sortCard = async (column, oldIndex, newIndex) => {
    try {
      await ProjectsService.sortFunnelCard(
        startupId,
        boardId,
        column._id,
        movedElement._id,
        {
          old_index: oldIndex,
          new_index: newIndex
        }
      )
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? error.response.data.msg_key
          : t('error_occurred_updating_card'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    } finally {
      setMovedElement({})
    }
  }

  useEffect(() => {
    ReactTooltip.rebuild()
    setLoading(true)
    getSections().finally(() => setLoading(false))
    getGoals().finally(() => setLoading(false))
  }, [])

  const expandOrCollapse = (elementId) => {
    setCollapsed({ ...collapsed, [elementId]: !collapsed[elementId] })
    const element = document.getElementById(elementId)
    element.classList.toggle('hidden')
  }

  return (
    <div ref={myRef}>
      {showDeleteSectionModal && (
        <DeleteSectionModal
          onSubmit={(values) => deleteSection(values)}
          showModal={showDeleteSectionModal}
          handleCloseModal={() => {
            setSectionToDelete(null)
          }}
          sections={sections}
          sectionToDelete={sectionToDelete}
        />
      )}
      {showMilestoneGoalWizard && (
        <MilestoneGoalWizard
          selectedSection={selectedSection}
          deleteGoal={deleteGoal}
          initialValues={goalToEdit}
          handleCloseWizard={() => {
            setShowMilestoneGoalWizard(false)
            setGoalToEdit(null)
            setSelectedSection(null)
          }}
          sections={sections}
          zIndex="10"
          title="title"
          setFiltersButtons={false}
          setShowModal={() => console.log("==")}
          notes={[]}
          createNote={() => console.log("==")}
          updateNote={() => console.log("==")}
          deleteNote={() => console.log("==")}
          getNote={() => console.log("==")}
          selectedNote={() => console.log("==")}
          setSelectedNote={() => console.log("==")}
          onSubmit={(data) => handleGoal(data)}
        />)
      }
      {showMilestoneSectionWizard && (
        <MilestoneSectionWizard
          initialValues={sectionToEdit}
          handleCloseWizard={() => {
            setShowMilestoneSectionWizard(false)
            setSectionToEdit(null)
          }}
          deleteSection={deleteSection}
          setFiltersButtons={false}
          setShowModal={() => console.log("==")}
          notes={[]}
          createNote={() => console.log("==")}
          updateNote={() => console.log("==")}
          deleteNote={() => console.log("==")}
          getNote={() => console.log("==")}
          selectedNote={() => console.log("==")}
          setSelectedNote={() => console.log("==")}
          onSubmit={(data) => handleSection(data)}
        />)
      }

      <div>
        {goals.length !== 0 && sections.length !== 0 && (
          <div className='last:border-none mb-10 grid grid-cols-1 md:grid-cols-2 gap-4 mt-6'>
            <div
              className="h-64 w-full"
            >
              <div className={`text-main-${Config.PLATFORM} font-semibold text-center text-xl`}>{t('goals_by_status')}</div>
              <PieChart legend={false} data={getGoalsStatsByStatus()} />
            </div>
            <div
              className="h-64 w-full"
            >
              <div className={`text-main-${Config.PLATFORM} font-semibold text-center text-xl`}>{t('goals_by_sections')}</div>
              <PieChart legend={false} data={getGoalsBySections()} />
            </div>
          </div>
        )}

      <div className={`flex justify-end ${goals.length === 0 && 'my-6'}`}>
      
          <ButtonSecondary
              onClick={() => setShowMilestoneSectionWizard(true)}
              text={t('create_section')}
              iconComponent={<FaPlus className="inline-block mr-3" />}
              marginRight="2"
            />
            <ButtonSecondary
              onClick={() => setShowMilestoneGoalWizard(true)}
              text={t('create_goal')}
              iconComponent={<FaPlus className="inline-block mr-3" />}
            />
      </div>
        <div className="-mt-2 p-4 rounded-xl overflow-x-auto overscroll-x-auto overflow-y-auto overscroll-y-auto w-full">
          <div className="w-full flex flex-col">
            {/*<ReactSortable
              onStart={() => setIsSortingColumns(true)}
              onEnd={() => setIsSortingColumns(false)}
              list={sections}
              setList={setUpdatedSort}
              group="root"
              animation={150}
              // handle=".cursor-move"
              className="w-full my-2"
              // chosenClass="sortable-choosen-column"
              // dragClass="drag"
        >*/}
              {sections.map((section, index) => {
                return (
                  <div className='bg-white  w-full my-6 p-4 rounded-2xl' key={section._id}>
                      <div className={`w-full justify-between border-b border-b border-main-${Config.PLATFORM} flex-row flex`}>
                        <div className={'flex flex-row'}>
                          <h3 className={`text-main-${Config.PLATFORM} mr-4`}>{collapsed[`section_${index}`] ? <FaChevronCircleDown
                                    onClick={() => expandOrCollapse(`section_${index}`)}
                                    data-panel={`section_${index}`}
                                    className={`text-main-${Config.PLATFORM} inline-block mr-1 cursor-pointer`}
                                  /> : <FaChevronCircleUp
                                  data-panel={`section_${index}`}
                                  onClick={() => expandOrCollapse(`section_${index}`)}
                                  className={`text-main-${Config.PLATFORM} inline-block mr-1 cursor-pointer`}
                                />}{section._id ? section.section : t(section.section)}</h3>
                          <div data-tip={t('create_goal')} className='mr-1'>
                              <ButtonSecondary
                                onClick={() => {
                                  setSelectedSection(section)
                                  setShowMilestoneGoalWizard(true)
                                }}
                                iconComponent={<FaPlus className="inline" />}
                              />
                            </div>
                            {section._id !== null && (
                              <>
                              <div data-tip={t('edit_section')} className='mr-1'>
                                <ButtonSecondary
                                  onClick={() => {
                                    setSectionToEdit({ id: section._id, section: section.section })
                                    setShowMilestoneSectionWizard(true)
                                  }}
                                  iconComponent={<FaEdit className="inline" />}
                                />
                            </div>
                            <div data-tip={t('delete_section')}>
                              <ButtonDanger
                                onClick={() => {
                                  if (goals.find((goal) => goal.section === section._id)) {
                                    setSectionToDelete(section._id)
                                  } else {
                                    confirmSectionDeletion(section._id)
                                  }
                                }}
                                iconComponent={<FaTrash className="inline" />}
                              />
                            </div>
                              </>
                            )}
                        </div>
                        {goals.find((goal) => goal.section === section._id) &&
                          (
                            <div className='w-40'>
                              <ProgressBar value={((goals.filter((goal) => goal.section === section._id && goal.status === 'completed').length / goals.filter((goal) => goal.section === section._id).length) * 100).toFixed(2)} />
                          </div>
                          )
                        }
                      </div>
                      <div className={'my-2'} id={`section_${index}`}>
                        {
                          /*
                            <ReactSortable
                              data-id={section.section}
                              setList={setUpdatedSort}
                              list={milestones}
                              group='grupo1'
                              animation={200}
                              easing='cubic-bezier(0.25, 1, 0.5, 1)'
                              // handle=".cursor-move"
                              // chosenClass='sortable-choosen'
                              onStart={(evt) => {
                                setMovedElement(milestones[evt.oldIndex])
                              }}
                              onSort={(evt) => {
                                if (!adding && !removing) {
                                  sortCard('general', evt.oldIndex, evt.newIndex)
                                }
                              }}
                              onRemove={(evt) => {
                                setRemoving(true)
                                setCardToRemove({
                                  column: 'general'
                                })
                              }}
                              onAdd={(evt) => {
                                setAdding(true)
                                setCardToAdd({
                                  column: 'general',
                                  index: evt.newIndex
                                })
                              }}
                            >
                          */
                        }
                          {goals.filter((goal) => goal.section === section._id).length === 0 && <div className='my-6'><NoDataInfo title={t('no_goals_found')} textOne={t('no_goals_found_description')} /></div>}
                          {goals.filter((goal) => goal.section === section._id).map((goal, index) => {
                            return (
                              <div key={index} className='my-2 py-2 border-b border-gray-lines'>
                                <div className='flex flex-row justify-between text-sm'>
                                  <div>
                                    {/*<FaGripVertical className='inline text-gray-lines cursor-move mr-2' />*/}
                                    <span className='font-semibold'>{goal.milestone}</span>
                                    <span
                                      className="inline-block ml-2 rounded-2xl py-0.5 px-2 relative font-semibold text-xxxs"
                                      style={{
                                        border: goal.status === 'unassigned' ? '1px solid rgb(229, 229, 229)' : goal.status === 'completed' ? '1px solid rgb(22 163 74)' : goal.status === 'overdue' ? '1px solid rgb(234 88 12)' : '1px solid rgb(220 38 38)',
                                        color: Utils.saturateColor(goal.status === 'unassigned' ? '7px solid rgb(229, 229, 229)' : goal.status === 'completed' ? '7px solid rgb(22 163 74)' : goal.status === 'overdue' ? '7px solid rgb(234 88 12)' : '7px solid rgb(220 38 38)', 0.7)
                                      }}
                                    >
                                      {t(goal.status)}
                                    </span>
                                  </div>
                                  <div className="flex flex-row justify-center items-start">
                                    <div data-tip={t('edit')}>
                                      <ButtonSecondary
                                        onClick={() => {
                                          setGoalToEdit({ id: goal._id, ...goal })
                                          setShowMilestoneGoalWizard(true)
                                        }}
                                        iconComponent={<FaEdit className="inline" />}
                                      />
                                    </div>
                                    <div className="ml-1" data-tip={t('delete')}>
                                      <ButtonDanger
                                        onClick={() => deleteGoal(goal._id)}
                                        iconComponent={<FaTrash className="inline" />}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {goal.description && (
                                  <div className='px-6 text-xs'>
                                    <span className={`text-main-${Config.PLATFORM} font-semibold mr-1`}>
                                      {`${t('description')}:`}
                                    </span>
                                    {goal.description}
                                  </div>
                                )}
                                {goal.deadline && (
                                  <div className='px-6 text-xs my-1'>
                                    <span className={`text-main-${Config.PLATFORM} font-semibold mr-1`}>
                                      {`${t('deadline')}:`}
                                    </span>
                                    {moment(goal.deadline).format('YYYY-MM-DD')}
                                  </div>
                                )}
                            </div>)
                          })}
                        {/*</ReactSortable>*/}
                      </div>
                  </div>
                )
              })}
            {/*</ReactSortable>*/}
          </div>
        </div>
        {loading && <Loader />}
      </div>
    </div>
  )
}

export default Milestones
