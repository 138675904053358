import { useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { RoutesLinks } from 'components/routes-links'
import { useToasts } from 'react-toast-notifications'
import { TbWriting, MdKeyboardVoice, MdTextFields } from 'react-icons/all';
import ProjectsService from 'services/projects-service'
import { Loader } from 'components/ui/molecules/Loader'
import { useSwal } from 'hooks/useSwal'
import useUser from '../../hooks/useUser'
import AuthUtils from 'utils/auth-utils'
import { Button } from 'components/ui/atoms/Button'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import VoiceNotes from 'components/project/components/general-information/components/voice-notes'
import TextNotes from 'components/project/components/general-information/components/text-notes'
import DrawNotes from 'components/project/components/general-information/components/draw-notes'
import { BasicCardNotes } from 'components/ui/molecules/BasicCardNotes'

import Config from 'config';
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import SupportService from 'services/support-service'
import { Card } from 'components/ui/atoms/Card'

const Annotations = ({dealId}) => {

  const { user } = useUser()
  const history = useHistory()
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { confirm } = useSwal()
  const [loading, setLoading] = useState(true)
  const [loadingNotes, setLoadingNotes] = useState(true)
  const [notes, setNotes] = useState([])
  const [selectedNote, setSelectedNote] = useState(null)

  const [showVoiceModal, setShowVoiceModal] = useState(false)
  const [showTextModal, setShowTextModal] = useState(false)
  const [showDraweModal, setShowDrawerModal] = useState(false)


  const createNote = async (title, type, content) => {
    try {
      setLoading(true)
      await SupportService.createNote({
        startup: dealId,
        user: user._id,
        title,
        content,
        type
      })
      await getNotes()
      addToast(t('note_saved_successfully'), {
        appearance: 'success',
        autoDismiss: true
      })
    } catch (error) {
      addToast(t('error_occurred_saving_note'), {
        appearance: 'error',
        autoDismiss: true
      })
    } finally {
      setLoading(false)
    }
  }

  const updateNote = async (title, content) => {
    try {
      setLoading(true)
      await SupportService.updateNote(selectedNote._id, { title, content })
      await getNotes()

      addToast(t('note_updated_successfully'), {
        appearance: 'success',
        autoDismiss: true
      })
    } catch (error) {
      addToast(t('error_occurred_updating_note'), {
        appearance: 'error',
        autoDismiss: true
      })
    } finally {
      setSelectedNote(null)
      setLoading(false)
    }
  }

  const deleteNote = async (noteId) => {
    confirm({}).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true)
          await SupportService.deleteNote(noteId)
          await getNotes()
          addToast(t('note_deleted_successfully'), {
            appearance: 'success',
            autoDismiss: true
          })
        } catch (error) {
          addToast(t('error_occurred_deleting_note'), {
            appearance: 'error',
            autoDismiss: true
          })
        } finally {
          setLoading(false)
        }
      }
    })
  }

  const getNote = async (noteId) => {
    try {
      setLoading(true)
      const result = await SupportService.getNote(noteId)
      setSelectedNote(result.data)
    } catch (error) {
      addToast(t('error_occurred_retrieving_note'), {
        appearance: 'error',
        autoDismiss: true
      })
    } finally {
      setLoading(false)
    }
  }

  const getNotes = async () => {
    try {
      if (AuthUtils.isInvestor(user.role)) {
        setLoadingNotes(true)
      }
      const result = await SupportService.getNotes({ startup: dealId })
      setNotes(result.data)
    } catch (error) {
      console.log(error)
      addToast(t('error_occurred_retrieving_notes'), {
        appearance: 'error',
        autoDismiss: true
      })
    } finally {
      setLoadingNotes(false)
      setLoading(false)
    }
  }

  const handleCloseTextModal = () => {
    setShowTextModal(false)
    setSelectedNote(null)
  }

  const handleCloseDrawerModal = () => {
    setShowDrawerModal(false)
    setSelectedNote(null)
  }

  const handleCloseVoiceModal = () => {
    setShowVoiceModal(false)
    setSelectedNote(null)
  }

  useEffect(() => {
    if (AuthUtils.isInvestor(user.role)) {
      getNotes()
    }
  }, [dealId])

  useEffect(() => {
    if (selectedNote) {
      if (selectedNote.type === 'draw') {
        setShowDrawerModal(true)
      } else if (selectedNote.type === 'text') {
        setShowTextModal(true)
      } else if (selectedNote.type === 'audio') {
        setShowVoiceModal(true)
      }
    }
  }, [selectedNote])

  return (
    <>
      {showVoiceModal && (
        <VoiceNotes
        editable={true}
        showModal={showVoiceModal}
          onSubmit={(title, type, content) => {
            setShowVoiceModal(false)
            createNote(title, type, content)
          }}
          initialValues={selectedNote}
          handleClose={handleCloseVoiceModal}
        /> 
      )}
      {showTextModal && (
        <TextNotes
        editable={true}
        showModal={showTextModal}
          onSubmit={(values) => {
            setShowTextModal(false)
            if (selectedNote) {
              updateNote(values.title, values.content)
            } else {
              createNote(values.title, 'text', values.content)
            }
          }}
          initialValues={{
            title: selectedNote ? selectedNote.title : '',
            content: selectedNote ? selectedNote.content : ''
          }}
          handleClose={handleCloseTextModal}
        />
      )}
      {showDraweModal && (
        <DrawNotes
          editable={true}
          showModal={showDraweModal}
          onSubmit={(title, content) => {
            setShowDrawerModal(false)
            if (selectedNote) {
              updateNote(title, content)
            } else {
              createNote(title, 'draw', content)
            }
          }}
          handleClose={handleCloseDrawerModal}
          initialValues={{
            title: selectedNote ? selectedNote.title : '',
            content: selectedNote ? selectedNote.content : ''
          }}
        />
      )}
      <div className='flex justify-center w-full'>

        <div className='grid grid-cols-1 md:grid-cols-3 gap-4 my-6'>
          <div>
            <ButtonSecondary
              onClick={() => setShowTextModal(true)}
              text={t('add_text_note')}
              iconComponent={<MdTextFields className="inline-block w-5 h-4 mr-1" />}
            />
          </div>
          <div>
            <ButtonSecondary
              onClick={() => setShowDrawerModal(true)}
              text={t('add_draw_note')}
              iconComponent={<TbWriting className="inline-block w-5 h-4 mr-1" />}
            />
          </div>
          <div>
            <ButtonSecondary
              onClick={() => setShowVoiceModal(true)}
              text={t('add_voice_note')}
              iconComponent={<MdKeyboardVoice className="inline-block w-5 h-4 mr-1" />}
            />
          </div>
        </div>
      </div>
      {loading && (
        <Loader />
      )}
      {notes.length === 0 && <div className='my-6'>
          <NoDataInfo title={t('no_notes_added')} />
        </div>}
      <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-4">
        {notes.length > 0 && (
          notes.map((note, index) => {
            return (
              <BasicCardNotes
                key={index}
                note={note}
                deleteNote={deleteNote}
                getNote={getNote}
              />
            )
          })
        )}
      </div>
    </>
  )
}

export default Annotations
