import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useParams } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import Logo from 'styles/images/logo_light.svg'

import ProjectsService from 'services/projects-service'
import EventsService from 'services/events-service'
import { Loader } from 'components/ui/molecules/Loader'
import Document from 'components/project/components/documentation/components/document'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import { Document as ReactPdfDocument, Page } from 'react-pdf'
import { Modal } from 'components/ui/molecules/Modal'
import Download from 'styles/images/download-filled.svg'
import { FaChevronCircleDown, FaChevronCircleUp } from 'react-icons/fa'
import Unauthorized from 'components/ui/objects/Unauthorized'
import moment from "moment";
import Config from 'config'

const SharedDataRoom = () => {
  const { dataRoomId } = useParams()
  const { addToast } = useToasts()
  const [dataRoomAccessCode, setDataRoomAccessCode] = useState(localStorage.getItem('X-DR-Token'))

  const [authorized, setAuthorized] = useState(false)

  const [dataRoom, setDataRoom] = useState({})

  const { t } = useTranslation()

  const [documentToPreview, setDocumentToPreview] = useState(null)

  const [
    showDownloadableUnavailableModal,
    setShowDownloadableUnavailableModal
  ] = useState(false)

  const previewFile = async (fileId, section) => {
    let sendEvent = false
    try {
      setLoading(true)
      const resp = await ProjectsService.getSharedDataRoomDocument(
        dataRoom._id,
        fileId,
        section
      )
      setDocumentToPreview(resp.data.url)

      setShowPDFModal(true)
      sendEvent = true
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t('error_occurred_retrieving_file'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    } finally {
      setLoading(false)
    }

    if (sendEvent) {
      try {
        await EventsService.sendEvent('dataRoom', 'preview', {
          code: dataRoomAccessCode,
          data_room: dataRoomId,
          document: fileId,
          section
        })
      } catch (error) {}
    }
  }

  const downloadFile = async (fileId, section) => {
    let sendEvent = false
    try {
      setLoading(true)
      const resp = await ProjectsService.getSharedDataRoomDocument(
        dataRoom._id,
        fileId,
        section
      )
      if (resp.data && resp.data.url !== '') {
        window.open(resp.data.url, '_blank')
      }
      sendEvent = true
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t('error_occurred_downloading_file'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    } finally {
      setLoading(false)
    }

    if (sendEvent) {
      try {
        await EventsService.sendEvent('dataRoom', 'download', {
          code: dataRoomAccessCode,
          data_room: dataRoomId,
          document: fileId,
          section
        })
      } catch (error) {}
    }
  }

  const [loading, setLoading] = useState(true)

  const [numPages, setNumPages] = useState(null)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  useEffect(() => {
    const getDataRoom = async () => {
      try {
        setLoading(true)
        const result = await ProjectsService.getSharedDataRoom(
          dataRoomId,
          dataRoomAccessCode
        )
        setDataRoom(result.data)
        setAuthorized(true)
      } catch (error) {
        addToast(t(error?.response?.status === 403 ? 'forbiden_access_to_dataroom' : 'error_occurred_retrieving_data_room'), {
          appearance: 'error',
          autoDismiss: true
        })
      } finally {
        setLoading(false)
      }
    }

    if (dataRoomAccessCode) {
      localStorage.setItem('X-DR-Token', dataRoomAccessCode)
      getDataRoom()
    } else {
      setLoading(false)
    }
  }, [dataRoomId, dataRoomAccessCode])

  useEffect(() => {
    const sendEvent = async () => {
      try {
        await EventsService.sendEvent('dataRoom', 'access', {
          code: dataRoomAccessCode,
          data_room: dataRoomId
        })
      } catch (error) {}
    }

    if (authorized) {
      sendEvent()
    }
  }, [authorized])

  const [showPDFModal, setShowPDFModal] = useState(false)

  const [collapsed, setCollapsed] = useState({})

  const expandOrCollapse = (elementId) => {
    setCollapsed({ ...collapsed, [elementId]: !collapsed[elementId] })
    const element = document.getElementById(elementId)
    element.classList.toggle('hidden')
  }
  const getDocuments = (sectionId) => {
    const documents = dataRoom.documents.filter((document) => document.section === sectionId)

    if (documents.length) {
      return (
        <div className="grid gap-4 xs:grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 my-10">
          {documents.map((document, index) => {
            return (
              <Document
                key={index}
                section={sectionId}
                popoverMenu={false}
                popoverMenuOptions={{}}
                popoverMenuMethods={{}}
                document={{
                  name: document.document.name,
                  _id: document._id,
                  section: sectionId,
                  downloadable: document.downloadable,
                  uploaded_at: document.uploaded_at
                }}
                handleUploadFile={null}
                downloadFile={
                  document.downloadable
                    ? () =>
                        downloadFile(
                          document._id,
                          sectionId
                        )
                    : () =>
                        setShowDownloadableUnavailableModal(true)
                }
                viewFile={
                  ['pdf', 'PDF'].includes(document.document.name
                    .split('.')
                    .at(-1))
                    ? () =>
                        previewFile(document._id, sectionId)
                    : null
                }
                documentSpecialized={false}
                dataRoomLanding={true}
              />
            )
          })}
      </div>
      )
    } else {
      return (
        <div className='my-10'>
          <NoDataInfo title={t('documents_not_found')} />
        </div>
      )
    }
  }

  if (!authorized && !loading) return <Unauthorized loading={loading} onRequestAccess={setDataRoomAccessCode} description={t('access_control_description_dataroom')} buttonText={t('access_dataroom')} />

  if (loading) return <Loader />

  return (
    <>
      <Modal
        showModal={showDownloadableUnavailableModal}
        showCloseModal={true}
        onClose={() => {
          setShowDownloadableUnavailableModal(false)
        }}
        fixedWidth={'w-10/12'}
      >
        <NoDataInfo
          image={
            <div className={'flex items-center justify-center'}>
              <img src={Download} className={'w-40'} alt={t('document_not_downloadable')} />
            </div>
          }
          title={t('document_not_downloadable')}
          textOne={t('document_not_downloadable_subtitle')}
        />
      </Modal>
      <Modal
        showModal={showPDFModal && documentToPreview}
        showCloseModal={true}
        onClose={() => {
          setShowPDFModal(false)
          setDocumentToPreview(null)
        }}
        height='full'
        fixedWidth={'w-10/12 h-3/4'}
      >
        <div className={'py-10 h-full overflow-y-scroll overflow-x-hidden pdf'}>
          <ReactPdfDocument
            file={{
              url: documentToPreview
            }}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<Loader />}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                loading={<Loader />}
              />
            ))}{' '}
          </ReactPdfDocument>
        </div>
      </Modal>
      <div className="bg-bgOnboardingOne bg-cover bg-no-repeat bg-center min-w-screen min-h-screen relative flex justify-center align-center h-full">
        <div className="w-full items-center justify-center  top-0">
          <div>
            {!loading && (
              <>
                <header className="w-full flex justify-between px-8 py-6">
                  <div>
                    <a href="https://www.kiota.com/" target="_blank" rel="noreferrer">
                      <img src={Logo} alt="Logo Kiota" />
                    </a>
                  </div>
                </header>

                <main>
                  <div>
                    <div className="px-10 flex relative">
                      <div className="pt-20 xs:w-full md:w-8/12">
                        <h1 className={'text-4xl lg:text-5xl mb-5 text-black'}>
                          {t('welcome_dataroom')}
                          {dataRoom.name}
                          {t('data_room_english')}
                        </h1>

                        <p className="text-ms mb-2 text-black">
                          {t('shared_dataroom_hero')}
                        </p>
                      </div>

                      <div
                        className={
                          !dataRoom.logo
                            ? 'hidden'
                            : 'flex flex-col items-center justify-center invisible md:visible md:w-4/12'
                        }
                      >
                        <img
                          src={dataRoom.logo}
                          alt="Startup logo"
                          className="w-72"
                        />
                      </div>
                    </div>
                    <div className={'my-10 pb-10 px-10'}>
                      <div className="mb-6 w-full">
                        {dataRoom.sections.map(
                          (section) => (
                            <div key={section._id}>
                              <div
                                className="border-b border-gray-lines font-bold flex flex-row justify-between hover:bg-gray-100 px-2 hover:rounded-md transition-colors cursor-pointer mt-6 text-xl"
                                onClick={() => expandOrCollapse(section._id)}
                              >
                                <span>{section.name}</span>
                                {collapsed[section._id] ? <FaChevronCircleDown
                                  data-panel={section._id}
                                  className={`text-main-${Config.PLATFORM} inline-block mt-1`}
                                /> : <FaChevronCircleUp
                                data-panel={section._id}
                                className={`text-main-${Config.PLATFORM} inline-block mt-1`}
                              />}
                              </div>

                              <div id={section._id} className={'mt-2'}>
                                {getDocuments(section._id)}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <footer className="visible sm:invisible h-18 items-center px-8 fixed bottom-0 w-full bg-white">
                    <div className='w-12/12 sm:w-8/12 px-4 sm:px-0 text-center'>
                      <span className="text-gray text-xxs">{`${t('copyright')} @${moment().format('YYYY')} ${t('copyright_rights')}`}</span>
                    </div>
                    <div className='flex justify-center my-2'>
                      <img src={Logo} alt="Logo Kiota" />
                    </div>
                  </footer>
                  <footer className="invisible sm:visible h-16 flex justify-between items-center px-8 fixed bottom-0 bg-white w-full bg-white">
                    <div className='px-4'>
                      <span className="text-gray text-xxs">{`${t('copyright')} @${moment().format('YYYY')} ${t('copyright_rights')}`}</span>
                    </div>
                    <div>
                      <img src={Logo} alt="Logo Kiota" />
                    </div>
                  </footer>
                </main>
              </>
            )}
            {loading && <Loader />}
          </div>
        </div>
      </div>
    </>
  )
}

export default SharedDataRoom
