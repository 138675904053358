import { useTranslation } from 'react-i18next'
import { SidePanel } from 'components/ui/objects/SidePanel'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import Config from 'config'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import Select from 'components/ui/atoms/Select'
import { Input } from 'components/ui/atoms/Input'
import { useToasts } from 'react-toast-notifications'
import { SectionHeader } from 'components/ui/molecules/SectionHeader'
import { Switch } from 'components/ui/atoms/Switch'
import { FaRegQuestionCircle } from 'react-icons/fa'
import ReactTooltip from 'react-tooltip'

/**
 * @param onClose
 * @returns {JSX.Element}
 * @constructor
 */
const JudgeSection = ({ onClose, onSubmit, initialValues, employees }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const [section, setSection] = useState('employees')

  const [selectedEmployee, setSelectedEmployee] = useState(null)

  const externalJudgeSchema = Yup.object().shape({
    judge: Yup.string().required(t('required_field')),
    email: Yup.string()
      .email(t('incorrect_format'))
      .required(t('required_field'))
  })

  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    reValidateMode: 'all',
    resolver: yupResolver(externalJudgeSchema)
  })

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const nonRegisteredEmployees = employees.filter((employee) => !employee.user);

  return (
    <>
      <SidePanel
        width={'1/4'}
        onClose={onClose}
        title={t(initialValues ? 'edit_score_card' : 'invite_judge')}
      >
        <div className="flex flex-col w-full">
            <SectionHeader
                showFilters={false}
                showAdd={false}
                showSort={false}
                showUpload={false}
                sectionTitles={[t('employees'), t('externals')]}
                sectionKeys={['employees', 'externals']}
                section={section}
                setSection={setSection}
            />
            {section === 'employees' && (
        <>
          <p className="font-medium text-sm text-gray-dark mt-2">
            {t('invite_employee_as_judge_explanation')}
          </p>
          <p className="font-medium text-sm text-gray-dark mt-2">
            {t('assign_startups_explanation')}
          </p>
          <div className="mt-6">
            <Select
              label={t('select_teammate')}
              required={true}
              placeholder={t('select_teammate')}
              initialValues={[]}
              onSelect={(value) => setSelectedEmployee(value[0])}
              items={employees.filter((employee) => employee.user).map((e) => ({
                id: e.user ? e.user._id : `${e._id}_not_registered`,
                value: `${e.user ? e.user.name : e.name} ${e.user ? e.user.last_name : e.last_name || ''} ${!e.user ? '(Not registered)' : ''
                  }`.trim(),
                disabled: !e.user,
                name: e.user ? e.user.name : e.name,
                email: e.user ? e.user.email : e.email,
                last_name: e.user ? e.user.last_name : e.last_name,
                user: e.user ? e.user._id : null,
                employee_id: e._id
              }))}
            />
          </div>
          {nonRegisteredEmployees.length !== 0 && (
          <>
            <p className="font-medium text-sm text-gray-dark mt-2 mb-2">
              {t('non_registered_employees_judges')}
            </p>
            <ul className='list-disc px-8'>
              {nonRegisteredEmployees.map((employee) => {
                return (
                  <li key={employee._id} className={'text-sm font-semibold'}>{`${employee.name} ${employee.last_name}`}</li>
                )
              })}
            </ul>
          </>)}
          <div className={`flex justify-end mt-4 pt-2 border-t border-separator-${Config.PLATFORM}`}>
            <ButtonMain
              onClick={() => {
                if (!selectedEmployee) {
                    addToast(t('you_have_to_select_a_judge'), {
                        appearance: 'error',
                        autoDismiss: true
                      })
                } else {
                    onSubmit({
                      judge: `${selectedEmployee.name} ${selectedEmployee.last_name}`,
                      employee_id: selectedEmployee.employee_id,
                      user: selectedEmployee.user,
                      email: selectedEmployee.email
                    })
                }
              }}
              text={t('add_judge')}
            />
          </div>
        </>
      )}

      {section === 'externals' && (
        <form
          className="w-full"
          onSubmit={handleSubmit(
            (values) => onSubmit && onSubmit({ ...values })
          )}
        >
          <p className="font-medium text-sm text-gray-dark mt-2">
            {t('invite_external_as_judge_explanation')}
          </p>
          <p className="font-medium text-sm text-gray-dark mt-2">
            {t('assign_startups_explanation')}
          </p>
          <div className="mt-8 w-full">
            <Input
              reference={register}
              id="judge"
              type="text"
              name="judge"
              label={`${t('judge')}*`}
              placeholder={t('judge')}
              error={errors.judge}
            />

            <Input
              reference={register}
              id="email"
              type="email"
              name="email"
              label={`${t('email')}*`}
              placeholder={t('email')}
              error={errors.email}
            />
          </div>
          
          <div className={`flex justify-end mt-4 pt-2 border-t border-separator-${Config.PLATFORM}`}>
            <ButtonMain
              type="submit"
              text={t('add_judge')}
            />
          </div>
        </form>
      )}
            </div>
      </SidePanel>
    </>
  )
}

export default JudgeSection
