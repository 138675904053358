import React, { useEffect, useState } from 'react'
import Avatar from 'react-avatar'
import { Card } from 'components/ui/atoms/Card'
import { useTranslation } from 'react-i18next'
import Subsectors from 'assets/json/subsectors.json'
import ProjectsService from '../../services/projects-service'
import { useToasts } from 'react-toast-notifications'
import { Spinner } from '../ui/atoms/Spinner'
import Person from 'assets/images/person.png'
import PitchIcon from '../../styles/images/pitch_icon.png'
import PitchDeckIcon from '../../styles/images/pitch_deck_icon.png'
import SalesDeckIcon from '../../styles/images/sales_deck_icon.png'
import BusinessPlanIcon from '../../styles/images/business_plan_icon.png'
import AnnualReportIcon from '../../styles/images/annual_report_icon.png'
import CaptableIcon from '../../styles/images/captable_icon.png'
import CvIcon from '../../styles/images/cv_icon.png'
import OthersDocumentsIcon from '../../styles/images/others_documents_icon.png'
import ReactTooltip from 'react-tooltip'
import Utils from '../../utils/utils'
import moment from 'moment'
import { Loader } from 'components/ui/molecules/Loader'
import { FaGithub, FaGitlab, FaLinkedin, FaMedium, FaTwitter, FaYoutube, SiCrunchbase, FaGlobe } from 'react-icons/all'
import {
  FaCalendarAlt,
  FaCopy,
  FaDownload,
  FaEnvelope,
  FaHandHoldingUsd, FaHandshake,
  FaLightbulb,
  FaLink,
  FaMapMarkerAlt,
  FaQuestion,
  FaUser
} from 'react-icons/fa'
import { BsBarChartSteps } from 'react-icons/bs'
import { BiTargetLock } from 'react-icons/bi'
import { MdPriceChange } from 'react-icons/md'
import { TagList } from '../tags/TagList'
import Linkedin from '../../assets/images/linkedin.png'
import Twitter from '../../assets/images/twitter.png'
import Instagram from '../../assets/images/instagram.png'
import Youtube from '../../assets/images/youtube.png'
import Tiktok from '../../assets/images/tiktok.png'
import Facebook from '../../assets/images/facebook.png'
import { FiTarget } from 'react-icons/fi'
import { DataGrid } from '../ui/objects/DataGrid'
import { PieChart } from '../ui/molecules/charts/PieChart'
import { NoDataInfo } from '../ui/atoms/NoDataInfo'
import { RequestProfileVisibility } from './components/RequestProfileVisibility'
import QRCode from 'qrcode'
import { RoutesLinks } from 'components/routes-links'
import { Button } from 'components/ui/atoms/Button'
import useUser from 'hooks/useUser'

import Config from 'config';
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { ButtonCardSecondary } from 'components/ui/atoms/ButtonCardSecondary'
import LineChart from 'components/ui/molecules/charts/LineChart'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'


export const StartupGeneralInformation = ({
  info,
  investments = [],
  requestAccess,
  publicProfile = false,
  isTeamMember = false,
  investorView = false,
  deal = null,
  setInvestorView,
  reminders,
  deleteReminder,
  setReminderToEdit,
  markReminderAsCompleted
}) => {

  const [infoToShow, setInfoToShow] = useState(info || {})

  useEffect(() => {
    if (investorView) {
      setInfoToShow(info)
    } else if (!isTeamMember) {
      setInfoToShow(info.project)
    }
  }, [investorView])

  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [documents, setDocuments] = useState([])
  const [loadingDocuments, setLoadingDocuments] = useState(false)
  const [loading, setLoading] = useState(false)
  const [qr, setQr] = useState(null)
  const { isFounder, isInvestor, userLanguage } = useUser()
  const [groupedInvesments, setGroupedInvestments] = useState({})

  const formatter = new Intl.DateTimeFormat(userLanguage, { month: 'short', year: 'numeric' })
  const headerClass = `text-main-${Config.PLATFORM} font-bold text-sm border-b border-main-${Config.PLATFORM} text-left bg-white`
  const documentIcons = {
    pitch: PitchIcon,
    pitch_deck: PitchDeckIcon,
    sales_deck: SalesDeckIcon,
    business_plan: BusinessPlanIcon,
    annual_report: AnnualReportIcon,
    captable: CaptableIcon,
    cv: CvIcon,
    others_documents: OthersDocumentsIcon
  }
  const socialNetworks = [
    {
      name: 'linkedin',
      icon: Linkedin,
      url: infoToShow.linkedin
    },
    {
      name: 'twitter',
      icon: Twitter,
      url: infoToShow.twitter
    },
    {
      name: 'instagram',
      icon: Instagram,
      url: infoToShow.instagram
    },
    {
      name: 'youtube',
      icon: Youtube,
      url: infoToShow.youtube
    },
    {
      name: 'tiktok',
      icon: Tiktok,
      url: infoToShow.tiktok
    },
    {
      name: 'facebook',
      icon: Facebook,
      url: infoToShow.facebook
    }
  ].sort((a, b) => {
    if (a.url && b.url) {
      return a.url.length - b.url.length
    } else if (a.url) {
      return -1
    } else if (b.url) {
      return 1
    }
    return 0
  })
  const socialNetworksItemsFounder = [
    {
      name: 'linkedin',
      title: 'Linkedin',
      icon: <FaLinkedin />
    },
    {
      name: 'twitter',
      title: 'Twitter',
      icon: <FaTwitter />
    },
    {
      name: 'youtube',
      title: 'YouTube',
      icon: <FaYoutube />
    },
    {
      name: 'medium',
      title: 'Medium',
      icon: <FaMedium />
    },
    {
      name: 'github',
      title: 'GitHub',
      icon: <FaGithub />
    },
    {
      name: 'gitlab',
      title: 'GitLab',
      icon: <FaGitlab />
    },
    {
      name: 'crunchbase_url',
      title: 'Crunchbase',
      icon: <SiCrunchbase />
    }
  ]

  const getFounderSocialNetworks = (user) => {
    const userNetworks = user?.social_networks || {}
    return Object.keys(userNetworks)
      .filter(n => userNetworks[n])
      .map(n => {
        const item = socialNetworksItemsFounder.find(i => i.name === n)
        return item || null
      })
  }

  const getSectors = () => {
    return [t(infoToShow.sector?.key), t(infoToShow.subsector?.key)]
      .filter(Boolean)
      .join(', ')
  }

  const getOtherInterestingAreas = () => {
    return Subsectors
      .filter(subsector => (infoToShow.other_interesting_areas || []).includes(subsector._id))
      .map(subsector => t(subsector.key)).join(', ')
  }

  const parseMember = (member) => {
    if (investorView || isTeamMember) {
      return {
        name: member.user
          ? member.user.last_name
            ? `${member.user.name} ${member.user.last_name}`
            : member.user.name
          : `${member.name} ${member.last_name}`,
        role: member.role ? t(member.role.key) : null,
        equity:
          member.percentage !== null &&
            member.percentage !== undefined
            ? member.percentage
            : null,
        image: member.user && member.user.image ? member.user.image : member.image ? member.image : Person,
        description: member?.user?.self_description,
        social_networks: member?.user?.social_networks || member.linkedin ? { linkedin: member.linkedin } : null,
        _id: member.user ? member.user._id : member._id
      }
    } else {
      return {
        name: `${member.name} ${member.last_name}`,
        role: member.role ? t(member.role.key) : null,
        equity:
          member.percentage !== null &&
            member.percentage !== undefined
            ? member.percentage
            : null,
        image: member.image ? member.image : Person,
        _id: member._id
      }
    }
  }

  const renderBadge = (title, icon, value) => {
    return (
      <span
        data-tip={title}
        className={`rounded bg-gray-100 flex justify-between items-center gap-2 px-2 py-1 hover:bg-main-${Config.PLATFORM} hover:text-white parent-hover:text-white`}
      >
        <span className={`text-main-${Config.PLATFORM}`}>
          {icon}
        </span>
        <span className='font-semibold'>
          {value}
        </span>
      </span>
    )
  }

  const getPublicLink = () => {
    const domain = window.location.origin

    if (info && investorView) {
      return `${domain}${RoutesLinks.SHARED_DEALFLOW_LINK}${info._id}`
    } else {
      return `${domain}${RoutesLinks.PUBLIC_PROFILE_LINK}${info._id}`
    }
  }

  const copyPublicProfileLink = () => {
    addToast(t('copy_link_to_clipboard_success'), { appearance: 'success', autoDismiss: true })
    Utils.copyToClipboard(getPublicLink())
  }

  const downloadQr = () => {
    const a = document.createElement('a')
    a.href = 'data:application/png;base64' + qr
    a.download = `QR - ${infoToShow.name}.png`
    a.click()
  }

  const haveAccessPermissions = (section) => {
    return isTeamMember ||
      investorView ||
      info.project_access_granted?.includes(section) ||
      (
        isProfileVisibilityPublic() &&
        !['documents', 'kpis', 'competitors', 'captable'].includes(section)
      )
  }

  const isProfileVisibilityPublic = () => {
    return infoToShow && infoToShow.profile_visibility === 'public'
  }

  const canRequestSectionAccess = section => {
    if (investorView) {
      return false
    }

    if (!haveAccessPermissions(section)) {
      return true
    }

    return true
  }

  const canViewSection = section => {
    if (!canRequestSectionAccess(section)) {
      if (section === 'competitors' && (!info?.competitors || !info?.competitors.length)) {
        return false
      }

      if (section === 'captable' && (!info?.captable || !info?.captable.length)) {
        return false
      }

      if (section === 'team' && (!info?.founders || !info?.founders.length)) {
        return info
      }
    }

    return !(!haveAccessPermissions(section) && !canRequestSectionAccess(section))
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  useEffect(() => {
    /*if (info && info._id && !investorView) {
      QRCode.toDataURL(getPublicLink()).then(qrURLData => setQr(qrURLData))
    }*/
    ReactTooltip.rebuild()
  }, [infoToShow, investorView])

  useEffect(() => {
    investments.sort((a, b) => new Date(a.date) - new Date(b.date))

    const groupedData = investments.reduce((acc, curr) => {
      const date = new Date(curr.date)
      const key = `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}01`

      if (!acc[key]) {
        acc[key] = []
      }

      acc[key].push(curr)
      return acc
    }, {})
    setGroupedInvestments(groupedData)
  }, [investments])

  if (!info) {
    return <Loader />
  }

  return (
    <>
      <div className='mt-4'>
        {info.project && <div>
          <Card containerClassName="flex flex-col lg:flex-row lg:justify-between p-6 text-2 border border-orange-400">
          <div className="md:w-full lg:w-10/12">
            {t(investorView ? 'investor_view_founders_information_available' : 'founder_view_founders_information_disponible')}
          </div>
          <div className="md:w-full lg:w-2/12 justify-end flex text-center">
            <ButtonMain
              width="1/2 lg:w-full"
              text={t(investorView ? 'switch_to_founders_view' : 'switch_to_my_view')}
              onClick={() => setInvestorView(!investorView)}
              />
          </div>
        </Card>
        </div>}
        <div className='flex mt-4'>
          <div className="flex-1 w-3/4 pr-4">
            <Card wrapperClassName='h-full' containerClassName='flex flex-col h-full'>
              <div className="flex">
                <div className="mr-4">
                  <Avatar
                    src={infoToShow.logo}
                    alt={infoToShow.name}
                    color="#e0e6f2"
                    fgColor="#4d70b3"
                    size="100px"
                    round={false}
                    className={'avatar-startup'}
                  />
                </div>
                <div>
                  <h1 className={`text-4xl text-main-${Config.PLATFORM} mb-2 font-bold`}>
                    {infoToShow.name}
                    {infoToShow.web && <FaLink className='inline ml-2 h-4 cursor-pointer' onClick={() => window.open(infoToShow.web, '_blank')}/>}
                  </h1>
                  <div>
                    <p className="font-bold">
                      {getSectors()}
                    </p>
                    {!publicProfile && <p className="text-xs">
                      {getOtherInterestingAreas()}
                    </p>}
                  </div>
                  {isInvestor && investorView && (
                    <div className='flex flex-row'>
                      <div className='w-auto'>{Utils.getDealflowStatus(info, t)}</div>
                      {info.priority && (
                        <div className='w-auto'>{Utils.getDealflowPriority(info, t)}</div>
                      )}
                      {info.next_step && (
                        <div className='w-auto'>{Utils.getDealflowNextStep(info, t)}</div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className='flex-1 mt-2 pt-2 border-t border-gray-lines'>
                  {infoToShow.brand_statement ? <pre className='font-sans whitespace-pre-wrap'>{infoToShow.brand_statement}</pre> : <span className="text-gray font-normal text-xs">{t('brand_statement_not_provided')}</span>}
              </div>

              <div className='flex flex-row flex-wrap mt-4 gap-2'>
                {(infoToShow.country_incorporation || infoToShow.city_incorporation) && (
                  renderBadge(
                    t('location'),
                    <FaMapMarkerAlt />,
                    [t(infoToShow.country_incorporation?.key), infoToShow.city_incorporation]
                      .filter(Boolean)
                      .join(', ')
                  )
                )}

                {infoToShow.creation_date && (
                  renderBadge(
                    t('creation_date'),
                    <FaCalendarAlt />,
                    moment(infoToShow.creation_date).format('DD/MM/YYYY')
                  )
                )}

                {infoToShow.project_stage && (
                  renderBadge(
                    t('project_stage'),
                    <BsBarChartSteps />,
                    t(infoToShow.project_stage.key)
                  )
                )}
                {infoToShow.investment_stage && (
                  renderBadge(
                    t('investment_stage'),
                    <BsBarChartSteps />,
                    t(infoToShow.investment_stage.key)
                  )
                )}

                {infoToShow.business_model && (
                  renderBadge(
                    t('business_model'),
                    <BiTargetLock />,
                    t(infoToShow.business_model.key)
                  )
                )}

                {infoToShow.minimum_ticket && (
                  renderBadge(
                    t('minimum_ticket'),
                    <MdPriceChange />,
                    `${infoToShow.minimum_ticket} ${t(infoToShow.minimum_ticket_unit)}`
                  )
                )}

                {infoToShow.investment_desired && (
                  renderBadge(
                    t('investment_desired'),
                    <FaHandHoldingUsd />,
                    `${infoToShow.investment_desired} ${t(infoToShow.investment_desired_unit)}`
                  )
                )}

                {infoToShow.valuation && (
                  renderBadge(
                    t('valuation'),
                    <FaHandHoldingUsd />,
                    `${infoToShow.valuation} ${t(infoToShow.valuation_unit)}`
                  )
                )}
              </div>

              {(isInvestor && infoToShow && !publicProfile && investorView) && (
                <div className='flex-1 my-4 border-t border-gray-lines pt-2'>
                  <TagList
                    showEmptyState={true}
                    tags={infoToShow.tags || []}
                  />
                </div>
              )}
              {(isInvestor && infoToShow && !publicProfile && investorView) && (
                <div className='flex-1 my-4 border-t border-gray-lines pt-2'>
                  {reminders.length === 0 && <span className="inline-block mr-3 mt-1 font-semibold text-xxs px-2 py-0.5 text-gray-300">
                    {t('reminders_not_found')}
                  </span>}
                  {reminders.length !== 0 && (
                    <div>
                      <div>
                        <span className="inline-block mr-3 mt-1 font-semibold text-xxs px-2 py-0.5">
                          {t('incoming_reminders')}
                        </span>
                      </div>
                      <div>
                      {reminders.map((reminder) => (
                        Utils.getReminderBadge(reminder, t, deleteReminder, setReminderToEdit, markReminderAsCompleted)
                      ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {(isInvestor && infoToShow && !publicProfile) && (
                <div className='flex-1 border-t border-gray-lines pt-2 flex text-xs mt-2'>
                  <FaUser className={`text-main-${Config.PLATFORM} mr-1`} data-tip={t('main_contact_person')} />
                  {infoToShow.main_contact_person || '-'}
                  <FaEnvelope className={`text-main-${Config.PLATFORM} ml-4 mr-1`} data-tip={t('main_contact_email')} />
                  {infoToShow.main_contact_email || '-'}
                </div>
              )}
            </Card>
          </div>

          <div className='w-1/4'>
            <Card>
              <h3 className={`text-main-${Config.PLATFORM}`}>{t('social_networks')}</h3>
              <div className='grid grid-cols-3 gap-4 py-4'>
                {socialNetworks.map(({ name, icon, url }) => (
                  <div key={name} className='flex justify-center flex-row'>
                    <a
                      target='_blank'
                      href={url}
                      rel="noreferrer"
                      data-tip={!url || url === '' ? null : t(name)}
                      className={!url || url === '' ? 'opacity-25 filter grayscale' : ''}
                    >
                      <img src={icon} alt={name} className="w-8" />
                    </a>
                  </div>
                ))}
              </div>
            </Card>
          </div>
        </div>

        <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 my-8'>
          {['problem', 'solution', 'business_description', 'market'].map(key => (
            <div key={key} className='relative bg-white py-6 px-6 rounded-2xl my-4'>
              <div className='text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl left-4 -top-6 bg-lightBlue-600'>
                {key === 'problem' && <FaQuestion className="inline-block w-6 h-6" />}
                {key === 'solution' && <FaLightbulb className="inline-block w-6 h-6" />}
                {key === 'business_description' && <FaHandshake className="inline-block w-6 h-6" />}
                {key === 'market' && <FiTarget className="inline-block w-6 h-6" />}
              </div>

              <div className="pt-6 flex flex-col justify-between h-full">
                <p className="text-lg font-semibold mt-2">{t(key)}</p>
                <div className="mt-2 flex-1 flex text-base">
                  {(infoToShow[key] && infoToShow[key] !== '') && infoToShow[key]}

                  {(!infoToShow[key] || infoToShow[key] === '') && (
                    <NoDataInfo title={t('no_market_section_data')} />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        {infoToShow.presentation_video && (
          <>
            <h3 className={`text-2xl text-main-${Config.PLATFORM} mt-4 mb-4 border-b border-main-${Config.PLATFORM}`}>{t('presentation_video')}</h3>
            <div className='flex flex-row justify-center py-4'>
              <iframe width="420" height="315" className='rounded-lg' allowFullScreen
                src={infoToShow.presentation_video.replace('watch?v=', 'embed/')}>
              </iframe>
            </div>
          </>
        )}

        {(isInvestor || canViewSection('team')) && (
          <>
            <h3 className={`text-2xl text-main-${Config.PLATFORM} mt-4 mb-4 border-b border-main-${Config.PLATFORM}`}>{t('team')}</h3>

            {infoToShow?.founders && infoToShow.founders.length !== 0 ? (
              <>
                {haveAccessPermissions('team') && (
                  <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
                    {infoToShow?.founders?.map(parseMember)
                      .sort((a, b) => b.equity - a.equity)
                      .map((member) => (
                        <Card
                          key={member._id}
                          wrapperClassName="mt-[50px]"
                          containerClassName="relative pt-10"
                        >
                          <div className="absolute w-full left-0 -top-[40px] flex justify-center items-center">
                            <Avatar
                              src={member.image}
                              alt={member.name}
                              color="#e0e6f2"
                              fgColor="#4d70b3"
                              size="80px"
                              round={true}
                              className={'avatar-startup'}
                            />
                          </div>
    
                          <h2 className="text-sm text-center mt-2">
                            {member.name}
                          </h2>
                          <div className="text-center text-xs">
                            {member.role}{' '}
                            {!publicProfile && member.equity && (
                              <span className="text-xxs">
                                ({member.equity}%)
                              </span>
                            )}
                          </div>
                          <h5 className="text-sm text-center mt-2">
                            {member.description}
                          </h5>
                          {getFounderSocialNetworks(member).length > 0 && (
                            <div className="mt-6 pt-6 border-t border-slate-200 text-center">
                              <div className="flex justify-center items-center py-2">
                                {getFounderSocialNetworks(member).map(item => (
                                  <a
                                    key={item.name}
                                    href={member.social_networks[item.name]}
                                    target="_blank"
                                    rel="noreferrer"
                                    data-tip={item.title}
                                    className={`w-8 h-8 flex justify-center items-center rounded-full bg-main-${Config.PLATFORM} text-white mx-2`}
                                  >
                                    {item.icon}
                                  </a>
                                ))}
                              </div>
                            </div>
                          )}
                        </Card>
                      ))}
                  </div>
                )}
              </>
            )
            : (
             <div className='my-6'>
                <NoDataInfo title={t('no_data_registered')} />
             </div>
           )
          }    
            {!haveAccessPermissions('team') && (
              <RequestProfileVisibility
                title={t('team_private_visibility')}
                requestAccess={requestAccess}
                section='team'
                hideButton={isFounder && !isTeamMember}
              />
            )}
          </>
        )}

        {isInvestor && investorView && (
          <>
            <h3 className={`text-2xl text-main-${Config.PLATFORM} my-8 border-b border-main-${Config.PLATFORM}`}>{t('investments')}</h3>
            {investments.length === 0 && (
              <div className='my-6'>
                <NoDataInfo title={t('no_data_registered')} />
              </div>
            )}
            {investments.length !== 0 && (
              <Card>
                <div className='w-full h-96 mt-6'>
                  <LineChart
                    marginLeft={50}
                    marginRight={50}
                    yFormat='>-.0f'
                    data={[{
                      id: 'dealflow',
                      data: Object.keys(groupedInvesments).map((key) => ({
                        x: formatter.format(new Date(key.substring(0, 4), key.substring(4, 6) - 1, 1)),
                        y: groupedInvesments[key].reduce((acc, curr) => acc + curr.full_investment, 0)
                      }))
                    }]}
                  />
                </div>
                <DataGrid
                  actionsHeaderClassName={headerClass}
                  data={investments}
                  headers={[
                    {
                      key: 'shareholder',
                      title: t('investor'),
                      headerClassName: headerClass,
                      render: (key, investment) => investment.is_own_investment ? t('own_investment') : investment.shareholder
                    },
                    {
                      key: 'investment',
                      title: t('investment'),
                      headerClassName: headerClass,
                      render: (key, investment) => `${investment[key]}${investment?.unit?.toUpperCase() || 'K'} €`
                    },
                    {
                      key: 'shares',
                      headerClassName: headerClass,
                      title: t('shares')
                    },
                    {
                      key: 'date',
                      title: t('date'),
                      headerClassName: headerClass,
                      render: (key, investment) => investment[key] ? moment(investment[key]).format('YYYY-MM-DD') : t('no_data')
                    }
                  ]}
                />
              </Card>
            )}
          </>
        )}
        {(isInvestor || canViewSection('captable')) && (
          <>
            <h3 className={`text-2xl text-main-${Config.PLATFORM} my-8 border-b border-main-${Config.PLATFORM}`}>{t('captable')}</h3>

            {haveAccessPermissions('captable') && infoToShow.captable && infoToShow.captable.length !== 0 && (
              <Card>
                <div className="grid grid-cols-3 gap-4">
                  <div className='pr-4 col-span-2 border-r border-gray-lines'>
                    <DataGrid
                      actionsHeaderClassName={headerClass}
                      data={(infoToShow.captable || []).map((h, i) => ({ ...h, id: i }))}
                      headers={[
                        {
                          key: 'stakeholder',
                          title: t('stakeholder'),
                          headerClassName: headerClass
                        },
                        {
                          key: 'investment',
                          title: t('investment'),
                          headerClassName: headerClass,
                          render: (key, shareholder) => `${shareholder[key]}${shareholder?.unit?.toUpperCase() || 'K'} €`
                        },
                        {
                          key: 'shares',
                          title: t('shares'),
                          headerClassName: headerClass
                        },
                        {
                          key: 'incorporation_date',
                          title: t('incorporation_date'),
                          headerClassName: headerClass,
                          render: (key, stakeholder) => stakeholder.incorporation_date ? moment(stakeholder.incorporation_date).format('DD/MM/YYYY') : t('no_data')
                        }
                      ]}
                    />
                  </div>

                  <div className='h-96'>
                    <div className={`px-6 py-4 text-main-${Config.PLATFORM} font-bold text-sm`}>
                      {t('equity_distribution')}
                    </div>
                    <PieChart
                      legend={false}
                      data={(infoToShow?.captable || []).map(h => ({
                        id: h.stakeholder,
                        value: h.shares
                      }))}
                    />
                  </div>
                </div>
              </Card>
            )}
            {haveAccessPermissions('captable') && (!infoToShow.captable || infoToShow.captable.length === 0) && (
              <div className="mt-2 flex-1 flex text-base">
                <NoDataInfo title={t('no_data_registered')} />
              </div>
            )}

            {!haveAccessPermissions('captable') && (
              <RequestProfileVisibility
                title={t('captable_private_visibility')}
                requestAccess={requestAccess}
                section='captable'
                hideButton={isFounder && !isTeamMember}
              />
            )}
          </>
        )}

        {(isInvestor || canViewSection('competitors')) && (
          <>
            <h3 className={`text-2xl text-main-${Config.PLATFORM} my-8 border-b border-main-${Config.PLATFORM}`}>{t('competitors')}</h3>

            {haveAccessPermissions('competitors') && infoToShow.competitors && infoToShow.competitors.length !== 0 && (
              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
                {infoToShow.competitors.map((competitor, i) => (
                  <Card key={i} containerClassName='flex flex-col h-full'>
                    <h2 className="mb-2 font-bold">
                      {competitor.name}
                    </h2>

                    {competitor.description && (
                      <p className='flex-1'>{competitor.description}</p>
                    )}

                    {competitor.web && (
                      <p className={`border-t border-gray-lines pt-2 mt-2 text-main-${Config.PLATFORM}`}>
                        <FaGlobe className={`inline-block mr-2 text-main-${Config.PLATFORM}`} />
                        <a href={competitor.web} target='_blank' rel="noreferrer" className={`text-main-${Config.PLATFORM} hover:text-link-hover-${Config.PLATFORM}`}>
                          {competitor.web}
                        </a>
                      </p>
                    )}
                  </Card>
                ))}
              </div>
            )}
            {haveAccessPermissions('competitors') && (!infoToShow.competitors || infoToShow.competitors.length === 0) && (
              <div className="mt-2 flex-1 flex text-base">
                <NoDataInfo title={t('no_data_registered')} />
              </div>
            )}

            {!haveAccessPermissions('competitors') && (
              <RequestProfileVisibility
                title={t('competitors_private_visibility')}
                requestAccess={requestAccess}
                section={'competitors'}
                hideButton={isFounder && !isTeamMember}
              />
            )}
          </>
        )}

        {/*{(info && !investorView) && (
          <>
            <h3 className={`text-2xl text-main-${Config.PLATFORM} my-8 border-b border-main-${Config.PLATFORM}`}>{t('share')}</h3>
            <div className='my-6 flex flex-col justify-center py-6'>
              <div className='flex flex-row justify-center'>
                <img src={qr} alt="QR" width={150} />
              </div>

              <div className='flex flex-row justify-center mt-2'>
                <ButtonSecondary
                  onClick={() => copyPublicProfileLink()}
                  text={t('copy_link')}
                  iconComponent={<FaCopy className="inline-block mr-3" />}
                  marginRight={2}
                />
                <ButtonCardSecondary
                  onClick={() => downloadQr()}
                  text={t('download')}
                  iconComponent={<FaDownload className="inline-block mr-3" />}
                />
              </div>
            </div>
          </>
        )}*/}
      </div>

      {loading && <Loader />}
    </>
  )
}
