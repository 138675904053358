import React, { useState, useEffect, useMemo, forwardRef, useImperativeHandle } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import ProjectsService from 'services/projects-service'
import { Loader } from 'components/ui/molecules/Loader'
import { useSwal } from 'hooks/useSwal'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FaListUl, FaTrash } from 'react-icons/all'
import Avatar from 'react-avatar'
import Sectors from 'assets/json/sectors.json'
import Subsectors from 'assets/json/subsectors.json'
import { FaStar } from 'react-icons/fa'
import FormUtils from '../../../utils/form-utils'
import { SectionHeader } from '../../ui/molecules/SectionHeader'
import { Input } from '../../ui/atoms/Input'
import { Button } from '../../ui/atoms/Button'
import InputFile from '../../ui/atoms/input-files'
import { TextArea } from '../../ui/atoms/TextArea'
import { StartupEditCaptable } from './StartupEditCaptable'
import { StartupEditInvestments } from './StartupEditInvestments'
import { Autocomplete } from '../../ui/atoms/Autocomplete'
import { StartupEditYearMonthsInput } from './StartupEditYearMonthsInput'
import Select from '../../ui/atoms/Select'
import { StartupEditCompetitors } from './StartupEditCompetitors'
import { StartupEditTeamMembers } from './StartupEditTeamMembers'
import { StartupEditSettings } from './StartupEditSettings'
import Config from 'config';
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { ButtonDanger } from 'components/ui/atoms/ButtonDanger'
import { DealEditTeamMembers } from './DealEditTeamMembers'
import useUser from 'hooks/useUser'
import { CurrencyInput } from 'components/ui/atoms/CurrencyInput'

const ProjectCreateOrUpdate = forwardRef(({
  info,
  onSubmit,
  onChangeStartupMembers,
  isDealflow = false,
  onChangeCaptableShareholders,
  onChangeCompetitors,
  showSubmitButton = true,
  investments,
  onChangeInvestments
}, ref) => {
  const { isInvestor } = useUser()
  const { addToast } = useToasts()
  const { confirm } = useSwal()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [section, setSection] = useState('general_info')
  const [sectors, setSectors] = useState([])
  const [subSectors, setSubSectors] = useState([])
  const [countries, setCountries] = useState([])
  const [businessModels, setBusinessModels] = useState([])
  const [projectStages, setProjectStages] = useState([])
  const [investmentStages, setInvestmentStages] = useState([])
  const [otherInterestingAreas, setOtherInterestingAreas] = useState([])
  const sectionKeys = useMemo(() => [
    'general_info',
    'contact_info',
    'market',
    'socials',
    'kpis',
    'financing',
    isDealflow ? 'investments' : null,
    !isDealflow ? 'team' : null,
    isDealflow ? 'team_dealflow' : null,
    'competitors',
    'captable',
    !isDealflow ? 'settings' : null
  ].filter(Boolean), [info])


  const validationShape = useMemo(() => ({
    name: Yup.string().required(t('required_field')),
    logo: Yup.string().nullable(),
    sector: Yup.string().nullable(),
    subsector: Yup.string().nullable(),
    other_interesting_areas: Yup.array().default([]),
    business_model: Yup.string().nullable(),
    project_stage: Yup.string().nullable(),
    investment_stage: Yup.string().nullable(),
    country_incorporation: Yup.string().nullable(),
    city_incorporation: Yup.string().nullable(),
    brand_statement: Yup.string().nullable(),
    presentation_video: Yup.string().nullable(),
    problem: Yup.string().nullable(),
    solution: Yup.string().nullable(),
    business_description: Yup.string().nullable(),
    market: Yup.string().nullable(),
    captable: Yup.array().default([]),
    competitors: Yup.array().default([]),
    economic_income: Yup.object().default({}),
    economic_resources: Yup.object().default({}),
    economic_spending: Yup.object().default({}),
    churn_rate: Yup.object().default({}),
    priority: Yup.string().nullable(),
    next_step: Yup.string().nullable(),
    profile_visibility: Yup.string().nullable(),
    gmv: Yup.object().default({}),
    nps: Yup.object().default({}),
    aov: Yup.number()
      .typeError(t('incorrect_format'))
      .transform((_, val) => val === `${Number(val)}` ? Number(val) : null)
      .nullable(),
    cac: Yup.number()
      .typeError(t('incorrect_format'))
      .transform((_, val) => val === `${Number(val)}` ? Number(val) : null)
      .nullable(),
    ltv: Yup.number()
      .typeError(t('incorrect_format'))
      .transform((_, val) => val === `${Number(val)}` ? Number(val) : null)
      .max(100, t('incorrect_format'))
      .nullable(),
    investment_desired: Yup.number()
      .typeError(t('incorrect_format'))
      .transform((_, val) => val === `${Number(val)}` ? Number(val) : null)
      .nullable(),
    investment_desired_unit: Yup.string().nullable(),
    valuation: Yup.number()
      .typeError(t('incorrect_format'))
      .transform((_, val) => val === `${Number(val)}` ? Number(val) : null)
      .nullable(),
    valuation_unit: Yup.string().nullable(),
    compromised_amount: Yup.number()
      .typeError(t('incorrect_format'))
      .transform((_, val) => val === `${Number(val)}` ? Number(val) : null)
      .nullable(),
    compromised_amount_unit: Yup.string().nullable(),
    minimum_ticket: Yup.number()
      .typeError(t('incorrect_format'))
      .transform((_, val) => val === `${Number(val)}` ? Number(val) : null)
      .nullable(),
    minimum_ticket_unit: Yup.string().nullable(),
    web: Yup.string()
      .transform(value => !value ? null : value)
      .matches(FormUtils.URL_REGEX, t('incorrect_format'))
      .nullable(),
    crunchbase_url: Yup.string()
      .transform(value => !value ? null : value)
      .matches(FormUtils.URL_REGEX, t('incorrect_format'))
      .nullable(),
    linkedin: Yup.string()
      .transform(value => !value ? null : value)
      .matches(FormUtils.URL_REGEX, t('incorrect_format'))
      .nullable(),
    twitter: Yup.string()
      .transform(value => !value ? null : value)
      .matches(FormUtils.URL_REGEX, t('incorrect_format'))
      .nullable(),
    instagram: Yup.string()
      .transform(value => !value ? null : value)
      .matches(FormUtils.URL_REGEX, t('incorrect_format'))
      .nullable(),
    youtube: Yup.string()
      .transform(value => !value ? null : value)
      .matches(FormUtils.URL_REGEX, t('incorrect_format'))
      .nullable(),
    tiktok: Yup.string()
      .transform(value => !value ? null : value)
      .matches(FormUtils.URL_REGEX, t('incorrect_format'))
      .nullable(),
    facebook: Yup.string()
      .transform(value => !value ? null : value)
      .matches(FormUtils.URL_REGEX, t('incorrect_format'))
      .nullable(),
    creation_date: Yup.string()
      .nullable()
      .transform((value) => FormUtils.parseDateFromString(value, 'YYYY-MM-DD HH:mm')),
    contact_persons: Yup.string()
      .nullable(),
    main_contact_person: Yup.string()
      .nullable(),
    main_contact_email: Yup.string()
      .email(t('incorrect_format'))
      .nullable(),
    main_contact_linkedin: Yup.string()
      .transform(value => !value ? null : value)
      .matches(FormUtils.URL_REGEX, t('incorrect_format'))
      .nullable(),
    contact: Yup.string()
      .nullable()
  }), [])

  const {
    register,
    handleSubmit,
    trigger,
    errors,
    setValue,
    watch,
    reset
  } = useForm({
    resolver: yupResolver(Yup.object().shape(validationShape), { abortEarly: false }),
    criteriaMode: 'all',
    reValidateMode: 'all',
    mode: 'onChange'
  })

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config)
    trigger(name)
  }

  const _onSubmit = () => {
    handleSubmit((values) => {
      if (values.creation_date) {
        values.creation_date = new Date(values.creation_date)
      }
      if (!isUpdate) {
        confirm().then(isConfirmed => {
          if (isConfirmed) {
            onSubmit(values)
          }
        })
      } else {
        onSubmit(values)
      }
    }, (error) => {
      console.log(error)
      addToast(t('form_field_error'), { appearance: 'error', autoDismiss: true })
    })()
  }

  const onSelectImage = async file => {
    const fileSizeMb = (file?.size || 0) / 1024 / 1024

    if (fileSizeMb > 5) {
      addToast(t('error_file_size', { max_size: 5 }), {
        appearance: 'error',
        autoDismiss: true
      })
      return
    }

    setLoading(true)

    _setValue(
      'logo',
      file ? await FormUtils.fileToBase64(file) : null,
      { shouldValidate: true }
    )

    setTimeout(() => setLoading(false), 500)
  }

  const getCountries = () => {
    return ProjectsService.getCountries().then(result => {
      setCountries((result.data.data || []).map(item => ({
        ...item,
        id: item._id,
        name: t(item.key)
      })))
    })
  }

  const getBusinessModels = () => {
    return ProjectsService.getBusinessModels().then(result => {
      setBusinessModels((result.data.data || []).map(item => ({
        ...item,
        id: item._id,
        value: t(item.key)
      })))
    })
  }

  const Priorities = [
    { id: 'low', value: t('low_priority') },
    { id: 'medium', value: t('medium_priority') },
    { id: 'high', value: t('high_priority') }
  ]

  const NextSteps = [
    { id: 'initial_meeting', value: t('initial_meeting') },
    { id: 'contact_by_email', value: t('contact_by_email') },
    { id: 'intro', value: t('intro') }
  ]

  const getProjectStages = () => {
    return ProjectsService.getProjectStages().then(result => {
      setProjectStages((result.data.data || []).map(item => ({
        ...item,
        id: item._id,
        value: t(item.key)
      })))
    })
  }

  const getInvestmentStages = () => {
    return ProjectsService.getInvestmentStages().then(result => {
      setInvestmentStages((result.data.data || []).map(item => ({
        ...item,
        id: item._id,
        name: t(item.key)
      })))
    })
  }

  const getSectors = () => {
    const _sectors = Sectors
      .map(item => ({ name: t(item.key), id: item._id }))
      .sort((a, b) => a.name > b.name ? 1 : -1)
    setSectors(_sectors)
    return Promise.resolve()
  }

  const onSelectSector = (sector) => {
    if (sector) {
      const _subSectors = Subsectors
        .filter(s => s.sectors.includes(sector.id))
        .map(item => ({ name: t(item.key), id: item._id }))
        .sort((a, b) => a.name > b.name ? 1 : -1)
      setSubSectors(_subSectors)
    } else {
      onSelectSubSector(null)
      setSubSectors([])
    }

    _setValue('sector', sector ? sector.id : null)
  }

  const onSelectSubSector = (subSector) => {
    if (subSector) {
      const _otherAreas = Subsectors.filter((s) => s._id !== subSector.id)
        .map((s) => {
          return { value: t(s.key), id: s._id }
        })
        .sort((a, b) => a.name > b.name ? 1 : -1)
      setOtherInterestingAreas(_otherAreas)
    } else {
      _setValue('other_interesting_areas', [])
      setOtherInterestingAreas([])
    }

    _setValue('subsector', subSector ? subSector.id : null)
  }

  const getSelectableInitialValues = (id, collection, lonely) => {
    if (!id) {
      return lonely ? null : []
    }

    if (Array.isArray(id)) {
      return collection.filter(i => id.includes(i.id))
    }

    const item = collection.find(i => i.id === id)
    return item ? lonely ? item : [item] : lonely ? null : []
  }

  const hydrateForm = () => {
    Object.keys(validationShape).forEach(key => {
      setValue(key, info[key])
    })

    if (info.creation_date) {
      setValue(
        'creation_date',
        FormUtils.getDateInputValueFromDate(new Date(info.creation_date))
      )
    }

    setValue('sector', info.sector ? info.sector._id : null)
    setValue('subsector', info.subsector ? info.subsector._id : null)
    setValue('business_model', info.business_model ? info.business_model._id : null)
    if (isDealflow) {
      setValue('priority', info.priority || null)
      setValue('next_step', info.next_step || null)
    }
    setValue('project_stage', info.project_stage ? info.project_stage._id : null)
    setValue('investment_stage', info.investment_stage ? info.investment_stage._id : null)
    setValue('captable', info.captable ? info.captable : [])
    setValue(
      'country_incorporation',
      info.country_incorporation ? info.country_incorporation._id : null
    )
    setValue(
      'investment_desired_unit',
      info.investment_desired_unit ? info.investment_desired_unit : 'k'
    )
    setValue(
      'minimum_ticket_unit',
      info.minimum_ticket_unit ? info.minimum_ticket_unit : 'k'
    )

    setValue(
      'valuation_unit',
      info.valuation_unit ? info.valuation_unit : 'k'
    )

    setValue(
      'compromised_amount_unit',
      info.compromised_amount_unit ? info.compromised_amount_unit : 'k'
    )

    setValue(
      'other_interesting_areas',
      info.other_interesting_areas ? info.other_interesting_areas : []
    )
    setValue(
      'economic_resources',
      info.economic_resources ? info.economic_resources : {}
    )
    setValue(
      'economic_income',
      info.economic_income ? info.economic_income : {}
    )
    setValue(
      'economic_spending',
      info.economic_spending ? info.economic_spending : {}
    )
    setValue(
      'churn_rate',
      info.churn_rate ? info.churn_rate : {}
    )
    setValue(
      'gmv',
      info.gmv ? info.gmv : {}
    )
    setValue(
      'nps',
      info.nps ? info.nps : {}
    )

    trigger()
  }

  const getFieldData = () => {
    return Promise.all([
      getCountries(),
      getBusinessModels(),
      getProjectStages(),
      getInvestmentStages(),
      getSectors()
    ])
  }

  const haveStartup = () => {
    return info && Object.keys(info).length > 0
  }

  const changePublicVisibility = visibility => {
    _setValue('profile_visibility', visibility)
    _onSubmit()
  }

  useImperativeHandle(ref, () => ({
    submit: () => _onSubmit()
  }), [])

  useEffect(() => {
    register('logo')
    register('sector')
    register('subsector')
    register('other_interesting_areas')
    register('economic_income')
    register('profile_visibility')
    register('economic_resources')
    register('economic_spending')
    register('churn_rate')
    register('gmv')
    register('nps')
    register('captable')
    register('competitors')
    register('business_model')
    register('priority')
    register('next_step')
    register('project_stage')
    register('investment_stage')
    register('country_incorporation')
    register('city_incorporation')
    register('investment_desired_unit')
    register('compromised_amount_unit')
    register('minimum_ticket_unit')
    register('valuation_unit')
  }, [register])

  useEffect(() => {
    getFieldData().catch(() => {
      addToast(t('error_occurred'), { appearance: 'error', autoDismiss: true })
    })
  }, [])

  useEffect(() => {
    if (haveStartup()) {
      setIsUpdate(true)
      hydrateForm()
    } else {
      setIsUpdate(false)
      reset()
    }
  }, [info])

  return (
    <>
      <SectionHeader
        section={section}
        setSection={setSection}
        sectionKeys={sectionKeys}
        sectionTitles={sectionKeys.map(key => t(key))}
      />
      <div className='my-6'>

        <div className={section !== 'general_info' ? 'hidden' : ''}>
          <div className="flex gap-8 border-b border-gray-lines mb-6">
            <div className='w-1/'>
              {watch().logo ? (
                <>
                  <div className="text-xs font-medium text-black">
                    {t('logo')}
                  </div>
                  <div className="mt-2 flex flex-col justify-center">
                    <Avatar
                      src={watch().logo}
                      size="80"
                      round={true}
                      color="#e0e6f2"
                      fgColor="#4d70b3"
                      className="mx-auto my-0 avatar-startup"
                    />
                    <ButtonDanger
                      marginTop={2}
                      type="button"
                      iconComponent={<FaTrash className="inline-block mr-2" />}
                      text={t('remove')}
                      onClick={() => onSelectImage(null)}
                    />
                  </div>
                </>
              ) : (
                <div className="my-3">
                  <InputFile
                    label={t('logo')}
                    placeholder={t('select_logo')}
                    error={errors.logo}
                    setSelectedFile={(file) => onSelectImage(file)}
                    accept="image/*"
                    padding="18px"
                  />
                </div>
              )}
            </div>
            <div className='flex-1'>
              <div className="my-3">
                <Input
                  reference={register}
                  id="name"
                  name="name"
                  placeholder={t('introduce_project_name')}
                  label={t('project_name')}
                  required={true}
                  error={errors.name}
                />
              </div>
              <div className="my-3">
                <TextArea
                  reference={register}
                  name="brand_statement"
                  label={t('brand_statement')}
                  placeholder={t('brand_statement_help')}
                  error={errors.brand_statement}
                  row={6}
                />
              </div>

            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <Autocomplete
              name="sector"
              placeholder={t('choose_or_type_sector')}
              label={t('sector')}
              required={true}
              options={sectors}
              initialValues={getSelectableInitialValues(info?.sector?._id, sectors, true)}
              error={errors.sector}
              onSelect={(sector) => onSelectSector(sector)}
            />

            <Autocomplete
              name="subsector"
              placeholder={t('choose_or_type_subsector')}
              label={t('subsector')}
              options={subSectors}
              initialValues={getSelectableInitialValues(info?.subsector?._id, subSectors, true)}
              error={errors.subsector}
              onSelect={subsector => onSelectSubSector(subsector)}
            />

            <Select
              disabled={!watch().subsector}
              multiSelect={true}
              name="other_interesting_areas"
              reference={register}
              label={t('other_interesting_areas')}
              placeholder={t('any_other_interesting_areas')}
              initialValues={getSelectableInitialValues(info?.other_interesting_areas, otherInterestingAreas)}
              items={otherInterestingAreas}
              onSelect={selected => {
                _setValue('other_interesting_areas', selected.map(o => o.id))
              }}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <Select
              name="project_stage"
              label={t('project_stage')}
              reference={register}
              required={false}
              placeholder={t('search_project_stage')}
              error={errors.project_stage}
              initialValues={getSelectableInitialValues(watch('project_stage'), projectStages)}
              items={projectStages}
              onSelect={selected => {
                _setValue('project_stage', selected.length ? selected[0].id : null)
              }}
            />

            <Autocomplete
              name="country_incorporation"
              placeholder={t('choose_or_type_country_incorporation')}
              options={countries}
              label={t('country_incorporation')}
              required={false}
              initialValues={getSelectableInitialValues(info?.country_incorporation?._id, countries, true)}
              error={errors.country_incorporation}
              onSelect={country => {
                _setValue('country_incorporation', country ? country.id : null)
              }}
            />

            <Input
              reference={register}
              name="city_incorporation"
              placeholder={t('introduce_city_incorporation')}
              label={t('city_incorporation')}
              error={errors.city_incorporation}
            />

          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <Input
              reference={register}
              name="creation_date"
              placeholder={t('startup_creation_date')}
              label={t('startup_creation_date')}
              type="date"
              error={errors.creation_date}
            />
            <Input
              name="web"
              reference={register}
              placeholder={t('introduce_project_web')}
              label={t('project_web')}
              error={errors.web}
            />
            <Input
              name="presentation_video"
              reference={register}
              placeholder={t('introduce_presentation_video_url')}
              label={t('introduce_presentation_video')}
              error={errors.presentation_video}
            />
          </div>

          {isInvestor && (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <Select
                name="priority"
                reference={register}
                label={t('priority')}
                placeholder={t('priority')}
                initialValues={getSelectableInitialValues(watch('priority'), Priorities)}
                items={Priorities}
                error={errors.priority}
                onSelect={selected => {
                  _setValue('priority', selected.length ? selected[0].id : null)
                }}
              />
              <Select
                name="next_step"
                reference={register}
                label={t('next_step')}
                placeholder={t('next_step')}
                initialValues={getSelectableInitialValues(watch('next_step'), NextSteps)}
                items={NextSteps}
                error={errors.next_step}
                onSelect={selected => {
                  _setValue('next_step', selected.length ? selected[0].id : null)
                }}
              />
            </div>
          )}
        </div>
        <div className={section !== 'contact_info' ? 'hidden' : ''}>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <Input
              reference={register}
              name="main_contact_person"
              placeholder={t('main_contact_person')}
              label={t('main_contact_person')}
              error={errors.main_contact_person}
            />
            <Input
              name="main_contact_email"
              reference={register}
              placeholder={t('main_contact_email')}
              label={t('main_contact_email')}
              error={errors.main_contact_email}
            />
            <Input
              reference={register}
              name="main_contact_linkedin"
              placeholder={t('main_contact_linkedin')}
              label={t('main_contact_linkedin')}
              error={errors.main_contact_linkedin}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-4">
            <Input
              reference={register}
              name="contact_persons"
              placeholder={t('other_contact_persons')}
              label={t('other_contact_persons')}
              error={errors.contact_persons}
            />
            <Input
              name="contact"
              reference={register}
              placeholder={t('other_contact_ways')}
              label={t('other_contact_ways')}
              error={errors.contact}
            />
          </div>
        </div>
        <div className={section !== 'socials' ? 'hidden' : ''}>
          {[
            'facebook',
            'twitter',
            'instagram',
            'linkedin',
            'youtube',
            'tiktok'
          ].map(network => (
            <div key={network} className="my-3">
              <Input
                name={network}
                reference={register}
                placeholder={t(`${network}_startup_profile_placeholder`)}
                label={t(network)}
                error={errors[network]}
              />
            </div>
          ))}

          <div className="my-3">
            <Input
              name="crunchbase_url"
              reference={register}
              placeholder={t('crunchbase_startup_profile_placeholder')}
              label={t('crunchbase')}
              error={errors.crunchbase_url}
            />
          </div>
        </div>

        <div className={section !== 'market' ? 'hidden' : ''}>
          <Select
            name="business_model"
            reference={register}
            label={t('business_model')}
            placeholder={t('search_business_model')}
            initialValues={getSelectableInitialValues(watch('business_model'), businessModels)}
            items={businessModels}
            error={errors.business_model}
            onSelect={selected => {
              _setValue('business_model', selected.length ? selected[0].id : null)
            }}
          />

          <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
            {['problem', 'solution', 'market', 'business_description'].map(key => (
              <TextArea
                key={key}
                reference={register}
                name={key}
                label={t(key)}
                placeholder={t(key + '_help')}
                error={errors[key]}
                row={6}
              />
            ))}
          </div>
        </div>

        <div className={section !== 'competitors' ? 'hidden' : ''}>
          <StartupEditCompetitors
            competitors={watch().competitors}
            onChange={() => onChangeCompetitors && onChangeCompetitors()}
            isDealflow={isDealflow}
            startup={info}
            loading={loading}
            setLoading={setLoading}
          />
        </div>

        <div className={section !== 'kpis' ? 'hidden' : ''}>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-1">
            <Input
              reference={register}
              name="aov"
              placeholder={t('aov')}
              label={t('aov')}
              type="number"
              error={errors.aov}
            />

            <Input
              reference={register}
              name="ltv"
              placeholder={t('ltv_long')}
              label={t('ltv_long')}
              type="number"
              error={errors.ltv}
            />

            <Input
              reference={register}
              name="cac"
              placeholder={t('cac_long')}
              label={t('cac_long')}
              type="number"
              error={errors.cac}
            />
          </div>

          <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4'>
            <StartupEditYearMonthsInput
              title={t('economic_income')}
              description={t('economic_income_help')}
              yearsData={watch().economic_income || {}}
              onChange={yearData => _setValue('economic_income', yearData)}
            />
            <StartupEditYearMonthsInput
              title={t('economic_spending')}
              description={t('economic_spending_help')}
              yearsData={watch().economic_spending || {}}
              onChange={yearData => _setValue('economic_spending', yearData)}
            />
            <StartupEditYearMonthsInput
              title={t('economic_resources')}
              description={t('economic_resources_help')}
              yearsData={watch().economic_resources || {}}
              onChange={yearData => _setValue('economic_resources', yearData)}
            />
            <StartupEditYearMonthsInput
              title={t('churn_rate')}
              description={t('churn_rate_help')}
              yearsData={watch().churn_rate || {}}
              symbol='%'
              maxValue='100'
              onChange={yearData => _setValue('churn_rate', yearData)}
            />
            <StartupEditYearMonthsInput
              title={t('gmv')}
              description={t('gmv_help')}
              yearsData={watch().gmv || {}}
              onChange={yearData => _setValue('gmv', yearData)}
            />
            <StartupEditYearMonthsInput
              title={t('nps_short')}
              description={t('nps_help')}
              yearsData={watch().nps || {}}
              symbol={<FaStar />}
              maxValue='10'
              onChange={yearData => _setValue('nps', yearData)}
            />
          </div>
        </div>

        <div className={section !== 'financing' ? 'hidden' : ''}>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <Autocomplete
              name="investment_stage"
              placeholder={t('introduce_investment_stage')}
              label={t('investment_stage')}
              options={investmentStages}
              error={errors.investment_stage}
              initialValues={getSelectableInitialValues(info?.investment_stage?._id, investmentStages, true)}
              onSelect={stage => _setValue('investment_stage', stage ? stage.id : null)}
            />


            <CurrencyInput
              nameInput={'investment_desired'}
              nameSelect={'investment_desired_unit'}
              reference={register}
              placeholder={t('investment_desired') + ' (€)'}
              label={t('investment_desired') + ' (€)'}
              error={errors.investment_desired}
              watch={watch}
              setValue={_setValue}
            />

            <CurrencyInput
              nameInput={'compromised_amount'}
              nameSelect={'compromised_amount_unit'}
              reference={register}
              placeholder={t('compromised_amount') + ' (€)'}
              label={t('compromised_amount') + ' (€)'}
              error={errors.compromised_amount}
              watch={watch}
              setValue={_setValue}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <CurrencyInput
              nameInput={'minimum_ticket'}
              nameSelect={'minimum_ticket_unit'}
              reference={register}
              placeholder={t('minimum_ticket') + ' (€)'}
              label={t('minimum_ticket') + ' (€)'}
              error={errors.minimum_ticket}
              watch={watch}
              setValue={_setValue}
            />

            <CurrencyInput
              nameInput={'valuation'}
              nameSelect={'valuation_unit'}
              reference={register}
              placeholder={t('valuation') + ' (€)'}
              label={t('valuation') + ' (€)'}
              error={errors.valuation}
              watch={watch}
              setValue={_setValue}
            />
          </div>
        </div>

        <div className={section !== 'investments' ? 'hidden' : ''}>
          <StartupEditInvestments
            investments={investments}
            captable={watch().captable}
            onChange={() => onChangeInvestments && onChangeInvestments()}
            isDealflow={isDealflow}
            startup={info}
            loading={loading}
            setLoading={setLoading}
          />
        </div>

        <div className={section !== 'captable' ? 'hidden' : ''}>
          <StartupEditCaptable
            captable={watch().captable}
            onChange={() => onChangeCaptableShareholders && onChangeCaptableShareholders()}
            isDealflow={isDealflow}
            startup={info}
            loading={loading}
            setLoading={setLoading}
          />
        </div>

        <div className={section !== 'team' ? 'hidden' : ''}>
          <StartupEditTeamMembers
            startup={info}
            onChange={() => onChangeStartupMembers && onChangeStartupMembers()}
          />
        </div>

        <div className={section !== 'team_dealflow' ? 'hidden' : ''}>
          <DealEditTeamMembers
            startup={info}
            onChange={() => onChangeStartupMembers && onChangeStartupMembers()}
            loading={loading}
            setLoading={setLoading}
          />
        </div>

        <div className={section !== 'settings' ? 'hidden' : ''}>
          <StartupEditSettings
            visibility={watch().profile_visibility}
            onSelect={changePublicVisibility}
          />
        </div>

        {showSubmitButton && !['team', 'settings', 'team_dealflow', 'captable', 'competitors', 'investments'].includes(section) && (
          <div className={'flex justify-end my-6'}>
              <ButtonMain
                width={'w-full md:w-1/2'}
                text={isUpdate ? t('update') : t('save')}
                onClick={() => _onSubmit()}
              />
          </div>
        )}
      </div>

      {loading && <Loader />}
    </>
  )
})

export default ProjectCreateOrUpdate
