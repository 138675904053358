import React from 'react'
import { useTranslation } from 'react-i18next'

import { Container } from 'components/ui/objects/Container'
import { Button } from 'components/ui/atoms/Button'

import TestVideo from 'styles/images/test.mp4'
import Logo from 'styles/images/logo_light.svg'

import Gray from 'styles/images/circle_gray.png'
import Blue from 'styles/images/circle_blue.png'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

const FirstStep = ({ nextStep }) => {
  const { t } = useTranslation()

  return (
    <div className="bg-bgOnboardingOne bg-cover bg-no-repeat bg-center min-w-screen min-h-screen relative flex justify-center align-center h-full">
      <div className="w-full flex items-center justify-between pr-10 fixed top-0">
        <img src={Logo} alt="Logo" className="  mx-6 my-4" />
      </div>

      <div className="pt-8 px-4 | text-center | w-11/12 lg:w-9/12 max-w-screen-lg min-h-screen">
        <div className="flex flex-col justify-center items-center h-full">
          <h1 className="xl:text-4xl text-black  mb-8">{t('welcome')}</h1>
          <p className="max-w-xl mb-4 text-sm sm:text-base">
            {t('welcome_subtitle_one')}
          </p>
          <p className="max-w-xl mb-4 text-sm sm:text-base">
            {t('welcome_subtitle_two')}
          </p>
          <p className="max-w-xl mb-16 text-sm sm:text-base">
            {t('welcome_subtitle_three')}
          </p>
          <ButtonMain
            onClick={nextStep}
            type="button"
            text={t('next')}
          />
        </div>
      </div>
    </div>
  )
}

export default FirstStep
