import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/ui/atoms/Button'
import { Input } from 'components/ui/atoms/Input'
import { Autocomplete } from 'components/ui/atoms/Autocomplete'
import { TagsSelector } from 'components/ui/atoms/TagsSelector'
import { SidePanel } from 'components/ui/objects/SidePanel'
import Config from 'config';
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

const DocumentsFilters = ({
  onClose,
  onSubmit,
  filters,
  tags,
  dealId,
  entrepreneurId,
  dealflow
}) => {
  const { t } = useTranslation()
  const [startup, setStartup] = useState(null)
  const [selectedTags, setSelectedTags] = useState([])
  const [documentName, setDocumentName] = useState('')
  const parsedDealflow = dealflow.map(n => ({ id: n._id, name: n.name }))

  const getStartupInitialValue = () => {
    if (!startup || !parsedDealflow) {
      return null
    }

    const _startup = dealflow.find(d => d._id === startup)

    return _startup ? { id: _startup._id, name: _startup.name } : null
  }

  useEffect(() => {
    setDocumentName(filters?.name || '')
    setStartup(filters?.startup)
    setSelectedTags(filters?.tags || [])
  }, [filters])

  useEffect(() => {
    if (dealId && dealflow && dealflow.length) {
      setStartup(dealflow.find(d => d._id === dealId)?._id)
    }
  }, [dealId, dealflow])

  return (
    <SidePanel
      onClose={onClose}
      title={t('filter_documents')}
      width="1/4"
    >
      <Input
        name="name"
        label={t('document_name')}
        placeholder={t('document_name')}
        onChange={(e) => setDocumentName(e.target.value)}
        value={documentName}
      />

      {!dealId && (
        <Autocomplete
          name="startup"
          label={t('startup')}
          placeholder={t('startup')}
          noOptionsText={t('startups_not_found')}
          options={parsedDealflow}
          onSelect={selected => setStartup(selected?.id)}
          initialValues={getStartupInitialValue()}
        />
      )}

      <div className="mt-4 w-full">
        <label className="block mb-1 text-left text-xs font-medium text-black">
          {t('tags')}
        </label>
        <TagsSelector
          tags={tags}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
      </div>
      <div className={`flex justify-end mt-6 pt-2 border-t border-separator-${Config.PLATFORM}`}>
        <ButtonSecondary
          text={t('clear_filters')}
          onClick={() => onSubmit({ startup: dealId || null, name: null, tags: null })}
          marginRight={2}
        />
        <ButtonMain
          text={t('filter_documents')}
          onClick={() => onSubmit({ startup, name: documentName, tags: selectedTags })}
        />
      </div>
    </SidePanel>
  )
}

export default DocumentsFilters
