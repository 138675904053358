import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '../../../../../ui/molecules/Modal'
import Cross from 'styles/images/cross_gray.svg'
import { Button } from '../../../../../ui/atoms/Button'
import { TextArea } from 'components/ui/atoms/TextArea'

import { RichEditor } from 'components/ui/objects/RichEditor'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Input } from 'components/ui/atoms/Input'
import { isEmpty } from 'lodash'

import Config from 'config';
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

const TextNotes = ({
  showModal,
  handleClose,
  onSubmit,
  initialValues,
  editable
}) => {
  //console.log(initialValues)
  const { t } = useTranslation()

  const TextNoteSchema = Yup.object().shape({
    title: Yup.string().required(t('required_field')),
    content: Yup.string().required(t('required_field'))
  })

  
  const { register, handleSubmit, errors, setValue, getValues, trigger, watch } = useForm({
    mode: 'all',
    criteriaMode: 'all',
    reValidateMode: 'all',
    resolver: yupResolver(TextNoteSchema)
  })

  const watchContent = watch('editorEnabled', false);

  const _setValue = (name, value) => {
    setValue(name, value, { shouldValidate: true })
    trigger(name)
  }

  useEffect(() => {
    register('content')
    register('editorEnabled')
  }, [])

  useEffect(() => {
    if (!isEmpty(initialValues)) {
      setValue('title', initialValues.title)
      setValue('content', initialValues.content || '')
    }
    setValue('editorEnabled', true)
  }, [initialValues])



  return (
    <>
      <Modal
        showModal={showModal}
        showCloseModal={true}
        onClose={handleClose}
        paddingTop="6"
        paddingBottom="4"
      >
        <h3 className={`font-medium mr-4 text-main-${Config.PLATFORM}`}>{t('add_text_note')}</h3>

        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-6">
            <Input
              disabled={!editable}
              reference={register}
              id="title"
              type="text"
              name="title"
              placeholder={t('introduce_note_title')}
              label={t('note_title') + ':'}
              error={errors.title}
            />
          </div>
          <div className="mt-6">
            {/* <TextArea
              disabled={!editable}
              reference={register}
              id="content"
              placeholder={t('write_note')}
              label={t('note_content') + ':'}
              rows="8"
              cols="50"
              name="content"
              error={errors.note}
            /> */}
            {watchContent && <RichEditor
              id={'content'}
              reference={register}
              name='content'
              label={t('note_content') + ':'}
              placeholder={`${t('write_note')}`}
              onChange={value => _setValue('content', value === '<p><br></p>' ? '' : value)}
              toolbarClassName="content"
              value={getValues().content || ''}
              error={errors.content}
            />}
          </div>
          {editable && (
          <div className={`flex justify-end mt-4 border-t pt-2 border-separator-${Config.PLATFORM}`}>
              <ButtonMain
                type="submit"
                text={t('save')}
              />
            </div>
          )}
        </form>
      </Modal>
    </>
  )
}

export default TextNotes
