import Shepherd from 'shepherd.js'
import { useTranslation } from 'react-i18next'
import { RoutesLinks } from 'components/routes-links'
import React from 'react'
import UsersService from 'services/users-service'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import UserOperations from 'redux/user/user-operations'
import { renderToStaticMarkup } from 'react-dom/server'
import { TourStep } from 'components/ui/atoms/TourStep'
import { Button } from 'components/ui/atoms/Button'
import useUser from './useUser'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

/**
 * Hook for use tour
 * @param tourTag {'quick_tour' | 'register_startup_tour'}
 * @param skip {string | string[] | null=}
 * @returns {Tour}
 */
export function useTour (tourTag, skip) {
  const { user, isFounder, isVentureCapitals, isProspectiveInvestor } = useUser()
  const notifications = useSelector((state) => state.notifications)
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const generateProgressBar = tour => {
    const currentStepElement = tour.currentStep.el
    const header = currentStepElement.querySelector('.shepherd-footer')
    const progress = document.createElement('div')
    const innerBar = document.createElement('span')
    const progressPercentage = ((tour.steps.indexOf(tour.currentStep) + 1) / tour.steps.length) * 100 + '%'

    progress.className = 'relative bg-gray-200 overflow-hidden'
    progress.style.margin = '28px 8px 0 0'
    progress.style.borderRadius = '9px'
    progress.style.height = '9px'
    progress.style.flex = '1'

    innerBar.className = 'absolute left-0 bottom-0 h-full'
    innerBar.style.width = progressPercentage

    progress.appendChild(innerBar)

    header.insertBefore(progress, currentStepElement.querySelector('.shepherd-button'))
  }

  const generateButton = (text, callback, className = '') => ({
    text,
    label: text,
    action: callback,
    classes:
      'text-center text-sm font-semibold' +
      'bg-transparence-blue rounded-2xl cursor-pointer hover:shadow-inner ' +
      className
  })

  const generateActions = actions => {
    return {
      when: {
        show () {
          const content = this.el.querySelector('.shepherd-content')
          let textWrapper = content.querySelector('.shepherd-text')

          if (!textWrapper) {
            textWrapper = document.createElement('div')
            textWrapper.className = 'shepherd-text'
            content.insertBefore(textWrapper, content.querySelector('.shepherd-footer'))
          }

          const wrapper = document.createElement('div')

          actions.forEach(action => {
            const buttonWrapper = document.createElement('div')

            buttonWrapper.innerHTML = renderToStaticMarkup(
              <ButtonMain text={action.text} className="block my-2 text-xs" />
            )

            buttonWrapper.addEventListener('click', () => {
              this.complete()
              action.onClick()
            })

            wrapper.appendChild(buttonWrapper)
          })

          wrapper.className = 'border-b border-gray-lines'
          textWrapper.appendChild(wrapper)

          generateProgressBar(this.tour)
        }
      }
    }
  }

  const generateAttachment = (selector, anchor) => ({
    attachTo: { element: selector, on: anchor },
    popperOptions: {
      modifiers: [{
        name: 'offset',
        options: { offset: [0, 20] }
      }]
    }
  })

  const saveUserTourDoneFlag = () => {
    const tours = { ...user.tours }
    tours[tourTag] = moment()

    UsersService.updateTours(tours).then(result => {
      dispatch(UserOperations.setUserInfo({ data: result.data }))
    })
  }

  const backButton = generateButton(t('back'), function () { this.back() }, 'bg-white')
  const nextButton = generateButton(t('next'), function () { this.next() })
  const completeButton = generateButton(t('finish'), function () {
    saveUserTourDoneFlag()

    this.complete()

    if (tourTag === 'quick_tour') {
      history.push(RoutesLinks.DASHBOARD_LINK)
    }
  })

  /**
   * @type {{[tourTag: string] : Step[]}}
   */
  const steps = {
    quick_tour: [
      // common steps
      {
        id: 'welcome',
        title: t('welcome'),
        text: renderToStaticMarkup(<TourStep content={t('take_tour')} /* img={tourImg} */ />)
      },
      {
        id: 'dashboard',
        ...generateAttachment('#sidebar-menu-item-1', 'right'),
        beforeShowPromise: async () => history.push(RoutesLinks.DASHBOARD_LINK),
        title: t('dashboard'),
        text: renderToStaticMarkup(<TourStep content={t('dashboard_description_tour')} />)
      },
      // investor (venture_capitals)
      {
        id: 'projects',
        ...generateAttachment('#sidebar-menu-item-2', 'right'),
        beforeShowPromise: async () => history.push(RoutesLinks.DEALFLOW_LINK),
        showOn: () => isVentureCapitals,
        title: t('projects'),
        text: renderToStaticMarkup(<TourStep content={t('projects_description_tour')} />)
      },
      {
        id: 'pipeline-manager',
        ...generateAttachment('#sidebar-menu-item-3', 'right'),
        beforeShowPromise: async () => history.push(`${RoutesLinks.PIPELINE_MANAGER_LINK}boards`),
        showOn: () => isVentureCapitals,
        title: t('pipeline_manager'),
        text: renderToStaticMarkup(<TourStep content={t('pipeline_manager_tour')} />)
      },
      {
        id: 'questionnaires-engine',
        ...generateAttachment('#sidebar-menu-item-4', 'right'),
        beforeShowPromise: async () => history.push(RoutesLinks.QE_FORMS_LINK),
        showOn: () => isVentureCapitals,
        title: t('questionnaires_engine'),
        text: renderToStaticMarkup(<TourStep content={t('questionnaires_engine_description_tour')} />),
        when: {
          show () { generateProgressBar(this.tour) },
          // save flag at this point because the next step is a shortcut list
          hide () { saveUserTourDoneFlag() }
        }
      },
      {
        id: 'score-cards',
        ...generateAttachment('#sidebar-menu-item-5', 'right'),
        beforeShowPromise: async () => history.push(RoutesLinks.SCORE_CARDS_LINK),
        showOn: () => isVentureCapitals,
        title: t('score_cards'),
        text: renderToStaticMarkup(<TourStep content={t('score_cards_description_tour')} />)
      },
      {
        id: 'company',
        ...generateAttachment('#sidebar-menu-item-8', 'right'),
        beforeShowPromise: async () => history.push(RoutesLinks.COMPANY_LINK),
        showOn: () => isVentureCapitals,
        title: t('company'),
        text: renderToStaticMarkup(<TourStep content={t('company_tour')} />)
      },
      {
        id: 'vc-resume-actions',
        ...generateActions([
          {
            text: t('tour_action_invite_startup'),
            onClick: () => history.push(RoutesLinks.DEALFLOW_LINK)
          },
          {
            text: t('tour_action_create_board'),
            onClick: () => history.push(`${RoutesLinks.PIPELINE_MANAGER_LINK}boards`)
          },
          {
            text: t('tour_action_create_score_card'),
            onClick: () => history.push(RoutesLinks.SCORE_CARDS_LINK)
          },
          {
            text: t('tour_action_create_form'),
            onClick: () => history.push(RoutesLinks.QE_FORMS_LINK)
          }
        ]),
        showOn: () => isVentureCapitals,
        title: t('tour_next_steps')
      },
      // founder steps
      {
        id: 'register-startup',
        ...generateAttachment('#sidebar-menu-item-2', 'right'),
        showOn: () => isFounder,
        title: t('register_startup_tour'),
        text: renderToStaticMarkup(<TourStep content={t('register_startup_tour_description')} />)
      },
      {
        id: 'invitations',
        ...generateAttachment('#invitations-tour', 'bottom'),
        showOn: () => isFounder && !!notifications.invitations,
        title: t('invitations_title_tour'),
        text: renderToStaticMarkup(<TourStep content={t('invitations_description_tour')} />)
      }
    ],
    integrations: [
      {
        id: 'welcome',
        title: t('welcome'),
        text: renderToStaticMarkup(<TourStep content={t('take_tour')} /* img={tourImg} */ />)
      },
      {
        id: 'integrations',
        title: t('services'),
        text: renderToStaticMarkup(<TourStep content={t('services')} /* img={tourImg} */ />)
      },
      {
        id: 'api_keys',
        title: t('api_keys'),
        text: renderToStaticMarkup(<TourStep content={t('api_keys')} /* img={tourImg} */ />)
      },
      {
        id: 'kiota_express_integration',
        title: t('kiota_express_integration'),
        text: renderToStaticMarkup(<TourStep content={t('kiota_express_integration')} /* img={tourImg} */ />)
      },
      {
        id: 'kiota_express_integration_new_execution',
        title: t('kiota_express_integration_new_execution'),
        text: renderToStaticMarkup(<TourStep content={t('kiota_express_integration_new_execution')} /* img={tourImg} */ />)
      },
      {
        id: 'kiota_express_integration_result',
        title: t('kiota_express_integration_result'),
        text: renderToStaticMarkup(<TourStep content={t('kiota_express_integration_result')} /* img={tourImg} */ />)
      }
    ]
  }

  return new Shepherd.Tour({
    useModalOverlay: true,
    defaultStepOptions: {
      buttons: [backButton, nextButton],
      cancelIcon: { enabled: true, label: t('close') },
      arrow: true,
      canClickTarget: false,
      scrollTo: true,
      modalOverlayOpeningPadding: 8,
      modalOverlayOpeningRadius: 8,
      when: {
        show () { generateProgressBar(this.tour) }
      }
    },
    exitOnEsc: false,
    tourName: tourTag,
    keyboardNavigation: false,
    steps: steps[tourTag]
      // remove unused and skipped steps for correctly render progressbar
      .filter(step => {
        if (skip) {
          if (Array.isArray(skip) && skip.includes(step.id) || step.id === skip) {
            return false
          }
        }
        return step.showOn ? step.showOn() : true
      })
      // change step buttons
      .map((step, index, steps) => {
        // first step
        if (index === 0) {
          return { ...step, buttons: [nextButton] }
        }

        // last step
        if (index === steps.length - 1) {
          return { ...step, buttons: [backButton, completeButton] }
        }

        return step
      })
  })
}
