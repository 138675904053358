import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Card } from '../ui/atoms/Card'
import { NoDataInfo } from '../ui/atoms/NoDataInfo'
import { Pagination } from '../ui/atoms/Pagination'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { Loader } from '../ui/molecules/Loader'
import ProjectsService from '../../services/projects-service'
import { usePagination } from '../../hooks/usePagination'
import { DealflowExploreListItem } from './DealflowExploreListItem'
import { DealflowFilters } from './DealflowFilters'

export const ExploreStartups = forwardRef(({ setDealToResume }, ref) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [projects, setProjects] = useState([])
  const [loading, setLoading] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [filters, setFilters] = useState({})
  const {
    setTotalPages,
    totalPages,
    setPage,
    page,
    rowsPerPage,
    setRowsPerPage
  } = usePagination(12)

  const getProjects = () => {
    setLoading(true)
    return ProjectsService.getExploreProjects({
      ...filters,
      rowsPerPage,
      page,
      sortBy: '-createdAt'
    }).then(result => {
      const { projects, pagination } = result.data
      setProjects(projects)
      setTotalPages(pagination.pages)
    }).catch(() => {
      addToast(t('error_occurred_retrieving_startups'), { appearance: 'error', autoDismiss: true })
    }).finally(() => {
      setLoading(false)
    })
  }

  useImperativeHandle(ref, () => ({
    reload () {
      getProjects()
    },
    showFilters () {
      setShowFilters(true)
    }
  }))

  useEffect(() => {
    getProjects()
  }, [page, rowsPerPage, filters])

  return (
    <>
      {(!loading && projects.length === 0) && (
        <Card wrapperClassName="mt-6">
          <NoDataInfo
            title={t(Object.keys(filters).length ? 'no_filtered_results' : 'pool_startups_not_found')}
            textOne={t(Object.keys(filters).length ? 'no_filtered_results_text' : 'pool_startups_not_found_description')}
          />
        </Card>
      )}

      {(!loading && projects.length > 0) && (
        <div className="mt-6">
          <div className="grid grid-cols-1 md:grid-cols-2  2xl:grid-cols-3 gap-4">
            {projects.map(project => (
              <DealflowExploreListItem
                key={project._id}
                project={project}
                onManage={() => getProjects()}
                setDealToResume={setDealToResume}
              />
            ))}
          </div>

          <Pagination
            paginateOptions={[12, 24, 36, 48, 60]}
            currentPage={page}
            setCurrentPage={(page) => setPage(page)}
            perPage={rowsPerPage}
            pages={totalPages}
            setPerPage={(value) => setRowsPerPage(value[0].id)}
          />
        </div>
      )}

      {showFilters && (
        <DealflowFilters
          onClose={() => setShowFilters(false)}
          onFilter={setFilters}
          filters={filters}
          section={'explorer'}
          showTags={false}
        />
      )}

      {loading && <Loader />}
    </>
  )
})
