import axios from 'axios/index'
import Config from '../config'

const EventsService = {
  sendEvent: async (section, type, metadata) => {
    return axios.post(`${Config.PROJECTS_API_BASE_URL}events`, {
      section,
      type,
      metadata
    })
  },

  saveAnalyticEvent: async (data) => {
    return axios.post(`${Config.SUPPORT_API_URL}analytic-events`, data)
  }
}
export default EventsService
