import React from 'react'
import './Chat.css'

export function Chat () {
  return (
		<div
			id="chat-wrapper"
			className="fixed overflow-hidden z-50 right-1.5 bottom-1.5 rounded-2xl shadow-blue-400 layer-shadow"
		/>
  )
}
