import { useTranslation } from 'react-i18next'
import { SidePanel } from 'components/ui/objects/SidePanel'
import { ProgressBar } from '../atoms/ProgressBar'
import { useOnboardingSteps } from 'hooks/useOnboardingSteps'
import { ProgressTask } from '../atoms/ProgressTask'

export const OnboardingTasksPannel = ({
  onClose
}) => {
    const onboardingSteps = useOnboardingSteps()

    const { t } = useTranslation()


  return (
    <SidePanel title={t('onboarding_taks')} onClose={() => onClose && onClose()} width='1/4'>
        <div className="px-4 my-4">
            <ProgressBar value={onboardingSteps.getTaskProgress()} />
        </div>
        {onboardingSteps.getTasks().map((task, index) => {
            return (
            <ProgressTask
                key={index}
                title={task.title}
                description={task.description}
                completed={task.completed}
                onClick={task.onClick ? () => {
                    task.onClick()
                    onClose()
                } : null}
            />
            )
        })}
    </SidePanel>
  )
}
