import TranslateUtils from 'utils/translate-utils'

export const QuestionTypeSeparator = ({
  currentQuestion,
  language
}) => {
  return (
    <div
      className={`relative flex overflow-hidden ${['left', 'right'].includes(currentQuestion?.question_id?.separatorOptions?.elementsAlignment) ? 'flex-row' : ['top', 'bottom'].includes(currentQuestion?.question_id?.separatorOptions?.elementsAlignment) ? 'flex-col' : ''}`}
    >
      {
        currentQuestion?.question_id?.separatorOptions?.elementsAlignment === 'back' && (
          <div className='absolute z-0 w-full h-full'>
            <img
              src={currentQuestion?.question_id?.separatorOptions?.image}
              className={`w-full h-full ${currentQuestion?.question_id?.separatorOptions?.imageOpacity}`}
            />
          </div>
        )
      }
      <div
        className='flex-1 p-3'
        style={{ order: ['left', 'top'].includes(currentQuestion?.question_id?.separatorOptions?.elementsAlignment) ? 1 : 2 }}
        dangerouslySetInnerHTML={{ __html: TranslateUtils.getTextByLang(language, currentQuestion.question_id.description) }}
      />
      {currentQuestion?.question_id?.separatorOptions?.elementsAlignment !== 'back' && currentQuestion?.question_id?.separatorOptions?.image && (
        <div
          className={`flex-1 p-3 flex ${currentQuestion?.question_id?.separatorOptions?.imagePosition}`}
          style={{ order: ['right', 'bottom'].includes(currentQuestion?.question_id?.separatorOptions?.elementsAlignment) ? 1 : 2 }}
        >
          <img
            src={currentQuestion?.question_id?.separatorOptions?.image}
            className={`w-full ${currentQuestion?.question_id?.separatorOptions?.imageOpacity}`}
          />
        </div>
      )}
    </div>
  )
}
