import { useTranslation } from 'react-i18next'
import { Card } from 'components/ui/atoms/Card'
import { FaRegFilePdf, GrDocumentCsv } from 'react-icons/all'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { SidePanel } from 'components/ui/objects/SidePanel'

export const ExportEvaluationsOptionsSections = ({ onClose, exportCSV, exportPDF }) => {
  const { t } = useTranslation()

  return (
    <>
      <SidePanel
        width={'1/4'}
        onClose={onClose}
        title={t('export_evaluations')}
      >
        <div className={'max-h-full'}>
            <div className='grid grid-cols-2 gap-4'>
              <Card wrapperClassName='my-2'>
                <h3>{t('csv_format')}</h3>
                <div className='flex justify-center items-center mt-8'>
                  <GrDocumentCsv size={60} />
                </div>
                <ButtonSecondary
                  width={'full'}
                  marginTop={4}
                  text={t('select_this')}
                  onClick={() => exportCSV()}
                />
              </Card>
              <Card wrapperClassName='my-2'>
                <h3>{t('pdf_format')}</h3>
                <div className='flex justify-center items-center mt-8'>
                  <FaRegFilePdf size={60} />
                </div>
                <ButtonSecondary
                  width={'full'}
                  marginTop={4}
                  text={t('select_this')}
                  onClick={() => exportPDF()}
                />
              </Card>
            </div>
        </div>
      </SidePanel>
    </>
  )
}
