import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Card } from '../../../ui/atoms/Card'

import useWindowDimensions from 'hooks/useWindowsDimensions'

import LogoDefault from 'styles/images/logo_default.png'
import Tick from 'styles/images/tick_black.svg'

import Cross from 'styles/images/cross_gray.svg'

import { IconContainer } from 'components/ui/atoms/IconContainer'
import moment from 'moment'
import { CircularChard } from 'components/ui/molecules/charts/CircularChart'

import LinkedinIcon from 'styles/images/LinkedinLogo.svg'
import WebIcon from 'styles/images/web_icon.svg'
import { Button } from 'components/ui/atoms/Button'
import Avatar from 'react-avatar'
import CrunchbaseIcon from 'styles/images/crunchbase_icon.png'
import Config from 'config'
import { ButtonCardMain } from 'components/ui/atoms/ButtonCardMain'
import { ButtonCardSecondary } from 'components/ui/atoms/ButtonCardSecondary'
import { FaCheck, FaTimes } from 'react-icons/fa'

const Suggestion = ({ suggestion, decline, accept }) => {
  const { t } = useTranslation()

  return (
    <div className="my-4">
      <Card padding="2">
          <div className="flex border-b border-gray-lines pb-2 mb-2 ">
              <div className='flex flex-row w-full justify-around'>

                <div className="flex flex-col h-full text-sm min-w-0 w-3/5">
                  <h5 className={`uppercase font-bold text-sm leading-none text-main-${Config.PLATFORM} truncate mb-1`}>
                    {suggestion.name}
                  </h5>
                </div>

                <div className="flex items-center justify-end mr-5 w-2/5 ">
                <div className="info-card-container-img">
                  <Avatar
                    src={suggestion.logo}
                    name={suggestion.name}
                    alt={suggestion.name}
                    maxInitials={3}
                    className={`h-full object-contain ${suggestion.logo ? 'w-full' : ''}`}
                    color="#e0e6f2"
                    fgColor="#4d70b3"
                    round="5"
                  />
                  </div>
                </div>

              </div>
            </div>
            <div className="px-3 py-4 flex items-around items-center w-full">
              <div className="flex flex-col justify-between h-full w-full mr-4 xl:mr-4">

                <div className="flex flex-col my-2">
                  <span className="font-semibold text-xxs">
                    {t('sector')}:{' '}
                  </span>
                  <span className="font-regular text-xxxs">
                    {suggestion.sector
                      ? t(suggestion.sector.key)
                      : '-'}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="font-semibold text-xxs">
                    {t('business_model')}:{' '}
                  </span>
                  <span className="font-regular text-xxxs">
                    {suggestion.business_model
                      ? t(suggestion.business_model.key)
                      : '-'}
                  </span>
                </div>
                <div className="flex flex-col my-2">
                  <div className="flex flex-col">
                    <span className="font-semibold text-xxs">
                      {t('country')}:{' '}
                    </span>
                    <span className="font-regular text-xxxs">
                      {' '}
                      {suggestion.country_incorporation
                        ? t(suggestion.country_incorporation.key)
                        : '-'}
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex flex-col justify-between h-full w-full">
                <div className="flex flex-col">
                  <span className="font-semibold text-xxs">
                    {t('project_stage')}:{' '}
                  </span>
                  <span className="font-regular text-xxxs">
                    {suggestion.project_stage
                      ? t(suggestion.project_stage.key)
                      : '-'}
                  </span>
                </div>
                <div className="flex flex-col my-2">
                  <span className="font-semibold text-xxs">
                    {t('investment_stage')}:{' '}
                  </span>
                  <span className="font-regular text-xxxs">
                    {suggestion.investment_stage
                      ? t(suggestion.investment_stage.key)
                      : '-'}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="font-semibold text-xxs">
                    {t('city_incorporation')}:{' '}
                  </span>
                  <span className="font-regular text-xxxs">
                    {suggestion.city_incorporation
                      ? suggestion.city_incorporation
                      : '-'}
                  </span>
                </div>
              </div>
            </div>
            <div className={`flex justify-end mt-2 pt-2 border-t border-separator-${Config.PLATFORM}`}>
              <ButtonCardSecondary
                text={t('decline')}
                iconComponent={<FaTimes className='inline mr-2'/>}
                marginRight={2}
                onClick={() => decline(suggestion)}
              />
              <ButtonCardMain
                text={t('accept')}
                iconComponent={<FaCheck className='inline mr-2'/>}
                onClick={() => accept(suggestion)}
              />
            </div>
      </Card>
    </div>
  )
}

export default Suggestion
