import React, { useState } from 'react'
// import { AnimatedModal } from './AnimatedModal'
import { useTranslation } from 'react-i18next'
import Play from 'styles/images/play.svg'
import Config from 'config'

export function BasicCard (props) {
  const { t } = useTranslation()

  const [showModal, setShowModal] = useState(false)

  const openModal = () => {
    setShowModal((prev) => !prev)
  }

  // const [selectedCard, setSelectedCard] = useState(false);

  // const selectCard = () => {
  //   { !selectedCard ? setSelectedCard(true) : setSelectedCard(false) }
  // }

  return (
    <div className={`${props.question && 'w-full'}`}>
      {props.onBoardingPage ? (
        <>
          <div
            className={
              'w-32 h-32 p-2 | flex flex-col flex-grow justify-center items-center  rounded-2xl | transition-all duration-400 ease-in-out | shadow-soft-white mr-2'
            }
          >
            <div className="flex items-end h-10">
              <img src={props.image} alt={props.alt} className={'w-10'} />
            </div>
            <div className={'flex items-center h-8'}>
              <p className={'text-xxs mt-1 font-medium'}>{props.text}</p>
            </div>
            <div>
              <img
                onClick={openModal}
                src={Play}
                alt="Play icon"
                className="inline-block cursor-pointer w-10"
              />
            </div>
          </div>
        </>
      ) : props.uploadFileModal ? (
        <>
          <button onClick={props.onClick} className="focus:outline-none p-2">
            <div
              className={`${
                props.uploadFileModal
                  ? 'w-24 h-24'
                  : 'w-36 h-36 sm:w-40 sm:h-40'
              } p-4| flex flex-col flex-grow justify-center items-center | ${
                props.selected
                  ? 'shadow-soft-white shadow-inner'
                  : 'shadow-soft-white'
              } rounded-2xl | cursor-pointer transition-all duration-400 ease-in-out |shadow-soft-white hover:shadow-inner border-white`}
            >
              <div className="flex items-end h-10">
                <img
                  src={props.image}
                  alt={props.alt}
                  className={`${props.uploadFileModal ? 'w-10' : 'w-16'}`}
                />
              </div>
              <div
                className={`${
                  props.uploadFileModal
                    ? 'flex items-center h-8'
                    : 'h-6 pt-4 w-28'
                }`}
              >
                <p
                  className={`${
                    props.uploadFileModal ? 'text-xxs mt-1' : 'text-sm'
                  }  font-medium`}
                >
                  {props.text}
                </p>
              </div>
            </div>
          </button>
        </>
      ) : props.documents ? (
        <div
          className={'sm:w-20 sm:w-20 md:w-40 md:h-32 lg:w-60 lg:h-48 p-4| flex flex-col align-between justify-center items-center |  rounded-2xl | cursor-default transition-all duration-400 ease-in-out | border-white'}
        >
          <>
            {props.graph && (
              <div className={'flex items-center w-full px-4 h-10}'}>
                {props.graph && props.graph}
              </div>
            )}
            {!props.graph && props.value !== null && props.value !== undefined && (
              <div className="flex items-end h-10">
                <h1 className={`text-main-${Config.PLATFORM} font-semibold`}>
                  {props.value}
                </h1>
              </div>
            )}
            <div className={'h-6 pt-8 w-28 text-center'}>
              <h4 className={`text-main-${Config.PLATFORM} font-semibold`}>{props.text}</h4>
            </div>
          </>
        </div>
      ) : (
        <button
          onClick={
            props.cardSelection ? () => props.cardSelection(props.item) : null
          }
          className={`${props.question && 'w-full'} p-2 focus:outline-none`}
          id={props.cardId}
        >
          <div
            className={`sm:w-72 ${
              props.itemInTemplate ? 'sm:h-20' : 'sm:h-36'
            } ${
              props.question ? 'lg:w-full lg:h-auto' : 'lg:w-72'
            } | flex flex-grow ${
              props.question ? 'jusitfy-start' : 'justify-center'
            } items-center |    rounded-2xl | transition-all duration-400 ease-in-out | ${
              props.avoidHover
                ? 'cursor-default'
                : 'shadow-hover cursor-pointer hover:shadow-inner'
            } | ${props.selectedCard && 'shadow-inner'}`}
          >
            <div
              className={`flex flex-col items-end justify-start ${
                props.outputs ? 'p-1' : 'p-4'
              }`}
            >
              {/* <div className={`flex flex-col items-start ${props.template && !props.newForm && 'border-b border-gray-lines pb-2'} `}> */}
              <div className={'flex flex-col items-start\'} '}>
                <div
                  className={`${
                    props.template ? 'flex items-center' : 'flex items-center'
                  }`}
                >
                  {!props.question && props.image && (
                    <div>
                      <img
                        src={props.image}
                        alt="Template icon"
                        className={`w-6 mr-2 ${props.template && 'w-12 mr-5'} ${
                          props.outputs && 'w-11 h-11 ml-1'
                        }`}
                      />
                    </div>
                  )}

                  <div
                    className={`${props.template && 'w-48'} ${
                      props.question && 'w-auto flex items-center'
                    }  w-40`}
                  >
                    {props.question && (
                      <span className={`inline mr-1 text-sm text-main-${Config.PLATFORM} font-medium`}>
                        {props.index + 1}.-
                      </span>
                    )}
                    <p
                      className={`${
                        props.outputs && 'py-4'
                      } text-sm text-left ${
                        props.question
                          ? 'font-regular text-black text-xs'
                          : `font-medium text-main-${Config.PLATFORM}`
                      }`}
                    >
                      {props.text}
                    </p>
                    {props.template && (
                      <p className="text-xxs text-gray leading-3 text-left">
                        {props.description}
                        {/* <img src={Plus} alt="See more" className="inline w-3.5 ml-1" /> */}
                      </p>
                    )}
                  </div>
                </div>
                {props.template && (
                  <div className="flex justify-around text-xxs space-x-4 mt-4 w-full">
                    <div className="flex flex-col">
                      <span>{t('outputs')}</span>
                      <span className={`text-main-${Config.PLATFORM} font-medium`}>
                        {props.outputs}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span>{t('variables')}</span>
                      <span className={`text-main-${Config.PLATFORM} font-medium`}>
                        {props.variables}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span>{t('questions')}</span>
                      <span className={`text-main-${Config.PLATFORM} font-medium`}>
                        {props.questions}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </button>
      )}
    </div>
  )
}
