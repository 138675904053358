import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import InvestorsList from 'components/irm/InvestorsList'
import { Alert } from 'components/ui/molecules/Alert'
import Utils from 'utils/utils'
import Config from 'config';
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'

export const InvestorsMatching = ({ startup }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(true)
  const [investorsCurrentPage, setInvestorsCurrentPage] = useState(0)

  if (!startup) {
    return null
  }

  return (
    <div className="mx-4 my-6">
        {(startup.sector || startup.country_incorporation || startup.project_stage || startup.investment_stage || startup.business_model || startup.valuation || startup.minimum_ticket) && (
            <>
                <Alert
                    bgColor={'bg-white'}
                    style={'info'}
                    text={
                        <div>
                            <p className={`font-medium text-base text-main-${Config.PLATFORM}`}>
                                {t('investors_matching_criteria')}
                            </p>
                            <ul className='my-2'>
                                {startup.sector && <li>
                                    <span className='font-semibold'>{`${t('sector')}: `}</span><span>{t(startup.sector.key)}</span>
                                </li>}
                                {startup.country_incorporation && <li>
                                    <span className='font-semibold'>{`${t('incorporation_country')}: `}</span><span>{t(startup.country_incorporation.key)}</span>
                                </li>}
                                {startup.project_stage && <li>
                                    <span className='font-semibold'>{`${t('project_stage')}: `}</span><span>{t(startup.project_stage.key)}</span>
                                </li>}
                                {startup.investment_stage && <li>
                                    <span className='font-semibold'>{`${t('investment_stage')}: `}</span><span>{t(startup.investment_stage.key)}</span>
                                </li>}
                                {startup.business_model && <li>
                                    <span className='font-semibold'>{`${t('business_model')}: `}</span><span>{t(startup.business_model.key)}</span>
                                </li>}
                                {startup.valuation && <li>
                                    <span className='font-semibold'>{`${t('valuation')}: `}</span><span>{`${startup.valuation} ${t(startup.valuation_unit)} €`}</span>
                                </li>}
                                {startup.minimum_ticket && <li>
                                    <span className='font-semibold'>{`${t('minimum_ticket')}: `}</span><span>{`${startup.minimum_ticket} ${t(startup.minimum_ticket_unit)} €`}</span>
                                </li>}
                                {startup.maximum_ticket && <li>
                                    <span className='font-semibold'>{`${t('maximum_ticket')}: `}</span><span>{`${startup.maximum_ticket} ${t(startup.maximum_ticket_unit)} €`}</span>
                                </li>}
                            </ul>

                        </div>
                    }
                />
                <InvestorsList
                    filters={
                        {
                            sectors: startup.sector ? [startup.sector._id] : null,
                            countries: startup.country_incorporation ? [startup.country_incorporation._id] : null,
                            project_stages: startup.project_stage ? [startup.project_stage._id] : null,
                            investment_stages: startup.investment_stage ? [startup.investment_stage._id] : null,
                            business_models: startup.business_model ? [startup.business_model._id] : null,
                            maximum_valuation: ![null, undefined].includes(startup.valuation) ? startup.valuation : null,
                            maximum_ticket: ![null, undefined].includes(startup.minimum_ticket) ? startup.minimum_ticket : null,
                            maximum_valuation_unit: startup.maximum_valuation_unit || 'k',
                            maximum_ticket_unit: startup.maximum_ticket_unit || 'k',
                            exclusive: false

                        }
                    }
                    loading={loading}
                    setLoading={setLoading}
                    currentPage={investorsCurrentPage}
                    setCurrentPage={setInvestorsCurrentPage}
                    irm={false}
                />
            </>
        )}
        {!startup.sector && !startup.country_incorporation && !startup.project_stage && !startup.investment_stage && !startup.business_model && !startup.valuation && !startup.minimum_ticket && (
            <NoDataInfo title={t('insufficient_data')} textOne={t('insufficient_data_for_investors_matching')} />
        )}
    </div>
  )
}
