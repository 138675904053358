import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import QuestionnairesService from '../../../services/questionnaires-service'
import { useToasts } from 'react-toast-notifications'
import useUser from '../../../hooks/useUser'
import { Loader } from '../../ui/molecules/Loader'
import { FormAnswers } from './forms-form/FormAnswers'
import { usePagination } from '../../../hooks/usePagination'

export const FormResultsSection = ({ questionnaire, answersEvolutions, formStatistics }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { haveFunctionality, user } = useUser()
  const [loading, setLoading] = useState(true)
  const [formAnswers, setFormAnswers] = useState([])

  const {
    setTotalPages,
    totalPages,
    setPage,
    page,
    rowsPerPage,
    setRowsPerPage
  } = usePagination(12)

  const getQuestionnaireAnswers = () => {
    setLoading(true)
    QuestionnairesService.getQuestionnaireAnswers(questionnaire._id, {
      rowsPerPage,
      page,
      sortBy: '-createdAt'
    })
      .then((result) => {
        const { applications, pagination } = result.data
        setFormAnswers(applications)
        setTotalPages(pagination.pages)
      })
      .catch(() => {
        addToast(t('error_getting_answers'), {
          appearance: 'error',
          autoDismiss: true
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (questionnaire) {
      getQuestionnaireAnswers()
    }
  }, [user, questionnaire, page, rowsPerPage])

  return (
    <>
      <FormAnswers
        questions={questionnaire.questions}
        answers={formAnswers}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        totalPages={totalPages}
        setRowsPerPage={setRowsPerPage}
      />
      {loading && <Loader />}
    </>
  )
}
