import { RoutesLinks } from 'components/routes-links'
import { BannerResponsive } from 'components/ui/atoms/BannerResponsive'
import { BottomText } from 'components/ui/atoms/BottomText'
import { Button } from 'components/ui/atoms/Button'
import { CoverBackground } from 'components/ui/atoms/CoverBackground'
import { FormTitle } from 'components/ui/atoms/FormTitle'
import { Loader } from 'components/ui/molecules/Loader'
import { Container } from 'components/ui/objects/Container'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom'
import AuthService from 'services/auth-service'
import Plane from 'styles/images/plane.svg'
import Tick from 'styles/images/tick_blue.svg'
import moment from "moment";
import Config from 'config';
import { PoweredText } from 'components/ui/atoms/PoweredText'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

const RegisterResend = () => {
  const { t } = useTranslation()
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const location = useLocation()

  const registerMode = location.pathname.split('/')[2]
  const history = useHistory()
  const handleRedirect = (url) => history.push(url)
  const queryParams = new URLSearchParams(location.search)

  const email = history.location.state ? history.location.state.email : null
  const [emailSent, setEmailSent] = useState(false)

  const resendCode = async () => {
    try {
      setEmailSent(true)
      setError(null)
      setLoading(true)
      await AuthService.resendActivationCode(email)
    } catch (error) {
      setError(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t('error_occurred')
      )
    } finally {
      setLoading(false)
    }
  }

  if (email) {
    return (
      <Container variant="session">
        <Container>
          <section className="flex flex-col items-center justify-center lg:justify-around | min-h-screen w-full | p-6 sm:pt-0">
            <div className="max-w-xs sm:max-w-lg 2xl:max-w-xl mt-16">
              <BannerResponsive />
              <div className="flex flex-col">
                <>
                  <FormTitle
                    formTitle={t('activate')}
                    formSubtitle={t('activate_subtitle')}
                  />
                  {!error && (
                    <div className="w-full flex justify-center py-6">
                      <img
                        src={Plane}
                        alt="Paper plane"
                        className="w-20 sm:w-auto"
                      />
                    </div>
                  )}
                  {!error && (
                    <div className="text-black text-base text-justify">
                      <p className="py-2">
                        {t('register_code_one')}
                        <span className="font-medium">{email}</span>
                        {t('register_code_two')} {t('activate_wait')}
                      </p>
                      <p className="py-2">{t('register_code_sent')}</p>
                    </div>
                  )}
                  {error && (
                    <div className="text-black text-base">
                      <p className="py-2">{t('send_activation_link_failed')}</p>
                    </div>
                  )}
                  <div className="w-full flex flex-col items-center justify-center my-8">
                    <ButtonMain
                      onClick={resendCode}
                      text={t('resend')}
                    />
                    {emailSent && (
                      <div className="text-gray flex text-sm w-full justify-center -mt-2">
                        <img src={Tick} alt="Tick icon" className="w-3 mr-2" />
                        {t('msg_resend_successfully')}
                      </div>
                    )}
                  </div>
                  <div className="w-full text-center">
                    <Link
                      to={`${RoutesLinks.LOGIN_LINK}`}
                      className={`underline text-main-${Config.PLATFORM} hover:text-link-hover-${Config.PLATFORM}`}
                    >
                      {t('go_to_login')}
                    </Link>
                  </div>
                </>
                {loading && <Loader />}
              </div>
            </div>
            <BottomText text={`${t('copyright')} @${moment().format('YYYY')} ${t('copyright_rights')}`} />
            {Config.PLATFORM !== '000' && <PoweredText />}
          </section>
        </Container>

        <CoverBackground link={true}/>
      </Container>
    )
  } else {
    return (
      <Redirect
        to={{
          pathname: RoutesLinks.LOGIN_LINK
        }}
      />
    )
  }
}

export default RegisterResend
