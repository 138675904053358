import { useTranslation } from 'react-i18next'
import { Input } from 'components/ui/atoms/Input'
import { FaChevronCircleDown, FaMinusCircle, FaTimes } from 'react-icons/all'
import { ReactSortable } from 'react-sortablejs'
import TranslateUtils from 'utils/translate-utils'
import { QuestionsUtils } from 'utils/questions-utils'
import { Switch } from 'components/ui/atoms/Switch'
import useUser from 'hooks/useUser'
import { Question } from 'components/qe-questions/components/Question'
import Select from 'components/ui/atoms/Select'
import ReactTooltip from 'react-tooltip'
import { useEffect, useState } from 'react'
import { ButtonDanger } from 'components/ui/atoms/ButtonDanger'
import Config from 'config'
import { Loader } from 'components/ui/molecules/Loader'

export const FormQuestions = ({
  selectedQuestions,
  setSelectedQuestions,
  lang,
  requiredQuestions,
  setRequiredQuestions,
  filteredQuestions,
  setFilteredQuestions,
  keyQuestions,
  setKeyQuestions,
  questions,
  processQuestions,
  selectedExpandedQuestions,
}) => {
  const { t } = useTranslation()
  const { haveFunctionality, isAdmin } = useUser()
  const keyQuestionsKeys = [
    { key: 'startup_name', type: 'short_answer' },
    { key: 'main_contact_email', type: 'email' },
    { key: 'startup_logo', type: 'file_image' },
    { key: 'brand_statement', type: 'long_answer' },
    { key: 'problem', type: 'long_answer' },
    { key: 'solution', type: 'long_answer' },
    { key: 'market', type: 'long_answer' },
    { key: 'business_description', type: 'long_answer' },
    { key: 'city', type: 'short_answer' },
    { key: 'startup_deck', type: 'file' },
    { key: 'one_pager', type: 'file' },
    { key: 'web', type: 'url' },
    { key: 'linkedin', type: 'url' },
    { key: 'crunchbase', type: 'url' },
    { key: 'facebook', type: 'url' },
    { key: 'twitter', type: 'url' },
    { key: 'instagram', type: 'url' },
    { key: 'youtube', type: 'url' },
    { key: 'tiktok', type: 'url' }
  ]

  const onSort = (evt) => {
    const itemToSort = { ...selectedQuestions[evt.oldIndex] }
    const tmpSelectedQuestions = [...selectedQuestions]

    tmpSelectedQuestions.splice(evt.oldIndex, 1)
    tmpSelectedQuestions.splice(
      evt.newIndex < evt.oldIndex ? evt.newIndex : evt.oldIndex + evt.newIndex,
      0,
      itemToSort
    )

    setSelectedQuestions(tmpSelectedQuestions)
  }

  const onRemoveQuestion = (question) => {
    const tmpSelectedQuestions = [...selectedQuestions]

    tmpSelectedQuestions.splice(
      tmpSelectedQuestions.findIndex((q) => q._id === question._id),
      1
    )

    setSelectedQuestions(tmpSelectedQuestions)
  }

  const onMakeRequired = (question, value) => {
    setRequiredQuestions({
      ...requiredQuestions,
      [question._id]: value
    })
  }

  const onSearch = (e) => {
    const search = e.target.value
    setFilteredQuestions(processQuestions(questions, search))
  }

  const questionIsSelected = (questionId) => {
    return !!selectedQuestions.find((q) => q._id === questionId)
  }

  const onAddQuestion = (question) => {
    setSelectedQuestions([...selectedQuestions, question])
  }

  const onSelectKeyQuestion = (key, questionId) => {
    setKeyQuestions({
      ...keyQuestions,
      [key]: questionId
    })
  }

  const expandOrCollapse = (elementId) => {
    const element = document.getElementById(elementId)
    const icon = document.getElementById(`${elementId}_icon`)
    element.classList.toggle('hidden')
    icon.classList.toggle('rotate-180')
    setTimeout(() => ReactTooltip.rebuild(), 200)
  }

  const getKeyQuestionInitialValue = (key) => {
    const question = selectedExpandedQuestions.find((q) => q._id === keyQuestions[key])
    if (!question) {
      return []
    }

    return [
      {
        id: question._id,
        value: TranslateUtils.getTextByLang(lang, question.title)
      }
    ]
  }

  const getQuestions = () => {
    const questions = Object.keys(filteredQuestions).map((tag, index) => (
      <div key={`filtered_${index}`}>
        <div
          onClick={() => expandOrCollapse(`${tag}_${index}`)}
          className="border-b border-gray-lines font-bold cursor-pointer flex flex-row justify-between hover:bg-gray-100 px-2 hover:rounded-md transition-colors  mt-6"
        >
          <div className="text-xs">
            {tag === 'other_questions' ? t(tag) : filteredQuestions[tag][0].investor ? tag : t(tag)}&nbsp; (
            {filteredQuestions[tag].length})
          </div>
          <div id={`${tag}_${index}_icon`} className="flex justify-center items-center">
            <FaChevronCircleDown data-panel={1} className={`text-main-${Config.PLATFORM} inline-block mt-1`} />
          </div>
        </div>
        <div id={`${tag}_${index}`} className="mt-2 hidden">
          {filteredQuestions[tag].map((question) => (
            <div className='my-2'>
              <Question
                key={question._id}
                question={question}
                showTag={false}
                iconSmall={true}
                openEditInTab={true}
                isSelected={questionIsSelected(question._id)}
                addOrRemoveQuestion={(question) => {
                  if (questionIsSelected(question._id)) {
                    onRemoveQuestion(question)
                  } else {
                    onAddQuestion(question)
                  }
                }}
              />
            </div>
          ))}
        </div>
      </div>
    ))
    return questions
  }

  return (
    <>
      <div className="w-full flex flex-row my-6">
        <div className="w-1/4 flex flex-col pr-4">
          <div className="w-full px-1 text-xs my-4 font-semibold">{t('content')}</div>

          {selectedQuestions.length === 0 ? (
            <div className={`w-full py-36 text-center justify-center flex flex-col text-xs text-main-${Config.PLATFORM} border-main-${Config.PLATFORM} border-dotted border-2 rounded-lg px-4 py-2 font-semibold`}>
              {t('add_first_question_to_form')}
            </div>
          ) : (
            <div className={`w-full text-center justify-center flex flex-col text-xs bg-main-${Config.PLATFORM} text-white rounded-lg px-4 py-2 font-semibold`}>
              {t('sort_questions')}
            </div>
          )}

          {selectedQuestions.length !== 0 && (
            <div className={`border border-main-${Config.PLATFORM} border-dotted border-2 rounded-lg my-2 p-2`}>
              <ReactSortable
                group="grupo1"
                data-id={'selected'}
                setList={() => {}}
                list={selectedQuestions}
                className="flex items-start h-full my-4 flex flex-col"
                animation={200}
                easing="cubic-bezier(0.25, 1, 0.5, 1)"
                onSort={onSort}
              >
                {selectedQuestions.map((q) => (
                  <div key={`selected_${q._id}`} data-id={`selected_${q._id}`} className="w-full cursor-move">
                    <div className="mb-2 bg-white rounded-2xl p-2 h-18 w-full">
                      <div className="flex">
                        <div className="w-8">{QuestionsUtils.getIcon(q.type, true)}</div>
                        <div className="w-full mx-1 px-2 text-xs font-semibold truncate">
                          {TranslateUtils.getTextByLang(lang, q.title)}
                        </div>
                        <div className="w-14 h-7 rounded-xl flex flex-row justify-center items-center">
                          <div className="ml-1">
                            <ButtonDanger
                              onClick={() => onRemoveQuestion(q)}
                              iconComponent={<FaMinusCircle className="inline" />}
                            />
                          </div>
                        </div>
                      </div>
                      {q.type !== 'separator' && <div className="flex justify-start my-2">
                        <Switch
                          size="sm"
                          checked={requiredQuestions[q._id] || false}
                          onChange={(value) => onMakeRequired(q, value)}
                          text={t('is_this_question_required')}
                          textSize={'xs'}
                        />
                      </div>}
                    </div>
                  </div>
                ))}
              </ReactSortable>
            </div>
          )}
        </div>

        <div className={`${isAdmin || haveFunctionality('key_questions') ? 'w-2/4' : 'w-3/4'} border-l border-gray-lines px-4`}>
          <div className="w-full px-1 text-xs my-4 font-semibold">{t('questions')}</div>

          <div className="-mb-5 flex-1 mr-5">
            <Input placeholder={t('search')} onChange={onSearch} />
          </div>
          {getQuestions()}
        </div>

        {(isAdmin || haveFunctionality('key_questions')) && <div className="w-1/4 border-l border-gray-lines pl-4">
          <div className="w-full px-1 text-xs my-4 font-semibold">{t('key_questions')}</div>

          {keyQuestionsKeys.map(({ key, type }) => (
            <Select
              key={key}
              name={key}
              initialValues={getKeyQuestionInitialValue(key)}
              onSelect={(value) => onSelectKeyQuestion(key, value.length ? value[0].id : null)}
              reset={keyQuestions[key] === null}
              setReset={(reset) => reset && onSelectKeyQuestion(key, null)}
              isClearable={false}
              noOptionsText={t('no_options_key_questions', { type: t(type) })}
              items={selectedExpandedQuestions
                .filter((question) => {
                  return type === 'file_image' ? question.type === 'file' && question?.file_accepted_extensions?.includes('image/*') : type !== 'file' ? question.type === type : question.type === 'file_excel' || question.type === 'file_pdf' || question.type === 'file'
                })
                .map((q) => ({
                  id: q._id,
                  value: TranslateUtils.getTextByLang(lang, q.title)
                }))}
              label={
                <>
                  {t(key)}
                  {keyQuestions[key] && (
                    <span
                      data-tip={t('reset')}
                      className={`hover:underline text-main-${Config.PLATFORM} relative -top-[1px]`}
                      onClick={(e) => {
                        e.stopPropagation()
                        onSelectKeyQuestion(key, null)
                      }}
                    >
                      <FaTimes className="cursor-pointer inline-block ml-1" />
                    </span>
                  )}
                  <br />
                  <span className="text-gray">{t(type)}</span>
                </>
              }
            />
          ))}
        </div>}
      </div>
    </>
  )
}
