import React, { useEffect, useState } from 'react'
import { Card } from '../ui/atoms/Card'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import { Pagination } from 'components/ui/atoms/Pagination'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { DealflowService } from 'services/dealflow-service'
import { Loader } from '../ui/molecules/Loader'
import { DealflowSharedListItem } from './DealflowSharedListItem'
import { usePagination } from 'hooks/usePagination'

export const SharedStartups = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [sharedDealflow, setSharedDealflow] = useState([])
  const [loading, setLoading] = useState(false)

  const {
    setTotalPages,
    totalPages,
    setPage,
    page,
    rowsPerPage,
    setRowsPerPage
  } = usePagination(12)

  const getSharedDealflow = () => {
    setLoading(true)
    return DealflowService.getDealflow({
      rowsPerPage,
      page,
      sortBy: '-createdAt',
      pool: 'false',
      shared: 'true'
    }).then(result => {
      const { dealflow, pagination } = result.data
      setSharedDealflow(dealflow)
      setTotalPages(pagination.pages)
    }).catch(() => {
      addToast(t('error_occurred_retrieving_dealflow'), { appearance: 'error', autoDismiss: true })
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    getSharedDealflow()
  }, [page, rowsPerPage])

  return (
    <>
      {(!loading && sharedDealflow.length === 0) && (
        <Card wrapperClassName="mt-6">
          <NoDataInfo
            title={t('shared_startups_not_found')}
            textOne={t('shared_startups_not_found_description')}
          />
        </Card>
      )}

      {(!loading && sharedDealflow.length > 0) && (
        <div className="mt-6">
          <div className="grid grid-cols-1 lg:grid-cols-2  2xl:grid-cols-3 gap-4">
            {sharedDealflow.map(dealflow => (
              <DealflowSharedListItem
                key={dealflow._id}
                dealflow={dealflow}
                onManage={() => getSharedDealflow()}
              />
            ))}
          </div>

          <Pagination
            paginateOptions={[12, 24, 36, 48, 60]}
            currentPage={page}
            setCurrentPage={(page) => setPage(page)}
            perPage={rowsPerPage}
            pages={totalPages}
            setPerPage={(value) => setRowsPerPage(value[0].id)}
          />
        </div>
      )}
      {loading && <Loader />}
    </>
  )
}
