import axios from 'axios/index'
import Config from '../config'

const KbotsService = {
  getBots: async (params) => {
    const query = new URLSearchParams(params)
    return axios.get(`${Config.SUPPORT_API_URL}kbots/bots?${query}`)
  },

  checkBots: async () => {
    return axios.get(`${Config.SUPPORT_API_URL}kbots/bots/check`)
  },

  getBot: async (botId) => {
    return axios.get(`${Config.SUPPORT_API_URL}kbots/bots/${botId}`)
  },

  registerBot: async (data) => {
    return axios.post(`${Config.SUPPORT_API_URL}kbots/bots`, data)
  },

  updateBot: async (botId, data) => {
    return axios.patch(`${Config.SUPPORT_API_URL}kbots/bots/${botId}`, data)
  },

  deleteBot: async (botId) => {
    return axios.delete(`${Config.SUPPORT_API_URL}kbots/bots/${botId}`)
  },

  importDealflow: async (data) => {
    return axios.post(`${Config.SUPPORT_API_URL}kbots/dealflow/import`, data)
  },

  getDealflow: async (params) => {
    const query = new URLSearchParams(params)
    return axios.get(`${Config.SUPPORT_API_URL}kbots/dealflow?${query}`)
  }
}
export default KbotsService
