/**
 *
 * @param content {string}
 * @param img {string}
 * @returns {JSX.Element}
 * @constructor
 */
export const TourStep = ({ content, img }) => {
  return (
    <div className="border-b border-gray-lines pb-2 -mt-3">
      <div className="flex flex-row items-start">
        <div className={`flex flex-col justify-start text-left ${img ? 'mb-6' : ''}`}>
          {content && <p className="text-secondary">{content}</p>}
        </div>
      </div>

      {img && (<img src={img} alt="tour image" className="w-full" />)}
    </div>
  )
}
