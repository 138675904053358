import { useTranslation } from 'react-i18next'
import { SidePanel } from 'components/ui/objects/SidePanel'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import Config from 'config'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import Select from 'components/ui/atoms/Select'
import RejectionReasons from 'assets/json/rejection-reasons.json'
import { TextArea } from 'components/ui/atoms/TextArea'
import { CurrencyInput } from 'components/ui/atoms/CurrencyInput'
import { Input } from 'components/ui/atoms/Input'
import FormUtils from 'utils/form-utils'
import moment from 'moment'
import { Switch } from 'components/ui/atoms/Switch'
import { useToasts } from 'react-toast-notifications'
import { initial } from 'lodash'
import { isEmpty } from 'lodash'
import { FaInfoCircle } from 'react-icons/fa'
import ReactTooltip from 'react-tooltip'

/**
 * @param onClose
 * @returns {JSX.Element}
 * @constructor
 */
const StageSection = ({ onClose, onSubmit, initialValues, evaluationProcesses, judges, forms }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const [rejectionEmailAutomation, setRejectionEmailAutomation] = useState(false);
  const [temporarilyDiscardedEmailAutomation, setTemporarilyDiscardedEmailAutomation] = useState(false);
  const [advanceEmailAutomation, setAdvanceEmailAutomation] = useState(false);
  const [endReachedEmailAutomation, setEndReachedEmailAutomation] = useState(false);
  const [addStartupToJudges, setAddStartupToJudges] = useState(false)
  const [assignFormsToStartups, setAssignFormsToStartups] = useState(false)

  const [resetJudges, setResetJudges] = useState(false)
  const [resetForms, setResetForms] = useState(false)

  const [updatingSwitch, setUpdatingSwitch] = useState(false)

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  )

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const StageSchema = Yup.object().shape({
    name: Yup.string().required(t('required_field')),
    evaluation_process: Yup.string().nullable(),
    judges: Yup.array().required(t('required_field')),
    forms: Yup.array()
  })

  const { register, errors, setValue, trigger, getValues, watch } = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    reValidateMode: 'all',
    resolver: yupResolver(StageSchema, { abortEarly: false })
  })

  useEffect(() => {
    register('evaluation_process')
    register('judges')
    register('forms')
  }, [register])

  useEffect(() => {
    if (!isEmpty(initialValues)) {
        setValue('name', initialValues.name)
        if (initialValues.deleteable) {
          setRejectionEmailAutomation(initialValues.rejection_email_automation)
          setTemporarilyDiscardedEmailAutomation(initialValues.temporarily_discarded_email_automation)
          setAdvanceEmailAutomation(initialValues.advance_email_automation)
          setEndReachedEmailAutomation(initialValues.end_reached_email_automation)
        }
        if (initialValues?.evaluation_process) {
          onSelectEvaluationProcess(initialValues.evaluation_process, true)
        } else {
          onSelectEvaluationProcess(null, true)
        }

        if (initialValues?.judges?.length) {
          onSelectJudges(initialValues.judges, true)
        } else {
          onSelectJudges([], true)
        }

        if (initialValues?.forms?.length) {
          onSelectForms(initialValues.forms, true)
        } else {
          onSelectForms([], true)
        }
    } else {
      onSelectJudges([], true)
      onSelectEvaluationProcess(null, true)
    }
    trigger()
  }, [initialValues])


  const onSelectJudges = (judgesArray, initial = false) => {
    if (judgesArray.length) {
      if (initial && initialValues?.judges?.length) {
        setValue('judges', judgesArray)
      } else {
        setValue('judges', judgesArray.filter((s) => s.id).map((s) => s.id))
      }
    } else {
      setValue('judges', [])
    }
    trigger(['judges'])
  }

  const onSelectEvaluationProcess = (evaluationProcess, initial = false) => {
    if (evaluationProcess) {
      if (initial && initialValues?.evaluation_process) {
        setAddStartupToJudges(true)
        setValue('evaluation_process', initialValues.evaluation_process)
      } else {
        setResetJudges(true)
        setAddStartupToJudges(!!evaluationProcess)
        setValue('evaluation_process', evaluationProcess)
        setValue('judges', [])
      }
    } else {
      setValue('evaluation_process', null)
      setAddStartupToJudges(false)
      setResetJudges(true)
      setValue('judges', [])
    }
    trigger(['evaluation_process', 'judges'])
  }

  const onSelectForms = (formsArray, initial = false) => {
    if (formsArray.length) {
      if (initial && initialValues?.forms?.length) {
        setValue('forms', formsArray)
      } else {
        setValue('forms', formsArray.filter((s) => s.id).map((s) => s.id))
      }
    } else {
      setValue('forms', [])
    }
    trigger(['forms'])
  }

  return (
    <>
      <SidePanel
        width={'1/4'}
        onClose={onClose}
        title={t(isEmpty(initialValues) ? 'add_stage' : 'update_stage')}
      >
        <div className="flex flex-col w-full">
            <div className='w-full'>
            <Input
              reference={register}
              name="name"
              placeholder={t('name')}
              label={t('name')}
              type="text"
              error={errors.name}
            />
            </div>
            {Config.PLATFORM === '000' && (!initialValues || (initialValues && (!initialValues.hasOwnProperty('deleteable') || initialValues.deleteable === null || initialValues.deleteable))) && (
              <div>
                <div className={`w-full px-1 text-sm mb-4 font-semibold border-b border-main-${Config.PLATFORM} pt-4 text-main-${Config.PLATFORM}`}>{t('emails_automation')}</div>
                <div className="w-full px-1 text-xs mt-2 mb-2 font-semibold">
                  {t('would_you_like_to_enable_rejection_email_automation')}
                  <FaInfoCircle
                    data-tip={t('rejection_email_automation_enabled_tooltip')}
                    className='inline w-3 mb-1 ml-2 text-gray'
                  />
                </div>
                <div className='mb-2'>
                    <Switch
                    checked={rejectionEmailAutomation}
                    onChange={async (checked) => {
                      if (!updatingSwitch) {
                        setUpdatingSwitch(true)
                        await delay(100)
                        setTemporarilyDiscardedEmailAutomation(false)
                        setAdvanceEmailAutomation(false)
                        setEndReachedEmailAutomation(false)
                        setRejectionEmailAutomation(checked)
                        setUpdatingSwitch(false)
                      }
                    }}
                    text={rejectionEmailAutomation ? t('yes') : t('no')}
                    />
                </div>
                <div className="w-full px-1 text-xs mt-4 mb-2 font-semibold">
                  {t('would_you_like_to_enable_temporarily_discarded_email_automation')}
                  <FaInfoCircle
                    data-tip={t('temporarily_discarded_email_automation_enabled_tooltip')}
                    className='inline w-3 mb-1 ml-2 text-gray'
                  />
                </div>
                <div className='mb-2'>
                    <Switch
                    checked={temporarilyDiscardedEmailAutomation}
                    onChange={async (checked) => {
                      if (!updatingSwitch) {
                        await delay(100)
                        setUpdatingSwitch(true)
                        setAdvanceEmailAutomation(false)
                        setEndReachedEmailAutomation(false)
                        setRejectionEmailAutomation(false)
                        setTemporarilyDiscardedEmailAutomation(checked)
                        setUpdatingSwitch(false)
                      }
                    }}
                    text={temporarilyDiscardedEmailAutomation ? t('yes') : t('no')}
                    />
                </div>
                <div className="w-full px-1 text-xs mt-4 mb-2 font-semibold">
                  {t('would_you_like_to_enable_advance_email_automation')}
                  <FaInfoCircle
                    data-tip={t('advance_email_automation_enabled_tooltip')}
                    className='inline w-3 mb-1 ml-2 text-gray'
                  />
                </div>
                <div className='mb-2'>
                    <Switch
                    checked={advanceEmailAutomation}
                    onChange={async (checked) => {
                      if (!updatingSwitch) {
                        await delay(100)
                        setUpdatingSwitch(true)
                        setTemporarilyDiscardedEmailAutomation(false)
                        setEndReachedEmailAutomation(false)
                        setRejectionEmailAutomation(false)
                        setAdvanceEmailAutomation(checked)
                        setUpdatingSwitch(false)
                      }
                    }}
                    text={advanceEmailAutomation ? t('yes') : t('no')}
                    />
                </div>
                <div className="w-full px-1 text-xs mt-4 mb-2 font-semibold">
                  {t('would_you_like_to_enable_end_reached_email_automation')}
                  <FaInfoCircle
                    data-tip={t('end_reached_email_utomation_enabled_tooltip')}
                    className='inline w-3 mb-1 ml-2 text-gray'
                  />
                </div>
                <div className='mb-2'>
                    <Switch
                    checked={endReachedEmailAutomation}
                    onChange={async (checked) => {
                      if (!updatingSwitch) {
                        await delay(100)
                        setUpdatingSwitch(true)
                        setTemporarilyDiscardedEmailAutomation(false)
                        setAdvanceEmailAutomation(false)
                        setRejectionEmailAutomation(false)
                        setEndReachedEmailAutomation(checked)
                        setUpdatingSwitch(false)
                      }
                    }}
                    text={endReachedEmailAutomation ? t('yes') : t('no')}
                    />
                </div>
              </div>
            )}
            {/*<div className={`w-full px-1 text-sm mb-4 font-semibold border-b border-main-${Config.PLATFORM} pt-4 text-main-${Config.PLATFORM}`}>{t('forms')}</div>
            <div className="w-full px-1 text-xs mb-2 font-semibold">{t('would_you_like_to_assign_forms_automatically')}</div>
            <div className='mb-2'>
                <Switch
                  checked={assignFormsToStartups}
                  onChange={(checked) => {
                      setAssignFormsToStartups(checked)
                      if (!checked) {
                        onSelectForms([])
                      }
                  }}
                  text={assignFormsToStartups ? t('yes') : t('no')}
                />
            </div>
            {assignFormsToStartups && (
              <div className='mt-2'>
              <Select
                reset={resetForms}
                setReset={setResetForms}
                name="forms"
                label={t('select_forms')}
                multiSelect={true}
                required={false}
                placeholder={t('select_forms')}
                initialValues={watch('forms')}
                onSelect={(value) => onSelectForms(value)}
                items={forms}
                isClearable={false}
              />
            </div>
            )}*/}
            <div className={`w-full px-1 text-sm mb-4 font-semibold border-b border-main-${Config.PLATFORM} pt-4 text-main-${Config.PLATFORM}`}>{t('evaluations')}</div>
            <div className="w-full px-1 text-xs mb-2 font-semibold">{t('would_you_like_to_add_startups_automatically_to_a_judge')}</div>
            <div className='mb-2'>
                <Switch
                  checked={addStartupToJudges}
                  onChange={(checked) => {
                      setAddStartupToJudges(checked)
                      if (!checked) {
                        onSelectEvaluationProcess(null)
                      }
                  }}
                  text={addStartupToJudges ? t('yes') : t('no')}
                />
            </div>
            {addStartupToJudges && (
              <>
                <div className='my-2'>
                  <Select
                    name="evaluation_process"
                    label={t('select_evaluation_processes')}
                    multiSelect={false}
                    required={false}
                    placeholder={t('select_evaluation_processes')}
                    initialValues={evaluationProcesses.filter(item => watch('evaluation_process') === item.id)}
                    onSelect={(value) => onSelectEvaluationProcess(value[0].id)}
                    items={evaluationProcesses}
                    isClearable={false}
                  />
                </div>
                <div>
                  <Select
                    reset={resetJudges}
                    setReset={setResetJudges}
                    name="judges"
                    label={t('select_judges')}
                    multiSelect={true}
                    required={false}
                    placeholder={t('select_judges')}
                    initialValues={judges.filter((judge) => judge.event === watch('evaluation_process') && watch('judges').includes(judge.id))}
                    onSelect={(value) => onSelectJudges(value)}
                    items={judges.filter((judge) => judge.event === watch('evaluation_process'))}
                    isClearable={false}
                  />
                </div>
              </>
            )}
            <div className={`flex justify-end mt-2 pt-2 border-t border-separator-${Config.PLATFORM}`}>
            <ButtonMain
               onClick={async () => {
                const validation = await trigger()
                if (validation) {
                  if (addStartupToJudges && !getValues().evaluation_process) {
                    addToast(t('you_should_select_some_evaluation_process'), { appearance: 'error', autoDismiss: true })
                  } else if (addStartupToJudges && !getValues().judges.length) {
                    addToast(t('you_should_select_some_judge'), { appearance: 'error', autoDismiss: true })
                  } else if (assignFormsToStartups && !getValues().forms.length ) {
                    addToast(t('you_should_select_some_form'), { appearance: 'error', autoDismiss: true })
                  } else {
                    onSubmit({
                      ...getValues(),
                      rejection_email_automation: rejectionEmailAutomation,
                      temporarily_discarded_email_automation: temporarilyDiscardedEmailAutomation,
                      advance_email_automation: advanceEmailAutomation,
                      end_reached_email_automation: endReachedEmailAutomation
                    })
                  }
                } else {
                  addToast(t('form_field_error'), { appearance: 'error', autoDismiss: true })
              }
              }}
              text={
                isEmpty(initialValues) ? t('add_stage') : t('update_stage')
              }
            />
            </div>
        </div>
      </SidePanel>
    </>
  )
}

export default StageSection
