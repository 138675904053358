import React from 'react'
import { useTranslation } from 'react-i18next'
import Config from 'config'

export const DemoAccountBanner = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className={`fixed top-0 left-0 border-t-4 border-main-${Config.PLATFORM} b-2 z-50 w-full`}/>
      <div className="fixed top-0 left-0 flex flex-col items-center justify-center w-full ml-20 z-50">
        <div className={`text-center text-sm bg-main-${Config.PLATFORM} rounded-br-2xl rounded-bl-2xl text-white font-bold px-3 py-2`}>
          {t('demo_account')}
        </div>
      </div>
    </>
  )
}
