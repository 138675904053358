import ScrollContainer from 'react-indiana-drag-scroll'
import { RoutesLinks } from '../routes-links'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import Config from 'config';
import Utils from 'utils/utils'
import moment from 'moment'

export const RemindersBar = ({ dealflow }) => {
  const { t } = useTranslation()
  const remindersPanel = useRef(null)
  const history = useHistory()
  const [remindersPanelWidth, setRemindersPanelWidth] = useState('100%')

  const calculateRemindersWidth = () => {
    if (!remindersPanel || !remindersPanel.current) {
      return 0
    }

    if (!dealflow.reminders || !dealflow.reminders.length) {
      return '100%'
    }

    let totalWidth = 0
    const tags = remindersPanel.current.querySelectorAll('span')

    for (let i = 0; i < tags.length; i++) {
      totalWidth += tags[i].offsetWidth + 10
    }

    return totalWidth
  }

  useEffect(() => {
    setRemindersPanelWidth(calculateRemindersWidth())
  }, [remindersPanel])

  return (
    <div className="relative">
      {(dealflow.reminders && dealflow.reminders.length > 0) && (
        <div className="h-full top-0 right-0 w-5 absolute bg-gradient-to-l from-white to-transparent" />
      )}

      <ScrollContainer
        className="h-8 max-h-8 mt-0.5 cursor-grab active:cursor-grabbing"
        horizontal={true}
        hideScrollbars={true}
      >
        <div
          ref={remindersPanel}
          style={{ width: remindersPanelWidth }}
        >
          {(dealflow.reminders && dealflow.reminders.length > 0) && dealflow.reminders.map((reminder, i) => {
            return (
                <span
                  key={`${reminder._id}-${i}`}
                  className={`inline-block mr-2 mt-1 font-semibold text-xxs px-2 py-0.5 rounded-md border text-main-${Config.PLATFORM} ${moment(reminder.date).isSame(moment(), 'day') ? 'border-red' : 'border-gray-lines'}`}
                >
                    {Utils.getReminderIcon(reminder, t)} {reminder.reminder} {`(${moment(reminder.date).format('YYYY-MM-DD')})`}
                </span>
              )
          })}

          {(!dealflow.reminders || dealflow.reminders.length === 0) && (
            <span className="inline-block mr-3 mt-1 font-semibold text-xxs px-2 py-0.5 text-gray-300">
              {t('reminders_not_found')}
            </span>
          )}
        </div>
      </ScrollContainer>
    </div>
  )
}
