import { useTranslation } from 'react-i18next'
import { Button } from 'components/ui/atoms/Button'
import { Input } from 'components/ui/atoms/Input'
import { FaTrash } from 'react-icons/all'
import { RichEditor } from 'components/ui/objects/RichEditor'
import { InputColor } from 'components/ui/atoms/InputColor'
import InputFile from 'components/ui/atoms/input-files'
import Config from 'config'

export const FormAppearance = ({
  formBanner,
  onSelectImage,
  formTitle,
  setFormTitle,
  formDescription,
  setFormDescription,
  formFavicon,
  formBackgroundColor,
  setFormBackgroundColor,
  formFontColor,
  setFormFontColor,
  formButtonColor,
  setFormButtonColor,
  formButtonFontColor,
  setFormButtonFontColor
}) => {
  const { t } = useTranslation()

  const setDefaultColor = () => {
    setFormBackgroundColor('#FFFFFF')
    setFormFontColor('#000000')
    setFormButtonColor('#4D70B3')
    setFormButtonFontColor('#FFFFFF')
  }

  return (
    <div className='my-6'>
      {/*{formBanner ? (
        <>
          <div className="text-xs font-medium text-black">
            {t('application_banner')}
          </div>
          <p className="text-xs">
            {t('application_banner_preview_description')}
          </p>
          <div className="my-4">
            <img
              src={formBanner}
              alt="Banner preview"
              className="w-[640px] max-w-full rounded-lg"
            />
            <Button
              type="button"
              iconComponent={<FaTrash className="inline-block mr-2" />}
              text={t('remove')}
              className="w-auto mt-4 bg-red text-white"
              verticalMargin="0"
              textSize="xs"
              onClick={() => onSelectImage('form_banner', null)}
            />
          </div>
        </>
      ) : (
        <InputFile
          name="application_banner"
          label={t('application_banner')}
          setSelectedFile={(file) =>
            onSelectImage('form_banner', file)
          }
          accept="image/*"
        />
        )}*/}
      <div className='mt-6'>
        <Input
          type={'text'}
          name="application_title"
          label={t('application_title')}
          placeholder={t('application_title_description')}
          value={formTitle}
          onChange={(e) => {
            setFormTitle(e.target.value)
          }}
        />
      </div>
      {/*<RichEditor
        name="application_description"
        label={t('application_description')}
        placeholder={t('application_description_description')}
        className="mb-4"
        value={formDescription}
        toolbarClassName="application_description"
        onChange={value => {
          setFormDescription(value)
        }}
      />*/}
      <div className="grid grid-cols-2 gap-4 my-6">
        <div>
          <InputColor
            value={formBackgroundColor}
            id="application_background_color"
            name="application_background_color"
            label={t('application_background_color')}
            onChange={(color) => setFormBackgroundColor(color)}
          />
        </div>
        <div>
          <InputColor
            value={formFontColor}
            id="application_font_color"
            name="application_font_color"
            label={t('application_font_color')}
            onChange={(color) => setFormFontColor(color)}
          />
        </div>
        <div>
          <InputColor
            value={formButtonFontColor}
            id="application_button_font_color"
            name="application_button_font_color"
            label={t('application_button_font_color')}
            onChange={(color) => setFormButtonFontColor(color)}
          />
        </div>
        <div>
          <InputColor
            value={formButtonColor}
            id="application_button_color"
            name="application_button_color"
            label={t('application_button_color')}
            onChange={(color) => setFormButtonColor(color)}
          />
        </div>

        <div>
          {formFavicon
            ? (
              <>
                <div className="text-xs font-medium text-black">
                  {t('application_favicon')}
                </div>
                <p className="text-xs">
                  {t('application_favicon_description')}
                </p>
                <div className="mt-2 flex">
                  <img
                    src={formFavicon}
                    alt="Favicon preview"
                    className="w-[24px] h-[24px] inline-block mr-4"
                  />
                  <Button
                    type="button"
                    iconComponent={
                      <FaTrash className="inline-block mr-2" />
                    }
                    text={t('remove')}
                    width='auto'
                    bgColor='red'
                    className="text-white max-h-[24px]"
                    verticalMargin="0"
                    vertical="0"
                    textSize="xs"
                    onClick={() =>
                      onSelectImage('form_favicon', null)
                    }
                  />
                </div>
              </>
              )
            : (
              <InputFile
                name="application_favicon"
                label={t('application_favicon')}
                setSelectedFile={(file) =>
                  onSelectImage('form_favicon', file)
                }
                accept="image/*"
                height="24px"
                padding="18px"
              />
              )}
        </div>
        <div>
          <button onClick={setDefaultColor} className={`text-main-${Config.PLATFORM} background-transparent font-bold px-3 py-1 text-xs outline-none focus:outline-none mr-1 mb-1" type="button`}>
            {t('set_default_colors')}
          </button>
        </div>
    </div>
  </div>
  )
}
