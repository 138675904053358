import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import './i18n'
import App from './App'
// import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from 'react-router-dom'
import { hotjar } from 'react-hotjar'
import ReduxStore from './redux/store'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

const { store, persistor } = ReduxStore
library.add(fab, fas)
hotjar.initialize(2239795, 6)

Sentry.init({
  dsn:
    'https://478209701fe746a8ad1006a09694e029@o474089.ingest.sentry.io/5509840',
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0
})

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
