import axios from 'axios/index'
import Config from '../config'

export const DealflowService = {

  checkSharedDealflowAccess: async (sharedDealflowAccessToken) => {
    return axios.post(`${Config.PROJECTS_API_BASE_URL}shared/dealflow/check-access`, { access_token: sharedDealflowAccessToken })
  },

  getSharedDealflowExternal: async (sharedDealflowAccessToken) => {
    return axios.get(`${Config.PROJECTS_API_BASE_URL}shared/dealflow/external?access_token=${sharedDealflowAccessToken}`)
  },

  getSharedDealExternal: async (sharedDealId, sharedDealflowAccessToken) => {
    return axios.get(`${Config.PROJECTS_API_BASE_URL}shared/dealflow/external/${sharedDealId}?access_token=${sharedDealflowAccessToken}`)
  },

  getDealflow: async (query) => {
    return axios.post(`${Config.PROJECTS_API_URL}dealflow/list`, query)
  },

  getProjectConnections: async (query) => {
    const params = new URLSearchParams(query)
    return axios.get(`${Config.PROJECTS_API_URL}dealflow/connections?${params}`)
  },

  getDeal: async (dealId, isShared = false) => {
    return axios.get(`${Config.PROJECTS_API_URL}dealflow/${dealId}`, isShared ? {
      headers: { 'X-Shared': 'true' }
    } : {})
  },

  updateDealTags: async (dealId, tags) => {
    return axios.put(`${Config.PROJECTS_API_URL}dealflow/${dealId}/tags`, { tags })
  },

  rejectDeal: async (dealId, data) => {
    return axios.post(`${Config.PROJECTS_API_URL}dealflow/${dealId}/reject`, data)
  },

  markAsFailed: async (dealId, data) => {
    return axios.post(`${Config.PROJECTS_API_URL}dealflow/${dealId}/failure`, data)
  },

  markAsInvested: async (dealId) => {
    return axios.post(`${Config.PROJECTS_API_URL}dealflow/${dealId}/invested`)
  },

  markAsInteresting: async (dealId) => {
    return axios.post(`${Config.PROJECTS_API_URL}dealflow/${dealId}/interesting`)
  },

  markAsCommitted: async (dealId, data) => {
    return axios.post(`${Config.PROJECTS_API_URL}dealflow/${dealId}/committed`, data)
  },

  registerInvestment: async (dealId, data) => {
    return axios.post(`${Config.PROJECTS_API_URL}dealflow/${dealId}/investments`, data)
  },

  getInvestments: async (dealId) => {
    return axios.get(`${Config.PROJECTS_API_URL}dealflow/${dealId}/investments`)
  },

  registerExit: async (dealId, data) => {
    return axios.post(`${Config.PROJECTS_API_URL}dealflow/${dealId}/register-exit`, data)
  },

  deleteDeal: async (dealId, data) => {
    return axios.post(`${Config.PROJECTS_API_URL}dealflow/${dealId}/delete`, data)
  },

  moveSharedDealflowToPool: async (dealflowId) => {
    return axios.post(`${Config.PROJECTS_API_URL}dealflow/shared/${dealflowId}/accept`, {})
  },

  addProjectToPool: async (projectId) => {
    return axios.post(`${Config.PROJECTS_API_URL}dealflow/project/${projectId}/connect`, {})
  },

  rejectSharedDealflow: async (dealflowId) => {
    return axios.post(`${Config.PROJECTS_API_URL}dealflow/shared/${dealflowId}/reject`, {})
  },

  acceptProjectConnection: async (connectionId) => {
    return axios.post(`${Config.PROJECTS_API_URL}dealflow/connection/${connectionId}/accept`, {})
  },

  rejectProjectConnection: async (connectionId) => {
    return axios.post(`${Config.PROJECTS_API_URL}dealflow/connection/${connectionId}/reject`, {})
  },

  requestProfileVisibility: async (dealflowId, section) => {
    return axios.post(`${Config.PROJECTS_API_URL}dealflow/${dealflowId}/request-visibility`, { section })
  },

  addEventStartupToPool: async (startupId, eventId) => {
    return axios.post(`${Config.PROJECTS_API_URL}dealflow/event/${eventId}/startup/${startupId}/connect`, {})
  },

  saveManagedStartup: async (application, questionnaireId) => {
    return axios.post(`${Config.PROJECTS_API_URL}dealflow/managed-startup`, {
      application,
      questionnaireId
    })
  },

  addDeal: async (data) => {
    return axios.post(`${Config.PROJECTS_API_URL}dealflow`, data)
  },

  updateDeal: async (dealId, data) => {
    return axios.put(`${Config.PROJECTS_API_URL}dealflow/${dealId}`, data)
  },

  getDealByStartupId: async (startupId) => {
    return axios.get(`${Config.PROJECTS_API_URL}dealflow/by-startup-id/${startupId}`)
  },

  getStats: async () => {
    return axios.get(`${Config.PROJECTS_API_URL}dealflow/stats`)
  },

  getRejectedStats: async () => {
    return axios.get(`${Config.PROJECTS_API_URL}dealflow/rejected-stats`)
  },

  getFailedStats: async () => {
    return axios.get(`${Config.PROJECTS_API_URL}dealflow/failed-stats`)
  },

  getInvestmentStats: async () => {
    return axios.get(`${Config.PROJECTS_API_URL}dealflow/investment-stats`)
  },

  getInvestmentDistributionStats: async () => {
    return axios.get(`${Config.PROJECTS_API_URL}dealflow/investment-distribution-stats`)
  },

  updateMember: async (dealId, data, memberId) => {
    return axios.patch(
      `${Config.PROJECTS_API_URL}dealflow/${dealId}/members/${memberId}`,
      data
    )
  },

  addMember: async (dealId, data) => {
    return axios.post(
      `${Config.PROJECTS_API_URL}dealflow/${dealId}/members`,
      data
    )
  },

  deleteMember: async (dealId, memberId) => {
    return axios.delete(
      `${Config.PROJECTS_API_URL}dealflow/${dealId}/members/${memberId}`
    )
  },

  updateShareholder: async (dealId, data, shareholderId) => {
    return axios.patch(
      `${Config.PROJECTS_API_URL}dealflow/${dealId}/shareholders/${shareholderId}`,
      data
    )
  },

  addShareholder: async (dealId, data) => {
    return axios.post(
      `${Config.PROJECTS_API_URL}dealflow/${dealId}/shareholders`,
      data
    )
  },

  deleteShareholder: async (dealId, shareholderId) => {
    return axios.delete(
      `${Config.PROJECTS_API_URL}dealflow/${dealId}/shareholders/${shareholderId}`
    )
  },

  updateCompetitor: async (dealId, data, competitorId) => {
    return axios.patch(
      `${Config.PROJECTS_API_URL}dealflow/${dealId}/competitors/${competitorId}`,
      data
    )
  },

  addCompetitor: async (dealId, data) => {
    return axios.post(
      `${Config.PROJECTS_API_URL}dealflow/${dealId}/competitors`,
      data
    )
  },

  deleteCompetitor: async (dealId, competitorId) => {
    return axios.delete(
      `${Config.PROJECTS_API_URL}dealflow/${dealId}/competitors/${competitorId}`
    )
  },

  updateInvestment: async (dealId, data, investmentId) => {
    return axios.patch(
      `${Config.PROJECTS_API_URL}dealflow/${dealId}/investments/${investmentId}`,
      data
    )
  },

  deleteInvestment: async (dealId, investmentId) => {
    return axios.delete(
      `${Config.PROJECTS_API_URL}dealflow/${dealId}/investments/${investmentId}`
    )
  }
}
