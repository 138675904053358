import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { Card } from 'components/ui/atoms/Card'
import useUser from 'hooks/useUser'
import { Loader } from 'components/ui/molecules/Loader'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import FormUtils from 'utils/form-utils'
import Avatar from 'react-avatar'
import { Button } from 'components/ui/atoms/Button'
import { FaTrash } from 'react-icons/all'
import InputFile from 'components/ui/atoms/input-files'
import { Input } from 'components/ui/atoms/Input'
import { TextArea } from 'components/ui/atoms/TextArea'
import CompanyService from 'services/company-service'
import Config from 'config';
import { ButtonDanger } from 'components/ui/atoms/ButtonDanger'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

export function GeneralInformation() {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { user, reloadUserInfo } = useUser()
  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    trigger,
    errors,
    setValue,
    getValues
  } = useForm({
    resolver: yupResolver(Yup.object().shape({
      company_name: Yup.string().required(t('required_field')),
      contact_person: Yup.string().nullable(),
      contact_email: Yup.string().nullable(),
      contact_phone_number: Yup.string().nullable(),
      company_description: Yup.string().nullable(),
      logo: Yup.string().nullable()
    }), { abortEarly: false }),
    criteriaMode: 'all',
    reValidateMode: 'all',
    mode: 'onChange'
  })

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config)
    trigger(name)
  }

  const onSelectLogo = async file => {
    const fileSizeMb = (file?.size || 0) / 1024 / 1024

    if (fileSizeMb > 5) {
      addToast(t('error_file_size', { max_size: 5 }), {
        appearance: 'error',
        autoDismiss: true
      })
      return
    }

    setLoading(true)

    _setValue(
      'logo',
      file ? await FormUtils.fileToBase64(file) : null,
      { shouldValidate: true }
    )

    setTimeout(() => setLoading(false), 500)
  }

  const updateCompanyInfo = (values) => {
    setLoading(true)
    CompanyService.updateCompanyInfo(values)
      .then(() => reloadUserInfo())
      .then(() => {
        addToast(t('company_info_updated_successfully'), {
          appearance: 'success',
          autoDismiss: true
        })
      })
      .catch(error => {
        addToast(
          error.response && error.response.data.msg_key
            ? t(error.response.data.msg_key)
            : t('error_occurred_updating_company_info'),
          {
            appearance: 'error',
            autoDismiss: true
          }
        )
      }).finally(() => {
        setLoading(false)
      })
  }

  const onInvalid = () => {
    addToast(t('form_field_error'), { appearance: 'error', autoDismiss: true })
  }

  useEffect(() => {
    register('logo')
  }, [register])

  useEffect(() => {
    if (user) {
      _setValue('company_name', user.investor ? user.investor.company_name : user.professional.company_name)
      _setValue('contact_email', user.investor ? user.investor.contact_email : user.professional.contact_email)
      _setValue('contact_person', user.investor ? user.investor.contact_person : user.professional.contact_person)
      _setValue('contact_phone_number', user.investor ? user.investor.contact_phone_number : user.professional.contact_phone_number)
      _setValue('company_description', user.investor ? user.investor.company_description : user.professional.company_description)
      _setValue('logo', user.investor ? user.investor.logo : user.professional.logo, { shouldValidate: true })
    }
  }, [user])

  return (
    <>
      <Card>
        <div className="flex flex-row">
            <div className="w-1/3 lg:w-1/6">
              {getValues().logo ? (
                <>
                  <div className="text-xs font-medium text-black">
                    {t('company_logo')}
                  </div>
                  <div className="mt-2 flex flex-col justify-center w-32">
                    <Avatar
                      src={getValues().logo}
                      size="100"
                      round={false}
                      color="#e0e6f2"
                      fgColor="#4d70b3"
                      className="mx-auto my-0 object-contain"
                    />
                    <ButtonDanger
                      type="button"
                      iconComponent={<FaTrash className="inline-block mr-2" />}
                      text={t('remove')}
                      onClick={() => onSelectLogo(null)}
                    />
                  </div>
                </>
              ) : (
                <div className="w-40">
                  <InputFile
                    label={t('company_logo_label')}
                    placeholder={t('company_logo_placeholder')}
                    error={errors.logo}
                    setSelectedFile={(file) => onSelectLogo(file)}
                    accept="image/*"
                    height="120px"
                    padding="18px"
                  />
                </div>
              )}
            </div>
            <div className="w-2/3 lg:w-5/6 flex items-center mt-4">
              <form
                className="flex flex-col w-full"
                onSubmit={handleSubmit(updateCompanyInfo, onInvalid)}
              >
                <div className="grid grid-cols-2">
                  <Input
                    reference={register}
                    name="company_name"
                    placeholder={t('company_name_placeholder')}
                    label={t('company_name_label')}
                    required={true}
                    error={errors.company_name}
                  />
                </div>
                <div className='mb-6'>
                  <TextArea
                    reference={register}
                    name="company_description"
                    error={errors.company_description}
                    label={t('company_description_label')}
                    maxLength={200}
                    placeholder={t('company_description_placeholder')}
                  />
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                  <Input
                    name="contact_person"
                    placeholder={t('name_placeholder')}
                    label={t('contact_person_label')}
                    required={true}
                    reference={register}
                    error={errors.contact_person}
                  />
                  <Input
                    name="contact_email"
                    placeholder={t('email_placeholder')}
                    label={t('contact_email_label')}
                    required={true}
                    reference={register}
                    error={errors.contact_email}
                  />
                  <Input
                    type="text"
                    name="contact_phone_number"
                    placeholder={t('phone_number_placeholder')}
                    label={t('contact_phone_number_label')}
                    reference={register}
                    error={errors.contact_phone_number}
                  />
                </div>
                <div className='flex justify-end'>
                  <ButtonMain
                    width={'full md:w-1/2'}
                    text={t('save')}
                    type={'submit'}
                  />
                </div>
              </form>
            </div>

        </div>
      </Card>
      {loading && <Loader />}
    </>
  )
}
