import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Lang from './lang'

const resources = {
  en: {
    translation: {
      ...Lang.getRawJsonFromLangCode('en')
    }
  },
  es: {
    translation: {
      ...Lang.getRawJsonFromLangCode('es')
    }
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: Lang.getCurrentLang().code,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n
