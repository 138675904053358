import axios from 'axios/index'
import Config from '../config'

const QuestionnairesService = {
  getQuestionnairesStatus: async (version, projectId) => {
    return axios.get(`${Config.QUESTIONNAIRES_API_URL}status`, {
      params: {
        version,
        project_id: projectId
      }
    })
  },

  getFounderQuestionnaireStatus: async (founder, projectId) => {
    return axios.get(`${Config.QUESTIONNAIRES_API_URL}founder`, {
      params: {
        founder,
        project_id: projectId
      }
    })
  },

  exportQuestionnaires: (projects) => {
    return axios.post(`${Config.QUESTIONNAIRES_API_URL}export`, { projects })
  },

  getQuestionnairesOutputs: async (params = {}) => {
    let query = ''

    if (params.search) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}name=${params.search}`
    }

    if (params.page !== null && params.page !== undefined) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}page=${params.page}`
    }

    if (params.rowsPerPage) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}rowsPerPage=${params.rowsPerPage}`
    }

    if (params.sort) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}sort=${params.sort}`
    }

    return axios.get(`${Config.QUESTIONNAIRES_API_URL}outputs${query}`)
  },

  createQuestionnairesOutput: async (data) => {
    return axios.post(`${Config.QUESTIONNAIRES_API_URL}outputs`, data)
  },

  deleteQuestionnairesOutput: async (id) => {
    return axios.delete(`${Config.QUESTIONNAIRES_API_URL}outputs/${id}`)
  },

  updateQuestionnairesOutput: async (id, data) => {
    return axios.patch(`${Config.QUESTIONNAIRES_API_URL}outputs/${id}`, data)
  },

  getQuestionnairesVariables: async (params) => {
    let query = ''

    if (params.search) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}name=${params.search}`
    }

    if (params.page !== null && params.page !== undefined) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}page=${params.page}`
    }

    if (params.rowsPerPage) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}rowsPerPage=${params.rowsPerPage}`
    }

    return axios.get(`${Config.QUESTIONNAIRES_API_URL}variables${query}`)
  },

  createQuestionnairesVariable: async (data) => {
    return axios.post(`${Config.QUESTIONNAIRES_API_URL}variables`, data)
  },

  deleteQuestionnairesVariable: async (id) => {
    return axios.delete(`${Config.QUESTIONNAIRES_API_URL}variables/${id}`)
  },

  updateQuestionnairesVariable: async (id, data) => {
    return axios.patch(`${Config.QUESTIONNAIRES_API_URL}variables/${id}`, data)
  },

  getQuestionnairesQuestions: async (params) => {
    return axios.post(`${Config.QUESTIONNAIRES_API_URL}get-questions`, params)
  },

  getQuestionnairesQuestion: async (id) => {
    return axios.get(`${Config.QUESTIONNAIRES_API_URL}questions/id/${id}`)
  },

  createQuestionnairesQuestion: async (data) => {
    return axios.post(`${Config.QUESTIONNAIRES_API_URL}questions`, data)
  },

  deleteQuestionnairesQuestion: async (id) => {
    return axios.delete(`${Config.QUESTIONNAIRES_API_URL}questions/${id}`)
  },

  updateQuestionnairesQuestion: async (id, data) => {
    return axios.patch(`${Config.QUESTIONNAIRES_API_URL}questions/${id}`, data)
  },

  getQuestionnairesTemplates: async (params) => {
    const query = new URLSearchParams(params)
    return axios.get(`${Config.QUESTIONNAIRES_API_URL}templates?${query}`)
  },

  getQuestionnairesTemplate: async (id, isPublic = false) => {
    return axios.get(`${Config.QUESTIONNAIRES_API_URL}templates/${id}`, {
      headers: isPublic ? { 'X-Public': 'true' } : {}
    })
  },

  createQuestionnairesTemplate: async (data) => {
    return axios.post(`${Config.QUESTIONNAIRES_API_URL}templates`, data)
  },

  deleteQuestionnairesTemplate: async (id) => {
    return axios.delete(`${Config.QUESTIONNAIRES_API_URL}templates/${id}`)
  },

  updateQuestionnairesTemplate: async (id, data) => {
    return axios.patch(`${Config.QUESTIONNAIRES_API_URL}templates/${id}`, data)
  },

  getQuestionnairesQuestionsByElementType: async (elementsType, elements) => {
    return axios.get(`${Config.QUESTIONNAIRES_API_URL}questions/type/${elementsType}`, {
      params: {
        elements
      }
    })
  },

  getQuestionsByVariables: async (variables) => {
    return axios.get(`${Config.QUESTIONNAIRES_API_URL}questions/by-variables`, {
      params: {
        variables
      }
    })
  },

  getQuestionnairesForms: async (params) => {
    const query = new URLSearchParams(params)
    return axios.get(`${Config.QUESTIONNAIRES_API_URL}forms?${query}`)
  },

  getQuestionnairesForm: async (id, isPublic) => {
    return axios.get(
      `${Config.QUESTIONNAIRES_API_URL}forms/${id}`,
      isPublic ? { headers: { 'X-Public': 'true' } } : {}
    )
  },

  createQuestionnairesForm: async (data) => {
    return axios.post(`${Config.QUESTIONNAIRES_API_URL}forms`, data)
  },

  deleteQuestionnairesForm: async (id) => {
    return axios.delete(`${Config.QUESTIONNAIRES_API_URL}forms/${id}`)
  },

  updateQuestionnairesForm: async (id, data) => {
    return axios.patch(`${Config.QUESTIONNAIRES_API_URL}forms/${id}`, data)
  },

  publishQuestionnairesForm: async (data) => {
    return axios.post(`${Config.QUESTIONNAIRES_API_URL}forms/publish`, data)
  },

  getProjectQuestionnaires: async (projectId, investor = null) => {
    if (investor) {
      return axios.get(`${Config.QUESTIONNAIRES_API_URL}projects/${projectId}?investor=${investor}`)
    } else {
      return axios.get(`${Config.QUESTIONNAIRES_API_URL}projects/${projectId}`)
    }
  },

  getProjectQuestionnaire: async (projectQuestionnaireId, founder) => {
    if (founder) {
      return axios.get(`${Config.QUESTIONNAIRES_API_URL}founder-questionnaire/${projectQuestionnaireId}`)
    } else {
      return axios.get(`${Config.QUESTIONNAIRES_API_URL}project-questionnaire/${projectQuestionnaireId}`)
    }
  },

  getLastKiotaExpress: async () => {
    return axios.get(`${Config.QUESTIONNAIRES_API_URL}kiota-express`)
  },

  getLastKiotaExpressByInvestor: async (projectId) => {
    return axios.get(`${Config.QUESTIONNAIRES_API_URL}kiota-express?project=${projectId}`)
  },

  updateProjectQuestionnaireQuestion: async (projectQuestionnaireId, questionId, data) => {
    return axios.patch(
      `${Config.QUESTIONNAIRES_API_URL}project-questionnaire/${projectQuestionnaireId}/question/${questionId}`,
      data
    )
  },

  updateKiotaExpressQuestion: async (kiotaExpressId, questionId, data) => {
    return axios.patch(`${Config.QUESTIONNAIRES_API_URL}kiota-express/${kiotaExpressId}/questions/${questionId}`, data)
  },

  finishKiotaExpress: async (kiotaExpressId) => {
    return axios.post(`${Config.QUESTIONNAIRES_API_URL}kiota-express/${kiotaExpressId}/finalization`)
  },

  finishProjectQuestionnaire: async (projectQuestionnaireId) => {
    return axios.post(`${Config.QUESTIONNAIRES_API_URL}project-questionnaire/${projectQuestionnaireId}/finalization`)
  },

  basicEvaluation: async (projectId, projectQuestionnaireId) => {
    return axios.post(
      `${Config.QUESTIONNAIRES_API_URL}projects/${projectId}/project-questionnaire/${projectQuestionnaireId}/basic-evaluation`
    )
  },

  calculatePercentages: async (projectId) => {
    return axios.post(`${Config.QUESTIONNAIRES_API_URL}projects/${projectId}/calculate-percentages`, {})
  },

  getPublicQuestionnaire: async (investorId, formId) => {
    return axios.get(`${Config.QUESTIONNAIRES_API_URL}public/investor/${investorId}/form/${formId}`)
  },

  getOutliers: async (formId) => {
    return axios.get(`${Config.QUESTIONNAIRES_API_URL}outliers?form_id=${formId}`)
  },

  publishKiotaExpress: async () => {
    return axios.post(`${Config.QUESTIONNAIRES_API_URL}kiota-express`)
  },

  importQuestions: async (data) => {
    return await axios.post(`${Config.QUESTIONNAIRES_API_URL}questions/import`, data)
  },

  getPublishedForm: async (formId, isPublic = false) => {
    return axios.get(`${Config.QUESTIONNAIRES_API_URL}forms/${formId}`, {
      headers: { 'X-Published': 'true', 'X-Public': isPublic ? 'true' : 'false' }
    })
  },

  getDraftForm: async (formId, isPublic = false) => {
    return axios.get(`${Config.QUESTIONNAIRES_API_URL}forms/${formId}`, {
      headers: {
        'X-Draft': 'true',
        'X-Public': isPublic ? 'true' : 'false'
      }
    })
  },

  saveFormApplication: (formId, data) => {
    return axios.post(`${Config.QUESTIONNAIRES_API_URL}forms/${formId}/application`, data, {
      headers: {
        'X-Public': 'true'
      }
    })
  },

  getQuestionnaireAnswers: (formId, params, isPublic = false) => {
    return axios.get(`${Config.QUESTIONNAIRES_API_URL}forms/${formId}/applications`, {
      params,
      headers: {
        'X-Public': isPublic ? 'true' : 'false'
      }
    })
  },

  getQuestionnaireStats: (formId) => {
    return axios.get(`${Config.QUESTIONNAIRES_API_URL}forms/${formId}/statistics`)
  }

}
export default QuestionnairesService
