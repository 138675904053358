import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import { Loader } from 'components/ui/molecules/Loader'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import Lupa from 'styles/images/lupa.png'
import { RoutesLinks } from 'components/routes-links'
import { Pagination } from 'components/ui/atoms/Pagination'
import { Card } from 'components/ui/atoms/Card'
import Avatar from 'react-avatar'
import IrmService from '../../services/irm-service'
import Utils from 'utils/utils'
import { FaEye, FaPlus, FaPlusCircle, FaRocket, FaUser, FaUsers } from 'react-icons/fa'
import { Button } from 'components/ui/atoms/Button'
import { useSwal } from 'hooks/useSwal'
import { ButtonCardMain } from 'components/ui/atoms/ButtonCardMain'
import Config from 'config'
import ReactTooltip from 'react-tooltip'
import { BsCash } from 'react-icons/bs'
import IRMInvestorCard from './IRMInvestorCard'

const InvestorsList = ({ currentPage, setCurrentPage, filters, irm = true, kiota = false }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { confirm } = useSwal()

  const handleRedirect = (url) => history.push(url)

  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalInvestors, setTotalInvestors] = useState(0)
  const [investors, setInvestors] = useState([])

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const addToMyContacts = (investorId) => {
    confirm({text: t('add_investor_to_contacts_explanation')}).then(async (isConfirmed) => {
        if (isConfirmed) {
          try {
            setLoading(true)
            await IrmService.addInvestorToContacts(investorId)
            addToast(t('investor_added_successfully_to_contacts'), {
                appearance: 'success',
                autoDismiss: true
              })
          } catch (error) {
            addToast(error?.response?.data?.msg_key ? t(error.response.data.msg_key) : t('error_occurred_adding_investor'), {
              appearance: 'error',
              autoDismiss: true
            })
          } finally {
            setLoading(false)
          }
        }
      })
  }

  const getInvestors = () => {
    setLoading(true)

    const params = {
      ...filters,
      rowsPerPage: pageSize,
      page: currentPage,
      kiota
    }

    IrmService.getInvestors(params)
      .then((response) => {
        const { investors, pagination } = response.data.data
        setInvestors(investors)

        if (pagination.current - 1 !== currentPage) {
          setCurrentPage(pagination.current)
        }

        if (pagination.total !== totalPages) {
          setTotalPages(pagination.pages)
        }

        if (pagination.totalDocs !== totalInvestors) {
          setTotalInvestors(pagination.totalDocs)
        }
      })
      .catch((error) => {
        console.log(error)
        addToast(t('error_occurred_retrieving_information'), {
          appearance: 'error',
          autoDismiss: true
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getInvestors()
  }, [currentPage, pageSize, filters, kiota])

  return (
    <>
      {loading && <Loader />}

      {!loading && investors.length === 0 && (
        <div className='my-6'>
            <NoDataInfo
              image={
                <div className={'flex items-center justify-center'}>
                  <img src={Lupa} className={'w-20 mb-6'} />
                </div>
              }
              title={t('investors_not_found')}
            />
        </div>
      )}
      {investors.length !== 0 && (
        <div className='my-6'>
          <div className='grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4'>
            {investors.map((investor) => {
              return <IRMInvestorCard investor={investor} irm={irm} kiota={kiota} key={investor._id} addToMyContacts={addToMyContacts} />
            })}
          </div>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={(page) => setCurrentPage(page)}
            perPage={pageSize}
            pages={totalPages}
            setPerPage={(value) => setPageSize(value[0].id)}
          />
        </div>
      )}
    </>
  )
}

export default InvestorsList
