import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Voice from 'styles/images/voice_icon.svg'
import Text from 'styles/images/text_icon.svg'
import Draw from 'styles/images/draw_icon.svg'
import { BasicCardNotes } from 'components/ui/molecules/BasicCardNotes'
import VoiceNotes from 'components/project/components/general-information/components/voice-notes'
import TextNotes from 'components/project/components/general-information/components/text-notes'
import DrawNotes from 'components/project/components/general-information/components/draw-notes'
import { Button } from 'components/ui/atoms/Button'
import { Input } from 'components/ui/atoms/Input'
import { isEmpty } from 'lodash'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { TextArea } from 'components/ui/atoms/TextArea'
import { FaCheckCircle, FaExclamationCircle, FaQuestion, FaTimesCircle } from 'react-icons/fa'
import { SidePanel } from 'components/ui/objects/SidePanel'
import { Autocomplete } from 'components/ui/atoms/Autocomplete'
import Config from 'config'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { ButtonDanger } from 'components/ui/atoms/ButtonDanger'

const MilestoneGoalWizard = ({
  selectedSection,
  sections,
  initialValues,
  handleCloseWizard,
  onSubmit,
  deleteGoal
}) => {
  const { t } = useTranslation()

  const MilestoneSchema = Yup.object().shape({
    id: Yup.string().nullable(),
    milestone: Yup.string().required(t('required_field')),
    description: Yup.string().nullable(),
    deadline: Yup.string().nullable(),
    status: Yup.string().nullable(),
    section: Yup.string().nullable()
  })

  const { register, errors, setValue, handleSubmit, trigger, getValues } = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    reValidateMode: 'all',
    resolver: yupResolver(MilestoneSchema, { abortEarly: false })
  })

  useEffect(() => {
    register('status')
    register('id')
    register('section')
  }, [register])

  const getSectionInitialValues = () => {
    if (!getValues().section || !parsedSections) {
      return { id: null, name: t('unclassified_goals') }
    }

    const _section = sections.find(section => section._id === getValues().section)

    return _section ? { id: _section._id, name: _section.section } : null
  }

  useEffect(() => {
    if (initialValues && !isEmpty(initialValues)) {
      setValue('id', initialValues.id)
      setValue('milestone', initialValues.milestone)
      setValue('description', initialValues.description)
      setValue('deadline', initialValues.deadline)
      setValue('status', initialValues?.status ? initialValues.status : 'unassigned')
      setValue('section', initialValues?.section)
    } else {
      setValue('status', initialValues?.status ? initialValues.status : 'unassigned')
      setValue('section', initialValues?.section)
    }

    if (selectedSection) {
      setValue('section', selectedSection._id)
      trigger()
    }
    trigger()
  }, [initialValues, selectedSection])


  const parsedSections = sections.map(section => ({ id: section._id, name: section._id ? section.section : t(section.section) }))

  return (
    <>
    <SidePanel
      width={'1/4'}
      onClose={handleCloseWizard}
      title={isEmpty(initialValues) ? t('create_milestone') : t('update_milestone')}
    >
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full">
            <Input
              reference={register}
              id="milestone"
              type="text"
              name="milestone"
              label={`${t('milestone')}: *`}
              placeholder={t('milestone')}
              error={errors.milestone}
            />
          </div>
          <div className="mt-2 w-full">

            <TextArea
              reference={register}
              name="description"
              error={errors.description}
              label={t('description')}
              maxLength={200}
              placeholder={t('description')}
            />
            </div>
            <div className="mt-4 w-full">
            <Input
                type="date"
                name="deadline"
                placeholder={t('deadline')}
                label={t('deadline')}
                reference={register}
              />
          </div>
          <Autocomplete
            name="section"
            label={t('section')}
            placeholder={t('section')}
            noOptionsText={t('sections_not_found')}
            options={parsedSections}
            onSelect={selected => {
              setValue('section', selected?.id)
              trigger()
            }}
            initialValues={getSectionInitialValues()}
            isClearable={false}
            disabled={isEmpty(initialValues) && selectedSection}
          />
          <div className="mt-2 w-full">
          <label className="block mb-1 text-left text-xs font-medium text-black">
            {`${t('status')}:`} <span className={`ml-2 font-semibold ${getValues().status === 'unassigned' ? 'text-gray' : getValues().status === 'overdue' ? 'text-orange-400' : getValues().status === 'not_completed' ? 'text-red' : 'text-green'}`}>{t(getValues().status)}</span>
          </label>
          <div className="flex flex-row justify-start items-start my-6">
                <div data-tip={t('unassigned')}>
                  <ButtonSecondary
                  type={'button'}
                    onClick={() => {
                      setValue('status', 'unassigned')
                      trigger()
                    }}
                    iconComponent={<FaQuestion className="inline w-4 h-4" />}
                    marginRight={2}
                  />
                </div>
                <div data-tip={t('overdue')}>
                  <ButtonSecondary
                    type={'button'}
                    onClick={() => {
                      setValue('status', 'overdue')
                      trigger()
                    }}
                    iconComponent={ <FaExclamationCircle className="inline w-4 h-4" /> }
                    marginRight={2}
                  />
                </div>
                <div data-tip={t('completed')}>
                  <ButtonSecondary
                    type={'button'}
                    onClick={() => {
                      setValue('status', 'completed')
                      trigger()
                    }}
                    iconComponent={ <FaCheckCircle className="inline w-4 h-4" /> }
                    marginRight={2}
                  />
                </div>
                <div data-tip={t('not_completed')}>
                  <ButtonSecondary
                    type={'button'}
                    onClick={() => {
                      setValue('status', 'not_completed')
                      trigger()
                    }}
                    iconComponent={ <FaTimesCircle className="inline w-4 h-4" /> }
                  />
                </div>
            </div>

              </div>
              <div className={`flex justify-end mt-2 pt-2 border-t border-separator-${Config.PLATFORM}`}>
            {!isEmpty(initialValues) && <ButtonDanger
              type="button"
              onClick={() => {
                deleteGoal(initialValues.id)
                handleCloseWizard()
              }}
              text={
                t('delete_goal')
              }
              marginRight='2'
            />}
            <ButtonSecondary
              type="submit"
              text={
                isEmpty(initialValues) ? t('create_milestone') : t('update_milestone')
              }
            />
          </div>
        </form>
      </SidePanel>
    </>
  )
}

export default MilestoneGoalWizard
