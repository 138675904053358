import { ButtonDanger } from 'components/ui/atoms/ButtonDanger'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { Card } from 'components/ui/atoms/Card'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import { Loader } from 'components/ui/molecules/Loader'
import { useSwal } from 'hooks/useSwal'
import useUser from 'hooks/useUser'
import { forwardRef, useImperativeHandle, useState } from 'react'
import Avatar from 'react-avatar'
import { useTranslation } from 'react-i18next'
import { FaExternalLinkAlt, FaLink, FaPen, FaTrash } from 'react-icons/fa'
import { useToasts } from 'react-toast-notifications'
import AccountService from 'services/account-service'
import PortfolioJobSection from './PortfolioJobSection'
import Config from 'config'
import { ButtonCardSecondary } from 'components/ui/atoms/ButtonCardSecondary'
import moment from 'moment'

export const Portfolio = forwardRef(({}, ref) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const { addToast } = useToasts()
    const { user, reloadUserInfo } = useUser()
    const { confirm } = useSwal()
    const [portfolioJobToEdit, setPortfolioJobToEdit] = useState(null)

    const [showPortfolioJobSection, setShowPortfolioJobSection] = useState(false)

    useImperativeHandle(ref, () => ({
        showPortfolioJobSection: () => setShowPortfolioJobSection(true)
      }))

    const addJobToPortfolio = (values) => {
        confirm().then(isConfirmed => {
            if (isConfirmed) {
                setLoading(true)
                AccountService.addJobToPortfolio(values)
                .then(() => reloadUserInfo())
                .then(() => {
                    addToast(t('job_to_portfolio_registered_successfully'), {
                    appearance: 'success',
                    autoDismiss: true
                    })
                })
                .catch(error => {
                    addToast(
                    error.response && error.response.data.msg
                        ? error.response.data.msg
                        : t('error_occurred_registering_job_to_portfolio'),
                    {
                        appearance: 'error',
                        autoDismiss: true
                    }
                    )
                }).finally(() => {
                    setLoading(false)
                    setShowPortfolioJobSection(false)
                })
            }
        })
    }

    const updateJobFromPortfolio = (values) => {
        confirm().then(isConfirmed => {
            if (isConfirmed) {
                setLoading(true)
                AccountService.updateJobFromPortfolio(portfolioJobToEdit._id, values)
                .then(() => reloadUserInfo())
                .then(() => {
                    addToast(t('job_from_portfolio_updated_successfully'), {
                    appearance: 'success',
                    autoDismiss: true
                    })
                })
                .catch(error => {
                    addToast(
                    error.response && error.response.data.msg
                        ? error.response.data.msg
                        : t('error_occurred_updating_job_from_portfolio'),
                    {
                        appearance: 'error',
                        autoDismiss: true
                    }
                    )
                }).finally(() => {
                    setLoading(false)
                    setShowPortfolioJobSection(false)
                    setPortfolioJobToEdit(null)
                })
            }
        })
    }

    const deleteJobFromPortfolio = (portfolioJobId) => {
        confirm().then(async (isConfirmed) => {
          if (isConfirmed) {
            try {
              setLoading(true)
              await AccountService.deleteJobFromPortfolio(portfolioJobId)
              reloadUserInfo()
              addToast(t('job_from_portfolio_deleted_successfully'), {
                appearance: 'success',
                autoDismiss: true
              })
            } catch (error) {
                addToast(
                  error.response
                    ? error.response.data.msg_key
                      ? t(error.response.data.msg_key)
                      : t('error_occurred_deleting_job_from_portfolio')
                    : t('error_occurred_deleting_job_from_portfolio'),
                  {
                    appearance: 'error',
                    autoDismiss: true
                  }
                )
            } finally {
              setLoading(false)
            }
          }
        })
      }
    
      console.log(user.portfolio)
  return (
    <div className='my-6'>
        {showPortfolioJobSection && (
            <PortfolioJobSection
                onClose={() => {
                    setPortfolioJobToEdit(null)
                    setShowPortfolioJobSection(false)
                }}
                addJobToPortfolio={addJobToPortfolio}
                initialValues={portfolioJobToEdit}
                updateJobFromPortfolio={updateJobFromPortfolio}
            />
        )}
        {(!user.portfolio || user.portfolio.length === 0) && (
            <NoDataInfo title={t('no_portfolio_job_registered')} />
        )}
        {user.portfolio && user.portfolio.length !== 0 && (
            <div className='grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4'>
                {user.portfolio.map((portfolioJob) => {
                    return (
                        <Card
                            key={portfolioJob._id}
                            wrapperClassName="transition-transform"
                            containerClassName="w-full flex flex-col h-full"
                        >
                            <div className='flex flex-col'>
                                <div className="flex pb-2 mb-2 ">
                                    <div className='flex flex-row w-full'>
                                        <div className="flex flex-col text-xs min-w-0 w-3/5">
                                            <h3 className={`uppercase font-bold leading-none text-main-${Config.PLATFORM} truncate mb-1`}>
                                            {portfolioJob.title}
                                            {portfolioJob.link && <FaExternalLinkAlt className='inline ml-2 h-4 cursor-pointer' onClick={() => window.open(portfolioJob.link, '_blank')}/>}

                                            </h3>
                                            <div>
                                                <div className='text-sm'>
                                                <b>{portfolioJob.company}</b>
                                                {portfolioJob.company_url && <FaLink className='inline ml-2 h-4 cursor-pointer' onClick={() => window.open(portfolioJob.company_url, '_blank')}/>}
                                                </div>
                                                <div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="flex items-center justify-center w-2/5">
                                        <div className="w-full h-[80px] ">
                                            <Avatar
                                            src={portfolioJob.company_logo}
                                            name={portfolioJob.company_logo}
                                            alt={portfolioJob.company_logo}
                                            maxInitials={3}
                                            className={`w-full h-[80px] object-contain`}
                                            color="#e0e6f2"
                                            fgColor="#4d70b3"
                                            round="5"
                                            />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {portfolioJob.description && <div className='border-t border-gray-lines py-2 text-xs'>
                                    {portfolioJob.description}
                                </div>}
                            </div>
                            <div className={`flex justify-end border-t border-separator-${Config.PLATFORM} pt-2 mt-4`}>
                                <ButtonDanger
                                    onClick={() => deleteJobFromPortfolio(portfolioJob._id)}
                                    text={t('delete')}
                                    marginRight={2}
                                    iconComponent={<FaTrash className="inline-block mr-2 w-5 h-4" />}
                                />
                                <ButtonCardSecondary
                                    onClick={() => {
                                        setPortfolioJobToEdit(portfolioJob)
                                        setShowPortfolioJobSection(true)
                                    }}
                                    text={t('edit')}
                                    iconComponent={<FaPen className="inline-block mr-2 w-5 h-4" />}
                                    marginRight="2"
                                />
                            </div>
                        </Card>
                    )
                })}
            </div>
        )}
        {loading && <Loader /> }
    </div>
  )
})
