import { QuestionType } from './QuestionType'

export const QuestionTypeGroup = ({
  currentQuestion,
  indexElement,
  answers,
  setAnswers,
  checkCurrentQuestionErrors,
  setLoading,
  goNext,
  setQuestionValidated,
  language,
  showInvestorsPercentage,
  expandedQuestionsErrors,
  investorId,
  eventId,
  scoreCardId
}) => {

  return (
    <>
    {
      currentQuestion.question_id.questionsGroup?.map((question, index) => (
        <QuestionType showInvestorsPercentage={showInvestorsPercentage} groupedQuestionId={currentQuestion.question_id._id} groupedQuestionIndex={index} key={index} indexElement={indexElement} currentQuestion={{ question_id: question }} answers={answers} setAnswers={setAnswers} checkCurrentQuestionErrors={checkCurrentQuestionErrors} setLoading={setLoading} goNext={goNext} setQuestionValidated={setQuestionValidated} language={language} expandedQuestionsErrors={expandedQuestionsErrors} investorId={investorId} eventId={eventId} scoreCardId={scoreCardId}/>
      ))
    }
    </>
  )
}
