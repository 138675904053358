import React, { Component } from 'react'
import './loader-styles.css'

export default class Loader extends Component {
  render () {
    return (
      <div className="loader">
        <div className="lds-ellipsis">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    )
  }
}
