import { useTranslation } from 'react-i18next'
import { Card } from 'components/ui/atoms/Card'
import Avatar from 'react-avatar'
import { FaCheck, FaExpandAlt } from 'react-icons/all'
import { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { Button } from 'components/ui/atoms/Button'
import { useToasts } from 'react-toast-notifications'
import { Loader } from '../ui/molecules/Loader'
import { DealflowService } from '../../services/dealflow-service'
import { useSwal } from '../../hooks/useSwal'
import Config from 'config';
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { ButtonCardSecondary } from 'components/ui/atoms/ButtonCardSecondary'

export const DealflowExploreListItem = ({
  project,
  onManage,
  setDealToResume
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { confirm } = useSwal()
  const [loading, setLoading] = useState(false)

  const addProjectToPool = () => {
    confirm({
      title: t('add_project_to_pool_confirmation'),
      text: t('add_project_to_pool_confirmation_text', { name: project.name })
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        return DealflowService.addProjectToPool(project._id)
          .then(() => {
            addToast(t('startup_added_to_pool_successfully'), { appearance: 'success', autoDismiss: true })
          })
          .catch(() => {
            addToast(t('error_occurred_adding_startup_to_pool'), { appearance: 'error', autoDismiss: true })
          })
          .finally(() => {
            setLoading(false)
            onManage && onManage()
          })
      }
    })
  }

  const getSectors = () => {
    return [t(project.sector?.key), t(project.subsector?.key)]
      .filter(Boolean)
      .join(', ')
  }

  const getLocation = () => {
    return [t(project.country_incorporation?.key), project.city_incorporation]
      .filter(Boolean)
      .join(', ') || '-'
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  if (!project) {
    return null
  }

  return (
    <>
      <Card
        wrapperClassName="transition-transform"
        containerClassName="divide-gray-lines w-full flex flex-col h-full"
      >
        <div className='flex flex-col'>
          <div className="flex pb-2 mb-2 ">
            <div className='flex flex-row w-full justify-around'>

              <div className="flex flex-col h-full text-sm min-w-0 w-3/5">
                <h5 className={`uppercase font-bold text-sm leading-none text-main-${Config.PLATFORM} truncate mb-1`}>
                  {project.name}
                </h5>
                <div><b>{t('sector')}:</b> {getSectors()}</div>
                <div><b>{t('location')}:</b> {getLocation()}</div>
              </div>
              <div className="flex items-center justify-center mr-2 w-2/5 info-card-container-img">
                <Avatar
                  src={project.logo}
                  name={project.name}
                  alt={project.name}
                  maxInitials={3}
                  className={`h-full object-contain ${project.logo ? 'w-full' : ''}`}
                  color="#e0e6f2"
                  fgColor="#4d70b3"
                />
              </div>
            </div>
          </div>
        </div>

        <div className={`flex justify-end mt-2 pt-2 border-t border-separator-${Config.PLATFORM}`}>
        <ButtonCardSecondary
            onClick={() => setDealToResume(project)}
            text={t('see_resume')}
            iconComponent={<FaExpandAlt className="inline-block mr-2 w-5 h-4" />}
            marginRight="4"
            />
          <ButtonMain
            onClick={() => addProjectToPool(project._id)}
            text={t('move_to_pool')}
            iconComponent={<FaCheck className="inline-block mr-2 w-5 h-4" />}
          />
        </div>
      </Card>

      {loading && <Loader />}
    </>
  )
}
