import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import { Loader } from 'components/ui/molecules/Loader'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import Lupa from 'styles/images/lupa.png'
import { RoutesLinks } from 'components/routes-links'
import { Pagination } from 'components/ui/atoms/Pagination'
import { Card } from 'components/ui/atoms/Card'
import Avatar from 'react-avatar'
import KbotsService from '../../services/kbots-service'
import Utils from 'utils/utils'
import { FaPlusCircle } from 'react-icons/fa'
import { Button } from 'components/ui/atoms/Button'
import { useSwal } from 'hooks/useSwal'

const KbotsDealflowList = ({ selectedDealflow, setSelectedDealflow, dealflow, setDealflow, admin = false }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [totalPages, setTotalPages] = useState(1)
  const [totalBots, setTotalBots] = useState(0)

  const [loading, setLoading] = useState(false)
    const [hasActiveBots, setHasActiveBots] = useState(admin)
 
  const getDealflow = () => {
    setLoading(true)
    const params = {
      rowsPerPage: pageSize,
      page: currentPage
    }

    KbotsService.getDealflow(params)
      .then((response) => {
        const { dealflow, pagination } = response.data.data
        setDealflow(dealflow)

        if (pagination.current - 1 !== currentPage) {
          setCurrentPage(pagination.current)
        }

        if (pagination.total !== totalPages) {
          setTotalPages(pagination.pages)
        }

        if (pagination.totalDocs !== totalBots) {
          setTotalBots(pagination.totalDocs)
        }
      })
      .catch((error) => {
        console.log(error)
        addToast(t('error_occurred_retrieving_information'), {
          appearance: 'error',
          autoDismiss: true
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const checkBots = () => {
    setLoading(true)

    KbotsService.checkBots()
      .then((response) => {
        setHasActiveBots(response.data.data)
      })
      .catch((error) => {
        console.log(error)
        addToast(t('error_occurred_retrieving_information'), {
          appearance: 'error',
          autoDismiss: true
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (!admin) {
        checkBots()
    }
  }, [admin])

  useEffect(() => {
    getDealflow()
  }, [currentPage, pageSize])


  const getKeyValue = (admin, deal, key) => {
    if (admin) {
        return key === 'name' ? deal[key] : deal[key] ? t(deal[key].key) : '-'
    } else {
        return key === 'name' ? deal.startup.name : deal.startup[key] ? t(deal.startup[key].key) : '-'
    }
  }

  return (
    <>
      {loading && <Loader />}


      {!loading && !admin && hasActiveBots && dealflow.length === 0 && (
        <div className='my-6'>
          <NoDataInfo
            image={
              <div className={'flex items-center justify-center'}>
                <img src={Lupa} className={'w-20 mb-6'} />
              </div>
            }
            title={t('kbots_dealflow_not_found_text_one')}
            textOne={t('kbots_dealflow_not_found_text_two')}
          />
        </div>
      )}
      {!loading && !admin && !hasActiveBots && dealflow.length === 0 && (
        <div className='my-6'>
          <NoDataInfo
            image={
              <div className={'flex items-center justify-center'}>
                <img src={Lupa} className={'w-20 mb-6'} />
              </div>
            }
            title={t('kbots_no_bots_registered')}
          />
        </div>
      )}
      {!loading && admin && dealflow.length == 0 && (
        <div className='my-6'>
          <NoDataInfo
            image={
              <div className={'flex items-center justify-center'}>
                <img src={Lupa} className={'w-20 mb-6'} />
              </div>
            }
            title={t('no_dealflow_imported')}
          />
        </div>
      )}
      {dealflow.length !== 0 && (
        <div className='my-6'>
          <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4'>
            {dealflow.map((deal) => {
              return <div key={deal._id}>
              <Card>
                <div className='flex flex-col px-2'>
                  <div>
                    <span className="text-sm font-bold line-clamp-1">
                        {admin ? deal.name : deal.startup.name}
                    </span>
                  </div>
                  <div className='text-xs mt-2 flex flex-col'>
                    <div>
                      <span className='font-semibold'>
                        {`${t('sector')}: `}
                      </span>
                      <span>
                        {getKeyValue(admin, deal, 'sector')}
                      </span>
                    </div>
                    <div>
                      <span className='font-semibold'>
                      {`${t('incorporation_country')}: `}
                      </span>
                      <span>
                        {getKeyValue(admin, deal, 'incorporation_country')}
                      </span>
                    </div>
                    <div>
                      <span className='font-semibold'>
                      {`${t('investment_stage')}: `}
                      </span>
                      <span>
                        {getKeyValue(admin, deal, 'investment_stage')}
                      </span>
                    </div>
                    <div>
                      <span className='font-semibold'>
                        {`${t('business_model')}: `}
                      </span>
                      <span>
                        {getKeyValue(admin, deal, 'business_model')}
                      </span>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            })}
          </div>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={(page) => setCurrentPage(page)}
            perPage={pageSize}
            pages={totalPages}
            setPerPage={(value) => setPageSize(value[0].id)}
          />
        </div>
      )}
    </>
  )
}

export default KbotsDealflowList
