import Logo from 'styles/images/logo_light.svg'
import { Button } from 'components/ui/atoms/Button'
import { RoutesLinks } from 'components/routes-links'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Config from 'config';
import Parameters from 'config/parameters.json';

const logo = {
  '000': Logo,
  '001': Parameters.favicon['001'],
}

export const SharedEventHeader = ({ showLoginButtons = true }) => {
  const { t } = useTranslation()
  const { token } = useSelector((state) => state.auth)
  const history = useHistory()

  return (
    <header className="w-full flex justify-between px-8 py-6 border-b border-gray-lines">
      <div>
        <a href={Parameters.main_url_link[Config.PLATFORM]} target="_blank" rel="noreferrer">
          <img src={logo[Config.PLATFORM]} alt="Logo Kiota" />
        </a>
      </div>
      <div className="flex">
        {(!token && showLoginButtons) && (
          <>
            <Button
              text={t('login')}
              verticalMargin=""
              marginRight="6"
              horizontal="3"
              vertical="1"
              width="auto"
              textSize="xxs"
              onClick={() => history.push(RoutesLinks.LOGIN_LINK)}
              textColor={`buttons-card-text-${Config.PLATFORM}`}
              bgColor={`buttons-card-${Config.PLATFORM}`}
              bgHoverColor={`buttons-hover-${Config.PLATFORM}`}
            />
            <Button
              text={t('sign_up')}
              verticalMargin=""
              horizontal="3"
              vertical="1"
              width="auto"
              textSize="xxs"
              onClick={() => history.push(RoutesLinks.REGISTER_LINK)}
              textColor={`buttons-card-text-${Config.PLATFORM}`}
              bgColor={`buttons-secondary-${Config.PLATFORM}`}
              bgHoverColor={`buttons-hover-${Config.PLATFORM}`}
            />
          </>
        )}
      </div>
    </header>
  )
}
