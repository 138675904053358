import React, { useState } from 'react'
import LogoDefault from 'styles/images/logo_default.png'
import { useTranslation } from 'react-i18next'
import { TagList } from 'components/tags/TagList'
import { FaChevronUp, FaExpandAlt, FaEye, FaTrash } from 'react-icons/all'
import { BoardCardCollapsedHeader } from 'components/pipeline-manager/components/cards/BoardCardCollapsedHeader'
import Config from 'config';
import { ButtonDanger } from 'components/ui/atoms/ButtonDanger'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { RoutesLinks } from 'components/routes-links'
import { useHistory } from 'react-router-dom'

const PipelineDealflowTypeCard = ({
  editable,
  dealflow,
  deleteCard,
  getForm,
  getDeal,
  startupToSearch
}) => {
  const { t } = useTranslation()
  const [expandInformation, setExpandInformation] = useState(false)
  const history = useHistory()

  return (
    <div className={`p-2 my-2 rounded-2xl bg-white w-56 flex flex-row justify-between ${editable && 'cursor-move hover:shadow-inner'} relative border-gray-lines border`}>
      {!expandInformation && (
        <BoardCardCollapsedHeader
          startupToSearch={startupToSearch}
          onClick={() => setExpandInformation(true)}
          tags={dealflow.tags || []}
          title={dealflow.name}
          imageComponent={
            <img
              src={dealflow.logo || LogoDefault}
              alt={dealflow.name}
              className="cursor-move mr-2 w-5 h-5"
            />
          }
        />
      )}

      {expandInformation && (
        <div className="justify-around w-full ">
          <div className="flex w-full">
            <div className="flex items-start justify-start h-auto  pb-1">
              <div className="flex items-center justify-center h-16 mr-2 w-16 mb-4">
                <img
                  src={dealflow.logo || LogoDefault}
                  alt={dealflow.name}
                  className="mr-2 w-14 h-14"
                />
              </div>
            </div>

            <div
              className="flex w-full relative cursor-pointer"
              onClick={() => setExpandInformation(false)}
            >
              <div className="flex flex-col justify-between w-full pb-4 h-full ">
                <div className="flex flex-row justify-between">
                  <div>
                    <h5 className={`uppercase font-bold text-xxs mt-4 leading-none break-all text-left ${startupToSearch && dealflow.name.toLowerCase().includes(startupToSearch.toLowerCase()) && 'bg-yellow-400'}`}>
                      {dealflow.name}
                    </h5>
                  </div>
                  <span className={`text-sm text-center text-main-${Config.PLATFORM} w-5 h-5 mr-0.5`}>
                    <FaChevronUp />
                  </span>
                </div>
              </div>
            </div>
          </div>

          {dealflow?.tags?.length > 0 && (
            <div className={`pb-2 border-t border-gray-lines`}>
              <TagList tags={dealflow?.tags} />
            </div>
          )}

          {dealflow?.forms.length > 0 && (
            <div className={`pb-2 border-t border-gray-lines`}>
              <span className='font-bold text-xs'>{t('questionnaires')}</span>
              {dealflow.forms.map((form, i) => {
                return (
                  <span
                    key={`${form.form._id}-${i}`}
                    className={`inline-block mr-2 mt-1 font-semibold text-xxs bg-bg-buttons-secondary-${Config.PLATFORM} px-2 py-0.5 rounded-md border border-border-buttons-secondary-${Config.PLATFORM} text-text-buttons-secondary-${Config.PLATFORM} cursor-pointer hover:shadow-inner`}
                    onClick={() => getForm(form.form._id, dealflow._id)}
                  >
                    {form.form.name}
                  </span>
                )
              })}
            </div>
          )}

          {editable && dealflow?.events.length > 0 && (
            <div className={`pb-2 border-t border-gray-lines`}>
                <span className='font-bold text-xs'>{t('evaluation_processes')}</span>
                {dealflow.events.map((event, i) => {
                return (
                  <span
                    key={`${event.event._id}-${i}`}
                    className={`inline-block mr-2 mt-1 font-semibold text-xxs bg-bg-buttons-secondary-${Config.PLATFORM} px-2 py-0.5 rounded-md border border-border-buttons-secondary-${Config.PLATFORM} text-text-buttons-secondary-${Config.PLATFORM} cursor-pointer hover:shadow-inner`}
                    onClick={() => history.push(`${RoutesLinks.EVALUATION_PROCESSES_LINK}${event.event._id}/startups/${dealflow._id}`)}
                  >
                    {event.event.name}
                  </span>
                )
              })}
            </div>
          )}

          {editable && <div className={`border-t border-separator-${Config.PLATFORM} py-2`}>
                <ButtonSecondary text={t('see_profile_resume')} iconComponent={<FaExpandAlt className='inline mr-2'/>} onClick={() => getDeal(dealflow._id)} width={'full'} marginTop={2} />
                <ButtonSecondary text={t('go_to_profile')} iconComponent={<FaEye className='inline mr-2'/>} onClick={() => history.push(`${RoutesLinks.DEALFLOW_LINK}${dealflow._id}`)} width={'full'} marginTop={2} />
                <ButtonDanger text={t('delete_startup')} iconComponent={<FaTrash className='inline mr-2'/>} onClick={() => deleteCard()} width={'full'} marginTop={2} />
            </div>}
        </div>
      )}
    </div>
  )
}

export default PipelineDealflowTypeCard
