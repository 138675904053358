import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Autocomplete } from 'components/ui/atoms/Autocomplete'
import { useToasts } from 'react-toast-notifications'
import Config from 'config'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { SidePanel } from 'components/ui/objects/SidePanel'
import Select from 'components/ui/atoms/Select'

/**
 * @param onSubmit {(type: 'startup' | 'questionnaire' | 'questionnaire_import' | 'pool', data: { application?: any, questionnaireId?:any }) => Promise<void>}
 * @param onFinishImport {() => void}
 * @param onClose
 * @param questionnaire
 * @param showProject
 * @param showQuestionnaire
 * @param showManualQuestionnaireSelector
 * @returns {JSX.Element}
 * @constructor
 */
export const AddStartupsToEventSection = ({
  onSubmit,
  onClose,
  startupsFromPool,
  judges = []
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [selectedStartup, setSelectedStartup] = useState(undefined)
  const [selectedJudges, setSelectedJudges] = useState([])

  const close = () => {
    onClose && onClose()
  }

  const onError = () => {
    addToast(t('form_field_error'), { appearance: 'error', autoDismiss: true })
  }

  const onSubmitFromPool = () => {
    onSubmit(selectedStartup, selectedJudges).finally(() => close())
  }

  const _onSubmit = (values) => {
    if (!selectedStartup) {
      onError()
      setSelectedStartup(null)
    } else {
      onSubmitFromPool()
    }
  }

  const onSelectPoolStartup = (selected) => {
    setSelectedStartup({
      _id: selected._id,
      name: selected.name,
      logo: selected.logo
    })
  }

  return (
      <SidePanel
        width={'1/4'}
        onClose={onClose}
        title={t('register_investment')}
      >
        <div
          style={{ height: '95%', maxHeight: '85vh' }}
          className="h-85height flex flex-col max-h-full"
        >
          <h2 className="font-semibold text-xl mb-2">
            {t('add_startup')}
          </h2>


          <div className={'flex-1 overflow-y-auto p-2 mt-4'}>
            <Autocomplete
              name="startup"
              placeholder={t('search_startup')}
              isClearable={false}
              label={t('add_startup_from_pool_to_event_explanation')}
              required={true}
              options={startupsFromPool}
              error={selectedStartup !== undefined && selectedStartup === null ? { message: t('required_field') } : null}
              onSelect={onSelectPoolStartup}
            />
            {selectedStartup && (
              <>
                <div className="w-full px-1 text-xs mt-2 mb-4 font-semibold">{t('would_you_like_to_add_startup_to_judges')}</div>
                <Select
                  name="judges"
                  label={t('select_judges')}
                  multiSelect={true}
                  required={false}
                  placeholder={t('select_judges')}
                  initialValues={[]}
                  onSelect={(value) => setSelectedJudges(value)}
                  items={judges}
                  isClearable={true}
                />
              </>
            )}
          </div>

          <div className={`flex justify-end mt-4 border-t pt-2 border-separator-${Config.PLATFORM}`}>
            <ButtonMain
              text={t('add_startup')}
              onClick={() => _onSubmit()}
            />
          </div>
        </div>
      </SidePanel>
  )
}
