import { Button } from 'components/ui/atoms/Button'
import {
  IoMdSwitch,
  AiOutlineNumber,
  CgPlayListCheck,
  AiOutlineLink,
  FaPhoneAlt,
  FaCircle,
  FaEnvelope,
  FaCalendarDay,
  FaTasks,
  FaAlignLeft,
  FaMinus,
  FaFileImage,
  FaFilePdf,
  FaExternalLinkAlt,
  FaDownload,
  FaFileExcel,
  FaFile,
  RiPageSeparator,
  FaObjectGroup,
  FaImages
} from 'react-icons/all'
import TranslateUtils from './translate-utils'
import Utils from './utils'
import Config from 'config'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'

export const QuestionsUtils = {
  getIcon: (type, small = false) => {
    const wrapperClass = `${small ? 'w-6 h-6 rounded-lg text-xs' : 'w-14 h-14 rounded-xl'} bg-main-${Config.PLATFORM} flex items-center justify-center`

    switch (type) {
      case 'questions_group':
        return (
          <div className={wrapperClass}>
            <FaObjectGroup className={`inline-block text-white ${small ? 'text-sm' : ''}`} />
          </div>
        )
      case 'separator':
        return (
          <div className={wrapperClass}>
            <RiPageSeparator className={`inline-block text-white ${small ? 'text-sm' : ''}`} />
          </div>
        )
      case 'short_answer':
        return (
          <div className={wrapperClass}>
            <FaMinus className={`inline-block text-white ${small ? 'text-sm' : ''}`} />
          </div>
        )
      case 'long_answer':
        return (
          <div className={wrapperClass}>
            {' '}
            <FaAlignLeft className={`inline-block text-white ${small ? 'text-sm' : ''}`}/>
          </div>
        )
      case 'select':
        return (
          <div className={wrapperClass}>
            <CgPlayListCheck className={`inline-block text-white ${small ? 'text-sm' : 'h-8 w-8'}`}/>
          </div>
        )
      case 'boolean':
        return (
          <div className={wrapperClass}>
            <IoMdSwitch className={`inline-block text-white ${small ? 'text-sm' : 'h-7 w-7'}`}/>
          </div>
        )
      case 'number':
        return (
          <div className={wrapperClass}>
            <AiOutlineNumber className={`inline-block text-white ${small ? 'text-sm' : 'h-7 w-7'}`} />
          </div>
        )
      case 'multiselect':
        return (
          <div className={wrapperClass}>
            <FaTasks className={`inline-block ${small ? 'text-sm' : 'h-7 w-7'} text-white`}/>
          </div>
        )
      case 'date':
        return (
          <div className={wrapperClass}>
            <FaCalendarDay className={`inline-block ${small ? 'text-sm' : 'h-5 w-5'} text-white`} />
          </div>
        )
      case 'url':
        return (
          <div className={wrapperClass}>
            <AiOutlineLink className={`inline-block ${small ? 'text-sm' : 'h-7 w-7'} text-white`} />
          </div>
        )
      case 'phone':
        return (
          <div className={wrapperClass}>
            <FaPhoneAlt className={`inline-block ${small ? 'text-sm' : 'h-5 w-5'} text-white`} />
          </div>
        )
      case 'likert_scale':
        return (
          <div className={wrapperClass}>
            <FaCircle className={`inline-block text-white ${small ? 'text-xs' : 'h-5 w-5'}`} />
          </div>
        )
      case 'email':
        return (
          <div className={wrapperClass}>
            <FaEnvelope className={`inline-block text-white ${small ? 'text-xs' : 'h-5 w-5'}`} />
          </div>
        )
      case 'file_image':
        return (
          <div className={wrapperClass}>
            <FaFileImage className={`inline-block text-white ${small ? 'text-xs' : 'h-5 w-5'}`} />
          </div>
        )
      case 'file_pdf':
        return (
          <div className={wrapperClass}>
            <FaFilePdf className={`inline-block text-white ${small ? 'text-xs' : 'h-5 w-5'}`} />
          </div>
        )
      case 'file_excel':
        return (
          <div className={wrapperClass}>
            <FaFileExcel className={`inline-block text-white ${small ? 'text-xs' : 'h-5 w-5'}`} />
          </div>
        )
      case 'file':
        return (
          <div className={wrapperClass}>
            <FaFile className={`inline-block text-white ${small ? 'text-xs' : 'h-5 w-5'}`} />
          </div>
        )
      case 'image_selector':
        return (
          <div className={wrapperClass}>
            <FaImages className={`inline-block text-white ${small ? 'text-xs' : 'h-5 w-5'}`} />
          </div>
        )
    }
  },

  getAnswer: (question, questionAnswer, t, downloadDocument, lang, groupedIndex = null, limited = false) => {
    if (groupedIndex !== null) {
      questionAnswer = questionAnswer.split('|__|')[groupedIndex]
    }


    if (
      [null, undefined, ''].includes(questionAnswer) ||
      (Array.isArray(questionAnswer) && !questionAnswer.length)
    ) {
      return '-'
    }

    const answer = Array.isArray(questionAnswer)
      ? questionAnswer
      : questionAnswer?.$numberDecimal
        ? parseFloat(questionAnswer.$numberDecimal)
        : `${questionAnswer}`.trim()

    if (question.type === 'boolean') {
      return questionAnswer ? t('yes') : t('no')
    }

    if (question.type === 'url' || question.type === 'email') {
      return (
        <a
          href={`${
            question.type === 'email' ? 'mailto:' : ''
          }${answer}`}
          target="_blank"
          className="text-black font-normal hover:underline" rel="noreferrer"
        >
          {answer}&nbsp;
          <FaExternalLinkAlt className={`inline text-main-${Config.PLATFORM}`} />
        </a>
      )
    }

    if (question.type === 'long_answer') {
      return <pre className="font-sans whitespace-pre-wrap">{limited ? answer.length > 60 ? `${answer.substring(0, 60)}...` : answer : answer}</pre>
    }

    if (question.type === 'select') {
      return (
        <div>
          {TranslateUtils.getTextByLang(
            lang,
            question.options[parseInt(answer)]
          ).length > 30 ? `${TranslateUtils.getTextByLang(
            lang,
            question.options[parseInt(answer)]
          ).substring(0, 30)}...` : TranslateUtils.getTextByLang(
            lang,
            question.options[parseInt(answer)]
          )}
        </div>
      )
    }

    if (question.type === 'multiselect') {
      return answer.split(',').map((answ, index) => {
        return (
          <div key={index}>
            {TranslateUtils.getTextByLang(
              lang,
              question.options[parseInt(answ)]
            ).length > 30 ? `${TranslateUtils.getTextByLang(
              lang,
              question.options[parseInt(answ)]
            ).substring(0, 30)}...` : TranslateUtils.getTextByLang(
              lang,
              question.options[parseInt(answ)]
            )}
          </div>
        )
      })
    }

    if (question.type === 'likert_scale') {
      return (
        <div>
          {TranslateUtils.getTextByLang(lang, question.options[answer - 1])}
        </div>
      )
    }

    if (question.type === 'file_image') {
      return (
        <div>
          <ButtonSecondary
            className="w-auto"
            iconComponent={<FaDownload className="inline-block mr-2" />}
            text={`${t('download')} Image`}
            onClick={() => downloadDocument(answer.split('-|-')[0])}
          />
        </div>
      )
    }

    if (question.type === 'file_pdf') {
      return (
        <div>
          <ButtonSecondary
            className="w-auto"
            iconComponent={<FaDownload className="inline-block mr-2" />}
            text={`${t('download')} PDF`}
            onClick={() => downloadDocument(answer.split('-|-')[0])}
          />
        </div>
      )
    }

    if (question.type === 'file_excel') {
      return (
        <div>
          <ButtonSecondary
            className="w-auto"
            iconComponent={<FaDownload className="inline-block mr-2" />}
            text={`${t('download')} Excel`}
            onClick={() => downloadDocument(answer.split('-|-')[0])}
          />
        </div>
      )
    }

    if (question.type === 'file') {
      return (
        <div>
          <ButtonSecondary
            className="w-auto"
            iconComponent={<FaDownload className="inline-block mr-2" />}
            text={`${t('download')} ${t('file')}`}
            onClick={() => downloadDocument(answer.split('-|-')[0])}
          />
        </div>
      )
    }

    if (question.type === 'image_selector') {
      return (
        <div className='flex'>
          {answer.split(',').map((opt, index) => { return <img key={index} src={question.options[parseInt(opt)]} style={{ height: '100px', marginRight: 6 }}/> })}
        </div>
      )
    }

    return limited ? answer.length > 30 ? `${answer.substring(0, 30)}...` : answer : answer
  }
}
