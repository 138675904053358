import { useTranslation } from 'react-i18next'
import { Modal } from 'components/ui/molecules/Modal'
import moment from 'moment'
import { DataGrid } from 'components/ui/objects/DataGrid'
import Config from 'config'

const StatsModal = ({
  showModal,
  setShowModal,
  documentStats,
  setDocumentStats
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Modal
        showModal={showModal}
        showCloseModal={true}
        onClose={() => {
          setShowModal(null)
          setDocumentStats({})
        }}
        fixedWidth="w-4/5 lg:w-3/4"
      >
        <div className="w-full">
          <h2 className="font-semibold mr-4">{`${t('stats_of')} ${t(
            documentStats.section
          )}`}</h2>

          <div className="mt-6">
            <h4 className={`text-main-${Config.PLATFORM}`}>{`${t('downloads')}: ${
              documentStats.downloads
            }`}</h4>
            <h4 className={`text-main-${Config.PLATFORM}`}>{`${t('previews')}: ${
              documentStats.previews
            }`}</h4>

            {documentStats.guests.length !== 0 && (
              <div className='my-2'>
                <DataGrid
                  wrapperClassName='mt-3'
                  data={documentStats.guests.map((guest, index) => {
                    return {
                      id: index,
                      name: guest.name,
                      downloads: guest.downloads,
                      last_download: guest.last_download
                        ? moment(guest.last_download).format(
                          'YYYY-MM-DD'
                        )
                        : '-',
                      previews: guest.previews,
                      last_preview: guest.last_preview
                        ? moment(guest.last_preview).format(
                          'YYYY-MM-DD'
                        )
                        : '-'
                    }
                  })}
                  bordered={true}
                  compact={true}
                  headers={[
                    {
                      key: 'name',
                      title: t('name')
                    },
                    {
                      key: 'downloads',
                      title: t('downloads')
                    },
                    {
                      key: 'last_download',
                      title: t('last_download')
                    },
                    {
                      key: 'previews',
                      title: t('previews')
                    },
                    {
                      key: 'last_preview',
                      title: t('last_preview')
                    }
                  ]}
              />
            </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default StatsModal
