import Config from 'config'

/**
 * @param onClick
 * @param type
 * @param marginRight
 * @param marginLeft
 * @param icon
 * @param iconComponent
 * @param text
 * @param disabled
 * @returns {JSX.Element}
 * @constructor
 */
export function ButtonCardMain ({
    onClick,
    type,
    marginRight = '0',
    marginLeft = '0',
    icon,
    iconComponent,
    text,
    disabled
  }) {
    return (
      <button
        onClick={onClick}
        type={type}
        disabled={disabled}
        className={
          `text-center block w-auto mr-${marginRight} ml-${marginLeft} py-2 px-4
          text-xs font-semibold text-text-buttons-card-main-${Config.PLATFORM} placeholder-gray bg-bg-buttons-card-main-${Config.PLATFORM} rounded-2xl 
          shadow-soft-white cursor-pointer transition-all duration-500 ease-in-out 
          hover:bg-buttons-card-main-hover-${Config.PLATFORM} hover:text-text-buttons-card-main-${Config.PLATFORM} hover:shadow-hover
          focus:outline-none hover:shadow-inner`
        }
      >
        {icon && (
          <img src={icon} alt="Icon" className={'inline | mr-2 | w-auto'} />
        )}
        {iconComponent && iconComponent}
        {text}
      </button>
    )
}
