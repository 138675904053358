import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import Config from 'config';
import moment from "moment";

import { BannerResponsive } from 'components/ui/atoms/BannerResponsive'
import { BottomText } from 'components/ui/atoms/BottomText'
import { FormTitle } from 'components/ui/atoms/FormTitle'
import { RoutesLinks } from 'components/routes-links'
import { Button } from 'components/ui/atoms/Button'
import { Input } from 'components/ui/atoms/Input'
import { PoweredText } from 'components/ui/atoms/PoweredText'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

const ForgotForm = ({ onSubmit }) => {
  const { t } = useTranslation()

  const ForgotSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('incorrect_format'))
      .required(t('required_field'))
  })

  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ForgotSchema)
  })

  return (
    <section className="flex flex-col justify-center items-center | min-h-screen w-full  | pt-20 sm:pt-0 lg:p-16">
      <div className="max-w-xs mx-4 sm:max-w-md 2xl:max-w-lg">
        <BannerResponsive />
        <div className="flex flex-col">
          <FormTitle formTitle={t('recover_password')} />
          <div className="text-black text-base">
            <p className="pb-2">{t('recover_instructions')}</p>
          </div>
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-4">
              <Input
                reference={register}
                id="email"
                type="email"
                name="email"
                label={t('what_is_your_email') + ': *'}
                placeholder={t('write_email')}
                error={errors.email}
              />
            </div>
            <div className="w-full flex justify-center">
              <ButtonMain
                type={'submit'}
                width="full"
                paddingVertical={3}
                text={t('recover_password')}
              />
            </div>
          </form>
          <div className="w-full text-center mt-4 text-xs">
            <Link to={`${RoutesLinks.LOGIN_LINK}`} className={`underline text-main-${Config.PLATFORM} hover:text-link-hover-${Config.PLATFORM}`}>
              {t('go_to_login')}
            </Link>
          </div>
        </div>
      </div>
      <BottomText text={`${t('copyright')} @${moment().format('YYYY')} ${t('copyright_rights')}`} />
      {Config.PLATFORM !== '000' && <PoweredText />}
    </section>
  )
}

export default ForgotForm
