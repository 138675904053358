import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { Loader } from '../ui/molecules/Loader'
import { StartupGeneralInformation } from '../startup-profile/StartupGeneralInformation'
import { SharedEventHeader } from 'components/events/components/event/components/SharedEventHeader'
import { Card } from 'components/ui/atoms/Card'
import { FaExclamationCircle } from 'react-icons/fa'
import RequestAccessModal from './RequestAccessModal'
import useUser from 'hooks/useUser'
import { DealflowService } from 'services/dealflow-service'
import { useToasts } from 'react-toast-notifications'
import ProjectsService from 'services/projects-service'
import { useSwal } from 'hooks/useSwal'
import Config from 'config';

export const PublicProfile = () => {
  const { t } = useTranslation()
  const { confirm } = useSwal()
  const { startupId } = useParams()
  const { isFounder, isInvestor, user, startupId: founderStartupId, plan } = useUser()
  const { addToast } = useToasts()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [startup, setStartup] = useState(null)
  const [errorRetrievingPublicProfile, setErrorRetrievingPublicProfile] = useState(false)
  const [deal, setDeal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [requestedSection, setRequestSection] = useState(null)

  const getStartup = () => {
    return ProjectsService.getProjectPublicProfile(startupId).then(result => {
      setStartup(result.data)
    }).catch(() => {
      setErrorRetrievingPublicProfile(true)
    })
  }

  const getDeal = () => {
    return DealflowService.getDealByStartupId(startup._id).then(result => {
      setDeal(result.data)
    }).catch(() => {
      addToast(t('error_occurred'), { appearance: 'error', autoDismiss: true })
      history.goBack()
    })
  }

  const requestExternalAccess = (data) => {
    ProjectsService.requestAccess(startup._id, data).then(() => {
      addToast(t('request_received_successfully'), { appearance: 'success', autoDismiss: true })
      setShowModal(false)
    }).catch(() => {
      addToast(t('error_occurred'), { appearance: 'error', autoDismiss: true })
    })
  }

  const requestLimitedInvestorAccess = () => {
    ProjectsService.requestAccess(startup._id, { name: user.name, email: user.email, investor: user.investor._id }).then(() => {
      addToast(t('request_received_successfully'), { appearance: 'success', autoDismiss: true })
      setShowModal(false)
    }).catch(() => {
      addToast(t('error_occurred'), { appearance: 'error', autoDismiss: true })
    })
  }

  const requestDealflowAccess = (deal, section) => {
    setLoading(true)
    DealflowService.requestProfileVisibility(deal._id, section)
      .then(() => {
        addToast(t('dealflow_requested_visibility_success'), {
          appearance: 'success',
          autoDismiss: true
        })
      })
      .finally(() => setLoading(false))
  }

  const requestAccess = (section) => {
    confirm({
      title: t('request_access'),
      text: t('add_startup_to_pool_required', { name: startup.name })
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        return DealflowService.addProjectToPool(startup._id)
          .then(() => {
            addToast(t('startup_added_to_pool_successfully'), { appearance: 'success', autoDismiss: true })
            DealflowService.getDealByStartupId(startup._id).then(result => {
              setDeal(result.data)
              requestDealflowAccess(result.data, section)
            }).catch(() => {
              addToast(t('error_occurred'), { appearance: 'error', autoDismiss: true })
            })
          })
          .catch(() => {
            addToast(t('error_occurred_adding_startup_to_pool'), { appearance: 'error', autoDismiss: true })
          })
          .finally(() => {
            setLoading(false)
          })
      }
    })
  }

  const requestAccessToSection = (section) => {
    if (deal) {
      requestDealflowAccess(deal, section)
    } else if (isInvestor && plan === 'kiota_crm') {
      requestAccess(section)
    } else if (isInvestor) {
      requestLimitedInvestorAccess(section)
    } else {
      setRequestSection(section)
      setShowModal(true)
    }
  }

  useEffect(() => {
    setLoading(true)
    getStartup().finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (isInvestor && startup) {
      setLoading(true)
      getDeal().finally(() => setLoading(false))
    }
  }, [isInvestor, startup])

  return (
    <>
      {!startup && !loading && (
        <Card
          wrapperClassName={`bg-main-${Config.PLATFORM} mt-24 max-w-screen-sm mx-auto`}
          containerClassName="text-center text-white text-lg my-6"
        >
          <div>
            <FaExclamationCircle
              className="text-white inline-block mb-6"
              size={70}
            />
          </div>
          <p>
            {t(errorRetrievingPublicProfile ? 'error_occurred_retrieving_public_profile' : 'public_profile_not_found')}
          </p>
        </Card>
      )}

      {startup &&
        <>
          <SharedEventHeader showLoginButtons={true}/>

          <div className='px-20 py-10'>
            <div className='flex-1 bg-bgOnboardingOne bg-no-repeat bg-right-bottom w-full'>
              <StartupGeneralInformation
                info={startup}
                publicProfile={true}
                requestAccess={requestAccessToSection}
                deal={deal}
                isTeamMember={user && isFounder && founderStartupId === startupId}
              />
            </div>
          </div>
        </>
      }

      <RequestAccessModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleCloseModal={() => setShowModal(false)}
        onSubmit={(values) => requestExternalAccess({ ...values, section: requestedSection })}
      />

      {loading && <Loader />}
    </>
  )
}
