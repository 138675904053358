import { useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import { Loader } from 'components/ui/molecules/Loader'
import { Button } from 'components/ui/atoms/Button'
import { FaTrash } from 'react-icons/fa'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Avatar from 'react-avatar'
import InputFile from 'components/ui/atoms/input-files'
import FormUtils from 'utils/form-utils'
import { Input } from 'components/ui/atoms/Input'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { ButtonDanger } from 'components/ui/atoms/ButtonDanger'
import { Card } from 'components/ui/atoms/Card'
import BAForm from './BAForm'
import GeneralInvestorForm from './GeneralInvestorForm'

const IRMInvestorGeneralInformation = ({ onSubmit, generalInformation, isWizard, onDelete }) => {
    const { t } = useTranslation()
    const [logo, setLogo] = useState(null)
    const [loading, setLoading] = useState(false)

    const { addToast } = useToasts()

    useEffect(() => {
      if (generalInformation && generalInformation.logo) {
        setLogo(generalInformation.logo)
      }
    }, [generalInformation])

    const InvestorSchema = Yup.object().shape({
        company_name: Yup.string().required(t('required_field')),
        manager_name: Yup.string(),
        manager_email: Yup.string()
        .email(t('incorrect_format')),
        web: Yup.string(),
        contact_person: Yup.string(),
        contact_phone_number: Yup.string(),
        contact_email: Yup.string()
        .email(t('incorrect_format'))
    })


    const { register, errors, trigger, getValues, setValue } = useForm({
        resolver: yupResolver(InvestorSchema),
        criteriaMode: 'all',
        reValidateMode: 'all',
        mode: 'onChange'
      })
    
      const onSelectLogo = async file => {
        const fileSizeMb = (file?.size || 0) / 1024 / 1024
    
        if (fileSizeMb > 5) {
          addToast(t('error_file_size', { max_size: 5 }), {
            appearance: 'error',
            autoDismiss: true
          })
          return
        }
    
        setLoading(true)
        setLogo(file ? await FormUtils.fileToBase64(file) : null)
    
        setTimeout(() => setLoading(false), 500)
      }

  return (
    <Card>
    
      {loading && <Loader />}
      {generalInformation?.investor_type === 'ba' && (
        <BAForm
          onDelete={onDelete}
          onSubmit={onSubmit}
          investorType={generalInformation.investor_type}
          isWizard={false}
          initialValues={generalInformation}
        />
      )}
      {generalInformation?.investor_type && generalInformation.investor_type !== 'ba' && (
        <GeneralInvestorForm
          onDelete={onDelete}
          onSubmit={onSubmit}
          investorType={generalInformation.investor_type}
          isWizard={false}
          initialValues={generalInformation}
        />
      )}
    </Card>
  )
}

export default IRMInvestorGeneralInformation
