import axios from 'axios/index'
import Config from '../config'

const KuffService = {
  access: async (accessCode) => {
    return axios.post(
      `${Config.SUPPORT_API_URL}kuff/access`,
      { access_code: accessCode }
    )
  },

  getLatest: async (accessCode) => {
    return axios.get(
      `${Config.SUPPORT_API_URL}kuff/latest?code=${accessCode}`
    )
  },

  start: async (accessCode) => {
    return axios.post(
      `${Config.SUPPORT_API_URL}kuff`,
      { code: accessCode }
    )
  },
}
export default KuffService
