import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../atoms/Button'
import Cross from 'styles/images/cross_blue.svg'
import { useSelector } from 'react-redux'
import { MessageTemplatesService } from 'services/message-templates-service'
import { Spinner } from '../atoms/Spinner'
import { BasicCardMessageTemplate } from '../molecules/BasicCardMessageTemplate'
import { useMessageTemplatesContainer } from 'hooks/useMessageTemplatesContainer'
import { ChatService } from 'services/chat-service'
import { EditCardMessageTemplate } from '../molecules/EditCardMessageTemplate'
import { FaPlus } from 'react-icons/all'
import { useSwal } from 'hooks/useSwal'
import useUser from '../../../hooks/useUser'
import { ButtonMain } from '../atoms/ButtonMain'

export const ChatMessageTemplatesContainer = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { editMode, meta, show } = useSelector(
    (state) => state.app.messageTemplatesContainer
  )
  const { confirm } = useSwal()
  const container = useMessageTemplatesContainer()
  const [loading, setLoading] = useState(false)
  const [templates, setTemplates] = useState([])
  const [category, setCategory] = useState(meta.category || 'startup')
  const [isCreating, setIsCreating] = useState(false)

  const categories = [
    { id: 'startup', name: t('startup') },
    { id: 'entrepreneur', name: t('entrepreneur') }
  ]

  const getTemplates = () => {
    MessageTemplatesService.getTemplates()
      .then((templates) => {
        if (show) {
          setTemplates(templates)
          setLoading(false)
        }
      })
      .catch(() => {
        if (show) {
          setLoading(false)
        }
      })
  }

  const updateTemplate = (template, values) => {
    setLoading(true)
    MessageTemplatesService.updateTemplate(template._id, values)
      .then(() => getTemplates())
      .catch(() => setLoading(false))
  }

  const deleteTemplate = (templateId) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        setLoading(true)
        MessageTemplatesService.deleteTemplate(templateId)
          .then(() => getTemplates())
          .finally(() => setLoading(false))
      }
    })
  }

  const createTemplate = (template) => {
    setLoading(true)
    MessageTemplatesService.createTemplate(template)
      .then(() => {
        setIsCreating(false)
        return getTemplates()
      })
      .finally(() => setLoading(false))
  }

  const sendMessage = (template) => {
    setLoading(true)
    const isGroup = meta.category === 'startup'
    const message = template.message.replace(/{{name}}/gi, meta.target.name)

    ChatService.sendMessage(meta.sendTo, message, isGroup)
      .then(() => {
        /*
      hack for click back button in chat iframe when chat screen with user/group is already opened,
      because do not refresh and message do not show
       */
        const iframe = document.querySelector('#chat-wrapper iframe')
        iframe?.contentWindow.document
          .querySelector('.chat__sidebar-menu')
          ?.click()

        if (show) {
          setLoading(false)
          container.close()
        }

        setTimeout(() => {
          isGroup
            ? ChatService.chatWithGroup(meta.sendTo)
            : ChatService.chatWithUser(meta.sendTo)
        }, 1000)
      })
      .catch(() => {
        if (show) {
          setLoading(false)
        }
      })
  }

  const filterTemplates = () => {
    if (category === 'startup') {
      return templates.filter((template) => template.is_startup)
    } else {
      return templates.filter((template) => template.is_entrepreneur)
    }
  }

  useEffect(() => {
    if (user && show) {
      setLoading(true)
      getTemplates()
    }
  }, [user, show, getTemplates])

  return (
    <div className="fixed top-0 right-0 z-50 h-screen w-screen bg-opacity-25 bg-black">
      <div className="layer-shadow top-0 right-0 absolute w-80 h-screen mb-16 bg-white overflow-auto overscroll-y-auto">
        <div className="w-full flex justify-start items-center">
          <ButtonMain
            onClick={() => container.close()}
            text={t('close')}
            />
        </div>
        <div className="mx-4 my-6">
          <div className="text-xl font-bold">
            {editMode ? t('message_templates') : t('send_message_template')}
          </div>

          {editMode && !isCreating && (
            <div className="text-xs flex justify-start mt-2">
              {categories.map((cat) => (
                <span
                  key={cat.id}
                  className={`${
                    cat.id === category
                      ? 'border-b'
                      : 'text-gray'
                  } cursor-pointer py-1 px-2 mr-2`}
                  onClick={() => setCategory(cat.id)}
                >
                  {cat.name}
                </span>
              ))}
            </div>
          )}

          <div className="mt-6">
            {loading
              ? (
              <div className="text-center">
                <Spinner color="rgb(77 112 179)" />
              </div>
                )
              : (
              <div className="flex flex-wrap justify-start overflow-auto h-auto mt-4">
                {!isCreating &&
                  filterTemplates().map((template) => {
                    return editMode
                      ? (
                      <EditCardMessageTemplate
                        key={template._id}
                        template={template}
                        onSave={(values) => updateTemplate(template, values)}
                        onDelete={() => deleteTemplate(template._id)}
                      />
                        )
                      : (
                      <BasicCardMessageTemplate
                        key={template._id}
                        template={template}
                        onSend={() => sendMessage(template)}
                      />
                        )
                  })}
                {!isCreating && filterTemplates().length === 0 && (
                  <div className="text-center text-gray text-xs shadow-soft-white rounded-2xl p-4 mx-4 mb-4">
                    {t('message_template_empty')}
                  </div>
                )}
                {editMode && isCreating && (
                  <EditCardMessageTemplate
                    onSave={(values) => createTemplate(values)}
                    onCancel={() => setIsCreating(false)}
                    template={{
                      is_startup: category === 'startup',
                      is_entrepreneur: category === 'entrepreneur'
                    }}
                    mode="create"
                  />
                )}
                {editMode && !isCreating && (
                  <>
                    <div className=" mt-2 mb-2 w-full mx-4">
                      <ButtonMain
                        onClick={() => setIsCreating(true)}
                        text={t('add_message_template')}
                        iconComponent={<FaPlus className="inline-block mr-2" />}
                      />
                    </div>
                  </>
                )}
              </div>
                )}
          </div>
        </div>
      </div>
    </div>
  )
}
