import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { IoMdRocket, FaPlus, FaInfoCircle, FaExclamationTriangle, GiPodium, FaEnvelope, FaWpforms } from 'react-icons/all'
import Plus from 'styles/images/plus.svg'
import Config from 'config';
import ReactTooltip from 'react-tooltip'

import { PopoverTrelloList } from 'components/ui/atoms/PopoverTrelloList'

const PipelineList = ({
  editable,
  key,
  ListTitle,
  children,
  addCard,
  editColumn,
  deleteColumn,
  column,
  createColumn,
  newColumn = false,
  items,
  entityToManage
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  return (
    <div>
      {!newColumn ?
         (
          <div
            id={key}
            className={`px-4 pt-4 pb-4 rounded-2xl w-64 mr-2 ${editable && 'cursor-move'} bg-white flex flex-col`}
          >
            <div className="flex w-full justify-between items-center">
              {!deleteColumn && (
                <h6 className="text-base font-semibold">
                  {ListTitle}
                  {/*<FaInfoCircle data-tip={t('stage_not_deleteable')} className='inline w-3 ml-2' />*/}
                  {editable && column?.evaluation_process && <GiPodium data-tip={t('add_to_judge_automation_enabled_tooltip')} className='inline w-4 mb-1 ml-2 text-yellow-600' />}
                </h6>
              )}
              {deleteColumn && (
                <h6 className="text-base font-semibold">
                  {ListTitle}
                  {/*editable && column?.forms?.length > 0 && <FaWpforms data-tip={t('assign_forms_automation_enabled_tooltip')} className='inline w-4 mb-2 ml-2 text-yellow-600' />*/}
                  {editable && column?.evaluation_process && <GiPodium data-tip={t('add_to_judge_automation_enabled_tooltip')} className='inline w-4 mb-2 ml-2 text-yellow-600' />}
                  {editable && (column?.advance_email_automation || column?.end_reached_email_automation || column?.rejection_email_automation || column?.temporarily_discarded_email_automation) && (
                    <FaEnvelope
                      data-tip={
                        column?.advance_email_automation ? t('advance_email_automation_enabled_tooltip')
                        : column?.end_reached_email_automation ? t('end_reached_email_utomation_enabled_tooltip')
                        : column?.temporarily_discarded_email_automation ? t('temporarily_discarded_email_automation_enabled_tooltip')
                        : t('rejection_email_automation_enabled_tooltip')
                      }
                      className='inline w-3 mb-1 ml-2 text-yellow-600' />
                    )}
                </h6>
              )}
              {editable && <PopoverTrelloList
                items={deleteColumn ? ['add_startup', 'edit_stage', 'delete_column'] : ['add_startup', 'edit_stage']}
                methods={{
                  add_startup: () => addCard(),
                  edit_stage: () => {
                    editColumn(column)
                  },
                  delete_column: deleteColumn ? () => deleteColumn(column) : null
                }}
              />}
            </div>

            <div className="flex flex-row my-2">
              <IoMdRocket className={`inline-block text-main-${Config.PLATFORM}`} />
              <div className={'text-xs'}>{`${t('startups')}: ${items}`}</div>
            </div>

            <div className='flex-1'>
              {children}
            </div>

            {editable && <div className="mt-4 w-56 ">
              <button
                onClick={addCard}
                className={` border text-xxs text-text-buttons-secondary-${Config.PLATFORM} flex items-center  outline-none w-56 py-2 pl-2 rounded-2xl focus:outline-none hover:shadow-inner shadow-soft-white bg-white border-border-buttons-secondary-${Config.PLATFORM}`}
              >
                <FaPlus className={`inline-block text-main-${Config.PLATFORM} mr-2`} />
                <span className="font-medium text-xs">{t('add_startup')}</span>
              </button>
              </div>}
          </div>
        )
        : (
          <div className="w-60 h-auto mr-4">
            <button
              onClick={createColumn}
              className={`border border-border-buttons-secondary-${Config.PLATFORM} text-xxs text-gray-dark flex items-center  outline-none w-60 py-4 pl-6 rounded-2xl shadow-hover hover:shadow-inner bg-white focus:outline-none`}
            >
              <FaPlus className={`inline-block text-main-${Config.PLATFORM} mr-2`} />
              <span className="font-medium text-xs block">{t('add_stage')}</span>
            </button>
          </div>
        )}
    </div>
  )
}

export default PipelineList
