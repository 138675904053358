import { useTranslation } from 'react-i18next'
import { SidePanel } from 'components/ui/objects/SidePanel'
import { useState } from 'react'
import Config from 'config'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { Input } from 'components/ui/atoms/Input'
import { useToasts } from 'react-toast-notifications'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'

/**
 * @param onClose
 * @returns {JSX.Element}
 * @constructor
 */
const SearchStartupSection = ({ onClose, onSubmit, initialValues }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const [startupToSearch, setStartupToSearch] = useState(initialValues || '')

  return (
    <>
      <SidePanel
        width={'1/4'}
        onClose={onClose}
        title={t('search_startup')}
      >
        <div className="flex flex-col w-full">
            <div className='w-full'>
            <Input
              name="startup"
              placeholder={t('startup')}
              label={t('startup')}
              type="text"
              value={startupToSearch}
              onChange={(e) => setStartupToSearch(e.target.value)}
            />
            </div>
            <div className={`flex justify-end mt-2 pt-2 border-t border-separator-${Config.PLATFORM}`}>
            <ButtonSecondary
               onClick={async () => {
                onSubmit(null)
              }}
              text={
                t('reset_search')
              }
              marginRight={2}
              />
            <ButtonMain
               onClick={async () => {
                onSubmit(startupToSearch)
              }}
              text={
                t('search_startup')
              }
            />
            </div>
        </div>
      </SidePanel>
    </>
  )
}

export default SearchStartupSection
