import axios from 'axios/index'
import Config from '../config'
import moment from 'moment'

const UserService = {
  getUser: async (userId) => {
    return axios.get(`${Config.USERS_API_URL}${userId}`)
  },

  cancelInvitation: async (investorId, invitationId) => {
    return axios.patch(
      `${Config.USERS_API_URL}/investors/${investorId}/invitations/${invitationId}/cancel`
    )
  },

  deleteInvestorFromProject: async (investorId, projectId) => {
    return axios.delete(
      `${Config.USERS_API_URL}investor/${investorId}/project/${projectId}`
    )
  },

  joinProjectToPortfolio: async (investorId, projectId) => {
    return axios.post(`${Config.USERS_API_URL}${investorId}/join-portfolio`, {
      projectId
    })
  },

  deletePortfolioJoin: async (joinId) => {
    return axios.delete(`${Config.USERS_API_URL}join/${joinId}`)
  },

  getRequests: async (investorId, params) => {
    let query = ''

    if (params.page !== null) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}page=${params.page}`
    }

    if (params.rowsPerPage) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}rowsPerPage=${params.rowsPerPage}`
    }

    return axios.get(
      `${Config.USERS_API_URL}investor/${investorId}/requests${query}`
    )
  },

  getCuratedDealflow: async (investorId, params) => {
    let query = ''

    if (params.page !== null) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}page=${params.page}`
    }

    if (params.rowsPerPage) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}rowsPerPage=${params.rowsPerPage}`
    }

    return axios.get(
      `${Config.USERS_API_URL}investor/${investorId}/curated-dealflow${query}`
    )
  },

  acceptJoinPortfolio: async (joinId) => {
    return axios.patch(`${Config.USERS_API_URL}join/${joinId}/accept`)
  },

  rejectJoinPortfolio: async (joinId) => {
    return axios.patch(`${Config.USERS_API_URL}join/${joinId}/reject`)
  },

  getAvailableInvestors: async (name) => {
    return axios.get(`${Config.USERS_API_URL}available-investor?name=${name}`)
  },

  createInvestmentFilter: async (investorId, filter) => {
    return axios.post(
      `${Config.USERS_API_URL}investors/${investorId}/investment-filters/`,
      filter
    )
  },

  updateInvestmentFilter: async (investorId, filterId, filter) => {
    return axios.patch(
      `${Config.USERS_API_URL}investors/${investorId}/investment-filters/${filterId}`,
      filter
    )
  },

  deleteInvestmentFilter: async (investorId, filterId) => {
    return axios.delete(
      `${Config.USERS_API_URL}investors/${investorId}/investment-filters/${filterId}`
    )
  },

  acceptSuggestion: async (suggestionId) => {
    return axios.patch(
      `${Config.USERS_API_URL}suggestions/${suggestionId}/accept`
    )
  },

  rejectCuratedDealflow: async (suggestionId, data) => {
    return axios.patch(
      `${Config.USERS_API_URL}suggestions/${suggestionId}/reject`,
      data
    )
  },

  getKiotaExpressEvaluation: async () => {
    return axios.get(`${Config.USERS_API_URL}kiota-express-evaluation`)
  },

  /**
   * @param data {{type: 'manual' | 'import', email?: string, founder?: string, startup?: string, invitations?: {email: string, founder: string, startup: string}[]}}
   * @returns {Promise<AxiosResponse<any>>}
   */
  inviteStartup: async (data) => {
    return axios.post(`${Config.USERS_API_URL}invite-startup`, data)
  },

  getNotes: async (entrepreneurId) => {
    return axios.get(`${Config.USERS_API_URL}${entrepreneurId}/notes`)
  },

  createNote: async (entrepreneurId, title, type, content) => {
    return axios.post(`${Config.USERS_API_URL}${entrepreneurId}/notes`, {
      title,
      type,
      content
    })
  },

  deleteNote: async (entrepreneurId, noteId) => {
    return axios.delete(
      `${Config.USERS_API_URL}${entrepreneurId}/notes/${noteId}`
    )
  },

  getNote: async (entrepreneurId, noteId) => {
    return axios.get(
      `${Config.USERS_API_URL}${entrepreneurId}/notes/${noteId}`
    )
  },

  updateNote: async (entrepreneurId, noteId, title, content) => {
    return axios.patch(
      `${Config.USERS_API_URL}${entrepreneurId}/notes/${noteId}`,
      {
        title,
        content
      }
    )
  },

  getEntrepreneurs: async (params) => {
    let query = ''

    if (params.name) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}name=${params.name}`
    }

    if (params.selectedDateType) {
      if (params.selectedDateType === 'custom') {
        if (params.selectedDate) {
          if (params.selectedDate[0]) {
            query = `${query}${query ? '&' : '?'}`
            query = `${query}date_from=${params.selectedDate[0].format(
              'YYYY-MM-DD'
            )}`
          }
          if (params.selectedDate[1]) {
            query = `${query}${query ? '&' : '?'}`
            query = `${query}date_to=${params.selectedDate[1].format(
              'YYYY-MM-DD'
            )}`
          }
        }
      } else {
        const to = moment()
        const from = moment().subtract(params.selectedDateType, 'd')
        query = `${query}${query ? '&' : '?'}`
        query = `${query}date_to=${to.format('YYYY-MM-DD')}`

        query = `${query}${query ? '&' : '?'}`
        query = `${query}date_from=${from.format('YYYY-MM-DD')}`
      }
    }

    if (params.sortBy) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}sortBy=${params.sortBy}`
    }

    if (params.page) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}page=${params.page}`
    }

    if (params.rowsPerPage) {
      query = `${query}${query ? '&' : '?'}`
      query = `${query}rowsPerPage=${params.rowsPerPage}`
    }

    return axios.get(`${Config.USERS_API_URL}filter-entrepreneurs${query}`, {})
  }
}
export default UserService
