import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { RoutesLinks } from 'components/routes-links'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import SupportService from 'services/support-service'
import { EvaluationsMatrix } from 'components/evaluations/EvaluationsMatrix'
import { Button } from 'components/ui/atoms/Button'
import { Loader } from 'components/ui/molecules/Loader'
import { SectionHeader } from 'components/ui/molecules/SectionHeader'
import ReactTooltip from 'react-tooltip'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import { Card } from 'components/ui/atoms/Card'
import { DataGrid } from 'components/ui/objects/DataGrid'
import { FaDownload, FaEye } from 'react-icons/all'
import { Input } from 'components/ui/atoms/Input'
import Papa from 'papaparse'
import ScoreCardUtils from 'utils/score-card-utils'
import { Checkbox } from 'components/ui/molecules/Checkbox'
import { SharedEventHeader } from 'components/events/components/event/components/SharedEventHeader'
import { SharedEventFooter } from 'components/events/components/event/components/SharedEventFooter'
import { EventBasicInfo } from 'components/events/components/event/components/EventBasicInfo'
import { ApplicationQuestionnaireView } from '../../../ui/objects/ApplicationQuestionnaireView'
import { Modal } from '../../../ui/molecules/Modal'
import Lang from '../../../../lang'
import Config from 'config'

export const SharedEventReport = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { eventId, sharedId } = useParams()
  const { addToast } = useToasts()
  const [event, setEvent] = useState(null)
  const [shared, setShared] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingMatrix, setLoadingMatrix] = useState(true)
  const [sections, setSections] = useState(['evaluations', 'pipeline_manager', 'startups'])
  const [section, setSection] = useState('evaluations')
  const [startups, setStartups] = useState([])
  const [filteredStartups, setFilteredStartups] = useState([])
  const [evaluations, setEvaluations] = useState([])
  const [applicationQuestions, setApplicationQuestions] = useState(null)
  const [showDiscarded, setShowDiscarded] = useState(false)

  const loadEventError = (error, messageKey = 'error_occurred_retrieving_event') => {
    console.log(error)
    history.push(RoutesLinks.LOGIN_LINK)
    addToast(t(messageKey), {
      appearance: 'error',
      autoDismiss: true
    })
  }

  const getEvent = () => {
    setLoading(true)

    return SupportService.getEvent(eventId, true)
      .then((result) => {
        const event = result.data.data
        const shared = event.shared.find((s) => s._id === sharedId)

        if (!event || !shared) {
          loadEventError(result.data.error)
          return
        }

        setEvent(event)
        setShared(shared)
      })
      .catch((error) => {
        loadEventError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getEvaluations = () => {
    return SupportService.getEvaluations(
      {
        event: event._id,
        rowsPerPage: 999999
      },
      true
    ).then((result) => {
      setEvaluations(result.data.data.evaluations)
    })
  }

  const getStartups = () => {
    return SupportService.getStartups(
      eventId,
      {
        rowsPerPage: 99999
      },
      true
    ).then((response) => {
      setStartups(response.data.data.startups)
      setFilteredStartups(response.data.data.startups)
    })
  }

  const calculateCriteriaAverage = (criteriaId, row) => {
    const startupEvaluations = evaluations.filter((e) => e.startup?._id === row._id)

    const criteria = event.score_card.criteria.find((c) => c._id === criteriaId)

    const sum = startupEvaluations.reduce((sum, evaluation) => {
      return ScoreCardUtils.getParentScore(criteria, event.score_card.criteria, evaluation.criterias_score) + sum
    }, 0)

    const average = sum / startupEvaluations.length
    return Number.isNaN(average) ? 0 : average.toFixed(2)
  }

  const calculateTotalAverage = (row) => {
    const startupEvaluations = evaluations.filter((e) => e.startup?._id === row._id)

    const average =
      startupEvaluations.reduce((sum, evaluation) => {
        return evaluation.general_score + sum
      }, 0) / startupEvaluations.length

    return Number.isNaN(average) ? 0 : average.toFixed(2)
  }

  const getStartupsHeaders = () => {
    const parentCriteriaList = ScoreCardUtils.getCriteriaParents(event.score_card.criteria)

    return [
      {
        key: 'name',
        title: t('name')
      },
      ...parentCriteriaList.map((criteria) => ({
        key: criteria._id,
        title: criteria.name,
        render: (criteriaId, row) => calculateCriteriaAverage(criteriaId, row)
      })),
      {
        key: 'total',
        title: t('total'),
        render: (criteria, row) => calculateTotalAverage(row)
      }
    ]
  }

  const getDataGridData = () => {
    return filteredStartups
      .filter((s) => {
        if (showDiscarded) {
          return true
        } else {
          return !s.discarded
        }
      })
      .map((s) => ({ ...s, id: s._id }))
  }

  const onSearch = (e) => {
    const search = e.target.value
    setFilteredStartups(
      startups.filter((tag) => {
        return tag.name.toLowerCase().includes(search.toLowerCase())
      })
    )
  }

  const downloadCsv = () => {
    const headers = getStartupsHeaders()
    const fields = getStartupsHeaders().map((header) => header.title)
    const data = filteredStartups.map((startup) => {
      const row = {}

      headers.forEach((header) => {
        if (header.render) {
          row[header.title] = header.render(header.key, startup)
        } else {
          row[header.title] = startup[header.key]
        }
      })

      return row
    })

    const csv = Papa.unparse({ fields, data })
    const link = document.createElement('a')

    link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv))
    link.setAttribute('download', 'startups.csv')
    link.style.display = 'none'

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const viewForm = (row) => {
    setLoading(true)
    return SupportService.getEventStartup(eventId, row._id, true).then((response) => {
      setApplicationQuestions(response.data.data.application?.questions || [])
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    getEvent()
  }, [])

  useEffect(() => {
    if (event && event._id) {
      setLoading(true)
      Promise.all([getEvaluations(), getStartups()])
        .catch((error) => {
          loadEventError(error)
        })
        .finally(() => {
          setTimeout(() => ReactTooltip.rebuild(), 1000)
          setLoading(false)
          setLoadingMatrix(false)
        })
    }
  }, [event])

  useEffect(() => {
    if (event && shared) {
      if (!shared.active) {
        loadEventError(null, 'error_event_shared_report_not_active')
        return
      }

      const sections = []

      if (shared.startups) {
        sections.push('startups')
      }

      if (shared.evaluations) {
        sections.push('evaluations')
      }

      if (shared.pipeline_manager) {
        sections.push('pipeline_manager')
      }

      setSections(sections)
      setSection(sections.length > 0 ? sections[0] : null)
    }
  }, [shared])

  if (loading) {
    return <Loader />
  }

  if (!event || !shared) {
    return null
  }

  return (
    <>
      <div className="flex flex-col min-h-screen">
        <SharedEventHeader />

        <section className="px-8 my-8 flex-1">
          <h1 className={`text-main-${Config.PLATFORM} text-4xl`}>{event.name}</h1>

          <SectionHeader
            sectionKeys={sections}
            sectionTitles={sections.map((section) => t(section))}
            section={section}
            setSection={setSection}
          />

          <div className="my-6">
            {section === 'evaluations' && (
              <>
                <Checkbox
                  label={t('show_discarded')}
                  checked={showDiscarded}
                  onChange={(e) => setShowDiscarded(e.target.checked)}
                />
                <EvaluationsMatrix
                  showDiscardedStartups={showDiscarded}
                  scaleToTen={event.score_card.scale === 'scale_to_ten'}
                  evaluations={evaluations}
                  loading={loadingMatrix}
                  height="40rem"
                />
              </>
            )}

            {section === 'startups' && (
              <>
                <EventBasicInfo event={event} />

                <div className="flex justify-end mb-6">
                  <div className="-mb-5 flex-1 mr-5">
                    <Input placeholder={t('search')} onChange={onSearch} />
                  </div>
                  <Button
                    onClick={() => downloadCsv()}
                    width="auto"
                    text={t('download')}
                    textColor="blue-dark"
                    textSize="xs"
                    verticalMargin="1"
                    iconComponent={<FaDownload className="inline-block mr-3" />}
                    iconWidth="5"
                    vertical="2"
                    horizontal="4"
                  />
                </div>

                <Checkbox
                  label={t('show_discarded')}
                  checked={showDiscarded}
                  onChange={(e) => setShowDiscarded(e.target.checked)}
                />

                <DataGrid
                  compact={true}
                  bordered={true}
                  headers={getStartupsHeaders()}
                  data={getDataGridData()}
                  actions={[
                    {
                      id: 'view_application',
                      tip: t('view_application'),
                      buttonProps: {
                        iconComponent: <FaEye className="inline" />,
                        width: '12',
                        horizontal: '1',
                        vertical: '1',
                        verticalMargin: '0',
                        horizontalMargin: '0'
                      },
                      onClick: (row) => viewForm(row)
                    }
                  ]}
                />
              </>
            )}

            {section === null && (
              <Card>
                <NoDataInfo
                  title={t('event_shared_report_no_sections')}
                  textOne={t('event_shared_report_no_sections_description')}
                />
              </Card>
            )}
          </div>
        </section>

        <SharedEventFooter />
      </div>

      {applicationQuestions && (
        <Modal
          showModal={!!applicationQuestions}
          paddingTop={0}
          paddingBottom={0}
        >
          <div className="w-70width max-h-96 overflow-y-auto mt-6">
            <ApplicationQuestionnaireView
              applicationQuestions={applicationQuestions}
              lang={Lang.getCurrentLang()}
              setLoading={setLoading }
              shared={true}
            />
          </div>

          <div className="flex justify-start">
            <Button
              width="full"
              text={t('close')}
              onClick={() => setApplicationQuestions(null)}
            />
          </div>
        </Modal>
      )}
    </>
  )
}
