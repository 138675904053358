import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'

import { RoutesLinks } from 'components/routes-links'
import { useSelector } from 'react-redux'
import Config from 'config';

export function NotificationsMenu () {
  const history = useHistory()

  const handleRedirect = (url) => {
    history.push(url)
  }
  const notifications = useSelector((state) => state.notifications)

  return (
    <>
      <button
        onClick={() => handleRedirect(RoutesLinks.NOTIFICATIONS_LINK)}
        className={`focus:outline-none custom-circle hover:shadow-inner mr-2 shadow-soft-white border border-border-buttons-secondary-${Config.PLATFORM} w-8 h-8 rounded-full`}
      >
        <FontAwesomeIcon className={`text-text-buttons-secondary-${Config.PLATFORM} w-3`} icon="bell" />
        {notifications.invitations && (
          <span className="inline-block w-1.5 h-1.5 bg-red -mr-1.5 -mb-0.5 rounded-full animate-ping"/>
        )}
      </button>
    </>
  )
}
