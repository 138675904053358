import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'
import { Card } from '../../ui/atoms/Card'
import Select from '../../ui/atoms/Select'
import useUser from '../../../hooks/useUser'
import Utils from '../../../utils/utils'
import Config from 'config';

export const StartupEditYearMonthsInput = ({
  title,
  description,
  yearsData = {},
  symbol = '€',
  maxValue,
  onChange
}) => {
  const selectName = useMemo(() => Math.random().toString(36).substring(2, 9), [])
  const { t } = useTranslation()
  const { userLanguage } = useUser()
  const currentYear = (new Date()).getFullYear()
  const [year, setYear] = useState(currentYear)
  const [newYearsData, setNewYearsData] = useState({})
  const yearList = Array(5)
    .fill(0)
    .map((_, i) => ({ id: currentYear - i, value: currentYear - i }))

  const onChangeValue = (month, value) => {
    const newValue = parseFloat(maxValue ? Math.min(value, maxValue) : value)
    const _newYearData = { ...newYearsData, [year]: { ...newYearsData[year], [month]: newValue } }
    setNewYearsData(_newYearData)
    onChange && onChange(_newYearData)
  }

  const getValue = (month) => {
    return (newYearsData[year] && newYearsData[year][month]) || ''
  }

  useEffect(() => {
    setNewYearsData(yearsData)
  }, [yearsData])

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [newYearsData])

  return (
    <Card>
      <div className='flex justify-between items-center'>
        <h3 className={`text-main-${Config.PLATFORM} text-xl`}>
          {title}
        </h3>

        <Select
          name={selectName}
          placeholder={t('select_year')}
          isClearable={false}
          items={yearList}
          initialValues={[yearList.find(item => item.id === year)]}
          onSelect={selection => setYear(selection[0].id)}
          className='-mb-2 sm:-mb-5'
        />
      </div>

      {description && (
        <div className='text-gray-400 italic text-sm mt-3'>
          {description}
        </div>
      )}

      <div className='grid grid-cols-4 gap-1 mt-3'>
        {Array(12).fill(0).map((_, i) => (
          <div key={i} className={`flex items-center rounded overflow-hidden border-2 border-gray-300 hover:border-main-${Config.PLATFORM}`}>
            <input
              min='0'
              type='number'
              data-tip={Utils.getMonthName(userLanguage, i)}
              placeholder={Utils.getMonthName(userLanguage, i)}
              className='w-full h-full px-2 py-1 focus:outline-none'
              value={getValue(i + 1)}
              onChange={e => onChangeValue(i + 1, e.target.value)}
            />
            {symbol && (
              <div className='flex items-center bg-gray-lines py-1 px-2 font-semibold whitespace-nowrap h-full'>
                {symbol}
              </div>
            )}
          </div>
        ))}
      </div>
    </Card>
  )
}
