import React, { useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import { CoverBackground } from 'components/ui/atoms/CoverBackground'
import { Container } from 'components/ui/objects/Container'
import { RoutesLinks } from 'components/routes-links'
import ForgotForm from './components/forgot-form'
import AuthService from 'services/auth-service'

const Forgot = () => {
  const { addToast } = useToasts()

  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [emailSent, setEmailSent] = useState(false)

  const handleSubmit = async ({ email }) => {
    try {
      setEmail(email)
      await AuthService.forgotPassword(email)
      setEmailSent(true)
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t('error_occurred'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    }
  }

  if (emailSent) {
    return (
      <Redirect
        to={{
          pathname: RoutesLinks.FORGOT_RESEND_LINK,
          state: { email }
        }}
      />
    )
  } else {
    return (
      <Container variant="session">
        <Container>
          <ForgotForm onSubmit={handleSubmit} />
        </Container>
        <CoverBackground link={true}/>
      </Container>
    )
  }
}

export default Forgot
