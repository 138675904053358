import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ChecksOperations } from '../../redux/checks'
import { Redirect, useHistory } from 'react-router-dom'
import { RoutesLinks } from '../routes-links'
import FirstStep from './components/first-step'
import SecondStep from './components/second-step'
import ThirdStep from './components/third-step'
import FourthStep from './components/fourth-step'

const OnBoarding = () => {
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const checked = useSelector((state) => state.checks.checked)
  const stage = useSelector((state) => state.checks.stage)

  const { token } = auth

  const history = useHistory()
  const handleRedirect = (url) => history.push(url)

  const nextStep = () => {
    if (stage === 'ON_BOARDING_FIRST_STEP') {
      dispatch(ChecksOperations.setStage('ON_BOARDING_SECOND_STEP'))
    } else if (stage === 'ON_BOARDING_SECOND_STEP') {
      dispatch(ChecksOperations.setStage('ON_BOARDING_FOURTH_STEP'))
    }
    /* if (stage === 'ON_BOARDING_FIRST_STEP') {
			dispatch(ChecksOperations.setStage('ON_BOARDING_SECOND_STEP'));
		} else if (stage === 'ON_BOARDING_SECOND_STEP') {
			dispatch(ChecksOperations.setStage('ON_BOARDING_THIRD_STEP'));
		} else if (stage === 'ON_BOARDING_THIRD_STEP') {
			dispatch(ChecksOperations.setStage('ON_BOARDING_FOURTH_STEP'));
		} */
  }

  const previousStep = () => {
    if (stage === 'ON_BOARDING_SECOND_STEP') {
      dispatch(ChecksOperations.setStage('ON_BOARDING_FIRST_STEP'))
    } else if (stage === 'ON_BOARDING_THIRD_STEP') {
      dispatch(ChecksOperations.setStage('ON_BOARDING_SECOND_STEP'))
    } else if (stage === 'ON_BOARDING_FOURTH_STEP') {
      dispatch(ChecksOperations.setStage('ON_BOARDING_SECOND_STEP'))
    }
    /* if (stage === 'ON_BOARDING_SECOND_STEP') {
			dispatch(ChecksOperations.setStage('ON_BOARDING_FIRST_STEP'));
		} else if (stage === 'ON_BOARDING_THIRD_STEP') {
			dispatch(ChecksOperations.setStage('ON_BOARDING_SECOND_STEP'));
		} else if (stage === 'ON_BOARDING_FOURTH_STEP') {
			dispatch(ChecksOperations.setStage('ON_BOARDING_THIRD_STEP'));
		} */
  }

  const finishOnBoarding = () => {
    dispatch(ChecksOperations.setStage('ON_BOARDING_FINISHED'))
    handleRedirect(RoutesLinks.DASHBOARD_LINK)
  }

  if (!token) {
    return <Redirect to={RoutesLinks.LOGIN_LINK} />
  } else if (checked) {
    return <Redirect to={RoutesLinks.DASHBOARD_LINK} />
  } else {
    return (
			<section>
				{stage === 'ON_BOARDING_FIRST_STEP' && <FirstStep nextStep={nextStep} />}
				{stage === 'ON_BOARDING_SECOND_STEP' && <SecondStep nextStep={nextStep} previousStep={previousStep} />}
				{stage === 'ON_BOARDING_THIRD_STEP' && <ThirdStep nextStep={nextStep} previousStep={previousStep} />}
				{stage === 'ON_BOARDING_FOURTH_STEP' && <FourthStep nextStep={finishOnBoarding} previousStep={previousStep} />}
			</section>

    //  <section>
    // {stage === "ON_BOARDING_FIRST_STEP" ? (
    //   <FirstStep nextStep={nextStep} />
    // ) : (
    //   <SecondStep nextStep={finishOnBoarding} previousStep={previousStep} />
    // )}
    // </section>
    )
  }
}

export default OnBoarding
