import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import { Loader } from 'components/ui/molecules/Loader'
import { SectionHeader } from 'components/ui/molecules/SectionHeader'
import { PageTitle } from 'components/ui/atoms/PageTitle'
import IRMInvestorGeneralInformation from './IRMInvestorGeneralInformation'
import { InvestorInvestmentThesis } from 'components/investment-thesis/InvestorInvestmentThesis'
import { useParams } from 'react-router-dom'
import IrmService from 'services/irm-service'
import { RoutesLinks } from 'components/routes-links'
import { useHistory } from 'react-router-dom'
import { pick } from 'lodash'
import { Button } from 'components/ui/atoms/Button'
import NotesContainer from 'components/ui/objects/NotesContainer'
import { useSwal } from 'hooks/useSwal'
import SupportService from "services/support-service"
import useUser from 'hooks/useUser'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { TbNotes } from 'react-icons/tb'

const IRMInvestor = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { investorId } = useParams()
  const history = useHistory()
  const { user } = useUser()

  const handleRedirect = (url) => history.push(url)
  const [showNotesContainer, setShowNotesContainer] = useState(false)
  const { confirm } = useSwal()

  const isWizard = investorId === 'wizard'
  const [loading, setLoading] = useState(!isWizard)
  const [notes, setNotes] = useState([])
  const [section, setSection] = useState('general_information')
  const [thesis, setThesis] = useState({})
  const [selectedNote, setSelectedNote] = useState(null)

  const SECTIONS = isWizard ? ['general_information'] : ['general_information', 'investment_thesis']

  const [generalInformation, setGeneralInformation] = useState(null)

  const saveInvestor = async (values) => {
    confirm({
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          await IrmService.saveInvestor(investorId, values)
          addToast(t('investor_updated_successfully'), {
            appearance: 'success',
            autoDismiss: true
          })
        } catch (error) {
          console.error(error)
          addToast(t('error_occurred_updating_investor'))
        } finally {
          setLoading(false)
        }
      }
    })
  }

  const deleteInvestor = () => {
    confirm({
      text: t('delete_investor_description')
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true)
          await IrmService.deleteInvestor(investorId)
          addToast(t('investor_deleted_successfully'), {
            appearance: 'success',
            autoDismiss: true
          })
          handleRedirect(RoutesLinks.INVESTORS_RELATIONS_MANAGER_LINK)
        } catch (error) {
          console.log(error)
          addToast(
            error.response && error.response.data.msg_key
              ? t(error.response.data.msg_key)
              : t('error_occurred_deleting_investor'),
            {
              appearance: 'error',
              autoDismiss: true
            }
          )
        } finally {
          setLoading(false)
        }
      }
    })
  }

  const COMPONENTS = {
    general_information: <IRMInvestorGeneralInformation
      onDelete={deleteInvestor}
      onSubmit={(values) => {
        setGeneralInformation(values)
        saveInvestor(values)
      }}
      generalInformation={generalInformation}/>,
    investment_thesis: <InvestorInvestmentThesis thesis={thesis} irm={true} onSubmit={(values) => saveInvestor({ investment_thesis: { ...values, lead_investor: values.lead_investor?.length && values.lead_investor[0] ? values.lead_investor[0] : null, follow_on: values.follow_on?.length && values.follow_on[0] ? values.follow_on[0] : null } })} />
  }

  const getIrmInvestor = async () => {
    IrmService.getInvestor(investorId).then((result) => {
     if (result.data.data) {
       setGeneralInformation(
        pick(
          result.data.data,
          [
            'ba',
            'ba_email',
            'ba_image',
            'ba_web',
            'ba_linkedin',
            'ba_phone_number',
            'company',
            'company_logo',
            'company_web',
            'company_linkedin',
            'manager',
            'manager_email',
            'manager_linkedin',
            'manager_phone_number',
            'contact',
            'contact_email',
            'contact_phone_number',
            'logo',
            'investor_type'
          ]))
       setThesis(result.data.data.investment_thesis)
      } else {
      handleRedirect(RoutesLinks.INVESTORS_RELATIONS_MANAGER_LINK)
     }
    }).catch((error) => addToast(t('error_occurred_retrieving_information'), {
      appearance: 'error',
      autoDismiss: true
    })).finally(() => setLoading(false))
  }

  const getNotes = async () => {
    try {
      setLoading(true)
      const result = await SupportService.getNotes({ irm_investor: investorId })
      setNotes(result.data)
    } catch (error) {
      addToast(t('error_occurred_retrieving_notes'), {
        appearance: 'error',
        autoDismiss: true
      })
    } finally {
      setLoading(false)
    }
  }

  const createNote = async (title, type, content) => {
    try {
      setLoading(true)
      await SupportService.createNote({
        user: user._id,
        title,
        content,
        type,
        irm_investor: investorId
      })
      getNotes()
    } catch (error) {
      addToast(t('error_occurred_creating_note'), {
        appearance: 'error',
        autoDismiss: true
      })
    } finally {
      setLoading(false)
    }
  }

  const updateNote = async (title, content) => {
    try {
      setLoading(true)
      await SupportService.updateNote(selectedNote._id, { title, content })
      getNotes()
    } catch (error) {
      addToast(t('error_occurred_updating_note'), {
        appearance: 'error',
        autoDismiss: true
      })
    } finally {
      setLoading(false)
    }
  }

  const deleteNote = async (noteId) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true)
          await SupportService.deleteNote(noteId)
          getNotes()
        } catch (error) {
          addToast(t('error_occurred_deleting_note'), {
            appearance: 'error',
            autoDismiss: true
          })
        } finally {
          setLoading(false)
        }
      }
    })
  }

  const getNote = async (noteId) => {
    try {
      setLoading(true)
      const result = await SupportService.getNote(noteId)
      setSelectedNote(result.data)
    } catch (error) {
      addToast(t('error_occurred_retrieving_note'), {
        appearance: 'error',
        autoDismiss: true
      })
    } finally {
      setLoading(false)
    }
  }


  useEffect(() => {
    if (!isWizard) {
      getIrmInvestor()
      getNotes()
    }
  }, [isWizard])

  return (
    <>
      <div className='flex'>
        <div className='flex-1'>
          <PageTitle
            title={generalInformation ? generalInformation.company || generalInformation.ba : t('loading')}
            removeMargin={true}
            showBack={true}
            onBackClick={() => handleRedirect(RoutesLinks.INVESTORS_RELATIONS_MANAGER_LINK)}
          />
        </div>
        {!isWizard && <div>
          <ButtonSecondary
            iconComponent={<TbNotes className="inline-block mr-3 w-4 h-4" />}
            text={t('notes')}
            onClick={() => setShowNotesContainer(true)}
          />
        </div>}
      </div>

      <div className='my-6'>
        <SectionHeader
          section={section}
          setSection={setSection}
          sectionKeys={SECTIONS}
          sectionTitles={SECTIONS.map((s) => t(s))}
        />

      </div>

      {showNotesContainer && (
        <NotesContainer
          zIndex="20"
          listTitle={t('notes')}
          setFiltersButtons={false}
          setShowModal={setShowNotesContainer}
          notes={notes}
          createNote={createNote}
          updateNote={updateNote}
          deleteNote={deleteNote}
          getNote={getNote}
          selectedNote={selectedNote}
          setSelectedNote={setSelectedNote}
          onClose={() => setShowNotesContainer(false)}
        />
      )}

      <div className='my-6'>
        {COMPONENTS[section]}
      </div>
    </>
  )
}

export default IRMInvestor
