import React, { useEffect, useState } from 'react'
import { Card } from '../../ui/atoms/Card'
import { Button } from '../../ui/atoms/Button'
import { DataGrid } from '../../ui/objects/DataGrid'
import { FaCheckCircle, FaEdit, FaTimesCircle, FaTrash } from 'react-icons/all'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Modal } from '../../ui/molecules/Modal'
import { Input } from '../../ui/atoms/Input'
import ProjectsService from '../../../services/projects-service'
import { useToasts } from 'react-toast-notifications'
import { useSwal } from '../../../hooks/useSwal'
import { Loader } from '../../ui/molecules/Loader'
import Roles from '../../../assets/json/roles.json'
import Select from '../../ui/atoms/Select'
import Avatar from 'react-avatar'
import Linkedin from 'assets/images/linkedin.png'
import FormUtils from 'utils/form-utils'
import InputFile from 'components/ui/atoms/input-files'
import useUser from 'hooks/useUser'
import Config from 'config';
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'

export const StartupEditTeamMembers = ({ startup, onChange }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { confirm } = useSwal()
  const [teamMembers, setTeamMembers] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [memberToEdit, setMemberToEdit] = useState(null)
  const [loading, setLoading] = useState(false)
  const [roles, setRoles] = useState([])
  const {user} = useUser()

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    reset,
    trigger,
    getValues,
    watch
  } = useForm({
    resolver: yupResolver(Yup.object().shape({
      name: Yup.string().required(t('required_field')),
      last_name: Yup.string().nullable(),
      role: Yup.string().nullable(),
      percentage: Yup.number()
        .typeError(t('incorrect_format'))
        .transform((_, val) => val === `${Number(val)}` ? Number(val) : null)
        .nullable()
        .min(0)
        .max(100),
      image: Yup.string().nullable(),
      linkedin: Yup.string().nullable(),
      email: Yup.string()
        .required(t('required_field'))
        .notOneOf(
          teamMembers
            .map(s => s.email)
            .filter(email => {
              if (!memberToEdit) return true
              return email !== memberToEdit.email
            }),
          t('startup_member_already_invited')
        )
    }), { abortEarly: false }),
    criteriaMode: 'all',
    reValidateMode: 'all',
    mode: 'all',
    shouldUnregister: false
  })

  const showCreateFormModal = () => {
    setShowModal(true)
  }

  const showEditFormModal = member => {
    setMemberToEdit(member)
    setShowModal(true)
    setValue('image', member.image)
    setValue('email', member.email)
    setValue('name', member.name)
    setValue('last_name', member.last_name)
    setValue('role', member.role?._id)
    setValue('percentage', member.percentage)
    setValue('linkedin', member.linkedin)
    trigger()
  }

  const closeModal = () => {
    setShowModal(false)
    setMemberToEdit(null)
    reset()
    trigger()
  }

  const onSelectImage = async file => {
    const fileSizeMb = (file?.size || 0) / 1024 / 1024

    if (fileSizeMb > 5) {
      addToast(t('error_file_size', { max_size: 5 }), {
        appearance: 'error',
        autoDismiss: true
      })
      return
    }

    setLoading(true)

    setValue('image', file ? await FormUtils.fileToBase64(file) : null, { shouldValidate: true })
    trigger('image')

    setTimeout(() => setLoading(false), 500)
  }

  const onError = error => {
    addToast(
      error.response && error.response.data.msg_key
        ? t(error.response.data.msg_key)
        : t('error_occurred'),
      {
        appearance: 'error',
        autoDismiss: true
      }
    )
  }

  const onSuccess = messageKey => {
    closeModal()
    onChange && onChange()
    addToast(t(messageKey), {
      appearance: 'success',
      autoDismiss: true
    })
  }

  const onSubmit = () => {
    handleSubmit((formData) => {
      const restOfPercentage = teamMembers
        .filter(member => memberToEdit ? member._id !== memberToEdit._id : true)
        .reduce((acc, member) => acc + (member.percentage || 0), 0)

      if (restOfPercentage + (formData.percentage || 0) > 100) {
        addToast(t('percentage_overload'), {
          appearance: 'error',
          autoDismiss: true
        })

        return
      }

      if (!memberToEdit) {
        confirm().then(isConfirmed => {
          if (isConfirmed) {
            setLoading(true)
            ProjectsService.addMember(startup._id, formData)
              .then(() => onSuccess('member_added_successfully'))
              .catch(error => onError(error))
              .finally(() => setLoading(false))
          }
        })
      } else {
        setLoading(true)
        ProjectsService.updateMember(startup._id, formData, memberToEdit._id)
          .then(() => onSuccess('member_updated_successfully'))
          .catch(error => onError(error))
          .finally(() => setLoading(false))
      }
    }, () => {
      addToast(t('form_field_error'), { appearance: 'error', autoDismiss: true })
    })()
  }

  const deleteMember = member => {
    confirm().then(isConfirmed => {
      if (isConfirmed) {
        setLoading(true)
        ProjectsService.deleteMember(startup._id, member._id)
          .then(() => onSuccess('member_deleted_successfully'))
          .catch(error => onError(error))
          .finally(() => setLoading(false))
      }
    })
  }

  const getRoles = () => {
    const _roles = Roles.map(item => ({ value: t(item.key), id: item._id }))
    setRoles(_roles)
  }

  const getRoleInitialValue = () => {
    if (watch().role) {
      const _role = roles.find(role => role.id === watch().role)
      return _role ? [_role] : []
    }

    if (memberToEdit && memberToEdit.role) {
      const _role = roles.find(role => role.id === memberToEdit.role?._id)
      return _role ? [_role] : []
    }

    return []
  }

  useEffect(() => {
    if (startup) {
      setTeamMembers(startup.founders || [])
    }
  }, [startup])

  useEffect(() => {
    register('role')
  }, [register()])

  useEffect(() => {
    getRoles()
  }, [])

  const parseMember = (member) => {
    return {
      name: member.user
        ? member.user.last_name
          ? `${member.user.name} ${member.user.last_name}`
          : member.user.name
        : `${member.name} ${member.last_name}`,
      role: member.role ? member.role : null,
      percentage:
        member.percentage !== null &&
        member.percentage !== undefined
          ? member.percentage
          : null,
      email: member.user ? member.user.email : member.email ? member.email : null,
      image: member.user && member.user.image ? member.user.image : member.image ? member.image : null,
      linkedin: member.user && member.user.linkedin ? member.user.linkedin : member.linkedin ? member.linkedin : null,
      _id: member._id,
      user: member.user
    }
  }

  return (
    <>
      <div className="w-full mt-6 mb-8">
        <Card containerClassName="flex flex-col lg:flex-row lg:justify-between p-6 text-2">
          <div className="md:w-full lg:w-10/12">
            <h3 className={`text-main-${Config.PLATFORM}`}>
              {t('would_you_like_to_invite_startup_members')}
            </h3>
            <p className="text-xs">{t('invite_startup_members_explanation')}</p>
          </div>
          <div className="md:w-full lg:w-2/12 justify-end flex text-center">
            <ButtonMain
              width="1/2 lg:w-full"
              text={t('invite_startup_members')}
              onClick={() => showCreateFormModal()}
            />
          </div>
        </Card>
      </div>

      {teamMembers.length !== 0 && (
        <DataGrid
          bordered={true}
          data={teamMembers.map(m => (parseMember(m)))}
          headers={[
            {
              title: t('name'),
              key: 'name',
              render: (key, member) => {
                return <div className='flex'>
                  <Avatar
                    src={member.image}
                    size="30"
                    round={true}
                    color="#e0e6f2"
                    fgColor="#4d70b3"
                    alt={member.name}
                  />
                  <span className="ml-2 flex items-center">{`${member.name}`.trim()}</span>
                </div>
              }
            },
            {
              title: t('email'),
              key: 'email',
              render: (key, member) => member.email || t('no_data')
            },
            {
              title: t('role'),
              key: 'role',
              render: (key, member) => member.role ? t(member.role.key) : t('no_data')
            },
            {
              title: t('equity'),
              key: 'percentage',
              render: (key, member) => {
                return member.percentage !== null && member.percentage >= 0 ? `${member.percentage}%` : t('no_data')
              }
            },
            {
              title: t('linkedin'),
              key: 'linkedin',
              render: (key, member) => {
                return <div className='flex justify-center flex-row'>
                  <a
                    target='_blank'
                    href={member.linkedin}
                    rel="noreferrer"
                    data-tip={!member.linkedin || member.linkedin === '' ? null : t('linkedin')}
                    className={!member.linkedin || member.linkedin === '' ? 'opacity-25 filter grayscale' : ''}
                  >
                    <img src={Linkedin} alt={t('linkedin')} className="w-8" />
                  </a>
                </div>
              }
            },
            {
              title: t('registered'),
              key: 'user',
              render: (key, member) => (
                <>
                  {member.user && <FaCheckCircle className="text-green" />}
                  {!member.user && <FaTimesCircle className="text-red" />}
                </>
              )
            }
          ]}
          actions={[
            {
              id: 'edit',
              tip: t('edit'),
              onClick: (member) => showEditFormModal(member),
              buttonProps: {
                iconComponent: <FaEdit className="inline-block" />,
                className: 'w-12',
                horizontal: '1',
                vertical: '1',
                verticalMargin: '0',
                horizontalMargin: '0',
                textColor: `buttons-card-text-${Config.PLATFORM}`,
                bgColor: `buttons-card-${Config.PLATFORM}`,
                bgHoverColor: `buttons-hover-${Config.PLATFORM}`
              }
            },
            {
              id: 'delete',
              tip: t('delete'),
              onClick: (member) => deleteMember(member),
              show: (member) => !member.user || member.user._id !== user._id,
              buttonProps: {
                iconComponent: <FaTrash className="inline-block" />,
                className: 'text-red w-12',
                horizontal: '1',
                vertical: '1',
                verticalMargin: '0',
                horizontalMargin: '0'
              }
            }
          ]}
        />
      )}
      {teamMembers.length === 0 && (
        <div className='my-10'><NoDataInfo title={t('no_data_registered')} /></div>
      )}

      <Modal showModal={showModal} showCloseModal={true} onClose={closeModal} paddingBottom={4}>
        <div style={{ width: 600 }}>
        <h2 className={`font-semibold text-xl my-4  text-main-${Config.PLATFORM}`}>
          {t('invite_startup_members')}
        </h2>
          <div className="w-1/3 lg:w-1/6">
            {getValues().image ? (
              <>
                <div className="text-xs font-medium text-black">
                  {t('photo')}
                </div>
                <div className="mt-2 flex flex-col justify-center w-32">
                  <Avatar
                    src={getValues().image}
                    size="80"
                    round={true}
                    color="#e0e6f2"
                    fgColor="#4d70b3"
                    className="mx-auto my-0"
                  />
                  <Button
                    type="button"
                    iconComponent={<FaTrash className="inline-block mr-2" />}
                    text={t('remove')}
                    width='auto'
                    bgColor='red'
                    className="text-white max-h-[24px]"
                    verticalMargin="1"
                    vertical="1"
                    textSize="xs"
                    onClick={() => onSelectImage(null)}
                  />
                </div>
              </>
            ) : (
              <div className="w-40">
                <InputFile
                  label={t('image')}
                  placeholder={t('select_photo')}
                  error={errors.image}
                  setSelectedFile={(file) => onSelectImage(file)}
                  accept="image/*"
                  height="120px"
                  padding="18px"
                />
              </div>
            )}
          </div>
          <div className="grid grid-cols-2 gap-4 mt-4">
            <Input
              name="name"
              label={t('name')}
              placeholder={t('name')}
              error={errors.name}
              required={true}
              reference={register}
            />
            <Input
              name="last_name"
              label={t('last_name')}
              placeholder={t('last_name')}
              error={errors.last_name}
              reference={register}
            />
          </div>
          <Input
            name="email"
            label={t('email')}
            placeholder={t('email')}
            error={errors.email}
            required={true}
            reference={register}
          />
          <div className="grid grid-cols-2 gap-4">
            <Select
              name="role"
              label={t('role')}
              items={roles}
              error={errors.role}
              initialValues={getRoleInitialValue()}
              onSelect={selected => {
                setValue('role', selected.length ? selected[0].id : null)
                trigger()
              }}
            />
            <Input
              name="percentage"
              label={`${t('equity')} (%)`}
              placeholder={`${t('equity')} (%)`}
              error={errors.percentage}
              reference={register}
            />
          </div>

          <Input
            name="linkedin"
            label={t('linkedin')}
            placeholder={t('linkedin')}
            error={errors.linkedin}
            required={false}
            reference={register}
          />

<div className={`flex justify-end mt-4 border-t pt-2 border-separator-${Config.PLATFORM}`}>
            <ButtonMain
              text={t(memberToEdit ? 'update' : 'create')}
              onClick={() => onSubmit()}
            />
          </div>
        </div>
      </Modal>

      {loading && <Loader />}
    </>
  )
}
