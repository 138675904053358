import { Card } from '../../../ui/atoms/Card'
import { Button } from '../../../ui/atoms/Button'
import { NoDataInfo } from '../../../ui/atoms/NoDataInfo'
import { FaDownload, FaExternalLinkAlt, FaGripHorizontal, FaListAlt, FaTags, FaTrashAlt } from 'react-icons/fa'
import { Document } from '../../../ui/atoms/Document'
import { DataGrid } from '../../../ui/objects/DataGrid'
import { DocumentIcon } from './DocumentIcon'
import Avatar from 'react-avatar'
import moment from 'moment/moment'
import { Pagination } from '../../../ui/atoms/Pagination'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import SupportService from '../../../../services/support-service'
import ReactTooltip from 'react-tooltip'
import DocumentsFilters from './DocumentsFilters'
import { useSwal } from '../../../../hooks/useSwal'
import TagsService from '../../../../services/tags-service'
import { isEqual } from 'lodash'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import { ManageTagsModal } from '../../../tags/ManageTagsModal'
import { DealflowService } from '../../../../services/dealflow-service'
import { Loader } from '../../../ui/molecules/Loader'
import { TagListCircles } from '../../../tags/TagListCircles'
import { Modal } from '../../../ui/molecules/Modal'
import { ProgressBar } from '../../../ui/atoms/ProgressBar'

import Config from 'config';
import { UploadFileSection } from './UploadFileSection'

export const DocumentList = forwardRef(({ dealId, shared = false }, ref) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { confirm } = useSwal()
  const [showFilters, setShowFilters] = useState(false)
  const [filters, setFilters] = useState({ startup: dealId })
  const [dealflow, setDealflow] = useState([])

  const [viewMode, setViewMode] = useState('list')
  const [showUploadDocumentSection, setShowUploadDocumentSection] = useState(false)
  const [startup, setStartup] = useState(null)
  const [documents, setDocuments] = useState([])
  const [tags, setTags] = useState([])
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 12,
    totalPages: 0,
    totalDocs: 0
  })
  const [showManageTagsModal, setShowManageTagsModal] = useState(false)
  const [documentToEdit, setDocumentToEdit] = useState(null)
  const [uploadingPercentage, setUploadingPercentage] = useState(0)
  const headerClass = `text-main-${Config.PLATFORM} font-bold text-sm border-b border-main-${Config.PLATFORM} text-left bg-white`;

  const uploadDocument = async (document, tags, selectedStartup, entityToAssignDocument) => {
    const formData = new FormData()
    formData.append('document', document)

    if (tags) {
      formData.append('tags', tags)
    }

    if (entityToAssignDocument === 'startup') {
      if (selectedStartup) {
        setStartup(selectedStartup)
        formData.append('startup', selectedStartup)
      }
    }

    return SupportService.uploadDocument(formData)
      .then(() => setStartup(null))
      .catch((error) => {
        addToast(
          error?.response?.data?.msg_key ? t(error.response.data.msg_key) : t('error_occurred_uploading_document'),
          {
            appearance: 'error',
            autoDismiss: true
          }
        )
      })
  }

  const uploadDocuments = async (documentType, documents, tags, selectedStartup, link, name, entityToAssignDocument) => {
    if (documentType === 'link') {
      setLoading(true)
      const data = { name, link, tags }
      if (entityToAssignDocument === 'startup') {
        data.startup = selectedStartup
      }

      SupportService.uploadLink(data)
        .then(() => getDocuments())
        .catch((error) => addToast(
          error?.response?.data?.msg_key ? t(error.response.data.msg_key) : t('error_occurred_uploading_document'),
          {
            appearance: 'error',
            autoDismiss: true
          }
        ))
        .finally(() => setLoading(false))
    } else {
      if (Array.isArray(documents) && documents.length > 1) {
        setUploadingPercentage(1)

        for (const document of documents) {
          await uploadDocument(document, tags, selectedStartup, entityToAssignDocument)
          setUploadingPercentage((prev) => Math.ceil(prev + 100 / documents.length + 1))
        }

        setUploadingPercentage(0)
        setLoading(true)
        getDocuments().finally(() => setLoading(false))
      } else {
        setLoading(true)
        const file = Array.isArray(documents) ? documents[documents.length - 1] : documents
        uploadDocument(file, tags, selectedStartup, entityToAssignDocument)
          .then(() => getDocuments())
          .finally(() => setLoading(false))
      }
    }
  }

  const downloadDocument = async (documentId) => {
    setLoading(true)
    SupportService.getDocument(documentId, shared).then((response) => {
      if (response?.data?.url) {
        window.open(response.data.url, '_blank')
      }
    }).catch((error) => {
      addToast(
        error?.response?.data?.msg_key ? t(error.response.data.msg_key) : t('error_occurred_downloading_document'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    }).finally(() => {
      setLoading(false)
    })
  }

  const deleteDocument = async (documentId) => {
    confirm({
      icon: 'error'
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        setLoading(true)
        SupportService.deleteDocument(documentId)
          .then(() => getDocuments())
          .catch((error) => {
            addToast(
              error?.response?.data?.msg_key ? t(error.response.data.msg_key) : t('error_occurred_deleting_document'),
              {
                appearance: 'error',
                autoDismiss: true
              }
            )
          }).finally(() => {
            setLoading(false)
          })
      }
    })
  }

  const getDocuments = async () => {

    try {
      const result = await SupportService.getDocuments(filters ? {
        page: pagination.page,
        rowsPerPage: pagination.rowsPerPage,
        ...filters
      } : {
        page: pagination.page,
        rowsPerPage: pagination.rowsPerPage
      }, shared)

      const { documents, pagination: responsePagination } = result.data
      setDocuments(documents)

      if (responsePagination) {
        const newPagination = {
          ...pagination,
          page: responsePagination.current >= responsePagination.pages ? responsePagination.pages - 1 : responsePagination.current - 1,
          totalPages: responsePagination.pages,
          totalDocs: responsePagination.totalDocs
        }

        if (!isEqual(newPagination, pagination)) {
          setPagination(newPagination)
        }
      }
    } catch (error) {
      addToast(
        error?.response?.data?.msg_key ? t(error.response.data.msg_key) : t('error_occurred_retrieving_documents'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    } finally {
      setLoading(false)
    }
  }

  const getTags = () => {
    return TagsService.getTags('documentation').then((response) => {
      setTags(response.data.data)
    }).catch((error) => {
      addToast(
        error.response && error.response.data.msg_key
          ? error.response.data.msg_key
          : t('error_occurred_retrieving_tags'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    })
  }

  const getDealflow = () => {
    return DealflowService.getDealflow({
      rowsPerPage: 9999999,
      pool: 'true',
      populate: 'false',
      boards: 'false',
      milestones: 'false',
      files: 'false'
    }).then((response) => {
      setDealflow(response.data.dealflow)
    }).catch((error) => {
      addToast(error?.response?.data?.msg_key || t('error_occurred_retrieving_dealflow'), {
        appearance: 'error',
        autoDismiss: true
      })
    })
  }

  const manageTags = (data) => {
    setShowManageTagsModal(false)
    setLoading(true)
    SupportService.updateDocument(documentToEdit._id, { tags: data.length ? data : null })
      .then(() => getDocuments())
      .catch((error) => {
        addToast(
          error?.response?.data?.msg_key ? t(error.response.data.msg_key) : t('error_occurred_updating_tags'),
          {
            appearance: 'error',
            autoDismiss: true
          }
        )
      }).finally(() => {
        setLoading(false)
        setDocumentToEdit(null)
      })
  }

  useImperativeHandle(ref, () => ({
    showFilters: () => setShowFilters(true),
    uploadDocument: () => setShowUploadDocumentSection(true)
  }))

  useEffect(() => {
    setLoading(true)
    getDocuments().finally(() => setLoading(false))
  }, [pagination.page, pagination.rowsPerPage, filters])

  useEffect(() => {
    const init = async () => {
      setLoading(true)
      if (!dealId && !shared) {
        await getDealflow()
      }
      if (!shared) {
        await getTags()
      }
      await getDocuments()
      setLoading(false)
    }

    init()
  }, [dealId, shared])

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [documents, viewMode])

  return (
    <>
      {documents.length === 0 && (
        <div className='mt-6'>
            <NoDataInfo title={t('documents_not_found')} />
        </div>
      )}

      {documents.length !== 0 && (
        <>
          {viewMode === 'grid' && (
            <div className='grid grid-cols-1 sm:grid-cols-3 md:grid-cols-6 lg:grid-cols-8 gap-4 mt-6'>
              {documents.map((document) => (
                <Document
                  key={document._id}
                  document={document}
                  downloadDocument={downloadDocument}
                  deleteDocument={deleteDocument}
                  tags={tags}
                  manageTags={manageTags}
                />
              ))}
            </div>
          )}

          {viewMode === 'list' && (
            <div className='mt-6'>
              <Card>
                <DataGrid
                  actionsHeaderClassName={headerClass}
                  data={documents.map(d => ({ ...d, id: d._id }))}
                  headers={[
                    {
                      key: 'name',
                      title: t('name'),
                      headerClassName: headerClass,
                      render: (key, doc) => (
                        <div className="flex">
                          <DocumentIcon documentName={doc.name} size={20} link={!!doc.link}/>
                          <div className="ml-2">{doc.name}</div>
                        </div>
                      )
                    },
                    {
                      key: 'startup',
                      title: t('startup'),
                      headerClassName: headerClass,
                      show: () => !dealId,
                      render: (key, doc) => (
                        <>
                          {doc.startup && (
                            <div>
                              <Avatar
                                src={doc.startup.logo}
                                size="30"
                                round={true}
                                color="#e0e6f2"
                                fgColor="#4d70b3"
                                alt={doc.startup.name}
                              />
                              <span className="ml-2">{doc.startup.name}</span>
                            </div>
                          )}
                          {!doc.startup && '-'}
                        </>
                      )
                    },
                    {
                      key: 'tags',
                      title: t('tags'),
                      headerClassName: headerClass,
                      render: (key, document) => (
                        <>
                        {(document?.tags || []).length !== 0 ?
                          <TagListCircles tags={document?.tags || []} /> : '-'}
                        </>
                      )
                    },
                    {
                      key: 'createdAt',
                      title: t('date'),
                      headerClassName: headerClass,
                      render: (key, createdAt) => moment(createdAt).format('DD/MM/YYYY')
                    }
                  ]}
                  actions={[
                    {
                      id: 'tags',
                      tip: t('tags'),
                      onClick: (document) => {
                        setShowManageTagsModal(true)
                        setDocumentToEdit(document)
                      },
                      show: () => !shared,
                      buttonProps: {
                        iconComponent: <FaTags />
                      }
                    },
                    {
                      id: 'link',
                      tip: t('open_link'),
                      onClick: (document) => {
                        window.open(document.link, '_blank')
                      },
                      show: (document) => document.link,
                      buttonProps: {
                        iconComponent: <FaExternalLinkAlt />
                      }
                    },
                    {
                      id: 'download',
                      tip: t('download'),
                      onClick: (document) => downloadDocument(document._id),
                      show: (document) => !document.link,
                      buttonProps: {
                        iconComponent: <FaDownload />
                      }
                    },
                    {
                      id: 'delete',
                      tip: t('delete'),
                      show: () => !shared,
                      onClick: (document) => deleteDocument(document._id),
                      buttonProps: {
                        iconComponent: <FaTrashAlt />
                      }
                    }
                  ]}
                />
                <Pagination
                  paginateOptions={[12, 24, 36]}
                  currentPage={pagination.page}
                  setCurrentPage={(page) => setPagination({ ...pagination, page })}
                  perPage={pagination.rowsPerPage}
                  pages={pagination.totalPages}
                  setPerPage={(value) => setPagination({ ...pagination, page: 0, rowsPerPage: value[0].id })}
                />

              </Card>
            </div>
          )}

        </>
      )}

      {showUploadDocumentSection && (
        <UploadFileSection
          onSubmit={uploadDocuments}
          onClose={() => setShowUploadDocumentSection(false)}
          startup={startup}
          tags={tags}
          dealflow={dealflow}
          dealId={dealId}
        />
      )}

      {showFilters && (
        <DocumentsFilters
          onClose={() => setShowFilters(false)}
          filters={filters}
          dealflow={dealflow}
          dealId={dealId}
          tags={tags}
          onSubmit={(filters) => {
            setFilters(filters)
            setShowFilters(false)
          }}
        />
      )}

      {showManageTagsModal && (
        <ManageTagsModal
          tags={tags}
          currentTags={documentToEdit?.tags || []}
          showModal={showManageTagsModal}
          onSubmit={manageTags}
          handleCloseModal={() => {
            setShowManageTagsModal(false)
            setDocumentToEdit(null)
          }}
        />
      )}

      {(uploadingPercentage > 0 && uploadingPercentage < 100) && (
        <Modal showModal={true}>
          <div className='w-72'>
            <ProgressBar
              percentage={uploadingPercentage}
              value={uploadingPercentage}
              valueText={t('uploading')}
            />
          </div>
        </Modal>
      )}

      {loading && <Loader />}
    </>
  )
})
