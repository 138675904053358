import { useTranslation } from 'react-i18next'
import { Card } from '../../ui/atoms/Card'
import { useEffect, useState } from 'react'
import QRCode from 'qrcode'
import { useToasts } from 'react-toast-notifications'
import { Button } from '../../ui/atoms/Button'
import { FaCopy, FaDownload } from 'react-icons/fa'
import Utils from '../../../utils/utils'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { atomOneDark as codeStyle } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import Config from 'config'

export const ShareAndEmbedForm = ({ questionnaire, showTitle = true }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [qrImage, setQrImage] = useState(null)
  const [embedCode, setEmbedCode] = useState(null)
  const [url, setUrl] = useState(questionnaire?.short_link || null)

  const generateEmbedCode = link => {
    setEmbedCode(
      `<!-- Kiota Form -->
<iframe
  src="${link}"
  width="100%"
  height="100%"
  frameborder="0"
  allowfullscreen
></iframe>
<!-- end Kiota Form -->`
    )
  }

  const generateQRImage = link => {
    link && QRCode.toDataURL(link).then(qrURLData => setQrImage(qrURLData))
  }

  const copyText = (text) => {
    Utils.copyToClipboard(text).then(() => {
      addToast(t('text_copied'), { appearance: 'success', autoDismiss: true })
    })
  }

  const downloadQr = (qrImage) => {
    const a = document.createElement('a')
    a.href = 'data:application/png;base64' + qrImage
    a.download = `QR - ${questionnaire.name}.png`
    a.click()
  }

  useEffect(() => {
    if (questionnaire) {
      let url

      if (!questionnaire.short_link) {
        url = `${location.origin}/public/forms/${questionnaire?._id}`
      } else {
        url = questionnaire.short_link
      }

      generateQRImage(url)
      generateEmbedCode(url)
      setUrl(url)
    }
  }, [questionnaire])

  if (!questionnaire) {
    return null
  }

  return (
    <>
      {showTitle && (
        <h5 className={`text-main-${Config.PLATFORM} text-2xl font-bold border-b border-main-${Config.PLATFORM} mt-6 mb-4`}>
          {t('share_and_integrate')}
        </h5>
      )}

      <div className="grid grid-cols-2 gap-4 mt-4">

        <Card>
          <div>
            <h4 className={`text-main-${Config.PLATFORM} mb-2 font-bold`}>{t('share_form_title')}</h4>
            <p className='text-xs'>{t('share_form_description')}</p>
          </div>

          <div className='my-6 flex flex-col justify-center flex-1'>
            <div className='flex flex-row justify-center'>
              {qrImage && <img src={qrImage} alt="QR" width={200}/>}
            </div>

            <div className='mx-auto my-3 rounded-2xl w-1/2 border border-gray-lines p-4 text-center font-bold overflow-y-auto'>
              {url}
            </div>
          </div>

          <div className='flex flex-row justify-center'>
            <ButtonSecondary
              onClick={() => copyText(url)}
              text={t('copy_link')}
              marginRight={2}
              iconComponent={<FaCopy className="inline-block mr-3" />}
            />
            <ButtonSecondary
              onClick={() => downloadQr(qrImage)}
              text={t('download')}
              iconComponent={<FaDownload className="inline-block mr-3" />}
            />
          </div>
        </Card>

        <Card containerClassName='flex flex-col h-full'>
          <div>
            <h4 className={`text-main-${Config.PLATFORM} mb-2 font-bold`}>{t('embed_form_title')}</h4>
            <p className='text-xs'>{t('embed_form_description')}</p>
          </div>

          <div className='mt-3'>
            <h5 className='mb-2 text-sm'>{t('embed_code')}</h5>
            <p className='text-xs'>{t('embed_code_description')}</p>
          </div>

          <div className='flex-1'>
            <div
              onClick={() => copyText(embedCode)}
              className='mx-auto mt-6 mb-2 rounded-2xl w-2/3 border border-gray-lines overflow-hidden hover:cursor-pointer'
            >
              <SyntaxHighlighter language="htmlbars" style={codeStyle}>
                {embedCode}
              </SyntaxHighlighter>
            </div>
          </div>

          <div className='flex flex-row justify-center'>
            <ButtonSecondary
              onClick={() => copyText(embedCode)}
              text={t('copy_code')}
              iconComponent={<FaCopy className="inline-block mr-3" />}
            />
          </div>
        </Card>

      </div>
    </>
  )
}
