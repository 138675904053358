import { Button } from 'components/ui/atoms/Button'
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import SupportService from 'services/support-service'
import { Loader } from 'components/ui/molecules/Loader'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { isEmpty, isEqual } from 'lodash'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import { Pagination } from 'components/ui/atoms/Pagination'
import { RoutesLinks } from 'components/routes-links'
import { useHistory } from 'react-router-dom'
import { DataGrid } from 'components/ui/objects/DataGrid'
import {
  FaTrash,
  FaTimes,
  FaCheck,
  FaEye,
  FaRegQuestionCircle,
  FaFilePdf,
  FaShareAlt, FaRocket
} from 'react-icons/all'
import moment from 'moment'
import { useSwal } from 'hooks/useSwal'
import { Card } from '../../../../ui/atoms/Card'
import Avatar from 'react-avatar'
import ShareStartupSection from 'components/ui/atoms/ShareStartupSection'
import useUser from '../../../../../hooks/useUser'
import { DealflowService } from '../../../../../services/dealflow-service'
import Config from 'config'
import { EventsStartupsFilters } from './EventsStartupsFilters'
import { EvaluationsMatrix } from 'components/evaluations/EvaluationsMatrix'
import { AddStartupsToEventSection } from './AddStartupsToEventSection'

export const EventStartups = forwardRef(({ orderBy, setShowFilterStartups, evaluations, event, setShowStartupsButton, showFilters, setShowFilters }, ref) => {
  const history = useHistory()
  const historyState = history.location.state
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { confirm } = useSwal()
  const { user } = useUser()
  const [startups, setStartups] = useState([])
  const [sort, setSort] = useState('-createdAt')
  const [loading, setLoading] = useState(true)
  const [loadingStartupsFromPool, setLoadingStartupsFromPool] = useState(true)
  const [loadingJudges, setLoadingJudges] = useState(true)
  const [showAddStartupSection, setShowAddStartupSection] = useState(false)
  const [showShareStartup, setShowShareStartup] = useState(false)
  const [startupToShare, setStartupToShare] = useState(null)
  const [filters, setFilters] = useState({})
  const [startupsFromPool, setStartupsFromPool] = useState([])
  const [judges, setJudges] = useState([])
  const [pagination, setPagination] = useState(
    historyState && historyState?.page && historyState?.rowsPerPage
      ? historyState
      : {
          page: 0,
          rowsPerPage: 10,
          totalPages: 0,
          totalDocs: 0
        }
  )

  const getJudges = () => {
    setLoadingJudges(true)
    return SupportService.getJudges(event._id, {
      rowsPerPage: 9999,
    }).then((result) => {
      setJudges(result.data.data.judges)
    }).catch(error => {
      addToast(t('error_occurred_retrieving_judges'), {
        appearance: 'error',
        autoDismiss: true
      })
      console.log(error)
    }).finally(() => {
      setLoadingJudges(false)
    })
  }

  const getStartups = async () => {
    try {
      setLoading(true)

      const result = await SupportService.getStartups(event._id, {
        page: pagination.page,
        rowsPerPage: pagination.rowsPerPage,
        sortBy: orderBy ? orderBy : sort,
        ...filters
      })
      setStartups(result.data.data.startups)
      if (isEmpty(filters)) {
        setShowFilterStartups(!!result.data.data.startups.length)
      } else {
        setShowFilterStartups(true)
      }
      // setJudges(result.data.data.judges)
      setShowStartupsButton(!!result.data.data.startups.length)
      if (result.data.data.pagination) {
        const newPagination = {
          ...pagination,

          page:
            result.data.data.pagination.current >=
            result.data.data.pagination.pages
              ? result.data.data.pagination.pages - 1
              : result.data.data.pagination.current - 1,
          totalPages: result.data.data.pagination.pages,
          totalDocs: result.data.data.pagination.totalDocs
        }

        if (!isEqual(newPagination, pagination)) {
          setPagination(newPagination)
        }
      }
    } catch (error) {
      addToast(t('error_occurred_retrieving_startups'), {
        appearance: 'error',
        autoDismiss: true
      })
    } finally {
      setLoading(false)
    }
  }

  const updateStartup = async (startupId, discarded) => {
    try {
      setLoading(true)
      await SupportService.updateEventStartup(event._id, startupId, {
        discarded
      })
      getStartups()
      addToast(t(discarded ? 'startup_discarded_successfully' : 'startup_admitted_successfully'), {
        appearance: 'success',
        autoDismiss: true
      })
    } catch (error) {
      addToast(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : t(
            discarded
              ? 'error_occurred_discarding_startup'
              : 'error_occurred_admitting_startup'
          ),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    } finally {
      setLoading(false)
    }
  }

  const deleteStartup = (startupId) => {
    confirm({
      text: t('delete_startup_from_event_description'),
      icon: 'error'
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          await SupportService.deleteEventStartup(event._id, startupId)
          getStartups()
          addToast(t('startup_deleted_from_event_successfully'), {
            appearance: 'success',
            autoDismiss: true
          })
        } catch (error) {
          addToast(
            error.response && error.response.data.msg
              ? error.response.data.msg
              : t('error_occurred_deleting_startup_from_event'),
            {
              appearance: 'error',
              autoDismiss: true
            }
          )
        }
      }
    })
  }

  const downloadEvaluationReport = async (rowData) => {
    try {
      setLoading(true)
      await SupportService.evaluationReportPDF(rowData.id, event._id)
    } catch (error) {
      addToast(t(error?.response?.data?.msg_key || 'error_occurred_downloading_evaluation_report'), {
        appearance: 'error',
        autoDismiss: true
      })
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const addPoolStartup = (dealflow, selectedJudges) => {
    setLoading(true)
    return SupportService.addStartupToEvent(event._id, { dealflow: dealflow._id, judges: selectedJudges.map((j) => j.id) })
      .then(() => getStartups())
      .then(() => {
        addToast(t('startup_added_successfully'), {
          appearance: 'success',
          autoDismiss: true
        })
      }).catch((error) => {
        addToast(t(error?.response?.data?.msg_key || 'error_adding_startup'), {
          appearance: 'error',
          autoDismiss: true
        })
      }).finally(() => {
        setLoading(false)
      })
  }

  const getStartupsFromPool = () => {
    setLoadingStartupsFromPool(true)
    DealflowService.getDealflow({
      rowsPerPage: 999999,
      pool: 'true',
      populate: 'false',
      boards: 'false',
      milestones: 'false',
      files: 'false'
    }).then((response) => {
      setStartupsFromPool(response.data.dealflow)
    }).catch(() => {
      addToast(t('error_occurred'), {
        appearance: 'error',
        autoDismiss: true
      })
    }).finally(() => {
      setLoadingStartupsFromPool(false)
    })
  }

  useEffect(() => {
    if (event) {
      getStartupsFromPool()
      getJudges()
    }
  }, [event])

  useEffect(() => {
    if (event) {
      getStartups()
    }
  }, [event, pagination.page, pagination.rowsPerPage, filters, orderBy])

  const getActions = () => {
    const actions = [
      {
        id: 'evaluation-report',
        tip: t('evaluation_report_available'),
        buttonProps: {
          iconComponent: <FaFilePdf className="inline" />,
          width: '12',
          horizontal: '1',
          vertical: '1',
          verticalMargin: '0',
          horizontalMargin: '0'
        },
        show: (row) => {
          return row.evaluations
        },
        onClick: (rowData) => downloadEvaluationReport(rowData)
      },
      {
        id: 'admit',
        tip: t('admit'),
        buttonProps: {
          iconComponent: <FaCheck className="inline" />,
          textColor: 'green',
          width: '12',
          horizontal: '1',
          vertical: '1',
          verticalMargin: '0',
          horizontalMargin: '0'
        },
        show: (row) => {
          return row.discarded && !event.archived
        },
        onClick: (rowData) => {
          updateStartup(rowData._id, !rowData.discarded)
        }
      },
      {
        id: 'discard',
        tip: t('discard'),
        buttonProps: {
          iconComponent: <FaTimes className="inline" />,
          textColor: 'red',
          width: '12',
          horizontal: '1',
          vertical: '1',
          verticalMargin: '0',
          horizontalMargin: '0'
        },
        show: (row) => {
          return !row.discarded && !event.archived
        },
        onClick: (rowData) => {
          updateStartup(rowData._id, !rowData.discarded)
        }
      },
      {
        id: 'delete',
        tip: t('delete'),
        buttonProps: {
          iconComponent: <FaTrash className="inline" />,
          textColor: 'red',
          width: '12',
          horizontal: '1',
          vertical: '1',
          verticalMargin: '0',
          horizontalMargin: '0'
        },
        show: () => !event.archived,
        onClick: (rowData) => {
          deleteStartup(rowData._id)
        }
      }
    ]

    /*if ((user?.investor?.permissions?.sections || []).includes('network')) {
      actions.unshift({
        id: 'share',
        tip: t('share'),
        buttonProps: {
          iconComponent: <FaShareAlt className="inline" />,
          width: '12',
          horizontal: '1',
          vertical: '1',
          verticalMargin: '0',
          horizontalMargin: '0'
        },
        onClick: (rowData) => {
          setShowShareStartup(true)
          setStartupToShare(rowData)
        }
      })
    }*/

    actions.unshift({
      id: 'details',
      tip: t('details'),
      buttonProps: {
        iconComponent: <FaEye className="inline" />,
        width: '12',
        horizontal: '1',
        vertical: '1',
        verticalMargin: '0',
        horizontalMargin: '0'
      },
      onClick: (rowData) => {
        history.push(
          `${RoutesLinks.EVALUATION_PROCESSES_LINK}${event._id}/startups/${rowData._id}`,
          {
            pagination,
            from: history.location.pathname
          }
        )
      }
    })

    return actions
  }

  useImperativeHandle(ref, () => ({
    addStartup: () => setShowAddStartupSection(true)
  }))

  return (
    <>
      {showFilters && (
        <EventsStartupsFilters
          onClose={() => setShowFilters(false)}
          onFilter={setFilters}
          filters={filters}
          judges={judges.map((judge) => { return { id: judge._id, value: judge.judge } })}
        />
      )}

      {showShareStartup && (
        <ShareStartupSection
          elementToShare={startupToShare}
          source='event'
          onClose={() => {
            setShowShareStartup(false)
            setStartupToShare(null)
          }}
        />
      )}

      {showAddStartupSection && (
        <AddStartupsToEventSection
          judges={judges.map((j) => { return { id: j._id, value: j.judge } })}
          startupsFromPool={startupsFromPool}
          onClose={() => setShowAddStartupSection(false)}
          onSubmit={(startup, selectedJudges) => addPoolStartup(startup, selectedJudges)}
        />
      )}

      {startups.length === 0 && !loading && (
        <div className="my-10">
          <NoDataInfo
            title={t('startups_not_found_in_event')}
          />
        </div>
      )}

      {startups.length !== 0 && (
        <>
          {evaluations && evaluations.length !== 0 && <div className='my-6'>
            <Card>
              <EvaluationsMatrix
                scaleToTen={event.score_card?.scale === 'scale_to_ten'}
                evaluations={evaluations}
                loading={false}
              />
            </Card>
          </div>}
          <div className="mt-6">
            <Card>
              <DataGrid
                compact={true}
                bordered={true}
                data={startups.map((startup) => ({
                  ...startup,
                  name: startup.name ? startup.name : t('startup_name_not_provided'),
                  id: startup._id
                }))}
                headers={[
                  {
                    key: 'name',
                    title: t('startup'),
                    render: (key, startup) => (
                      <>
                        <Avatar
                          src={startup.logo}
                          size="30"
                          round={true}
                          color="#e0e6f2"
                          fgColor="#4d70b3"
                          alt={startup.name}
                        />
                        <span className="ml-2">{startup.name}</span>
                      </>
                    )
                  },
                  {
                    key: 'status',
                    title: (
                      <>
                        {t('status')}
                        <span
                          data-tip={t('event_startup_status_description')}
                          data-multiline={true}
                        >
                          <FaRegQuestionCircle className="inline-block ml-1" />
                        </span>
                      </>
                    ),
                    render: (key, startup) => {
                      return (
                        <span
                          className={startup.discarded ? 'text-red' : 'text-green'}
                        >
                          {t(startup.discarded ? 'discarded' : 'admitted')}
                        </span>
                      )
                    }
                  },
                  {
                    key: 'evaluations',
                    title: (
                      <>
                        {t('evaluations')}
                        <span
                          data-tip={t('startup_without_assigned_judge')}
                          data-multiline={true}
                        >
                          <FaRegQuestionCircle className="inline-block ml-1" />
                        </span>
                      </>
                    ),
                    render: (key, startup) => {
                      return (
                        <span>
                          {`${startup.evaluations}/${startup.judges}`}
                        </span>
                      )
                    }
                  },
                  {
                    key: 'avg',
                    title: (
                      <>
                        {t('avg')}
                      </>
                    ),
                    render: (key, startup) => {
                      return (
                        <span>
                          {startup.avg !== null && startup.avg !== undefined ? startup.avg : '-' }
                        </span>
                      )
                    }
                  },
                  {
                    key: 'createdAt',
                    title: t('application_date'),
                    render: (key, startup) => {
                      return moment(startup.date).format('YYYY-MM-DD HH:mm')
                    }
                  }
                ]}
                actions={getActions()}
              />

            <div>
              <Pagination
                paginateOptions={[10, 20, 30]}
                currentPage={pagination.page}
                setCurrentPage={(page) => {
                  setPagination({
                    ...pagination,
                    page
                  })
                }}
                perPage={pagination.rowsPerPage}
                pages={pagination.totalPages}
                setPerPage={(value) => {
                  setPagination({
                    ...pagination,
                    page: 0,
                    rowsPerPage: value[0].id
                  })
                }}
              />
            </div>
            </Card>
          </div>

        </>
      )}

      {(loading || loadingJudges || loadingStartupsFromPool) && <Loader />}
    </>
  )
})
