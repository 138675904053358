import { useEffect, useState, useCallback, forwardRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import UserService from 'services/user-service'
import { useHistory } from 'react-router-dom'

import UserOperations from 'redux/user/user-operations'
import Suggestion from './components/suggestion'
import { Pagination } from 'components/ui/atoms/Pagination'
import { Loader } from 'components/ui/molecules/Loader'
import { RoutesLinks } from 'components/routes-links'
import { SectionHeader } from 'components/ui/molecules/SectionHeader'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import { isEqual } from 'lodash'
import { Modal } from 'components/ui/molecules/Modal'
import { Button } from 'components/ui/atoms/Button'
import Select from 'components/ui/atoms/Select'
import { useSwal } from 'hooks/useSwal'
import useUser from '../../hooks/useUser'
import Config from 'config'
import RejectDealSection from 'components/dealflow/RejectDealSection'
import { Card } from 'components/ui/atoms/Card'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import EventUtils from 'utils/event-utils'

export const CuratedDealFlow = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const { confirm } = useSwal()
  const { user } = useUser()
  const history = useHistory()

  const handleRedirect = (url) => history.push(url)

  const [suggestions, setSuggestions] = useState([])
  const [loading, setLoading] = useState(true)
  const { addToast } = useToasts()
  const dispatch = useDispatch()

  const setUserInfo = (data) => dispatch(UserOperations.setUserInfo({ data }))

  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 12,
    totalPages: 0,
    totalDocs: 0
  })

  const reloadUserInfo = async () => {
    try {
      const response = await UserService.getUser(user._id)

      setUserInfo(response.data)
    } catch (error) {}
  }

  const [showRejectCuratedDealflowSection, setShowRejectCuratedDealflowSection] = useState(false)

  const decline = (suggestion) => {
    setSuggestionToReject(suggestion)
    setShowRejectCuratedDealflowSection(true)
  }

  const accept = (suggestion) => {
    confirm({text: t('accept_curated_dealflow_description')}).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true)
          await UserService.acceptSuggestion(suggestion._id)
          reloadUserInfo()
          getCuratedDealflow()

          addToast(t('startup_added_to_your_portfolio'), {
            appearance: 'success',
            autoDismiss: true
          })
        } catch (error) {
          console.log(error)
          addToast(t('error_occurred'), {
            appearance: 'error',
            autoDismiss: true
          })
        } finally {
          setLoading(false)
        }
      }
    })
  }

  const getCuratedDealflow = useCallback(async () => {
    try {
      setLoading(true)
      const queryParams = {
        page: pagination.page,
        rowsPerPage: pagination.rowsPerPage
      }

      const result = await UserService.getCuratedDealflow(
        user.investor._id,
        queryParams
      )

      setSuggestions(result.data.suggestions)

      if (result.data.pagination) {
        const newPagination = {
          ...pagination,
          totalPages: result.data.pagination.pages,
          totalDocs: result.data.pagination.totalDocs
        }

        if (!isEqual(newPagination, pagination)) {
          setPagination(newPagination)
        }
      }

      setLoading(false)
    } catch (error) {
      console.log(error)
      addToast(t('error_occurred'), {
        appearance: 'error',
        autoDismiss: true
      })
      console.log(error)
      setLoading(false)
    }
  }, [addToast, t, user, pagination])

  useEffect(() => {
    // getCuratedDealflow()
  }, [getCuratedDealflow, pagination.page, pagination.rowsPerPage])

  // const SECTIONS = ["pool", "manage_requests", "manage_invitations"];
  const SECTIONS = ['pool', 'curated_dealflow', 'applications']
  const URLS = {
    pool: RoutesLinks.DEALFLOW_LINK,
    curated_dealflow: RoutesLinks.CURATED_DEALFLOW_LINK,
    applications: RoutesLinks.APPLICATIONS_LINK
  }

  const [suggestionToReject, setSuggestionToReject] = useState(null)
  const rejectDeal = async (values) => {
    confirm().then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          await UserService.rejectCuratedDealflow(
            suggestionToReject._id,
            values
          )
          getCuratedDealflow()
        } catch (error) {
          addToast(t('error_occurred_rejecting_curated_dealflow'), {
            appearance: 'error',
            autoDismiss: true
          })
        } finally {
          setSuggestionToReject(null)
          setShowRejectCuratedDealflowSection(false)
        }
      }
    })
  }

  useEffect(() => {
    getCuratedDealflow()
  }, [getCuratedDealflow, pagination.page, pagination.rowsPerPage])

  const sendCuratedDealflowEvent = async () => {
    try {
      EventUtils.sendAnalyticEvent({
        event: 'ask_for_curated_dealflow',
        user: user ? user._id : null,
        payload: {}
      })
      addToast(t('request_received_successfully'), { appearance: 'success', autoDismiss: true })
    } catch (error) {
      addToast(t('error_occurred'), { appearance: 'error', autoDismiss: true })
      console.error(error)
    }
  }

  return (
    <>
      {!!suggestions.length && (
        <div className={'mt-3'}>
          <p className={'text-sm'}>{t('curated_dealflow_explanation')}</p>
        </div>
      )}

      <div className="grid grid-cols-1 lg:grid-cols-2  2xl:grid-cols-3 gap-4">

        {suggestions.map((suggestion, index) => {
          return (
            <Suggestion
              key={index}
              suggestion={suggestion}
              decline={decline}
              accept={accept}
            />
          )
        })}
      </div>
      {showRejectCuratedDealflowSection && (
        <RejectDealSection
          rejectDeal={rejectDeal}
          onClose={() => setShowRejectCuratedDealflowSection(false)}
        />
      )}
      {loading && <Loader />}
      {suggestions.length > 0 && (
        <Pagination
          paginateOptions={[12, 24, 36]}
          currentPage={pagination.page}
          setCurrentPage={(page) => {
            setPagination({
              ...pagination,
              page
            })
          }}
          perPage={pagination.rowsPerPage}
          pages={pagination.totalPages}
          setPerPage={(value) => {
            setPagination({
              ...pagination,
              page: 0,
              rowsPerPage: value[0].id
            })
          }}
        />
      )}
      {!loading && suggestions.length === 0 && (
        <div className='my-6'>
          <NoDataInfo
              title={t('we_can_not_provide_curated_dealflow_at_this_moment')}
              textOne={t('we_can_not_provide_curated_dealflow_at_this_moment_subtitle')}
            />
          <div className='my-4 w-full flex justify-center'>
            <ButtonMain text={t('i_need_curated_dealflow')} onClick={sendCuratedDealflowEvent} />
          </div>
      </div>
      )}
    </>
  )
})
