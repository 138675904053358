import { useTranslation } from 'react-i18next'
import { Button } from 'components/ui/atoms/Button'
import { SidePanel } from 'components/ui/objects/SidePanel'
import { FaEdit, FaFileDownload } from 'react-icons/fa'
import { FaTimes } from 'react-icons/all'
import Config from 'config';
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { ButtonDanger } from 'components/ui/atoms/ButtonDanger'

const Actions = ({
  onClose,
  downloadOnePager,
  downloadKpisReport,
  editInfo
}) => {
  const { t } = useTranslation()

  return (
    <SidePanel
      onClose={onClose}
      title={t('actions')}
    >
      {downloadOnePager && (
        <ButtonSecondary
          onClick={() => downloadOnePager()}
          text={t('one_pager')}
          width={'full'}
          iconComponent={<FaFileDownload className="inline-block mr-3" />}
          marginTop={2}
        />
      )}

      {downloadKpisReport && (
        <ButtonSecondary
          onClick={() => downloadKpisReport()}
          text={t('kpis_report')}
          width={'full'}
          iconComponent={<FaFileDownload className="inline-block mr-3" />}
          marginTop={2}
        />
      )}

      <ButtonSecondary
        onClick={() => editInfo()}
        text={t('edit_info')}
        width={'full'}
        marginTop={2}
        iconComponent={<FaEdit className="inline-block mr-3" />}
      />
    </SidePanel>
  )
}

export default Actions
