import { useTranslation } from 'react-i18next'
import { SidePanel } from 'components/ui/objects/SidePanel'
import { QuestionsUtils } from 'utils/questions-utils'
import TranslateUtils from 'utils/translate-utils'
import useUser from 'hooks/useUser'

const FormAnswersSection = ({ onClose, answer }) => {
  const { t } = useTranslation()
  const { userLanguage } = useUser()

  return (
    <>
      <SidePanel
        onClose={onClose}
        title={t('detailed_answer')}
        width={'1/3'}
        showExpand={true}
      >
          {answer.questions.map((question, index) => {
            return (
              <>
                {!['separator'].includes(question.question.type) && (
                  <div key={index} className="flex flex-row border-b border-gray-lines py-4">
                    {QuestionsUtils.getIcon(question.question.type, true)}

                    <div className="flex-1 flex flex-row ml-2">
                      <div className='w-full flex-1 mr-2 font-semibold text-base'>
                        {TranslateUtils.getTextByLang(userLanguage, question.question.title)}
                        <div className="text-xxs text-gray">{t(question.question.type)}</div>
                        {question.question.type !== 'image_selector' && <div className="text-xxs text-gray font-normal text-ellipsis w-full">
                          {question.question.options?.length > 0 &&
                            question.question.options.map((option, index) => (
                              <span key={index}>
                                {index > 0 && ', '}
                                {TranslateUtils.getTextByLang(userLanguage, option)}
                              </span>
                            ))}
                        </div>}
                        <div className='text-sm'>{answer.answers[question.question._id]}</div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )
          })}
      </SidePanel>

    </>
  )
}

export default FormAnswersSection
