import React, { useEffect, useState } from 'react'
import { DataGrid } from '../../ui/objects/DataGrid'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import FormUtils from '../../../utils/form-utils'
import { Input } from '../../ui/atoms/Input'
import { Button } from '../../ui/atoms/Button'
import { Modal } from '../../ui/molecules/Modal'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useToasts } from 'react-toast-notifications'
import moment from 'moment'
import { FaTrash } from 'react-icons/all'
import { FaEdit, FaPlus } from 'react-icons/fa'
import ReactTooltip from 'react-tooltip'
import { Card } from '../../ui/atoms/Card'
import { NoDataInfo } from '../../ui/atoms/NoDataInfo'
import Config from 'config';
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { useSwal } from 'hooks/useSwal'
import { Loader } from 'components/ui/molecules/Loader'
import { DealflowService } from 'services/dealflow-service'
import { CurrencyInput } from 'components/ui/atoms/CurrencyInput'
import RegisterInvestmentSection from 'components/dealflow/RegisterInvestmentSection'

export const StartupEditInvestments = ({ captable, onChange, isDealflow, startup, loading, setLoading, investments }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [showRegisterInvestmentSection, setShowRegisterInvestmentSection] = useState(false)
  const [investmentToEdit, setInvestmentToEdit] = useState(null)
  const { confirm } = useSwal()

  const onSuccess = messageKey => {
    setShowRegisterInvestmentSection(false)
    setInvestmentToEdit(null)
    onChange && onChange()
    addToast(t(messageKey), {
      appearance: 'success',
      autoDismiss: true
    })
  }

  const onError = messageKey => {
    addToast(t(messageKey),
      {
        appearance: 'error',
        autoDismiss: true
      }
    )
  }

  const registerInvestment = async (values) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        if (!investmentToEdit) {
            try {
              setLoading(true)
              if (values.is_own_investment) {
                delete values.shareholder
              }
              await DealflowService.registerInvestment(startup._id, { ...values, sector: startup?.sector?._id })
              onSuccess('investment_registered_successfully')
            } catch (error) {
              console.log(error)
              onError('error_occurred_registering_investment')
            } finally {
              setLoading(false)
            }
        } else {
            try {
                setLoading(true)
                if (values.is_own_investment) {
                  delete values.shareholder
                }
                await DealflowService.updateInvestment(startup._id, { ...values, sector: startup?.sector?._id }, investmentToEdit._id)
                onSuccess('investment_registered_successfully')
              } catch (error) {
                console.log(error)
                onError('error_occurred_registering_investment')
              } finally {
                setLoading(false)
              }
        }
    }})
  };

  const deleteInvestment = (investment) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        setLoading(true)
        DealflowService.deleteInvestment(startup._id, investment._id)
          .then(() => onSuccess('investment_deleted_successfully'))
          .catch(error => onError('error_occurred_deleting_investment'))
          .finally(() => setLoading(false))
      }
    })
  }

  const openInvestmentSection = (investment) => {
    setInvestmentToEdit(investment)
    setShowRegisterInvestmentSection(true)
  }


  useEffect(() => {
    ReactTooltip.rebuild()
  }, [captable])

  return (
    <>
      <div className="w-full mt-6 mb-8">
        <Card containerClassName="flex flex-col lg:flex-row lg:justify-between p-6 text-2">
          <div className="md:w-full lg:w-10/12">
            <h3 className={`text-main-${Config.PLATFORM}`}>
              {t('would_you_like_to_add_an_investment')}
            </h3>
          </div>
          <div className="md:w-full lg:w-2/12 justify-end flex text-center">
            <ButtonMain
              width="1/2 lg:w-full"
              text={t('add_investment')}
              onClick={() => openInvestmentSection(null)}
              />
          </div>
        </Card>
      </div>

      {(!captable || captable.length === 0) && (
        <NoDataInfo
          title={t('no_captable_title')}
          textOne={t('no_captable_description')}
        />
      )}

      {investments?.length > 0 && (
        <DataGrid
          wrapperClassName='mt-3'
          data={investments}
          bordered={true}
          compact={true}
          headers={[
            {
              key: 'shareholder',
              title: t('investor'),
              render: (key, investment) => investment.is_own_investment ? t('own_investment') : investment.shareholder
            },
            {
              key: 'investment',
              title: t('investment'),
              render: (key, investment) => `${investment[key]}${investment?.unit?.toUpperCase() || 'K'} €`
            },
            {
              key: 'shares',
              title: t('shares')
            },
            {
              key: 'date',
              title: t('date'),
              render: (key, investment) => investment[key] ? moment(investment[key]).format('YYYY-MM-DD') : t('no_data')
            }
          ]}
          actions={[
            {
              id: 'edit',
              tip: t('edit'),
              onClick: (investment) => openInvestmentSection(investment),
              buttonProps: {
                iconComponent: <FaEdit className="inline" />,
                className: 'w-12',
                horizontal: '1',
                vertical: '1',
                verticalMargin: '0',
                horizontalMargin: '0',
                textColor: `buttons-card-text-${Config.PLATFORM}`,
                bgColor: `buttons-card-${Config.PLATFORM}`,
                bgHoverColor: `buttons-hover-${Config.PLATFORM}`
              }
            },
            {
              id: 'delete',
              tip: t('delete'),
              onClick: (investment) => deleteInvestment(investment),
              buttonProps: {
                iconComponent: <FaTrash className="inline" />,
                className: 'w-12 bg-red text-white',
                horizontal: '1',
                vertical: '1',
                verticalMargin: '0',
                horizontalMargin: '0'
              }
            }
          ]}
        />
      )}

        {showRegisterInvestmentSection && (
            <RegisterInvestmentSection
                onClose={() => setShowRegisterInvestmentSection(false)}
                registerInvestment={registerInvestment}
                initialValues={investmentToEdit}
                investments={investments}
            />
        )}

      {loading && <Loader /> }
    </>
  )
}
