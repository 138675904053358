import {
  FaFire,
  FaMoneyBillAlt,
  FaPiggyBank,
  FaRoad,
  FaUserSlash
} from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { Card } from '../../ui/atoms/Card'
import LineChart from '../../ui/molecules/charts/LineChart'
import { ResponsiveBar } from '@nivo/bar'
import BarChart from '../../ui/molecules/charts/BarChart'
import { FaMoneyBill, FaUserCheck } from 'react-icons/all'
import Utils from '../../../utils/utils'
import useUser from '../../../hooks/useUser'
import { isEmpty } from 'lodash'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'

export const StartupKPIs = ({ startup }) => {
  const { t } = useTranslation()
  const { userLanguage } = useUser()
  const gainLossProps = {
    padding: 0.2,
    labelTextColor: 'inherit:darker(1.4)',
    labelSkipWidth: 16,
    labelSkipHeight: 16,
    indexBy: 'month',
    enableGridX: true,
    enableGridY: true,
    valueFormat: (value) => `${Math.abs(value)}€`,
    axisTop: {
      tickSize: 0,
      tickPadding: 12
    },
    axisLeft: null,
    axisRight: {
      format: (v) => `${Math.abs(v)}€`
    },
    markers: [
      {
        axis: 'y',
        value: 0,
        lineStyle: { strokeOpacity: 0 },
        textStyle: { fill: '#2ebca6' },
        legend: t('gain'),
        legendPosition: 'top-left',
        legendOrientation: 'vertical',
        legendOffsetY: 30
      },
      {
        axis: 'y',
        value: 0,
        lineStyle: { stroke: '#f47560', strokeWidth: 1 },
        textStyle: { fill: '#e25c3b' },
        legend: t('loss'),
        legendPosition: 'bottom-left',
        legendOrientation: 'vertical',
        legendOffsetY: 30
      }
    ],
    height: 350,
    keys: [t('gain'), t('loss')],
    colors: ['#61cdbb', '#f47560']
  }

  const getKPILatestYear = (obj) => {
    const years = Object.keys(obj || {})

    if (years.length > 0) {
      return obj[years.sort((a, b) => b - a)[0]]
    }

    return {}
  }

  const getKPILatestYearAvg = (obj, latestAmount = 12) => {
    const latestYear = getKPILatestYear(obj)
    const months = Object.values(latestYear)
      .filter(Boolean)
      .sort((a, b) => b - a)
      .slice(0, latestAmount)

    let sum = 0

    for (let i = 0; i < months.length; i++) {
      sum += months[i] || 0
    }

    return sum ? sum / months.length : 0
  }

  const getKPILatestMonthValue = (obj) => {
    const latestYear = getKPILatestYear(obj)
    const months = Object.keys(latestYear)

    if (months.length > 0) {
      return latestYear[months.sort((a, b) => b - a)[0]]
    }

    return null
  }

  const getRunway = () => {
    const { economic_spending: spending, economic_resources: resources } = startup

    if (!spending || !resources) {
      return 0
    }

    const resourcesValue = getKPILatestMonthValue(resources)
    const spendingAVG = getKPILatestYearAvg(spending)

    return resourcesValue !== null ? (resourcesValue / spendingAVG).toFixed() : null
  }

  const getBurnRate = () => {
    const { economic_spending: spending, economic_resources: resources } = startup

    if (!spending || !resources) {
      return 0
    }

    const resourcesValue = getKPILatestMonthValue(resources)
    const spendingAVG = getKPILatestYearAvg(spending, 3)

    if (!resourcesValue || !spendingAVG) {
      return null
    }

    return (spendingAVG * 100 / resourcesValue).toFixed(2)
  }

  const getMRR = () => {
    const { economic_income: income } = startup

    if (!income) {
      return 0
    }

    const mrr = getKPILatestYearAvg(income, 4)
    return mrr ? mrr.toFixed() : null
  }

  const getARR = () => {
    const { economic_income: income } = startup

    if (!income) {
      return 0
    }

    const latestYear = getKPILatestYear(income)
    const months = Object.keys(latestYear)
    const arr = months.reduce((acc, month) => acc + latestYear[month], 0)

    return arr ? arr.toFixed() : null
  }

  const getChurnRate = () => {
    const { churn_rate: rate } = startup

    if (!rate) {
      return 0
    }

    return getKPILatestMonthValue(rate)?.toFixed() || null
  }

  const getGMV = () => {
    const { gmv } = startup

    if (!gmv) {
      return 0
    }

    return getKPILatestMonthValue(gmv)?.toFixed() || null
  }

  const getChartEvolution = (year) => {
    if (!year || !Object.keys(year).length) {
      return Array.from({ length: 12 }, () => 0).map((_, i) => ({
        x: Utils.getMonthName(userLanguage, i, true),
        y: 0
      }))
    }

    const latestYear = getKPILatestYear(year)

    return Object.keys(latestYear)
      .filter(month => Boolean(latestYear[month]))
      .sort((a, b) => a - b)
      .map(month => ({
        x: Utils.getMonthName(userLanguage, month - 1, true),
        y: latestYear[month]
      }))
  }

  const getGainLossEvolution = () => {
    const { economic_spending: spending, economic_income: income } = startup

    if (isEmpty(spending) || isEmpty(income)) {
      return <NoDataInfo title={t('no_data_provided')} />
    }

    const gain = getKPILatestYear(income)
    const loss = getKPILatestYear(spending)

    return <ResponsiveBar {...gainLossProps} data={Array(12).fill(0).map((_, index) => ({
        [t('gain')]: gain[index + 1] || 0,
        [t('loss')]: -(loss[index + 1]) || 0,
        month: Utils.getMonthName(userLanguage, index, true)
      }))}
    />

  }

  const getNPSEvolution = () => {
    const { nps } = startup

    if (!nps || !Object.keys(nps).length) {
      return <NoDataInfo title={t('no_data_provided')} />
    }

    const latestYear = getKPILatestYear(nps)
    const months = Object.keys(latestYear)

    return <BarChart keys={[t('nps_short')]} data={months.map(month => ({
      [t('nps_short')]: latestYear[month] || 0,
      indexed: Utils.getMonthName(userLanguage, month - 1, true)
    }))} />
  }

  const renderCard = (title, value, icon) => (
    <Card wrapperClassName='relative' containerClassName='h-full'>
      <div className='text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl bg-lightBlue-600 left-4 -top-6'>
        {icon}
      </div>
      <div className='pt-6 flex flex-col h-full'>
        <div>
          <p className='text-lg font-semibold my-2'>{title}</p>
        </div>
        <div className='flex-1 flex font-bold justify-center items-end text-5xl'>
          <div>{value}</div>
        </div>
      </div>
    </Card>
  )

  if (!startup) {
    return null
  }

  return (
    <>
      <div className='grid grid-cols-1 gap-x-4 gap-y-9 md:grid-cols-2 xl:grid-cols-4 my-10'>
        {renderCard(
          <>{t('runway')} ({t(getRunway() > 12 ? 'years' : 'months')})</>,
          getRunway() !== null
            ? getRunway() > 12 ? Math.round(getRunway() / 12) : getRunway()
            : <div className='text-center'><span className='text-sm text-gray-300 text-center'>{t('no_data_provided')}</span></div>,
          <FaRoad className='w-6 h-6' />
        )}

        {renderCard(
          <>{t('burn_rate')} ({t('monthly')})</>,
          getBurnRate() !== null ? `${getBurnRate()}%` : <div className='text-center'><span className='text-sm text-gray-300 text-center'>{t('no_data_provided')}</span></div>,
          <FaFire className='w-6 h-6' />
        )}

        {renderCard(
          t('mrr_short'),
          getMRR() !== null ? `${Utils.nFormatter(getMRR())}€` : <div className='text-center'><span className='text-sm text-gray-300 text-center'>{t('no_data_provided')}</span></div>,
          <FaMoneyBill className='w-6 h-6' />
        )}

        {renderCard(
          t('arr_short'),
          getARR() !== null ? `${Utils.nFormatter(getARR())}€` : <div className='text-center'><span className='text-sm text-gray-300 text-center'>{t('no_data_provided')}</span></div>,
          <FaMoneyBill className='w-6 h-6' />
        )}

        {renderCard(
          t('churn_rate'),
          getChurnRate() !== null ? `${getChurnRate()}%` : <div className='text-center'><span className='text-sm text-gray-300 text-center'>{t('no_data_provided')}</span></div>,
          <FaUserSlash className='w-6 h-6' />
        )}

        {renderCard(
          t('ltv'),
          startup?.ltv ? `${startup?.ltv}%` : <div className='text-center'><span className='text-sm text-gray-300 text-center'>{t('no_data_provided')}</span></div>,
          <FaPiggyBank className='w-6 h-6' />
        )}

        {renderCard(
          t('cac'),
          startup?.cac ? `${startup?.cac}€` : <div className='text-center'><span className='text-sm text-gray-300 text-center'>{t('no_data_provided')}</span></div>,
          <FaUserCheck className='w-6 h-6' />
        )}

        {renderCard(
          t('gmv'),
          getGMV() !== null ? `${getGMV()}€` : <div className='text-center'><span className='text-sm text-gray-300 text-center'>{t('no_data_provided')}</span></div>,
          <FaMoneyBillAlt className='w-6 h-6' />
        )}
      </div>

      <div className='grid grid-cols-1 md:grid-cols-2 gap-4 my-4'>
        <Card>
          <p className='text-xl font-semibold my-2'>{t('mrr_evolution')}</p>

          <div className='w-full h-96 mt-6'>
            {isEmpty(startup?.economic_income) ? <NoDataInfo title={t('no_data_provided')} /> : <LineChart
              yFormat='>-.0f'
              data={[{
                id: 'economic_income',
                data: getChartEvolution(startup?.economic_income)
              }]}
            />}
          </div>
        </Card>
        <Card>
          <p className='text-xl font-semibold my-2'>{t('churn_rate_evolution')}</p>

          <div className='w-full h-96 mt-6'>
          {isEmpty(startup?.churn_rate) ? <NoDataInfo title={t('no_data_provided')} /> : 
            <LineChart
              yFormat='>-.0f'
              data={[{
                id: 'churn_rate',
                data: getChartEvolution(startup?.churn_rate)
              }]}
            />}
          </div>
        </Card>
      </div>

      <Card>
        <p className='text-xl font-semibold my-2'>{t('loss_gain_evolution')}</p>

        <div className='h-[370px] flex items-center max-w-full'>
          {getGainLossEvolution()}
        </div>
      </Card>

      <Card wrapperClassName='mt-4'>
        <p className='text-xl font-semibold my-2'>{t('nps_evolution')}</p>
        <div className='h-[370px] flex items-center max-w-full'>
          {getNPSEvolution()}
        </div>
      </Card>

      {/* <div className='grid grid-cols-1 md:grid-cols-2 gap-4 my-4'>
        <div>
          <Card>
            <p className='text-xl font-semibold mb-6'>{t('social_media')}</p>
            <div className='grid grid-cols-3 gap-4'>
              <div className='flex justify-center flex-row'>
                <div className='flex flex-col items-center'>
                  <img
                    src={Linkedin}
                    alt='Arrow icon'
                    className='w-14'
                  />
                  <div className='font-bold text-xl flex justify-center mt-2'>
                    20.5K
                  </div>
                  <div>
                    {t('followers')}
                  </div>
                </div>
              </div>
              <div className='flex justify-center flex-row'>
                <div className='flex flex-col items-center'>
                  <img
                    src={Twitter}
                    alt='Arrow icon'
                    className='w-14'
                  />
                  <div className='font-bold text-xl flex justify-center mt-2'>
                    20.5K
                  </div>
                  <div>
                    {t('followers')}
                  </div>
                </div>
              </div>
              <div className='flex justify-center flex-row'>
                <div className='flex flex-col items-center'>
                  <img
                    src={Instagram}
                    alt='Arrow icon'
                    className='w-14'
                  />
                  <div className='font-bold text-xl flex justify-center mt-2'>
                    20.5K
                  </div>
                  <div>
                    {t('followers')}
                  </div>
                </div>
              </div>
              <div className='flex justify-center flex-row'>
                <div className='flex flex-col items-center'>
                  <img
                    src={Youtube}
                    alt='Arrow icon'
                    className='w-14'
                  />
                  <div className='font-bold text-xl flex justify-center mt-2'>
                    20.5K
                  </div>
                  <div>
                    {t('followers')}
                  </div>
                </div>
              </div>
              <div className='flex justify-center flex-row'>
                <div className='flex flex-col items-center'>
                  <img
                    src={Tiktok}
                    alt='Arrow icon'
                    className='w-14'
                  />
                  <div className='font-bold text-xl flex justify-center mt-2'>
                    20.5K
                  </div>
                  <div>
                    {t('followers')}
                  </div>
                </div>
              </div>
              <div className='flex justify-center flex-row'>
                <div className='flex flex-col items-center'>
                  <img
                    src={Facebook}
                    alt='Arrow icon'
                    className='w-14'
                  />
                  <div className='font-bold text-xl flex justify-center mt-2'>
                    20.5K
                  </div>
                  <div>
                    {t('likes')}
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div>

          <Card>
            <p className='text-xl font-semibold mb-6'>{t('apps')}</p>
            <div className='grid grid-cols-2 gap-4'>
              <div className='flex justify-center flex-row'>
                <div className='flex flex-col items-center'>
                  <img
                    src={PlayStore}
                    alt='Arrow icon'
                    className='w-24'
                  />
                  <div className='font-bold text-xl flex justify-center mt-2'>
                    <FaStar className='inline-block w-6 h-6 mr-2 text-yellow-600'/> 4.4
                    <FaUser className='inline-block w-6 h-6 mx-2 text-gray'/> 4.4K
                    <FaDownload className='inline-block w-6 h-6 mx-2'/> 4K
                  </div>
                </div>
              </div>
              <div className='flex justify-center flex-row'>
                <div className='flex flex-col items-center'>
                  <img
                    src={AppleStore}
                    alt='Arrow icon'
                    className='w-20'
                  />
                  <div className='font-bold text-xl flex justify-center mt-8'>
                    <FaStar className='inline-block w-6 h-6 mr-2 text-yellow-600'/> 4.4
                    <FaUser className='inline-block w-6 h-6 mx-2 text-gray'/> 4.4K
                    <FaDownload className='inline-block w-6 h-6 mx-2'/> 4K
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div> */}
    </>
  )
}
