/* eslint-disable default-case */
import produce from 'immer'

import { InvitationsActionsTypes } from './invitations-actions-types'
import { UserActionsTypes } from '../user/user-actions-types'

const {
  SET_FILTERS,
  SET_PAGINATION,
  SET_SORT,
  RESET
} = InvitationsActionsTypes
const { RESET: LOGOUT } = UserActionsTypes

const defaultState = {
  filters: {
    name: '',
    email: '',
    status: null
  },
  pagination: {
    page: 0,
    rowsPerPage: 10
  }
}

const InvitationsReducer = (state = defaultState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_FILTERS:
        draft.filters = {
          ...action.payload.data.filters
        }
        break
      case SET_PAGINATION:
        draft.pagination = action.payload.data
        break
      case SET_SORT:
        draft.filters = {
          ...action.payload.data
        }
        break
      case RESET:
      case LOGOUT:
        return defaultState
    }
  })

export default InvitationsReducer
