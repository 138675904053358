import { saturateColor } from 'utils/utils'
import { useTranslation } from 'react-i18next'

export const TagList = ({ tags, showEmptyState = false }) => {
  const { t } = useTranslation()

  if ((!tags || !tags.length) && !showEmptyState) {
    return null
  }

  return (
    <>
      {tags.map((tag) => (
        <span
          key={tag._id}
          className="inline-block mr-2 rounded-2xl py-0.5 px-2 relative font-semibold text-xxxs"
          style={{
            backgroundColor: tag.color,
            color: saturateColor(tag.color, 0.7)
          }}
        >
          {tag.name}
        </span>
      ))}

      {(!tags || tags.length === 0) && (
        <span className="inline-block mr-3 mt-1 font-semibold text-xxs px-2 py-0.5 text-gray-300">
          {t('no_tags')}
        </span>
      )}
    </>
  )
}
