import { RoutesLinks } from 'components/routes-links'
import { BannerResponsive } from 'components/ui/atoms/BannerResponsive'
import { BottomText } from 'components/ui/atoms/BottomText'
import { CoverBackground } from 'components/ui/atoms/CoverBackground'
import { FormTitle } from 'components/ui/atoms/FormTitle'
import { AccountTypeCard } from 'components/ui/molecules/AccountTypeCard'
import { Container } from 'components/ui/objects/Container'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import Config from 'config';
import moment from "moment";
import { PoweredText } from 'components/ui/atoms/PoweredText'
import Parameters from 'config/parameters.json';

const RegisterAccountType = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Container variant="session">
      <Container>
        <section className="flex flex-col justify-center items-center | min-h-screen w-full lg:p-16">
          <div className="w-full xl:max-w-2xl p-6 mx-2 mt-16 sm:mt-32 md:mt-0">
            <BannerResponsive />

            <FormTitle
              formTitle={t('started')}
              formSubtitle={t('select_account_type')}
            />
            <div className="flex flex-col my-8 w-full">
              <AccountTypeCard
                onClick={() => history.push(RoutesLinks.REGISTER_FOUNDER_LINK)}
                type="startup"
                title={t('im_entrepreneur')}
                subTitle={t('startup_text')}
                positionText={Config.PLATFORM === '001' ? 'items-end justify-end text-right' : 'items-start justify-start text-left'}
                imgBgSource={Parameters.bg_account_type_startup[Config.PLATFORM]}
                positionImgBg={Config.PLATFORM === '001' ? 'bg-left-top' : 'bg-right'}
                classes={Config.PLATFORM === '001' ? 'tsc-account-type-img' : 'bg-contain'}
              />
              <AccountTypeCard
                onClick={() => history.push(RoutesLinks.REGISTER_INVESTOR_LINK)}
                type="investor"
                title={t('im_investor')}
                subTitle={t('investor_text')}
                imgBgSource={Parameters.bg_account_type_investor[Config.PLATFORM]}
                classes={Config.PLATFORM === '001' ? 'tsc-account-type-img ' : 'bg-contain'}
                positionImgBg={Config.PLATFORM === '001' ? 'bg-left-top' : 'bg-left'}
                style={Config.PLATFORM === '001' ? { order: 1 } : {}}
              />
              {Config.PLATFORM !== '000' && <AccountTypeCard
                onClick={() => history.push(RoutesLinks.REGISTER_PROFESSIONAL_LINK)}
                type="professional"
                title={t('im_professional')}
                subTitle={t('professional_text')}
                positionText='items-start justify-start text-left'
                imgBgSource={Parameters.bg_account_type_professional[Config.PLATFORM]}
                positionImgBg={Config.PLATFORM === '001' ? 'bg-right-bottom' : 'bg-right'}
                classes={Config.PLATFORM === '001' ? 'tsc-account-type-img' : 'bg-contain'}
              />}
            </div>
            <div className="">
              <span className="text-xs text-gray font-regular">
                {t('already_have_an_account')}
                <Link
                  to={`${RoutesLinks.LOGIN_LINK}`}
                  className={`underline inline text-main-${Config.PLATFORM} hover:text-link-hover-${Config.PLATFORM}`}
                >
                  {t('go_to_login')}
                </Link>
              </span>
            </div>
          </div>
          <BottomText text={`${t('copyright')} @${moment().format('YYYY')} ${t('copyright_rights')}`} />
          {Config.PLATFORM !== '000' && <PoweredText />}

        </section>
      </Container>

      <CoverBackground link={true}></CoverBackground>
    </Container>
  )
}

export default RegisterAccountType
