import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { Card } from 'components/ui/atoms/Card'
import {
  FaCheckCircle,
  FaRocket,
  FaChevronLeft,
} from 'react-icons/all'
import Lang from 'lang'
import * as Yup from 'yup'
import { PublicQuestionnaireQuestion } from '../public-questionnaire-question/PublicQuestionnaireQuestion'
import { useSwal } from '../../hooks/useSwal'
import Spanish from 'styles/images/spanish.svg'
import English from 'styles/images/english.jpg'
import Config from 'config'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

const comparators = {
  '=_array': (a, b) => a && a[0] === b,
  '=': (a, b) => a === b,
  '!=': (a, b) => a !== b,
  '>': (a, b) => a > b,
  '>=': (a, b) => a >= b,
  '<': (a, b) => a < b,
  '<=': (a, b) => a <= b,
  AND: (a, b) => a && b,
  OR: (a, b) => a || b
}

export const PublicQuestionnaire = ({
  questionnaireId,
  questions,
  isSubmitted,
  setLoading,
  confirmFinishForm,
  answers,
  setAnswers,
  questionErrors,
  setQuestionErrors,
  isDraft,
  isPreview,
  descriptionFormEnded,
  visibilityMode,
  showInvestorsPercentage,
  loading,
  confirmRetries,
  languages,
  formId,
  investorId,
  scoreCardId = null,
  cbFinish = false,
  setResultCbFinish = null,
  shared = false
}) => {

  const { t, i18n } = useTranslation()
  const { alert } = useSwal()
  const [index, setIndex] = useState(-1)
  const [language, setLanguage] = useState(Lang.getCurrentLang().code)
  const [percentageCompleted, setPercentageCompleted] = useState(0)
  const [containerHeight, setContainerHeight] = useState(0)
  const [sortedQuestions, setSortedQuestions] = useState([])
  const [expandedQuestions, setExpandedQuestions] = useState([])
  const [expandedQuestionsErrors, setExpandedQuestionsErrors] = useState({})
  const [someErrorId, setSomeErrorId] = useState(null)

  const ref = useRef(null)

  const changeLanguage = async (langCode) => {
    setLanguage(langCode)
    await i18n.changeLanguage(langCode)
  }

  useEffect(() => {
    if (languages && languages.length === 1) {
      changeLanguage(languages[0])
    }
  }, [languages])

  useEffect(() => {
    if (questions.length) {
      const expandedQuestions = []
      questions.forEach((q) => {
        if (q.type !== 'separator') {
          if (q.type !== 'questions_group') {
            expandedQuestions.push(q._id)
          } else if (q.type === 'questions_group') {
            const groupedQuestion = questions.find((question) => question._id === q._id)
            groupedQuestion.questionsGroup.forEach((q) => {
              if (!expandedQuestions.includes(q)) {
                expandedQuestions.push(q)
              }
            })
          }
        }
      })

      setExpandedQuestions(expandedQuestions)
    }
  }, [questions])

  const checkIfRequiredQuestionGroupIsValid = (question, value) => {
    if (question.required) {
      const groupedAnswers = answers[question.question_id._id];
      if (groupedAnswers && groupedAnswers.some((answer) => !answer)) return true;

      return (!groupedAnswers || groupedAnswers.length !== question.question_id.questionsGroup.length)
    }
    return false
  }

  const checkRequiredQuestion = (question, value) => {
    if (question.question_id.type !== 'questions_group') return question.required && !value
    return checkIfRequiredQuestionGroupIsValid(question, value)
  }

  const checkCurrentQuestionErrors = async (question, value) => {
    let validator

    if ((!isDraft && !isPreview) && answers.hasOwnProperty(question.question_id._id) && (checkRequiredQuestion(question, value) || (!value && question.next_question?.some(q => q.conditions.length && q.conditions?.some(c => !c.value))))) {
      setQuestionErrors({
        ...questionErrors,
        [question.question_id._id]: t('required_field')
      })
      return true
    }

    if (question.question_id.type === 'questions_group') {
      const questionsGroup = question.question_id.questionsGroup
      const emailQuestions = questionsGroup.filter((questionGroup) => questionGroup.type === 'email');

      for (const q of emailQuestions) {
        const emailQuestionIndex = questionsGroup.findIndex((questionGroup) => questionGroup.type === 'email');
        if (answers[question.question_id._id] && emailQuestionIndex > -1) {
          validator = Yup.string().email(t('incorrect_format'));
          try {
            await validator.validate(answers[question.question_id._id][emailQuestionIndex])
            setQuestionErrors({
              ...questionErrors,
              [question.question_id._id]: null
            })
            setExpandedQuestionsErrors({
              ...expandedQuestionsErrors,
              [q._id]: null
            })
            return false;
          } catch (error) {
            console.log(error)
            setQuestionErrors({
              ...questionErrors,
              [question.question_id._id]: t('some_question_has_an_incorrect_format')
            })
            setExpandedQuestionsErrors({
              ...expandedQuestionsErrors,
              [q._id]: t('incorrect_format')
            })
            return true;
          }
        }
      }
      const urlQuestions = questionsGroup.filter((questionGroup) => questionGroup.type === 'url');
      for (const q of urlQuestions) {
        const urlQuestionIndex = questionsGroup.findIndex((questionGroup) => questionGroup._id === q._id);
        if (urlQuestionIndex > -1 && answers[question.question_id._id]) {
          validator = Yup.string().url(t('incorrect_format'))
          try {
            await validator.validate(answers[question.question_id._id][urlQuestionIndex])
            setQuestionErrors({
              ...questionErrors,
              [question.question_id._id]: null
            })
            setExpandedQuestionsErrors({
              ...expandedQuestionsErrors,
              [q._id]: null
            }) 
            return false

          } catch (error) {
            setExpandedQuestionsErrors({
              ...expandedQuestionsErrors,
              [q._id]: t('incorrect_format')
            })
            setQuestionErrors({
              ...questionErrors,
              [question.question_id._id]: t('some_question_has_an_incorrect_format')
            })
            return true
          }
        }
      }
    }


    switch (question.question_id.type) {
      case 'select':
      case 'multiselect':
      case 'image_selector':
        validator = Yup.array(t('incorrect_format')).of(Yup.string())
        break
      case 'boolean':
        validator = Yup.boolean(t('incorrect_format'))
        break
      case 'number':
        validator = Yup.number()
          .typeError(t('incorrect_format'))
          .min(0)
          .transform((_, val) => {
            return val === `${Number(val)}` ? Number(val) : null
          })
        break
      case 'url':
        validator = Yup.string().url(t('incorrect_format'))
        break
      default:
        validator = Yup.string()
    }

    
    if (question.required) {
      validator = validator.required(t('required_field'))
    }
    
    if (question.question_id.type === 'email') {
      validator = validator.email(t('incorrect_format'))
    }
    validator = validator.nullable()

    return validator.validate(question.question_id.type.includes('file') ? value ? value._id : null : question.question_id.type !== 'questions_group' ? value : value ? value.join('|__|') : null).then(() => {
      setQuestionErrors({
        ...questionErrors,
        [question.question_id._id]: null
      })
      return null
    }).catch(error => {
      setQuestionErrors({
        ...questionErrors,
        [question.question_id._id]: error.message
      })
      return error.message
    })
  }

  const getCompletedPercentage = async () => {
    const sortedQuestionsExcludingSeparators = sortedQuestions.filter((q) => q.question_id.type !== 'separator');
    if (index === 0) return 0
    const total = sortedQuestionsExcludingSeparators.length
    let completed = 0

    const questionsValidation = await Promise.all(sortedQuestionsExcludingSeparators.map((question, i) => checkCurrentQuestionErrors(question, answers[question.question_id._id] || null)))

    questionsValidation.forEach((error, i) => {
      if ((!sortedQuestionsExcludingSeparators[i].required && sortedQuestionsExcludingSeparators[i].question_id._id in answers) || (!error && answers[sortedQuestionsExcludingSeparators[i].question_id._id])) {
        completed++
      }
    })

    setPercentageCompleted(Math.round((completed / total) * 100))
  }

  const finishForm = async () => {
    let errorId
    setSomeErrorId(errorId)
    if (visibilityMode === 'single_page') {
      const errors = {...questionErrors }
      for (const question of sortedQuestions) {
        const errorResponse = await checkCurrentQuestionErrors(question, answers[question.question_id._id] || null) 
        errors[question.question_id._id] = errorResponse
      }
      setQuestionErrors(errors)
      errorId = Object.keys(errors).find(key => errors[key])
    } else { 
      errorId = Object.keys(questionErrors).find(key => questionErrors[key])
    }

    if (errorId) {
      const sortedQuestionsIds = sortedQuestions.map((sortedQuestion) => sortedQuestion.question_id._id)

      alert({ text: t('form_field_error'), icon: 'error' }).then(() => {
        if (!sortedQuestionsIds.includes(errorId)) {
          const groupedQuestions = sortedQuestions.filter((sortedQuestion) => sortedQuestion.question_id.type === 'questions_group')
          const incorrectGroupedQuestion = groupedQuestions.find((groupedQuestion) => groupedQuestion.question_id.questionsGroup.find((questionGroup) => questionGroup._id === someErrorId))
          errorId = incorrectGroupedQuestion.question_id._id
        } 

        const qIndex = sortedQuestions.findIndex((q) => q.question_id._id === errorId)
        setSomeErrorId(errorId)
        setIndex(qIndex)
      })
 
      return
    }

    confirmFinishForm()
  }

  const setNextQuestion = (sorted, currentQuestion) => {
    if (currentQuestion?.next_question?.length === 1 && !currentQuestion?.next_question[0]?.conditions.length) {
      const nextQuestion = questions.find(q => q.question_id._id === currentQuestion.next_question[0].question_id)
      sorted.push(nextQuestion)
      sorted = setNextQuestion(sorted, nextQuestion)
    } else if (answers[currentQuestion?.question_id?._id] || currentQuestion?.next_question?.some(q => !q.conditions.length || q.conditions?.some(c => !c.value))) { // } if (currentQuestion?.next_question?.length > 1 && answers[currentQuestion?.question_id?._id]) {
      const response = answers[currentQuestion?.question_id?._id]
      const currentQuestionIndex = sorted.findIndex(q => q.question_id._id === currentQuestion.question_id._id)

      let nextQuestionId = ''
      sorted.splice(currentQuestionIndex + 1)

      currentQuestion?.next_question?.forEach((nextQuestion, i) => {
        let conditionValue = ''
        let conditionOperator = null

        nextQuestion.conditions?.forEach((condition) => {
          const conditionResult = comparators[condition.operator](response, condition.value)

          if (conditionOperator) {
            conditionValue = comparators[conditionOperator](conditionValue, conditionResult)
          } else {
            conditionValue = conditionResult
          }

          conditionOperator = condition.condition || null
        })

        if (conditionValue) {
          nextQuestionId = nextQuestion.question_id
        }

        if (i >= currentQuestion?.next_question.length - 1 && !nextQuestionId && (!nextQuestion.conditions.length)) {
          nextQuestionId = nextQuestion.question_id
        }
      })

      const nextQuestion = questions.find(q => q.question_id._id === nextQuestionId)

      if (nextQuestion) {
        sorted.push(nextQuestion)

        sorted = setNextQuestion(sorted, nextQuestion)
      }
    }

    return sorted
  }

  const sortQuestions = () => {
    let sorted = []

    let firstQuestionIndex = questions.findIndex(q => q.first_question)

    if (firstQuestionIndex === -1) {
      firstQuestionIndex = 0
    }

    sorted.push(questions[firstQuestionIndex])
    sorted = setNextQuestion(sorted, questions[firstQuestionIndex])
    setSortedQuestions(sorted)
  }

  const sendAnswersToAdmin = () => {
    const parsedQuestions = []
    questions.forEach(question => {
      let answer = answers[question.question_id._id]

      if (question.question_id.type === 'questions_group') {
        answer = answers[question.question_id._id]?.join('|__|')
      }

      parsedQuestions.push({
        question: { ...question.question_id },
        answer: `${answer || ''}`.trim()
      })
    })

    // TODO: send to admin
    // setIsSubmitted(true)
    // console.log(JSON.stringify(parsedQuestions))
  }

  useEffect(() => {
    if (!scoreCardId) {
      const localAnswers = JSON.parse(localStorage.getItem('kiota-questionnaire'))
  
      if (localAnswers?.questionnaireId === questionnaireId) {
        setAnswers(localAnswers.answers)
      } else if (localAnswers) {
        localStorage.removeItem('kiota-questionnaire')
      }
    }

    sortQuestions()
    setIndex(0)
  }, [])

  useEffect(() => {
    try {
      /*const validAnswers = {}
      const regexBase64 = /data:(.*?);base64,(.*?)/g;
      for (const [key, value] of Object.entries(answers)) {
        if (!regexBase64.test(value)) {
          validAnswers[key] = value
        }
      }*/
     
      if (!scoreCardId) {
        localStorage.setItem('kiota-questionnaire', JSON.stringify({ questionnaireId, answers }))
      }
    } catch (error) {

    }
  }, [answers])

  useEffect(() => {
    if (index > -1 && index <= sortedQuestions.length) {
      const sortedQuestionsExcludingSeparators = sortedQuestions.filter((q) => q.question_id.type !== 'separator');
      if (index <= sortedQuestionsExcludingSeparators.length && !sortedQuestions[index]?.question_id.title[language]) {
        setLanguage(language === 'es' ? 'en' : 'es')
      }

      if (sortedQuestionsExcludingSeparators.length) {
        getCompletedPercentage()
      }
    }

    if (!scoreCardId) {
      ref?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [index, sortedQuestions, scoreCardId])

  const goBack = () => {
    setIndex(index - 1)
  }

  const goNext = () => {
    if (sortedQuestions[index]?.next_question?.length > 1 || sortedQuestions[index]?.next_question?.some(q => q.conditions.length)) {
      let sorted = [...sortedQuestions]

      sorted = setNextQuestion(sorted, sortedQuestions[index])

      setSortedQuestions(sorted)
    }

    setTimeout(() => {
      setIndex(index + 1)
    }, 100)
  }
  
  useEffect(() => {
    if (cbFinish) {
      finishForm()
    }
    if (setResultCbFinish) {
      setResultCbFinish(null)
    }
  }, [cbFinish])

  return (
    <>
    {!isSubmitted && (
      <>
        {index >= 0 && (
          <div
            className="relative"
            style={{
              height: visibilityMode === 'single_page' ? 'auto' : containerHeight + 150,
              minHeight: 350,
              marginBottom: 70,
              overflow: 'hidden',
              overflowY: 'auto',
              transition: 'height 0.5s ease-in-out'
            }}
          >

          <div className='max-w-screen-sm mx-auto py-10' ref={ref}>

            {languages.length > 1 && <div className="flex flex-row mb-3 justify-end">
                <div
                  className={'text-xs mr-2 pt-1 flex'}
                >
                  {t('select_language')}
                  </div>
                <div
                  onClick={() => changeLanguage('es')}
                  className={`w-8 p-1 hover:shadow-inner rounded-md cursor-pointer border-2 bg-white ${language === 'es' ? `shadow-inner border-main-${Config.PLATFORM}` : 'shadow-soft-white'}`}
                >
                  <img src={Spanish} alt="Spanish" />
                </div>

                <div
                  onClick={() => changeLanguage('en')}
                  className={`bg-white w-8 p-1 hover:shadow-inner ml-2 rounded-md cursor-pointer border-2 ${language === 'en' ? `shadow-inner border-main-${Config.PLATFORM}` : 'shadow-soft-white'}`}
                >
                  <img src={English} alt="English" style={{ height: 13 }} />
                </div>
            </div>}</div>
            { sortedQuestions.map((question, indexElement) => {
              return (
                <PublicQuestionnaireQuestion
                  key={indexElement}
                  currentQuestion={question}
                  indexElement={indexElement}
                  currentIndex={index}
                  goBack={goBack}
                  goNext={goNext}
                  answers={answers}
                  setAnswers={setAnswers}
                  questionErrors={questionErrors}
                  setQuestionErrors={setQuestionErrors}
                  checkCurrentQuestionErrors={checkCurrentQuestionErrors}
                  setLoading={setLoading}
                  setContainerHeight={setContainerHeight}
                  questionsLength={sortedQuestions.length}
                  visibilityMode={visibilityMode}
                  showInvestorsPercentage={showInvestorsPercentage}
                  language={language}
                  someErrorId={someErrorId}
                  expandedQuestionsErrors={expandedQuestionsErrors}
                  sortedQuestions={sortedQuestions}
                  formId={formId}
                  investorId={investorId}
                  scoreCardId={scoreCardId}
                  shared={shared}
                ></PublicQuestionnaireQuestion>
              )
            }) }

            {visibilityMode === 'single_page' && (
                <div className="max-w-screen-sm mx-auto">
                  <div className={`flex ${!isDraft && !isPreview ? 'justify-end' : 'justify-start'} mt-5`}>
                    {!isDraft && !isPreview && confirmRetries < 1 && <ButtonMain
                      iconComponent={<FaRocket className="inline-block mr-2" />}
                      onClick={() => {
                        finishForm()
                      }}
                      text={t('submit')}
                    />}
                    {!isDraft && !loading && confirmRetries >= 1 && <ButtonMain
                      iconComponent={<FaRocket className="inline-block mr-2" />}
                      onClick={() => sendAnswersToAdmin()}
                      text={t('send_to_admin')}
                    />}
                  </div>
                </div>
            )}

            {visibilityMode !== 'single_page' && (
              <div className={`transition duration-1000 ease-in-out w-full $ absolute ${index >= sortedQuestions.length ? '' : 'translate-x-full'}`}>
                <div className="max-w-screen-sm mx-auto">
                  <div className="flex justify-center mt-10">
                    <h3 className="text-center">{percentageCompleted === 100 && (isDraft || isPreview) ? t('draft_completed') : percentageCompleted === 100 && !isDraft && !isPreview ? t('form_completed') : t('pending_questions_description')}</h3>
                  </div>
                  <div className={`flex ${!isDraft && !isPreview ? 'justify-between' : 'justify-start'} mt-5`}>
                    <FaChevronLeft
                      className={`w-8 h-8 hover:shadow-inner text-main-${Config.PLATFORM} rounded-lg p-1 cursor-pointer`}
                      onClick={() => goBack()}
                    />
                    {!isDraft && !isPreview && percentageCompleted === 100 && confirmRetries < 1 && <ButtonMain
                      iconComponent={<FaRocket className="inline-block mr-2" />}
                      onClick={() => finishForm()}
                      text={t('submit')}
                    />}
                    {!isDraft && !loading && confirmRetries >= 1 && <ButtonMain
                      iconComponent={<FaRocket className="inline-block mr-2" />}
                      onClick={() => sendAnswersToAdmin()}
                      text={t('send_to_admin')}
                    />}
                  </div>
                </div>
              </div>
            )}

          </div>
        )}
      </>
    )}

    {isSubmitted && (
      <div className="w-full">
      <div className="max-w-screen-sm mx-auto">
        <Card
          wrapperClassName="bg-white mb-6"
          containerClassName={`text-center text-main-${Config.PLATFORM} text-lg my-6`}
        >
          <div>
            <FaCheckCircle
              className="text-green inline-block mb-6"
              size={70}
            />
          </div>
          {descriptionFormEnded ? <div
            dangerouslySetInnerHTML={{ __html: descriptionFormEnded }}
          /> : <div>{t('application_submitted')}</div>}
        </Card>
      </div>
      </div>
    )}
    </>
  )
}
