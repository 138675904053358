import { useTranslation } from 'react-i18next'
import { Button } from 'components/ui/atoms/Button'
import { SidePanel } from 'components/ui/objects/SidePanel'
import { Input } from 'components/ui/atoms/Input'
import InputFile from 'components/ui/atoms/input-files'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useEffect, useMemo, useState } from 'react'
import FormUtils from 'utils/form-utils'
import { useToasts } from 'react-toast-notifications'
import JsonPreview from 'styles/images/json_preview.svg'
import ExcelPreview from 'styles/images/excel_preview.svg'
import CSVPreview from 'styles/images/csv_preview.svg'
import KiotaExpressXlsxSample from 'assets/sample/KiotaExpressSample.xlsx'
import KiotaExpressCsvSample from 'assets/sample/KiotaExpressSample.csv'
import KiotaExpressJsonSample from 'assets/sample/KiotaExpressSample.json'
import InvestmentStagesData from 'assets/json/investment-stages.json'
import Countries from 'assets/json/countries.json'
import Sectors from 'assets/json/sectors.json'

import Utils from 'utils/utils'
import Select from 'components/ui/atoms/Select'
import { Autocomplete } from 'components/ui/atoms/Autocomplete'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

const KiotaGammaEvaluationPannel = ({
  onClose,
  onSubmit,
  expandedInformation,
  dealflow = false
}) => {

    const { t } = useTranslation()
    const [selectedFile, setSelectedFile] = useState(null)
    const [fileError, setFileError] = useState(false)
    const { addToast } = useToasts()

    const investmentStages = InvestmentStagesData.map(item => ({
        ...item,
        id: item._id,
        name: t(item.key)
      }))
    
    const validationShape = useMemo(() => ({
        last_investment_round_lead_investors: Yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
        last_investment_round_investor_names: Yup.string().nullable(),
        last_investment_round_lead_investor_names: Yup.string().nullable(),
        organization_name: Yup.string().required(t('required_field')),
        crunchbase_url: Yup.string().nullable(),
        funding_type: Yup.string().required(t('required_field')),
        last_investment_round_money_raised: Yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
        last_investment_round_announced_date: Yup.string().nullable(),
        last_investment_round_pre_money_valuation: Yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
        last_investment_round_equity_only_funding: Yup.string().nullable(),
        industries: Yup.string().required(t('required_field')),
        incorporation_country: Yup.string().nullable(),
        total_funding_amount: Yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
        funding_rounds: Yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
        last_investment_round_investors: Yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
        last_investment_round_partner_investors: Yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable()
    }), [])

  const { register, errors, setValue, trigger, getValues, watch } = useForm({
    resolver: yupResolver(Yup.object().shape(validationShape), { abortEarly: false }),
    criteriaMode: 'all',
    reValidateMode: 'all',
    mode: 'onChange'
  })

  /*const setFormValues = (data) => {
    if (data.hasOwnProperty('last_investment_round_lead_investors')) {
        setValue('last_investment_round_lead_investors', data.last_investment_round_lead_investors)
    }
    if (data.hasOwnProperty('last_investment_round_investor_names')) {
        setValue('last_investment_round_investor_names', data.last_investment_round_investor_names)
    }
    if (data.hasOwnProperty('last_investment_round_lead_investor_names')) {
        setValue('last_investment_round_lead_investor_names', data.last_investment_round_lead_investor_names)
    }
    if (data.hasOwnProperty('organization_name')) {
        setValue('organization_name', data.organization_name)
    }
    if (data.hasOwnProperty('crunchbase_url')) {
        setValue('crunchbase_url', data.crunchbase_url)
    }
    if (data.hasOwnProperty('funding_type')) {
        setValue('funding_type', data.funding_type)
    }
    if (data.hasOwnProperty('last_investment_round_money_raised')) {
        setValue('last_investment_round_money_raised', data.last_investment_round_money_raised)
    }
    if (data.hasOwnProperty('last_investment_round_announced_date')) {
        setValue('last_investment_round_announced_date', data.last_investment_round_announced_date)
    }
    if (data.hasOwnProperty('last_investment_round_pre_money_valuation')) {
        setValue('last_investment_round_pre_money_valuation', data.last_investment_round_pre_money_valuation)
    }
    if (data.hasOwnProperty('last_investment_round_equity_only_funding')) {
        setValue('last_investment_round_equity_only_funding', data.last_investment_round_equity_only_funding)
    }
    if (data.hasOwnProperty('industries')) {
        setValue('industries', data.industries)
    }
    if (data.hasOwnProperty('incorporation_country')) {
        setValue('incorporation_country', data.incorporation_country)
    }
    if (data.hasOwnProperty('total_funding_amount')) {
        setValue('total_funding_amount', data.total_funding_amount)
    }
    if (data.hasOwnProperty('funding_rounds')) {
        setValue('funding_rounds', data.funding_rounds)
    }
    if (data.hasOwnProperty('last_investment_round_investors')) {
        setValue('last_investment_round_investors', data.last_investment_round_investors)
    }
    if (data.hasOwnProperty('last_investment_round_partner_investors')) {
        setValue('last_investment_round_partner_investors', data.last_investment_round_partner_investors)
    }
  }*/

  const KEYS_ASSOCIATION = {
    Startup: 'organization_name',
    'Funding type': 'funding_type',
    Industries: 'industries',
    Country: 'incorporation_country',
    'Crunchbase URL': 'crunchbase_url',
    'Funding rounds': 'funding_rounds',
    'Total funding amount': 'total_funding_amount',
    'What was the pre money valuation before last investment round?': 'last_investment_round_pre_money_valuation',
    'How much money raised in last investment round?': 'last_investment_round_money_raised',
    'When was the last investment round announced?': 'last_investment_round_announced_date',
    'Was the last investment round an only equity funding?': 'last_investment_round_equity_only_funding',
    'How many investors had the last investment round?': 'last_investment_round_investors',
    'Who were the investors for the last investment round?': 'last_investment_round_investor_names',
    'How many lead investors had the last investment round?': 'last_investment_round_lead_investors',
    'Who were the lead investors for the last investment round?': 'last_investment_round_lead_investor_names',
    'How many partner investors had the last investment round?': 'last_investment_round_partner_investors',
  }

  const parseCSVToKeys = (csv) => {
    const jsonArray = Utils.csvJSON(csv)
    const json = jsonArray[0]

    Object.keys(json).forEach((key, index) => {
        json[KEYS_ASSOCIATION[key]] = json[key]
        delete json[key]
    })

    return json
  }

  const parseXlsxToKeys = async (file) => {
    const jsonArray = await Utils.xlsxToJson(file)
    const json = jsonArray[0]

    Object.keys(json).forEach((key, index) => {
        json[KEYS_ASSOCIATION[key]] = json[key]
        delete json[key]
    })

    return json
  }

  const selectFile = async (file) => {
    if (!file) {
      return
    }

    try {
        let data = null
        let readFile = null
        if (file.type === 'text/csv') {
            readFile = await FormUtils.readFile(file)
            data = parseCSVToKeys(readFile)
        } else if (file.type === 'application/json') {
            readFile = await FormUtils.readFile(file)
            data = JSON.parse(readFile)
        } else {
            data = await parseXlsxToKeys(file)
        }

        // setFormValues(data)
        setSelectedFile(file)
    } catch (error) {
        console.log(error)
        addToast(t('incorrect_format'), { appearance: 'error', autoDismiss: true })
    }
  }

  const hydrateForm = () => {
    Object.keys(validationShape).forEach(key => {
      setValue(key, expandedInformation[key])
    })

    if (expandedInformation.last_investment_round_announced_date) {
      setValue(
        'last_investment_round_announced_date',
        FormUtils.getDateInputValueFromDate(new Date(expandedInformation.last_investment_round_announced_date))
      )
    }

    setValue('industries', expandedInformation?.industries)
    setValue('funding_type', expandedInformation?.funding_type)
    setValue(
      'incorporation_country',
      expandedInformation?.incorporation_country
    )

    trigger()
  }

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config)
    trigger(name)
    }

    useEffect(() => {
        register('industries')
        register('funding_type')
        register('last_investment_round_equity_only_funding')
        register('incorporation_country')
    }, [register])

  useEffect(() => {
    if (expandedInformation) {
       hydrateForm()
    }
  }, [expandedInformation])

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })
  
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  const YES_NO_OPTIONS = [
    { id: 'yes', value: t('yes') },
    { id: 'no', value: t('no') }
  ]

  const sectors = Sectors
      .map(item => ({ name: t(item.key), id: item._id }))
      .sort((a, b) => a.name > b.name ? 1 : -1)

    const countries = Countries.map(item => ({
        ...item,
        id: item._id,
        name: t(item.key)
      }))

    const getSelectableInitialValues = (id, collection, lonely) => {
        if (!id) {
          return lonely ? null : []
        }
    
        if (Array.isArray(id)) {
          return collection.filter(i => id.includes(i.id))
        }
    
        const item = collection.find(i => i.id === id)
        return item ? lonely ? item : [item] : lonely ? null : []
      }

  return (
    <SidePanel
        width={'2/5'}
        onClose={onClose}
        title={expandedInformation ? expandedInformation.organization_name : t('request_evaluation')}
    >
        <div>
            <div>
                <Input
                    reference={register}
                    id='organization_name'
                    type='text'
                    name='organization_name'
                    label={`${t('organization_name_label')}*:`}
                    placeholder={t('organization_name_placeholder')}
                    error={errors.organization_name}
                    disabled={(!dealflow && expandedInformation) || !!expandedInformation?.organization_name}
                />
            </div>
            <div>
            <Autocomplete
                isClearable={false}
                name="funding_type"
                required={true}
                placeholder={t('introduce_investment_stage')}
                label={t('investment_stage')}
                options={investmentStages}
                error={errors.funding_type}
                disabled={(!dealflow && expandedInformation) || !!expandedInformation?.funding_type}
                initialValues={getSelectableInitialValues(expandedInformation?.funding_type, investmentStages, true)}
                onSelect={stage => _setValue('funding_type', stage ? stage.id : null)}
            />
            </div>
            <div>
            <Autocomplete
                isClearable={false}
                name="industries"
                placeholder={t('choose_or_type_sector')}
                label={t('sector')}
                required={true}
                options={sectors}
                initialValues={getSelectableInitialValues(expandedInformation?.industries, sectors, true)}
                error={errors.industries}
                disabled={(!dealflow && expandedInformation) || !!expandedInformation?.industries}
                onSelect={sector => _setValue('industries', sector ? sector.id : null)}
                />
            </div>
            <div>
                <Autocomplete
                    name="incorporation_country"
                    placeholder={t('choose_or_type_country_incorporation')}
                    options={countries}
                    label={t('country_incorporation')}
                    required={false}
                    disabled={(!dealflow && expandedInformation) || !!expandedInformation?.incorporation_country}
                    initialValues={getSelectableInitialValues(expandedInformation?.incorporation_country, countries, true)}
                    error={errors.incorporation_country}
                    onSelect={country => {
                        _setValue('incorporation_country', country ? country.id : null)
                }}
            />
            </div>
            <div>
                <Input
                    reference={register}
                    id='crunchbase_url'
                    type='text'
                    name='crunchbase_url'
                    label={`${t('crunchbase_url_label')}:`}
                    placeholder={t('crunchbase_url_placeholder')}
                    error={errors.crunchbase_url}
                    disabled={(!dealflow && expandedInformation) || !!expandedInformation?.crunchbase_url}
                />
            </div>
            <div>
                <Input
                    reference={register}
                    id='funding_rounds'
                    type='number'
                    name='funding_rounds'
                    label={`${t('funding_rounds_label')}:`}
                    placeholder={t('funding_rounds_placeholder')}
                    error={errors.funding_rounds}
                    disabled={(!dealflow && expandedInformation)}
                />
            </div>
            <div>
                <Input
                    reference={register}
                    id='total_funding_amount'
                    type='number'
                    name='total_funding_amount'
                    label={`${t('total_funding_amount_label')}:`}
                    placeholder={t('total_funding_amount_placeholder')}
                    error={errors.total_funding_amount}
                    disabled={(!dealflow && expandedInformation)}
                />
            </div>
            <div>
                <Input
                    reference={register}
                    id='last_investment_round_pre_money_valuation'
                    type='number'
                    name='last_investment_round_pre_money_valuation'
                    label={`${t('last_investment_round_pre_money_valuation_label')}:`}
                    placeholder={t('last_investment_round_pre_money_valuation_placeholder')}
                    error={errors.last_investment_round_pre_money_valuation}
                    disabled={(!dealflow && expandedInformation)}
                />
            </div>
            <div>
                <Input
                    reference={register}
                    id='last_investment_round_money_raised'
                    type='number'
                    name='last_investment_round_money_raised'
                    label={`${t('last_investment_round_money_raised_label')}:`}
                    placeholder={t('last_investment_round_money_raised_placeholder')}
                    error={errors.last_investment_round_money_raised}
                    disabled={(!dealflow && expandedInformation)}
                />
            </div>
            <div>
                <Input
                    reference={register}
                    id='last_investment_round_announced_date'
                    name="last_investment_round_announced_date"
                    placeholder={t('last_investment_round_announced_date_placeholder')}
                    label={t('last_investment_round_announced_date_label')}
                    type="date"
                    error={errors.last_investment_round_announced_date}
                    disabled={(!dealflow && expandedInformation)}
                />
            </div>
            
            <div>
                <Select
                    name="last_investment_round_equity_only_funding"
                    label={t('last_investment_round_equity_only_funding_label')}
                    reference={register}
                    required={false}
                    placeholder={t('last_investment_round_equity_only_funding_placeholder')}
                    error={errors.last_investment_round_equity_only_funding}
                    initialValues={getSelectableInitialValues(watch('last_investment_round_equity_only_funding'), YES_NO_OPTIONS)}
                    items={YES_NO_OPTIONS}
                    onSelect={selected => {
                        _setValue('last_investment_round_equity_only_funding', selected.length ? selected[0].id : null)
                    }}
                />
            </div>
            <div>
                <Input
                    reference={register}
                    id='last_investment_round_investors'
                    type='number'
                    name='last_investment_round_investors'
                    label={`${t('last_investment_round_investors_label')}:`}
                    placeholder={t('last_investment_round_investors_placeholder')}
                    error={errors.last_investment_round_investors}
                    disabled={(!dealflow && expandedInformation)}
                />
            </div>
            <div>
                <Input
                    reference={register}
                    id='last_investment_round_investor_names'
                    type='text'
                    name='last_investment_round_investor_names'
                    label={`${t('last_investment_round_investor_names_label')}:`}
                    placeholder={t('last_investment_round_investor_names_placeholder')}
                    error={errors.last_investment_round_investor_names}
                    disabled={(!dealflow && expandedInformation)}
                />
            </div>
            <div>
                <Input
                    reference={register}
                    id='last_investment_round_lead_investors'
                    type='number'
                    name='last_investment_round_lead_investors'
                    label={`${t('last_investment_round_lead_investors_label')}:`}
                    placeholder={t('last_investment_round_lead_investors_placeholder')}
                    error={errors.last_investment_round_lead_investors}
                    disabled={(!dealflow && expandedInformation)}
                />
            </div>
            
            <div>
                <Input
                    reference={register}
                    id='last_investment_round_lead_investor_names'
                    type='text'
                    name='last_investment_round_lead_investor_names'
                    label={`${t('last_investment_round_lead_investor_names_label')}:`}
                    placeholder={t('last_investment_round_lead_investor_names_placeholder')}
                    error={errors.last_investment_round_lead_investor_names}
                    disabled={(!dealflow && expandedInformation)}
                />
            </div>
            <div>
                <Input
                    reference={register}
                    id='last_investment_round_partner_investors'
                    type='number'
                    name='last_investment_round_partner_investors'
                    label={`${t('last_investment_round_partner_investors_label')}:`}
                    placeholder={t('last_investment_round_partner_investors_placeholder')}
                    error={errors.last_investment_round_partner_investors}
                    disabled={(!dealflow && expandedInformation)}
                />
            </div>
            {(dealflow || (!dealflow && !expandedInformation)) && <div className='flex justify-end'>
                <ButtonMain
                    onClick={async () => {
                        const validation = await trigger()
                        if (validation) { onSubmit({ ...getValues() }) }
                        else {
                            addToast(t('form_field_error'), { appearance: 'error', autoDismiss: true })
                        }
                    }}
                    text={t('evaluate')}
                />
            </div>}
        </div>
    </SidePanel>)
}

export default KiotaGammaEvaluationPannel
