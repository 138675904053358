import React from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import Avatar from 'react-avatar'
import Signeblock from 'styles/images/signeblock-icon.svg'

import moment from 'moment'

import VideoPreview from 'styles/images/video_preview.svg'
import PdfPreview from 'styles/images/pdf_preview.svg'
import WordPreview from 'styles/images/word_preview.svg'
import ExcelPreview from 'styles/images/excel_preview.svg'
import PptPreview from 'styles/images/ppt_preview.svg'
import JsonPreview from 'styles/images/json_preview.svg'
import DefaultPreview from 'styles/images/default_preview.svg'

import { PopoverDocuments } from 'components/ui/atoms/PopoverDocuments'
import Upload from 'styles/images/Upload.svg'
import { IconContainer } from 'components/ui/atoms/IconContainer'
import { Switch } from 'components/ui/atoms/Switch'
import { Button } from 'components/ui/atoms/Button'
import { FaDownload, FaEye } from 'react-icons/fa'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import Config from 'config'
import { Card } from 'components/ui/atoms/Card'

const Document = ({
  dataRoomWizard = false,
  section,
  popoverMenu,
  popoverMenuOptions,
  popoverMenuMethods,
  document = null,
  handleUploadFile,
  downloadFile = null,
  viewFile = null,
  specializedSubtitle,
  specializedBg,
  documentSpecialized = false,
  dataRoomLanding = false,
  downloadable,
  handleDownloadable
}) => {
  const { t } = useTranslation()

  const previewIcons = {
    default: DefaultPreview,
    pdf: PdfPreview,
    PDF: PdfPreview,
    docx: WordPreview,
    doc: WordPreview,
    json: JsonPreview,
    ppt: PptPreview,
    pptx: PptPreview,
    xls: ExcelPreview,
    xlsx: ExcelPreview,
    mp4: VideoPreview,
    avi: VideoPreview,
    mov: VideoPreview
  }


  const getType = (file) => {
    return previewIcons[file.split('.').at(-1)]
      ? previewIcons[file.split('.').at(-1)]
      : previewIcons.default
  }
  return (
    <Card>
      <div
        className={`relative flex flex-col align-between`}
      >
        <div className="flex justify-between">
          <p
            className={classnames(
              `w-44 sm:w-full font-semibold truncate  text-xs ${
                documentSpecialized
                  ? 'text-' + `${specializedSubtitle}`
                  : `text-main-${Config.PLATFORM} mb-2`
              }`
            )}
          >
            {document.name}
          </p>
          <div>
            {popoverMenu && (
              <PopoverDocuments
                options={popoverMenuOptions}
                methods={popoverMenuMethods}
              />
            )}
          </div>
        </div>

        <div
          className={`mx-2 my-2 ${
            dataRoomLanding ? 'h-24' : 'h-32'
          } flex justify-around items-center relative`}
        >
          {document
            ? (
            <>
              <div
                className={`
                text-main-${Config.PLATFORM} text-center text-xs font-medium | flex flex-col items-center  |  outline-none focus:outline-none hover:underline`}
              >
                <img
                  src={
                    getType(document.name ? document.name : document.path) || {
                      DefaultPreview
                    }
                  }
                  className="w-14 mb-2"
                  alt="Icon preview"
                />
                {!dataRoomLanding &&
                  (document.name
                    ? ['pdf', 'PDF'].includes(document.name.split('.').at(-1))
                    : ['pdf', 'PDF'].includes(document.path.split('.').at(-1))) && (
                    <div className={'text-xs mt-2 mb-1 flex'}>
                      <span className={'pt-1.5 pr-2'}>{t('downloadable')}</span>
                      <Switch
                        checked={downloadable}
                        onChange={checked => {
                          handleDownloadable(checked)
                        }}
                      />
                    </div>
                )}
              </div>
              <div className="absolute right-12 bottom-5 ">
                {document.flow_id && document.step_id && (
                  <span>
                    <img src={Signeblock} alt="Tick icon" className="w-6" />
                  </span>
                )}
              </div>
            </>
              )
            : (
            <p className="text-gray text-sm">{t('document_not_uploaded')}</p>
              )}
        </div>

        <div className={`border-t border-gray-lines flex ${document.owner && 'justify-between'} pt-2`}>
          {document && !dataRoomWizard && (
            <>
              <div>
                <span
                  className={classnames(
                    `${
                      documentSpecialized
                        ? 'text-' + `${specializedSubtitle}`
                        : `text-main-${Config.PLATFORM}`
                    } text-xxs font-medium`
                  )}
                >
                  {`${t('upload_date')}:`}
                </span>
                <span className="text-gray text-xxs font-normal ml-2">
                  {moment(document.uploaded_at).format('YYYY-MM-DD')}
                </span>
              </div>
              {document.owner && <div>
                <Avatar
                  src={document.owner.image ? document.owner.image : null}
                  name={document.owner.image ? null : document.owner.name}
                  color="#e0e6f2"
                  fgColor="#4d70b3"
                  size="25px"
                  round="8px"
                />
              </div>}
            </>
          )}
          {(dataRoomWizard || (!document && !dataRoomWizard)) &&
            handleUploadFile && (
              <>
                <div className="w-full flex justify-end items-center">
                  <IconContainer
                    width="8"
                    height="8"
                    shadow="hover"
                    shadowHover="inner"
                    iconWidth="5"
                    icon={Upload}
                    onClick={() => handleUploadFile(section)}
                  />
                </div>
              </>
          )}
        </div>
          {document && dataRoomLanding && (
          <div className={`flex justify-end mt-2 pt-2 border-t border-separator-${Config.PLATFORM}`}>
          <div className='flex justify-center'>
                {viewFile && (<ButtonSecondary
                  text={t('see')}
                  onClick={() => viewFile()}
                  verticalMargin="1"
                  iconComponent={<FaEye className="inline-block w-5 h-4" />}
                  marginRight="2"
                />)}
                {document.downloadable && <ButtonSecondary
                  text={t('download')}
                  onClick={() => downloadFile()}
                  iconComponent={<FaDownload className="inline-block w-5 h-4" />}
                />}
              </div>
            </div>
          )}
      </div>
    </Card>
  )
}

export default Document
