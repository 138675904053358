import Arrow from 'styles/images/arrow_right_square.svg'


export function AccountTypeCard ({
  onClick,
  type,
  title,
  subTitle,
  positionImgBg = 'bg-left',
  positionText = 'items-end justify-end text-right',
  imgBgSource,
  style,
  classes
}) {
  return (
    <div
      onClick={onClick}
      style={{ backgroundImage: `url(${imgBgSource})`, ...style }}
      className={
        `h-52 sm:h-60 sm:w-full flex flex-col 
          bg-no-repeat p-8 mt-8 mb-0 
        rounded-2xl bg-white cursor-pointer 
        ${positionImgBg} ${classes}`
      }
    >
      <div
        className={
          `h-full w-auto flex flex-col 
          ${positionText}`
        }
      >
        <div className="flex">
          <h2 className="mb-2 mt-0">{title}</h2>
          <img
            className="ml-2 cursor-pointer"
            onClick={onClick}
            src={Arrow}
            alt="Arrow icon"
          />
        </div>
        <p className="font-normal w-72">{subTitle}</p>
      </div>
    </div>
  )
}
