/* eslint-disable default-case */
import produce from 'immer'

import { NotificationsActionsTypes } from './notifications-actions-types'
import { UserActionsTypes } from '../user/user-actions-types'

const { SET_INVITATIONS } = NotificationsActionsTypes
const { RESET: LOGOUT } = UserActionsTypes

const defaultState = {
  invitations: false
}

const InvitationsReducer = (state = defaultState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_INVITATIONS:
        draft.invitations = action.payload.invitations
        break
      case LOGOUT:
        return defaultState
    }
  })

export default InvitationsReducer
