import { useTranslation } from 'react-i18next'
import { SidePanel } from 'components/ui/objects/SidePanel'
import { useEffect } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import Config from 'config'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { TextArea } from 'components/ui/atoms/TextArea'
import { Input } from 'components/ui/atoms/Input'
import FormUtils from 'utils/form-utils'
import { useToasts } from 'react-toast-notifications'

/**
 * @param onClose
 * @returns {JSX.Element}
 * @constructor
 */
const PortfolioJobSection = ({ onClose, addJobToPortfolio, initialValues, updateJobFromPortfolio }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const { register, handleSubmit, errors, setValue, trigger, watch, getValues } = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    reValidateMode: 'all',
    resolver: yupResolver(Yup.object().shape({
        title: Yup.string().required(t('required_field')),
        company: Yup.string(),
        company_logo: Yup.string()
            .transform(value => !value ? null : value)
            .matches(FormUtils.URL_REGEX, t('incorrect_format'))
            .nullable(),
        company_url: Yup.string()
            .transform(value => !value ? null : value)
            .matches(FormUtils.URL_REGEX, t('incorrect_format'))
            .nullable(),
        link: Yup.string()
            .transform(value => !value ? null : value)
            .matches(FormUtils.URL_REGEX, t('incorrect_format'))
            .nullable(),
        description: Yup.string().nullable()
      }), { abortEarly: false })
  })

  useEffect(() => {
    if (initialValues) {
        setValue('title', initialValues.title)
        setValue('link', initialValues.link)
        setValue('description', initialValues.description || '')
        setValue('company', initialValues.company)
        setValue('company_logo', initialValues.company_logo || '')
        setValue('company_url', initialValues.company_url || '')
    }
    trigger()
  }, [initialValues])

  return (
    <>
      <SidePanel
        width={'1/4'}
        onClose={onClose}
        title={t(initialValues ? 'update_job_from_portfolio' : 'add_job_to_portfolio')}
      >
        <div className="mt-4 flex flex-col w-full">
            <div className="w-full">
            <Input
            required={true}
              reference={register}
              name="title"
              placeholder={t('title')}
              label={t('title')}
              type="string"
              error={errors.title}
            />
            <TextArea
                reference={register}
                name="description"
                error={errors.description}
                label={t('description')}
                maxLength={200}
                placeholder={t('description')}
            />
            <Input
              reference={register}
              name="link"
              placeholder={t('portfolio_job_link_placeholder')}
              label={t('link')}
              type="string"
              error={errors.link}
            />
            <Input
              reference={register}
              name="company"
              placeholder={t('company')}
              label={t('company')}
              type="string"
              error={errors.company}
            />
            <Input
              reference={register}
              name="company_logo"
              placeholder={t('company_logo_url_placeholder')}
              label={t('company_logo')}
              type="string"
              error={errors.company_logo}
            />
            <Input
              reference={register}
              name="company_url"
              placeholder={t('company_url_placeholder')}
              label={t('company_url')}
              type="string"
              error={errors.company_url}
            />
            </div>
            <div className={`flex justify-end mt-2 pt-2 border-t border-separator-${Config.PLATFORM}`}>
              <ButtonMain
                onClick={async () => {
                    const validation = await trigger()
                    if (validation) {
                        if (initialValues) {
                            updateJobFromPortfolio(getValues())
                        } else {
                            addJobToPortfolio(getValues())
                        }
                    }
                    else {
                        addToast(t('form_field_error'), { appearance: 'error', autoDismiss: true })
                    }
                }}
                text={t(initialValues ? 'update_job_from_portfolio' : 'add_job_to_portfolio')}
              />
            </div>
        </div>
      </SidePanel>
    </>
  )
}

export default PortfolioJobSection
