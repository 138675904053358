import { useTranslation } from 'react-i18next'
import { SidePanel } from 'components/ui/objects/SidePanel'
import { FaBell, FaEdit, FaEuroSign, FaExclamationCircle, FaFileDownload, FaHandshake, FaPaperPlane, FaTags, FaTimesCircle, FaTrash } from 'react-icons/fa'

import Config from 'config';
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { ButtonDanger } from 'components/ui/atoms/ButtonDanger'
import { GrLike } from 'react-icons/gr';
import { AiOutlineLike } from 'react-icons/ai';

const DealflowActions = ({
  onClose,
  showTags,
  downloadOnePager,
  downloadKpisReport,
  deleteDeal,
  editInfo,
  registerInvestment,
  markAsFailed,
  markAsInvested,
  markAsInteresting,
  registerExit,
  rejectDeal,
  invested,
  rejected,
  failed,
  exited,
  committed,
  interesting,
  markAsCommitted,
  contact,
  addReminder
}) => {
  const { t } = useTranslation()

  return (
    <SidePanel
      onClose={onClose}
      title={t('actions')}
    >
      {downloadOnePager && (
        <ButtonSecondary
          width={'full'}
          marginTop={2}
          onClick={() => downloadOnePager()}
          text={t('one_pager')}
          iconComponent={<FaFileDownload className="inline-block mr-3" />}
        />
      )}

      {downloadKpisReport && (
        <ButtonSecondary
          marginTop={2}
          width={'full'}
          onClick={() => downloadKpisReport()}
          text={t('kpis_report')}
          iconComponent={<FaFileDownload className="inline-block mr-3" />}
        />
      )}

      {!invested && !failed && !exited && !rejected && !committed && <ButtonSecondary
        width={'full'}
        marginTop={2}
        onClick={rejectDeal}
        text={t('reject_startup')}
        iconComponent={<FaTimesCircle className="inline-block mr-3" />}
      />}
      {!invested && !failed && !exited && !rejected && !committed && <ButtonSecondary
        width={'full'}
        marginTop={2}
        onClick={markAsFailed}
        text={t('mark_as_failed')}
        iconComponent={<FaExclamationCircle className="inline-block mr-3" />}
        />}
      {!interesting && !invested && !failed && !exited && !rejected && <ButtonSecondary
        width={'full'}
        marginTop={2}
        onClick={markAsInteresting}
        text={t('mark_as_interesting')}
        iconComponent={<AiOutlineLike className="inline-block mr-3" />}
      />}
      {/*{!invested && !failed && !exited && !rejected && !committed && <ButtonSecondary
        width={'full'}
        marginTop={2}
        onClick={markAsCommitted}
        text={t('mark_as_committed')}
        iconComponent={<FaHandshake className="inline-block mr-3 w-4 h-4" />}
      />}*/}
      {!invested && !failed && !exited && !rejected && <ButtonSecondary
        width={'full'}
        marginTop={2}
        onClick={markAsInvested}
        text={t('mark_as_invested')}
        iconComponent={<FaEuroSign className="inline-block mr-3" />}
      />}
      {!rejected && !failed && !exited && <ButtonSecondary
        width={'full'}
        marginTop={2}
        onClick={registerInvestment}
        text={t('register_investment')}
        iconComponent={<FaEuroSign className="inline-block mr-3" />}
      />}
      {!rejected && !failed && !exited && <ButtonSecondary
        width={'full'}
        marginTop={2}
        onClick={registerExit}
        text={t('register_exit')}
        iconComponent={<FaHandshake className="inline-block mr-3 w-4 h-4" />}
      />}
      <ButtonSecondary
        width={'full'}
        marginTop={2}
        onClick={() => showTags()}
        text={t('manage_tags')}
        iconComponent={<FaTags className="inline-block mr-3" />}
      />

      <ButtonSecondary
        marginTop={2}
        width={'full'}
        onClick={() => addReminder()}
        text={t('add_reminder')}
        iconComponent={<FaBell className="inline-block mr-3" />}
      />
      {editInfo && <ButtonSecondary
        marginTop={2}
        width={'full'}
        onClick={() => editInfo()}
        text={t('edit_info')}
        iconComponent={<FaEdit className="inline-block mr-3" />}
      />}
      {contact && <ButtonSecondary
        text={t('contact')}
        marginTop={2}
        width={'full'}
        iconComponent={<FaPaperPlane className='inline mr-1' />}
        onClick={() => contact()}
      />}

      <div className={`flex justify-end mt-4 pt-2 border-t border-separator-${Config.PLATFORM}`}>
        <ButtonDanger
          onClick={() => deleteDeal()}
          text={t('delete')}
          iconComponent={<FaTrash className="inline-block mr-3" />}
          width={'full'}
        />
      </div>
    </SidePanel>
  )
}

export default DealflowActions
