import React from 'react'
import Logo from 'styles/images/logo_light.svg'
import { useTranslation } from 'react-i18next'
import moment from "moment";
export const SharedEventFooter = () => {
  const { t } = useTranslation()

  return (
    <footer className="w-full h-16 flex justify-between items-center border-t px-8 border-gray-lines bg-white">
      <div>
        <span className="text-gray text-xxs">{`${t('copyright')} @${moment().format('YYYY')} ${t('copyright_rights')}`}</span>
      </div>
      <div>
        <img src={Logo} alt="Logo Kiota" />
      </div>
    </footer>
  )
}
