import 'react-quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import QuillToolbar, { formats } from './RichEditorToolbar'

export const RichEditor = ({
  label,
  id,
  name,
  value,
  onChange,
  placeholder,
  disabled,
  reference,
  error,
  className,
  toolbarClassName
}) => {
  const _onChange = (content, delta, source, editor) => {
    onChange && source === 'user' && onChange(editor.getHTML())
  }

  return (
    <>
      {label && (
        <label htmlFor={id} className="block mb-1 text-left text-xs font-medium">
          {label}
        </label>
      )}
      <QuillToolbar toolbarClassName={toolbarClassName} />
      <ReactQuill
        modules={{ toolbar: `.${toolbarClassName}` }}
        ref={reference}
        id={id}
        name={name}
        value={value}
        defaultValue={value}
        theme="snow"
        onChange={_onChange}
        placeholder={placeholder}
        readOnly={disabled}
        className={className}
        formats={formats}
        toolbarClassName={toolbarClassName}
        disabled={true}
      />

      {error && (
        <div className="text-red text-xxs">
          {error.message}
        </div>
      )}
    </>

  )
}
