import { NotificationsActionsTypes } from './notifications-actions-types'

const NotificationsActions = {
  setInvitations: (invitations) => ({
    type: NotificationsActionsTypes.SET_INVITATIONS,
    payload: { invitations }
  })
}

export default NotificationsActions
