import React, { useEffect, useState } from 'react'
import { SharedEventHeader } from '../event/components/SharedEventHeader'
import { SharedEventFooter } from '../event/components/SharedEventFooter'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { Card } from '../../../ui/atoms/Card'
import { Input } from '../../../ui/atoms/Input'
import { Button } from '../../../ui/atoms/Button'
import { Checkbox } from '../../../ui/molecules/Checkbox'
import SupportService from '../../../../services/support-service'
import { useHistory, useParams } from 'react-router-dom'
import { RoutesLinks } from '../../../routes-links'
import { Loader } from '../../../ui/molecules/Loader'
import { FaExclamationCircle, FaPaperPlane } from 'react-icons/fa'
import Config from 'config'

export const RegisterEventJudge = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { eventId } = useParams()
  const history = useHistory()
  const [event, setEvent] = useState()
  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(false)

  const [judgeRegistrationOpen, setJudgeRegistrationOpen] = useState(false)

  const { register, errors, handleSubmit, watch, setValue } = useForm({
    mode: 'all',
    criteriaMode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      Yup.object().shape({
        judge: Yup.string().when('link_lost', {
          is: false,
          then: Yup.string().required(t('required_field'))
        }),
        email: Yup.string().email().required(t('required_field')),
        accept_terms: Yup.boolean().when('link_lost', {
          is: false,
          then: Yup.boolean().oneOf([true], t('accept_terms_required'))
        }),
        link_lost: Yup.boolean().default(false)
      })
    )
  })

  const manageError = (
    error,
    messageKey = 'error_occurred_retrieving_event',
    redirect = true
  ) => {
    if (redirect) {
      history.push(RoutesLinks.LOGIN_LINK)
    }

    console.log(error)
    addToast(t(messageKey), {
      appearance: 'error',
      autoDismiss: true
    })
  }

  const onSubmitForm = ({ judge, email, link_lost }) => {
    setLoading(true)
    SupportService.inviteJudgeToEvent(event._id, { judge, email, link_lost }, true).then(() => {
      setSuccess(true)
      addToast(t('judge_register_success'), {
        appearance: 'success',
        autoDismiss: true
      })
    }).catch((error) => {
      addToast(t(error?.response?.data?.msg_key || 'judge_register_error'), {
        appearance: 'error',
        autoDismiss: true
      })
    }).finally(() => {
      setLoading(false)
    })
  }

  const onError = () => {
    addToast(t('form_field_error'), { appearance: 'error', autoDismiss: true })
  }

  useEffect(() => {
    register('link_lost')
  }, [register])

  useEffect(() => {
    setValue('judge', '')
    setValue('email', '')
    setValue('accept_terms', false)
  }, [watch('link_lost')])

  useEffect(() => {
    if (event !== undefined && !event?.judge_registration_open) {
      manageError(null, 'judge_registration_closed', false)
    }
  }, [event])

  useEffect(() => {
    const getEvent = async () => {
      try {
        setLoading(true)
        const result = await SupportService.getEvent(eventId, true)
        setEvent(result.data.data)
        setJudgeRegistrationOpen(result.data.data.judge_registration_open)
      } catch (error) {
        manageError(error, null, false)
      } finally {
        setLoading(false)
      }
    }

    if (!eventId) {
      manageError()
      return
    }

    getEvent()
  }, [])

  return (
    <div className="flex flex-col min-h-screen">
      <SharedEventHeader showLoginButtons={false} />

      {!loading && <>
        <div className='flex-1 bg-bgOnboardingOne bg-no-repeat bg-right-bottom w-full'>
          <section className="px-8 py-8">
            {judgeRegistrationOpen && <form onSubmit={handleSubmit(onSubmitForm, onError)}>
              <Card wrapperClassName='max-w-3xl mx-auto overflow-hidden bg-white' containerClassName='p-0'>
                <h3 className={`text-2xl text-center p-4 bg-main-${Config.PLATFORM} text-white`}>
                  {event?.name}
                </h3>

                {!success && (
                  <>
                    <div className='p-4'>
                      <p className='mb-3 pb-3 border-b border-gray-lines'>
                        {t(watch('link_lost') ? 'judge_link_lost_description' : 'judge_register_description')}
                      </p>

                      {!watch('link_lost') && (
                        <Input
                          reference={register}
                          name="judge"
                          error={errors.judge}
                          placeholder={t('name')}
                          required={true}
                        />
                      )}

                      <Input
                        reference={register}
                        name="email"
                        error={errors.email}
                        placeholder={t('email')}
                        required={true}
                      />

                      {!watch('link_lost') && (
                        <Checkbox
                          id="accept_terms"
                          label={t('privacy_policy')}
                          name="accept_terms"
                          reference={register}
                          error={errors.accept_terms}
                          disabled={loading}
                        />
                      )}

                      <Button
                        type="submit"
                        text={t(watch('link_lost') ? 'send_link' : 'judge_register')}
                        horizontal="6"
                        verticalMargin="1"
                        className="mt-4 text-lg"
                      />

                    </div>

                    <p
                      onClick={() => setValue('link_lost', !watch('link_lost'), { shouldValidate: true })}
                      className='px-4 cursor-pointer text-blue-dark hover:text-transparence-blue font-bold text-xs text-center py-3 border-t border-gray-lines'>
                      {t(!watch('link_lost') ? 'judge_link_lost' : 'register')}
                    </p>
                  </>
                )}

                {success && (
                  <div className="p-4 text-center text-lg my-6">
                    <div>
                      <FaPaperPlane className="text-transparence-blue inline-block mb-6" size={70} />
                    </div>
                    <p>{t('judge_register_success_description')}</p>
                  </div>
                )}
              </Card>
            </form>}
            {!judgeRegistrationOpen && (
              <Card wrapperClassName='max-w-3xl mx-auto overflow-hidden bg-white' containerClassName='p-0'>
                <h3 className={`text-2xl text-center p-4 bg-main-${Config.PLATFORM} text-white`}>
                  {event?.name}
                </h3>
                <Card
                  bgColor={`bg-main-${Config.PLATFORM}`}
                    wrapperClassName={'mt-6 max-w-screen-sm mx-auto'}
                    containerClassName="text-center text-white text-lg my-6"
                  >
                    <div>
                      <FaExclamationCircle
                        className="text-white inline-block mb-6"
                        size={70}
                      />
                    </div>
                    <p>
                      {t('event_does_not_allow_judge_registration')}
                    </p>

                  </Card>
              </Card>
            )}
          </section>
        </div>

        <SharedEventFooter />
      </>}

      {loading && <Loader />}
    </div>
  )
}
