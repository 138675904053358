import { BsCircle, FaCheckCircle } from 'react-icons/all'
import Config from 'config'

export function ProgressTask ({ title, description, completed, onClick }) {
  return (
    <div
      onClick={() => onClick && !completed && onClick()}
      className={
        (onClick && !completed ? 'cursor-pointer ' : '') +
        (completed
          ? `text-main-${Config.PLATFORM}`
          : !onClick ? 'text-gray-600 ' : `text-gray-600 hover:text-main-${Config.PLATFORM}`) +
        'my-4'
      }
    >
      <div className={`text-sm ${completed && 'font-bold'}`}>
        {!completed && <BsCircle className="inline-block mr-2" />}
        {completed && <FaCheckCircle className="inline-block mr-2" />}
        {title}
      </div>
      <div className="text-gray-600 text-xxs pl-5">{description}</div>
    </div>
  )
}
