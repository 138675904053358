/* eslint-disable default-case */
import produce from 'immer'

import { InvestmentActionsTypes } from './investment-action-types'

const { SET_FILTERS, SET_PAGINATION, SET_SORT, RESET } = InvestmentActionsTypes

const defaultState = {
  filters: {
    sector: null,
    selectedDateType: 'custom',
    selectedDate: ['', ''],
    sortBy: '-date'
  },
  pagination: {
    page: 0,
    rowsPerPage: 12
  }
}

const InvestmentReducer = (state = defaultState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_FILTERS:
        draft.filters = {
          ...action.payload.data.filters
        }
        break
      case SET_PAGINATION:
        draft.pagination = action.payload.data
        break
      case SET_SORT:
        draft.filters = {
          ...action.payload.data
        }
        break
      case RESET:
        return defaultState
    }
  })

export default InvestmentReducer
