import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { RoutesLinks } from 'components/routes-links'
import { Loader } from 'components/ui/molecules/Loader'
import { Button } from 'components/ui/atoms/Button'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import Rocket from 'styles/images/menu_rocket_blue.svg'
import useUser from '../../hooks/useUser'
import { AiOutlineMore, FaPlus } from 'react-icons/all'
import { PageTitle } from '../ui/atoms/PageTitle'
import { Card } from '../ui/atoms/Card'
import SupportService from '../../services/support-service'
import { SectionHeader } from '../ui/molecules/SectionHeader'
import { StartupGeneralInformation } from './StartupGeneralInformation'
import { StartupKPIs } from './components/StartupKPIs'
import Actions from './Actions'
import { useToasts } from 'react-toast-notifications'
import Config from 'config';
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { SidePanel } from 'components/ui/objects/SidePanel'
import CreateStartupForm from 'components/edit-startup/components/CreateStartupForm'
import FormUtils from 'utils/form-utils'
import ProjectsService from 'services/projects-service'

const MyStartup = () => {
  const { startupId, getUserStartup, reloadUserInfo, user } = useUser()
  const { addToast } = useToasts()
  const history = useHistory()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [startup, setStartup] = useState(null)
  const [section, setSection] = useState('general_information')
  const [showActions, setShowActions] = useState(false)
  const [showRegisterStartupSection, setShowRegisterStartupSection] = useState(false)

  const downloadOnePager = () => {
    setLoading(true)
    SupportService.onePagerStartupPDF(startup._id).catch(() => {
      addToast(t('error_occurred'), { appearance: 'error', autoDismiss: true })
    }).finally(() => setLoading(false))
  }

  const downloadKpisReport = () => {
    setLoading(true)
    SupportService.kpisReportStartupPDF(startup._id).catch(() => {
      addToast(t('error_occurred'), { appearance: 'error', autoDismiss: true })
    }).finally(() => {
      setLoading(false)
      setShowActions(false)
    })
  }

  useEffect(() => {
    if (startupId) {
      setLoading(true)
      getUserStartup()
        .then((startup) => setStartup(startup))
        .finally(() => setLoading(false))
    }
  }, [startupId])

  const registerStartup = async (values) => {
    const showError = error => {
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t('error_occurred_registering_startup'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    }
    setLoading(true)
    values.founders = [{
      user: user._id,
      email: user.email,
      name: user.name,
      last_name: user.last_name,
      score: null,
      role: null
    }]

    try {
      await ProjectsService.createProject(values)
      reloadUserInfo()
    } catch (error) {
      showError(error)
      addToast(t('startup_registered_successfully'), { appearance: 'success', autoDismiss: true })
      setShowRegisterStartupSection(false)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>

      <div className="flex items-start">
        <div className="flex-1">
          <PageTitle title={t('my_startup')} />
        </div>

        {startup && (
          <>
            <ButtonMain
              onClick={() => setShowActions(true)}
              text={t('actions')}
              iconComponent={<AiOutlineMore className="inline-block w-6 h-4" />}
            />
          </>
        )}

        {!startup && (
          <>
            <ButtonMain
              onClick={() => {
                setShowRegisterStartupSection(true)
              }}
              text={t('register_startup')}
              iconComponent={<FaPlus className="inline-block w-6 h-4" />}
            />
          </>
        )}
      </div>

      {!startupId && (
        <div className='my-10'>
          <NoDataInfo
            title={t('startup_not_found')}
            textOne={t('startup_not_found_text_one')}
          />
        </div>
      )}

      {showRegisterStartupSection && (
        <SidePanel
          onClose={() => setShowRegisterStartupSection(false)}
          title={t('register_startup')}
          width='1/4'
        >
          <CreateStartupForm onSubmit={registerStartup}/>
        </SidePanel>
      )}

      {showActions && (
        <Actions
          onClose={() => setShowActions(false)}
          downloadOnePager={() => downloadOnePager()}
          downloadKpisReport={() => downloadKpisReport()}
          editInfo={() => history.push(`${RoutesLinks.MY_STARTUP_LINK}wizard`)}
        />
      )}

      {startup && (
        <>
          <SectionHeader
            sectionTitles={[t('general_information'), t('kpis')]}
            sectionKeys={['general_information', 'kpis']}
            section={section}
            setSection={setSection}
          />

          {section === 'general_information' && (
            <StartupGeneralInformation
              info={startup}
              isTeamMember={true}
              investments={[]}
            />
          )}

          {section === 'kpis' && (
            <StartupKPIs startup={startup} />
          )}
        </>
      )}

      {loading && <Loader />}
    </div>
  )
}

export default MyStartup
