import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { RoutesLinks } from '../routes-links'

const PrivateRoute = ({ children, ...rest }) => {
  const authInfo = useSelector((state) => state.auth)
  const checked = useSelector((state) => state.checks && state.checks.checked)
  const { token } = authInfo

  return (
    <Route
      {...rest}
      render={(props) =>
        token && checked
          ? (
              children
            )
          : (
          <Redirect
            to={{
              pathname: RoutesLinks.LOGIN_LINK,
              state: { from: props.location }
            }}
          />
            )
      }
    />
  )
}

export default PrivateRoute
