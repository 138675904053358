import { useState, useEffect, useCallback, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ProjectsService from 'services/projects-service'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import { ModalSwal } from 'components/ui/molecules/Modal'
import { Loader } from 'components/ui/molecules/Loader'
import { RoutesLinks } from 'components/routes-links'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import { PageTitle } from 'components/ui/atoms/PageTitle';
import AuthUtils from 'utils/auth-utils'
import UserService from 'services/user-service'
import UserOperations from 'redux/user/user-operations'
import Config from 'config'
import { isEmpty, isEqual } from 'lodash'
import Invitation from 'components/invitations/components/invitation'
import NotificationsOperations from 'redux/notifications/notifications-operations'
import Info from 'styles/images/vidio-info.svg'
import { useSwal } from 'hooks/useSwal'
import useUser from '../../hooks/useUser'
import SupportService from 'services/support-service'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { FaCheckCircle, FaFilter, FaPen, FaPlus, FaSearch, FaTrash } from 'react-icons/fa'
import ReminderSection from './ReminderSection'
import RemindersOperations from 'redux/reminders/reminders-operations'
import moment from 'moment'
import { Card } from 'components/ui/atoms/Card'
import { DataGrid } from 'components/ui/objects/DataGrid'
import Avatar from 'react-avatar'
import Utils from 'utils/utils'
import { RemindersFilters } from './RemindersFilters'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { DealflowService } from 'services/dealflow-service'
import { usePagination } from 'hooks/usePagination'
import { Pagination } from 'components/ui/atoms/Pagination'

const headerClass = `text-main-${Config.PLATFORM} font-bold text-sm border-b border-main-${Config.PLATFORM} text-left bg-white`;

export const Reminders = () => {
  const { user } = useUser()

  const history = useHistory()
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { confirm } = useSwal()
  const handleRedirect = (url) => history.push(url)
  const dispatch = useDispatch()
  const [showModalInfo, setShowModalInfo] = useState(false)

  const setUserInfo = (data) => dispatch(UserOperations.setUserInfo({ data }))
  const setInvitationsOperations = (invitations) =>
    dispatch(NotificationsOperations.setInvitations(invitations))
    const [showFilters, setShowFilters] = useState(false)
    const [filters, setFilters] = useState({})
  const [loading, setLoading] = useState(false)
  const [loadingReminders, setLoadingReminders] = useState(false)
  const [showReminderSection, setShowReminderSection] = useState(false)
  const [reminderToEdit, setReminderToEdit] = useState(null)

  const [reminders, setReminders] = useState([])
  const formInitialized = useRef(false)
  const {
    setTotalPages,
    totalPages,
    setPage,
    page,
    rowsPerPage,
    setRowsPerPage
  } = usePagination(12)

  const [deals, setDeals] = useState([])

  const getDealflow = () => {
    return DealflowService.getDealflow({
      rowsPerPage: 999999,
      pool: 'true',
      populate: 'false',
      boards: 'false',
      milestones: 'false',
      files: 'false'
    }).then((response) => {
      setDeals(response.data.dealflow.map((d) => { return { name: d.name, id: d._id } }).sort((a, b) => (a.name > b.name ? 1 : -1)))
    })
  }

  const setRemindersOperations = (reminders) =>
  dispatch(RemindersOperations.setReminders(reminders))

const setTotalReminders = (total) =>
  dispatch(RemindersOperations.setTotalReminders(total))


  const getRemindersResume = async () => {
    try {
      const result = await SupportService.getRemindersResume()
      if (result.data) {
        setRemindersOperations(result.data.reminders)
        setTotalReminders(result.data.total)
      }
    } catch (error) {
      console.error(error)
    }
  }
  
  const getReminders = async () => {
    try {
      setLoadingReminders(true)
      const result = await SupportService.getReminders({...filters,
        rowsPerPage,
        page
      })
      setReminders(result.data.reminders)
      setTotalPages(result.data.pagination.pages)
    } catch (error) {
      console.error(error)
      addToast(t('error_occurred_retrieving_reminders'), {
        appearance: 'error',
        autoDismiss: true
      })
    } finally {
      setLoadingReminders(false)
    }
  }

  const deleteReminder = (reminderId) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true)
          await SupportService.deleteReminder(reminderId)
          getReminders()
          getRemindersResume()
          addToast(t('reminder_deleted_successfully'), { appearance: 'success', autoDismiss: true })
        } catch (error) {
          addToast(t('error_occurred_deleting_reminder'), { appearance: 'error', autoDismiss: true })
        } finally {
          setLoading(false)
        }
      }
    })
  }

  const markAsCompleted = (reminder) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true)
          await SupportService.updateReminder(reminder._id, { completed: true })
          getReminders()
          getRemindersResume()
          addToast(t('reminder_marked_as_completed_successfully'), { appearance: 'success', autoDismiss: true })
        } catch (error) {
          addToast(t('error_occurred_marking_reminder_as_completed'), { appearance: 'error', autoDismiss: true })
        } finally {
          setLoading(false)
        }
      }
    })
  }

  useEffect(() => {
    getDealflow()
  }, [])

  useEffect(() => {
    if (!formInitialized.current) {
      formInitialized.current = true
      return
    }

    if (page > 0) {
      setPage(0)
      return
    }
    getReminders()
  }, [filters])

  useEffect(() => {
    getReminders()
  }, [page, rowsPerPage])

  return (
    <>
    {showFilters && (
      <RemindersFilters
        onClose={() => setShowFilters(false)}
        onFilter={setFilters}
        filters={filters}
        deals={deals}
      />
    )}
    {(showReminderSection || reminderToEdit) && (
        <ReminderSection
          onClose={() => {
            setReminderToEdit(null)
            setShowReminderSection(false)
          }}
          afterSubmit={() => {
            setReminderToEdit(null)
            setShowReminderSection(false)
            getReminders()
            getRemindersResume()
          }}
          initialValues={reminderToEdit}
          dealId={reminderToEdit?.deal?._id}
        />
      )}
      <div className="flex items-start">
      <div className="flex-1">
        <PageTitle title={t('reminders')} />
      </div>
      <div className='flex'>
        <ButtonMain
          onClick={() => setShowReminderSection(true)}
          text={t('add_reminder')}
          iconComponent={<FaPlus className='inline-block mr-2' />}
          />
          {(reminders?.length !== 0 || !isEmpty(filters)) && <ButtonSecondary
            onClick={() => setShowFilters(true)}
            iconComponent={<FaFilter/>}
            marginLeft={2}
          />}
      </div>
    </div>
      <div className='my-6'>
        {reminders &&
          reminders.length > 0 && (
            <Card>
              <DataGrid
                actionsHeaderClassName={headerClass}
                data={reminders.map(d => ({ ...d, id: d._id }))}
                headers={[
                  {
                    key: 'reminder',
                    title: t('reminder'),
                    headerClassName: headerClass,
                    render: (key, reminder) => (
                      <div className="flex">
                        <div className="ml-2">{Utils.getReminderIcon(reminder, t)} {reminder.reminder}</div>
                      </div>
                    )
                  },
                  {
                    key: 'deal',
                    title: t('startup'),
                    headerClassName: headerClass,
                    render: (key, reminder) => (
                      <>
                        <div>
                          <Avatar
                            src={reminder.deal.logo}
                            size="30"
                            round={true}
                            color="#e0e6f2"
                            fgColor="#4d70b3"
                            alt={reminder.deal.name}
                          />
                          <span className="ml-2">{reminder.deal.name}</span>
                        </div>
                      </>
                    )
                  },
                  {
                    key: 'visibility',
                    title: t('visibility'),
                    headerClassName: headerClass,
                    render: (key, reminder) => (
                      <div className="flex">
                        <div className="ml-2">{reminder.is_private ? t('private') : t('public')}</div>
                      </div>
                    )
                  },
                  {
                    key: 'date',
                    title: t('date'),
                    headerClassName: headerClass,
                    render: (key, reminder) => (
                      <div className="flex">
                        <div className="ml-2">{moment(reminder.date).format('YYYY-MM-DD')}</div>
                      </div>
                    )
                  }
                ]}
                actions={[
                  {
                    id: 'mark_as_completed',
                    tip: t('mark_as_completed'),
                    onClick: (reminder) => markAsCompleted(reminder),
                    buttonProps: {
                      iconComponent: <FaCheckCircle />
                    }
                  },
                  {
                    id: 'update',
                    tip: t('update_reminder'),
                    onClick: (reminder) => setReminderToEdit(reminder),
                    buttonProps: {
                      iconComponent: <FaPen />
                    }
                  },
                  {
                    id: 'delete',
                    tip: t('delete_reminder'),
                    onClick: (reminder) => deleteReminder(reminder._id),
                    buttonProps: {
                      iconComponent: <FaTrash />
                    }
                  }
                ]}
              />
              <Pagination
                paginateOptions={[12, 24, 36, 48, 60]}
                currentPage={page}
                setCurrentPage={(page) => setPage(page)}
                perPage={rowsPerPage}
                pages={totalPages}
                setPerPage={(value) => setRowsPerPage(value[0].id)}
              />
            </Card>
          )
        }
        {!loading && reminders.length === 0 && (
          <NoDataInfo
            title={t('reminders_not_found')}
          />
        )}
        {(loading || loadingReminders) && <Loader />}
      </div>
    </>
  )
}
