import { Card } from '../../ui/atoms/Card'
import { useTranslation } from 'react-i18next'
import { FaEye, FaEyeSlash } from 'react-icons/all'
import { Button } from '../../ui/atoms/Button'
import Config from 'config';

export const RequestProfileVisibility = ({
  title,
  subtitle,
  requestAccess,
  section,
  hideButton
}) => {
  const { t } = useTranslation()

  return (
    <Card
      wrapperClassName='overflow-hidden border-2 border-orange-200'
      containerClassName='text-center'
    >
      <p className='text-orange-400'>
        <FaEyeSlash className='inline-block mr-2' />
        {title}
      </p>

      <p>
        {subtitle}
      </p>

      {!hideButton && requestAccess && <div className='mt-4 flex flex-col justify-center items-center'>
        <Button
          text={t('request_access')}
          className='w-auto'
          iconComponent={<FaEye className='inline-block mr-2' />}
          onClick={() => requestAccess(section)}
          textColor={`buttons-card-text-${Config.PLATFORM}`}
          bgColor={`buttons-card-${Config.PLATFORM}`}
          bgHoverColor={`buttons-hover-${Config.PLATFORM}`}
        />
      </div>}
    </Card>
  )
}
