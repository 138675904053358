import { useTranslation } from 'react-i18next'
import { Card } from 'components/ui/atoms/Card'
import Avatar from 'react-avatar'
import { FaExpandAlt, FaEye, FaHandshake, FaPaperPlane } from 'react-icons/all'
import { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { Button } from 'components/ui/atoms/Button'
import Utils from 'utils/utils'
import { useSwal } from 'hooks/useSwal'
import { useToasts } from 'react-toast-notifications'
import { Loader } from 'components/ui/molecules/Loader'
import ProfessionalService from 'services/professional-service'
import Config from 'config';
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { ButtonCardSecondary } from 'components/ui/atoms/ButtonCardSecondary'
import { ButtonCardMain } from 'components/ui/atoms/ButtonCardMain'

export function RegisteredStartup({
  startup,
  onManage,
  setDealToResume
}) {
  const { t } = useTranslation()
  const { confirm } = useSwal()
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)

  const connect = () => {
    confirm({
      title: t('connect_with_startup')
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        setLoading(true)
        ProfessionalService.requestConnectionToStartup(startup._id).then(() => {
          addToast(t('connect_with_startup_success'), { appearance: 'success', autoDismiss: true })
          onManage && onManage()
        }).catch(() => {
          addToast(t('connect_with_startup_error'), { appearance: 'error', autoDismiss: true })
        }).finally(() => {
          setLoading(false)
        })
      }
    })
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  return (
    <>
      <Card
        wrapperClassName="transition-transform"
        containerClassName="w-full flex flex-col h-full"
      >
        <div className='flex flex-col'>
          <div className="flex pb-2 mb-2 ">
            <div className='flex flex-row w-full'>
              <div className="flex flex-col text-xs min-w-0 w-3/5">
                <h5 className={`uppercase font-bold text-sm leading-none text-main-${Config.PLATFORM} truncate mb-1`}>
                  {startup.name}
                </h5>
                <div className='text-xxs'>
                    <div>
                    <b>{t('country')}:</b>
                    <span className='capitalize ml-1'>{startup.country_incorporation ? t(startup.country_incorporation.key) : '-'}</span>
                    </div>
                    <div>
                    <b>{t('sector')}:</b>
                    <span className='capitalize ml-1'>{startup.sector ? t(startup.sector.key) : '-'}</span>
                    </div>
                    <div>
                    <b>{t('investment_stage')}:</b>
                    <span className='capitalize ml-1'>{startup.investment_stage ? t(startup.investment_stage.key) : '-'}</span>
                    </div>
                    <div>
                    <b>{t('project_stage')}:</b>
                    <span className='capitalize ml-1'>{startup.project_stage ? t(startup.project_stage.key) : '-'}</span>
                    </div>
                </div>
              </div>

              <div className="flex items-center justify-center w-2/5">
              <div className="w-full h-[80px] ">
                <Avatar
                  src={startup.logo}
                  name={startup.name}
                  alt={startup.name}
                  maxInitials={3}
                  className={`w-full h-[80px] object-contain`}
                  color="#e0e6f2"
                  fgColor="#4d70b3"
                  round="5"
                />
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className={`flex justify-end border-t border-separator-${Config.PLATFORM} pt-2 mt-4`}>
          <ButtonCardSecondary
            onClick={() => setDealToResume(startup)}
            text={t('see_profile')}
            iconComponent={<FaEye className="inline-block mr-2 w-5 h-4" />}
            marginRight="4"
            />
          <ButtonCardMain
            onClick={() => connect()}
            text={t('connect')}
            iconComponent={<FaHandshake className="inline-block mr-2 w-5 h-4" />}
          />
        </div>
      </Card>

      {loading && <Loader />}
    </>
  )
}
