import { useEffect } from 'react'
import { SidePanel } from 'components/ui/objects/SidePanel'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useToasts } from 'react-toast-notifications'
import Select from 'components/ui/atoms/Select'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import Config from 'config'
import SkillsKnowledges from 'assets/json/skills-knowledges.json'

export const ProfessionalsFilters = ({
  onFilter,
  onClose,
  filters = {},
}) => {
    console.log({filters})
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const skillsAndKnowledge = SkillsKnowledges.map((item) => {
    return { value: t(item.key), id: item._id }
  }).sort((a, b) => {
    if (a.value.toUpperCase() < b.value.toUpperCase()) return -1
    if (a.value.toUpperCase() > b.value.toUpperCase()) return 1
    // names must be equal
    return 0
  })

  const {
    register,
    handleSubmit,
    trigger,
    errors,
    setValue,
    reset,
    watch
  } = useForm({
    resolver: yupResolver(Yup.object().shape({
      skills_and_knowledge: Yup.string().nullable()
    }), { abortEarly: false }),
    criteriaMode: 'all',
    reValidateMode: 'all',
    mode: 'onChange'
  })

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config)
    trigger(name)
  }

  const filter = ({ clearFilters, ...filterValues }) => {
    let _filters = {}

    if (!clearFilters) {
      _filters = Object.keys(filterValues).reduce((acc, key) => {
        if (filterValues[key]) {
          acc[key] = filterValues[key]
        }
        return acc
      }, {})
    }

    onFilter && onFilter(_filters)
    onClose && onClose()
  }

  const resetFilters = () => {
    reset()
    filter({ clearFilters: true })
  }

  const onInvalid = () => {
    addToast(t('form_field_error'), { appearance: 'error', autoDismiss: true })
  }

  useEffect(() => {
    register('skills_and_knowledge')
  }, [register])

  useEffect(() => {
    if (filters) {
      Object.entries(filters).forEach(([key, value]) => {
          _setValue(key, value, { shouldValidate: true })
        }
      )
    }
  }, [filters, register])

  return (
    <SidePanel title={t('filters')} onClose={() => onClose && onClose()} width="1/4">
      <form
        className="flex flex-col w-full"
        onSubmit={handleSubmit(filter, onInvalid)}
      >
        <div className='mt-2'>
            <Select
                name="skills_and_knowledge"
                reference={register}
                label={t('skills_and_knowledge')}
                items={skillsAndKnowledge}
                error={errors.skills_and_knowledge}
                onSelect={selected => _setValue('skills_and_knowledge', selected?.length ? selected[0].id : null)}
                initialValues={
                    watch('skills_and_knowledge')
                    ? skillsAndKnowledge
                        .filter(item => item.id === watch('skills_and_knowledge'))
                        .map(item => ({ id: item.id, value: item.value }))
                    : []
                }
            />
        </div>

        <div className={`flex justify-end mt-2 pt-2 border-t border-separator-${Config.PLATFORM}`}>
          <ButtonSecondary
            text={t('clear_filters')}
            onClick={() => resetFilters()}
            type="button"
            marginRight={2}
          />
          <ButtonMain
            text={t('filter')}
            type="submit"
          />
        </div>
      </form>
    </SidePanel>
  )
}
