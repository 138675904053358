import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom'
import SidebarMenuItems from './sidebar-menu-items'
import Logo from '../../../styles/images/logo_kiota_horizon.svg'
import LogoSmall from '../../../styles/images/logo_login.svg'
import { UiList } from '../../ui/objects/UiList'
import EventUtils from 'utils/event-utils'
import useUser from '../../../hooks/useUser'
import { RoutesLinks } from '../../routes-links'
import Config from 'config'
import Parameters from 'config/parameters.json'

const Menu = ({ openSetup }) => {
  const { t } = useTranslation()
  const { user, isInvestor, isAdmin, isFounder, isProfessional } = useUser()
  const location = useLocation()
  const history = useHistory()
  const [hovers, setHovers] = useState({})

  const ADMIN_SECTIONS = ['dashboard', 'admin_irm', 'investors', 'questionnaires_engine', 'kbots_dealflow']
  const PROFESSIONAL_SECTIONS = ['startups_professionals']
  const FOUNDER_SECTIONS = Config.PLATFORM === '000' ? ['dashboard', 'my_startup', 'data_rooms', 'investors', 'professionals'] : ['dashboard', 'my_startup', 'investors', 'professionals']
  const INVESTOR_SECTIONS = Config.PLATFORM === '000'
    ? ['dashboard', 'dealflow', 'irm', /* 'entrepreneurs', */ 'pipeline_manager', 'evaluations', 'documentation', 'events', 'questionnaires_engine', 'reports', 'kbots', 'kbots_dealflow']
    : ['dashboard', 'dealflow', 'pipeline_manager', 'evaluations', 'documentation']

  const checkUrl = (item, url) => {
    let result = false
    if (item.url === url) {
      result = true
    } else if (
      item.section === 'dealflow' &&
      (url.includes('/curated-dealflow') ||
        (url.includes('/applications') && !url.includes('/applications-filter')) ||
        (url.includes('/dealflow') && !url.startsWith('/evaluations')))
    ) {
      result = true
    } else if (
      item.section === 'pipeline_manager' &&
      (url.includes('/pipeline-manager/templates') || url.includes('/pipeline-manager/boards'))
    ) {
      result = true
    } else if (item.section === 'questionnaires_engine' && url.includes('/questionnaires-engine')) {
      result = true
    } else if (item.section === 'preferences' && url.includes('/settings')) {
      result = true
    } else if (item.section === 'dealflow_engine' && url.includes('/dealflow-engine')) {
      result = true
    } else if (item.section === 'data_rooms' && url.includes('/data-rooms')) {
      result = true
    } else if (item.section === 'reports' && url.includes('/reports')) {
      result = true
    } else if (item.section === 'my_startup' && url.includes('/startup-profile')) {
      result = true
    } else if (item.section === 'evaluations' && (url.includes('/evaluations') || url.includes('score-cards'))) {
      result = true
    } else if (item.section === 'support' && url.includes('/support')) {
      result = true
    } else if (item.section === 'score_cards' && url.includes('/score-cards')) {
      result = true
    } else if (item.section === 'events' && url.includes('/events')) {
      result = true
    } else if (item.section === 'events' && url.includes('kmz-form') && user) {
      return true
    } else if (item.section === 'reporting' && url.includes('reporting') && user) {
      return true
    } else if (item.section === 'documentation' && url.includes('documentation') && user) {
      return true
    } else if (
      item.section === 'questionnaires_engine_forms' &&
      (url === RoutesLinks.DASHBOARD_LINK || url.includes(RoutesLinks.QE_FORMS_LINK))
    ) {
      return true
    } else if (item.section === 'investors_relations_manager' && url.includes('irm') && user) {
      return true
    } else if (item.section === 'professionals' && url.includes('professionals') && user) {
      return true
    } else if (item.section === 'startups_professionals' && url.includes('startups') && user) {
      return true
    } else if (item.section === 'entrepreneurs' && url.includes('people') && user) {
      return true
    } 

    return result
  }


  const onItemClick = (item, index) => {
    EventUtils.sendAnalyticEvent({
      event: 'clickSidebarItem',
      user: user ? user._id : null,
      payload: {
        from: location.pathname,
        item: item.title,
        to: item.url
      }
    })
    const tmpHovers = { ...hovers }
    Object.keys(tmpHovers).forEach((key, index) => {
      tmpHovers[key] = false
    })
    tmpHovers[index.toString()] = true
    setHovers(tmpHovers)
    history.push(item.url)
  }

  const onMouseOver = (index) => setHovers({ ...hovers, [index]: true })

  const onMouseLeave = (item, index) => {
    const tmpHovers = { ...hovers }
    Object.keys(tmpHovers).forEach((key) => (tmpHovers[key] = false))
    tmpHovers[index.toString()] = checkUrl(item, location.pathname)
    setHovers(tmpHovers)
  }

  const getMenuItems = () => {
    const sections = []
    if (isInvestor) {
      user.investor.permissions.sections.filter((section) => INVESTOR_SECTIONS.includes(section)).forEach((section) => {
        const s = SidebarMenuItems.find((s) => s.investor && s.section === section)
        if (s) {
          sections.push(s)
        }
      })
    } else if (isFounder) {
      FOUNDER_SECTIONS.forEach((section) => {
        const s = SidebarMenuItems.find((s) => s.founder && s.section === section)
        if (s) {
          sections.push(s)
        }
      })
    } else if (isProfessional) {
      PROFESSIONAL_SECTIONS.forEach((section) => {
        const s = SidebarMenuItems.find((s) => s.professional && s.section === section)
        if (s) {
          sections.push(s)
        }
      })
    } else if (isAdmin) {
      ADMIN_SECTIONS.forEach((section) => {
        const s = SidebarMenuItems.find((s) => s.admin && s.section === section)
        if (s) {
          sections.push(s)
        }
      })
    }

    return sections.map((item, index) => {
      return (
        <div id={`sidebar-menu-item-${index + 1}`} key={index} className="flex justify-center text-right w-full">
          <div
            onMouseOver={() => onMouseOver(index)}
            onMouseLeave={() => onMouseLeave(item, index)}
            onClick={() => onItemClick(item, index)}
            className={`flex items-center w-full  
              h-9 my-2 ml-6 lg:ml-2 px-2 
              rounded-l-2xl cursor-pointer text-center 
              hover:bg-white hover:text-main-${Config.PLATFORM} hover:shadow-none 
              ${checkUrl(item, location.pathname)
                ? `font-bold bg-white text-main-${Config.PLATFORM} shadow-none`
                : 'text-white shadow-soft-white'
              }`}
          >
            <div className="w-8 flex justify-between text-center text-white hover:text-white">
              {hovers[index] || checkUrl(item, location.pathname) ? item.iconHover : item.icon}
            </div>

            <div className="text-sm font-medium hidden lg:block">{t(item.title)}</div>
          </div>
        </div>
      )
    })
  }

  useEffect(() => {
    setHovers({})
  }, [location.pathname])

  return (
    <div className={`fixed top-0 bg-main-${Config.PLATFORM} h-screen w-20 lg:w-52 z-20`}>
      <div className="menu-logo-container">
        <div className="mt-4 px-4 flex justify-center items-center hidden lg:flex">
          <img src={Parameters.logo_sidebar[Config.PLATFORM]} alt="Kiota Logo" className="object-cover w-full h-full" />
        </div>
        <div className="h-12 mt-2 px-2 flex justify-center items-center block lg:hidden">
          <img src={Config.PLATFORM === '001' ? Parameters.logo_sidebar['001'] : LogoSmall } alt={Parameters.logo_sidebar_alt[Config.PLATFORM]} className="object-cover w-full h-full" />
        </div>
      </div>
      <UiList className="mt-4 lg:mt-8">{getMenuItems()}</UiList>
      {Config.PLATFORM !== '000' && <div className="mt-8 fixed bottom-3 w-20 lg:w-52">
        <div className="text-xxs lg:text-xxs px-4 text-center text-white">
          {t('powered_by_kiota')}
        </div>
      </div>}
      {/* <div className="mt-8 fixed bottom-3 w-20 lg:w-52">
            <div className="text-xxs lg:text-sm px-4">
              <ProgressBar
                value={10}
                valueText={`${t('onboarding')} ${10}%`}
                percentage={false}
                textColor={'text-white'}
                shadow={null}
              />
              <div
                className={'mt-2 font-semibold text-right cursor-pointer hover:font-bold text-white w-full'}
                onClick={() => openSetup()}
              >
                {t('see')}
                <img
                  src={ArrowRightWhite}
                  alt="Arrow right icon"
                  className="inline-block ml-2 w-3"
                />
              </div>
            </div>
  </div> */}
    </div>
  )
}

export default Menu
