import React from 'react'
import { css } from '@emotion/core'
import BeatLoader from 'react-spinners/BeatLoader'

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #4d70b3;
`

export function Spinner ({ color = '#4d70b3', loading }) {
  return (
    <div className="sweet-loading">

      {/* <button onClick={() => setLoading(!loading)}>Toggle Loader</button>
      <input
        value={color}
        onChange={(input) => setColor(input.target.value)}
        placeholder="Color of the loader"
      /> */}
      <BeatLoader color={color} loading={loading} css={override} size={5} />
    </div>
  )
}
