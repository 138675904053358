import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { RoutesLinks } from 'components/routes-links'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import useUser from '../../hooks/useUser'
import moment from 'moment'
import { ButtonCardMain } from 'components/ui/atoms/ButtonCardMain'
import Config from 'config'
import { Card } from 'components/ui/atoms/Card'

const DataroomsList = ({ dataRooms }) => {
  const { user } = useUser()
  const projectId = user.projects.length ? user.projects[0]._id : null
  const history = useHistory()
  const { t } = useTranslation()
  const handleRedirect = (url) => history.push(url)

    return (
        <div className='my-6'>
            {dataRooms.length === 0 && (
                <>
                    <NoDataInfo
                        title={t('data_rooms_not_found')}
                        textOne={t(
                            !projectId
                            ? 'data_rooms_not_found_no_project'
                            : 'no_data_rooms_data_one'
                        )}
                    />
                </>
            )}
            {dataRooms.length !== 0 && (
                <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4'>
                    {dataRooms.map((dataRoom) => {
                        return (
                            <Card key={dataRoom._id}>
                                <div className={'flex flex-col justify-left items-left'}>
                                    <h3>
                                        {dataRoom.name}
                                    </h3>
                                    <div className='flex mt-2'>
                                        <div className='mr-2'>
                                            <span className='text-sm font-medium'>{`${t('guests')}: `}</span>
                                            <span className='text-sm'>{dataRoom.guests}</span>
                                        </div>
                                        <div className='mr-2'>
                                            <span className='text-sm font-medium'>{`${t('sections')}: `}</span>
                                            <span className='text-sm'>{dataRoom.sections}</span>
                                        </div>
                                        <div>
                                            <span className='text-sm font-medium'>{`${t('documents')}: `}</span>
                                            <span className='text-sm'>{dataRoom.documents}</span>
                                        </div>
                                    </div>
                                    <div className='border-t border-gray-lines pt-2 mt-4'>
                                        <div>
                                            <span className='text-xxs font-medium'>{`${t('creation_date')}: `}</span>
                                            <span className='text-xxs'>{moment(dataRoom.createdAt).format('YYYY-MM-DD')}</span>
                                        </div>
                                        <div>
                                            <span className='text-xxs font-medium'>{`${t('last_modification')}: `}</span>
                                            <span className='text-xxs'>{moment(dataRoom.last_update).format('YYYY-MM-DD HH:mm')}</span>
                                        </div>
                                    </div>
                                    <div className={`flex justify-end mt-2 pt-2 border-t border-separator-${Config.PLATFORM}`}>
                                        <ButtonCardMain
                                            onClick={() => handleRedirect(`${RoutesLinks.DATA_ROOMS_LINK}/${dataRoom._id}`)}                                            textColor={`blue-dark-${Config.PLATFORM}`}
                                            text={t('see_data_room')}
                                        />
                                        </div>
                                </div>
                            </Card>
                        )}
                    )}
                </div>
            )}
        </div>
    )
}

export default DataroomsList
