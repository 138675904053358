import { useTranslation } from 'react-i18next'
import { Button } from 'components/ui/atoms/Button'
import { Input } from 'components/ui/atoms/Input'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { ButtonMain } from '../atoms/ButtonMain'
import Config from 'config'

const UnauthorizedKmZeroReport = ({ loading, description, buttonText, onRequestAccess }) => {
    const { t } = useTranslation()

    const onSubmit = ({ email }) => {
        onRequestAccess(email)
    }

    const DatroomAccessSchema = Yup.object().shape({
        email: Yup.string()
          .required(t('required_field'))
          .email(t('incorrect_format'))
    })

    const { register, handleSubmit, errors } = useForm({
        mode: 'onChange',
        resolver: yupResolver(DatroomAccessSchema)
    })

    return (
        <div className="bg-bgOnboardingOne bg-cover bg-no-repeat bg-center min-w-screen min-h-screen relative flex justify-center align-center h-full">
            <div className="w-full flex items-center justify-center fixed top-0">
                <div className="xs:w-full md:w-1/3 pt-8 px-4 | text-center | min-h-screen justify-center mt-20">
                    <div className="w-full flex flex-col justify-center items-center h-full">
                        <div>
                            <h1 className={`text-main-${Config.PLATFORM}`}>
                                {t('access_control')}
                            </h1>
                        </div>
                        <div>
                            {description}
                        </div>
                        <form className="w-full my-10" onSubmit={handleSubmit(onSubmit)}>
                            <Input
                                reference={register}
                                id="email"
                                type='email'
                                name="email"
                                label={t('email')}
                                required={true}
                                placeholder={t('email')}
                                error={errors.email}
                                disabled={!!loading}
                            />
                            <div className='flex w-full justify-end'>
                                <ButtonMain
                                    width={'full'}
                                    type="submit"
                                    text={buttonText}
                                    disabled={!!loading}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UnauthorizedKmZeroReport
