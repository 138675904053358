import { RoutesLinks } from 'components/routes-links'
import Config from 'config'

import {
  FaShareAlt,
  RxDashboard,
  BsKanban,
  HiOutlineDocumentDuplicate,
  RiQuestionAnswerLine,
  AiOutlineScan,
  TbReportAnalytics
} from 'react-icons/all'

import { FaCalendarAlt, FaFolder, FaPen, FaRocket, FaUsers } from 'react-icons/fa'

const SidebarMenuItems = [
  {
    section: 'dashboard',
    title: 'dashboard',
    url: RoutesLinks.DASHBOARD_LINK,
    icon: <RxDashboard className={'w-6 h-5'}/>,
    iconHover: <RxDashboard className={`h-5 text-main-${Config.PLATFORM} w-6`} />,
    founder: true,
    investor: true,
    admin: true
  },
  {
    section: 'my_startup',
    title: 'my_startup',
    url: RoutesLinks.MY_STARTUP_LINK,
    icon: <FaRocket className="w-6" />,
    iconHover: <FaRocket className={`text-main-${Config.PLATFORM} w-6 h-5`} />,
    founder: true,
    investor: false,
    admin: false
  },
  {
    section: 'evaluations',
    title: 'evaluations',
    url: `${RoutesLinks.EVALUATIONS_LINK}evaluation-processes`,
    icon: <FaPen className={'w-6 h-5'} />,
    iconHover: <FaPen className={`h-5 text-main-${Config.PLATFORM} w-6`} />,
    founder: true,
    investor: true,
    admin: false
  },
  {
    section: 'investors',
    title: 'investors',
    url: RoutesLinks.INVESTORS_LINK,
    icon: <AiOutlineScan className="w-6 h-5" />,
    iconHover: <AiOutlineScan className={`text-main-${Config.PLATFORM} w-6 h-5`} />,
    founder: true,
    investor: false,
    admin: false,
    professional: true
  },
  {
    section: 'professionals',
    title: 'professionals',
    url: RoutesLinks.PROFESSIONALS_LINK,
    icon: <FaUsers className="w-6 h-5" />,
    iconHover: <FaUsers className={`text-main-${Config.PLATFORM} w-6 h-5`} />,
    founder: true,
    investor: false,
    admin: false
  },
  {
    section: 'data_rooms',
    title: 'data_rooms',
    url: RoutesLinks.DATA_ROOMS_LINK,
    icon: <FaFolder className="w-6 h-5" />,
    iconHover: <FaFolder className={`text-main-${Config.PLATFORM} w-6 h-5`} />,
    founder: true,
    investor: false,
    admin: false
  },
  {
    section: 'dealflow',
    title: 'dealflow',
    url: RoutesLinks.DEALFLOW_LINK,
    icon: <FaRocket className="w-6 h-5" />,
    iconHover: <FaRocket className={`text-main-${Config.PLATFORM} w-6 h-5`} />,
    founder: false,
    investor: true,
    admin: false
  },
  {
    section: 'pipeline_manager',
    title: 'pipeline_manager',
    url: `${RoutesLinks.PIPELINE_MANAGER_LINK}boards`,
    icon: <BsKanban className="w-6 h-5" />,
    iconHover: <BsKanban className={`w-6 h-5 text-main-${Config.PLATFORM}`} />,
    founder: false,
    investor: true,
    admin: false
  },
  {
    section: 'irm',
    title: 'investors_relations_manager_sidebar',
    url: RoutesLinks.INVESTORS_RELATIONS_MANAGER_LINK,
    icon: <FaShareAlt className='w-6 h-5'/>,
    iconHover: <FaShareAlt className={`h-5 text-main-${Config.PLATFORM} w-6`}/>,
    founder: false,
    investor: true,
    admin: false
  },
  {
    section: 'entrepreneurs',
    title: 'people',
    url: RoutesLinks.ENTREPRENEURS_LINK,
    icon: <FaUsers />,
    iconHover: <FaUsers className={`text-main-${Config.PLATFORM}`}/>,
    founder: false,
    investor: true,
    admin: false
  },
  {
    section: 'admin_irm',
    title: 'irm',
    url: `${RoutesLinks.USERS_LINK}investors/irm`,
    icon: <FaUsers />,
    iconHover: <FaUsers className={`text-main-${Config.PLATFORM}`} />,
    founder: false,
    investor: false,
    admin: true
  },
  {
    section: 'investors',
    title: 'investors',
    url: `${RoutesLinks.USERS_LINK}investors`,
    icon: <FaUsers />,
    iconHover: <FaUsers className={`text-main-${Config.PLATFORM}`} />,
    founder: false,
    investor: false,
    admin: true
  },
  {
    section: 'startups',
    title: 'startups',
    url: `${RoutesLinks.USERS_LINK}investors`,
    icon: <FaRocket className="w-6 h-5" />,
    iconHover: <FaRocket className={`text-main-${Config.PLATFORM} w-6 h-5`} />,
    founder: false,
    investor: false,
    admin: true,
    professional: true
  },
  {
    section: 'startups_professionals',
    title: 'startups',
    url: `${RoutesLinks.STARTUPS_LINKS}`,
    icon: <FaRocket className="w-6 h-5" />,
    iconHover: <FaRocket className={`text-main-${Config.PLATFORM} w-6 h-5`} />,
    founder: false,
    investor: false,
    admin: false,
    professional: true
  },
  {
    section: 'questionnaires_engine',
    title: 'questionnaires',
    url: RoutesLinks.QE_FORMS_LINK,
    icon: <RiQuestionAnswerLine className='w-6 h-5' />,
    iconHover: <RiQuestionAnswerLine className={`w-6 h-5 text-main-${Config.PLATFORM}`} />,
    founder: false,
    investor: true,
    admin: true
  },
  {
    section: 'reports',
    title: 'reports',
    url: RoutesLinks.REPORTS_LINK,
    icon: <TbReportAnalytics className='w-6 h-6' />,
    iconHover: <TbReportAnalytics className={`w-6 h-6 text-main-${Config.PLATFORM}`} />,
    founder: false,
    investor: true,
    admin: true
  },
  {
    section: 'documentation',
    title: 'documentation',
    url: `${RoutesLinks.DOCUMENTATION_LINK}documents`,
    icon: <HiOutlineDocumentDuplicate className='w-6 h-6'/>,
    iconHover: <HiOutlineDocumentDuplicate className={`text-main-${Config.PLATFORM} h-6 w-6`} />,
    founder: false,
    investor: true,
    admin: false
  }
]

export default SidebarMenuItems
