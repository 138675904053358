import { useTranslation } from 'react-i18next'
import { SidePanel } from 'components/ui/objects/SidePanel'
import { useEffect } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import Config from 'config'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { TextArea } from 'components/ui/atoms/TextArea'
import { Input } from 'components/ui/atoms/Input'
import FormUtils from 'utils/form-utils'
import { useToasts } from 'react-toast-notifications'
import moment from 'moment'

/**
 * @param onClose
 * @returns {JSX.Element}
 * @constructor
 */
const EducationSection = ({ onClose, addEducation, initialValues, updateEducation }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const { register, handleSubmit, errors, setValue, trigger, watch, getValues } = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    reValidateMode: 'all',
    resolver: yupResolver(Yup.object().shape({
        title: Yup.string().required(t('required_field')),
        entity: Yup.string(),
        entity_logo: Yup.string()
            .transform(value => !value ? null : value)
            .matches(FormUtils.URL_REGEX, t('incorrect_format'))
            .nullable(),
        entity_url: Yup.string()
            .transform(value => !value ? null : value)
            .matches(FormUtils.URL_REGEX, t('incorrect_format'))
            .nullable(),
        start_date: Yup.string()
            .required(t('required_field'))
            .transform((value) => FormUtils.parseDateFromString(value, 'YYYY-MM-DD HH:mm')),
        end_date: Yup.string()
            .nullable()
            .transform((value) => FormUtils.parseDateFromString(value, 'YYYY-MM-DD HH:mm')),
        description: Yup.string().nullable()
      }), { abortEarly: false })
  })

  useEffect(() => {
    if (!initialValues) {
        setValue('start_date', moment().format('YYYY-MM-DD'))
    } else {
        setValue('title', initialValues.title)
        setValue('description', initialValues.description || '')
        setValue('entity', initialValues.entity)
        setValue('entity_logo', initialValues.entity_logo || '')
        setValue('entity_url', initialValues.entity_url || '')
        if (initialValues.end_date) {
            setValue('end_date', moment(initialValues.end_date).format('YYYY-MM-DD'))
        }
        setValue('start_date', moment(initialValues.start_date).format('YYYY-MM-DD'))
    }
    trigger()
  }, [initialValues])

  return (
    <>
      <SidePanel
        width={'1/4'}
        onClose={onClose}
        title={t(initialValues ? 'update_education' : 'add_education')}
      >
        <div className="mt-4 flex flex-col w-full">
            <div className="w-full">
            <Input
            required={true}
              reference={register}
              name="title"
              placeholder={t('title')}
              label={t('title')}
              type="string"
              error={errors.title}
            />
            <TextArea
                reference={register}
                name="description"
                error={errors.description}
                label={t('description')}
                maxLength={200}
                placeholder={t('description')}
            />
            <Input
            required={true}
              reference={register}
              name="start_date"
              placeholder={t('start_date')}
              label={t('start_date')}
              type="date"
              error={errors.start_date}
            />
            <Input
              reference={register}
              name="end_date"
              placeholder={t('end_date')}
              label={t('end_date')}
              type="date"
              error={errors.end_date}
            />
            <Input
              reference={register}
              name="entity"
              placeholder={t('entity')}
              label={t('entity')}
              type="string"
              error={errors.entity}
            />
            <Input
              reference={register}
              name="entity_logo"
              placeholder={t('entity_logo_placeholder')}
              label={t('entity_logo')}
              type="string"
              error={errors.entity_logo}
            />
            <Input
              reference={register}
              name="entity_url"
              placeholder={t('entity_logo_placeholder')}
              label={t('entity_url')}
              type="string"
              error={errors.entity_url}
            />
            </div>
            <div className={`flex justify-end mt-2 pt-2 border-t border-separator-${Config.PLATFORM}`}>
              <ButtonMain
                onClick={async () => {
                    const validation = await trigger()
                    if (validation) {
                        if (initialValues) {
                            updateEducation(getValues())
                        } else {
                            addEducation(getValues())
                        }
                    }
                    else {
                        addToast(t('form_field_error'), { appearance: 'error', autoDismiss: true })
                    }
                }}
                text={t(initialValues ? 'update_education' : 'add_education')}
              />
            </div>
        </div>
      </SidePanel>
    </>
  )
}

export default EducationSection
