import Parameters from 'config/parameters.json'
import Config from 'config'

export function CoverBackground({ link = 'false' }) {
  return (
    <div className={`hidden lg:block bg-main-${Config.PLATFORM} `}>
      <div style={{ height: 'calc(100vh - 5rem)' }}>
        <img src={Parameters.logo[Config.PLATFORM]} alt={Parameters.logo_sidebar_alt[Config.PLATFORM]} className="w-full h-full" />
      </div>
      {link && (
        <div >
          <div className={`w-full flex justify-center items-center relative ${Config.PLATFORM === '000' ? 'bottom-16 xl:bottom-42' : ''}`}>
            <a
              href={Parameters.main_url_link[Config.PLATFORM]}
              target="_blank"
              rel="noreferrer"
              className={`absolute translate-y-1/2 bg-white px-5 py-1 text-xs font-medium rounded-md cursor-pointer text-main-${Config.PLATFORM} hover:text-link-hover-${Config.PLATFORM}`}
            >
              {Parameters.main_url_preview_text[Config.PLATFORM]}
            </a>
            <hr className="w-8/12 h-full  text-white"></hr>
          </div>
        </div>
      )
      }
    </div>

  )
}
