import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLayer } from 'react-laag'
import Points from '../../../styles/images/points.svg'
import Config from 'config'

export function PopoverDocuments ({
  methods,
  options = ['preview', 'download', 'historical']
}) {
  const [isOpen, setOpen] = React.useState(false)

  function close () {
    setOpen(false)
  }

  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen,
    onOutsideClick: close,
    onDisappear: close,
    overflowContainer: true,
    auto: true,
    placement: 'bottom-start',
    triggerOffset: 12,
    containerOffset: 16,
    arrowOffset: 16
  })

  const { t } = useTranslation()

  return (
    <>
      <button
        {...triggerProps}
        onClick={() => setOpen(!isOpen)}
        className={`w-6 focus:outline-none rounded-full  pl-2 py-2 -mt-2 -mr-2 border border-border-buttons-secondary-${Config.PLATFORM}`}
      >
        {isOpen
          ? (
          <img src={Points} alt="Arrow up" className="w-1 text-black" />
            )
          : (
          <img src={Points} alt="Arrow down" className="w-1 " />
            )}
      </button>
      {renderLayer(
        <div>
          {isOpen && (
            <ul
              {...layerProps}
              className={`px-2 py-2 | bg-white shadow-soft-white rounded-2xl | text-gray text-xs | w-28 border border-border-buttons-secondary-${Config.PLATFORM}`}
            >
              {options.map((i, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => methods[i]()}
                    className={`px-2 py-2 flex cursor-pointer text-main-${Config.PLATFORM} hover:font-bold`}
                  >
                    <span>{t(i)}</span>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      )}
    </>
  )
}
