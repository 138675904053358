import axios from 'axios/index'
import Config from './config'
import UserActions from './redux/user/user-actions'

/*
import UserOperations from "./redux/user/user-operations";

const EXPIRED_TOKEN_ERROR_CODE = "ExpiredToken";
const INVALID_TOKEN_ERROR_CODE = "InvalidToken";
const INVALID_SIGNATURE_ERROR_CODE = "InvalidSignature";
const NOT_AUTHORIZED_EXCEPTION = "NotAuthorizedException";
const UNAUTHORIZED_ERROR_CODE = "Unauthorized";

const UNAUTHORIZED_STATUS = 401;
*/
/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  setupInterceptors: (store) => {
    // Add a request interceptor
    axios.interceptors.request.use(
      function (config) {
        const token = store.getState().auth.token
        config.headers['X-Platform'] = Config.PLATFORM
        if (token) {
          config.headers['X-Auth-Token'] = token
        }
        return config
      },
      function (err) {
        return Promise.reject(err)
      }
    )

    // Add a response interceptor
    axios.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        const originalRequest = error.config
        const token = store.getState().auth.token

        if (
          error.response &&
          error.response.status === 401 &&
          originalRequest.url.indexOf('auth/token') >= 0
        ) {
          store.dispatch(UserActions.cleanUserData())
          return Promise.reject(error)
        }

        if (
          token &&
          error.response &&
          error.response.status === 401 &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true

          return axios
            .post(`${Config.AUTH_SEC_API_URL}auth/token`, {
              refresh_token: store.getState().auth.refreshToken
            })
            .then((res) => {
              if (res.status === 200) {
                store.dispatch(UserActions.setToken({ token: res.data }))
                return axios(originalRequest)
              }
            })
        }
        return Promise.reject(error)
      }
    )
  }
}
