import { useTranslation } from 'react-i18next'
import { Input } from 'components/ui/atoms/Input'
import { RichEditor } from 'components/ui/objects/RichEditor'
import { Switch } from 'components/ui/atoms/Switch'
import { Card } from 'components/ui/atoms/Card'
import { Button } from 'components/ui/atoms/Button'
import { TfiLayoutSlider } from 'react-icons/tfi'
import { MdFormatAlignJustify } from 'react-icons/md'
import Spanish from 'styles/images/spanish.svg'
import English from 'styles/images/english.jpg'
import { FaQuestionCircle } from 'react-icons/fa'
import ReactTooltip from 'react-tooltip'
import { useEffect } from 'react'
import { ButtonCardMain } from 'components/ui/atoms/ButtonCardMain'
import Config from 'config'
import Select from 'components/ui/atoms/Select'

export const FormGeneralInformation = ({
  name,
  setName,
  formNotificationEmail,
  setFormNotificationEmail,
  formEndedDescription,
  setFormEndedDescription,
  sendNotificationEmail,
  setSendNotificationEmail,
  customEndedMessage,
  setCustomEndedMessage,
  visibilityMode,
  setVisibilityMode,
  showInvestorsPercentage,
  setShowInvestorsPercentage,
  languages = [],
  setLanguages,
  assignStartupsToPipelineManager,
  setAssignStartupsToPipelineManager,
  pipelineManagerBoard,
  setPipelineManagerBoard,
  pipelineManagerStage,
  setPipelineManagerStage,
  stages,
  boards,
  onSelectBoard,
  onSelectStage,
  boardInitialValues,
  stageInitialValues
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  return (
    <>
      <div className="w-full px-1 text-xs mt-6 font-semibold">{t('configure_form_name')}</div>
      <div className="my-2">
        <Input
          id="name"
          type="text"
          name="name"
          value={name}
          placeholder={t('name')}
          onChange={(e) => {
            setName(e.target.value)
          }}
        />
      </div>

      <div className="w-full px-1 my-2 text-xs font-semibold">{t('configure_form_visibility_mode')}</div>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-6'>
        <div>
          <Card>
            <div className='flex flex-row'>
              <div className='px-2'>
                <MdFormatAlignJustify />
              </div>
              <div className='flex flex-col'>
                <div className='text-xs font-semibold'>
                  {t('single_page_form')}
                </div>
                <div className='text-xs my-2'>
                  {t('single_page_form_description')}
                </div>
                <div className='text-xxxs'>
                  {t('single_page_form_warning')}
                </div>
              </div>
            </div>
            {visibilityMode !== 'single_page' && (
              <div className='flex justify-end'>
                <ButtonCardMain
                  text={t('select_option')}
                  onClick={() => setVisibilityMode('single_page')}
                />
              </div>
            )}
          </Card>
        </div>
        <div>
        <Card>
            <div className='flex flex-row'>
              <div className='px-2'>
                <TfiLayoutSlider />
              </div>
              <div className='flex flex-col'>
                <div className='text-xs font-semibold'>
                  {t('multiple_page_form')}
                </div>
                <div className='text-xs my-2'>
                  {t('multiple_page_form_description')}
                </div>
              </div>
            </div>
            {visibilityMode !== 'multiple_page' && (
              <div className='flex justify-end'>
                <ButtonCardMain
                  text={t('select_option')}
                  onClick={() => setVisibilityMode('multiple_page')}
                />
              </div>
            )}
          </Card>
        </div>
      </div>

      {/*<div className="w-full px-1 text-xs mb-2 font-semibold">{t('would_you_like_to_include_questions_investors_percentage')}</div>
      <Switch
        checked={showInvestorsPercentage}
        onChange={(checked) => setShowInvestorsPercentage(checked)}
        text={showInvestorsPercentage ? t('yes') : t('no')}
      />*/}
      <div>
      <div className="w-full px-1 text-xs mt-4 mb-2 font-semibold">
                {t('would_you_like_to_add_startups_automatically_to_a_pipeline_manager')}
              </div>
              <div className="w-full text-xs mt-2 mb-4">
                {t('add_startups_to_pipeline_manager_automatically_description')}
              </div>
              <Switch
                  checked={assignStartupsToPipelineManager}
                  onChange={(checked) => {
                    setAssignStartupsToPipelineManager(checked)
                    onSelectBoard(null)
                    onSelectStage(null)
                  }}
                  text={assignStartupsToPipelineManager ? t('yes_assign_startups_automatically') : t('dont_assign_startups_automatically')}
                />
              {assignStartupsToPipelineManager && <div>
                <div className="w-full px-1 text-xs mt-4 mb-2 font-semibold">
                  {t('what_board_do_you_want_to_use')}
                </div>
                <Select
                  name="pipeline_manager_board"
                  label={t('board')}
                  isClearable={false}
                  initialValues={boardInitialValues}
                  onSelect={(selection) => {
                    onSelectBoard(selection[0]?.id)
                    onSelectStage(null)
                  }}
                  items={boards.map((board) => ({
                    value: board.columns.length ? board.name : `${board.name} (${t('empty_board_disabled')})`,
                    id: board._id
                  }))}
                />
                </div>
              }
              {pipelineManagerBoard && <div>
              <div className="w-full px-1 text-xs mt-4 mb-2 font-semibold">
                  {t('what_stage_do_you_want_to_use')}
                </div>
                <Select
                isClearable={false}
                name="pipeline_manager_stage"
                label={t('stage')}
                initialValues={stageInitialValues}
                onSelect={(selection) => onSelectStage(selection[0]?.id)}
                items={stages}
              />
              </div>}
            </div>
      <div className="w-full px-1 text-xs mt-4 mb-2 font-semibold">{t('would_you_like_to_send_application_notification_email')}</div>
      <Switch
              checked={sendNotificationEmail}
              onChange={(checked) => setSendNotificationEmail(checked)}
              text={sendNotificationEmail ? t('yes') : t('no')}
            />
      {sendNotificationEmail && <>
        <div className="w-full px-1 text-xs mt-4 mb-2 font-semibold">{t('configure_application_notification_email')}</div>
        <Input
          value={formNotificationEmail}
          onChange={(e) => setFormNotificationEmail(e.target.value)}
          name="application_notification_email"
          placeholder={t('application_notification_email_description')}
        />
      </>}

      <div className="w-full px-1 text-xs mb-2 my-6 font-semibold">{t('would_you_like_to_customize_the_ended_application_message')}</div>
      <Switch
              checked={customEndedMessage}
              onChange={(checked) => setCustomEndedMessage(checked)}
              text={customEndedMessage ? t('yes') : t('no')}
            />
      {customEndedMessage && <div>
        <div className="w-full px-1 text-xs mb-2 my-6 font-semibold">{t('application_form_ended_label')}</div>
        <RichEditor
          name="application_form_ended_description"
          placeholder={t('application_form_ended_description')}
          className="mb-4"
          value={formEndedDescription}
          toolbarClassName="application_form_ended_description"
          onChange={value => {
            setFormEndedDescription(value === '<p><br></p>' ? '' : value)
          }}
          // TODO: TAKE A LOOK TO FUTHER BUMS FOR react-quill LIBRARY TO SOLVE CLEAN ISSUE
      /></div>}

      <div className="w-full px-1 text-xs mb-2 my-6 font-semibold flex">{t('configure_languages_form')}
                  <FaQuestionCircle className='ml-0.5 cursor-pointer' data-tip={t('configure_languages_form_help')}/>
                  </div>
      <div className="flex flex-row mb-3">
          <div
            onClick={() => languages.includes('es') ? setLanguages(languages.filter((language) => language !== 'es')) : setLanguages([...languages, 'es']) }
            className={`w-8 p-1 hover:shadow-inner rounded-md cursor-pointer border-2 bg-white ${languages.includes('es') ? `shadow-inner border-main-${Config.PLATFORM}` : 'shadow-soft-white'}`}
          >
            <img src={Spanish} alt="Spanish" />
          </div>

          <div
            onClick={() => languages.includes('en') ? setLanguages(languages.filter((language) => language !== 'en')) : setLanguages([...languages, 'en']) }
            className={`bg-white w-8 p-1 hover:shadow-inner ml-2 rounded-md cursor-pointer border-2 ${languages.includes('en') ? `shadow-inner border-main-${Config.PLATFORM}` : 'shadow-soft-white'}`}
          >
            <img src={English} alt="English" style={{ height: 13 }} />
          </div>
      </div>
    </>
  )
}
