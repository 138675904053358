import { SectionHeader } from '../../ui/molecules/SectionHeader'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import QuestionnairesService from '../../../services/questionnaires-service'
import { useToasts } from 'react-toast-notifications'
import useUser from '../../../hooks/useUser'
import { Loader } from '../../ui/molecules/Loader'
import { Button } from '../../ui/atoms/Button'
import { FaEye, FaEyeSlash, FaSave, FaTrash } from 'react-icons/all'
import { Alert } from '../../ui/molecules/Alert'
import { FormGeneralInformation } from './forms-form/FormGeneralInformation'
import { FormAppearance } from './forms-form/FormAppearance'
import { RoutesLinks } from '../../routes-links'
import { Input } from '../../ui/atoms/Input'
import InputFile from '../../ui/atoms/input-files'
import { Switch } from '../../ui/atoms/Switch'
import Select from '../../ui/atoms/Select'
import FormUtils from '../../../utils/form-utils'
import { useSwal } from '../../../hooks/useSwal'
import { useHistory } from 'react-router-dom'
import { ButtonDanger } from 'components/ui/atoms/ButtonDanger'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import BoardsService from 'services/boards-service'

export const FormSettingsSection = ({
  questionnaire,
  isTemplate,
  getForm,
  onSave,
  boards,
  assignStartupsToPipelineManager,
  setAssignStartupsToPipelineManager,
  pipelineManagerBoard,
  setPipelineManagerBoard,
  pipelineManagerStage,
  setPipelineManagerStage,
  name,
  setName,
  formBanner,
  setFormBanner,
  formDescription,
  setFormDescription,
  formFavicon,
  setFormFavicon,
  formTitle,
  setFormTitle,
  formNotificationEmail,
  setFormNotificationEmail,
  formEndedDescription,
  setFormEndedDescription,
  languages,
  setLanguages,
  sendNotificationEmail,
  setSendNotificationEmail,
  customEndedMessage,
  setCustomEndedMessage,
  formBackgroundColor,
  setFormBackgroundColor,
  formFontColor,
  setFormFontColor,
  formButtonFontColor,
  setFormButtonFontColor,
  formButtonColor,
  setFormButtonColor,
  visibilityMode,
  setVisibilityMode,
  showInvestorsPercentage,
  setShowInvestorsPercentage,
  activeTemplate,
  setActiveTemplate,
  category,
  setCategory,
  estimatedDuration,
  setEstimatedDuration,
  templateLogo,
  setTemplateLogo,
  investorName,
  setInvestorName
}) => {

  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { isAdmin } = useUser()
  const { alert, confirm } = useSwal()
  const history = useHistory()
  const [section, setSection] = useState('general')
  const [sections] = useState(['general', 'appearance'])
  const [loading, setLoading] = useState(false)
  
  const [stages, setStages] = useState([])
  const [boardInitialValues, setBoardInitialValues] = useState([])
  const [stageInitialValues, setStageInitialValues] = useState([])

  useEffect(() => {
    if (pipelineManagerBoard) {
      onSelectBoard(pipelineManagerBoard)
    }
    if (pipelineManagerStage) {
      const board = boards.find(board => board._id === pipelineManagerBoard)
      const _stages = board.columns.map((stage) => { return { id: stage._id, value: stage.name } })
      setStageInitialValues(_stages.filter((st) => st.id === pipelineManagerStage))
    }
  }, [])

  const onSelectImage = async (field, file) => {
    const fileSizeMb = (file?.size || 0) / 1024 / 1024

    if (fileSizeMb > 5) {
      addToast(t('error_file_size', { max_size: 5 }), {
        appearance: 'error',
        autoDismiss: true
      })
      return
    }

    setLoading(true)
    if (field === 'template_logo') {
      setTemplateLogo(file ? await FormUtils.fileToBase64(file) : null)
    } else if (field === 'form_banner') {
      setFormBanner(file ? await FormUtils.fileToBase64(file) : null)
    } else if (field === 'form_favicon') {
      setFormFavicon(file ? await FormUtils.fileToBase64(file) : null)
    }
    setTimeout(() => setLoading(false), 500)
  }

  const disableForm = async () => {
    confirm({
      title: t('are_you_sure'),
      icon: 'warning'
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        await QuestionnairesService.updateQuestionnairesForm(questionnaire._id, { published: false }).then(() => {
          addToast(t('form_disabled_successfully'), {
            appearance: 'success',
            autoDismiss: true
          })
          getForm()
        }).catch(error => {
          addToast(
            error.response && error.response.data.msg_key
              ? t(error.response.data.msg_key)
              : t('error_occurred_disabling_form'),
            {
              appearance: 'error',
              autoDismiss: true
            }
          )
        })
      }
    })
  }

  const enableForm = async () => {
    confirm({
      title: t('are_you_sure'),
      icon: 'warning'
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        await QuestionnairesService.updateQuestionnairesForm(questionnaire._id, { published: true }).then(() => {
          addToast(t('form_enabled_successfully'), {
            appearance: 'success',
            autoDismiss: true
          })
          getForm()
          onSave && onSave(true)
        }).catch(error => {
          console.log(error)
          addToast(
            error.response && error.response.data.msg_key
              ? t(error.response.data.msg_key)
              : t('error_occurred_enabling_form'),
            {
              appearance: 'error',
              autoDismiss: true
            }
          )
        })
      }
    })
  }

  const discardDraft = async () => {
    confirm({
      title: t('are_you_sure'),
      icon: 'warning'
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true)
          await QuestionnairesService.updateQuestionnairesForm(questionnaire._id, { draft: null }).then(() => {
            addToast(t('form_updated_successfully'), {
              appearance: 'success',
              autoDismiss: true
            })
            getForm()
          })
        } catch (error) {
          addToast(t('error_occurred_updating_form'), {
            appearance: 'error',
            autoDismiss: true
          })
        } finally {
          setLoading(false)
        }
      }
    })
  }

  const deleteForm = () => {
    confirm({
      title: t('are_you_sure'),
      text: t(questionnaire.published ? 'delete_published_form_warning' : 'revert_notification'),
      icon: 'warning'
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          await QuestionnairesService.deleteQuestionnairesForm(questionnaire._id)
          addToast(t('form_deleted_successfully'), {
            appearance: 'success',
            autoDismiss: true
          })
          history.push(RoutesLinks.QE_FORMS_LINK)
        } catch (error) {
          addToast(
            error.response
              ? error.response.data.msg_key
                ? t(error.response.data.msg_key)
                : t('error_occurred')
              : t('error_occurred'),
            {
              appearance: 'error',
              autoDismiss: true
            }
          )
        }
      }
    })
  }

  const deleteTemplate = () => {
    confirm({
      title: t('are_you_sure'),
      text: t('revert_notification'),
      icon: 'warning'
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          await QuestionnairesService.deleteQuestionnairesTemplate(questionnaire._id)
          addToast(t('template_deleted_successfully'), {
            appearance: 'success',
            autoDismiss: true
          })
          history.push(RoutesLinks.QE_TEMPLATES_LINK)
        } catch (error) {
          addToast(
            error.response
              ? error.response.data.msg_key
                ? t(error.response.data.msg_key)
                : t('error_occurred')
              : t('error_occurred_deleting_template'),
            {
              appearance: 'error',
              autoDismiss: true
            }
          )
        }
      }
    })
  }

  const checkValues = () => {
    if (sendNotificationEmail && !formNotificationEmail) {
      addToast(t('application_notification_email_required'), {
        appearance: 'error',
        autoDismiss: true
      })
      return false
    } else if (customEndedMessage && !formEndedDescription) {
      addToast(t('custom_application_ended_message_required'), {
        appearance: 'error',
        autoDismiss: true
      })
      return false
    } else if (!languages.length) {
      addToast(t('form_should_contains_at_least_one_language'), {
        appearance: 'error',
        autoDismiss: true
      })
      return false
    }

    return true
  }

  const onSelectBoard = (boardId) => {
    const board = boards.find(board => board._id === boardId)

    if (board) {
      setBoardInitialValues([{ id: board._id, value: board.name }])
      setPipelineManagerBoard(boardId)
      setStages(board.columns.map((stage) => { return { id: stage._id, value: stage.name } }))
    } else {
      setBoardInitialValues([])
      setStages([])
      setPipelineManagerBoard(null)
    }
  }

  const onSelectStage = (stageId) => {
    const stage = stages.find((stage) => stage.id === stageId)
    if (stage) {
      setStageInitialValues([stage])
      setPipelineManagerStage(stageId)
    } else {
      setStageInitialValues([])
      setPipelineManagerStage(null)
    }
  }

  return (
    <>
      <SectionHeader
        section={section}
        setSection={setSection}
        sectionKeys={sections}
        sectionTitles={sections.map((s) => t(s))}
      />

      {!!questionnaire?.draft && <Alert style='warning' text={t('form_draft_warning_2')} />}

      {section === 'general' && (
        <>
          <FormGeneralInformation
            name={name}
            setName={setName}
            formNotificationEmail={formNotificationEmail}
            setFormNotificationEmail={setFormNotificationEmail}
            setFormEndedDescription={setFormEndedDescription}
            formEndedDescription={formEndedDescription}
            sendNotificationEmail={sendNotificationEmail}
            setSendNotificationEmail={setSendNotificationEmail}
            customEndedMessage={customEndedMessage}
            setCustomEndedMessage={setCustomEndedMessage}
            visibilityMode={visibilityMode}
            setVisibilityMode={setVisibilityMode}
            showInvestorsPercentage={showInvestorsPercentage}
            setShowInvestorsPercentage={setShowInvestorsPercentage}
            languages={languages}
            setLanguages={setLanguages}
            assignStartupsToPipelineManager={assignStartupsToPipelineManager}
            setAssignStartupsToPipelineManager={setAssignStartupsToPipelineManager}
            pipelineManagerBoard={pipelineManagerBoard}
            setPipelineManagerBoard={setPipelineManagerBoard}
            pipelineManagerStage={pipelineManagerStage}
            setPipelineManagerStage={setPipelineManagerStage}
            stages={stages}
            boards={boards}
            onSelectBoard={onSelectBoard}
            onSelectStage={onSelectStage}
            boardInitialValues={boardInitialValues}
            stageInitialValues={stageInitialValues}
          />

          {isAdmin && isTemplate && (
            <>
              <div className="w-full px-1 text-xs my-2 font-semibold">
                {t('configure_template_category')}
              </div>

              <div className="mb-4">
                <Input
                  id="category"
                  type="text"
                  name="category"
                  value={category}
                  placeholder={t('category')}
                  onChange={(e) => {
                    setCategory(e.target.value)
                  }}
                />
              </div>
              <div className='my-3'>
                <Input
                  id="investor_name"
                  type="text"
                  name="investor_name"
                  value={investorName}
                  placeholder={t('investor')}
                  onChange={(e) => {
                    setInvestorName(e.target.value)
                  }}
                />
              </div>
              <div className="my-4">
                {templateLogo ? (
                  <>
                    <div className="my-4">
                      <img src={templateLogo} alt="Logo preview" className="w-20 max-w-full rounded-lg" />
                      <Button
                        type="button"
                        iconComponent={<FaTrash className="inline-block mr-2" />}
                        text={t('remove')}
                        width='auto'
                        bgColor='red'
                        className="mt-4 text-white"
                        verticalMargin="0"
                        textSize="xs"
                        onClick={() => setTemplateLogo(null)}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="w-full px-1 text-xs my-2 font-semibold">{t('configure_template_logo')}</div>

                    <InputFile name="templateLogo" setSelectedFile={(file) => onSelectImage('template_logo', file)} accept="image/*" />
                  </>
                )}
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 pb-4">
                <div>
                  <div className="w-full px-1 text-xs my-4 font-semibold">{t('configure_template_status')}</div>
                  <Switch
                    key={'activeTemplate'}
                    size="sm"
                    checked={activeTemplate}
                    onChange={(value) => setActiveTemplate(value)}
                    textSize={'xs'}
                  />
                </div>
                <div>
                  <div className="w-full px-1 text-xs my-4 font-semibold">{t('configure_template_scope')}</div>
                  <Select
                    id="account_type"
                    name="account_type"
                    placeholder={t('select_scope')}
                    initialValues={[]}
                    items={[
                      {
                        id: 'venture_capitals',
                        value: t('venture_capitals')
                      },
                      {
                        id: 'business_angel',
                        value: t('business_angel')
                      }
                    ]}
                  />
                </div>
                <div>
                  <div className="w-full px-1 text-xs my-4 font-semibold">{t('configure_template_estimated_duration')}</div>
                  <Input
                    id="estimated_duration"
                    type="number"
                    name="estimated_duration"
                    value={estimatedDuration}
                    placeholder={t('estimated_duration')}
                    onChange={(e) => {
                      setEstimatedDuration(e.target.value)
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}

      {section === 'appearance' && (
        <FormAppearance
          formBanner={formBanner}
          onSelectImage={onSelectImage}
          formTitle={formTitle}
          setFormTitle={setFormTitle}
          formDescription={formDescription}
          setFormDescription={setFormDescription}
          formFavicon={formFavicon}
          formBackgroundColor={formBackgroundColor}
          setFormBackgroundColor={setFormBackgroundColor}
          formFontColor={formFontColor}
          setFormFontColor={setFormFontColor}
          formButtonColor={formButtonColor}
          setFormButtonColor={setFormButtonColor}
          formButtonFontColor={formButtonFontColor}
          setFormButtonFontColor={setFormButtonFontColor}
        />
      )}

      <div className="flex justify-end pb-8 mt-4">
        <ButtonDanger
          onClick={isTemplate ? deleteTemplate : deleteForm}
          text={t(isTemplate ? 'delete_template' : 'delete_form')}
          iconComponent={<FaTrash className="inline mr-3" />}
          marginRight={2}
        />

        {!isTemplate && !questionnaire?.published && (
          <ButtonSecondary
            onClick={enableForm}
            text={t('enable_form')}
            iconComponent={<FaEye className="inline mr-3" />}
            marginRight={2}
          />
        )}

        {!isTemplate && questionnaire?.published && (
          <ButtonSecondary
            onClick={disableForm}
            text={t('disable_form')}
            iconComponent={<FaEyeSlash className="inline mr-3" />}
            marginRight={2}
          />
        )}

        {!!questionnaire?.draft && (
          <ButtonSecondary
            onClick={discardDraft}
            text={t('discard_draft')}
            iconComponent={<FaEyeSlash className="inline mr-3" />}
            marginRight={2}
            />
        )}

      </div>

      {loading && <Loader />}
    </>
  )
}
