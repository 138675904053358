import { Button } from 'components/ui/atoms/Button'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'components/ui/molecules/Modal'
import { TagsSelector } from '../ui/atoms/TagsSelector'
import TagsService from '../../services/tags-service'
import { useToasts } from 'react-toast-notifications'
import { Spinner } from '../ui/atoms/Spinner'
import Config from 'config';
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'

export const ManageTagsModal = ({
  category,
  showModal,
  handleCloseModal,
  onSubmit,
  tags: tagsFromProps,
  currentTags
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [tags, setTags] = useState(tagsFromProps || [])
  const [loading, setLoading] = useState(false)
  const [selectedTags, setSelectedTags] = useState([])

  const getTags = () => {
    return TagsService.getTags(category).then((response) => {
      setTags(response.data.data)
    }).catch((error) => {
      addToast(
        error.response && error.response.data.msg_key
          ? error.response.data.msg_key
          : t('error_occurred_retrieving_tags'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    })
  }

  useEffect(() => {
    if (tagsFromProps) {
      setTags(tagsFromProps)
    } else if (category) {
      setLoading(true)
      getTags().finally(() => setLoading(false))
    }
  }, [tagsFromProps, category])

  useEffect(() => {
    setSelectedTags(currentTags ? currentTags.map((tag) => tag._id) : [])
  }, [currentTags])

  //console.log(selectedTags)
  return (
    <Modal showModal={showModal}>
      <div className='w-72'>
        <h3 className={`mb-4 text-main-${Config.PLATFORM}`}>
          {t('manage_tags')}
        </h3>

        {loading && (
          <div className='text-center'>
            <Spinner />
          </div>
        )}

        {!loading && (
          <TagsSelector
            tags={tags}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
          />
        )}

        <div className="flex justify-between border-t border-gray-lines mt-6 pt-6">
          <ButtonSecondary
            text={t('cancel')}
            onClick={handleCloseModal}
          />
          <ButtonMain
            type="submit"
            text={t('save')}
            onClick={() => onSubmit(selectedTags)}
          />
        </div>
      </div>
    </Modal>
  )
}
