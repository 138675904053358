import styled, { css } from 'styled-components'
import React from 'react'

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  ${(props) =>
    props.horizontal &&
    css`
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      align-items: center;
    `}
`

export function UiList (props) {
  return (
    <List {...props}>
      {props.children.map((child, index) => (
        <li key={index}>{child}</li>
      ))}
    </List>
  )
}
