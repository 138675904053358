import { applyMiddleware, combineReducers, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'localforage'
import UserReducer from './user'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import AppReducer, { AppOperations } from './app'
import ProjectReducer from './project'
import InvitationsReducer from './invitations'

import EntrepreneursReducer from './entrepreneurs'
import NetworkService from '../network-service.js'
import UserOperations from './user/user-operations'
import InvestmentReducer from './investment'
import ChecksReducer from './checks'
import QuestionnaireReducer from './questionnaires'
import NotificationsReducer from './notifications'
import ScoreCardsReducer from './score-cards/score-cards-reducer'
import RemindersReducer from './reminders'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'auth', 'login_entries']
}

const userPersistConfig = {
  key: 'user',
  storage,
  whitelist: ['name', 'lastName', 'email', 'dni', 'group']
}

const remindersPersistConfig = {
  key: 'reminders',
  storage,
  whitelist: ['reminders', 'total']
}

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['token', 'api_key']
}

const loginEntriesPersistConfig = {
  key: 'login_entries',
  storage,
  whitelist: ['data']
}

const checksPersistConfig = {
  key: 'checks',
  storage,
  whitelist: ['checked', 'stage']
}

const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, UserReducer.user),
  auth: persistReducer(authPersistConfig, UserReducer.auth),
  loginEntries: persistReducer(
    loginEntriesPersistConfig,
    UserReducer.loginEntries
  ),
  checks: persistReducer(checksPersistConfig, ChecksReducer),
  notifications: NotificationsReducer,
  reminders: persistReducer(remindersPersistConfig, RemindersReducer),
  app: AppReducer,
  project: ProjectReducer,
  invitations: InvitationsReducer,
  entrepreneurs: EntrepreneursReducer,
  investment: InvestmentReducer,
  questionnaire: QuestionnaireReducer,
  scoreCards: ScoreCardsReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const composeEnhancers = composeWithDevTools({
  // trace: true,
  // traceLimit: 25,
  actionsBlacklist: []
})

const middlewares = [thunk]
const store = createStore(
  persistedReducer,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
)

const persistor = persistStore(store, {}, async () => {
  NetworkService.setupInterceptors(store)
  await store.dispatch(UserOperations.checkUserExist())
  store.dispatch(AppOperations.setLoading(false))
})

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default { store, persistor }
