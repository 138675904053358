import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { RoutesLinks } from './routes-links'
import Login from './login'
import Checks from './checks'
import RegisterAccountType from './register-account-type'
import RegisterForm from './register-form'
import RegisterConfirm from './register-confirm'
import RegisterResend from './register-resend'
import OnBoarding from './on-boarding'
import Forgot from './forgot'
import ForgotResend from './forgot-resend'
import ForgotConfirm from './forgot-confirm'
import PrivateRoute from './private-route'
import ScoreCards from './score-cards'
import ScoreCardsWizard from './score-cards-wizard'
import ScoreCard from './score-cards/components/score-card'
import Event from './events/components/event'
import Application from './events/components/event/components/application'
import { CuratedDealFlow } from './curated-dealflow/CuratedDealflow'
import Dashboard from './dashboard'
import Notifications from './notifications'
import Forms from './qe-forms'
import { LinkedInPopUp } from 'react-linkedin-login-oauth2'
import Templates from './qe-templates'
import Template from './qe-templates/components/template'
import Questions from './qe-questions'
import MyStartup from './startup-profile'
import QuestionForm from './qe-questions/components/question-form'
import DataRoom from './project/components/documentation/components/data-room'
import MessagingSystem from './messaging-system/messaging-system-view'
import Company from './company'
import InvestorsAdmin from './users/components/investors'
import SharedDataRoom from './shared-data-room'
import DataRooms from './data-rooms'
import Boards from './pipeline-manager/components/boards'
import Board from './pipeline-manager/components/board'
import ProjectInvestors from './investors'
import { Professionals } from './professionals/Professionals'
import SupportTickets from './support/components/tickets'
import SupportNPS from './support/components/nps'
import SupportRequestedFeatures from './support/components/requested-features'
import { SharedEventReport } from 'components/events/components/report/SharedEventReport'
import SharedEventJudge from 'components/events/components/judge/SharedEventJudge'
import { PublicForm } from 'components/public-form/PublicForm'
import useUser from '../hooks/useUser'
import StartupCreateOrUpdate from './edit-startup'
import Account from './account/Account'
import { useAuth } from '../hooks/useAuth'
import DocumentationTags from './documentation/tags'
import Documents from './documentation/documents'
import { DealflowListView } from './dealflow/DealflowListView'
import { DealflowView } from './dealflow/DealflowView'
import { InvestorInvestmentThesis } from './investment-thesis/InvestorInvestmentThesis'
import { RegisterEventJudge } from './events/components/judge/RegisterEventJudge'
import DeaflowForm from './dealflow/DeaflowForm'
import { PublicProfile } from './public-profile/PublicProfile'
import { SharedDealflow } from './shared-dealflow/SharedDealflow'
import { KmZeroReport } from './KmZeroReport/KmZeroReport'
import { KmZeroReportVC } from './KmZeroReport/KmZeroReportVC'
import { Reminders } from './reminders/Reminders'

import EvaluationProcesses from './evaluations/EvaluationProcesses'
import QuestionnairesForm from './qe-forms/components/forms-form/QuestionnairesForm'
import IRM from './irm/Irm'
import IRMInvestor from './irm/IRMInvestor'
import Kuff from './kuff/Kuff'
import { IrmAdmin } from './irm/IrmAdmin'
import KbotsDealflowAdmin from './kbots/KbotsDealflowAdmin'
import { Startups } from './professionals/Startups'

import Config from 'config'
import Reports from './reports/Reports'

const Routes = () => {
  const { isAdmin, isFounder, isInvestor, user, plan, isProfessional } = useUser()
  const { token } = useAuth()

  const investorHaveSection = section => {
    return (user?.investor?.permissions?.sections || []).includes(section)
  }
  return (
    <Switch>
      {!token && <Route exact path='/linkedin' component={LinkedInPopUp} />}

      <PrivateRoute exact path={RoutesLinks.DASHBOARD_LINK}>
          {user && !isProfessional && (
            <Dashboard />
          )}
          {(Config.PLATFORM !== '000' && user && isProfessional) && (
          <Redirect to={RoutesLinks.STARTUPS_LINK} />
          )}
      </PrivateRoute>

      {isAdmin && (
        <PrivateRoute exact path={`${RoutesLinks.SUPPORT_TICKETS_LINK}`}>
          <SupportTickets />
        </PrivateRoute>
      )}

      {isAdmin && (
        <PrivateRoute exact path={`${RoutesLinks.SUPPORT_NPS_LINK}`}>
          <SupportNPS />
        </PrivateRoute>
      )}

      {isAdmin && (
        <PrivateRoute exact path={`${RoutesLinks.SUPPORT_REQUESTED_FEATURES_LINK}`}>
          <SupportRequestedFeatures />
        </PrivateRoute>
      )}

      {(isFounder || isInvestor || isProfessional) && (
        <PrivateRoute exact path={RoutesLinks.ACCOUNT_LINK}>
          <Account />
        </PrivateRoute>
      )}

      {isInvestor && (
        <PrivateRoute exact path={'/investment-thesis'}>
          <InvestorInvestmentThesis />
        </PrivateRoute>
      )}

      {isAdmin && (
        <PrivateRoute exact path={`${RoutesLinks.QE_TEMPLATES_LINK}/wizard`}>
          <QuestionnairesForm />
        </PrivateRoute>
      )}

      {isAdmin && (
        <PrivateRoute exact path={`${RoutesLinks.QE_TEMPLATES_LINK}/:templateId`}>
          <QuestionnairesForm />
        </PrivateRoute>
      )}

      {(isAdmin || isInvestor) && (
        <PrivateRoute exact path={RoutesLinks.QE_FORMS_LINK}>
          <Forms />
        </PrivateRoute>
      )}

      {(isInvestor || isAdmin) && (
        <PrivateRoute exact path={`${RoutesLinks.QE_FORMS_LINK}/wizard`}>
          <QuestionnairesForm />
        </PrivateRoute>
      )}

      {(isAdmin || isInvestor) && (
        <PrivateRoute exact path={`${RoutesLinks.QE_FORMS_LINK}/:formId`}>
          <QuestionnairesForm />
        </PrivateRoute>
      )}

      {!isFounder && (
        <PrivateRoute exact path={RoutesLinks.QE_TEMPLATES_LINK}>
          <Templates />
        </PrivateRoute>
      )}

      {isInvestor && (
        <PrivateRoute exact path={`${RoutesLinks.QE_TEMPLATES_LINK}/:templateId`}>
          <Template />
        </PrivateRoute>
      )}

      {(isAdmin || isInvestor) && (
        <PrivateRoute exact path={RoutesLinks.QE_QUESTIONS_LINK}>
          <Questions />
        </PrivateRoute>
      )}

      {(isAdmin || isInvestor) && (
        <PrivateRoute exact path={`${RoutesLinks.QE_QUESTIONS_WIZARD_LINK}`}>
          <QuestionForm />
        </PrivateRoute>
      )}

      {(isAdmin || isInvestor) && (
        <PrivateRoute exact path={`${RoutesLinks.QE_QUESTIONS_WIZARD_LINK}/:questionId`}>
          <QuestionForm />
        </PrivateRoute>
      )}

      {isProfessional && (
        <PrivateRoute exact path={RoutesLinks.STARTUPS_LINK}>
          <Startups />
        </PrivateRoute>
      )}

      {(isInvestor || isAdmin) && (
        <PrivateRoute exact path={RoutesLinks.DEALFLOW_LINK}>
          <DealflowListView />
        </PrivateRoute>
      )}

      {(isInvestor || isAdmin) && (
        <PrivateRoute exact path={`${RoutesLinks.DEALFLOW_LINK}:dealId`}>
          <DealflowView />
        </PrivateRoute>
      )}

      {isInvestor && (
        <PrivateRoute exact path={RoutesLinks.EVALUATION_PROCESSES_LINK}>
          <EvaluationProcesses />
        </PrivateRoute>
      )}

      {isInvestor && (
        <PrivateRoute exact path={RoutesLinks.SCORE_CARDS_LINK}>
          <ScoreCards />
        </PrivateRoute>
      )}

      {isInvestor && (
        <PrivateRoute exact path={`${RoutesLinks.SCORE_CARDS_LINK}wizard`}>
          <ScoreCardsWizard />
        </PrivateRoute>
      )}

      {isInvestor && (
        <PrivateRoute exact path={`${RoutesLinks.SCORE_CARDS_LINK}:scoreCardId`}>
          <ScoreCard />
        </PrivateRoute>
      )}

      {isInvestor && (
        <PrivateRoute exact path={`${RoutesLinks.EVALUATION_PROCESSES_LINK}:eventId`}>
          <Event />
        </PrivateRoute>
      )}

      {isInvestor && (
        <PrivateRoute exact path={`${RoutesLinks.EVALUATION_PROCESSES_LINK}:eventId/startups/:startupId`}>
          <Application />
        </PrivateRoute>
      )}

      {isInvestor && (
        <PrivateRoute exact path={`${RoutesLinks.SCORE_CARDS_LINK}:scoreCardId/events/:eventId`}>
          <ScoreCard />
        </PrivateRoute>
      )}

      {isInvestor && (
        <PrivateRoute exact path={RoutesLinks.CURATED_DEALFLOW_LINK}>
          <CuratedDealFlow />
        </PrivateRoute>
      )}

      {isFounder && (
        <PrivateRoute exact path={`${RoutesLinks.MY_STARTUP_LINK}`}>
          <MyStartup />
        </PrivateRoute>
      )}

      {isFounder && (
        <PrivateRoute exact path={`${RoutesLinks.MY_STARTUP_LINK}wizard`}>
          <StartupCreateOrUpdate />
        </PrivateRoute>
      )}

      {isAdmin && (
        <PrivateRoute exact path={`${RoutesLinks.USERS_LINK}investors/irm`}>
          <IrmAdmin />
        </PrivateRoute>
      )}

      {isAdmin && (
        <PrivateRoute exact path={`${RoutesLinks.KIOTA_BOTS_DEALFLOW_LINK}`}>
          <KbotsDealflowAdmin />
        </PrivateRoute>
      )}

      {isAdmin && (
        <PrivateRoute exact path={`${RoutesLinks.USERS_LINK}investors`}>
          <InvestorsAdmin />
        </PrivateRoute>
      )}
      {(isFounder || isProfessional) && (
        <PrivateRoute exact path={`${RoutesLinks.INVESTORS_LINK}`}>
          <ProjectInvestors />
        </PrivateRoute>
      )}

      {isFounder && (
        <PrivateRoute exact path={`${RoutesLinks.PROFESSIONALS_LINK}`}>
          <Professionals />
        </PrivateRoute>
      )}


      {(isInvestor || isProfessional) && (
        <PrivateRoute exact path={`${RoutesLinks.COMPANY_LINK}`}>
          <Company />
        </PrivateRoute>
      )}

      {isInvestor && (
        <PrivateRoute exact path={`${RoutesLinks.MESSAGING_SYSTEM_LINK}`}>
          <MessagingSystem />
        </PrivateRoute>
      )}

      {isFounder && (
        <PrivateRoute exact path={`${RoutesLinks.DATA_ROOMS_LINK}`}>
          <DataRooms />
        </PrivateRoute>
      )}

      {isFounder && (
        <PrivateRoute exact path={`${RoutesLinks.DATA_ROOMS_LINK}/:dataRoomId`}>
          <DataRoom />
        </PrivateRoute>
      )}

      {isInvestor && (
        <PrivateRoute exact path={`${RoutesLinks.PIPELINE_MANAGER_LINK}boards`}>
          <Boards />
        </PrivateRoute>
      )}

      {isInvestor && (
        <PrivateRoute exact path={`${RoutesLinks.REPORTS_LINK}`}>
          <Reports />
        </PrivateRoute>
      )}

      {isInvestor && (
        <PrivateRoute exact path={`${RoutesLinks.PIPELINE_MANAGER_LINK}boards/:boardId`}>
          <Board />
        </PrivateRoute>
      )}

      {isInvestor && (
        <PrivateRoute exact path={`${RoutesLinks.DOCUMENTATION_LINK}documents`}>
          <Documents />
        </PrivateRoute>
      )}

      {isInvestor && (
        <PrivateRoute exact path={`${RoutesLinks.DOCUMENTATION_LINK}tags`}>
          <DocumentationTags />
        </PrivateRoute>
      )}

      {/* {isInvestor && (
       <PrivateRoute exact path={RoutesLinks.DEALFLOW_LINK}>
       <Milestones  />
       </PrivateRoute>
       )} */}

      {/* {isFounder && (
       <PrivateRoute exact path={`${RoutesLinks.FUNNEL_LINK}boards`}>
       <FunnelBoards  />
       </PrivateRoute>
       )}
       {isFounder && (
       <PrivateRoute exact path={`${RoutesLinks.FUNNEL_LINK}boards/:boardId`}>
       <FunnelBoard  />
       </PrivateRoute>
       )}
       {isFounder && (
       <PrivateRoute exact path={`${RoutesLinks.FUNNEL_LINK}templates`}>
       <FunnelBoardsTemplates  />
       </PrivateRoute>
       )} */}

      {isInvestor && (
        <PrivateRoute exact path={RoutesLinks.INVESTORS_RELATIONS_MANAGER_LINK}>
          <IRM />
        </PrivateRoute>
      )}

      {isInvestor && (
        <PrivateRoute exact path={`${RoutesLinks.INVESTORS_RELATIONS_MANAGER_LINK}:investorId`}>
          <IRMInvestor />
        </PrivateRoute>
      )}

      <Route exact path={RoutesLinks.LOGIN_LINK}>
        <Login />
      </Route>

      {!token  && (
        <Route exact path={RoutesLinks.REGISTER_LINK}>
          <RegisterAccountType />
        </Route>
      )}

      {!token && (
        <Route exact path={RoutesLinks.REGISTER_FOUNDER_LINK}>
          <RegisterForm />
        </Route>
      )}

      {!token && (
        <Route exact path={RoutesLinks.REGISTER_INVESTOR_LINK}>
          <RegisterForm />
        </Route>
      )}

      {!token && (
        <Route exact path={RoutesLinks.REGISTER_PROFESSIONAL_LINK}>
          <RegisterForm />
        </Route>
      )}

      {!token && (
        <Route exact path={RoutesLinks.REGISTER_INVITATION}>
          <RegisterForm />
        </Route>
      )}

      {!token && (
        <Route exact path={RoutesLinks.REGISTER_RESEND_LINK}>
          <RegisterResend />
        </Route>
      )}

      {!token && (
        <Route exact path={RoutesLinks.REGISTER_CONFIRM}>
          <RegisterConfirm />
        </Route>
      )}

      <Route exact path={RoutesLinks.CHECKS_LINK}>
        <Checks />
      </Route>

      <Route exact path={RoutesLinks.ON_BOARDING_LINK}>
        <OnBoarding />
      </Route>

      <Route exact path={`${RoutesLinks.SHARED_LINK}data-room/:dataRoomId`}>
        <SharedDataRoom />
      </Route>

      <Route exact path={`${RoutesLinks.KUFF_LINK}`}>
        <Kuff />
      </Route>

      <Route exact path={`${RoutesLinks.SHARED_LINK}events/:eventId/report/:sharedId`}>
        <SharedEventReport />
      </Route>

      <Route exact path={`${RoutesLinks.SHARED_LINK}events/:eventId`}>
        <SharedEventJudge />
      </Route>

      <Route exact path={`${RoutesLinks.SHARED_LINK}events/:eventId/judge-register`}>
        <RegisterEventJudge />
      </Route>

      {/*FORMULARIO DE KMZERO*/}
      <Route exact path={`${RoutesLinks.PUBLIC_EVENTS_LINK}63db95e1e27335268d646a1d`}>
        <Redirect to={`${RoutesLinks.PUBLIC_FORMS_LINK}6423021a10f9e261a95ec61f`} />
      </Route>

      {/*FORMULARIO DE ÓRBITA*/}
      <Route exact path={`${RoutesLinks.PUBLIC_EVENTS_LINK}63ff51a9536e671ec13031f1`}>
        <Redirect to={`${RoutesLinks.PUBLIC_FORMS_LINK}63ff516559b78229fe30d534`} />
      </Route>

      {/*FORMULARIO DE FUNDALOGY*/}
      <Route exact path={`${RoutesLinks.PUBLIC_EVENTS_LINK}63e2281336d75a6fae718aab`}>
        <Redirect to={`${RoutesLinks.PUBLIC_FORMS_LINK}63d26a39c586122d338eae3e`} />
      </Route>

      {/*FORMULARIO DE CAPSA*/}
      <Route exact path={`${RoutesLinks.PUBLIC_EVENTS_LINK}630733873b9f651afc40132e`}>
        <Redirect to={`${RoutesLinks.PUBLIC_FORMS_LINK}62d6695ea18c6e21b7460331`} />
      </Route>

      {/*FORMULARIO DE FIRST DROP*/}
      <Route exact path={`${RoutesLinks.PUBLIC_EVENTS_LINK}6343e1548430842f0a3487f9`}>
        <Redirect to={`${RoutesLinks.PUBLIC_FORMS_LINK}6343e0f98430842f0a3487f3`} />
      </Route>

      {/*FORMULARIO DE ALHAMBRA*/}
      <Route exact path={`${RoutesLinks.PUBLIC_EVENTS_LINK}6373a81d3c95b91672c1b045`}>
        <Redirect to={`${RoutesLinks.PUBLIC_FORMS_LINK}63d30d5f2728db42bdfe2f92`} />
      </Route>

      <Route exact path={`${RoutesLinks.PUBLIC_FORMS_LINK}:formId`}>
        <PublicForm />
      </Route>

      <Route exact path={`${RoutesLinks.PUBLIC_FORMS_LINK}template/:templateId`}>
        <PublicForm />
      </Route>

      <Route exact path={`${RoutesLinks.PUBLIC_PROFILE_LINK}:startupId`}>
        <PublicProfile />
      </Route>

      <Route exact path={`${RoutesLinks.SHARED_REPORTS_LINK}kmzero`}>
        <KmZeroReport />
      </Route>

      <Route exact path={`${RoutesLinks.SHARED_REPORTS_LINK}kmzero-vc`}>
        <KmZeroReportVC />
      </Route>

      <Route exact path={`${RoutesLinks.SHARED_DEALFLOW_LINK}`}>
        <SharedDealflow />
      </Route>

      {!token && (
        <Route exact path={RoutesLinks.FORGOT_LINK}>
          <Forgot />
        </Route>
      )}

      {!token && (
        <Route exact path={RoutesLinks.FORGOT_RESEND_LINK}>
          <ForgotResend />
        </Route>
      )}

      {!token && (
        <Route exact path={RoutesLinks.FORGOT_CONFIRM_LINK}>
          <ForgotConfirm />
        </Route>
      )}

      {isFounder && (
        <PrivateRoute exact path={RoutesLinks.NOTIFICATIONS_LINK}>
          <Notifications />
        </PrivateRoute>
      )}

      {isInvestor && (
        <PrivateRoute exact path={RoutesLinks.REMINDERS_LINK}>
          <Reminders />
        </PrivateRoute>
      )}

      {isInvestor && (
        <PrivateRoute exact path={`${RoutesLinks.DEALFLOW_LINK}:dealId/edit`}>
          <DeaflowForm />
        </PrivateRoute>
      )}

      <Redirect to={RoutesLinks.DASHBOARD_LINK} />
    </Switch>
  )
}

export default Routes
