import { PageTitle } from '../ui/atoms/PageTitle'
import { useTranslation } from 'react-i18next'
import { Button } from '../ui/atoms/Button'
import { FaPlusCircle } from 'react-icons/all'
import InputFile from '../ui/atoms/input-files'
import FormUtils from '../../utils/form-utils'
import Utils from '../../utils/utils'
import { useToasts } from 'react-toast-notifications'
import { Loader } from '../ui/molecules/Loader'
import React, { useState } from 'react'
import { useSwal } from '../../hooks/useSwal'
import IrmService from '../../services/irm-service'
import Sectors from '../../assets/json/sectors.json'
import InvestmentStages from '../../assets/json/investment-stages.json'
import Countries from '../../assets/json/countries.json'
import InvestorsList from './InvestorsList'

export const IrmAdmin = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { confirm } = useSwal()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [currentPage, setCurrentPage] = useState(0)

  const importData = async () => {
    setLoading(true)
    IrmService.importData(data).then(() => {
      addToast(t('investor_registered_successfully'), {
        appearance: 'success',
        autoDismiss: true
      })
    }).catch(() => addToast(t('error_occurred_registering_investor'), {
      appearance: 'error',
      autoDismiss: true
    })).finally(() => setLoading(false))
  }

  const selectFile = async (file) => {
    if (!file) {
      return
    }

    setLoading(true)

    try {
      const csv = await FormUtils.readFile(file)
      const json = Utils.csvJSON(csv, ',')

      if (!json || !json.length) {
        addToast(t('invalid_csv_format'), {
          appearance: 'error',
          autoDismiss: true
        })
        setLoading(false)
        return
      }

      const data = json.map((item) => {
        const keys = Object.keys(item)
        const values = Object.values(item)
        const obj = {}

        for (let i = 0; i < keys.length; i++) {
          const key = keys[i].trim() || 'id'
          obj[Utils.toSnakeCase(key)] = values[i]
        }

        const sectors = (obj.db_sector || '').split(',').map(s => s.trim())
        const _sectors = Sectors.filter(s => sectors.includes(s.name))

        const investmentStages = (obj.db_inv_stage || '').split(',').map(s => s.trim())
        const _investmentStages = InvestmentStages.filter(s => investmentStages.includes(s.name))

        const countries = (obj.db_country || '').split(',').map(s => s.trim())
        const _countries = Countries.filter(s => countries.includes(s.name))

        return {
          logo: null,
          company_name: obj['organización'],
          manager_name: null,
          manager_email: obj.correo_empresarial,
          web: obj.url_web,
          contact_person: obj.nombre,
          contact_email: obj.email,
          contact_phone_number: obj['teléfono'],
          public: false,
          raw_object: obj,
          investment_thesis: {
            sectors: _sectors.map((sector) => sector._id),
            investment_stages: _investmentStages.map((investmentStage) => investmentStage._id),
            countries: _countries.map((country) => country._id),
            business_models: [],
            project_stages: []
          }
        }
      })

      setLoading(false)
      setData(data)

      confirm({
        text: `¿Seguro que desea importar ${data.length} registros?`
      }).then(isConfirmed => {
        if (isConfirmed) {
          importData()
        }
      })
    } catch (e) {
      addToast(t('error_occurred'), {
        appearance: 'error',
        autoDismiss: true
      })
      setLoading(false)
    }
  }

  return (
    <>
      <PageTitle
        removeMargin={true}
        title={t('investors_irm')}
      />

      <div className='my-4'>
        <InputFile
          setSelectedFile={selectFile}
          accept='.csv'
        />
      </div>

      <InvestorsList
        filters={{ admin: true }}
        filterInvestors={{ admin: true }}
        loading={loading}
        setLoading={setLoading}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />

      {loading && <Loader />}
    </>
  )
}
