import Config from 'config';

const bgStyles = {
  '000': { height: '8rem', backgroundSize: 'contain' },
  '001': { height: '6rem',  backgroundPosition: 'center', backgroundSize: '210px' }
}

export function BannerResponsive() {
  return <div className={`bg-no-repeat w-screen absolute top-0 left-0 lg:hidden bg-banner-${Config.PLATFORM}`} style={bgStyles[Config.PLATFORM]}></div>
}
