export const RoutesLinks = {
  LOGIN_LINK: '/login',
  CHECKS_LINK: '/checks',
  ACTIVATE_LINK: '/activate',
  ON_BOARDING_LINK: '/on-boarding',
  SHARED_LINK: '/shared/',
  PUBLIC_EVENTS_LINK: '/public/events/',
  REGISTER_LINK: '/register/',
  REGISTER_FOUNDER_LINK: '/register/founder',
  REGISTER_PROFESSIONAL_LINK: '/register/professional',
  REGISTER_INVESTOR_LINK: '/register/investor',
  REGISTER_INVITATION: '/register/invitation',
  REGISTER_RESEND_LINK: '/register/resend',
  REGISTER_CONFIRM: '/register/confirm',
  FORGOT_LINK: '/forgot',
  FORGOT_RESEND_LINK: '/forgot/resend',
  FORGOT_CONFIRM_LINK: '/forgot/confirm',
  ACCOUNT_LINK: '/account',
  DEALFLOW_LINK: '/dealflow/',
  COMPANY_LINK: '/company/',
  USERS_LINK: '/users/',
  SECURITY_LINK: '/security/',
  QUESTIONNAIRES_LINK: '/questionnaires',
  QE_FORMS_LINK: '/questionnaires-engine/forms',
  QE_TEMPLATES_LINK: '/questionnaires-engine/templates',
  QE_QUESTIONS_WIZARD_LINK: '/questionnaires-engine/questions/wizard',
  QE_QUESTIONS_LINK: '/questionnaires-engine/questions',
  DASHBOARD_LINK: '/',
  CURATED_DEALFLOW_LINK: '/curated-dealflow',
  GENERAL_INFORMATION: '/general-information',
  DATA_ROOMS_LINK: '/data-rooms',
  INVESTORS_LINK: '/investors',
  PROFESSIONALS_LINK: '/professionals',
  PIPELINE_MANAGER_LINK: '/pipeline-manager/',
  MY_STARTUP_LINK: '/startup-profile/',
  EVALUATIONS_LINK: '/evaluations/',
  FUNNEL_LINK: '/funnel/',
  NOTIFICATIONS_LINK: '/notifications',
  MESSAGING_SYSTEM_LINK: '/messaging-system',
  SUPPORT_TICKETS_LINK: '/support/tickets',
  SUPPORT_NPS_LINK: '/support/nps',
  SUPPORT_REQUESTED_FEATURES_LINK: '/support/requested-features',
  SCORE_CARDS_LINK: '/evaluations/score-cards/',
  EVALUATION_PROCESSES_LINK: '/evaluations/evaluation-processes/',
  DOCUMENTATION_LINK: '/documentation/',
  MILESTONES_LINK: '/milestones/',
  PUBLIC_FORMS_LINK: '/public/forms/',
  PUBLIC_PROFILE_LINK: '/public/profile/',
  SHARED_DEALFLOW_LINK: '/shared/dealflow/',
  SHARED_REPORTS_LINK: '/shared/reports/',
  INVESTORS_RELATIONS_MANAGER_LINK: '/irm/',
  KUFF_LINK: '/kuff',
  KIOTA_BOTS_BOTS_LINK: '/kbots/bots',
  KIOTA_BOTS_DEALFLOW_LINK: '/kbots/dealflow',
  STARTUPS_LINK: '/startups',
  REPORTS_LINK: '/reports/',
  REMINDERS_LINK: '/reminders'

}
