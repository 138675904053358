import React, { useEffect, useRef, useState } from 'react'
import { ReactSortable } from 'react-sortablejs'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import PipelineList from '../lists'
import PipelineDealflowTypeCard from '../cards'
import './pipeline.css'
import { useToasts } from 'react-toast-notifications'
import BoardsService from 'services/boards-service'
import { Loader } from 'components/ui/molecules/Loader'
import { RoutesLinks } from 'components/routes-links'
import ColumnModal from '../column-modal'
import { isEmpty, findIndex } from 'lodash'
import NewCardModal from '../new-card-modal'
import { PipelineEventTypeCard } from 'components/pipeline-manager/components/cards/PipelineEventTypeCard'
import { useSwal } from 'hooks/useSwal'
import { AiOutlineForm, FaChartPie, FaEye, FaRegEdit, FaSearch, FaTags, FaTrash } from 'react-icons/all'
import { ManageTagsModal } from '../../../tags/ManageTagsModal'
import { PageTitle } from '../../../ui/atoms/PageTitle'
import { Button } from '../../../ui/atoms/Button'
import { SectionHeader } from '../../../ui/molecules/SectionHeader'
import { Modal } from 'components/ui/molecules/Modal'
import Config from 'config'
import StageSection from '../StageSection'
import SearchStartupSection from '../SearchStartupSection'
import SupportService from 'services/support-service'
import FormUtils from 'utils/form-utils'
import FormAnswersSection from 'components/qe-forms/components/forms-form/FormAnswersSection'
import useUser from 'hooks/useUser'
import { DealflowService } from 'services/dealflow-service'
import { DealResumeSection } from 'components/dealflow/DealResumeSection'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { Input } from 'components/ui/atoms/Input'
import QuestionnairesService from 'services/questionnaires-service'

const Board = ({editable = true, identifier }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const boardId = identifier || useParams().boardId
  const { confirm } = useSwal()
  const history = useHistory()
  const location = useLocation()
  const [board, setBoard] = useState(null)
  const [startupToSearch, setStartupToSearch] = useState(null)
  const [showSearchStartupSection, setShowSearchStartupSection] = useState(false)
  const [loading, setLoading] = useState(editable)
  const [loadingRetrievingBoard, setLoadingRetrievingBoard] = useState(true)
  const [loadingJudges, setLoadingJudges] = useState(editable)
  const [showNewCardModal, setShowNewCardModal] = useState(false)
  const [showStageSection, setShowStageSection] = useState(false)
  const [columnToEdit, setColumnToEdit] = useState({})
  const [isSortingColumns, setIsSortingColumns] = useState(null)
  const [movedElement, setMovedElement] = useState({})
  const [updatedSort, setUpdatedSort] = useState([])
  const [updatedElement, setUpdatedElement] = useState(null)
  const [adding, setAdding] = useState(false)
  const [removing, setRemoving] = useState(false)
  const [cardToAdd, setCardToAdd] = useState({})
  const [cardToRemove, setCardToRemove] = useState({})
  const [cardToEdit, setCardToEdit] = useState({})
  const [showEditCardModal, setShowEditCardModal] = useState(false)
  const [section, setSection] = useState(location.state?.section ?? 'board')
  const [showUsedStageError, setShowUsedStageError] = useState(false)
  const [stageInEvents, setStageInEvents] = useState([])
  const [evaluationProcesses, setEvaluationProcesses] = useState([])
  const [forms, setForms] = useState([])
  const [judges, setJudges] = useState([])
  const [investorView, setInvestorView] = useState(true)
  const [info, setInfo] = useState(null)
  const [deal, setDeal] = useState(null)
  const [answerToExpand, setAnswerToExpand] = useState(null)
  const { userLanguage } = useUser()

  const getBoard = () => {
    setLoadingRetrievingBoard(true)
    BoardsService.getBoard(boardId, !editable)
      .then((response) => {
        setBoard(response.data)
      })
      .catch((error) => {
        addToast(
          error.response && error.response.data.msg_key
            ? error.response.data.msg_key
            : t('error_occurred_retrieving_board'),
          {
            appearance: 'error',
            autoDismiss: true
          }
        )
      })
      .finally(() => {
        setLoadingRetrievingBoard(false)
      })
  }

  const deleteColumn = (column) => {
    confirm({
      title: t('are_you_sure'),
      text: t('delete_column_text')
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true)
          await BoardsService.deleteColumn(boardId, column._id)
          getBoard()
          addToast(t('column_deleted_successfully'), {
            appearance: 'success',
            autoDismiss: true
          })
        } catch (error) {
          if (error.response.status === 403) {
            setShowUsedStageError(true)
            setStageInEvents(error.response.data.events)
          } else {
            addToast(
              error.response
                ? error.response.data.msg_key
                  ? t(error.response.data.msg_key)
                  : t('error_occurred')
                : t('error_occurred_deleting_stage'),
              {
                appearance: 'error',
                autoDismiss: true
              }
            )
          }
        } finally {
          setLoading(false)
        }
      }
    })
  }

  const deleteCard = (column, card) => {
    confirm({
      text: t('delete_card_text')
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true)
          await BoardsService.deleteCard(boardId, column._id, card._id)
          getBoard()
        } catch (error) {
          console.log(error)
          addToast(
            error.response && error.response.data.msg_keys
              ? error.response.data.msg_key
              : t('error_occurred_deleting_card'),
            {
              appearance: 'error',
              autoDismiss: true
            }
          )
        } finally {
          setLoading(false)
        }
      }
    })
  }

  const editColumn = (column) => {
    setColumnToEdit({ evaluation_process: column.evaluation_process, deleteable: column.deleteable, name: column.name, _id: column._id, advance_email_automation: column.advance_email_automation, end_reached_email_automation: column.end_reached_email_automation, rejection_email_automation: column.rejection_email_automation, temporarily_discarded_email_automation: column.temporarily_discarded_email_automation, judges: column.judges })
    setShowStageSection(true)
  }

  const createColumn = () => {
    setColumnToEdit({})
    setShowStageSection(true)
  }

  const confirmCreateColumn = async (data) => {
    try {
      setLoading(true)
      await BoardsService.createColumn(boardId, data)
      getBoard()
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? error.response.data.msg_key
          : t('error_occurred_creating_column'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    } finally {
      setLoading(false)
      setShowStageSection(false)
      setColumnToEdit({})
    }
  }

  const confirmCreateCard = async (card) => {
    const hasEmailAutomationEnabled = (columnToEdit.advance_email_automation || columnToEdit.end_reached_email_automation || columnToEdit.rejection_email_automation || columnToEdit.temporarily_discarded_email_automation)
    const hasEvaluationProcessAutomationEnabled = !!columnToEdit.evaluation_process

    if (hasEmailAutomationEnabled || hasEvaluationProcessAutomationEnabled) {
      confirm({
        title: t('are_you_sure'),
        text: t(
          hasEvaluationProcessAutomationEnabled && hasEmailAutomationEnabled ? 'add_startup_to_stage_with_judges_assignation_and_email_automations_description'
          : hasEvaluationProcessAutomationEnabled ? 'add_startup_to_stage_with_judges_assignation_automation_description' : 'add_startup_to_stage_with_email_automation_description'
        )
      }).then(async (isConfirmed) => {
        if (isConfirmed) {
          try {
            setLoading(true)
            await BoardsService.createCard(boardId, columnToEdit._id, card)
            getBoard()
          } catch (error) {
            addToast(error?.response?.data?.msg_key ? t(error?.response?.data?.msg_key) : t('error_occurred_creating_card'), {
              appearance: 'error',
              autoDismiss: true
            })
          } finally {
            setLoading(false)
            setShowNewCardModal(false)
            setColumnToEdit({})
          }
        }
      })
    } else {
      try {
        setLoading(true)
        await BoardsService.createCard(boardId, columnToEdit._id, card)
        getBoard()
      } catch (error) {
        addToast(error?.response?.data?.msg_key ? t(error?.response?.data?.msg_key) : t('error_occurred_creating_card'), {
          appearance: 'error',
          autoDismiss: true
        })
      } finally {
        setLoading(false)
        setShowNewCardModal(false)
        setColumnToEdit({})
      }
    }
  }

  const confirmUpdateColumn = async (data) => {
    try {
      setLoading(true)
      await BoardsService.updateColumn(boardId, columnToEdit._id, data)
      getBoard()
      addToast(t('column_updated_successfully'), {
        appearance: 'success',
        autoDismiss: true
      })
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? error.response.data.msg_key
          : t('error_occurred_updating_column'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    } finally {
      setLoading(false)
      setShowStageSection(false)
      setColumnToEdit({})
    }
  }

  const sortCard = async (column, oldIndex, newIndex) => {
    try {
      setLoading(true)
      await BoardsService.sortCard(boardId, column._id, movedElement._id, {
        old_index: oldIndex,
        new_index: newIndex
      })
      getBoard()
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? error.response.data.msg_key
          : t('error_occurred_updating_card'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    } finally {
      setMovedElement({})
      setLoading(false)
    }
  }

  const updateCardTags = (tags) => {
    setLoading(true)
    BoardsService.updateCard(boardId, columnToEdit._id, cardToEdit._id, {
      tags
    })
      .then(() => {
        getBoard()
        setColumnToEdit({})
        setCardToEdit({})
      })
      .catch((error) => {
        addToast(
          error.response && error.response.data.msg_key
            ? error.response.data.msg_key
            : t('error_occurred_updating_card'),
          {
            appearance: 'error',
            autoDismiss: true
          }
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    const orderColumns = async (newIndex) => {
      try {
        setLoading(true)
        await BoardsService.updateColumn(boardId, updatedElement._id, {
          order: newIndex
        })
        getBoard()
      } catch (error) {
        addToast(
          error.response && error.response.data.msg_key
            ? error.response.data.msg_key
            : t('error_occurred_updating_column'),
          {
            appearance: 'error',
            autoDismiss: true
          }
        )
      } finally {
        setLoading(false)
      }
    }

    if (isSortingColumns === false) {
      setIsSortingColumns(null)
      const newIndex = findIndex(
        updatedSort,
        (column) => column._id === updatedElement._id
      )
      const oldIndex = findIndex(
        board.columns,
        (column) => column._id === updatedElement._id
      )
      if (newIndex !== oldIndex) {
        orderColumns(newIndex)
      }
      setUpdatedElement(null)
    } else if (isSortingColumns) {
      setUpdatedElement(
        updatedSort[findIndex(updatedSort, (column) => column.chosen === true)]
      )
    }
  }, [isSortingColumns])

  useEffect(() => {
    const moveCard = async () => {
      const newColumn = board.columns.find((c) => c._id === cardToAdd.column)
      const hasEmailAutomationEnabled = (newColumn.advance_email_automation || newColumn.end_reached_email_automation || newColumn.rejection_email_automation || newColumn.temporarily_discarded_email_automation)
      const hasEvaluationProcessAutomationEnabled = !!newColumn.evaluation_process

      if (hasEmailAutomationEnabled || hasEvaluationProcessAutomationEnabled) {
        confirm({
          title: t('are_you_sure'),
          text: t(
            hasEvaluationProcessAutomationEnabled && hasEmailAutomationEnabled ? 'move_startup_to_stage_with_judges_assignation_and_email_automations_description'
            : hasEvaluationProcessAutomationEnabled ? 'move_startup_to_stage_with_judges_assignation_automation_description' : 'move_startup_to_stage_with_email_automation_description'
          )
        }).then(async (isConfirmed) => {
          if (isConfirmed) {
            try {
              setLoading(true)
              await BoardsService.moveCard(boardId, movedElement._id, {
                add: cardToAdd,
                remove: cardToRemove
              })
              getBoard()
            } catch (error) {
              addToast(
                error.response && error.response.data.msg_key
                  ? error.response.data.msg_key
                  : t('error_occurred_updating_card'),
                {
                  appearance: 'error',
                  autoDismiss: true
                }
              )
            } finally {
              setCardToRemove({})
              setCardToAdd({})
              setMovedElement({})
              setLoading(false)
              setAdding(false)
              setRemoving(false)
            }
          }
        })
      } else {
        try {
          setLoading(true)
          await BoardsService.moveCard(boardId, movedElement._id, {
            add: cardToAdd,
            remove: cardToRemove
          })
          getBoard()
        } catch (error) {
          addToast(
            error.response && error.response.data.msg_key
              ? error.response.data.msg_key
              : t('error_occurred_updating_card'),
            {
              appearance: 'error',
              autoDismiss: true
            }
          )
        } finally {
          setCardToRemove({})
          setCardToAdd({})
          setMovedElement({})
          setLoading(false)
          setAdding(false)
          setRemoving(false)
        }
      }
    }

    if (adding && removing && !isEmpty(cardToAdd) && !isEmpty(cardToRemove)) {
      moveCard()
    }
  }, [adding, removing, cardToAdd, cardToRemove])

  const getEvaluationProcesses = (archived = false) => {
    setLoading(true)
    SupportService.getEvents({
      page: 0,
      rowsPerPage: 9999,
      sortBy: "-createdAt",
      archived: false
    }).then(result => {
      setEvaluationProcesses(result.data.data.events.map((event) => { return {id: event._id, value: event.name}}))
    }).catch(error => {
      addToast(t('error_occurred_retrieving_evaluation_processes'), {
        appearance: 'error',
        autoDismiss: true
      })
      console.log(error)
    }).finally(() => {
      setLoading(false)
    })
  }

  const getForms = async () => {
    try {
      setLoading(true)
      const result = await QuestionnairesService.getQuestionnairesForms({
        rowsPerPage: 9999,
        page: 0
      })
      const { forms } = result.data.data
      setForms(forms.map((form) => { return { id: form._id, value: form.name } }))
    } catch (error) {
      addToast(t('error_retrieving_forms'), {
        appearance: 'error',
        autoDismiss: true
      })
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const getJudges = () => {
    setLoadingJudges(true)
    SupportService.getAllJudges().then(result => {
      setJudges(result.data.data)
    }).catch(error => {
      addToast(t('error_occurred_retrieving_judges'), {
        appearance: 'error',
        autoDismiss: true
      })
      console.log(error)
    }).finally(() => {
      setLoadingJudges(false)
    })
  }

  useEffect(() => {
    getBoard()
    if (editable) {
      getEvaluationProcesses()
      getJudges()
      getForms()
    }
  }, [])

  const downloadDocument = async (documentId) => {
    try {
      setLoading(true)
      let result = null
      result = await SupportService.getDocumentForForm(documentId, !editable)
      if (result?.data?.url) {
        window.open(result.data.url, '_blank')
      }
    } catch (error) {
      addToast(t(error?.response?.data?.msg_key || 'error_occurred_downloading_document'), {
        appearance: 'error',
        autoDismiss: true
      })
    } finally {
      setLoading(false)
    }
  }

  const getForm = async (formId, dealId) => {
    try {
      setLoading(true)
      const result = await SupportService.getEventStartup(formId, dealId, !editable)
      setInfo(result.data.data)
    } catch (error) {
      addToast(t('error_occurred_retrieving_information'), {
        appearance: 'error',
        autoDismiss: true
      })
    } finally {
      setLoading(false)
    }
  }


  const getDeal = async (dealId) => {
    try {
      setLoading(true)
      const result = await DealflowService.getDeal(dealId)
      if (result.data) {
        setDeal(result.data)
      } else {
        addToast(t('error_occurred_retrieving_dealflow'), { appearance: 'error', autoDismiss: true })
      }
    } catch (error) {
      addToast(t('error_occurred_retrieving_dealflow'), { appearance: 'error', autoDismiss: true })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (info?.application) {
      const parsedQuestions = FormUtils.getParsedQuestions(info.application.questions.map((q) => { return { question_id: q.question }}))
      const answers = FormUtils.getParsedAnswers(parsedQuestions, [info], t, userLanguage, downloadDocument)

      setAnswerToExpand({ answers: answers[0], questions: parsedQuestions })
    }
  }, [info])

  return (
    <>

      {deal && (
        <DealResumeSection
          deal={deal || {}}
          onClose={() => {
            setDeal(null)
          }}
          investorView={investorView}
          setInvestorView={setInvestorView}
        />
      )}
      {answerToExpand && (
        <FormAnswersSection
          answer={answerToExpand}
          onClose={() => {
            setInfo(null)
            setAnswerToExpand(null)
          }}
        />
      )}
    {showUsedStageError && (
        <Modal
          showCloseModal={true}
          showModal={showUsedStageError}
          paddingBottom="2"
          paddingTop="6"
          onClose={() => {
            setShowUsedStageError(false)
            setStageInEvents([])
          }}
        >
          <h2 className="font-semibold text-xl mt-4">
            {t('error_occurred_deleting_stage')}
          </h2>
          <p className="font-medium text-sm text-gray-dark">
            {t('stage_is_being_used_in_some_automations')}
          </p>
          <p className="font-medium text-sm text-gray-dark mt-6 mb-4">
            {t('delete_stage_automation_previously')}
          </p>
          <div className="text-sm  text-justify font-normal w-full px-10 mb-6">
            <ul className='list-disc'>
              {stageInEvents.map((q, index) => {
                return <li key={index} className="mb-1">{q}</li>
              })}
            </ul>
          </div>
        </Modal>
      )}
      {editable && <div className="flex items-start">
        <div className="flex-1">
          <PageTitle
            title={!board ? t('board') : `${t('board')}: ${board.name}`}
            showBack={true}
            removeMargin={true}
            onBackClick={() => history.push({
              pathname: `${RoutesLinks.PIPELINE_MANAGER_LINK}boards`
            })}
          />
        </div>
        <div className="flex items-start">
          <ButtonSecondary
            onClick={() => setShowSearchStartupSection(true)}
            iconComponent={<FaSearch className="inline-block" />}
            marginLeft={2}
          />
        </div>
      </div>}
      {!editable && (
        <div className="flex justify-end">
        <ButtonSecondary
          onClick={() => setShowSearchStartupSection(true)}
          iconComponent={<FaSearch className="inline-block" />}
          marginLeft={2}
        />
      </div>
      )}

      {/*<SectionHeader
        section={section}
        setSection={setSection}
        sectionKeys={['board', 'eda']}
        sectionTitles={[t('board'), t('eda')]}
      />*/}

      {(section === 'board' && board) && (
        <div className='h-full w-full mt-6'>
          <div className="h-full w-full overflow-x-auto overflow-y-auto pb-6">
            <div className="flex items-start">
              {editable && <ReactSortable
                onStart={() => setIsSortingColumns(true)}
                onEnd={() => setIsSortingColumns(false)}
                list={board.columns}
                setList={setUpdatedSort}
                group="root"
                animation={150}
                className="flex items-start"
                chosenClass="sortable-choosen-column"
              >
                {board.columns.map((column) => (
                  <PipelineList
                    entityToManage={board.entity_to_manage}
                    key={column._id}
                    addCard={() => {
                      setShowNewCardModal(true)
                      setColumnToEdit(column)
                    }}
                    column={{
                      ...column,
                      id: column._id
                    }}
                    editable={editable}
                    ListTitle={column.name}
                    deleteColumn={column.deleteable ? deleteColumn : null}
                    editColumn={editColumn}
                    items={column.cards.length}
                  >
                    <ReactSortable
                      data-id={column._id}
                      setList={setUpdatedSort}
                      list={column.cards}
                      group="grupo1"
                      animation={200}
                      easing="cubic-bezier(0.25, 1, 0.5, 1)"
                      chosenClass="sortable-choosen"
                      onStart={(evt) => {
                        setMovedElement(column.cards[evt.oldIndex])
                      }}
                      onSort={(evt) => {
                        if (!adding && !removing) {
                          sortCard(column, evt.oldIndex, evt.newIndex)
                        }
                      }}
                      onRemove={(evt) => {
                        setRemoving(true)
                        setCardToRemove({
                          column: column._id
                        })
                      }}
                      onAdd={(evt) => {
                        setAdding(true)
                        setCardToAdd({
                          column: column._id,
                          index: evt.newIndex
                        })
                      }}
                    >
                      {column.cards.map((card, index) => (
                        <>
                          <PipelineDealflowTypeCard
                            startupToSearch={startupToSearch}
                            editable={editable}
                            key={`${card._id}-${index}`}
                            dealflow={card.dealflow}
                            deleteCard={() => deleteCard(column, card)}
                            getForm={getForm}
                            getDeal={getDeal}
                          />
                        </>
                      ))}
                    </ReactSortable>
                  </PipelineList>
                ))}
              </ReactSortable>}
              {!editable && (
                board.columns.map((column) => (
                  <PipelineList
                    entityToManage={board.entity_to_manage}
                    key={column._id}
                    addCard={() => {
                      setShowNewCardModal(true)
                      setColumnToEdit(column)
                    }}
                    column={{
                      ...column,
                      id: column._id
                    }}
                    editable={editable}
                    ListTitle={column.name}
                    deleteColumn={column.deleteable ? deleteColumn : null}
                    editColumn={editColumn}
                    items={column.cards.length}
                  >
                    {column.cards.map((card, index) => (
                      <>
                        <PipelineDealflowTypeCard
                          startupToSearch={startupToSearch}
                          getForm={getForm}
                          editable={editable}
                          key={`${card._id}-${index}`}
                          dealflow={card.dealflow}
                          tags={card.tags}
                          actions={card.event ? [
                            {
                              label: t('see_profile'),
                              icon: <FaEye className="inline-block" />,
                              onClick: () => {
                                history.push({
                                  pathname: `${RoutesLinks.DEALFLOW_LINK}${card.dealflow._id}`,
                                  state: { from: location.pathname }
                                })
                              },
                              className: `text-text-buttons-main-${Config.PLATFORM} bg-bg-buttons-main-${Config.PLATFORM} border border-main-${Config.PLATFORM}`
                            },
                            {
                              label: t('see_request'),
                              icon: <AiOutlineForm className="inline-block" />,
                              onClick: () => {
                                history.push({
                                  pathname: `${RoutesLinks.EVENTS_LINK}${card.event._id}/startups/${card.dealflow._id}/application`,
                                  state: { from: location.pathname }
                                })
                              },
                              className: `text-text-buttons-secondary-${Config.PLATFORM} border border-border-buttons-secondary-${Config.PLATFORM} bg-white`
                            },
                            {
                              label: t('delete'),
                              icon: <FaTrash className="inline-block text-red" />,
                              className: 'border border-red bg-white',
                              onClick: () => deleteCard(column, card)
                            }
                          ] : [
                            {
                              label: t('see_profile'),
                              icon: <FaEye className="inline-block" />,
                              onClick: () => {
                                history.push({
                                  pathname: `${RoutesLinks.DEALFLOW_LINK}${card.dealflow._id}`,
                                  state: { from: location.pathname }
                                })
                              },
                              className: `text-text-buttons-main-${Config.PLATFORM} bg-bg-buttons-main-${Config.PLATFORM} border border-main-${Config.PLATFORM}`
                            },
                            {
                              label: t('delete'),
                              icon: <FaTrash className="inline-block text-red" />,
                              className: 'border border-red bg-white',
                              onClick: () => deleteCard(column, card)
                            }
                          ] }
                        />
                      </>
                    ))}
                  </PipelineList>
                ))
              )}
              {editable && <PipelineList newColumn={true} createColumn={createColumn} />}
            </div>
          </div>
        </div>
      )}

      {showEditCardModal && <ManageTagsModal
        category='pipeline_manager'
        currentTags={cardToEdit.tags}
        showModal={showEditCardModal}
        handleCloseModal={() => setShowEditCardModal(false)}
        onSubmit={(selectedTags) => {
          setShowEditCardModal(false)
          updateCardTags(selectedTags)
        }}
      />}

      {showNewCardModal && (
        <NewCardModal
          showModal={showNewCardModal}
          onSubmit={confirmCreateCard}
          handleCloseModal={() => {
            setShowNewCardModal(false)
            setColumnToEdit({})
          }}
        />
      )}

      {showSearchStartupSection && (
        <SearchStartupSection
          onClose={() => setShowSearchStartupSection(false)}
          onSubmit={(value) => {
            setStartupToSearch(value)
            setShowSearchStartupSection(false)
          }}
          initialValues={startupToSearch}
        />
      )}
      {showStageSection && (
        <StageSection
          judges={judges.map((j) => { return { id: j._id, value: j.judge, event: j.event }})}
          evaluationProcesses={evaluationProcesses}
          forms={forms}
          onClose={() => {
            setShowStageSection(false)
            setColumnToEdit({})
          }}
          onSubmit={isEmpty(columnToEdit) ? confirmCreateColumn : confirmUpdateColumn}
          initialValues={columnToEdit}
        />
      )}

      {(loading || loadingJudges || loadingRetrievingBoard) && <Loader />}
    </>
  )
}

export default Board
