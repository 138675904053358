import { useTranslation } from 'react-i18next'
import { Input } from 'components/ui/atoms/Input'

export const QuestionTypeInput = ({
  currentQuestion,
  onChange,
  validateQuestion,
  value
}) => {
  const { t } = useTranslation()

  return (
    <Input
      id={currentQuestion.question_id._id}
      key={currentQuestion.question_id._id}
      value={value || ''}
      placeholder={t('type_answer')}
      onChange={e => onChange(e.target.value)}
      type={currentQuestion.question_id.type}
      // onKeyPress={e => e.key === 'Enter' && validateQuestion()}
    />
  )
}
