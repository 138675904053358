import React, { useState, useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'

import Config from 'config';
import { BannerResponsive } from 'components/ui/atoms/BannerResponsive'
import { Checkbox } from 'components/ui/molecules/Checkbox'
import { BottomText } from 'components/ui/atoms/BottomText'
import { FormTitle } from 'components/ui/atoms/FormTitle'
import ShowPassword from 'assets/images/ShowPassword.png'
import { RoutesLinks } from 'components/routes-links'
import { Button } from 'components/ui/atoms/Button'
import { Input } from 'components/ui/atoms/Input'
import moment from "moment";
import { PoweredText } from 'components/ui/atoms/PoweredText'
import Parameters from 'config/parameters.json';
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

const Form = ({
  mode,
  onSubmit,
  responseSuccessGoogle,
  responseFailureGoogle,
  responseSuccessLinkedin,
  responseFailureLinkedin,
  invitationData,
  loading
}) => {
  const { t } = useTranslation()

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)

  const handleShowhide = () => {
    setShowPassword(!showPassword)
  }

  const handleShowhideConfirm = () => {
    setShowConfirm(!showConfirm)
  }

  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('incorrect_format'))
      .required(t('required_field')),
    name: Yup.string().required(t('required_field')),
    last_name: Yup.string().required(t('required_field')),
    company_name: Yup.string().required(t('required_field')),
    password_register: Yup.string()
      .required(t('required_field'))
      .min(8, t('password_length'))
      .matches(/^.*(?=.*?[A-Z]).*$/, t('password_req_uppercase'))
      .matches(/^.*(?=.*?[a-z]).*$/, t('password_req_lowercase'))
      .matches(/^.*(?=.*?[0-9]).*$/, t('password_req_number'))
      .matches(
        /^.*(?=.*?[\^ $ * . [ \] { } ( ) ? " ! @ # % & , > < ' : ; _ ~ ` \\ / |]).*$/,
        t('password_req_special_char')
      ),

    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password_register'), null], t('passwords_do_not_match'))
      .required(t('required_field')),

    accept_terms: Yup.boolean().oneOf([true], t('accept_terms_required'))
  })

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    trigger,
    getValues
  } = useForm({
    resolver: yupResolver(RegisterSchema, { abortEarly: false }),
    criteriaMode: 'all',
    reValidateMode: 'all',
    mode: 'onChange'
  })

  useEffect(() => {
    if (Object.keys(invitationData).length) {
      setValue('email', invitationData.email, { shouldDirty: true })
      setValue('name', invitationData.name)
      setValue('last_name', invitationData.last_name)
      setValue('company_name', invitationData.company_name)
    }
  }, [invitationData])

  return (
    <section className="flex flex-col justify-center items-center | min-h-screen w-full  | sm:pt-0 lg:p-16">
      <div className="max-w-xs sm:max-w-md 2xl:max-w-lg mx-2 mt-16 sm:mt-32 md:mt-0 lg:pt-4">
        <BannerResponsive />
        <FormTitle
          formTitle={t('started')}
          formSubtitle={t('started_subtitle')}
        />
        {/* <div className="mt-8 w-full">
            <GoogleButton
              icon={GoogleIcon}
              extension="border"
              borderColor="blue-dark"
              textColorHover="blue-dark"
              text="Sign Up with Google"
              clientId="690938764456-ac8b32rplj2tfk4f43grc7iuh36udi8i.apps.googleusercontent.com"
              onSuccess={responseSuccessGoogle}
              onFailure={responseFailureGoogle}
              cookiePolicy={"single_host_origin"}
            />
            <LinkedinButton
              icon={LinkedinIcon}
              text="Sign Up with LinkedIn"
              extension="border"
              borderColor="blue-dark"
              textColorHover="blue-dark"
              clientId="78cvhkml88ak81"
              onFailure={responseFailureLinkedin}
              onSuccess={responseSuccessLinkedin}
              redirectUri={Config.LINKEDIN_REDIRECT_URL}
            />

            <HrText text="Or Sign up with Email" />
          </div> */}
        <form className="w-full">
          <Input
            reference={register}
            id="email"
            name="email"
            type="email"
            label={t('email') + ': *'}
            placeholder={t('write_email')}
            error={errors.email}
            disabled={mode === 'invitation'}
          />
          {mode === 'investor' && (
            <Input
              reference={register}
              id="company_name"
              name="company_name"
              type="text"
              label={t('company_name') + ': *'}
              placeholder={t('write_company_name')}
              error={errors.company_name}
              disabled={mode === 'invitation'}
            />
          )}
          <div className="flex w-full">
            <div className="w-3/6 mr-4">
              <Input
                reference={register}
                id="name"
                name="name"
                type="text"
                label={t('first_name') + ': *'}
                placeholder={t('first_name')}
                error={errors.name}
                disabled={mode === 'invitation'}
              />
            </div>

            <div className="w-3/6">
              <Input
                reference={register}
                id="last_name"
                name="last_name"
                type="text"
                label={t('last_name') + ': *'}
                placeholder={t('last_name')}
                error={errors.last_name}
                disabled={mode === 'invitation'}
              />
            </div>
          </div>
          <div>
            <Input
              reference={register}
              id="passwordRegister"
              name="password_register"
              type={showPassword ? 'text' : 'password'}
              label={t('password') + ': *'}
              placeholder={t('password_req_min_char')}
              error={errors}
              icon={ShowPassword}
              show={handleShowhide}
              isRegister="isRegister"
              disabled={loading}
            />
          </div>
          <Input
            disabled={loading}
            reference={register}
            id="confirm_password"
            name="password_confirmation"
            type={showConfirm ? 'text' : 'password'}
            label={t('confirm_password') + ': *'}
            placeholder={t('password_req_min_char')}
            error={errors.password_confirmation}
            icon={ShowPassword}
            show={handleShowhideConfirm}
          />

          {
            /*
              {mode === 'invitation' && (
                <Input
                  id="invited_by"
                  name="invited_by"
                  type="text"
                  label={t('invited_by')}
                  disabled={true}
                  value={invitationData.invited_by}
                />
              )}
            */
          }
          <Checkbox
            id="accept_terms"
            label={<a href={Parameters.terms_and_conditions[Config.PLATFORM]} target="_blank" className={`underline inline text-link-${Config.PLATFORM} hover:text-link-hover-${Config.PLATFORM}`}>{t('privacy_policy')}</a>}
            name="accept_terms"
            reference={register}
            error={errors.accept_terms}
            disabled={loading}
          />

          <div className='my-4'>
            <ButtonMain
              width={'full'}
              type="button"
              onClick={async () => {
                if (await trigger()) onSubmit(getValues())
              }}
              text={t('sign_up')}
              disabled={loading}
            />
          </div>

          <span className="text-gray font-regular text-xs">
            {t('already_have_an_account')}
            <Link to={`${RoutesLinks.LOGIN_LINK}`} className={`underline inline text-main-${Config.PLATFORM} hover:text-link-hover-${Config.PLATFORM}`}>
              {t('go_to_login')}
            </Link>
          </span>
        </form>
      </div>

      <BottomText text={`${t('copyright')} @${moment().format('YYYY')} ${t('copyright_rights')}`} />
      {Config.PLATFORM !== '000' && <PoweredText />}

    </section>
  )
}

export default Form
