import { useTranslation } from 'react-i18next'
import { Button } from 'components/ui/atoms/Button'
import { SidePanel } from 'components/ui/objects/SidePanel'
import { useState } from 'react'
import { Input } from 'components/ui/atoms/Input'
import Select from 'components/ui/atoms/Select'
import { useToasts } from 'react-toast-notifications'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Loader } from 'components/ui/molecules/Loader'
import { FaTrash } from 'react-icons/fa'
import Avatar from 'react-avatar'
import InputFile from 'components/ui/atoms/input-files'
import FormUtils from 'utils/form-utils'

const InvestorSection = ({ onClose, onSubmit }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)

  const PLANS = [
    { id: 'kiota_crm', value: 'Kiota CRM' }
  ]

  const ACCOUNT_TYPES = [
    { id: 'accelerator', value: t('accelerator') },
    { id: 'accelerator_incubator', value: t('accelerator_incubator') },
    { id: 'ba_network', value: t('ba_network') },
    { id: 'vc', value: t('vc') }
  ]

  const [accountType, setAccountType] = useState('')
  const [planId, setPlanId] = useState('')
  const [logo, setLogo] = useState(null)

  const InvestorSchema = Yup.object().shape({
    company_name: Yup.string().required(t('required_field')),
    manager_name: Yup.string().required(t('required_field')),
    manager_last_name: Yup.string().required(t('required_field')),
    manager_email: Yup.string()
      .email(t('incorrect_format'))
      .required(t('required_field')),
    contact_person: Yup.string().required(t('required_field')),
    contact_phone_number: Yup.string(),
    contact_email: Yup.string()
      .email(t('incorrect_format'))
      .required(t('required_field'))
  })

  const { register, errors, trigger, getValues } = useForm({
    resolver: yupResolver(InvestorSchema),
    criteriaMode: 'all',
    reValidateMode: 'all',
    mode: 'onChange'
  })

  const onSelectLogo = async file => {
    const fileSizeMb = (file?.size || 0) / 1024 / 1024

    if (fileSizeMb > 5) {
      addToast(t('error_file_size', { max_size: 5 }), {
        appearance: 'error',
        autoDismiss: true
      })
      return
    }

    setLoading(true)
    setLogo(file ? await FormUtils.fileToBase64(file) : null)

    setTimeout(() => setLoading(false), 500)
  }
  return (
    <>
      <SidePanel
        onClose={onClose}
        title={t('create_account')}
        width={'1/4'}
      >

      <div>
        {logo ? (
          <>
            <div className="text-xs font-medium text-black">
              {t('company_logo')}
            </div>
            <div className="mt-2 flex flex-col justify-center w-32 mb-6">
              <Avatar
                src={logo}
                size="80"
                round={true}
                color="#e0e6f2"
                fgColor="#4d70b3"
                className="mx-auto my-0"
              />
              <Button
                type="button"
                iconComponent={<FaTrash className="inline-block mr-2" />}
                text={t('remove')}
                width='auto'
                bgColor='red'
                className="text-white max-h-[24px]"
                verticalMargin="1"
                vertical="1"
                textSize="xs"
                onClick={() => onSelectLogo(null)}
              />
            </div>
          </>
        ) : (
          <div className="w-full mb-4">
            <InputFile
              label={t('admin_company_logo_label')}
              placeholder={t('company_logo_placeholder')}
              error={errors.logo}
              setSelectedFile={(file) => onSelectLogo(file)}
              accept="image/*"
              height="120px"
              padding="18px"
            />
          </div>
        )}
      </div>
      <form className="w-full">
          <div>
            <Input
              reference={register}
              id="company_name"
              type="text"
              name="company_name"
              label={`${t('admin_company_name_label')}*:`}
              placeholder={t('admin_company_name_placeholder')}
              error={errors.company_name}
            />
          </div>
          <div>
            <Input
              reference={register}
              id="manager_name"
              type="text"
              name="manager_name"
              label={`${t('admin_manager_name_label')}*:`}
              placeholder={t('admin_manager_name_placeholder')}
              error={errors.manager_name}
            />
          </div>
          <div>
            <Input
              reference={register}
              id="manager_last_name"
              type="text"
              name="manager_last_name"
              label={`${t('admin_manager_last_name_label')}*:`}
              placeholder={t('admin_manager_last_name_placeholder')}
              error={errors.manager_last_name}
            />
          </div>
          <div>
            <Input
              reference={register}
              id="manager_email"
              type="email"
              name="manager_email"
              label={`${t('admin_manager_email_label')}*:`}
              placeholder={t('admin_manager_email_placeholder')}
              error={errors.manager_email}
            />
          </div>
          <div>
            <Input
              reference={register}
              id="contact_person"
              type="text"
              name="contact_person"
              label={`${t('contact_person_label')}*:`}
              placeholder={t('contact_person_placeholder')}
              error={errors.contact_person}
            />
          </div>
          <div>
            <Input
              reference={register}
              id="contact_email"
              type="email"
              name="contact_email"
              label={`${t('contact_email_label')}*:`}
              placeholder={t('contact_email_placeholder')}
              error={errors.contact_email}
            />
          </div>
          <div>
            <Input
              reference={register}
              id="contact_phone_number"
              type="text"
              name="contact_phone_number"
              label={`${t('contact_phone_number_label')}:`}
              placeholder={t('phone_number_placeholder')}
              error={errors.contact_phone_number}
            />
          </div>
          <label
            htmlFor="select-account-type"
            className="block mb-1 | text-left text-xs font-medium text-black"
          >
            {`${t('investor_account_type_label')}*:`}
          </label>
          <Select
            id="accountType"
            items={ACCOUNT_TYPES}
            initialValues={[]}
            onSelect={(selections) => {
              setAccountType(selections.length ? selections[0].id : null)
            }}
            placeholder={t('investor_account_type_placeholder')}
            name="accountType"
            error={accountType === null ? { message: t('required_field') } : null}
          />

          <label
            htmlFor="select-investor-type"
            className="block mb-1 | text-left text-xs font-medium text-black"
          >
            {`${t('plan_id_label')}*:`}
          </label>
          <Select
            id="plan"
            items={PLANS}
            initialValues={[]}
            onSelect={(selections) => {
              setPlanId(selections.length ? selections[0].id : null)
            }}
            placeholder={t('plan_id_placeholder')}
            name="plan"
            error={planId === null ? { message: t('required_field') } : null}
          />

          <div className="flex justify-end">
            <Button
              type="button"
              onClick={async () => {
                const validation = await trigger()
                if (accountType === '') {
                  setAccountType(null)
                }
                if (planId === '') {
                  setPlanId(null)
                }
                if (validation && accountType && planId) onSubmit({ ...getValues(), account_type: accountType, plan_id: planId, logo })
              }}
              bgColor="blue-dark"
              width="auto"
              textColor="white"
              bgHoverColor="blue-dark"
              text={t('save')}
            />
          </div>
        </form>
      </SidePanel>
      {loading && <Loader />}
    </>
  )
}

export default InvestorSection
