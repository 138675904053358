import { useState } from 'react'

export const usePagination = (
  rowsPerPage = 10,
  currentPage = 0,
  totalPages = 1
) => {
  const [page, setPage] = useState(currentPage)
  const [rowsPerPageState, setRowsPerPageState] = useState(rowsPerPage)
  const [totalPagesState, setTotalPagesState] = useState(totalPages)

  return {
    page,
    rowsPerPage: rowsPerPageState,
    totalPages: totalPagesState,
    setTotalPages: setTotalPagesState,
    setPage: (_page) => {
      if (!_page) {
        setPage(0)
      }

      if (_page === page) {
        return
      }

      if (_page > totalPagesState) {
        setPage(totalPagesState)
      } else {
        setPage(_page)
      }
    },
    setRowsPerPage: (rowsPerPage) => {
      if (!rowsPerPage) {
        return
      }

      setRowsPerPageState(rowsPerPage)
      setPage(0)
    }
  }
}
