import { ButtonDanger } from 'components/ui/atoms/ButtonDanger'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { Card } from 'components/ui/atoms/Card'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import { Loader } from 'components/ui/molecules/Loader'
import { useSwal } from 'hooks/useSwal'
import useUser from 'hooks/useUser'
import { forwardRef, useImperativeHandle, useState } from 'react'
import Avatar from 'react-avatar'
import { useTranslation } from 'react-i18next'
import { FaLink, FaPen, FaTrash } from 'react-icons/fa'
import { useToasts } from 'react-toast-notifications'
import AccountService from 'services/account-service'
import EducationSection from './EducationSection'
import Config from 'config'
import { ButtonCardSecondary } from 'components/ui/atoms/ButtonCardSecondary'
import moment from 'moment'

export const Education = forwardRef(({}, ref) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const { addToast } = useToasts()
    const { user, reloadUserInfo } = useUser()
    const { confirm } = useSwal()
    const [educationToEdit, setEducationToEdit] = useState(null)

    const [showEducationSectionBoolean, setShowEducationSectionBoolean] = useState(false)

    useImperativeHandle(ref, () => ({
        showEducationSection: () => setShowEducationSectionBoolean(true)
      }))

    const addEducation = (values) => {
        confirm().then(isConfirmed => {
            if (isConfirmed) {
                setLoading(true)
                AccountService.addEducation(values)
                .then(() => reloadUserInfo())
                .then(() => {
                    addToast(t('education_registered_successfully'), {
                    appearance: 'success',
                    autoDismiss: true
                    })
                })
                .catch(error => {
                    addToast(
                    error.response && error.response.data.msg
                        ? error.response.data.msg
                        : t('error_occurred_registering_education'),
                    {
                        appearance: 'error',
                        autoDismiss: true
                    }
                    )
                }).finally(() => {
                    setLoading(false)
                    setShowEducationSectionBoolean(false)
                })
            }
        })
    }

    const updateEducation = (values) => {
        confirm().then(isConfirmed => {
            if (isConfirmed) {
                setLoading(true)
                AccountService.updateEducation(educationToEdit._id, values)
                .then(() => reloadUserInfo())
                .then(() => {
                    addToast(t('education_updated_successfully'), {
                    appearance: 'success',
                    autoDismiss: true
                    })
                })
                .catch(error => {
                    addToast(
                    error.response && error.response.data.msg
                        ? error.response.data.msg
                        : t('error_occurred_updating_education'),
                    {
                        appearance: 'error',
                        autoDismiss: true
                    }
                    )
                }).finally(() => {
                    setLoading(false)
                    setShowEducationSectionBoolean(false)
                    setEducationToEdit(null)
                })
            }
        })
    }

    const deleteEducation = (educationId) => {
        confirm().then(async (isConfirmed) => {
          if (isConfirmed) {
            try {
              setLoading(true)
              await AccountService.deleteEducation(educationId)
              reloadUserInfo()
              addToast(t('education_deleted_successfully'), {
                appearance: 'success',
                autoDismiss: true
              })
            } catch (error) {
                addToast(
                  error.response
                    ? error.response.data.msg_key
                      ? t(error.response.data.msg_key)
                      : t('error_occurred_deleting_education')
                    : t('error_occurred_deleting_education'),
                  {
                    appearance: 'error',
                    autoDismiss: true
                  }
                )
            } finally {
              setLoading(false)
            }
          }
        })
      }
    
  return (
    <div className='my-6'>
        {showEducationSectionBoolean && (
            <EducationSection
                onClose={() => {
                    setEducationToEdit(null)
                    setShowEducationSectionBoolean(false)
                }}
                addEducation={addEducation}
                initialValues={educationToEdit}
                updateEducation={updateEducation}
            />
        )}
        {(!user.education || user.education.length === 0) && (
            <NoDataInfo title={t('no_education_registered')} />
        )}
        {user.education && user.education.length !== 0 && (
            <div className='grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4'>
                {user.education.map((education) => {
                    return (
                        <Card
                            key={education._id}
                            wrapperClassName="transition-transform"
                            containerClassName="w-full flex flex-col h-full"
                        >
                            <div className='flex flex-col'>
                                <div className="flex pb-2 mb-2 ">
                                    <div className='flex flex-row w-full'>
                                        <div className="flex flex-col text-xs min-w-0 w-3/5">
                                            <h3 className={`uppercase font-bold leading-none text-main-${Config.PLATFORM} truncate mb-1`}>
                                            {education.title}
                                            </h3>
                                            <div>
                                                <div className='text-sm'>
                                                <b>{education.entity}</b>
                                                {education.entity_url && <FaLink className='inline ml-2 h-4 cursor-pointer' onClick={() => window.open(education.entity_url, '_blank')}/>}
                                                </div>
                                                <div className='my-2'>
                                                    {`${moment(education.start_date).format('YYYY-MM-DD')} - ${education.end_date ? moment(education.end_date).format('YYYY-MM-DD') : t('currently')}`}
                                                </div>
                                                <div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="flex items-center justify-center w-2/5">
                                        <div className="w-full h-[80px] ">
                                            <Avatar
                                            src={education.entity_logo}
                                            name={education.entity_logo}
                                            alt={education.entity_logo}
                                            maxInitials={3}
                                            className={`w-full h-[80px] object-contain`}
                                            color="#e0e6f2"
                                            fgColor="#4d70b3"
                                            round="5"
                                            />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {education.description && <div className='border-t border-gray-lines py-2 text-xs'>
                                    {education.description}
                                </div>}
                            </div>
                            <div className={`flex justify-end border-t border-separator-${Config.PLATFORM} pt-2 mt-4`}>
                                <ButtonDanger
                                    onClick={() => deleteEducation(education._id)}
                                    text={t('delete')}
                                    marginRight={2}
                                    iconComponent={<FaTrash className="inline-block mr-2 w-5 h-4" />}
                                />
                                <ButtonCardSecondary
                                    onClick={() => {
                                        setEducationToEdit(education)
                                        setShowEducationSectionBoolean(true)
                                    }}
                                    text={t('edit')}
                                    iconComponent={<FaPen className="inline-block mr-2 w-5 h-4" />}
                                    marginRight="2"
                                />
                            </div>
                        </Card>
                    )
                })}
            </div>
        )}
        {loading && <Loader /> }
    </div>
  )
})
