import React, { useState } from 'react'
import UserOperations from 'redux/user/user-operations'
import PreferencesService from 'services/preferences-service'
import { useToasts } from 'react-toast-notifications'
import LanguageForm from './components/language-form'
import { useTranslation } from 'react-i18next'
import Lang from 'lang'
import { useDispatch } from 'react-redux'
import { Loader } from 'components/ui/molecules/Loader'
import { ChatService } from 'services/chat-service'
import { Card } from 'components/ui/atoms/Card'
import useUser from '../../../../../hooks/useUser'
import Config from 'config';

const Language = ({ activeTheme }) => {
  const { t, i18n } = useTranslation()

  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const { user } = useUser()

  const [showLanguage, setShowLanguage] = useState(false)

  const setUserInfo = (data) => dispatch(UserOperations.setUserInfo({ data }))
  const [loading, setLoading] = useState(false)

  const onSubmit = async (langCode) => {
    try {
      setLoading(true)
      const result = await PreferencesService.updatePreferences({
        ...user.preferences,
        language: langCode
      })
      Lang.setLang(langCode)
      ChatService.setChatLocalization(langCode)
      setUserInfo(result.data.data)
      await i18n.changeLanguage(langCode)
      setShowLanguage(!showLanguage)
      addToast(t('language_updated_successfully'), {
        appearance: 'success',
        autoDismiss: true
      })
    } catch (error) {
      if (error.response) {
        addToast(t('error_occurred_updating_language'), {
          appearance: 'error',
          autoDismiss: true
        })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Card>
    <h3 className={`border-b font-medium text-main-${Config.PLATFORM} font-bold mb-4 border-main-${Config.PLATFORM}`}>
        {t('language')}
      </h3>
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-start sm:justify-between">
        <div>
          <small className="text-gray-dark font-light">
            {t('change_language')}
          </small>
        </div>
        <div className="flex flex-row">
        <LanguageForm
            onSubmit={onSubmit}
            activeTheme={activeTheme}
            setShowLanguage={setShowLanguage}
          />
        </div>
      </div>
      {loading && <Loader />}
    </Card>
  )
}

export default Language