import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/ui/atoms/Button'

import { Input } from 'components/ui/atoms/Input'
import { isEmpty } from 'lodash'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { SidePanel } from 'components/ui/objects/SidePanel'
import Config from 'config'
import { ButtonDanger } from 'components/ui/atoms/ButtonDanger'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'

const MilestoneSectionWizard = ({
  initialValues,
  handleCloseWizard,
  onSubmit,
  deleteSection
}) => {
  const { t } = useTranslation()

  const MilestoneSectionSchema = Yup.object().shape({
    id: Yup.string().nullable(),
    section: Yup.string().required(t('required_field')),
  })

  const { register, errors, setValue, handleSubmit, trigger } = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    reValidateMode: 'all',
    resolver: yupResolver(MilestoneSectionSchema, { abortEarly: false })
  })

  useEffect(() => {
    register('id')
  }, [register])

  useEffect(() => {
    if (!isEmpty(initialValues)) {
      setValue('id', initialValues.id)
      setValue('section', initialValues.section)
    }
    trigger()
  }, [initialValues])

  return (
    <>
    <SidePanel
      onClose={handleCloseWizard}
      title={isEmpty(initialValues) ? t('create_section') : t('update_section')}
    >
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full">
            <Input
              reference={register}
              id="section"
              type="text"
              name="section"
              label={`${t('section')}: *`}
              placeholder={t('section')}
              error={errors.section}
            />
          </div>

          <div className={`flex justify-end mt-2 pt-2 border-t border-separator-${Config.PLATFORM}`}>
            {!isEmpty(initialValues) && <ButtonDanger
              type="button"
              text={
                t('delete_section')
              }
              marginRight='2'
              onClick={() => {
                deleteSection(initialValues.id)
                handleCloseWizard()
              }}
            />}
            <ButtonSecondary
              type="submit"
              text={
                isEmpty(initialValues) ? t('create_section') : t('update_section')
              }
            />
          </div>
        </form>
      </SidePanel>
    </>
  )
}

export default MilestoneSectionWizard
