import { useTranslation } from 'react-i18next'
import { Card } from 'components/ui/atoms/Card'
import Avatar from 'react-avatar'
import { FaCheck, FaFile, FaTimes, FaExpandAlt } from 'react-icons/all'
import { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { Button } from 'components/ui/atoms/Button'
import Utils from 'utils/utils'
import SupportService from '../../services/support-service'
import { useToasts } from 'react-toast-notifications'
import { Loader } from '../ui/molecules/Loader'
import { DealflowService } from '../../services/dealflow-service'
import { useSwal } from '../../hooks/useSwal'
import Config from 'config'
import { ButtonCardMain } from 'components/ui/atoms/ButtonCardMain'
import { ButtonCardSecondary } from 'components/ui/atoms/ButtonCardSecondary'

export const DealflowProjectConnection = ({ connection, onManage, setDealToResume }) => {
  console.log({connection})
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { confirm } = useSwal()
  const startup = connection.project
  const [loading, setLoading] = useState(false)

  const downloadDocument = async (documentId) => {
    setLoading(true)
    SupportService.getDocument(documentId).then((response) => {
      if (response?.data?.url) {
        window.open(response.data.url, '_blank')
      }
    }).catch((error) => {
      addToast(t(error?.response?.data?.msg_key || 'error_occurred_downloading_document'), {
        appearance: 'error',
        autoDismiss: true
      })
    }).finally(() => {
      setLoading(false)
    })
  }

  const acceptProjectConnection = () => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        return DealflowService.acceptProjectConnection(connection._id)
          .then(() => {
            addToast(t('startup_added_to_pool_successfully'), { appearance: 'success', autoDismiss: true })
            onManage && onManage()
          })
          .catch(() => {
            addToast(t('error_occurred_adding_startup_to_pool'), { appearance: 'error', autoDismiss: true })
          }).finally(() => {
            setLoading(false)
          })
      }
    })
  }

  const rejectSharedDealflow = () => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        return DealflowService.rejectProjectConnection(connection._id)
          .then(() => {
            addToast(t('startup_declined_successfully'), { appearance: 'success', autoDismiss: true })
            onManage && onManage()
          })
          .catch(() => {
            addToast(t('error_occurred_declining_startup'), { appearance: 'error', autoDismiss: true })
          }).finally(() => {
            setLoading(false)
          })
      }
    })
  }

  if (!startup) {
    return null
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  return (
    <>
      <Card
        wrapperClassName="transition-transform"
        containerClassName="w-full flex flex-col h-full"
      >
        <div className='flex flex-col w-full'>
          <div className="flex pb-2 mb-2 w-full">
            <div className='flex flex-row justify-around w-full'>

              <div className="flex flex-col h-full text-xs min-w-0 w-3/5">
                <h5 className="uppercase font-bold text-sm leading-none truncate mb-1">
                  {startup.name}
                </h5>
                <div>
                  <b>{t('date')}:</b>{' '}
                  <span className='capitalize'>{Utils.getReadableDate(connection.createdAt)}</span>
                </div>
              </div>

              <div className="flex items-center justify-center mr-2 w-2/5 info-card-container-img">
                <Avatar
                  src={startup.logo}
                  name={startup.name}
                  alt={startup.name}
                  maxInitials={3}
                  className={`h-full object-contain ${connection.project.logo ? 'w-full' : ''}`}
                  color="#e0e6f2"
                  fgColor="#4d70b3"
                  round="5"
                />
              </div>

            </div>
          </div>
        </div>

        <div className={`flex justify-end mt-2 pt-2 border-t border-separator-${Config.PLATFORM}`}>
          <ButtonCardSecondary
            onClick={() => setDealToResume(startup)}
            text={t('see_resume')}
            iconComponent={<FaExpandAlt className="inline-block mr-2 w-5 h-4" />}
            marginRight="4"
            />
          <ButtonCardSecondary
            onClick={() => rejectSharedDealflow(connection._id)}
            text={t('i_am_not_interested')}
            iconComponent={<FaTimes className="inline-block mr-2 w-5 h-4" />}
            marginRight="4"
            />
          <ButtonCardMain
            onClick={() => acceptProjectConnection(connection._id)}
            text={t('add_to_pool')}
            iconComponent={<FaCheck className="inline-block mr-2 w-5 h-4" />}
          />
        </div>
      </Card>

      {loading && <Loader />}
    </>
  )
}
