import Slider from 'rc-slider'
import { useEffect, useState } from 'react'
import 'rc-slider/assets/index.css'
import Config from 'config'

export const SliderScore = ({
  max,
  onChange,
  value,
  showInput = false,
  inputPosition = 'left'
}) => {
  const [sliderValue, setSliderValue] = useState(Number(value) || 0)

  const handleChange = (value) => {
    const newValue = value > max ? max : value
    setSliderValue(newValue)
    onChange(newValue)
  }

  useEffect(() => {
    setSliderValue(Number(value) || 0)
  }, [value])

  return (
    <>
      <div className={`w-full flex gap-4 ${inputPosition !== 'right' ? 'flex-row-reverse' : ''}`}>
        <div className='flex-1'>
          <Slider
              step={0.1}
              min={0}
              max={max}
              value={sliderValue}
              onChange={handleChange}
              marks={{ [sliderValue]: sliderValue }}
            />
          </div>

        <div className='w-20 hidden sm:flex justify-center items-center'>
          <input
            type='number'
            value={sliderValue}
            onChange={e => handleChange(Number(e.target.value))}
            onClick={e => e.target?.select()}
            className={`
              shadow-soft-white border border-gray-lines focus:border-main-${Config.PLATFORM} w-full py-1  
              text-xs text-center rounded-md placeholder-gray cursor-pointer transition-all duration-200 
              outline-none hover:border-main-${Config.PLATFORM} hover:outline-none hover:shadow-inner focus:outline-none 
              focus:shadow-focus active:outline-none webkit-appearance-none
            `}
          />
        </div>
      </div>
    </>
  )
}
