import { Button } from 'components/ui/atoms/Button'
import { Input } from 'components/ui/atoms/Input'
import { Modal } from 'components/ui/molecules/Modal'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Config from 'config'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
const NewDataRoomModal = ({ showModal, onClose, onSubmit }) => {
    const { t } = useTranslation()

    const { register, handleSubmit, errors } = useForm({
        mode: 'onChange',
        criteriaMode: 'all',
        reValidateMode: 'all',
        resolver: yupResolver(Yup.object().shape({
          name: Yup.string().required(t('required_field'))
        }))
      })

    return (
        <Modal
            showModal={showModal}
            onClose={onClose}
            showCloseModal={true}
            fixedWidth={'w-1/4'}
        >
            <div className={'flex flex-col max-h-full'}>
                <h2 className={`font-semibold text-xl mb-2 text-main-${Config.PLATFORM}`}>
                    {t('create_data_room')}
                </h2>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className='flex flex-col'
                >
                    <Input
                        reference={register}
                        name='name'
                        label={t('name')}
                        placeholder={t('new_data_room_placeholder')}
                        error={errors.name}
                    />

                    <div className={`flex justify-end mt-4 border-t pt-2 border-separator-${Config.PLATFORM}`}>
                        <ButtonMain text={t('create_data_room')}/>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default NewDataRoomModal
