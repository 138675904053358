import React, { useEffect, useState, useImperativeHandle, forwardRef} from 'react'
import TagsService from '../../services/tags-service'
import ReactTooltip from 'react-tooltip'
import { Loader } from '../ui/molecules/Loader'
import { Card } from '../ui/atoms/Card'
import { Button } from '../ui/atoms/Button'
import { FaEdit, FaTrash } from 'react-icons/all'
import { NoDataInfo } from '../ui/atoms/NoDataInfo'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { useSwal } from '../../hooks/useSwal'
import CreateTagModal from './CreateTagModal'
import Config from 'config';
import { ButtonDanger } from 'components/ui/atoms/ButtonDanger'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'

export const TagListView = forwardRef(({ category }, ref) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { confirm } = useSwal()
  const [loading, setLoading] = useState(true)
  const [tagToEdit, setTagToEdit] = useState(null)
  const [showTagModal, setShowTagModal] = useState(false)
  const [tags, setTags] = useState([])

  const getTags = () => {
    setLoading(true)

    TagsService.getTags(category).then((response) => {
      setTags(response.data.data)
      ReactTooltip.rebuild()
    }).catch((error) => {
      addToast(
        error.response && error.response.data.msg_key
          ? error.response.data.msg_key
          : t('error_occurred_retrieving_tags'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    }).finally(() => {
      setLoading(false)
    })
  }

  useImperativeHandle(ref, () => ({
    handleCreateTag: () => setShowTagModal(true)
  }))

  const confirmCreate = async (name, color) => {
    try {
      setShowTagModal(false)
      setLoading(true)
      await TagsService.createTag(category, name, color)
      getTags()
      addToast(t('tag_created_successfully'), {
        appearance: 'success',
        autoDismiss: true
      })
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? error.response.data.msg_key
          : t('error_occurred_creating_tag'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    } finally {
      setTagToEdit(null)
      setLoading(false)
    }
  }

  const confirmUpdate = async (name, color) => {
    confirm({
      text: t('update_tag_text'),
      icon: 'warning'
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setShowTagModal(false)
          setLoading(true)
          await TagsService.updateTag(tagToEdit._id, { name, color })
          getTags()
          addToast(t('tag_updated_successfully'), {
            appearance: 'success',
            autoDismiss: true
          })
        } catch (error) {
          addToast(
            error.response && error.response.data.msg_key
              ? error.response.data.msg_key
              : t('error_occurred_updating_tag'),
            {
              appearance: 'error',
              autoDismiss: true
            }
          )
        } finally {
          setTagToEdit(null)
          setLoading(false)
        }
      }
    })
  }

  const deleteTag = (tagId) => {
    confirm({
      text: t(`delete_tag_${category}_text`),
      icon: 'warning'
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setShowTagModal(false)
          setLoading(true)
          await TagsService.deleteTag(tagId)
          getTags()
          addToast(t('tag_deleted_successfully'), {
            appearance: 'success',
            autoDismiss: true
          })
        } catch (error) {
          addToast(
            error.response && error.response.data.msg_key
              ? error.response.data.msg_key
              : t('error_occurred_deleting_tag'),
            {
              appearance: 'error',
              autoDismiss: true
            }
          )
        } finally {
          setTagToEdit(null)
          setLoading(false)
        }
      }
    })
  }

  const onModalSubmit = (name, color) => {
    tagToEdit ? confirmUpdate(name, color) : confirmCreate(name, color)
  }

  const handleEditTag = (tag) => {
    setShowTagModal(true)
    setTagToEdit({ ...tag })
  }

  useEffect(() => {
    category && getTags()
  }, [category])

  return (
    <>
      {showTagModal && (
        <CreateTagModal
          tag={tagToEdit}
          showModal={showTagModal}
          handleCloseModal={() => {
            setShowTagModal(false)
            setTagToEdit(null)
          }}
          onSubmit={onModalSubmit}
        />
      )}

      {tags.length !== 0 && (<div className="grid grid-cols-3 gap-4 mb-4 my-10">
        {tags.map((tag) => (
          <Card key={tag._id} containerClassName="flex items-center">
            <div
              style={{ backgroundColor: tag.color }}
              className='rounded-full w-8 h-8 mr-2'
            />

            <div className='flex-1 font-semibold'>
              {tag.name}
            </div>

            <div className='flex ml-2'>
              <div className='mr-2' data-tip={t('delete')}>
                <ButtonDanger
                  onClick={() => deleteTag(tag._id)}
                  iconComponent={<FaTrash className="inline" />}
                />
              </div>

              <div data-tip={t('edit')}>
                <ButtonSecondary
                  onClick={() => handleEditTag(tag)}
                  iconComponent={<FaEdit className={'inline'} />}
                />
              </div>
            </div>
          </Card>
        ))}
      </div>)}

      {tags.length === 0 && !loading && (
        <div className='my-6'>
                <NoDataInfo
                  title={t(`tags_not_found_${category}`)}
                />
          </div>
      )}

      {loading && <Loader />}
    </>
  )
})
