import React, { useState, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { Loader } from 'components/ui/molecules/Loader'
import { RoutesLinks } from 'components/routes-links'
import { Card } from 'components/ui/atoms/Card'
import Arrow from 'styles/images/arrow_right_square.svg'
import SupportService from 'services/support-service'
import moment from 'moment'

const AdminScope = () => {
  const { t } = useTranslation()

  const myRef = useRef(null)
  const history = useHistory()

  const [loadingStats, setLoadingStats] = useState(true)

  const { addToast } = useToasts()

  const [stats, setStats] = useState(null)

  const location = useLocation()

  const handleRedirect = (url) =>
    history.push({
      pathname: url,
      state: {
        from: location.pathname
      }
    })

  const getStats = useCallback(async () => {
    try {
      setLoadingStats(true)

      const result = await SupportService.getStats()
      setStats(result.data.data)
    } catch (error) {
      addToast(
        error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t('error_occurred_retrieving_stats'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    } finally {
      setLoadingStats(false)
    }
  }, [setStats])

  useEffect(() => {
    getStats()
  }, [])

  return (
    <div ref={myRef}>
      <div className={'text-2xl font-bold'}>
        {t('dashboard')}
      </div>

      <div className={'mt-10 w-full'}>
        <div className="w-full relative">
          <h5 className="font-bold mb-4 border-b ">
            {t('entrepreneurs')}
            <img
              src={Arrow}
              alt="Arrow right icon"
              className="inline-block cursor-pointer ml-1 mb-0.5 w-3"
              onClick={() =>
                handleRedirect(`${RoutesLinks.SUPPORT_TICKETS_LINK}`)
              }
            />
          </h5>

          <Card>
            <div className="flex justify-around text-xs">
              <div className="w-1/4 border-r  py-2">
                <div className={'font-bold mb-2'}>
                  {t('total')}
                </div>
                <div className={'text-xl font-bold'}>
                  {stats && stats?.entrepreneurs?.count
                    ? stats.entrepreneurs.count
                    : '-'}
                </div>
              </div>
              <div className="w-1/4 border-r  pl-4 py-2">
                <div className={'font-bold mb-2'}>
                  {t('activated')}
                </div>
                <div className={'text-xl font-bold'}>
                  {stats && stats?.entrepreneurs?.activated
                    ? `${stats.entrepreneurs.activated}`
                    : '-'}
                </div>
              </div>
              <div className="w-1/4 pl-4 py-2">
                <div className={'font-bold mb-2'}>
                  {t('last_registration')}
                </div>
                <div className={'text-xs mt-4'}>
                  {stats && stats?.entrepreneurs?.last_registration
                    ? `${moment(
                      stats.entrepreneurs.last_registration
                    ).format('YYYY-MM-DD HH:MM')}`
                    : '-'}
                </div>
              </div>
            </div>
          </Card>

          <div className={'mt-5'}>
            <Card>
              <div className="flex justify-around text-xs">
                <div className="w-1/4 border-r  pl-4 py-2">
                  <div className={'font-bold mb-2'}>
                    {t('today')}
                  </div>
                  <div className={'text-xl font-bold'}>
                    {stats && stats?.entrepreneurs?.today
                      ? `${stats.entrepreneurs.today}`
                      : '-'}
                  </div>
                </div>
                <div className="w-1/4 border-r  pl-4 py-2">
                  <div className={'font-bold mb-2'}>
                    {t('last_7_days')}
                  </div>
                  <div className={'text-xl font-bold'}>
                    {stats && stats?.entrepreneurs?.last_seven_days
                      ? `${stats.entrepreneurs.last_seven_days}`
                      : '-'}
                  </div>
                </div>
                <div className="w-1/4 border-r  pl-4 py-2">
                  <div className={'font-bold mb-2'}>
                    {t('last_30_days')}
                  </div>
                  <div className={'text-xl font-bold'}>
                    {stats && stats?.entrepreneurs.last_thirty_days
                      ? `${stats.entrepreneurs.last_thirty_days}`
                      : '-'}
                  </div>
                </div>
                <div className="w-1/4 pl-4 py-2">
                  <div className={'font-bold mb-2'}>
                    {t('last_60_days')}
                  </div>
                  <div className={'text-xl font-bold'}>
                    {stats && stats?.entrepreneurs?.last_sixty_days
                      ? `${stats.entrepreneurs.last_sixty_days}`
                      : '-'}
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>

      <div className={'mt-10 w-full'}>
        <div className="w-full relative">
          <h5 className="font-bold mb-4 border-b ">
            {t('startups')}
            <img
              src={Arrow}
              alt="Arrow right icon"
              className="inline-block cursor-pointer ml-1 mb-0.5 w-3"
              onClick={() =>
                handleRedirect(`${RoutesLinks.SUPPORT_TICKETS_LINK}`)
              }
            />
          </h5>
          <Card>
            <div className="flex justify-around text-xs">
              <div className="w-1/6 border-r  pl-4 py-2">
                <div className={'font-bold mb-2'}>
                  {t('total')}
                </div>
                <div className={'text-xl font-bold'}>
                  {stats && stats.startups && stats.startups.count
                    ? `${stats.startups.count}`
                    : '-'}
                </div>
              </div>
              <div className="w-1/6 border-r  pl-4 py-2">
                <div className={'font-bold mb-2'}>
                  {t('today')}
                </div>
                <div className={'text-xl font-bold'}>
                  {stats && stats.startups
                    ? `${stats.startups.today}`
                    : '-'}
                </div>
              </div>
              <div className="w-1/6 border-r  pl-4 py-2">
                <div className={'font-bold mb-2'}>
                  {t('last_7_days')}
                </div>
                <div className={'text-xl font-bold'}>
                  {stats && stats.startups
                    ? `${stats.startups.last_seven_days}`
                    : '-'}
                </div>
              </div>
              <div className="w-1/6 border-r  pl-4 py-2">
                <div className={'font-bold mb-2'}>
                  {t('last_30_days')}
                </div>
                <div className={'text-xl font-bold'}>
                  {stats && stats.startups
                    ? `${stats.startups.last_thirty_days}`
                    : '-'}
                </div>
              </div>
              <div className="w-1/6 border-r  pl-4 py-2">
                <div className={'font-bold mb-2'}>
                  {t('last_60_days')}
                </div>
                <div className={'text-xl font-bold'}>
                  {stats && stats.startups
                    ? `${stats.startups.last_sixty_days}`
                    : '-'}
                </div>
              </div>
              <div className="w-1/6 pl-4 py-2">
                <div className={'font-bold mb-2'}>
                  {t('last_registration')}
                </div>
                <div className={'text-xs mt-4'}>
                  {stats && stats?.startups?.last_registration
                    ? `${moment(stats.startups.last_registration).format(
                      'YYYY-MM-DD'
                    )}`
                    : '-'}
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>

      <div className={'mt-10 w-full'}>
        <div className="w-full relative">
          <h5 className="font-bold mb-4 border-b ">
            {t('data_rooms')}
            <img
              src={Arrow}
              alt="Arrow right icon"
              className="inline-block cursor-pointer ml-1 mb-0.5 w-3"
              onClick={() =>
                handleRedirect(`${RoutesLinks.SUPPORT_TICKETS_LINK}`)
              }
            />
          </h5>

          <Card>
            <div className="flex justify-around text-xs">
              <div className="w-1/4 border-r  py-2">
                <div className={'font-bold mb-2'}>
                  {t('total')}
                </div>
                <div className={'text-xl font-bold'}>
                  {stats && stats.data_rooms && stats.data_rooms.count
                    ? stats.data_rooms.count
                    : '-'}
                </div>
              </div>
              <div className="w-1/4 border-r  pl-4 py-2">
                <div className={'font-bold mb-2'}>
                  {t('public')}
                </div>
                <div className={'text-xl font-bold'}>
                  {stats && stats?.data_rooms?.public
                    ? stats.data_rooms.public
                    : '-'}
                </div>
              </div>
              <div className="w-1/4 border-r  pl-4 py-2">
                <div className={'font-bold mb-2'}>
                  {t('restricted')}
                </div>
                <div className={'text-xl font-bold'}>
                  {stats && stats?.data_rooms?.restricted
                    ? stats.data_rooms.restricted
                    : '-'}
                </div>
              </div>
              <div className="w-1/4 pl-4 py-2">
                <div className={'font-bold mb-2'}>
                  {t('last_data_room')}
                </div>
                <div className={'text-xs mt-4'}>
                  {stats && stats?.data_rooms?.last_data_room
                    ? `${moment(stats.data_rooms.last_data_room).format(
                      'YYYY-MM-DD HH:MM'
                    )}`
                    : '-'}
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>

      <div className={'mt-5'}>
        <Card>
          <div className="flex justify-around text-xs">
            <div className="w-1/4 border-r  pl-4 py-2">
              <div className={'font-bold mb-2'}>
                {t('today')}
              </div>
              <div className={'text-xl font-bold'}>
                {stats && stats.data_rooms
                  ? `${stats.data_rooms.today}`
                  : '-'}
              </div>
            </div>
            <div className="w-1/4 border-r  pl-4 py-2">
              <div className={'font-bold mb-2'}>
                {t('last_7_days')}
              </div>
              <div className={'text-xl font-bold'}>
                {stats && stats.data_rooms
                  ? `${stats.data_rooms.last_seven_days}`
                  : '-'}
              </div>
            </div>
            <div className="w-1/4 border-r  pl-4 py-2">
              <div className={'font-bold mb-2'}>
                {t('last_30_days')}
              </div>
              <div className={'text-xl font-bold'}>
                {stats && stats.data_rooms
                  ? `${stats.data_rooms.last_thirty_days}`
                  : '-'}
              </div>
            </div>
            <div className="w-1/4 pl-4 py-2">
              <div className={'font-bold mb-2'}>
                {t('last_60_days')}
              </div>
              <div className={'text-xl font-bold'}>
                {stats && stats.data_rooms
                  ? `${stats.data_rooms.last_sixty_days}`
                  : '-'}
              </div>
            </div>
          </div>
        </Card>
      </div>

      <div className={'mt-10 w-full'}>
        <div className="w-full relative">
          <h5 className="font-bold mb-4 border-b ">
            {t('documents')}
            <img
              src={Arrow}
              alt="Arrow right icon"
              className="inline-block cursor-pointer ml-1 mb-0.5 w-3"
              onClick={() =>
                handleRedirect(`${RoutesLinks.SUPPORT_TICKETS_LINK}`)
              }
            />
          </h5>
          <Card>
            <div className="flex justify-around text-xs">
              <div className="w-1/6 border-r  pl-4 py-2">
                <div className={'font-bold mb-2'}>
                  {t('total')}
                </div>
                <div className={'text-xl font-bold'}>
                  {stats && stats.documents && stats.documents.count
                    ? `${stats.documents.count}`
                    : '-'}
                </div>
              </div>
              <div className="w-1/6 border-r  pl-4 py-2">
                <div className={'font-bold mb-2'}>
                  {t('today')}
                </div>
                <div className={'text-xl font-bold'}>
                  {stats && stats.documents
                    ? `${stats.documents.today}`
                    : '-'}
                </div>
              </div>
              <div className="w-1/6 border-r  pl-4 py-2">
                <div className={'font-bold mb-2'}>
                  {t('last_7_days')}
                </div>
                <div className={'text-xl font-bold'}>
                  {stats && stats.documents
                    ? `${stats.documents.last_seven_days}`
                    : '-'}
                </div>
              </div>
              <div className="w-1/6 border-r  pl-4 py-2">
                <div className={'font-bold mb-2'}>
                  {t('last_30_days')}
                </div>
                <div className={'text-xl font-bold'}>
                  {stats && stats.documents
                    ? `${stats.documents.last_thirty_days}`
                    : '-'}
                </div>
              </div>
              <div className="w-1/6 border-r  pl-4 py-2">
                <div className={'font-bold mb-2'}>
                  {t('last_60_days')}
                </div>
                <div className={'text-xl font-bold'}>
                  {stats && stats.documents
                    ? `${stats.documents.last_sixty_days}`
                    : '-'}
                </div>
              </div>
              <div className="w-1/6 pl-4 py-2">
                <div className={'font-bold mb-2'}>
                  {t('last_document')}
                </div>
                <div className={'text-xs mt-4'}>
                  {stats && stats?.documents?.last_document
                    ? `${moment(stats.documents.last_document).format(
                      'YYYY-MM-DD'
                    )}`
                    : '-'}
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>

      <div className={'mt-10 w-full'}>
        <div className="w-full relative">
          <h5 className="font-bold mb-4 border-b ">
            {t('support_tickets')}
            <img
              src={Arrow}
              alt="Arrow right icon"
              className="inline-block cursor-pointer ml-1 mb-0.5 w-3"
              onClick={() =>
                handleRedirect(`${RoutesLinks.SUPPORT_TICKETS_LINK}`)
              }
            />
          </h5>

          <Card>
            <div className="flex justify-around text-xs">
              <div className="w-1/6 border-r  py-2">
                <div className={'font-bold mb-2'}>
                  {t('tickets')}
                </div>
                <div className={'text-xl font-bold'}>
                  {stats &&
                  stats.support_tickets &&
                  stats.support_tickets.count
                    ? stats.support_tickets.count
                    : '-'}
                </div>
              </div>
              <div className="w-1/6 border-r  pl-4 py-2">
                <div className={'font-bold mb-2'}>
                  {t('pending_tickets')}
                </div>
                <div className={'text-xl font-bold'}>
                  {stats && stats.support_tickets
                    ? `${stats.support_tickets.pending}`
                    : '-'}
                </div>
              </div>
              <div className="w-1/6 border-r  pl-4 py-2">
                <div className={'font-bold mb-2'}>
                  {t('in_progress')}
                </div>
                <div className={'text-xl font-bold'}>
                  {stats && stats.support_tickets
                    ? `${stats.support_tickets.in_progress}`
                    : '-'}
                </div>
              </div>
              <div className="w-1/6 border-r  pl-4 py-2">
                <div className={'font-bold mb-2'}>
                  {t('closed')}
                </div>
                <div className={'text-xl font-bold'}>
                  {stats && stats.support_tickets
                    ? `${stats.support_tickets.closed}`
                    : '-'}
                </div>
              </div>
              <div className="w-1/6 border-r  pl-4 py-2">
                <div className={'font-bold mb-2'}>
                  {t('confirmed')}
                </div>
                <div className={'text-xl font-bold'}>
                  {stats && stats.support_tickets
                    ? `${stats.support_tickets.confirmed}`
                    : '-'}
                </div>
              </div>
              <div className="w-1/6 pl-4 py-2">
                <div className={'font-bold mb-2'}>
                  {t('last_ticket')}
                </div>
                <div className={'text-xs mt-4'}>
                  {stats && stats.support_tickets
                    ? `${moment(stats.support_tickets.last_ticket).format(
                      'YYYY-MM-DD HH:MM'
                    )}`
                    : '-'}
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>

      <div className={'my-10 w-full'}>
        <div className="w-full relative">
          <h5 className="font-bold mb-4 border-b ">
            {t('nps_section')}
            <img
              src={Arrow}
              alt="Arrow right icon"
              className="inline-block cursor-pointer ml-1 mb-0.5 w-3"
              onClick={() =>
                handleRedirect(`${RoutesLinks.SUPPORT_NPS_LINK}`)
              }
            />
          </h5>

          <Card>
            <div className="flex justify-around text-xs">
              <div className="w-1/5 border-r  pl-4 py-2">
                <div className={'font-bold mb-2'}>
                  {t('scores')}
                </div>
                <div className={'text-xl font-bold'}>
                  {stats && stats.nps && stats.nps.count
                    ? stats.nps.count
                    : '-'}
                </div>
              </div>
              <div className="w-1/5 border-r  pl-4 py-2">
                <div className={'font-bold mb-2'}>
                  {t('maximum_score')}
                </div>
                <div className={'text-xl font-bold'}>
                  {`${stats && stats.nps ? stats.nps.max : '-'}/10`}
                </div>
              </div>
              <div className="w-1/5 border-r  pl-4 py-2">
                <div className={'font-bold mb-2'}>
                  {t('minimum_score')}
                </div>
                <div className={'text-xl font-bold'}>
                  {`${stats && stats.nps ? stats.nps.min : '-'}/10`}
                </div>{' '}
              </div>
              <div className="w-1/5 border-r  pl-4 py-2">
                <div className={'font-bold mb-2'}>
                  {t('avg_score')}
                </div>
                <div className={'text-xl font-bold'}>
                  {`${stats && stats.nps ? stats.nps.avg : '-'}/10`}
                </div>{' '}
              </div>
              <div className="w-1/5 pl-4 py-2">
                <div className={'font-bold mb-2'}>
                  {t('last_score')}
                </div>
                <div className={'text-xs mt-4'}>
                  {stats && stats.nps
                    ? `${moment(stats.nps.last_score).format(
                      'YYYY-MM-DD HH:MM'
                    )}`
                    : '-'}
                </div>{' '}
              </div>
            </div>
          </Card>
        </div>
      </div>

      {loadingStats && <Loader />}
    </div>
  )
}

export default AdminScope
