/* eslint-disable default-case */
import produce from 'immer'

import { EntrepreneursActionsTypes } from './entrepreneurs-actions-types'
import { UserActionsTypes } from '../user/user-actions-types'

const { SET_FILTERS, SET_PAGINATION, SET_SORT, RESET } =
  EntrepreneursActionsTypes
const { RESET: LOGOUT } = UserActionsTypes

const defaultState = {
  filters: {
    name: '',
    selectedDateType: 'custom',
    selectedDate: [null, null],
    sortBy: '-createdAt'
  },
  pagination: {
    page: 0,
    rowsPerPage: 12
  }
}

const EntrepreneursReducer = (state = defaultState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_FILTERS:
        draft.filters = {
          ...action.payload.data.filters
        }
        break
      case SET_PAGINATION:
        draft.pagination = action.payload.data
        break
      case SET_SORT:
        draft.filters = {
          ...action.payload.data
        }
        break
      case RESET:
      case LOGOUT:
        return defaultState
    }
  })

export default EntrepreneursReducer
