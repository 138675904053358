import { useTranslation } from 'react-i18next'
import { Card } from 'components/ui/atoms/Card'
import Avatar from 'react-avatar'
import { FaCheck, FaFile, FaTimes } from 'react-icons/all'
import { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { Button } from 'components/ui/atoms/Button'
import Utils from 'utils/utils'
import SupportService from '../../services/support-service'
import { useToasts } from 'react-toast-notifications'
import { Loader } from '../ui/molecules/Loader'
import { DealflowService } from '../../services/dealflow-service'
import { useSwal } from '../../hooks/useSwal'
import Config from 'config'

export const DealflowSharedListItem = ({ dealflow, onManage }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { confirm } = useSwal()
  const startup = dealflow.startup
  const [loading, setLoading] = useState(false)

  const downloadDocument = async (documentId) => {
    setLoading(true)
    SupportService.getDocument(documentId).then((response) => {
      if (response?.data?.url) {
        window.open(response.data.url, '_blank')
      }
    }).catch((error) => {
      addToast(t(error?.response?.data?.msg_key || 'error_occurred_downloading_document'), {
        appearance: 'error',
        autoDismiss: true
      })
    }).finally(() => {
      setLoading(false)
    })
  }

  const addShareDealflowToPool = (sharedDealflowId) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        return DealflowService.moveSharedDealflowToPool(sharedDealflowId)
          .then(() => {
            addToast(t('startup_added_to_pool_successfully'), { appearance: 'success', autoDismiss: true })
          })
          .catch(() => {
            addToast(t('error_occurred_adding_startup_to_pool'), { appearance: 'error', autoDismiss: true })
          }).finally(() => {
            setLoading(false)
            onManage && onManage()
          })
      }
    })
  }

  const rejectSharedDealflow = (sharedDealflowId) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        return DealflowService.rejectSharedDealflow(sharedDealflowId)
          .then(() => {
            addToast(t('startup_declined_successfully'), { appearance: 'success', autoDismiss: true })
          })
          .catch(() => {
            addToast(t('error_occurred_declining_startup'), { appearance: 'error', autoDismiss: true })
          }).finally(() => {
            setLoading(false)
            onManage && onManage()
          })
      }
    })
  }

  if (!startup) {
    return null
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  return (
    <>
      <Card
        wrapperClassName="transition-transform"
        containerClassName="divide-gray-lines w-full flex flex-col h-full"
      >
        <div className='flex flex-col'>
          <div className="flex border-b border-gray-lines pb-2 mb-2 justify-between">
            <div className='flex flex-row'>
              <div className="flex items-center justify-center mr-2 w-[50px] h-[50px]">
                <Avatar
                  src={startup.logo}
                  name={startup.name}
                  alt={startup.name}
                  maxInitials={3}
                  className="w-full h-full"
                  color="#e0e6f2"
                  fgColor="#4d70b3"
                  round="5"
                />
              </div>

              <div className="flex flex-col h-full text-xs min-w-0">
                <h5 className={`uppercase font-bold text-sm leading-none text-main-${Config.PLATFORM} truncate mb-1`}>
                  {startup.name}
                </h5>
                <div><b>{t('shared_by')}:</b> {dealflow.shared_by_investor.company_name}</div>
                <div><b>{t('shared')}:</b> <span className='capitalize'>{Utils.getReadableDate(dealflow.createdAt)}</span></div>
              </div>
            </div>
          </div>
        </div>
        <div className='flex justify-end mt-2 pt-2 border-t boder-gray-lines'>
          <Button
            onClick={() => rejectSharedDealflow(dealflow._id)}
            text={t('i_am_not_interested')}
            verticalMargin="1"
            iconComponent={<FaTimes className="inline-block mr-2 w-5 h-4" />}
            vertical="2"
            horizontal="4"
            marginRight="4"
            className="text-xs w-auto"
            bgColor='white'
            borderColor='transparence-blue'
          />
          <Button
            onClick={() => addShareDealflowToPool(dealflow._id)}
            text={t('move_to_pool')}
            width="auto"
            verticalMargin="1"
            iconComponent={<FaCheck className="inline-block mr-2 w-5 h-4" />}
            vertical="2"
            horizontal="4"
            marginRight="4"
            className="text-xs"
          />
        </div>
      </Card>

      {loading && <Loader />}
    </>
  )
}
