import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import QuestionnairesService from 'services/questionnaires-service'
import { useParams, useHistory } from 'react-router-dom'
import { RoutesLinks } from 'components/routes-links'
import React, { useEffect, useState } from 'react'
import { Loader } from 'components/ui/molecules/Loader'
import { PageTitle } from 'components/ui/atoms/PageTitle'
import { Question } from 'components/qe-questions/components/Question'
import TranslateUtils from 'utils/translate-utils'
import { Button } from 'components/ui/atoms/Button'
import CreateFormFromTemplateModal from 'components/qe-templates/components/templates-modal'
import useUser from '../../../../hooks/useUser'
import { Card } from '../../../ui/atoms/Card'
import { Modal } from '../../../ui/molecules/Modal'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { Alert } from 'components/ui/molecules/Alert'

const Template = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { templateId } = useParams()
  const { userLanguage, haveFunctionality } = useUser()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [template, setTemplate] = useState(null)
  const [showCreateFormModal, setShowCreateFormModal] = useState(false)
  const questionKeys = ['startup_name', 'startup_logo', 'founder_email', 'startup_deck', 'one_pager']

  const getTemplate = () => {
    setLoading(true)
    return QuestionnairesService.getQuestionnairesTemplate(templateId)
      .then((response) => {
        setTemplate(response.data.data)
      })
      .catch(() => {
        addToast(t('error_retrieving_template'), {
          appearance: 'error',
          autoDismiss: true
        })
        history.push(RoutesLinks.QE_TEMPLATES_LINK)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const createFormFromTemplate = (name) => {
    setLoading(true)
    setShowCreateFormModal(false)

    return QuestionnairesService.createQuestionnairesForm({ template: templateId, name })
      .then((response) => {
        addToast(t('form_created_successfully'), {
          appearance: 'success',
          autoDismiss: true
        })
        history.push(`${RoutesLinks.QE_FORMS_LINK}/${response.data.data._id}`, { section: 'content' })
      })
      .catch(() => {
        addToast(t('error_creating_questionnaire'), {
          appearance: 'error',
          autoDismiss: true
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getKeyQuestionTitle = (questionKey) => {
    if (!template?.key_questions[questionKey]) {
      return t('no_data')
    }

    const question = template.questions.find(question => {
      return question.question_id._id === template.key_questions[questionKey]
    })

    return TranslateUtils.getTextByLang(userLanguage, question?.question_id?.title)
  }

  useEffect(() => {
    if (templateId) {
      getTemplate()
    }
  }, [templateId])

  return (
    <>
      <div className='flex items-start'>
        <div className='flex-1'>
          <PageTitle
            title={t('template')}
            showBack={true}
            onBackClick={() => history.push(RoutesLinks.QE_TEMPLATES_LINK)}
          />
        </div>

        <ButtonMain
          onClick={() => setShowCreateFormModal(true)}
          text={t('use_this_template')}
        />
      </div>

      {showCreateFormModal && (
        <CreateFormFromTemplateModal
          showModal={showCreateFormModal}
          onSubmit={createFormFromTemplate}
          handleCloseModal={() => setShowCreateFormModal(false)}
        />
      )}

      {template && (
        <div className="flex my-6">
          <div className={'w-full pr-4'}>
            <div className={'mb-4'}>
              <Alert style={'info'} text={t('template_description')}/>
            </div>
            {template.questions.map(question => (
              <>
                <Question
                  key={question.question_id._id}
                  question={question.question_id}
                  showRequiredBadge={question.required}
                  showTag={true}
                  openEditInTab={false}
                />
              </>
            ))}
          </div>
        </div>
      )}

      {loading && <Loader />}
    </>
  )
}

export default Template
