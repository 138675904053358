import Avatar from 'react-avatar'
import Utils from '../../utils/utils'

export const TagListCircles = ({ tags }) => {
  if (!tags || tags.length === 0) {
    return null
  }

  return (
    <>
      {tags.map(tag => (
        <span
          key={tag._id}
          data-tip={tag.name}
          className="inline-block mr-2"
        >
          <Avatar
            size="30"
            name={tag.name}
            round={true}
            color={tag.color}
            fgColor={Utils.saturateColor(tag.color, 0.7)}
            alt={tag.name}
          />
        </span>
      ))}
    </>
  )
}
