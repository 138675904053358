import React from 'react'
import moment from 'moment';
import { FaEye } from 'react-icons/all';
import Config from 'config';
import { Card } from 'components/ui/atoms/Card';
import { DataGrid } from 'components/ui/objects/DataGrid';

const EmailsList = ({ emails, getEmailContent, showEmailDetail }) => {

  if ((!emails || !emails.length)) {
    return null
  }

  return (

    <>
      <Card>
        <DataGrid
          data={emails.map((email) => {
            const fromRaw = email.payload.headers.find(header => header.name === 'From')?.value;
            const toRaw = email.payload.headers.find(header => header.name === 'To')?.value;
            const from = fromRaw.includes("<") ? fromRaw.substring(fromRaw.indexOf("<") + 1, fromRaw.lastIndexOf(">")) : fromRaw;
            const to = toRaw.includes("<") ? toRaw.substring(toRaw.indexOf("<") + 1, toRaw.lastIndexOf(">")) : toRaw;
            const subject = email.payload.headers.find(header => header.name === 'Subject')?.value;

            return {
              id: email.id,
              email: email.labelIds.includes('INBOX') ? from : to,
              labelTypeEmail: email.labelIds.includes('INBOX') ? 'Received' : 'Sent',
              subject: subject || '(no subject)',
              date: moment(parseInt(email.internalDate)).format('DD/MM/YYYY')
            }
          })}
          headers={[
            { key: 'email', title: '' },
            { key: 'labelTypeEmail', title: '' },
            { key: 'subject', title: '' },
            { key: 'date', title: '' },
          ]}
          actions={[
            {
              id: 'see',
              buttonProps: {
                iconComponent: <FaEye className={'inline'} />
              },
              onClick: (email) => {
                getEmailContent(email.id)
                showEmailDetail(true)
              }
            }
          ]}
        />
      </Card>
    </>
  )
}

export default EmailsList