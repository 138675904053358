import { Modal } from '../../ui/molecules/Modal'
import { useTranslation } from 'react-i18next'
import { Button } from '../../ui/atoms/Button'
import { Card } from '../../ui/atoms/Card'
import { FaClone, FaRegClone, FaRegFile, FaRegFileAlt, ImInsertTemplate } from 'react-icons/all'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { RoutesLinks } from '../../routes-links'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Input } from '../../ui/atoms/Input'
import QuestionnairesService from '../../../services/questionnaires-service'
import { useToasts } from 'react-toast-notifications'
import { Loader } from '../../ui/molecules/Loader'
import Config from 'config'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { SidePanel } from 'components/ui/objects/SidePanel'
import Select from 'components/ui/atoms/Select'

export const CreateFormSection = ({ onClose, forms }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState('selectType')

  const { register, handleSubmit, errors, setValue, trigger } = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    reValidateMode: 'all',
    resolver: yupResolver(Yup.object().shape({
      name: Yup.string().required(t('required_field')),
      form: Yup.string().nullable()
    }))
  })

  useEffect(() => {
    register('form')
  }, [])
  const goToTemplates = () => {
    onClose && onClose()
    history.push(RoutesLinks.QE_TEMPLATES_LINK)
  }

  const onSubmit = (data) => {
    setLoading(true)
    QuestionnairesService.createQuestionnairesForm(data).then((result) => {
      addToast(t('form_created_successfully'), {
        appearance: 'success',
        autoDismiss: true
      })
      history.push(`${RoutesLinks.QE_FORMS_LINK}/${result.data.data._id}`, { section: 'content' })
    }).catch(() => {
      addToast(t('error_occurred_updating_form'), {
        appearance: 'error',
        autoDismiss: true
      })
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <>
      <SidePanel
        width={'1/4'}
        onClose={onClose}
        title={step === 'selectType' ? t('create_form') : step === 'blankForm' ? t('create_form_blank') : t('clone_form')}
      >
        <div className={'flex flex-row max-h-full'}>
          {step === 'selectType' && (
            <div>
              <Card wrapperClassName='my-2'>
                <h3>{t('create_form_blank')}</h3>
                <p className='text-sm'>{t('create_form_blank_description')}</p>
                <div className='flex justify-center items-center mt-8'>
                  <FaRegFile size={60} />
                </div>
                <ButtonSecondary
                  width={'full'}
                  marginTop={4}
                  text={t('select_this')}
                  onClick={() => setStep('blankForm')}
                />
              </Card>
              <Card wrapperClassName='my-2'>
                <h3>{t('clone_form')}</h3>
                <p className='text-sm'>{t('clone_form_description')}</p>
                <div className='flex justify-center items-center mt-8'>
                  <FaRegClone size={60} />
                </div>
                <ButtonSecondary
                  width={'full'}
                  marginTop={4}
                  text={t('select_this')}
                  onClick={() => setStep('cloneForm')}
                />
              </Card>
              <Card wrapperClassName='my-2'>
                <h3>{t('create_form_template')}</h3>
                <p className='text-sm'>{t('create_form_template_description')}</p>
                <div className='flex justify-center items-center mt-8'>
                  <ImInsertTemplate size={60} />
                </div>
                <ButtonSecondary
                  width={'full'}
                  marginTop={4}
                  text={t('select_this')}
                  onClick={goToTemplates}
                />
              </Card>
            </div>
          )}

          {step === 'blankForm' && (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className='flex flex-col'
            >
              <p className='text-sm mb-8'>
                {t('create_form_blank_step_description')}
              </p>
              <Input
                reference={register}
                name='name'
                label={t('form_name')}
                error={errors.name}
              />

              <ButtonSecondary
                width={'full'}
                marginTop={4}
                text={t('create_form')}
              />
            </form>
          )}

          {step === 'cloneForm' && (
            <div className='w-full'>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className='flex flex-col'
              >
                <div className='w-full'>
                  <Input
                    reference={register}
                    name='name'
                    label={t('form_name')}
                    error={errors.name}
                  />
                  <div className="my-3">
                    <Select
                        name="form"
                        label={t('select_form')}
                        multiSelect={false}
                        isClearable={false}
                        items={forms}
                        initialValues={[]}
                        onSelect={(value) => {
                          setValue('form', value[0].id)
                          trigger()
                        }}
                    />
                </div>
                </div>

                <ButtonSecondary
                  width={'full'}
                  marginTop={4}
                  text={t('clone_form')}
                />
              </form>
            </div>
          )}

        </div>
      </SidePanel>

      {loading && <Loader />}
    </>
  )
}
