import moment from 'moment'
import { QuestionsUtils } from './questions-utils'

const FormUtils = {
  URL_REGEX: /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/i,
  parseDateFromString: (dateString, format = 'YYYY-MM-DD') => {
    const date = moment(dateString, format)
    if (date.isValid()) {
      return date.toISOString()
    }

    return null
  },

  getDateInputValueFromDate: (date, withTime = false) => {
    date.setMinutes(date.getMinutes())
    return date.toISOString().slice(0, withTime ? 16 : 10)
  },

  fileToBase64: (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  },

  readFile: (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = (error) => {
        reject(error)
      }
      reader.readAsText(file)
    })
  },

  getBaseQuestionAnswers: (questionType, answer) => {
    if (questionType === 'select') {
      return [answer]
    } else if (questionType === 'multiselect') {
      return answer.split(',')
    } else {
      return answer
    }
  },

  getInitialAnswers: (answers, questions) => {
    const initialAnswers = {}
    questions.forEach((q) => {
      if (answers[q.question_id._id]) {
        if (q.question_id.type !== 'questions_group') {
          initialAnswers[q.question_id._id] = FormUtils.getBaseQuestionAnswers(q.question_id.type, answers[q.question_id._id])
        } else {
          const groupedQuestionsAnswers = answers[q.question_id._id].split('|__|')
          const parsedGroupedQuestionsAnswers = []
          groupedQuestionsAnswers.forEach((groupedQuestionsAnswer, index) => {
            if (groupedQuestionsAnswer) {
              parsedGroupedQuestionsAnswers.push(FormUtils.getBaseQuestionAnswers(q.question_id.questionsGroup[index].type, groupedQuestionsAnswer))
            } else {
              parsedGroupedQuestionsAnswers.push(null)
            }
          })
          initialAnswers[q.question_id._id] = parsedGroupedQuestionsAnswers
        }
      }
    })

    return initialAnswers
  }, 

  getParsedAnswers: (parsedQuestions, answers, t, lang, downloadDocument, limited = false) => {
    const parsedQuestionsIds = parsedQuestions.map((q) => q.question._id)
    const parsedAnswers = []
    for (const answer of answers) {
      const answeredQuestions = []
      const parsedAnswer = {}
      for (const question of answer.application.questions) {

        if (!['questions_group', 'separator'].includes(question.question.type)) {
          parsedAnswer[question.question._id] = QuestionsUtils.getAnswer(question.question, question.answer, t, downloadDocument, lang, null, limited)
          answeredQuestions.push(question.question._id)
        } else if (question.question.type === 'questions_group') {
          {question.question.questionsGroup.forEach((qg, index) => {
            if (!['separator'].includes(qg.type)) {
              parsedAnswer[qg._id] = QuestionsUtils.getAnswer(qg, question.answer, t, downloadDocument, lang, index, limited)
              answeredQuestions.push(qg._id)
            }
          })}
        }
      }

      if (parsedQuestionsIds.length !== answeredQuestions.length) {
        parsedQuestionsIds.filter(x => !answeredQuestions.includes(x)).map((notAnsweredQuestionId) => {
          parsedAnswer[notAnsweredQuestionId] = '-'
        })
      }
      // parsedAnswer.answer = answer
      parsedAnswers.push(parsedAnswer)
    }

    return parsedAnswers
  },

  getParsedQuestions: (questions) => {
    const parsedQuestions = []
    questions.forEach((q) => {
      if (!['questions_group', 'separator'].includes(q.question_id.type)) {
        parsedQuestions.push({ question: q.question_id })
      } else if (q.question_id.type === 'questions_group') {
        q.question_id.questionsGroup.forEach((qg) => {
          if (!['separator'].includes(qg.type)) {
            parsedQuestions.push({ question: qg, group_question: q.question_id._id })
          }
        })
      }
    })

    return parsedQuestions
  }
}

export default FormUtils
