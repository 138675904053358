import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { PageTitle } from '../../../ui/atoms/PageTitle'
import useUser from '../../../../hooks/useUser'
import { Loader } from '../../../ui/molecules/Loader'
import { Card } from '../../../ui/atoms/Card'
import Avatar from 'react-avatar'
import Person from '../../../../assets/images/person.png'
import { Button } from '../../../ui/atoms/Button'
import { RoutesLinks } from '../../../routes-links'
import { FaGithub, FaGitlab, FaLinkedin, FaMedium, FaTwitter, FaYoutube, SiCrunchbase } from 'react-icons/all'
import ReactTooltip from 'react-tooltip'
import { StartupSocialNetworks } from '../../../startup-profile/components/StartupSocialNetworks'
import { NoDataInfo } from '../../../ui/atoms/NoDataInfo'
import Config from 'config';
import { ButtonCardMain } from 'components/ui/atoms/ButtonCardMain'

const FounderScope = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { getUserStartup, startupId, user, getUserFullName } = useUser()
  const [loading, setLoading] = useState(false)
  const [startup, setStartup] = useState(null)
  const socialNetworksItems = [
    {
      name: 'linkedin',
      title: 'Linkedin',
      icon: <FaLinkedin/>
    },
    {
      name: 'twitter',
      title: 'Twitter',
      icon: <FaTwitter/>
    },
    {
      name: 'youtube',
      title: 'YouTube',
      icon: <FaYoutube/>
    },
    {
      name: 'medium',
      title: 'Medium',
      icon: <FaMedium/>
    },
    {
      name: 'github',
      title: 'GitHub',
      icon: <FaGithub/>
    },
    {
      name: 'gitlab',
      title: 'GitLab',
      icon: <FaGitlab/>
    },
    {
      name: 'crunchbase_url',
      title: 'Crunchbase',
      icon: <SiCrunchbase />
    }
  ]

  const renderAvatar = (image, alt) => (
    <div
      style={{ top: '-60px', width: 'calc(100% - 2rem)' }}
      className="absolute text-center"
    >
      <Avatar
        src={image}
        alt={alt}
        color="#e0e6f2"
        fgColor="#4d70b3"
        size="120px"
        round={true}
        className="avatar-startup"
      />
    </div>
  )

  const getFounderRole = () => {
    const key = (startup?.founders || []).find(f => f.user?._id === user?._id)?.role?.key
    return key ? t(key) : t('no_data')
  }

  const getFounderAge = () => {
    if (!user || !user.date_of_birth) {
      return t('no_data')
    }

    return new Date().getFullYear() - new Date(user.date_of_birth).getFullYear()
  }

  const getFounderSocialNetworks = () => {
    const userNetworks = user?.social_networks || {}
    return Object.keys(userNetworks)
      .filter(n => userNetworks[n])
      .map(n => {
        const item = socialNetworksItems.find(i => i.name === n)
        return item || null
      })
  }

  const haveStartupSocialNetworks = () => {
    if (!startup) {
      return false
    }

    const networks = {
      linkedin: startup.linkedin,
      crunchbase_url: startup.crunchbase_url,
      web: startup.web
    }

    return !!Object.keys(networks).filter(n => networks[n]).length
  }

  useEffect(() => {
    if (startupId) {
      setLoading(true)
      getUserStartup()
        .then((startup) => setStartup(startup))
        .finally(() => setLoading(false))
    }
  }, [startupId])

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [user])

  return (
    <>
      <PageTitle title={t('dashboard')}/>

      <div className="flex gap-4 mt-20">
        <Card
          wrapperClassName=" h-full"
          containerClassName="relative pt-16 flex flex-col h-full"
          width='1/2 lg:w-3/5'
        >
          {renderAvatar(user.image || Person, getUserFullName())}

          <div className="text-center mt-2">
            <h3 className="text-2xl text-slate-700 font-bold leading-normal mb-1">
              {getUserFullName()}
            </h3>
            <div className="text-xs mt-0 mb-2 text-slate-400 font-bold uppercase">
              <i className="fas fa-map-marker-alt mr-2 text-slate-400 opacity-75"></i>
              {t(user.nationality?.key)}
            </div>
          </div>

          <div className="w-full text-center">
            <div className="flex justify-center lg:pt-4 pt-8 pb-0">
              <div className="p-3 text-center">
                <span className="text-xl font-bold block uppercase tracking-wide text-slate-700">
                  {getFounderRole()}
                </span>
                <span className="text-sm text-slate-400">
                  {t('role')}
                </span>
              </div>

              <div className="p-3 text-center">
                <span className="text-xl font-bold block uppercase tracking-wide text-slate-700">
                  {getFounderAge()}
                </span>
                <span className="text-sm text-slate-400">
                  {t('age')}
                </span>
              </div>
            </div>
          </div>

          {getFounderSocialNetworks().length > 0 && (
            <div className="mt-6 pt-6 border-t border-slate-200 text-center">
            <span className="text-sm text-slate-400">
              {t('social_networks')}
            </span>
              <div className="flex justify-center items-center py-2">
                {getFounderSocialNetworks().map(item => (
                  <a
                    key={item.name}
                    href={user.social_networks[item.name]}
                    target="_blank"
                    rel="noreferrer"
                    data-tip={item.title}
                    className={`w-8 h-8 flex justify-center items-center rounded-full bg-main-${Config.PLATFORM} text-white mx-2`}
                  >
                    {item.icon}
                  </a>
                ))}
              </div>
            </div>
          )}

          <div className="text-center flex-1">
            <div className="flex flex-wrap justify-center h-full">
              <div className="w-full px-4 flex flex-col">
                <p className="font-light leading-relaxed text-slate-600 mb-4 flex-1">
                  {user.self_description}
                </p>
                <div className={`flex justify-end border-t border-separator-${Config.PLATFORM} pt-2 mt-4`}>
                  <ButtonCardMain
                    text={t('update_profile')}
                    onClick={() => history.push(RoutesLinks.ACCOUNT_LINK)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Card>

        <Card
          wrapperClassName="h-full"
          containerClassName="relative pt-16 flex flex-col h-full"
          width='1/2 lg:w-2/5'
        >
          {renderAvatar(startup ? startup.logo : null, startup ? startup.name : null)}

          {startup && (
            <>
              <div className="text-center mt-2">
                <div className="text-2xl text-slate-700 font-bold leading-normal mb-1">
                  {startup.name}
                </div>
                <div className="text-xs mt-0 mb-2 text-slate-400 font-bold uppercase">
                  <i className="fas fa-map-marker-alt mr-2 text-slate-400 opacity-75"></i>
                  {startup.country_incorporation && t(startup.country_incorporation.key)}
                </div>
              </div>
              <div className="flex flex-col justify-center lg:pt-4 pt-8 pb-0">
                <div className="flex justify-center">
                  <div className="p-3 text-center">
                    <span className="text-xl font-bold block uppercase tracking-wide text-slate-700">
                      {t(startup.business_model?.key || 'no_data')}
                    </span>
                    <span className="text-sm text-slate-400">
                      {t('business_model')}
                    </span>
                  </div>

                  <div className="p-3 text-center">
                    <span className="text-xl font-bold block uppercase tracking-wide text-slate-700">
                      {t(startup.project_stage?.key || 'no_data')}
                    </span>
                    <span className="text-sm text-slate-400">
                      {t('project_stage')}
                    </span>
                  </div>
                </div>
              </div>

              {haveStartupSocialNetworks() && (
                <div className="mt-6 pt-6 border-t border-slate-200 text-center">
                <span className="text-sm text-slate-400">
                  {t('social_networks')}
                </span>
                  <StartupSocialNetworks
                    className="py-2"
                    startup={startup}
                  />
                </div>
              )}

              <div className="text-center flex-1">
                <div className="flex flex-wrap justify-center h-full">
                  <div className="w-full px-4 flex flex-col">
                    <p className="font-light leading-relaxed text-slate-600 mb-4 flex-1">
                      {startup ? startup.brand_statement : null}
                    </p>
                    <div className={`flex justify-end border-t border-separator-${Config.PLATFORM} pt-2 mt-4`}>
                      <ButtonCardMain
                        text={t('see_full_profile')}
                        onClick={() => history.push(`${RoutesLinks.MY_STARTUP_LINK}`)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {(!startup && !loading) && (
            <>
              <div className="flex-1 mb-2">
                <NoDataInfo
                  title={t('startup_not_found')}
                  textOne={t('startup_not_found_text_one')}
                />
              </div>
              <div className="flex justify-end">
                <ButtonCardMain
                  text={t('register_startup')}
                  onClick={() => history.push(`${RoutesLinks.MY_STARTUP_LINK}wizard`)}
                />
              </div>
            </>
          )}
        </Card>
      </div>

      {loading && <Loader/>}
    </>
  )
}

export default FounderScope
