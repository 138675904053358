import { PageTitle } from '../ui/atoms/PageTitle'
import { useTranslation } from 'react-i18next'
import { Button } from '../ui/atoms/Button'
import { FaPlusCircle } from 'react-icons/all'
import InputFile from '../ui/atoms/input-files'
import FormUtils from '../../utils/form-utils'
import Utils from '../../utils/utils'
import { useToasts } from 'react-toast-notifications'
import { Loader } from '../ui/molecules/Loader'
import React, { useState } from 'react'
import { useSwal } from '../../hooks/useSwal'
import Sectors from '../../assets/json/sectors.json'
import InvestmentStages from '../../assets/json/investment-stages.json'
import Countries from '../../assets/json/countries.json'
import ProjectStages from '../../assets/json/project-stages.json'
import BusinessModels from '../../assets/json/business-models.json'

import KbotsDealflowList from './KbotsDealflowList'
import KbotsService from 'services/kbots-service'

const KbotsDealflowAdmin = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { confirm } = useSwal()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [currentPage, setCurrentPage] = useState(0)
  const [dealflow, setDealflow] = useState([])

  const importData = async () => {
    setLoading(true)
    KbotsService.importDealflow(data).then(() => {
      addToast(t('kbots_dealflow_imported_successfullt'), {
        appearance: 'success',
        autoDismiss: true
      })
    }).catch(() => addToast(t('error_occurred_importing_kbots_dealflow'), {
      appearance: 'error',
      autoDismiss: true
    })).finally(() => setLoading(false))
  }

  const selectFile = async (file) => {
    if (!file) {
      return
    }

    setLoading(true)

    try {
      const csv = await FormUtils.readFile(file)
      const json = Utils.csvJSON(csv, ',')

      if (!json || !json.length) {
        addToast(t('invalid_csv_format'), {
          appearance: 'error',
          autoDismiss: true
        })
        setLoading(false)
        return
      }

      const data = json.map((item) => {
        const keys = Object.keys(item)
        const values = Object.values(item)
        const obj = {}

        for (let i = 0; i < keys.length; i++) {
          const key = keys[i].trim() || 'id'
          obj[Utils.toSnakeCase(key)] = values[i]
        }

        return {
          name: obj.name,
          ceo: obj.ceo,
          internal_id: obj.internal_id,
          logo: obj.logo,
          web: obj.url_web,
          email: obj.email,
          raw_object: obj,
          sector: obj.sector ? Sectors.find(sector => sector.translation_en === obj.sector)._id : null,
          investment_stage: obj.investment_stage ? InvestmentStages.find(is => is.translation_en === obj.investment_stage)._id : null,
          incorporation_country: obj.incorporation_country ? Countries.find(c => c.translation_en === obj.incorporation_country)._id : null,
          business_model: obj.business_model ? BusinessModels.find(c => c.translation_en === obj.business_model)._id : null,
          project_stage: obj.project_stage ? ProjectStages.find(c => c.translation_en === obj.project_stage)._id : null,
        }
      })


      setLoading(false)
      setData(data)

      confirm({
        text: `¿Seguro que desea importar ${data.length} registros?`
      }).then(isConfirmed => {
        if (isConfirmed) {
          importData()
        }
      })
    } catch (e) {
      console.log(e)
      addToast(t('error_occurred'), {
        appearance: 'error',
        autoDismiss: true
      })
      setLoading(false)
    }
  }

  return (
    <>
      <PageTitle
        removeMargin={true}
        title={t('kbots_dealflow')}
      />

      <div className='my-4'>
        <InputFile
          setSelectedFile={selectFile}
          accept='.csv'
        />
      </div>

      <KbotsDealflowList dealflow={dealflow} setDealflow={setDealflow} admin={true} />

      {loading && <Loader />}
    </>
  )
}

export default KbotsDealflowAdmin
