import { ResponsiveBar } from '@nivo/bar'

const BarChart = ({ data, axisRotation, keys }) => {
  return (
    <ResponsiveBar
      groupMode="grouped"
      data={data}
      enableLabel={false}
      colors={['#4D70B3', '#67A7DE']}
      keys={keys}
      indexBy={'indexed'}
      borderRadius={3}
      margin={{ top: 40, right: 100, bottom: 60, left: 140 }}
      // isInteractive={false}
      labelTextColor={'#fff'}
      labelSkipWidth={16}
      labelSkipHeight={16}
      axisBottom={{
        tickRotation: axisRotation
      }}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'top-left',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: -30,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 12
        }
      ]}
    />
  )
}

export default BarChart
