import { useTranslation } from 'react-i18next'
import { Button } from 'components/ui/atoms/Button'
import { SidePanel } from 'components/ui/objects/SidePanel'
import { Card } from 'components/ui/atoms/Card'
import React, { useEffect, useState } from 'react'
import { FaCheck, FaCopy, FaDownload, FaTimes } from 'react-icons/fa'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import Avatar from 'react-avatar'
import SupportService from '../../../services/support-service'
import ProjectsService from '../../../services/projects-service'
import { useToasts } from 'react-toast-notifications'
import { useSwal } from '../../../hooks/useSwal'
import { Loader } from '../molecules/Loader'
import { useHistory } from 'react-router-dom'
import { RoutesLinks } from 'components/routes-links'
import QRCode from 'qrcode'
import Utils from 'utils/utils'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Input } from './Input'
import useUser from '../../../hooks/useUser'
import Config from 'config'
import { ButtonMain } from './ButtonMain'

/**
 * @param onClose
 * @param source {'event' | 'pool'}
 * @param elementToShare
 * @returns {JSX.Element}
 * @constructor
 */
const ShareStartupSection = ({ onClose, source, elementToShare }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { confirm } = useSwal()
  const { user } = useUser()
  const [selectedContacts, setSelectedContacts] = useState([])
  const [selectedInvestors, setSelectedInvestors] = useState([])
  const [loading, setLoading] = useState(false)
  const [contacts, setContacts] = useState([])
  const history = useHistory()
  const [qr, setQr] = useState(null)

  const getContacts = () => {
    setLoading(true)
    SupportService.getConnections('accepted')
      .then((result) => setContacts(result.data.data))
      .catch(() => {
        addToast(t('error_occurred_retrieving_contacts'), { appearance: 'error', autoDismiss: true })
      }).finally(() => {
        setLoading(false)
      })
  }

  const shareStartup = (investors) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        return ProjectsService.shareDealflow(source, elementToShare, investors)
          .then(() => {
            addToast(t('startup_shared_successfully'), { appearance: 'success', autoDismiss: true })
          })
          .catch(() => {
            addToast(t('error_occurred_retrieving_contacts'), { appearance: 'error', autoDismiss: true })
          }).finally(() => {
            setLoading(false)
            onClose && onClose()
          })
      }
    })
  }

  const handleChangeSelectContact = (contact) => {
    const index = selectedContacts.findIndex((item) => item === contact._id)
    if (index >= 0) {
      const tmpSelectedInvestors = [...selectedInvestors]
      const indexSelectedInvestor = tmpSelectedInvestors.findIndex((item) => item === contact?.requested_investor._id === user.investor._id ? contact?.requested_by_investor?._id : contact?.requested_investor?._id)

      const tmpSelectedContacts = [...selectedContacts]
      tmpSelectedContacts.splice(index, 1)
      tmpSelectedInvestors.splice(indexSelectedInvestor, 1)

      setSelectedContacts(tmpSelectedContacts)
      setSelectedInvestors(tmpSelectedInvestors)
    } else {
      setSelectedContacts([...selectedContacts, contact._id])
      setSelectedInvestors([...selectedInvestors, contact?.requested_investor._id === user.investor._id ? contact?.requested_by_investor?._id : contact?.requested_investor?._id])
    }
  }

  const copyPublicProfileLink = () => {
    const domain = window.location.origin
    addToast(t('copy_link_to_clipboard_success'), { appearance: 'success', autoDismiss: true })
    Utils.copyToClipboard(`${domain}${RoutesLinks.SHARED_DEALFLOW_LINK}${elementToShare._id}`)
  }

  const downloadQr = () => {
    const a = document.createElement('a')
    a.href = 'data:application/png;base64' + qr
    a.download = `QR - ${elementToShare._id}.png`
    a.click()
  }

  useEffect(() => {
    getContacts()
    const domain = window.location.origin
    QRCode.toDataURL(
      `${domain}${RoutesLinks.SHARED_DEALFLOW_LINK}${elementToShare._id}`
    ).then(qrURLData => {
      setQr(qrURLData)
    })
  }, [])

  const ShareLinkSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('incorrect_format'))
      .required(t('required_field'))
  })

  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ShareLinkSchema)
  })

  const shareDealWithExternal = ({email}) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        return ProjectsService.shareDealflowWithExternal(source, elementToShare, email)
          .then(() => {
            addToast(t('startup_shared_successfully'), { appearance: 'success', autoDismiss: true })
          })
          .catch((error) => {
            addToast(t(error.response && error.response.data.msg_key ? error.response.data.msg_key : 'error_occurred_sharing_startup'), { appearance: 'error', autoDismiss: true })
          }).finally(() => {
            setLoading(false)
            onClose && onClose()
          })
      }
    })
  }

  return (
    <>
      <SidePanel
        width={'1/4'}
        onClose={onClose}
        title={t('share_startup')}
      >

        <p className={`font-medium mb-6 text-sm text-main-${Config.PLATFORM} border-b border-main-${Config.PLATFORM}`}>
          {t('share_with')}
        </p>

        {!elementToShare.project && elementToShare.managed_startup && (
          <p className="text-base mb-2 text-gray">{t('share_managed_startup')}</p>
        )}

        {elementToShare.project && (
          <p className="text-base mb-2 text-gray">
            {elementToShare?.project.profile_visibility === 'public' && t('share_registered_startup_public')}
            {elementToShare?.project.profile_visibility !== 'public' && t('share_registered_startup_private')}
          </p>
        )}

        <div className="mt-4 flex flex-col w-full">
          <form className="w-full flex flex-col" onSubmit={handleSubmit(shareDealWithExternal)}>
            <div className="w-full mr-4">
              <Input
                reference={register}
                id="email"
                type={'email'}
                name="email"
                error={errors.email}
                label={t('email')}
                placeholder={t('email')}
              />
            </div>
            <div className={`flex justify-end mt-2 pt-2 border-t border-separator-${Config.PLATFORM}`}>
              <ButtonMain
                type={'submit'}
                text={t('share')}
              />
            </div>
          </form>
        </div>
      </SidePanel>

      {loading && <Loader />}
    </>
  )
}

export default ShareStartupSection
