import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ProjectsService from 'services/projects-service'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import { Loader } from 'components/ui/molecules/Loader'
import { NoDataInfo } from 'components/ui/atoms/NoDataInfo'
import useUser from '../../hooks/useUser'
import { PageTitle } from 'components/ui/atoms/PageTitle'
import { ConnectedInvestor } from 'components/ui/atoms/ConnectedInvestor'
import { SectionHeader } from '../ui/molecules/SectionHeader'
import { RegisteredInvestor } from '../ui/atoms/RegisteredInvestor'
import { usePagination } from '../../hooks/usePagination'
import { Pagination } from '../ui/atoms/Pagination'

const ProjectInvestors = () => {
  const { startupId, getUserStartup } = useUser()
  const history = useHistory()
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [connectedInvestors, setConnectedInvestors] = useState([])
  const [registeredInvestors, setRegisteredInvestors] = useState([])
  const [loading, setLoading] = useState(false)
  const [section, setSection] = useState('connections')
  const SECTIONS = ['connections', 'registered_investors']
  const [startup, setStartup] = useState({})

  const {
    setTotalPages,
    totalPages,
    setPage,
    page,
    rowsPerPage,
    setRowsPerPage
  } = usePagination(12)

  const getConnectedInvestors = () => {
    setLoading(true)
    return ProjectsService.getInvestors(startupId).then(result => {
      setConnectedInvestors(result.data)
    }).catch(() => {
      addToast(t('error_occurred_retrieving_investors'), { appearance: 'error', autoDismiss: true })
    }).finally(() => {
      setLoading(false)
    })
  }

  const getRegisteredInvestors = () => {
    setLoading(true)
    return ProjectsService.getExploreInvestors({
      rowsPerPage,
      page,
      project: startupId
    }).then(result => {
      const { investors, pagination } = result.data
      setRegisteredInvestors(investors)
      setTotalPages(pagination.pages)
    }).catch(() => {
      addToast(t('error_occurred_retrieving_investors'), { appearance: 'error', autoDismiss: true })
    }).finally(() => {
      setLoading(false)
    })
  }

  const showPagination = () => {
    if (loading) {
      return false
    }

    if (section === 'connections') {
      return false
    }

    return section === 'registered_investors'
  }

  useEffect(() => {
    if (startupId) {
      setLoading(true)
      getUserStartup()
        .then((startup) => setStartup(startup))
        .finally(() => setLoading(false))
    }
  }, [startupId])

  useEffect(() => {
    if (startupId && section === 'connections') {
      getConnectedInvestors()
    }

    if (section === 'registered_investors') {
      setPage(1)
      setRowsPerPage(12)
      getRegisteredInvestors()
    }
  }, [section, startupId])

  useEffect(() => {
    if (section === 'registered_investors') {
      getRegisteredInvestors()
    }
  }, [page, rowsPerPage])

  return (
    <>
      <PageTitle title={t('investors')} showBack={false} />

      <SectionHeader
        sectionTitles={SECTIONS.map(section => t(section))}
        sectionKeys={SECTIONS}
        section={section}
        setSection={setSection}
      />

      {section === 'connections' && (
        <>
          <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 my-6'>
            {connectedInvestors.map(investor => (
              <ConnectedInvestor
                key={investor._id}
                onManage={getConnectedInvestors}
                investor={{
                  ...investor.investor,
                  createdAt: investor.createdAt,
                  project_access_granted: investor.project_access_granted
                }}
                profileVisibility={startup?.profile_visibility}
              />
            ))}
          </div>

          {connectedInvestors.length === 0 && (
            <NoDataInfo
              title={startupId ? t('not_included_in_pool') : t('investors_not_found')}
              textOne={startupId ? t('not_included_in_pool_description') : t('investors_not_found_no_project')}
            />
          )}
        </>
      )}

      {section === 'registered_investors' && (
        <>
          {registeredInvestors.length === 0 && (
            <div className='my-10'>
              <NoDataInfo
                title={t('investors_not_found')}
                textOne={t('registered_investors_not_found')}
              />
            </div>
          )}
          {registeredInvestors.length !== 0 && (
            <>
              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 my-6'>
                {registeredInvestors.map(investor => (
                  <RegisteredInvestor
                    key={investor._id}
                    investor={investor}
                    onManage={getRegisteredInvestors}
                  />
                ))}
              </div>
              {showPagination() && (
                <Pagination
                  paginateOptions={[12, 24, 36, 48, 60]}
                  currentPage={page}
                  setCurrentPage={(page) => setPage(page)}
                  perPage={rowsPerPage}
                  pages={totalPages}
                  setPerPage={(value) => setRowsPerPage(value[0].id)}
                />
              )}
            </>
          )}
        </>
      )}
      {loading && <Loader />}
    </>
  )
}

export default ProjectInvestors
