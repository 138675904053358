import { useTranslation } from 'react-i18next'
import { Card } from 'components/ui/atoms/Card'
import Avatar from 'react-avatar'
import { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { Button } from 'components/ui/atoms/Button'
import Utils from 'utils/utils'
import ProjectsService from '../../../services/projects-service'
import useUser from '../../../hooks/useUser'
import { useToasts } from 'react-toast-notifications'
import { useSwal } from '../../../hooks/useSwal'
import { Switch } from './Switch'
import { isEqual, isEmpty } from 'lodash'
import Config from 'config';
import { FaPaperPlane } from 'react-icons/fa'
import { ButtonCardSecondary } from './ButtonCardSecondary'
import { ButtonCardMain } from './ButtonCardMain'

export function ConnectedInvestor({
  investor,
  onManage,
  profileVisibility
}) {
  const { t } = useTranslation()
  const { startupId } = useUser()
  const { addToast } = useToasts()
  const { confirm } = useSwal()
  const [permissionsToUpdate, setPermissionsToUpdate] = useState([])
  const [sections, setSections] = useState([])

  const updatePermissions = (investorId) => {
    confirm({
      title: t('update_permissions'),
      text: t('update_permissions_description')
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          await ProjectsService.manageAccess(
            startupId,
            investorId,
            permissionsToUpdate
          )
          addToast(t('permissions_updated_successfully'), {
            appearance: 'success',
            autoDismiss: true
          })
          onManage && onManage()
        } catch (error) {
          addToast(
            error.response && error.response.data.msg
              ? error.response.data.msg
              : t('error_occurred_updating_permissions'),
            {
              appearance: 'error',
              autoDismiss: true
            }
          )
        }
      }
    })
  }

  useEffect(() => {
    if (profileVisibility === 'public') {
      setSections(['documents', 'captable', 'competitors', 'kpis'])
    } else {
      setSections(['documents', 'captable', 'competitors', 'kpis', 'team', 'problem', 'solution', 'market', 'business_description'])
    }
  }, [profileVisibility])

  useEffect(() => {
    setPermissionsToUpdate(investor.project_access_granted ? investor.project_access_granted : [])
  }, [investor])

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  return (
    <Card
      wrapperClassName="transition-transform"
      containerClassName="w-full flex flex-col h-full"
    >
      <div className='flex flex-col'>
        <div className="flex pb-2 mb-2 justify-between">
          <div className='flex flex-row w-full items-center'>

            <div className="flex flex-col text-xs min-w-0 w-3/5">
              <h5 className={`uppercase font-bold text-sm leading-none text-main-${Config.PLATFORM} truncate mb-1`}>
                {investor.company_name}
              </h5>
              <div>
                <b>{t('connected')}:</b> <span className='capitalize'>{Utils.getReadableDate(investor.createdAt)}</span>
              </div>
            </div>

            <div className='flex items-center justify-center w-2/5'>
              <div className=" w-[50px] h-[50px]">
                <Avatar
                  src={investor.logo}
                  name={investor.company_name}
                  alt={investor.company_name}
                  maxInitials={3}
                  className="w-full h-full object-contain"
                  color="#e0e6f2"
                  fgColor="#4d70b3"
                  round="5"
                  size={"50"}
                />
              </div>
            </div>

          </div>
        </div>
      </div>
      {profileVisibility && (
        <>
          <div>
            <p className="text-sm text-gray my-2">{t('sections_to_allow_access')}</p>
          </div>
          <div className='my-2 grid grid-cols-2 gap-4'>
            {sections.map((section, index) => {
              return (
                <div key={index}>
                  <Switch
                    disabled={true}
                    size="sm"
                    checked={permissionsToUpdate.includes(section)}
                    onChange={(value) => {
                      if (value) {
                        setPermissionsToUpdate([...permissionsToUpdate, section])
                      } else {
                        const _permissionsToUpdate = [...permissionsToUpdate]
                        _permissionsToUpdate.splice(permissionsToUpdate.indexOf(section), 1)
                        setPermissionsToUpdate([..._permissionsToUpdate])
                      }
                    }}
                    text={t(section)}
                    textSize={'sm'}
                  />
                </div>
              )
            })}
          </div>
        </>
      )}
        <div className={`flex justify-end mt-2 pt-2 border-t border-separator-${Config.PLATFORM}`}>
        {investor.contact_email && (<ButtonCardSecondary
            onClick={() => window.location = `mailto:${investor.contact_email}`}
            text={t('contact')}
            marginRight={2}
            iconComponent={<FaPaperPlane className="inline-block mr-2 w-5 h-4" />}
          />)}
        <ButtonCardMain
          onClick={() => updatePermissions(investor._id)}
          text={t('update')}
          disabled={isEqual(permissionsToUpdate.sort(), investor.project_access_granted ? investor.project_access_granted.sort() : []) || (isEmpty(permissionsToUpdate) && !investor.project_access_granted)}
        />
      </div>
    </Card>
  )
}
