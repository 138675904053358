import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../ui/atoms/Button'
import { Input } from '../../../ui/atoms/Input'
import { Modal } from '../../../ui/molecules/Modal'

const CreateFormFromTemplateModal = ({ showModal, handleCloseModal, onSubmit }) => {
  const { t } = useTranslation()

  const [name, setName] = useState('')

  const handleSubmit = () => {
    onSubmit(name)
    setName('')
  }

  return (
    <>
      <Modal showModal={showModal} width="96">
        <h2 className="font-semibold mr-4">{t('create_form')}</h2>
        <p className="text-xs font-medium text-gray-dark mb-4">{t('create_form_from_template')}</p>

        <Input
          id="name"
          type="text"
          name="name"
          label={t('form_name') + ':'}
          placeholder={t('introduce_form_name')}
          onChange={(e) => {
            setName(e.target.value)
          }}
        />

        <div className="flex justify-between w-full my-4">
          <ButtonSecondary
            text={t('cancel')}
            onClick={() => handleCloseModal(false)}
          />
          <ButtonMain disabled={!name} onClick={handleSubmit} text={t('create_form')} />
        </div>
      </Modal>
    </>
  )
}

export default CreateFormFromTemplateModal
