import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { saturateColor } from 'utils/utils'
import { FaCheckCircle, FaCircle } from 'react-icons/all'
import { Input } from 'components/ui/atoms/Input'

export const TagsSelector = ({
  tags,
  setSelectedTags,
  selectedTags
}) => {
  const { t } = useTranslation()
  const [filteredTags, setFilteredTags] = useState(tags || [])

  const selectTag = (tagId) => {
    if (selectedTags.includes(tagId)) {
      setSelectedTags(selectedTags.filter((t) => t !== tagId))
    } else {
      setSelectedTags([...selectedTags, tagId])
    }
  }

  const isTagSelected = (tagId) => {
    return selectedTags.includes(tagId)
  }

  const onSearch = (e) => {
    const search = e.target.value
    setFilteredTags(tags.filter((tag) => {
      return tag.name.toLowerCase().includes(search.toLowerCase())
    }))
  }

  useEffect(() => {
    if (tags && Array.isArray(tags)) {
      setFilteredTags(tags)
    }
  }, [tags])

  return (
    <>
      <Input
        placeholder={t('search')}
        onChange={onSearch}
      />
      <div className="mt-2 flex flex-wrap max-h-52 overflow-y-scroll">
        {filteredTags.map((tag) => (
          <div
            key={tag._id}
            onClick={() => selectTag(tag._id)}
            className={`inline-block mr-2 mb-4 rounded-2xl py-1 px-2 cursor-pointer relative font-semibold text-xs ${isTagSelected(tag._id) ? 'shadow-inner' : ''}`}
            style={{
              backgroundColor: tag.color,
              color: saturateColor(tag.color, 0.7)
            }}
          >
            {tag.name}
            {isTagSelected(tag._id) && <FaCheckCircle className="inline-block ml-1" />}
            {!isTagSelected(tag._id) && <FaCircle className="inline-block ml-1" />}
          </div>
        ))}
      </div>
    </>
  )
}
