const ScoreCardUtils = {
  getCriteriaParents: (criteria) => {
    const childrenIds = criteria
      .filter((c) => c.parent)
      .map((children) => children._id)

    return criteria.filter((c) => !childrenIds.includes(c._id))
  },

  hasChildren: (currentCriteria, criteria, isId = false) => {
    if (!Array.isArray(criteria)) {
      return false
    }

    return isId
      ? !!criteria.filter((c) => c.parent === currentCriteria).length
      : !!criteria.filter((c) => c.parent === currentCriteria._id).length
  },

  getChildren: (parent, criteria) => {
    return criteria.filter((c) => c.parent === parent._id)
  },

  getParentScore: (parent, criteria, scores) => {
    const children = ScoreCardUtils.getChildren(parent, criteria)
    let score = 0

    if (children.length) {
      children.forEach((child) => {
        score += (scores[child._id] * child.weigth) / 100
      })
    } else {
      score = scores[parent._id]
    }

    return Math.round(score * 100) / 100
  }
}

export default ScoreCardUtils
