import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'components/ui/molecules/Modal'
import { Button } from 'components/ui/atoms/Button'
import InputFile from 'components/ui/atoms/input-files'
import Select from 'components/ui/atoms/Select'
import { Switch } from 'components/ui/atoms/Switch'
import Config from 'config'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'

const UploadFileModal = ({
  showModal,
  onClose,
  onSubmit,
  section,
  accept = '.doc, .docx, .json, .ppt, .pptx, .xls, .xlsx, video/mp4, video/avi, video/mov',
  file = null,
  documents = []
}) => {
  const { t } = useTranslation()

  const [selectedFileFromDevice, setSelectedFileFromDevice] = useState(null)
  const [selectedFileFromDocuments, setSelectedFileFromDocuments] = useState(
    null
  )

  const [downloadable, setDownloadable] = useState(true)

  const [fileError, setFileError] = useState(false)

  const [reset, setReset] = useState(false)
  const [isPDF, setIsPDF] = useState(false)

  return (
    <>
      <Modal showModal={showModal} paddingBottom="4" paddingTop="6" showCloseModal={true} onClose={() => onClose()}>
        {section && <h2 className="font-semibold mr-4">{section.name}</h2>}
        <p className="font-medium text-sm text-gray-dark mt-10 mb-4">
          {t('upload_from_device')}
        </p>

        <InputFile
          placeholder={t('file')}
          selectedFile={selectedFileFromDevice}
          setSelectedFile={(e) => {
            if (['pdf', 'PDF'].includes(e.path.split('.').at(-1))) {
              setIsPDF(true)
            } else {
              setIsPDF(false)
              setDownloadable(true)
            }
            setFileError(false)
            setSelectedFileFromDevice(e)
            setSelectedFileFromDocuments(null)
            setReset(true)
          }}
          accept={accept}
          error={fileError ? { message: t('one_is_required') } : {}}
        />
        {documents.length > 0 && (
          <>
            {' '}
            <p className="font-medium text-xs text-gray-dark mt-10 mb-4">
              {t('or_upload_from_documents')}
            </p>
            <Select
              reset={reset}
              setReset={setReset}
              id="file"
              name="file"
              placeholder={t('select_file')}
              onSelect={(options) => {
                if (options[0].value.split('.').at(-1) === 'pdf') {
                  setIsPDF(true)
                } else {
                  setIsPDF(false)
                  setDownloadable(true)
                }
                setSelectedFileFromDocuments(options[0])
                setSelectedFileFromDevice(null)
                setFileError(null)
              }}
              items={documents.map((document) => {
                return { id: document._id, value: document.name }
              })}
              initialValues={file && file.id ? [file] : []}
              error={fileError ? { message: t('one_is_required') } : {}}
            />
          </>
        )}

        {isPDF && <div className={'text-xs mt-8 mb-1 flex'}>
          <span className={'pt-1.5 pr-2'}>{t('document_downloadable')}</span>
          <Switch
            checked={downloadable}
            onChange={checked => {
              if (isPDF) {
                setDownloadable(checked)
              }
            }}
          />
        </div>}

        {!isPDF && (
          <div className='my-4'>
            <small className={`text-main-${Config.PLATFORM} font-medium`}>
              {t('only_pdf_configured_as_downloadable')}
            </small>
          </div>
        )}


        <div className={`flex justify-end mt-4 border-t pt-2 border-separator-${Config.PLATFORM}`}>
          <ButtonMain
            onClick={() => {
              if (!selectedFileFromDevice && !selectedFileFromDocuments) {
                setFileError(true)
              } else {
                onSubmit(
                  selectedFileFromDevice || selectedFileFromDocuments,
                  section._id,
                  downloadable
                )
                onClose()
              }
            }}
            text={t('upload_file')}
          />
        </div>
      </Modal>
    </>
  )
}

export default UploadFileModal
