import React, { useRef, useState } from 'react'
import { PageTitle } from '../ui/atoms/PageTitle'
import { useTranslation } from 'react-i18next'
import { SectionHeader } from '../ui/molecules/SectionHeader'
import { Button } from '../ui/atoms/Button'
import { FaEnvelope, FaFilter, FaPlus, FaPlusCircle } from 'react-icons/all'
import { PoolStartups } from './PoolStartups'
import { TagListView } from '../tags/TagListView'
import { SharedStartups } from './SharedStartups'
import { ExploreStartups } from './ExploreStartups'
import { InvestorProjectConnections } from './InvestorProjectConnections'
import { InvitationModal } from '../ui/molecules/InvitationModal'
import { Modal } from '../ui/molecules/Modal'
import { DataGrid } from '../ui/objects/DataGrid'
import Avatar from 'react-avatar'
import ProjectsService from '../../services/projects-service'
import { Loader } from '../ui/molecules/Loader'
import { useToasts } from 'react-toast-notifications'
import Config from 'config'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { ButtonSecondary } from 'components/ui/atoms/ButtonSecondary'
import { CuratedDealFlow } from 'components/curated-dealflow/CuratedDealflow'
import { DealResumeSection } from './DealResumeSection'

export const DealflowListView = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  // const sections = Config.PLATFORM === '000' ? ['pool', 'shared', 'explore', 'connections', 'tags'] : ['pool', 'explore', 'connections', 'tags']
  const sections = Config.PLATFORM === '000' ? ['pool', 'curated_dealflow', 'tags'] : ['pool', 'explore', 'connection_requests', 'tags']
  const [section, setSection] = useState('pool')
  const [showInviteStartupModal, setShowInviteStartupModal] = useState(false)
  const [invitationResult, setInvitationResult] = useState({})
  const [loading, setLoading] = useState(false)
  const poolRef = useRef()
  const curatedDealflowRef = useRef()
  const exploreRef = useRef()
  const tagsRef = useRef()
  const [investorView, setInvestorView] = useState(true)
  const [dealToResume, setDealToResume] = useState(null)

  const inviteStartup = (data) => {
    setLoading(true)
    setShowInviteStartupModal(false)
    ProjectsService.inviteStartup(data).then(({ data: result }) => {
      const { projects_found: projects } = result

      if (!projects.length) {
        addToast(t('entrepreneur_invited_successfully'), { appearance: 'success', autoDismiss: true })
      } else {
        setInvitationResult(result)
        // setShowInvitationResultsModal(true)
        exploreRef?.current?.reload()
      }
    }).catch(() => {
      addToast(t('error_occurred_inviting_entrepreneur'), { appearance: 'error', autoDismiss: true })
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <>
      <div className="flex items-start">
        <div className="flex-1">
          <PageTitle title={t('dealflow')} />
        </div>

        {Config.PLATFORM === '000' && section === 'pool' && (
          <>
            <ButtonMain
              onClick={() => poolRef.current?.addStartup()}
              text={t('add_startup')}
              iconComponent={<FaPlus className="inline-block mr-2" />}
              marginRight="2"
            />
          </>
        )}

        {/*{Config.PLATFORM === '000' && (
          <>
            <Button
              onClick={() => setShowInviteStartupModal(true)}
              width="auto"
              text={t('invite_startup')}
              verticalMargin="1"
              iconComponent={<FaEnvelope className="inline-block mr-3" />}
              vertical="2"
              horizontal="4"
              marginRight="4"
              className="text-xs"
              textColor={`buttons-card-text-${Config.PLATFORM}`}
              bgColor={`buttons-secondary-${Config.PLATFORM}`}
              bgHoverColor={`buttons-hover-${Config.PLATFORM}`}
            />
          </>
        )}*/}

        {['pool', 'explore'].includes(section) && <div data-tip={t('filter')}>
            <ButtonSecondary
              iconComponent={<FaFilter className='h-4'/>}
              onClick={() => {
                section === 'pool' && poolRef.current?.showFilters()
                section === 'explore' && exploreRef.current?.showFilters()
              }}
              />
          </div>}
          {section === 'tags' && <div>
            <ButtonMain
              iconComponent={<FaPlusCircle className="inline-block mr-3"/>}
              text={t('create_tag')}
              onClick={() => tagsRef.current?.handleCreateTag()}
              />
          </div>}
        </div>

      <SectionHeader
        section={section}
        setSection={setSection}
        sectionKeys={sections}
        sectionTitles={sections.map(section => t(section))}
        showFilters={false}
        setShowFilters={null}
      />

      {dealToResume && (
        <DealResumeSection
          investorView={investorView}
          setInvestorView={setInvestorView}
          deal={dealToResume || {}}
          onClose={() => {
            setDealToResume(null)
          }}
          section={section}
        />
      )}
      {section === 'pool' && (
        <PoolStartups ref={poolRef} setDealToResume={setDealToResume}/>
      )}

      {section === 'curated_dealflow' && (
        <CuratedDealFlow ref={curatedDealflowRef} />
      )}

      {section === 'shared' && (
        <SharedStartups />
      )}

      {section === 'explore' && (
        <ExploreStartups ref={exploreRef} setDealToResume={setDealToResume} />
      )}

      {section === 'connection_requests' && (
        <InvestorProjectConnections setDealToResume={setDealToResume}/>
      )}

      {section === 'tags' && (
        <TagListView category='dealflow' ref={tagsRef}/>
      )}

      {showInviteStartupModal && (
        <InvitationModal
          onSubmit={inviteStartup}
          showModal={showInviteStartupModal}
          handleCloseModal={() => setShowInviteStartupModal(false)}
        />
      )}

      {loading && <Loader />}
    </>
  )
}
